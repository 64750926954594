@import '../../../node_modules/ui-library/dist/styles/variables.css';
html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.5;
}
html.font-size-m {
  font-size: 71.4286%;
}
html.font-size-l {
  font-size: 80.357%;
}
html[data-theme='light'] {
  @charset "UTF-8";
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-no-unknown */
  @import '../../../node_modules/ui-library/dist/styles/themes/modern/variables.css';
  /* stylelint-disable */
  /*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
  /*# sourceMappingURL=bootstrap-grid.min.css.map */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
  /* Titles */
  /* Axes */
  /* Credits */
  /* Tooltip */
  /* Legend hover affects points and series */
  /* Series options */
  /* Default colors */
  /* Legend */
  /* Bubble legend */
  /* Loading */
  /* Plot bands and polar pane backgrounds */
  /* Highcharts More and modules */
  /* Highstock */
  /* Highmaps */
  /* 3d charts */
  /* Exporting module */
  /* Drilldown module */
  /* No-data module */
  /* Drag-panes module */
  /* Bullet type series */
  /* Lineargauge type series */
  /* Annotations module */
  /* Gantt */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
}
html[data-theme='light'] html,
html[data-theme='light'] body {
  width: 100%;
  height: 100%;
}
html[data-theme='light'] input::-ms-clear,
html[data-theme='light'] input::-ms-reveal {
  display: none;
}
html[data-theme='light'] *,
html[data-theme='light'] *::before,
html[data-theme='light'] *::after {
  box-sizing: border-box;
}
html[data-theme='light'] html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
html[data-theme='light'] article,
html[data-theme='light'] aside,
html[data-theme='light'] dialog,
html[data-theme='light'] figcaption,
html[data-theme='light'] figure,
html[data-theme='light'] footer,
html[data-theme='light'] header,
html[data-theme='light'] hgroup,
html[data-theme='light'] main,
html[data-theme='light'] nav,
html[data-theme='light'] section {
  display: block;
}
html[data-theme='light'] body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] [tabindex='-1']:focus {
  outline: none !important;
}
html[data-theme='light'] hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
html[data-theme='light'] h1,
html[data-theme='light'] h2,
html[data-theme='light'] h3,
html[data-theme='light'] h4,
html[data-theme='light'] h5,
html[data-theme='light'] h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
html[data-theme='light'] p {
  margin-top: 0;
  margin-bottom: 1em;
}
html[data-theme='light'] abbr[title],
html[data-theme='light'] abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
html[data-theme='light'] address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
html[data-theme='light'] input[type='text'],
html[data-theme='light'] input[type='password'],
html[data-theme='light'] input[type='number'],
html[data-theme='light'] textarea {
  -webkit-appearance: none;
}
html[data-theme='light'] ol,
html[data-theme='light'] ul,
html[data-theme='light'] dl {
  margin-top: 0;
  margin-bottom: 1em;
}
html[data-theme='light'] ol ol,
html[data-theme='light'] ul ul,
html[data-theme='light'] ol ul,
html[data-theme='light'] ul ol {
  margin-bottom: 0;
}
html[data-theme='light'] dt {
  font-weight: 500;
}
html[data-theme='light'] dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
html[data-theme='light'] blockquote {
  margin: 0 0 1em;
}
html[data-theme='light'] dfn {
  font-style: italic;
}
html[data-theme='light'] b,
html[data-theme='light'] strong {
  font-weight: bolder;
}
html[data-theme='light'] small {
  font-size: 80%;
}
html[data-theme='light'] sub,
html[data-theme='light'] sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
html[data-theme='light'] sub {
  bottom: -0.25em;
}
html[data-theme='light'] sup {
  top: -0.5em;
}
html[data-theme='light'] a {
  color: #4261ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
html[data-theme='light'] a:hover {
  color: #6b89ff;
}
html[data-theme='light'] a:active {
  color: #2e44d9;
}
html[data-theme='light'] a:active,
html[data-theme='light'] a:hover {
  text-decoration: none;
  outline: 0;
}
html[data-theme='light'] a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
html[data-theme='light'] pre,
html[data-theme='light'] code,
html[data-theme='light'] kbd,
html[data-theme='light'] samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
html[data-theme='light'] pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
html[data-theme='light'] figure {
  margin: 0 0 1em;
}
html[data-theme='light'] img {
  vertical-align: middle;
  border-style: none;
}
html[data-theme='light'] svg:not(:root) {
  overflow: hidden;
}
html[data-theme='light'] a,
html[data-theme='light'] area,
html[data-theme='light'] button,
html[data-theme='light'] [role='button'],
html[data-theme='light'] input:not([type='range']),
html[data-theme='light'] label,
html[data-theme='light'] select,
html[data-theme='light'] summary,
html[data-theme='light'] textarea {
  touch-action: manipulation;
}
html[data-theme='light'] table {
  border-collapse: collapse;
}
html[data-theme='light'] caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
html[data-theme='light'] th {
  text-align: inherit;
}
html[data-theme='light'] input,
html[data-theme='light'] button,
html[data-theme='light'] select,
html[data-theme='light'] optgroup,
html[data-theme='light'] textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
html[data-theme='light'] button,
html[data-theme='light'] input {
  overflow: visible;
}
html[data-theme='light'] button,
html[data-theme='light'] select {
  text-transform: none;
}
html[data-theme='light'] button,
html[data-theme='light'] html [type="button"],
html[data-theme='light'] [type="reset"],
html[data-theme='light'] [type="submit"] {
  -webkit-appearance: button;
}
html[data-theme='light'] button::-moz-focus-inner,
html[data-theme='light'] [type='button']::-moz-focus-inner,
html[data-theme='light'] [type='reset']::-moz-focus-inner,
html[data-theme='light'] [type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
html[data-theme='light'] input[type='radio'],
html[data-theme='light'] input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
html[data-theme='light'] input[type='date'],
html[data-theme='light'] input[type='time'],
html[data-theme='light'] input[type='datetime-local'],
html[data-theme='light'] input[type='month'] {
  -webkit-appearance: listbox;
}
html[data-theme='light'] textarea {
  overflow: auto;
  resize: vertical;
}
html[data-theme='light'] fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
html[data-theme='light'] legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
html[data-theme='light'] progress {
  vertical-align: baseline;
}
html[data-theme='light'] [type='number']::-webkit-inner-spin-button,
html[data-theme='light'] [type='number']::-webkit-outer-spin-button {
  height: auto;
}
html[data-theme='light'] [type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
html[data-theme='light'] [type='search']::-webkit-search-cancel-button,
html[data-theme='light'] [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
html[data-theme='light'] ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
html[data-theme='light'] output {
  display: inline-block;
}
html[data-theme='light'] summary {
  display: list-item;
}
html[data-theme='light'] template {
  display: none;
}
html[data-theme='light'] [hidden] {
  display: none !important;
}
html[data-theme='light'] mark {
  padding: 0.2em;
  background-color: #feffe6;
}
html[data-theme='light'] ::selection {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .clearfix {
  zoom: 1;
}
html[data-theme='light'] .clearfix::before,
html[data-theme='light'] .clearfix::after {
  display: table;
  content: '';
}
html[data-theme='light'] .clearfix::after {
  clear: both;
}
html[data-theme='light'] .anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html[data-theme='light'] .anticon > * {
  line-height: 1;
}
html[data-theme='light'] .anticon svg {
  display: inline-block;
}
html[data-theme='light'] .anticon::before {
  display: none;
}
html[data-theme='light'] .anticon .anticon-icon {
  display: block;
}
html[data-theme='light'] .anticon[tabindex] {
  cursor: pointer;
}
html[data-theme='light'] .anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
html[data-theme='light'] .anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
html[data-theme='light'] .fade-enter,
html[data-theme='light'] .fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .fade-enter.fade-enter-active,
html[data-theme='light'] .fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
html[data-theme='light'] .fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .fade-enter,
html[data-theme='light'] .fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
html[data-theme='light'] .fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html[data-theme='light'] .move-up-enter,
html[data-theme='light'] .move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-up-enter.move-up-enter-active,
html[data-theme='light'] .move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
html[data-theme='light'] .move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .move-up-enter,
html[data-theme='light'] .move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='light'] .move-down-enter,
html[data-theme='light'] .move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-down-enter.move-down-enter-active,
html[data-theme='light'] .move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
html[data-theme='light'] .move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .move-down-enter,
html[data-theme='light'] .move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='light'] .move-left-enter,
html[data-theme='light'] .move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-left-enter.move-left-enter-active,
html[data-theme='light'] .move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
html[data-theme='light'] .move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .move-left-enter,
html[data-theme='light'] .move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='light'] .move-right-enter,
html[data-theme='light'] .move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .move-right-enter.move-right-enter-active,
html[data-theme='light'] .move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
html[data-theme='light'] .move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .move-right-enter,
html[data-theme='light'] .move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
html[data-theme='light'] [ant-click-animating='true'],
html[data-theme='light'] [ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html[data-theme='light'] html {
  --antd-wave-shadow-color: #4261ff;
}
html[data-theme='light'] [ant-click-animating-without-extra-node='true']::after,
html[data-theme='light'] .ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #4261ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #4261ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
html[data-theme='light'] .slide-up-enter,
html[data-theme='light'] .slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-up-enter.slide-up-enter-active,
html[data-theme='light'] .slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
html[data-theme='light'] .slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .slide-up-enter,
html[data-theme='light'] .slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='light'] .slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='light'] .slide-down-enter,
html[data-theme='light'] .slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-down-enter.slide-down-enter-active,
html[data-theme='light'] .slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
html[data-theme='light'] .slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .slide-down-enter,
html[data-theme='light'] .slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='light'] .slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='light'] .slide-left-enter,
html[data-theme='light'] .slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-left-enter.slide-left-enter-active,
html[data-theme='light'] .slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
html[data-theme='light'] .slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .slide-left-enter,
html[data-theme='light'] .slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='light'] .slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='light'] .slide-right-enter,
html[data-theme='light'] .slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .slide-right-enter.slide-right-enter-active,
html[data-theme='light'] .slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
html[data-theme='light'] .slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .slide-right-enter,
html[data-theme='light'] .slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='light'] .slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
html[data-theme='light'] .swing-enter,
html[data-theme='light'] .swing-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .swing-enter.swing-enter-active,
html[data-theme='light'] .swing-appear.swing-appear-active {
  animation-name: antSwingIn;
  animation-play-state: running;
}
@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
html[data-theme='light'] .zoom-enter,
html[data-theme='light'] .zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-enter.zoom-enter-active,
html[data-theme='light'] .zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-enter,
html[data-theme='light'] .zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-big-enter,
html[data-theme='light'] .zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-big-enter.zoom-big-enter-active,
html[data-theme='light'] .zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-big-enter,
html[data-theme='light'] .zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-big-fast-enter,
html[data-theme='light'] .zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-big-fast-enter.zoom-big-fast-enter-active,
html[data-theme='light'] .zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-big-fast-enter,
html[data-theme='light'] .zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-up-enter,
html[data-theme='light'] .zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-up-enter.zoom-up-enter-active,
html[data-theme='light'] .zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-up-enter,
html[data-theme='light'] .zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-down-enter,
html[data-theme='light'] .zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-down-enter.zoom-down-enter-active,
html[data-theme='light'] .zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-down-enter,
html[data-theme='light'] .zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-left-enter,
html[data-theme='light'] .zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-left-enter.zoom-left-enter-active,
html[data-theme='light'] .zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-left-enter,
html[data-theme='light'] .zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .zoom-right-enter,
html[data-theme='light'] .zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .zoom-right-enter.zoom-right-enter-active,
html[data-theme='light'] .zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
html[data-theme='light'] .zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .zoom-right-enter,
html[data-theme='light'] .zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='light'] .zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
html[data-theme='light'] .ant-motion-collapse-legacy {
  overflow: hidden;
}
html[data-theme='light'] .ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
html[data-theme='light'] .ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
html[data-theme='light'] .ant-affix {
  position: fixed;
  z-index: 10;
}
html[data-theme='light'] .ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 8px 15px 8px 37px;
  border-radius: 2px;
}
html[data-theme='light'] .ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
html[data-theme='light'] .ant-alert.ant-alert-closable {
  padding-right: 30px;
}
html[data-theme='light'] .ant-alert-icon {
  position: absolute;
  top: 11.5px;
  left: 16px;
}
html[data-theme='light'] .ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='light'] .ant-alert-success {
  background-color: #f0f5ff;
  border: 1px solid #bdceff;
}
html[data-theme='light'] .ant-alert-success .ant-alert-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-alert-info {
  background-color: #f0f5ff;
  border: 1px solid #bdceff;
}
html[data-theme='light'] .ant-alert-info .ant-alert-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-alert-warning {
  background-color: #f0f5ff;
  border: 1px solid #e6ebf5;
}
html[data-theme='light'] .ant-alert-warning .ant-alert-icon {
  color: #babece;
}
html[data-theme='light'] .ant-alert-error {
  background-color: #fff0f1;
  border: 1px solid #ffb3bf;
}
html[data-theme='light'] .ant-alert-error .ant-alert-icon {
  color: #ff376b;
}
html[data-theme='light'] .ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}
html[data-theme='light'] .ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
html[data-theme='light'] .ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
html[data-theme='light'] .ant-alert-close-text {
  position: absolute;
  right: 16px;
}
html[data-theme='light'] .ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border-radius: 2px;
}
html[data-theme='light'] .ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
html[data-theme='light'] .ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
html[data-theme='light'] .ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
html[data-theme='light'] .ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
html[data-theme='light'] .ant-alert-with-description .ant-alert-description {
  display: block;
}
html[data-theme='light'] .ant-alert.ant-alert-close {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .ant-alert-slide-up-leave {
  animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
html[data-theme='light'] .ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
html[data-theme='light'] .ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
html[data-theme='light'] .ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
html[data-theme='light'] .ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
html[data-theme='light'] .ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
html[data-theme='light'] .ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #4261ff;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
html[data-theme='light'] .ant-anchor-ink-ball.visible {
  display: inline-block;
}
html[data-theme='light'] .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
html[data-theme='light'] .ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
html[data-theme='light'] .ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-anchor-link-active > .ant-anchor-link-title {
  color: #4261ff;
}
html[data-theme='light'] .ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
html[data-theme='light'] .ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 30px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-input {
  height: 30px;
  line-height: 1.5;
  background: transparent;
  border-width: 1px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-input:focus,
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 30px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select-lg .ant-input {
  height: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 30px;
}
html[data-theme='light'] .ant-select-auto-complete.ant-select-sm .ant-input {
  height: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
}
html[data-theme='light'] .ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}
html[data-theme='light'] .ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-avatar-image {
  background: transparent;
}
html[data-theme='light'] .ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='light'] .ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
html[data-theme='light'] .ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='light'] .ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
html[data-theme='light'] .ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='light'] .ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
html[data-theme='light'] .ant-avatar-square {
  border-radius: 2px;
}
html[data-theme='light'] .ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
html[data-theme='light'] .ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 1% no-repeat;
}
@media screen and (max-width: 768px) {
  html[data-theme='light'] .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  html[data-theme='light'] .ant-back-top {
    right: 20px;
  }
}
html[data-theme='light'] .ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
html[data-theme='light'] .ant-badge-count {
  z-index: 10;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff376b;
  border-radius: 10px;
  box-shadow: 0 0 0 1px none;
}
html[data-theme='light'] .ant-badge-count a,
html[data-theme='light'] .ant-badge-count a:hover {
  color: #fff;
}
html[data-theme='light'] .ant-badge-multiple-words {
  padding: 0 8px;
}
html[data-theme='light'] .ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #ff376b;
  border-radius: 100%;
  box-shadow: 0 0 0 1px none;
}
html[data-theme='light'] .ant-badge-count,
html[data-theme='light'] .ant-badge-dot,
html[data-theme='light'] .ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
html[data-theme='light'] .ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
html[data-theme='light'] .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
html[data-theme='light'] .ant-badge-status-success {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-badge-status-processing {
  position: relative;
  background-color: #4261ff;
}
html[data-theme='light'] .ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4261ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
html[data-theme='light'] .ant-badge-status-default {
  background-color: #d9d9d9;
}
html[data-theme='light'] .ant-badge-status-error {
  background-color: #ff376b;
}
html[data-theme='light'] .ant-badge-status-warning {
  background-color: #babece;
}
html[data-theme='light'] .ant-badge-status-pink {
  background: #eb2f96;
}
html[data-theme='light'] .ant-badge-status-magenta {
  background: #eb2f96;
}
html[data-theme='light'] .ant-badge-status-red {
  background: #f5222d;
}
html[data-theme='light'] .ant-badge-status-volcano {
  background: #fa541c;
}
html[data-theme='light'] .ant-badge-status-orange {
  background: #fa8c16;
}
html[data-theme='light'] .ant-badge-status-yellow {
  background: #fadb14;
}
html[data-theme='light'] .ant-badge-status-gold {
  background: #faad14;
}
html[data-theme='light'] .ant-badge-status-cyan {
  background: #13c2c2;
}
html[data-theme='light'] .ant-badge-status-lime {
  background: #a0d911;
}
html[data-theme='light'] .ant-badge-status-green {
  background: #52c41a;
}
html[data-theme='light'] .ant-badge-status-blue {
  background: #1890ff;
}
html[data-theme='light'] .ant-badge-status-geekblue {
  background: #2f54eb;
}
html[data-theme='light'] .ant-badge-status-purple {
  background: #722ed1;
}
html[data-theme='light'] .ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
html[data-theme='light'] .ant-badge-zoom-appear,
html[data-theme='light'] .ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
html[data-theme='light'] .ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
html[data-theme='light'] .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
html[data-theme='light'] .ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
html[data-theme='light'] .ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
html[data-theme='light'] .ant-scroll-number {
  overflow: hidden;
}
html[data-theme='light'] .ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
}
html[data-theme='light'] .ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
html[data-theme='light'] .ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-breadcrumb .anticon {
  font-size: 14px;
}
html[data-theme='light'] .ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
html[data-theme='light'] .ant-breadcrumb a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
html[data-theme='light'] .ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
html[data-theme='light'] .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
html[data-theme='light'] .ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .ant-btn > .anticon {
  line-height: 1;
}
html[data-theme='light'] .ant-btn,
html[data-theme='light'] .ant-btn:active,
html[data-theme='light'] .ant-btn:focus {
  outline: 0;
}
html[data-theme='light'] .ant-btn:not([disabled]):hover {
  text-decoration: none;
}
html[data-theme='light'] .ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn.disabled,
html[data-theme='light'] .ant-btn[disabled] {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-btn.disabled > *,
html[data-theme='light'] .ant-btn[disabled] > * {
  pointer-events: none;
}
html[data-theme='light'] .ant-btn-lg {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
html[data-theme='light'] .ant-btn-sm {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 3px;
}
html[data-theme='light'] .ant-btn > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn:hover,
html[data-theme='light'] .ant-btn:focus {
  color: #6b89ff;
  background-color: #fff;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn:hover > a:only-child,
html[data-theme='light'] .ant-btn:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn:hover > a:only-child::after,
html[data-theme='light'] .ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn:active,
html[data-theme='light'] .ant-btn.active {
  color: #2e44d9;
  background-color: #fff;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn:active > a:only-child,
html[data-theme='light'] .ant-btn.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn:active > a:only-child::after,
html[data-theme='light'] .ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-disabled,
html[data-theme='light'] .ant-btn.disabled,
html[data-theme='light'] .ant-btn[disabled],
html[data-theme='light'] .ant-btn-disabled:hover,
html[data-theme='light'] .ant-btn.disabled:hover,
html[data-theme='light'] .ant-btn[disabled]:hover,
html[data-theme='light'] .ant-btn-disabled:focus,
html[data-theme='light'] .ant-btn.disabled:focus,
html[data-theme='light'] .ant-btn[disabled]:focus,
html[data-theme='light'] .ant-btn-disabled:active,
html[data-theme='light'] .ant-btn.disabled:active,
html[data-theme='light'] .ant-btn[disabled]:active,
html[data-theme='light'] .ant-btn-disabled.active,
html[data-theme='light'] .ant-btn.disabled.active,
html[data-theme='light'] .ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-disabled > a:only-child,
html[data-theme='light'] .ant-btn.disabled > a:only-child,
html[data-theme='light'] .ant-btn[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn:hover,
html[data-theme='light'] .ant-btn:focus,
html[data-theme='light'] .ant-btn:active,
html[data-theme='light'] .ant-btn.active {
  text-decoration: none;
  background: #fff;
}
html[data-theme='light'] .ant-btn > i,
html[data-theme='light'] .ant-btn > span {
  display: inline-block;
  pointer-events: none;
}
html[data-theme='light'] .ant-btn-primary {
  color: #fff;
  background-color: #4261ff;
  border-color: #4261ff;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-primary > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-primary:hover,
html[data-theme='light'] .ant-btn-primary:focus {
  color: #fff;
  background-color: #6b89ff;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-primary:hover > a:only-child,
html[data-theme='light'] .ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-primary:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-primary:active,
html[data-theme='light'] .ant-btn-primary.active {
  color: #fff;
  background-color: #2e44d9;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn-primary:active > a:only-child,
html[data-theme='light'] .ant-btn-primary.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-primary:active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-primary-disabled,
html[data-theme='light'] .ant-btn-primary.disabled,
html[data-theme='light'] .ant-btn-primary[disabled],
html[data-theme='light'] .ant-btn-primary-disabled:hover,
html[data-theme='light'] .ant-btn-primary.disabled:hover,
html[data-theme='light'] .ant-btn-primary[disabled]:hover,
html[data-theme='light'] .ant-btn-primary-disabled:focus,
html[data-theme='light'] .ant-btn-primary.disabled:focus,
html[data-theme='light'] .ant-btn-primary[disabled]:focus,
html[data-theme='light'] .ant-btn-primary-disabled:active,
html[data-theme='light'] .ant-btn-primary.disabled:active,
html[data-theme='light'] .ant-btn-primary[disabled]:active,
html[data-theme='light'] .ant-btn-primary-disabled.active,
html[data-theme='light'] .ant-btn-primary.disabled.active,
html[data-theme='light'] .ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-primary-disabled > a:only-child,
html[data-theme='light'] .ant-btn-primary.disabled > a:only-child,
html[data-theme='light'] .ant-btn-primary[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-primary-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-primary.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-primary[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-primary-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-primary.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-primary[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-primary-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-primary.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-primary[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-primary-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-primary.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-primary-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-primary[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-primary-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-primary[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-primary-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-primary[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-primary-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #6b89ff;
  border-left-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #babece;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #babece;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
html[data-theme='light'] .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
html[data-theme='light'] .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #babece;
}
html[data-theme='light'] .ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #babece;
}
html[data-theme='light'] .ant-btn-ghost > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-ghost:hover,
html[data-theme='light'] .ant-btn-ghost:focus {
  color: #6b89ff;
  background-color: transparent;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-ghost:hover > a:only-child,
html[data-theme='light'] .ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-ghost:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-ghost:active,
html[data-theme='light'] .ant-btn-ghost.active {
  color: #2e44d9;
  background-color: transparent;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn-ghost:active > a:only-child,
html[data-theme='light'] .ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-ghost:active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-ghost-disabled,
html[data-theme='light'] .ant-btn-ghost.disabled,
html[data-theme='light'] .ant-btn-ghost[disabled],
html[data-theme='light'] .ant-btn-ghost-disabled:hover,
html[data-theme='light'] .ant-btn-ghost.disabled:hover,
html[data-theme='light'] .ant-btn-ghost[disabled]:hover,
html[data-theme='light'] .ant-btn-ghost-disabled:focus,
html[data-theme='light'] .ant-btn-ghost.disabled:focus,
html[data-theme='light'] .ant-btn-ghost[disabled]:focus,
html[data-theme='light'] .ant-btn-ghost-disabled:active,
html[data-theme='light'] .ant-btn-ghost.disabled:active,
html[data-theme='light'] .ant-btn-ghost[disabled]:active,
html[data-theme='light'] .ant-btn-ghost-disabled.active,
html[data-theme='light'] .ant-btn-ghost.disabled.active,
html[data-theme='light'] .ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-ghost-disabled > a:only-child,
html[data-theme='light'] .ant-btn-ghost.disabled > a:only-child,
html[data-theme='light'] .ant-btn-ghost[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-ghost-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-ghost.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-ghost[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-ghost-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-ghost.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-ghost[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-ghost-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-ghost.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-ghost[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-ghost-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-ghost.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-ghost-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #babece;
  border-style: dashed;
}
html[data-theme='light'] .ant-btn-dashed > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-dashed:hover,
html[data-theme='light'] .ant-btn-dashed:focus {
  color: #6b89ff;
  background-color: #fff;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-dashed:hover > a:only-child,
html[data-theme='light'] .ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-dashed:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-dashed:active,
html[data-theme='light'] .ant-btn-dashed.active {
  color: #2e44d9;
  background-color: #fff;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn-dashed:active > a:only-child,
html[data-theme='light'] .ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-dashed:active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-dashed-disabled,
html[data-theme='light'] .ant-btn-dashed.disabled,
html[data-theme='light'] .ant-btn-dashed[disabled],
html[data-theme='light'] .ant-btn-dashed-disabled:hover,
html[data-theme='light'] .ant-btn-dashed.disabled:hover,
html[data-theme='light'] .ant-btn-dashed[disabled]:hover,
html[data-theme='light'] .ant-btn-dashed-disabled:focus,
html[data-theme='light'] .ant-btn-dashed.disabled:focus,
html[data-theme='light'] .ant-btn-dashed[disabled]:focus,
html[data-theme='light'] .ant-btn-dashed-disabled:active,
html[data-theme='light'] .ant-btn-dashed.disabled:active,
html[data-theme='light'] .ant-btn-dashed[disabled]:active,
html[data-theme='light'] .ant-btn-dashed-disabled.active,
html[data-theme='light'] .ant-btn-dashed.disabled.active,
html[data-theme='light'] .ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-dashed-disabled > a:only-child,
html[data-theme='light'] .ant-btn-dashed.disabled > a:only-child,
html[data-theme='light'] .ant-btn-dashed[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-dashed-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-dashed.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-dashed[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-dashed-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-dashed.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-dashed[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-dashed-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-dashed.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-dashed[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-dashed-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-dashed.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-dashed-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-danger {
  color: #ff376b;
  background-color: #f5f5f5;
  border-color: #babece;
}
html[data-theme='light'] .ant-btn-danger > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-danger:hover {
  color: #fff;
  background-color: #ff6186;
  border-color: #ff6186;
}
html[data-theme='light'] .ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-danger:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-danger:focus {
  color: #ff6186;
  background-color: #fff;
  border-color: #ff6186;
}
html[data-theme='light'] .ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-danger:active,
html[data-theme='light'] .ant-btn-danger.active {
  color: #fff;
  background-color: #d9255b;
  border-color: #d9255b;
}
html[data-theme='light'] .ant-btn-danger:active > a:only-child,
html[data-theme='light'] .ant-btn-danger.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-danger:active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-danger-disabled,
html[data-theme='light'] .ant-btn-danger.disabled,
html[data-theme='light'] .ant-btn-danger[disabled],
html[data-theme='light'] .ant-btn-danger-disabled:hover,
html[data-theme='light'] .ant-btn-danger.disabled:hover,
html[data-theme='light'] .ant-btn-danger[disabled]:hover,
html[data-theme='light'] .ant-btn-danger-disabled:focus,
html[data-theme='light'] .ant-btn-danger.disabled:focus,
html[data-theme='light'] .ant-btn-danger[disabled]:focus,
html[data-theme='light'] .ant-btn-danger-disabled:active,
html[data-theme='light'] .ant-btn-danger.disabled:active,
html[data-theme='light'] .ant-btn-danger[disabled]:active,
html[data-theme='light'] .ant-btn-danger-disabled.active,
html[data-theme='light'] .ant-btn-danger.disabled.active,
html[data-theme='light'] .ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-danger-disabled > a:only-child,
html[data-theme='light'] .ant-btn-danger.disabled > a:only-child,
html[data-theme='light'] .ant-btn-danger[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-danger-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-danger.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-danger[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-danger-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-danger.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-danger[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-danger-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-danger.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-danger[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-danger-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-danger.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-danger-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-danger[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-danger-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-danger[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-danger-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-danger[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-danger-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-link {
  color: #4261ff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-link > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-link:hover,
html[data-theme='light'] .ant-btn-link:focus {
  color: #6b89ff;
  background-color: transparent;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-link:hover > a:only-child,
html[data-theme='light'] .ant-btn-link:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-link:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-link:active,
html[data-theme='light'] .ant-btn-link.active {
  color: #2e44d9;
  background-color: transparent;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn-link:active > a:only-child,
html[data-theme='light'] .ant-btn-link.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-link:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-link-disabled,
html[data-theme='light'] .ant-btn-link.disabled,
html[data-theme='light'] .ant-btn-link[disabled],
html[data-theme='light'] .ant-btn-link-disabled:hover,
html[data-theme='light'] .ant-btn-link.disabled:hover,
html[data-theme='light'] .ant-btn-link[disabled]:hover,
html[data-theme='light'] .ant-btn-link-disabled:focus,
html[data-theme='light'] .ant-btn-link.disabled:focus,
html[data-theme='light'] .ant-btn-link[disabled]:focus,
html[data-theme='light'] .ant-btn-link-disabled:active,
html[data-theme='light'] .ant-btn-link.disabled:active,
html[data-theme='light'] .ant-btn-link[disabled]:active,
html[data-theme='light'] .ant-btn-link-disabled.active,
html[data-theme='light'] .ant-btn-link.disabled.active,
html[data-theme='light'] .ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-link-disabled > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-link-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-link:hover,
html[data-theme='light'] .ant-btn-link:focus,
html[data-theme='light'] .ant-btn-link:active {
  border-color: transparent;
}
html[data-theme='light'] .ant-btn-link-disabled,
html[data-theme='light'] .ant-btn-link.disabled,
html[data-theme='light'] .ant-btn-link[disabled],
html[data-theme='light'] .ant-btn-link-disabled:hover,
html[data-theme='light'] .ant-btn-link.disabled:hover,
html[data-theme='light'] .ant-btn-link[disabled]:hover,
html[data-theme='light'] .ant-btn-link-disabled:focus,
html[data-theme='light'] .ant-btn-link.disabled:focus,
html[data-theme='light'] .ant-btn-link[disabled]:focus,
html[data-theme='light'] .ant-btn-link-disabled:active,
html[data-theme='light'] .ant-btn-link.disabled:active,
html[data-theme='light'] .ant-btn-link[disabled]:active,
html[data-theme='light'] .ant-btn-link-disabled.active,
html[data-theme='light'] .ant-btn-link.disabled.active,
html[data-theme='light'] .ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-link-disabled > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-link-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-link.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-link-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-round {
  height: 45px;
  padding: 0 22.5px;
  font-size: 16px;
  border-radius: 45px;
}
html[data-theme='light'] .ant-btn-round.ant-btn-lg {
  height: 45px;
  padding: 0 22.5px;
  font-size: 18px;
  border-radius: 45px;
}
html[data-theme='light'] .ant-btn-round.ant-btn-sm {
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 30px;
}
html[data-theme='light'] .ant-btn-circle,
html[data-theme='light'] .ant-btn-circle-outline {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-btn-circle.ant-btn-lg,
html[data-theme='light'] .ant-btn-circle-outline.ant-btn-lg {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 18px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-btn-circle.ant-btn-sm,
html[data-theme='light'] .ant-btn-circle-outline.ant-btn-sm {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
html[data-theme='light'] .ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-btn .anticon.anticon-plus > svg,
html[data-theme='light'] .ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
html[data-theme='light'] .ant-btn.ant-btn-loading {
  position: relative;
  pointer-events: none;
}
html[data-theme='light'] .ant-btn.ant-btn-loading::before {
  display: block;
}
html[data-theme='light'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
html[data-theme='light'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
html[data-theme='light'] .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
html[data-theme='light'] .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
html[data-theme='light'] .ant-btn-group {
  position: relative;
  display: inline-block;
}
html[data-theme='light'] .ant-btn-group > .ant-btn,
html[data-theme='light'] .ant-btn-group > span > .ant-btn {
  position: relative;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:hover,
html[data-theme='light'] .ant-btn-group > span > .ant-btn:hover,
html[data-theme='light'] .ant-btn-group > .ant-btn:focus,
html[data-theme='light'] .ant-btn-group > span > .ant-btn:focus,
html[data-theme='light'] .ant-btn-group > .ant-btn:active,
html[data-theme='light'] .ant-btn-group > span > .ant-btn:active,
html[data-theme='light'] .ant-btn-group > .ant-btn.active,
html[data-theme='light'] .ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:disabled,
html[data-theme='light'] .ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
html[data-theme='light'] .ant-btn-group-lg > .ant-btn,
html[data-theme='light'] .ant-btn-group-lg > span > .ant-btn {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 43px;
}
html[data-theme='light'] .ant-btn-group-sm > .ant-btn,
html[data-theme='light'] .ant-btn-group-sm > span > .ant-btn {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 28px;
}
html[data-theme='light'] .ant-btn-group-sm > .ant-btn > .anticon,
html[data-theme='light'] .ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
html[data-theme='light'] .ant-btn-group .ant-btn + .ant-btn,
html[data-theme='light'] .ant-btn + .ant-btn-group,
html[data-theme='light'] .ant-btn-group span + .ant-btn,
html[data-theme='light'] .ant-btn-group .ant-btn + span,
html[data-theme='light'] .ant-btn-group > span + span,
html[data-theme='light'] .ant-btn-group + .ant-btn,
html[data-theme='light'] .ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
html[data-theme='light'] .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
html[data-theme='light'] .ant-btn-group .ant-btn {
  border-radius: 0;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:first-child,
html[data-theme='light'] .ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
html[data-theme='light'] .ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:first-child:not(:last-child),
html[data-theme='light'] .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[data-theme='light'] .ant-btn-group > .ant-btn:last-child:not(:first-child),
html[data-theme='light'] .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
html[data-theme='light'] .ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 3px;
}
html[data-theme='light'] .ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 3px;
}
html[data-theme='light'] .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
html[data-theme='light'] .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
html[data-theme='light'] .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
html[data-theme='light'] .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
html[data-theme='light'] .ant-btn-group > .ant-btn-group {
  float: left;
}
html[data-theme='light'] .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
html[data-theme='light'] .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-btn:focus > span,
html[data-theme='light'] .ant-btn:active > span {
  position: relative;
}
html[data-theme='light'] .ant-btn > .anticon + span,
html[data-theme='light'] .ant-btn > span + .anticon {
  margin-left: 8px;
}
html[data-theme='light'] .ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary {
  color: #4261ff;
  background-color: transparent;
  border-color: #4261ff;
  text-shadow: none;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #6b89ff;
  background-color: transparent;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.active {
  color: #2e44d9;
  background-color: transparent;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled],
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger {
  color: #ff376b;
  background-color: transparent;
  border-color: #ff376b;
  text-shadow: none;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff6186;
  background-color: transparent;
  border-color: #ff6186;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9255b;
  background-color: transparent;
  border-color: #d9255b;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled],
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link {
  color: #4261ff;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:focus {
  color: #6b89ff;
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.active {
  color: #2e44d9;
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled],
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled.active,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
html[data-theme='light'] .ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
html[data-theme='light'] .ant-btn-block {
  width: 100%;
}
html[data-theme='light'] .ant-btn:empty {
  vertical-align: top;
}
html[data-theme='light'] a.ant-btn {
  line-height: 43px;
}
html[data-theme='light'] a.ant-btn-lg {
  line-height: 43px;
}
html[data-theme='light'] a.ant-btn-sm {
  line-height: 28px;
}
html[data-theme='light'] .ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid #babece;
  outline: none;
}
html[data-theme='light'] .ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
html[data-theme='light'] .ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
html[data-theme='light'] .ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
html[data-theme='light'] .ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
html[data-theme='light'] .ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
html[data-theme='light'] .ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
html[data-theme='light'] .ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
html[data-theme='light'] .ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
html[data-theme='light'] .ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
html[data-theme='light'] .ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
html[data-theme='light'] .ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
html[data-theme='light'] .ant-fullcalendar table,
html[data-theme='light'] .ant-fullcalendar th,
html[data-theme='light'] .ant-fullcalendar td {
  border: 0;
}
html[data-theme='light'] .ant-fullcalendar td {
  position: relative;
}
html[data-theme='light'] .ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
html[data-theme='light'] .ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
html[data-theme='light'] .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
html[data-theme='light'] .ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
html[data-theme='light'] .ant-fullcalendar-month,
html[data-theme='light'] .ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-fullcalendar-value:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-fullcalendar-value:active {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
html[data-theme='light'] .ant-fullcalendar-today .ant-fullcalendar-value,
html[data-theme='light'] .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #4261ff inset;
}
html[data-theme='light'] .ant-fullcalendar-selected-day .ant-fullcalendar-value,
html[data-theme='light'] .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
html[data-theme='light'] .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
html[data-theme='light'] .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
html[data-theme='light'] .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='light'] .ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen {
  border-top: 0;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 30px;
  line-height: 28px;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #e6edff;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #4261ff;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #4261ff;
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
html[data-theme='light'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
html[data-theme='light'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
html[data-theme='light'] .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
html[data-theme='light'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-card-hoverable {
  cursor: pointer;
}
html[data-theme='light'] .ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: none;
}
html[data-theme='light'] .ant-card-bordered {
  border: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
html[data-theme='light'] .ant-card-head::before,
html[data-theme='light'] .ant-card-head::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-head::after {
  clear: both;
}
html[data-theme='light'] .ant-card-head::before,
html[data-theme='light'] .ant-card-head::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-head::after {
  clear: both;
}
html[data-theme='light'] .ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
html[data-theme='light'] .ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
html[data-theme='light'] .ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
html[data-theme='light'] .ant-card-body {
  padding: 24px;
  zoom: 1;
}
html[data-theme='light'] .ant-card-body::before,
html[data-theme='light'] .ant-card-body::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-body::after {
  clear: both;
}
html[data-theme='light'] .ant-card-body::before,
html[data-theme='light'] .ant-card-body::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-body::after {
  clear: both;
}
html[data-theme='light'] .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
html[data-theme='light'] .ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-card-grid:hover {
  position: relative;
  z-index: 1;
  box-shadow: none;
}
html[data-theme='light'] .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
html[data-theme='light'] .ant-card-contain-tabs .ant-card-extra {
  padding-bottom: 0;
}
html[data-theme='light'] .ant-card-cover > * {
  display: block;
  width: 100%;
}
html[data-theme='light'] .ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
html[data-theme='light'] .ant-card-actions::before,
html[data-theme='light'] .ant-card-actions::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-actions::after {
  clear: both;
}
html[data-theme='light'] .ant-card-actions::before,
html[data-theme='light'] .ant-card-actions::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-actions::after {
  clear: both;
}
html[data-theme='light'] .ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
html[data-theme='light'] .ant-card-actions > li > span {
  position: relative;
  display: inline-block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
html[data-theme='light'] .ant-card-actions > li > span:hover {
  color: #4261ff;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
html[data-theme='light'] .ant-card-actions > li > span a {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
html[data-theme='light'] .ant-card-actions > li > span a:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
html[data-theme='light'] .ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
html[data-theme='light'] .ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
html[data-theme='light'] .ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
html[data-theme='light'] .ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
html[data-theme='light'] .ant-card-meta::before,
html[data-theme='light'] .ant-card-meta::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-meta::after {
  clear: both;
}
html[data-theme='light'] .ant-card-meta::before,
html[data-theme='light'] .ant-card-meta::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-card-meta::after {
  clear: both;
}
html[data-theme='light'] .ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
html[data-theme='light'] .ant-card-meta-detail {
  overflow: hidden;
}
html[data-theme='light'] .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
html[data-theme='light'] .ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-card-loading {
  overflow: hidden;
}
html[data-theme='light'] .ant-card-loading .ant-card-body {
  user-select: none;
}
html[data-theme='light'] .ant-card-loading-content p {
  margin: 0;
}
html[data-theme='light'] .ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
html[data-theme='light'] .ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
html[data-theme='light'] .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
html[data-theme='light'] .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
html[data-theme='light'] .ant-card-small > .ant-card-body {
  padding: 12px;
}
html[data-theme='light'] .ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
html[data-theme='light'] .ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
html[data-theme='light'] .ant-carousel .slick-list:focus {
  outline: none;
}
html[data-theme='light'] .ant-carousel .slick-list.dragging {
  cursor: pointer;
}
html[data-theme='light'] .ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
html[data-theme='light'] .ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
html[data-theme='light'] .ant-carousel .slick-slider .slick-track,
html[data-theme='light'] .ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
html[data-theme='light'] .ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
html[data-theme='light'] .ant-carousel .slick-track::before,
html[data-theme='light'] .ant-carousel .slick-track::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-carousel .slick-track::after {
  clear: both;
}
html[data-theme='light'] .slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
html[data-theme='light'] .ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
html[data-theme='light'] [dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
html[data-theme='light'] .ant-carousel .slick-slide img {
  display: block;
}
html[data-theme='light'] .ant-carousel .slick-slide.slick-loading img {
  display: none;
}
html[data-theme='light'] .ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
html[data-theme='light'] .ant-carousel .slick-initialized .slick-slide {
  display: block;
}
html[data-theme='light'] .ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
html[data-theme='light'] .ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
html[data-theme='light'] .ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
html[data-theme='light'] .ant-carousel .slick-prev,
html[data-theme='light'] .ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-carousel .slick-prev:hover,
html[data-theme='light'] .ant-carousel .slick-next:hover,
html[data-theme='light'] .ant-carousel .slick-prev:focus,
html[data-theme='light'] .ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
html[data-theme='light'] .ant-carousel .slick-prev:hover::before,
html[data-theme='light'] .ant-carousel .slick-next:hover::before,
html[data-theme='light'] .ant-carousel .slick-prev:focus::before,
html[data-theme='light'] .ant-carousel .slick-next:focus::before {
  opacity: 1;
}
html[data-theme='light'] .ant-carousel .slick-prev.slick-disabled::before,
html[data-theme='light'] .ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
html[data-theme='light'] .ant-carousel .slick-prev {
  left: -25px;
}
html[data-theme='light'] .ant-carousel .slick-prev::before {
  content: '←';
}
html[data-theme='light'] .ant-carousel .slick-next {
  right: -25px;
}
html[data-theme='light'] .ant-carousel .slick-next::before {
  content: '→';
}
html[data-theme='light'] .ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
html[data-theme='light'] .ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
html[data-theme='light'] .ant-carousel .slick-dots-top {
  top: 12px;
}
html[data-theme='light'] .ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
html[data-theme='light'] .ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
html[data-theme='light'] .ant-carousel .slick-dots li button:hover,
html[data-theme='light'] .ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
html[data-theme='light'] .ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
html[data-theme='light'] .ant-carousel .slick-dots li.slick-active button:hover,
html[data-theme='light'] .ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  transform: translateY(-50%);
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
html[data-theme='light'] .ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
html[data-theme='light'] .ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  background-color: transparent !important;
  cursor: pointer;
}
html[data-theme='light'] .ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
html[data-theme='light'] .ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
html[data-theme='light'] .ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
html[data-theme='light'] .ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
html[data-theme='light'] .ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  transition: transform 0.2s;
}
html[data-theme='light'] .ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  transform: rotate(180deg);
}
html[data-theme='light'] .ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-cascader-picker-small .ant-cascader-picker-clear,
html[data-theme='light'] .ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
html[data-theme='light'] .ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-cascader-menus ul,
html[data-theme='light'] .ant-cascader-menus ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-cascader-menus-empty,
html[data-theme='light'] .ant-cascader-menus-hidden {
  display: none;
}
html[data-theme='light'] .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
html[data-theme='light'] .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
html[data-theme='light'] .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
html[data-theme='light'] .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
html[data-theme='light'] .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
html[data-theme='light'] .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
html[data-theme='light'] .ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html[data-theme='light'] .ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px;
}
html[data-theme='light'] .ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0;
}
html[data-theme='light'] .ant-cascader-menu:only-child {
  border-radius: 2px;
}
html[data-theme='light'] .ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-cascader-menu-item:hover {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
html[data-theme='light'] .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
html[data-theme='light'] .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='light'] .ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
html[data-theme='light'] .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
html[data-theme='light'] .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
html[data-theme='light'] :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff376b;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
html[data-theme='light'] .ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-checkbox-wrapper:hover .ant-checkbox-inner,
html[data-theme='light'] .ant-checkbox:hover .ant-checkbox-inner,
html[data-theme='light'] .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4261ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='light'] .ant-checkbox:hover::after,
html[data-theme='light'] .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
html[data-theme='light'] .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='light'] .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='light'] .ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #babece !important;
}
html[data-theme='light'] .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='light'] .ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='light'] .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='light'] .ant-checkbox-wrapper + span,
html[data-theme='light'] .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='light'] .ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='light'] .ant-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='light'] .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4261ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='light'] .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #babece;
  border-bottom: 0;
  border-radius: 2px;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #babece;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item:last-child,
html[data-theme='light'] .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 30px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  line-height: 46px;
  transform: translateY(-50%);
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
html[data-theme='light'] .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
html[data-theme='light'] .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 30px;
  padding-right: 40px;
}
html[data-theme='light'] .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: initial;
}
html[data-theme='light'] .ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html[data-theme='light'] .ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #babece;
}
html[data-theme='light'] .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 30px 30px;
}
html[data-theme='light'] .ant-collapse-content-inactive {
  display: none;
}
html[data-theme='light'] .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-collapse-borderless {
  background-color: #fff;
  border: 0;
}
html[data-theme='light'] .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #babece;
}
html[data-theme='light'] .ant-collapse-borderless > .ant-collapse-item:last-child,
html[data-theme='light'] .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
html[data-theme='light'] .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
html[data-theme='light'] .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
html[data-theme='light'] .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
html[data-theme='light'] .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-comment {
  position: relative;
}
html[data-theme='light'] .ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
html[data-theme='light'] .ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
html[data-theme='light'] .ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
html[data-theme='light'] .ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
html[data-theme='light'] .ant-comment-content-author {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
html[data-theme='light'] .ant-comment-content-author > a,
html[data-theme='light'] .ant-comment-content-author > span {
  height: 18px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
html[data-theme='light'] .ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
html[data-theme='light'] .ant-comment-content-detail p {
  white-space: pre-wrap;
}
html[data-theme='light'] .ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
html[data-theme='light'] .ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
html[data-theme='light'] .ant-comment-actions > li > span:hover {
  color: #595959;
}
html[data-theme='light'] .ant-comment-nested {
  margin-left: 44px;
}
html[data-theme='light'] .ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='light'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='light'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='light'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='light'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='light'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='light'] .ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
html[data-theme='light'] .ant-calendar-picker-input {
  outline: none;
}
html[data-theme='light'] .ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
html[data-theme='light'] .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-calendar-picker-clear,
html[data-theme='light'] .ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='light'] .ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
html[data-theme='light'] .ant-calendar-picker-small .ant-calendar-picker-clear,
html[data-theme='light'] .ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
html[data-theme='light'] .ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-input {
  width: 100%;
  height: 22px;
  color: #25282e;
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
html[data-theme='light'] .ant-calendar-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-week-number {
  width: 286px;
}
html[data-theme='light'] .ant-calendar-week-number-cell {
  text-align: center;
}
html[data-theme='light'] .ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-header a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-century-select,
html[data-theme='light'] .ant-calendar-header .ant-calendar-decade-select,
html[data-theme='light'] .ant-calendar-header .ant-calendar-year-select,
html[data-theme='light'] .ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-century-select-arrow,
html[data-theme='light'] .ant-calendar-header .ant-calendar-decade-select-arrow,
html[data-theme='light'] .ant-calendar-header .ant-calendar-year-select-arrow,
html[data-theme='light'] .ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-century-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-century-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-decade-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-decade-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-month-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-month-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-century-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-decade-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-century-btn::after,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-decade-btn::after,
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-year-btn::after {
  content: '«';
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-century-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-decade-btn,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-century-btn::after,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-decade-btn::after,
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-year-btn::after {
  content: '»';
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-prev-month-btn::after {
  content: '‹';
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
}
html[data-theme='light'] .ant-calendar-header .ant-calendar-next-month-btn::after {
  content: '›';
}
html[data-theme='light'] .ant-calendar-body {
  padding: 8px 12px;
}
html[data-theme='light'] .ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
html[data-theme='light'] .ant-calendar table,
html[data-theme='light'] .ant-calendar th,
html[data-theme='light'] .ant-calendar td {
  text-align: center;
  border: 0;
}
html[data-theme='light'] .ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
html[data-theme='light'] .ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
html[data-theme='light'] .ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
html[data-theme='light'] .ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
html[data-theme='light'] .ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
html[data-theme='light'] .ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-calendar-date-panel {
  position: relative;
  outline: none;
}
html[data-theme='light'] .ant-calendar-date:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-calendar-date:active {
  color: #fff;
  background: #6b89ff;
}
html[data-theme='light'] .ant-calendar-today .ant-calendar-date {
  color: #4261ff;
  font-weight: bold;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='light'] .ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-calendar-selected-day .ant-calendar-date {
  background: #d9dfff;
}
html[data-theme='light'] .ant-calendar-selected-date .ant-calendar-date,
html[data-theme='light'] .ant-calendar-selected-start-date .ant-calendar-date,
html[data-theme='light'] .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #4261ff;
  border: 1px solid transparent;
}
html[data-theme='light'] .ant-calendar-selected-date .ant-calendar-date:hover,
html[data-theme='light'] .ant-calendar-selected-start-date .ant-calendar-date:hover,
html[data-theme='light'] .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
html[data-theme='light'] .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: '';
}
html[data-theme='light'] .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
html[data-theme='light'] .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
html[data-theme='light'] .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[data-theme='light'] .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
html[data-theme='light'] .ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-footer:empty {
  border-top: 0;
}
html[data-theme='light'] .ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
html[data-theme='light'] .ant-calendar-footer-extra {
  text-align: left;
}
html[data-theme='light'] .ant-calendar .ant-calendar-today-btn,
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
html[data-theme='light'] .ant-calendar .ant-calendar-today-btn-disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar .ant-calendar-today-btn:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
html[data-theme='light'] .ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 45px;
  padding: 0 15px;
  border-radius: 4px;
  color: #fff;
  background-color: #4261ff;
  border-color: #4261ff;
  text-shadow: none;
  box-shadow: none;
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 28px;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled > *,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-lg {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-sm {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 3px;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #6b89ff;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #2e44d9;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled],
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled],
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
html[data-theme='light'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='light'] .ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
html[data-theme='light'] .ant-calendar-range-picker-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range-picker-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range-picker-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
html[data-theme='light'] .ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
html[data-theme='light'] .ant-calendar-range-part {
  position: relative;
  width: 50%;
}
html[data-theme='light'] .ant-calendar-range-left {
  float: left;
}
html[data-theme='light'] .ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-range-right {
  float: right;
}
html[data-theme='light'] .ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  transform: translateX(-50%);
  pointer-events: none;
}
html[data-theme='light'] .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  transform: translateX(-50%);
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
  height: 30px;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input::-moz-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input::-moz-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:-moz-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:hover,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:focus,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input-disabled,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input-disabled:hover,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input[disabled],
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input[disabled]:hover,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-calendar-range .ant-calendar-input,
html[data-theme='light'] textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input-lg,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input-sm,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-input:focus,
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-year-panel,
html[data-theme='light'] .ant-calendar-range .ant-calendar-month-panel,
html[data-theme='light'] .ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-decade-panel-table,
html[data-theme='light'] .ant-calendar-range .ant-calendar-year-panel-table,
html[data-theme='light'] .ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #f0f5ff;
  border: 0;
  border-radius: 0;
  content: '';
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
html[data-theme='light'] div.ant-calendar-range-quick-selector {
  text-align: left;
}
html[data-theme='light'] div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-header,
html[data-theme='light'] .ant-calendar-range .ant-calendar-month-panel-header,
html[data-theme='light'] .ant-calendar-range .ant-calendar-year-panel-header {
  border-bottom: 0;
}
html[data-theme='light'] .ant-calendar-range .ant-calendar-body,
html[data-theme='light'] .ant-calendar-range .ant-calendar-month-panel-body,
html[data-theme='light'] .ant-calendar-range .ant-calendar-year-panel-body {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
html[data-theme='light'] .ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
html[data-theme='light'] .ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
html[data-theme='light'] .ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
html[data-theme='light'] .ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
html[data-theme='light'] .ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
html[data-theme='light'] .ant-calendar-time-picker-combobox {
  width: 100%;
}
html[data-theme='light'] .ant-calendar-time-picker-column-1,
html[data-theme='light'] .ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
html[data-theme='light'] .ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
html[data-theme='light'] .ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
html[data-theme='light'] .ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
html[data-theme='light'] .ant-calendar-time-picker-input-wrap {
  display: none;
}
html[data-theme='light'] .ant-calendar-time-picker-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
html[data-theme='light'] .ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
html[data-theme='light'] .ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
html[data-theme='light'] .ant-calendar-time-picker-select ul {
  box-sizing: border-box;
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-calendar-time-picker-select li {
  box-sizing: content-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding-left: 32px;
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  transition: background 0.3s ease;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
html[data-theme='light'] .ant-calendar-time-picker-select li:hover {
  background: #f0f5ff;
}
html[data-theme='light'] li.ant-calendar-time-picker-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
html[data-theme='light'] li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='light'] .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 2px;
  outline: none;
}
html[data-theme='light'] .ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
html[data-theme='light'] .ant-calendar-month-panel-hidden {
  display: none;
}
html[data-theme='light'] .ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-month-panel-header a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
}
html[data-theme='light'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='light'] .ant-calendar-month-panel-body {
  flex: 1;
}
html[data-theme='light'] .ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='light'] .ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='light'] .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-month-panel-cell {
  text-align: center;
}
html[data-theme='light'] .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
html[data-theme='light'] .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-calendar-month-panel-month:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 2px;
  outline: none;
}
html[data-theme='light'] .ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
html[data-theme='light'] .ant-calendar-year-panel-hidden {
  display: none;
}
html[data-theme='light'] .ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-year-panel-header a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
}
html[data-theme='light'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='light'] .ant-calendar-year-panel-body {
  flex: 1;
}
html[data-theme='light'] .ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='light'] .ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='light'] .ant-calendar-year-panel-cell {
  text-align: center;
}
html[data-theme='light'] .ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-calendar-year-panel-year:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
html[data-theme='light'] .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}
html[data-theme='light'] .ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  outline: none;
}
html[data-theme='light'] .ant-calendar-decade-panel-hidden {
  display: none;
}
html[data-theme='light'] .ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='light'] .ant-calendar-decade-panel-header a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
}
html[data-theme='light'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='light'] .ant-calendar-decade-panel-body {
  flex: 1;
}
html[data-theme='light'] .ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='light'] .ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='light'] .ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
html[data-theme='light'] .ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-calendar-decade-panel-decade:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
html[data-theme='light'] .ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}
html[data-theme='light'] .ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
html[data-theme='light'] .ant-calendar-month .ant-calendar-month-panel,
html[data-theme='light'] .ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
html[data-theme='light'] .ant-calendar-week-number-cell {
  opacity: 0.5;
}
html[data-theme='light'] .ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #e6edff;
}
html[data-theme='light'] .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
html[data-theme='light'] .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}
html[data-theme='light'] .ant-descriptions-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
html[data-theme='light'] .ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}
html[data-theme='light'] .ant-descriptions-view table {
  width: 100%;
}
html[data-theme='light'] .ant-descriptions-row > td {
  padding-bottom: 16px;
}
html[data-theme='light'] .ant-descriptions-row:last-child {
  border-bottom: none;
}
html[data-theme='light'] .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}
html[data-theme='light'] .ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ':';
}
html[data-theme='light'] .ant-descriptions-item-content {
  display: table-cell;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
html[data-theme='light'] .ant-descriptions-item {
  padding-bottom: 0;
}
html[data-theme='light'] .ant-descriptions-item > span {
  display: inline-block;
}
html[data-theme='light'] .ant-descriptions-item .ant-descriptions-item-label {
  float: left;
  padding: 0 !important;
}
html[data-theme='light'] .ant-descriptions-item .ant-descriptions-item-content {
  float: left;
  padding: 0 !important;
}
html[data-theme='light'] .ant-descriptions .ant-descriptions-item-label,
html[data-theme='light'] .ant-descriptions .ant-descriptions-item-content {
  padding: 16px 24px;
}
html[data-theme='light'] .ant-descriptions.bordered.middle .ant-descriptions-item-label,
html[data-theme='light'] .ant-descriptions.bordered.middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
html[data-theme='light'] .ant-descriptions.bordered.small .ant-descriptions-item-label,
html[data-theme='light'] .ant-descriptions.bordered.small .ant-descriptions-item-content {
  padding: 8px 16px;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-label,
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-content {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-label:last-child,
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
html[data-theme='light'] .ant-descriptions.bordered .ant-descriptions-item-label::after {
  display: none;
}
html[data-theme='light'] .ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #e8e8e8;
}
html[data-theme='light'] .ant-divider,
html[data-theme='light'] .ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
html[data-theme='light'] .ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text::after,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left::after,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
  content: '';
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
html[data-theme='light'] .ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
html[data-theme='light'] .ant-divider-dashed {
  background: none;
  border-top: 1px dashed #e8e8e8;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
html[data-theme='light'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
html[data-theme='light'] .ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
}
html[data-theme='light'] .ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
html[data-theme='light'] .ant-drawer-content-wrapper {
  position: fixed;
}
html[data-theme='light'] .ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-drawer-left,
html[data-theme='light'] .ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
html[data-theme='light'] .ant-drawer-left .ant-drawer-content-wrapper,
html[data-theme='light'] .ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
html[data-theme='light'] .ant-drawer-left.ant-drawer-open,
html[data-theme='light'] .ant-drawer-right.ant-drawer-open {
  width: 100%;
}
html[data-theme='light'] .ant-drawer-left.ant-drawer-open.no-mask,
html[data-theme='light'] .ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
html[data-theme='light'] .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='light'] .ant-drawer-right {
  right: 0;
}
html[data-theme='light'] .ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
html[data-theme='light'] .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='light'] .ant-drawer-top,
html[data-theme='light'] .ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
html[data-theme='light'] .ant-drawer-top .ant-drawer-content-wrapper,
html[data-theme='light'] .ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
html[data-theme='light'] .ant-drawer-top.ant-drawer-open,
html[data-theme='light'] .ant-drawer-bottom.ant-drawer-open {
  height: 100%;
}
html[data-theme='light'] .ant-drawer-top.ant-drawer-open.no-mask,
html[data-theme='light'] .ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
html[data-theme='light'] .ant-drawer-top {
  top: 0;
}
html[data-theme='light'] .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='light'] .ant-drawer-bottom {
  bottom: 0;
}
html[data-theme='light'] .ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
html[data-theme='light'] .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='light'] .ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 0.3;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
html[data-theme='light'] .ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
html[data-theme='light'] .ant-drawer-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
html[data-theme='light'] .ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
html[data-theme='light'] .ant-drawer-close:focus,
html[data-theme='light'] .ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
html[data-theme='light'] .ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
html[data-theme='light'] .ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
html[data-theme='light'] .ant-drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(37, 40, 46, 0.5);
  opacity: 0;
  filter: alpha(opacity=50);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
html[data-theme='light'] .ant-drawer-open {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
html[data-theme='light'] .ant-drawer-open-content {
  box-shadow: none;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
html[data-theme='light'] .ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
html[data-theme='light'] .ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
html[data-theme='light'] .ant-dropdown-wrap {
  position: relative;
}
html[data-theme='light'] .ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] :root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
html[data-theme='light'] .ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
html[data-theme='light'] .ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
html[data-theme='light'] .ant-dropdown-hidden,
html[data-theme='light'] .ant-dropdown-menu-hidden {
  display: none;
}
html[data-theme='light'] .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  -webkit-transform: translate3d(0, 0, 0);
}
html[data-theme='light'] .ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
html[data-theme='light'] .ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
html[data-theme='light'] .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  transform-origin: 0 0;
}
html[data-theme='light'] .ant-dropdown-menu-item,
html[data-theme='light'] .ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-dropdown-menu-item > .anticon:first-child,
html[data-theme='light'] .ant-dropdown-menu-submenu-title > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
}
html[data-theme='light'] .ant-dropdown-menu-item > a,
html[data-theme='light'] .ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
html[data-theme='light'] .ant-dropdown-menu-item-selected,
html[data-theme='light'] .ant-dropdown-menu-submenu-title-selected,
html[data-theme='light'] .ant-dropdown-menu-item-selected > a,
html[data-theme='light'] .ant-dropdown-menu-submenu-title-selected > a {
  color: #4261ff;
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-dropdown-menu-item:hover,
html[data-theme='light'] .ant-dropdown-menu-submenu-title:hover {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-dropdown-menu-item-disabled,
html[data-theme='light'] .ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-dropdown-menu-item-disabled:hover,
html[data-theme='light'] .ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-dropdown-menu-item-divider,
html[data-theme='light'] .ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
html[data-theme='light'] .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
html[data-theme='light'] .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
html[data-theme='light'] .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] :root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
html[data-theme='light'] :root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
html[data-theme='light'] .ant-dropdown-menu-submenu-vertical {
  position: relative;
}
html[data-theme='light'] .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
html[data-theme='light'] .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
html[data-theme='light'] .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
html[data-theme='light'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
html[data-theme='light'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
html[data-theme='light'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
html[data-theme='light'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
html[data-theme='light'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='light'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
html[data-theme='light'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
html[data-theme='light'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
html[data-theme='light'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
html[data-theme='light'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
html[data-theme='light'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='light'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
html[data-theme='light'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
html[data-theme='light'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='light'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
html[data-theme='light'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
html[data-theme='light'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='light'] .ant-dropdown-trigger > .anticon.anticon-down,
html[data-theme='light'] .ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] :root .ant-dropdown-trigger > .anticon.anticon-down,
html[data-theme='light'] :root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
html[data-theme='light'] .ant-dropdown-button {
  white-space: nowrap;
}
html[data-theme='light'] .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] :root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
html[data-theme='light'] .ant-dropdown-menu-dark,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
html[data-theme='light'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #4261ff;
}
html[data-theme='light'] .ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
html[data-theme='light'] .ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
html[data-theme='light'] .ant-empty-image img {
  height: 100%;
}
html[data-theme='light'] .ant-empty-description {
  margin: 0;
}
html[data-theme='light'] .ant-empty-footer {
  margin-top: 16px;
}
html[data-theme='light'] .ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-empty-normal .ant-empty-image {
  height: 40px;
}
html[data-theme='light'] .ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-empty-small .ant-empty-image {
  height: 35px;
}
html[data-theme='light'] .ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #babece;
}
html[data-theme='light'] .ant-form label {
  font-size: 14px;
}
html[data-theme='light'] .ant-form input[type='search'] {
  box-sizing: border-box;
}
html[data-theme='light'] .ant-form input[type='radio'],
html[data-theme='light'] .ant-form input[type='checkbox'] {
  line-height: normal;
}
html[data-theme='light'] .ant-form input[type='file'] {
  display: block;
}
html[data-theme='light'] .ant-form input[type='range'] {
  display: block;
  width: 100%;
}
html[data-theme='light'] .ant-form select[multiple],
html[data-theme='light'] .ant-form select[size] {
  height: auto;
}
html[data-theme='light'] .ant-form input[type='file']:focus,
html[data-theme='light'] .ant-form input[type='radio']:focus,
html[data-theme='light'] .ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
html[data-theme='light'] .ant-form output {
  display: block;
  padding-top: 15px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
}
html[data-theme='light'] .ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff376b;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
html[data-theme='light'] .ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
html[data-theme='light'] .ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}
html[data-theme='light'] .ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
html[data-theme='light'] .ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
html[data-theme='light'] input[type='radio'][disabled],
html[data-theme='light'] input[type='checkbox'][disabled],
html[data-theme='light'] input[type='radio'].disabled,
html[data-theme='light'] input[type='checkbox'].disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-radio-inline.disabled,
html[data-theme='light'] .ant-radio-vertical.disabled,
html[data-theme='light'] .ant-checkbox-inline.disabled,
html[data-theme='light'] .ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-radio.disabled label,
html[data-theme='light'] .ant-checkbox.disabled label {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
html[data-theme='light'] .ant-form-item label {
  position: relative;
}
html[data-theme='light'] .ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
html[data-theme='light'] .ant-form-item-control {
  position: relative;
  line-height: 30px;
  zoom: 1;
}
html[data-theme='light'] .ant-form-item-control::before,
html[data-theme='light'] .ant-form-item-control::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-form-item-control::after {
  clear: both;
}
html[data-theme='light'] .ant-form-item-control::before,
html[data-theme='light'] .ant-form-item-control::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-form-item-control::after {
  clear: both;
}
html[data-theme='light'] .ant-form-item-children {
  position: relative;
}
html[data-theme='light'] .ant-form-item-with-help {
  margin-bottom: 1px;
}
html[data-theme='light'] .ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 29.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
html[data-theme='light'] .ant-form-item-label-left {
  text-align: left;
}
html[data-theme='light'] .ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
html[data-theme='light'] .ant-form-explain,
html[data-theme='light'] .ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html[data-theme='light'] .ant-form-explain {
  margin-bottom: -1px;
}
html[data-theme='light'] .ant-form-extra {
  padding-top: 4px;
}
html[data-theme='light'] .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
html[data-theme='light'] .ant-form-split {
  display: block;
  text-align: center;
}
html[data-theme='light'] form .has-feedback .ant-input {
  padding-right: 24px;
}
html[data-theme='light'] form .has-feedback .ant-input-password-icon {
  margin-right: 18px;
}
html[data-theme='light'] form .has-feedback > .ant-select .ant-select-arrow,
html[data-theme='light'] form .has-feedback > .ant-select .ant-select-selection__clear,
html[data-theme='light'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
html[data-theme='light'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
html[data-theme='light'] form .has-feedback > .ant-select .ant-select-selection-selected-value,
html[data-theme='light'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
html[data-theme='light'] form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
html[data-theme='light'] form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
html[data-theme='light'] form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
html[data-theme='light'] form .has-feedback .ant-calendar-picker-icon,
html[data-theme='light'] form .has-feedback .ant-time-picker-icon,
html[data-theme='light'] form .has-feedback .ant-calendar-picker-clear,
html[data-theme='light'] form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
html[data-theme='light'] form .ant-mentions,
html[data-theme='light'] form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
html[data-theme='light'] form .ant-upload {
  background: transparent;
}
html[data-theme='light'] form input[type='radio'],
html[data-theme='light'] form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
html[data-theme='light'] form .ant-radio-inline,
html[data-theme='light'] form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
html[data-theme='light'] form .ant-radio-inline:first-child,
html[data-theme='light'] form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
html[data-theme='light'] form .ant-checkbox-vertical,
html[data-theme='light'] form .ant-radio-vertical {
  display: block;
}
html[data-theme='light'] form .ant-checkbox-vertical + .ant-checkbox-vertical,
html[data-theme='light'] form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
html[data-theme='light'] form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
html[data-theme='light'] form .ant-input-number-handler-wrap {
  z-index: 2;
}
html[data-theme='light'] form .ant-select,
html[data-theme='light'] form .ant-cascader-picker {
  width: 100%;
}
html[data-theme='light'] form .ant-input-group .ant-select,
html[data-theme='light'] form .ant-input-group .ant-cascader-picker {
  width: auto;
}
html[data-theme='light'] form :not(.ant-input-group-wrapper) > .ant-input-group,
html[data-theme='light'] form .ant-input-group-wrapper {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
}
html[data-theme='light'] .ant-input-group-wrap .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-input-group-wrap .ant-select-selection:hover {
  border-color: #babece;
}
html[data-theme='light'] .ant-input-group-wrap .ant-select-selection--single {
  height: 30px;
  margin-left: -1px;
  background-color: rgba(0, 0, 0, 0.07);
}
html[data-theme='light'] .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 25px;
  padding-left: 8px;
  line-height: 30px;
}
html[data-theme='light'] .ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: #babece;
  box-shadow: none;
}
html[data-theme='light'] .ant-form-vertical .ant-form-item-label,
html[data-theme='light'] .ant-col-24.ant-form-item-label,
html[data-theme='light'] .ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}
html[data-theme='light'] .ant-form-vertical .ant-form-item-label label::after,
html[data-theme='light'] .ant-col-24.ant-form-item-label label::after,
html[data-theme='light'] .ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
html[data-theme='light'] .ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
html[data-theme='light'] .ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
html[data-theme='light'] .ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
html[data-theme='light'] .ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  html[data-theme='light'] .ant-form-item-label,
  html[data-theme='light'] .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  html[data-theme='light'] .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-form-item-label label::after {
    display: none;
  }
  html[data-theme='light'] .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  html[data-theme='light'] .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  html[data-theme='light'] .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  html[data-theme='light'] .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  html[data-theme='light'] .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='light'] .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
html[data-theme='light'] .ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
html[data-theme='light'] .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
html[data-theme='light'] .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
html[data-theme='light'] .ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='light'] .ant-form-inline .ant-form-text {
  display: inline-block;
}
html[data-theme='light'] .ant-form-inline .has-feedback {
  display: inline-block;
}
html[data-theme='light'] .has-success.has-feedback .ant-form-item-children-icon,
html[data-theme='light'] .has-warning.has-feedback .ant-form-item-children-icon,
html[data-theme='light'] .has-error.has-feedback .ant-form-item-children-icon,
html[data-theme='light'] .is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
html[data-theme='light'] .has-success.has-feedback .ant-form-item-children-icon svg,
html[data-theme='light'] .has-warning.has-feedback .ant-form-item-children-icon svg,
html[data-theme='light'] .has-error.has-feedback .ant-form-item-children-icon svg,
html[data-theme='light'] .is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .has-success.has-feedback .ant-form-item-children-icon {
  color: #4261ff;
  animation-name: diffZoomIn1 !important;
}
html[data-theme='light'] .has-warning .ant-form-explain,
html[data-theme='light'] .has-warning .ant-form-split {
  color: #babece;
}
html[data-theme='light'] .has-warning .ant-input,
html[data-theme='light'] .has-warning .ant-input:hover {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-input:focus {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-warning .ant-input:not([disabled]):hover {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-warning .ant-input-affix-wrapper .ant-input,
html[data-theme='light'] .has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-input-prefix {
  color: #babece;
}
html[data-theme='light'] .has-warning .ant-input-group-addon {
  color: #babece;
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .has-warning .has-feedback {
  color: #babece;
}
html[data-theme='light'] .has-warning.has-feedback .ant-form-item-children-icon {
  color: #babece;
  animation-name: diffZoomIn3 !important;
}
html[data-theme='light'] .has-warning .ant-select-selection {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-select-selection:hover {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-select-open .ant-select-selection,
html[data-theme='light'] .has-warning .ant-select-focused .ant-select-selection {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-warning .ant-calendar-picker-icon::after,
html[data-theme='light'] .has-warning .ant-time-picker-icon::after,
html[data-theme='light'] .has-warning .ant-picker-icon::after,
html[data-theme='light'] .has-warning .ant-select-arrow,
html[data-theme='light'] .has-warning .ant-cascader-picker-arrow {
  color: #babece;
}
html[data-theme='light'] .has-warning .ant-input-number,
html[data-theme='light'] .has-warning .ant-time-picker-input {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-input-number-focused,
html[data-theme='light'] .has-warning .ant-time-picker-input-focused,
html[data-theme='light'] .has-warning .ant-input-number:focus,
html[data-theme='light'] .has-warning .ant-time-picker-input:focus {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-warning .ant-input-number:not([disabled]):hover,
html[data-theme='light'] .has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #babece;
}
html[data-theme='light'] .has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ced1db;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(186, 190, 206, 0.2);
}
html[data-theme='light'] .has-error .ant-form-explain,
html[data-theme='light'] .has-error .ant-form-split {
  color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input,
html[data-theme='light'] .has-error .ant-input:hover {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-input:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-input-affix-wrapper .ant-input,
html[data-theme='light'] .has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-prefix {
  color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-group-addon {
  color: #ff376b;
  background-color: #fff;
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .has-feedback {
  color: #ff376b;
}
html[data-theme='light'] .has-error.has-feedback .ant-form-item-children-icon {
  color: #ff376b;
  animation-name: diffZoomIn2 !important;
}
html[data-theme='light'] .has-error .ant-select-selection {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-select-selection:hover {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-select-open .ant-select-selection,
html[data-theme='light'] .has-error .ant-select-focused .ant-select-selection {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
html[data-theme='light'] .has-error .ant-calendar-picker-icon::after,
html[data-theme='light'] .has-error .ant-time-picker-icon::after,
html[data-theme='light'] .has-error .ant-picker-icon::after,
html[data-theme='light'] .has-error .ant-select-arrow,
html[data-theme='light'] .has-error .ant-cascader-picker-arrow {
  color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-number,
html[data-theme='light'] .has-error .ant-time-picker-input {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-input-number-focused,
html[data-theme='light'] .has-error .ant-time-picker-input-focused,
html[data-theme='light'] .has-error .ant-input-number:focus,
html[data-theme='light'] .has-error .ant-time-picker-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-input-number:not([disabled]):hover,
html[data-theme='light'] .has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-mention-wrapper .ant-mention-editor,
html[data-theme='light'] .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
html[data-theme='light'] .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='light'] .has-error .ant-transfer-list {
  border-color: #ff376b;
}
html[data-theme='light'] .has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #babece;
}
html[data-theme='light'] .has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #4261ff;
}
html[data-theme='light'] .ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
html[data-theme='light'] .ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 1px;
}
html[data-theme='light'] .show-help-enter,
html[data-theme='light'] .show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .show-help-enter.show-help-enter-active,
html[data-theme='light'] .show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
html[data-theme='light'] .show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='light'] .show-help-enter,
html[data-theme='light'] .show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
html[data-theme='light'] .ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
html[data-theme='light'] .ant-row::before,
html[data-theme='light'] .ant-row::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-row::after {
  clear: both;
}
html[data-theme='light'] .ant-row::before,
html[data-theme='light'] .ant-row::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-row::after {
  clear: both;
}
html[data-theme='light'] .ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
html[data-theme='light'] .ant-row-flex::before,
html[data-theme='light'] .ant-row-flex::after {
  display: flex;
}
html[data-theme='light'] .ant-row-flex-start {
  justify-content: flex-start;
}
html[data-theme='light'] .ant-row-flex-center {
  justify-content: center;
}
html[data-theme='light'] .ant-row-flex-end {
  justify-content: flex-end;
}
html[data-theme='light'] .ant-row-flex-space-between {
  justify-content: space-between;
}
html[data-theme='light'] .ant-row-flex-space-around {
  justify-content: space-around;
}
html[data-theme='light'] .ant-row-flex-top {
  align-items: flex-start;
}
html[data-theme='light'] .ant-row-flex-middle {
  align-items: center;
}
html[data-theme='light'] .ant-row-flex-bottom {
  align-items: flex-end;
}
html[data-theme='light'] .ant-col {
  position: relative;
}
html[data-theme='light'] .ant-col-1,
html[data-theme='light'] .ant-col-xs-1,
html[data-theme='light'] .ant-col-sm-1,
html[data-theme='light'] .ant-col-md-1,
html[data-theme='light'] .ant-col-lg-1,
html[data-theme='light'] .ant-col-2,
html[data-theme='light'] .ant-col-xs-2,
html[data-theme='light'] .ant-col-sm-2,
html[data-theme='light'] .ant-col-md-2,
html[data-theme='light'] .ant-col-lg-2,
html[data-theme='light'] .ant-col-3,
html[data-theme='light'] .ant-col-xs-3,
html[data-theme='light'] .ant-col-sm-3,
html[data-theme='light'] .ant-col-md-3,
html[data-theme='light'] .ant-col-lg-3,
html[data-theme='light'] .ant-col-4,
html[data-theme='light'] .ant-col-xs-4,
html[data-theme='light'] .ant-col-sm-4,
html[data-theme='light'] .ant-col-md-4,
html[data-theme='light'] .ant-col-lg-4,
html[data-theme='light'] .ant-col-5,
html[data-theme='light'] .ant-col-xs-5,
html[data-theme='light'] .ant-col-sm-5,
html[data-theme='light'] .ant-col-md-5,
html[data-theme='light'] .ant-col-lg-5,
html[data-theme='light'] .ant-col-6,
html[data-theme='light'] .ant-col-xs-6,
html[data-theme='light'] .ant-col-sm-6,
html[data-theme='light'] .ant-col-md-6,
html[data-theme='light'] .ant-col-lg-6,
html[data-theme='light'] .ant-col-7,
html[data-theme='light'] .ant-col-xs-7,
html[data-theme='light'] .ant-col-sm-7,
html[data-theme='light'] .ant-col-md-7,
html[data-theme='light'] .ant-col-lg-7,
html[data-theme='light'] .ant-col-8,
html[data-theme='light'] .ant-col-xs-8,
html[data-theme='light'] .ant-col-sm-8,
html[data-theme='light'] .ant-col-md-8,
html[data-theme='light'] .ant-col-lg-8,
html[data-theme='light'] .ant-col-9,
html[data-theme='light'] .ant-col-xs-9,
html[data-theme='light'] .ant-col-sm-9,
html[data-theme='light'] .ant-col-md-9,
html[data-theme='light'] .ant-col-lg-9,
html[data-theme='light'] .ant-col-10,
html[data-theme='light'] .ant-col-xs-10,
html[data-theme='light'] .ant-col-sm-10,
html[data-theme='light'] .ant-col-md-10,
html[data-theme='light'] .ant-col-lg-10,
html[data-theme='light'] .ant-col-11,
html[data-theme='light'] .ant-col-xs-11,
html[data-theme='light'] .ant-col-sm-11,
html[data-theme='light'] .ant-col-md-11,
html[data-theme='light'] .ant-col-lg-11,
html[data-theme='light'] .ant-col-12,
html[data-theme='light'] .ant-col-xs-12,
html[data-theme='light'] .ant-col-sm-12,
html[data-theme='light'] .ant-col-md-12,
html[data-theme='light'] .ant-col-lg-12,
html[data-theme='light'] .ant-col-13,
html[data-theme='light'] .ant-col-xs-13,
html[data-theme='light'] .ant-col-sm-13,
html[data-theme='light'] .ant-col-md-13,
html[data-theme='light'] .ant-col-lg-13,
html[data-theme='light'] .ant-col-14,
html[data-theme='light'] .ant-col-xs-14,
html[data-theme='light'] .ant-col-sm-14,
html[data-theme='light'] .ant-col-md-14,
html[data-theme='light'] .ant-col-lg-14,
html[data-theme='light'] .ant-col-15,
html[data-theme='light'] .ant-col-xs-15,
html[data-theme='light'] .ant-col-sm-15,
html[data-theme='light'] .ant-col-md-15,
html[data-theme='light'] .ant-col-lg-15,
html[data-theme='light'] .ant-col-16,
html[data-theme='light'] .ant-col-xs-16,
html[data-theme='light'] .ant-col-sm-16,
html[data-theme='light'] .ant-col-md-16,
html[data-theme='light'] .ant-col-lg-16,
html[data-theme='light'] .ant-col-17,
html[data-theme='light'] .ant-col-xs-17,
html[data-theme='light'] .ant-col-sm-17,
html[data-theme='light'] .ant-col-md-17,
html[data-theme='light'] .ant-col-lg-17,
html[data-theme='light'] .ant-col-18,
html[data-theme='light'] .ant-col-xs-18,
html[data-theme='light'] .ant-col-sm-18,
html[data-theme='light'] .ant-col-md-18,
html[data-theme='light'] .ant-col-lg-18,
html[data-theme='light'] .ant-col-19,
html[data-theme='light'] .ant-col-xs-19,
html[data-theme='light'] .ant-col-sm-19,
html[data-theme='light'] .ant-col-md-19,
html[data-theme='light'] .ant-col-lg-19,
html[data-theme='light'] .ant-col-20,
html[data-theme='light'] .ant-col-xs-20,
html[data-theme='light'] .ant-col-sm-20,
html[data-theme='light'] .ant-col-md-20,
html[data-theme='light'] .ant-col-lg-20,
html[data-theme='light'] .ant-col-21,
html[data-theme='light'] .ant-col-xs-21,
html[data-theme='light'] .ant-col-sm-21,
html[data-theme='light'] .ant-col-md-21,
html[data-theme='light'] .ant-col-lg-21,
html[data-theme='light'] .ant-col-22,
html[data-theme='light'] .ant-col-xs-22,
html[data-theme='light'] .ant-col-sm-22,
html[data-theme='light'] .ant-col-md-22,
html[data-theme='light'] .ant-col-lg-22,
html[data-theme='light'] .ant-col-23,
html[data-theme='light'] .ant-col-xs-23,
html[data-theme='light'] .ant-col-sm-23,
html[data-theme='light'] .ant-col-md-23,
html[data-theme='light'] .ant-col-lg-23,
html[data-theme='light'] .ant-col-24,
html[data-theme='light'] .ant-col-xs-24,
html[data-theme='light'] .ant-col-sm-24,
html[data-theme='light'] .ant-col-md-24,
html[data-theme='light'] .ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='light'] .ant-col-1,
html[data-theme='light'] .ant-col-2,
html[data-theme='light'] .ant-col-3,
html[data-theme='light'] .ant-col-4,
html[data-theme='light'] .ant-col-5,
html[data-theme='light'] .ant-col-6,
html[data-theme='light'] .ant-col-7,
html[data-theme='light'] .ant-col-8,
html[data-theme='light'] .ant-col-9,
html[data-theme='light'] .ant-col-10,
html[data-theme='light'] .ant-col-11,
html[data-theme='light'] .ant-col-12,
html[data-theme='light'] .ant-col-13,
html[data-theme='light'] .ant-col-14,
html[data-theme='light'] .ant-col-15,
html[data-theme='light'] .ant-col-16,
html[data-theme='light'] .ant-col-17,
html[data-theme='light'] .ant-col-18,
html[data-theme='light'] .ant-col-19,
html[data-theme='light'] .ant-col-20,
html[data-theme='light'] .ant-col-21,
html[data-theme='light'] .ant-col-22,
html[data-theme='light'] .ant-col-23,
html[data-theme='light'] .ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
html[data-theme='light'] .ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
html[data-theme='light'] .ant-col-push-24 {
  left: 100%;
}
html[data-theme='light'] .ant-col-pull-24 {
  right: 100%;
}
html[data-theme='light'] .ant-col-offset-24 {
  margin-left: 100%;
}
html[data-theme='light'] .ant-col-order-24 {
  order: 24;
}
html[data-theme='light'] .ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
html[data-theme='light'] .ant-col-push-23 {
  left: 95.83333333%;
}
html[data-theme='light'] .ant-col-pull-23 {
  right: 95.83333333%;
}
html[data-theme='light'] .ant-col-offset-23 {
  margin-left: 95.83333333%;
}
html[data-theme='light'] .ant-col-order-23 {
  order: 23;
}
html[data-theme='light'] .ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
html[data-theme='light'] .ant-col-push-22 {
  left: 91.66666667%;
}
html[data-theme='light'] .ant-col-pull-22 {
  right: 91.66666667%;
}
html[data-theme='light'] .ant-col-offset-22 {
  margin-left: 91.66666667%;
}
html[data-theme='light'] .ant-col-order-22 {
  order: 22;
}
html[data-theme='light'] .ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
html[data-theme='light'] .ant-col-push-21 {
  left: 87.5%;
}
html[data-theme='light'] .ant-col-pull-21 {
  right: 87.5%;
}
html[data-theme='light'] .ant-col-offset-21 {
  margin-left: 87.5%;
}
html[data-theme='light'] .ant-col-order-21 {
  order: 21;
}
html[data-theme='light'] .ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
html[data-theme='light'] .ant-col-push-20 {
  left: 83.33333333%;
}
html[data-theme='light'] .ant-col-pull-20 {
  right: 83.33333333%;
}
html[data-theme='light'] .ant-col-offset-20 {
  margin-left: 83.33333333%;
}
html[data-theme='light'] .ant-col-order-20 {
  order: 20;
}
html[data-theme='light'] .ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
html[data-theme='light'] .ant-col-push-19 {
  left: 79.16666667%;
}
html[data-theme='light'] .ant-col-pull-19 {
  right: 79.16666667%;
}
html[data-theme='light'] .ant-col-offset-19 {
  margin-left: 79.16666667%;
}
html[data-theme='light'] .ant-col-order-19 {
  order: 19;
}
html[data-theme='light'] .ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
html[data-theme='light'] .ant-col-push-18 {
  left: 75%;
}
html[data-theme='light'] .ant-col-pull-18 {
  right: 75%;
}
html[data-theme='light'] .ant-col-offset-18 {
  margin-left: 75%;
}
html[data-theme='light'] .ant-col-order-18 {
  order: 18;
}
html[data-theme='light'] .ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
html[data-theme='light'] .ant-col-push-17 {
  left: 70.83333333%;
}
html[data-theme='light'] .ant-col-pull-17 {
  right: 70.83333333%;
}
html[data-theme='light'] .ant-col-offset-17 {
  margin-left: 70.83333333%;
}
html[data-theme='light'] .ant-col-order-17 {
  order: 17;
}
html[data-theme='light'] .ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
html[data-theme='light'] .ant-col-push-16 {
  left: 66.66666667%;
}
html[data-theme='light'] .ant-col-pull-16 {
  right: 66.66666667%;
}
html[data-theme='light'] .ant-col-offset-16 {
  margin-left: 66.66666667%;
}
html[data-theme='light'] .ant-col-order-16 {
  order: 16;
}
html[data-theme='light'] .ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
html[data-theme='light'] .ant-col-push-15 {
  left: 62.5%;
}
html[data-theme='light'] .ant-col-pull-15 {
  right: 62.5%;
}
html[data-theme='light'] .ant-col-offset-15 {
  margin-left: 62.5%;
}
html[data-theme='light'] .ant-col-order-15 {
  order: 15;
}
html[data-theme='light'] .ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
html[data-theme='light'] .ant-col-push-14 {
  left: 58.33333333%;
}
html[data-theme='light'] .ant-col-pull-14 {
  right: 58.33333333%;
}
html[data-theme='light'] .ant-col-offset-14 {
  margin-left: 58.33333333%;
}
html[data-theme='light'] .ant-col-order-14 {
  order: 14;
}
html[data-theme='light'] .ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
html[data-theme='light'] .ant-col-push-13 {
  left: 54.16666667%;
}
html[data-theme='light'] .ant-col-pull-13 {
  right: 54.16666667%;
}
html[data-theme='light'] .ant-col-offset-13 {
  margin-left: 54.16666667%;
}
html[data-theme='light'] .ant-col-order-13 {
  order: 13;
}
html[data-theme='light'] .ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
html[data-theme='light'] .ant-col-push-12 {
  left: 50%;
}
html[data-theme='light'] .ant-col-pull-12 {
  right: 50%;
}
html[data-theme='light'] .ant-col-offset-12 {
  margin-left: 50%;
}
html[data-theme='light'] .ant-col-order-12 {
  order: 12;
}
html[data-theme='light'] .ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
html[data-theme='light'] .ant-col-push-11 {
  left: 45.83333333%;
}
html[data-theme='light'] .ant-col-pull-11 {
  right: 45.83333333%;
}
html[data-theme='light'] .ant-col-offset-11 {
  margin-left: 45.83333333%;
}
html[data-theme='light'] .ant-col-order-11 {
  order: 11;
}
html[data-theme='light'] .ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
html[data-theme='light'] .ant-col-push-10 {
  left: 41.66666667%;
}
html[data-theme='light'] .ant-col-pull-10 {
  right: 41.66666667%;
}
html[data-theme='light'] .ant-col-offset-10 {
  margin-left: 41.66666667%;
}
html[data-theme='light'] .ant-col-order-10 {
  order: 10;
}
html[data-theme='light'] .ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
html[data-theme='light'] .ant-col-push-9 {
  left: 37.5%;
}
html[data-theme='light'] .ant-col-pull-9 {
  right: 37.5%;
}
html[data-theme='light'] .ant-col-offset-9 {
  margin-left: 37.5%;
}
html[data-theme='light'] .ant-col-order-9 {
  order: 9;
}
html[data-theme='light'] .ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
html[data-theme='light'] .ant-col-push-8 {
  left: 33.33333333%;
}
html[data-theme='light'] .ant-col-pull-8 {
  right: 33.33333333%;
}
html[data-theme='light'] .ant-col-offset-8 {
  margin-left: 33.33333333%;
}
html[data-theme='light'] .ant-col-order-8 {
  order: 8;
}
html[data-theme='light'] .ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
html[data-theme='light'] .ant-col-push-7 {
  left: 29.16666667%;
}
html[data-theme='light'] .ant-col-pull-7 {
  right: 29.16666667%;
}
html[data-theme='light'] .ant-col-offset-7 {
  margin-left: 29.16666667%;
}
html[data-theme='light'] .ant-col-order-7 {
  order: 7;
}
html[data-theme='light'] .ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
html[data-theme='light'] .ant-col-push-6 {
  left: 25%;
}
html[data-theme='light'] .ant-col-pull-6 {
  right: 25%;
}
html[data-theme='light'] .ant-col-offset-6 {
  margin-left: 25%;
}
html[data-theme='light'] .ant-col-order-6 {
  order: 6;
}
html[data-theme='light'] .ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
html[data-theme='light'] .ant-col-push-5 {
  left: 20.83333333%;
}
html[data-theme='light'] .ant-col-pull-5 {
  right: 20.83333333%;
}
html[data-theme='light'] .ant-col-offset-5 {
  margin-left: 20.83333333%;
}
html[data-theme='light'] .ant-col-order-5 {
  order: 5;
}
html[data-theme='light'] .ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
html[data-theme='light'] .ant-col-push-4 {
  left: 16.66666667%;
}
html[data-theme='light'] .ant-col-pull-4 {
  right: 16.66666667%;
}
html[data-theme='light'] .ant-col-offset-4 {
  margin-left: 16.66666667%;
}
html[data-theme='light'] .ant-col-order-4 {
  order: 4;
}
html[data-theme='light'] .ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
html[data-theme='light'] .ant-col-push-3 {
  left: 12.5%;
}
html[data-theme='light'] .ant-col-pull-3 {
  right: 12.5%;
}
html[data-theme='light'] .ant-col-offset-3 {
  margin-left: 12.5%;
}
html[data-theme='light'] .ant-col-order-3 {
  order: 3;
}
html[data-theme='light'] .ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
html[data-theme='light'] .ant-col-push-2 {
  left: 8.33333333%;
}
html[data-theme='light'] .ant-col-pull-2 {
  right: 8.33333333%;
}
html[data-theme='light'] .ant-col-offset-2 {
  margin-left: 8.33333333%;
}
html[data-theme='light'] .ant-col-order-2 {
  order: 2;
}
html[data-theme='light'] .ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
html[data-theme='light'] .ant-col-push-1 {
  left: 4.16666667%;
}
html[data-theme='light'] .ant-col-pull-1 {
  right: 4.16666667%;
}
html[data-theme='light'] .ant-col-offset-1 {
  margin-left: 4.16666667%;
}
html[data-theme='light'] .ant-col-order-1 {
  order: 1;
}
html[data-theme='light'] .ant-col-0 {
  display: none;
}
html[data-theme='light'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='light'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='light'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='light'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='light'] .ant-col-offset-0 {
  margin-left: 0;
}
html[data-theme='light'] .ant-col-order-0 {
  order: 0;
}
html[data-theme='light'] .ant-col-xs-1,
html[data-theme='light'] .ant-col-xs-2,
html[data-theme='light'] .ant-col-xs-3,
html[data-theme='light'] .ant-col-xs-4,
html[data-theme='light'] .ant-col-xs-5,
html[data-theme='light'] .ant-col-xs-6,
html[data-theme='light'] .ant-col-xs-7,
html[data-theme='light'] .ant-col-xs-8,
html[data-theme='light'] .ant-col-xs-9,
html[data-theme='light'] .ant-col-xs-10,
html[data-theme='light'] .ant-col-xs-11,
html[data-theme='light'] .ant-col-xs-12,
html[data-theme='light'] .ant-col-xs-13,
html[data-theme='light'] .ant-col-xs-14,
html[data-theme='light'] .ant-col-xs-15,
html[data-theme='light'] .ant-col-xs-16,
html[data-theme='light'] .ant-col-xs-17,
html[data-theme='light'] .ant-col-xs-18,
html[data-theme='light'] .ant-col-xs-19,
html[data-theme='light'] .ant-col-xs-20,
html[data-theme='light'] .ant-col-xs-21,
html[data-theme='light'] .ant-col-xs-22,
html[data-theme='light'] .ant-col-xs-23,
html[data-theme='light'] .ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
html[data-theme='light'] .ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
html[data-theme='light'] .ant-col-xs-push-24 {
  left: 100%;
}
html[data-theme='light'] .ant-col-xs-pull-24 {
  right: 100%;
}
html[data-theme='light'] .ant-col-xs-offset-24 {
  margin-left: 100%;
}
html[data-theme='light'] .ant-col-xs-order-24 {
  order: 24;
}
html[data-theme='light'] .ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
html[data-theme='light'] .ant-col-xs-push-23 {
  left: 95.83333333%;
}
html[data-theme='light'] .ant-col-xs-pull-23 {
  right: 95.83333333%;
}
html[data-theme='light'] .ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
html[data-theme='light'] .ant-col-xs-order-23 {
  order: 23;
}
html[data-theme='light'] .ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
html[data-theme='light'] .ant-col-xs-push-22 {
  left: 91.66666667%;
}
html[data-theme='light'] .ant-col-xs-pull-22 {
  right: 91.66666667%;
}
html[data-theme='light'] .ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
html[data-theme='light'] .ant-col-xs-order-22 {
  order: 22;
}
html[data-theme='light'] .ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
html[data-theme='light'] .ant-col-xs-push-21 {
  left: 87.5%;
}
html[data-theme='light'] .ant-col-xs-pull-21 {
  right: 87.5%;
}
html[data-theme='light'] .ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
html[data-theme='light'] .ant-col-xs-order-21 {
  order: 21;
}
html[data-theme='light'] .ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
html[data-theme='light'] .ant-col-xs-push-20 {
  left: 83.33333333%;
}
html[data-theme='light'] .ant-col-xs-pull-20 {
  right: 83.33333333%;
}
html[data-theme='light'] .ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
html[data-theme='light'] .ant-col-xs-order-20 {
  order: 20;
}
html[data-theme='light'] .ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
html[data-theme='light'] .ant-col-xs-push-19 {
  left: 79.16666667%;
}
html[data-theme='light'] .ant-col-xs-pull-19 {
  right: 79.16666667%;
}
html[data-theme='light'] .ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
html[data-theme='light'] .ant-col-xs-order-19 {
  order: 19;
}
html[data-theme='light'] .ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
html[data-theme='light'] .ant-col-xs-push-18 {
  left: 75%;
}
html[data-theme='light'] .ant-col-xs-pull-18 {
  right: 75%;
}
html[data-theme='light'] .ant-col-xs-offset-18 {
  margin-left: 75%;
}
html[data-theme='light'] .ant-col-xs-order-18 {
  order: 18;
}
html[data-theme='light'] .ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
html[data-theme='light'] .ant-col-xs-push-17 {
  left: 70.83333333%;
}
html[data-theme='light'] .ant-col-xs-pull-17 {
  right: 70.83333333%;
}
html[data-theme='light'] .ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
html[data-theme='light'] .ant-col-xs-order-17 {
  order: 17;
}
html[data-theme='light'] .ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
html[data-theme='light'] .ant-col-xs-push-16 {
  left: 66.66666667%;
}
html[data-theme='light'] .ant-col-xs-pull-16 {
  right: 66.66666667%;
}
html[data-theme='light'] .ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
html[data-theme='light'] .ant-col-xs-order-16 {
  order: 16;
}
html[data-theme='light'] .ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
html[data-theme='light'] .ant-col-xs-push-15 {
  left: 62.5%;
}
html[data-theme='light'] .ant-col-xs-pull-15 {
  right: 62.5%;
}
html[data-theme='light'] .ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
html[data-theme='light'] .ant-col-xs-order-15 {
  order: 15;
}
html[data-theme='light'] .ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
html[data-theme='light'] .ant-col-xs-push-14 {
  left: 58.33333333%;
}
html[data-theme='light'] .ant-col-xs-pull-14 {
  right: 58.33333333%;
}
html[data-theme='light'] .ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
html[data-theme='light'] .ant-col-xs-order-14 {
  order: 14;
}
html[data-theme='light'] .ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
html[data-theme='light'] .ant-col-xs-push-13 {
  left: 54.16666667%;
}
html[data-theme='light'] .ant-col-xs-pull-13 {
  right: 54.16666667%;
}
html[data-theme='light'] .ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
html[data-theme='light'] .ant-col-xs-order-13 {
  order: 13;
}
html[data-theme='light'] .ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
html[data-theme='light'] .ant-col-xs-push-12 {
  left: 50%;
}
html[data-theme='light'] .ant-col-xs-pull-12 {
  right: 50%;
}
html[data-theme='light'] .ant-col-xs-offset-12 {
  margin-left: 50%;
}
html[data-theme='light'] .ant-col-xs-order-12 {
  order: 12;
}
html[data-theme='light'] .ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
html[data-theme='light'] .ant-col-xs-push-11 {
  left: 45.83333333%;
}
html[data-theme='light'] .ant-col-xs-pull-11 {
  right: 45.83333333%;
}
html[data-theme='light'] .ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
html[data-theme='light'] .ant-col-xs-order-11 {
  order: 11;
}
html[data-theme='light'] .ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
html[data-theme='light'] .ant-col-xs-push-10 {
  left: 41.66666667%;
}
html[data-theme='light'] .ant-col-xs-pull-10 {
  right: 41.66666667%;
}
html[data-theme='light'] .ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
html[data-theme='light'] .ant-col-xs-order-10 {
  order: 10;
}
html[data-theme='light'] .ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
html[data-theme='light'] .ant-col-xs-push-9 {
  left: 37.5%;
}
html[data-theme='light'] .ant-col-xs-pull-9 {
  right: 37.5%;
}
html[data-theme='light'] .ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
html[data-theme='light'] .ant-col-xs-order-9 {
  order: 9;
}
html[data-theme='light'] .ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
html[data-theme='light'] .ant-col-xs-push-8 {
  left: 33.33333333%;
}
html[data-theme='light'] .ant-col-xs-pull-8 {
  right: 33.33333333%;
}
html[data-theme='light'] .ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
html[data-theme='light'] .ant-col-xs-order-8 {
  order: 8;
}
html[data-theme='light'] .ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
html[data-theme='light'] .ant-col-xs-push-7 {
  left: 29.16666667%;
}
html[data-theme='light'] .ant-col-xs-pull-7 {
  right: 29.16666667%;
}
html[data-theme='light'] .ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
html[data-theme='light'] .ant-col-xs-order-7 {
  order: 7;
}
html[data-theme='light'] .ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
html[data-theme='light'] .ant-col-xs-push-6 {
  left: 25%;
}
html[data-theme='light'] .ant-col-xs-pull-6 {
  right: 25%;
}
html[data-theme='light'] .ant-col-xs-offset-6 {
  margin-left: 25%;
}
html[data-theme='light'] .ant-col-xs-order-6 {
  order: 6;
}
html[data-theme='light'] .ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
html[data-theme='light'] .ant-col-xs-push-5 {
  left: 20.83333333%;
}
html[data-theme='light'] .ant-col-xs-pull-5 {
  right: 20.83333333%;
}
html[data-theme='light'] .ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
html[data-theme='light'] .ant-col-xs-order-5 {
  order: 5;
}
html[data-theme='light'] .ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
html[data-theme='light'] .ant-col-xs-push-4 {
  left: 16.66666667%;
}
html[data-theme='light'] .ant-col-xs-pull-4 {
  right: 16.66666667%;
}
html[data-theme='light'] .ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
html[data-theme='light'] .ant-col-xs-order-4 {
  order: 4;
}
html[data-theme='light'] .ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
html[data-theme='light'] .ant-col-xs-push-3 {
  left: 12.5%;
}
html[data-theme='light'] .ant-col-xs-pull-3 {
  right: 12.5%;
}
html[data-theme='light'] .ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
html[data-theme='light'] .ant-col-xs-order-3 {
  order: 3;
}
html[data-theme='light'] .ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
html[data-theme='light'] .ant-col-xs-push-2 {
  left: 8.33333333%;
}
html[data-theme='light'] .ant-col-xs-pull-2 {
  right: 8.33333333%;
}
html[data-theme='light'] .ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
html[data-theme='light'] .ant-col-xs-order-2 {
  order: 2;
}
html[data-theme='light'] .ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
html[data-theme='light'] .ant-col-xs-push-1 {
  left: 4.16666667%;
}
html[data-theme='light'] .ant-col-xs-pull-1 {
  right: 4.16666667%;
}
html[data-theme='light'] .ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
html[data-theme='light'] .ant-col-xs-order-1 {
  order: 1;
}
html[data-theme='light'] .ant-col-xs-0 {
  display: none;
}
html[data-theme='light'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='light'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='light'] .ant-col-xs-push-0 {
  left: auto;
}
html[data-theme='light'] .ant-col-xs-pull-0 {
  right: auto;
}
html[data-theme='light'] .ant-col-xs-offset-0 {
  margin-left: 0;
}
html[data-theme='light'] .ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  html[data-theme='light'] .ant-col-sm-1,
  html[data-theme='light'] .ant-col-sm-2,
  html[data-theme='light'] .ant-col-sm-3,
  html[data-theme='light'] .ant-col-sm-4,
  html[data-theme='light'] .ant-col-sm-5,
  html[data-theme='light'] .ant-col-sm-6,
  html[data-theme='light'] .ant-col-sm-7,
  html[data-theme='light'] .ant-col-sm-8,
  html[data-theme='light'] .ant-col-sm-9,
  html[data-theme='light'] .ant-col-sm-10,
  html[data-theme='light'] .ant-col-sm-11,
  html[data-theme='light'] .ant-col-sm-12,
  html[data-theme='light'] .ant-col-sm-13,
  html[data-theme='light'] .ant-col-sm-14,
  html[data-theme='light'] .ant-col-sm-15,
  html[data-theme='light'] .ant-col-sm-16,
  html[data-theme='light'] .ant-col-sm-17,
  html[data-theme='light'] .ant-col-sm-18,
  html[data-theme='light'] .ant-col-sm-19,
  html[data-theme='light'] .ant-col-sm-20,
  html[data-theme='light'] .ant-col-sm-21,
  html[data-theme='light'] .ant-col-sm-22,
  html[data-theme='light'] .ant-col-sm-23,
  html[data-theme='light'] .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='light'] .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='light'] .ant-col-sm-push-24 {
    left: 100%;
  }
  html[data-theme='light'] .ant-col-sm-pull-24 {
    right: 100%;
  }
  html[data-theme='light'] .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='light'] .ant-col-sm-order-24 {
    order: 24;
  }
  html[data-theme='light'] .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-23 {
    order: 23;
  }
  html[data-theme='light'] .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-22 {
    order: 22;
  }
  html[data-theme='light'] .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='light'] .ant-col-sm-push-21 {
    left: 87.5%;
  }
  html[data-theme='light'] .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  html[data-theme='light'] .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='light'] .ant-col-sm-order-21 {
    order: 21;
  }
  html[data-theme='light'] .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-20 {
    order: 20;
  }
  html[data-theme='light'] .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-19 {
    order: 19;
  }
  html[data-theme='light'] .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='light'] .ant-col-sm-push-18 {
    left: 75%;
  }
  html[data-theme='light'] .ant-col-sm-pull-18 {
    right: 75%;
  }
  html[data-theme='light'] .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='light'] .ant-col-sm-order-18 {
    order: 18;
  }
  html[data-theme='light'] .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-17 {
    order: 17;
  }
  html[data-theme='light'] .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-16 {
    order: 16;
  }
  html[data-theme='light'] .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='light'] .ant-col-sm-push-15 {
    left: 62.5%;
  }
  html[data-theme='light'] .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  html[data-theme='light'] .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='light'] .ant-col-sm-order-15 {
    order: 15;
  }
  html[data-theme='light'] .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-14 {
    order: 14;
  }
  html[data-theme='light'] .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-13 {
    order: 13;
  }
  html[data-theme='light'] .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='light'] .ant-col-sm-push-12 {
    left: 50%;
  }
  html[data-theme='light'] .ant-col-sm-pull-12 {
    right: 50%;
  }
  html[data-theme='light'] .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='light'] .ant-col-sm-order-12 {
    order: 12;
  }
  html[data-theme='light'] .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-11 {
    order: 11;
  }
  html[data-theme='light'] .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-10 {
    order: 10;
  }
  html[data-theme='light'] .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='light'] .ant-col-sm-push-9 {
    left: 37.5%;
  }
  html[data-theme='light'] .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  html[data-theme='light'] .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='light'] .ant-col-sm-order-9 {
    order: 9;
  }
  html[data-theme='light'] .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-8 {
    order: 8;
  }
  html[data-theme='light'] .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-7 {
    order: 7;
  }
  html[data-theme='light'] .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='light'] .ant-col-sm-push-6 {
    left: 25%;
  }
  html[data-theme='light'] .ant-col-sm-pull-6 {
    right: 25%;
  }
  html[data-theme='light'] .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='light'] .ant-col-sm-order-6 {
    order: 6;
  }
  html[data-theme='light'] .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-5 {
    order: 5;
  }
  html[data-theme='light'] .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-4 {
    order: 4;
  }
  html[data-theme='light'] .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='light'] .ant-col-sm-push-3 {
    left: 12.5%;
  }
  html[data-theme='light'] .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  html[data-theme='light'] .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='light'] .ant-col-sm-order-3 {
    order: 3;
  }
  html[data-theme='light'] .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-sm-order-2 {
    order: 2;
  }
  html[data-theme='light'] .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-sm-order-1 {
    order: 1;
  }
  html[data-theme='light'] .ant-col-sm-0 {
    display: none;
  }
  html[data-theme='light'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-sm-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-sm-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .ant-col-md-1,
  html[data-theme='light'] .ant-col-md-2,
  html[data-theme='light'] .ant-col-md-3,
  html[data-theme='light'] .ant-col-md-4,
  html[data-theme='light'] .ant-col-md-5,
  html[data-theme='light'] .ant-col-md-6,
  html[data-theme='light'] .ant-col-md-7,
  html[data-theme='light'] .ant-col-md-8,
  html[data-theme='light'] .ant-col-md-9,
  html[data-theme='light'] .ant-col-md-10,
  html[data-theme='light'] .ant-col-md-11,
  html[data-theme='light'] .ant-col-md-12,
  html[data-theme='light'] .ant-col-md-13,
  html[data-theme='light'] .ant-col-md-14,
  html[data-theme='light'] .ant-col-md-15,
  html[data-theme='light'] .ant-col-md-16,
  html[data-theme='light'] .ant-col-md-17,
  html[data-theme='light'] .ant-col-md-18,
  html[data-theme='light'] .ant-col-md-19,
  html[data-theme='light'] .ant-col-md-20,
  html[data-theme='light'] .ant-col-md-21,
  html[data-theme='light'] .ant-col-md-22,
  html[data-theme='light'] .ant-col-md-23,
  html[data-theme='light'] .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='light'] .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='light'] .ant-col-md-push-24 {
    left: 100%;
  }
  html[data-theme='light'] .ant-col-md-pull-24 {
    right: 100%;
  }
  html[data-theme='light'] .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='light'] .ant-col-md-order-24 {
    order: 24;
  }
  html[data-theme='light'] .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-md-order-23 {
    order: 23;
  }
  html[data-theme='light'] .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-md-order-22 {
    order: 22;
  }
  html[data-theme='light'] .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='light'] .ant-col-md-push-21 {
    left: 87.5%;
  }
  html[data-theme='light'] .ant-col-md-pull-21 {
    right: 87.5%;
  }
  html[data-theme='light'] .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='light'] .ant-col-md-order-21 {
    order: 21;
  }
  html[data-theme='light'] .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-md-order-20 {
    order: 20;
  }
  html[data-theme='light'] .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-md-order-19 {
    order: 19;
  }
  html[data-theme='light'] .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='light'] .ant-col-md-push-18 {
    left: 75%;
  }
  html[data-theme='light'] .ant-col-md-pull-18 {
    right: 75%;
  }
  html[data-theme='light'] .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='light'] .ant-col-md-order-18 {
    order: 18;
  }
  html[data-theme='light'] .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-md-order-17 {
    order: 17;
  }
  html[data-theme='light'] .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-md-order-16 {
    order: 16;
  }
  html[data-theme='light'] .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='light'] .ant-col-md-push-15 {
    left: 62.5%;
  }
  html[data-theme='light'] .ant-col-md-pull-15 {
    right: 62.5%;
  }
  html[data-theme='light'] .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='light'] .ant-col-md-order-15 {
    order: 15;
  }
  html[data-theme='light'] .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-md-order-14 {
    order: 14;
  }
  html[data-theme='light'] .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-md-order-13 {
    order: 13;
  }
  html[data-theme='light'] .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='light'] .ant-col-md-push-12 {
    left: 50%;
  }
  html[data-theme='light'] .ant-col-md-pull-12 {
    right: 50%;
  }
  html[data-theme='light'] .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='light'] .ant-col-md-order-12 {
    order: 12;
  }
  html[data-theme='light'] .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-md-order-11 {
    order: 11;
  }
  html[data-theme='light'] .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-md-order-10 {
    order: 10;
  }
  html[data-theme='light'] .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='light'] .ant-col-md-push-9 {
    left: 37.5%;
  }
  html[data-theme='light'] .ant-col-md-pull-9 {
    right: 37.5%;
  }
  html[data-theme='light'] .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='light'] .ant-col-md-order-9 {
    order: 9;
  }
  html[data-theme='light'] .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-md-order-8 {
    order: 8;
  }
  html[data-theme='light'] .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-md-order-7 {
    order: 7;
  }
  html[data-theme='light'] .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='light'] .ant-col-md-push-6 {
    left: 25%;
  }
  html[data-theme='light'] .ant-col-md-pull-6 {
    right: 25%;
  }
  html[data-theme='light'] .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='light'] .ant-col-md-order-6 {
    order: 6;
  }
  html[data-theme='light'] .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-md-order-5 {
    order: 5;
  }
  html[data-theme='light'] .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-md-order-4 {
    order: 4;
  }
  html[data-theme='light'] .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='light'] .ant-col-md-push-3 {
    left: 12.5%;
  }
  html[data-theme='light'] .ant-col-md-pull-3 {
    right: 12.5%;
  }
  html[data-theme='light'] .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='light'] .ant-col-md-order-3 {
    order: 3;
  }
  html[data-theme='light'] .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-md-order-2 {
    order: 2;
  }
  html[data-theme='light'] .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-md-order-1 {
    order: 1;
  }
  html[data-theme='light'] .ant-col-md-0 {
    display: none;
  }
  html[data-theme='light'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-md-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-md-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-md-offset-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .ant-col-lg-1,
  html[data-theme='light'] .ant-col-lg-2,
  html[data-theme='light'] .ant-col-lg-3,
  html[data-theme='light'] .ant-col-lg-4,
  html[data-theme='light'] .ant-col-lg-5,
  html[data-theme='light'] .ant-col-lg-6,
  html[data-theme='light'] .ant-col-lg-7,
  html[data-theme='light'] .ant-col-lg-8,
  html[data-theme='light'] .ant-col-lg-9,
  html[data-theme='light'] .ant-col-lg-10,
  html[data-theme='light'] .ant-col-lg-11,
  html[data-theme='light'] .ant-col-lg-12,
  html[data-theme='light'] .ant-col-lg-13,
  html[data-theme='light'] .ant-col-lg-14,
  html[data-theme='light'] .ant-col-lg-15,
  html[data-theme='light'] .ant-col-lg-16,
  html[data-theme='light'] .ant-col-lg-17,
  html[data-theme='light'] .ant-col-lg-18,
  html[data-theme='light'] .ant-col-lg-19,
  html[data-theme='light'] .ant-col-lg-20,
  html[data-theme='light'] .ant-col-lg-21,
  html[data-theme='light'] .ant-col-lg-22,
  html[data-theme='light'] .ant-col-lg-23,
  html[data-theme='light'] .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='light'] .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='light'] .ant-col-lg-push-24 {
    left: 100%;
  }
  html[data-theme='light'] .ant-col-lg-pull-24 {
    right: 100%;
  }
  html[data-theme='light'] .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='light'] .ant-col-lg-order-24 {
    order: 24;
  }
  html[data-theme='light'] .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-23 {
    order: 23;
  }
  html[data-theme='light'] .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-22 {
    order: 22;
  }
  html[data-theme='light'] .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='light'] .ant-col-lg-push-21 {
    left: 87.5%;
  }
  html[data-theme='light'] .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  html[data-theme='light'] .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='light'] .ant-col-lg-order-21 {
    order: 21;
  }
  html[data-theme='light'] .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-20 {
    order: 20;
  }
  html[data-theme='light'] .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-19 {
    order: 19;
  }
  html[data-theme='light'] .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='light'] .ant-col-lg-push-18 {
    left: 75%;
  }
  html[data-theme='light'] .ant-col-lg-pull-18 {
    right: 75%;
  }
  html[data-theme='light'] .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='light'] .ant-col-lg-order-18 {
    order: 18;
  }
  html[data-theme='light'] .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-17 {
    order: 17;
  }
  html[data-theme='light'] .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-16 {
    order: 16;
  }
  html[data-theme='light'] .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='light'] .ant-col-lg-push-15 {
    left: 62.5%;
  }
  html[data-theme='light'] .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  html[data-theme='light'] .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='light'] .ant-col-lg-order-15 {
    order: 15;
  }
  html[data-theme='light'] .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-14 {
    order: 14;
  }
  html[data-theme='light'] .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-13 {
    order: 13;
  }
  html[data-theme='light'] .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='light'] .ant-col-lg-push-12 {
    left: 50%;
  }
  html[data-theme='light'] .ant-col-lg-pull-12 {
    right: 50%;
  }
  html[data-theme='light'] .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='light'] .ant-col-lg-order-12 {
    order: 12;
  }
  html[data-theme='light'] .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-11 {
    order: 11;
  }
  html[data-theme='light'] .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-10 {
    order: 10;
  }
  html[data-theme='light'] .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='light'] .ant-col-lg-push-9 {
    left: 37.5%;
  }
  html[data-theme='light'] .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  html[data-theme='light'] .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='light'] .ant-col-lg-order-9 {
    order: 9;
  }
  html[data-theme='light'] .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-8 {
    order: 8;
  }
  html[data-theme='light'] .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-7 {
    order: 7;
  }
  html[data-theme='light'] .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='light'] .ant-col-lg-push-6 {
    left: 25%;
  }
  html[data-theme='light'] .ant-col-lg-pull-6 {
    right: 25%;
  }
  html[data-theme='light'] .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='light'] .ant-col-lg-order-6 {
    order: 6;
  }
  html[data-theme='light'] .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-5 {
    order: 5;
  }
  html[data-theme='light'] .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-4 {
    order: 4;
  }
  html[data-theme='light'] .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='light'] .ant-col-lg-push-3 {
    left: 12.5%;
  }
  html[data-theme='light'] .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  html[data-theme='light'] .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='light'] .ant-col-lg-order-3 {
    order: 3;
  }
  html[data-theme='light'] .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-lg-order-2 {
    order: 2;
  }
  html[data-theme='light'] .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-lg-order-1 {
    order: 1;
  }
  html[data-theme='light'] .ant-col-lg-0 {
    display: none;
  }
  html[data-theme='light'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-lg-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-lg-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .ant-col-xl-1,
  html[data-theme='light'] .ant-col-xl-2,
  html[data-theme='light'] .ant-col-xl-3,
  html[data-theme='light'] .ant-col-xl-4,
  html[data-theme='light'] .ant-col-xl-5,
  html[data-theme='light'] .ant-col-xl-6,
  html[data-theme='light'] .ant-col-xl-7,
  html[data-theme='light'] .ant-col-xl-8,
  html[data-theme='light'] .ant-col-xl-9,
  html[data-theme='light'] .ant-col-xl-10,
  html[data-theme='light'] .ant-col-xl-11,
  html[data-theme='light'] .ant-col-xl-12,
  html[data-theme='light'] .ant-col-xl-13,
  html[data-theme='light'] .ant-col-xl-14,
  html[data-theme='light'] .ant-col-xl-15,
  html[data-theme='light'] .ant-col-xl-16,
  html[data-theme='light'] .ant-col-xl-17,
  html[data-theme='light'] .ant-col-xl-18,
  html[data-theme='light'] .ant-col-xl-19,
  html[data-theme='light'] .ant-col-xl-20,
  html[data-theme='light'] .ant-col-xl-21,
  html[data-theme='light'] .ant-col-xl-22,
  html[data-theme='light'] .ant-col-xl-23,
  html[data-theme='light'] .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='light'] .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='light'] .ant-col-xl-push-24 {
    left: 100%;
  }
  html[data-theme='light'] .ant-col-xl-pull-24 {
    right: 100%;
  }
  html[data-theme='light'] .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='light'] .ant-col-xl-order-24 {
    order: 24;
  }
  html[data-theme='light'] .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-23 {
    order: 23;
  }
  html[data-theme='light'] .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-22 {
    order: 22;
  }
  html[data-theme='light'] .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='light'] .ant-col-xl-push-21 {
    left: 87.5%;
  }
  html[data-theme='light'] .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  html[data-theme='light'] .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='light'] .ant-col-xl-order-21 {
    order: 21;
  }
  html[data-theme='light'] .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-20 {
    order: 20;
  }
  html[data-theme='light'] .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-19 {
    order: 19;
  }
  html[data-theme='light'] .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='light'] .ant-col-xl-push-18 {
    left: 75%;
  }
  html[data-theme='light'] .ant-col-xl-pull-18 {
    right: 75%;
  }
  html[data-theme='light'] .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='light'] .ant-col-xl-order-18 {
    order: 18;
  }
  html[data-theme='light'] .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-17 {
    order: 17;
  }
  html[data-theme='light'] .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-16 {
    order: 16;
  }
  html[data-theme='light'] .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='light'] .ant-col-xl-push-15 {
    left: 62.5%;
  }
  html[data-theme='light'] .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  html[data-theme='light'] .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='light'] .ant-col-xl-order-15 {
    order: 15;
  }
  html[data-theme='light'] .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-14 {
    order: 14;
  }
  html[data-theme='light'] .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-13 {
    order: 13;
  }
  html[data-theme='light'] .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='light'] .ant-col-xl-push-12 {
    left: 50%;
  }
  html[data-theme='light'] .ant-col-xl-pull-12 {
    right: 50%;
  }
  html[data-theme='light'] .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='light'] .ant-col-xl-order-12 {
    order: 12;
  }
  html[data-theme='light'] .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-11 {
    order: 11;
  }
  html[data-theme='light'] .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-10 {
    order: 10;
  }
  html[data-theme='light'] .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='light'] .ant-col-xl-push-9 {
    left: 37.5%;
  }
  html[data-theme='light'] .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  html[data-theme='light'] .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='light'] .ant-col-xl-order-9 {
    order: 9;
  }
  html[data-theme='light'] .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-8 {
    order: 8;
  }
  html[data-theme='light'] .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-7 {
    order: 7;
  }
  html[data-theme='light'] .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='light'] .ant-col-xl-push-6 {
    left: 25%;
  }
  html[data-theme='light'] .ant-col-xl-pull-6 {
    right: 25%;
  }
  html[data-theme='light'] .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='light'] .ant-col-xl-order-6 {
    order: 6;
  }
  html[data-theme='light'] .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-5 {
    order: 5;
  }
  html[data-theme='light'] .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-4 {
    order: 4;
  }
  html[data-theme='light'] .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='light'] .ant-col-xl-push-3 {
    left: 12.5%;
  }
  html[data-theme='light'] .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  html[data-theme='light'] .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='light'] .ant-col-xl-order-3 {
    order: 3;
  }
  html[data-theme='light'] .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xl-order-2 {
    order: 2;
  }
  html[data-theme='light'] .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xl-order-1 {
    order: 1;
  }
  html[data-theme='light'] .ant-col-xl-0 {
    display: none;
  }
  html[data-theme='light'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-xl-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-xl-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  html[data-theme='light'] .ant-col-xxl-1,
  html[data-theme='light'] .ant-col-xxl-2,
  html[data-theme='light'] .ant-col-xxl-3,
  html[data-theme='light'] .ant-col-xxl-4,
  html[data-theme='light'] .ant-col-xxl-5,
  html[data-theme='light'] .ant-col-xxl-6,
  html[data-theme='light'] .ant-col-xxl-7,
  html[data-theme='light'] .ant-col-xxl-8,
  html[data-theme='light'] .ant-col-xxl-9,
  html[data-theme='light'] .ant-col-xxl-10,
  html[data-theme='light'] .ant-col-xxl-11,
  html[data-theme='light'] .ant-col-xxl-12,
  html[data-theme='light'] .ant-col-xxl-13,
  html[data-theme='light'] .ant-col-xxl-14,
  html[data-theme='light'] .ant-col-xxl-15,
  html[data-theme='light'] .ant-col-xxl-16,
  html[data-theme='light'] .ant-col-xxl-17,
  html[data-theme='light'] .ant-col-xxl-18,
  html[data-theme='light'] .ant-col-xxl-19,
  html[data-theme='light'] .ant-col-xxl-20,
  html[data-theme='light'] .ant-col-xxl-21,
  html[data-theme='light'] .ant-col-xxl-22,
  html[data-theme='light'] .ant-col-xxl-23,
  html[data-theme='light'] .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='light'] .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='light'] .ant-col-xxl-push-24 {
    left: 100%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-24 {
    right: 100%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='light'] .ant-col-xxl-order-24 {
    order: 24;
  }
  html[data-theme='light'] .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-23 {
    order: 23;
  }
  html[data-theme='light'] .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-22 {
    order: 22;
  }
  html[data-theme='light'] .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='light'] .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='light'] .ant-col-xxl-order-21 {
    order: 21;
  }
  html[data-theme='light'] .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-20 {
    order: 20;
  }
  html[data-theme='light'] .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-19 {
    order: 19;
  }
  html[data-theme='light'] .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='light'] .ant-col-xxl-push-18 {
    left: 75%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-18 {
    right: 75%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='light'] .ant-col-xxl-order-18 {
    order: 18;
  }
  html[data-theme='light'] .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-17 {
    order: 17;
  }
  html[data-theme='light'] .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-16 {
    order: 16;
  }
  html[data-theme='light'] .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='light'] .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='light'] .ant-col-xxl-order-15 {
    order: 15;
  }
  html[data-theme='light'] .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-14 {
    order: 14;
  }
  html[data-theme='light'] .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-13 {
    order: 13;
  }
  html[data-theme='light'] .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='light'] .ant-col-xxl-push-12 {
    left: 50%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-12 {
    right: 50%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='light'] .ant-col-xxl-order-12 {
    order: 12;
  }
  html[data-theme='light'] .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-11 {
    order: 11;
  }
  html[data-theme='light'] .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-10 {
    order: 10;
  }
  html[data-theme='light'] .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='light'] .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='light'] .ant-col-xxl-order-9 {
    order: 9;
  }
  html[data-theme='light'] .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-8 {
    order: 8;
  }
  html[data-theme='light'] .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-7 {
    order: 7;
  }
  html[data-theme='light'] .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='light'] .ant-col-xxl-push-6 {
    left: 25%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-6 {
    right: 25%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='light'] .ant-col-xxl-order-6 {
    order: 6;
  }
  html[data-theme='light'] .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-5 {
    order: 5;
  }
  html[data-theme='light'] .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-4 {
    order: 4;
  }
  html[data-theme='light'] .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='light'] .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='light'] .ant-col-xxl-order-3 {
    order: 3;
  }
  html[data-theme='light'] .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='light'] .ant-col-xxl-order-2 {
    order: 2;
  }
  html[data-theme='light'] .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='light'] .ant-col-xxl-order-1 {
    order: 1;
  }
  html[data-theme='light'] .ant-col-xxl-0 {
    display: none;
  }
  html[data-theme='light'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-xxl-push-0 {
    left: auto;
  }
  html[data-theme='light'] .ant-col-xxl-pull-0 {
    right: auto;
  }
  html[data-theme='light'] .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .ant-col-xxl-order-0 {
    order: 0;
  }
}
html[data-theme='light'] .ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-input-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
html[data-theme='light'] .ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='light'] .ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
html[data-theme='light'] .ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
html[data-theme='light'] .ant-input-group-addon,
html[data-theme='light'] .ant-input-group-wrap,
html[data-theme='light'] .ant-input-group > .ant-input {
  display: table-cell;
}
html[data-theme='light'] .ant-input-group-addon:not(:first-child):not(:last-child),
html[data-theme='light'] .ant-input-group-wrap:not(:first-child):not(:last-child),
html[data-theme='light'] .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
html[data-theme='light'] .ant-input-group-addon,
html[data-theme='light'] .ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
html[data-theme='light'] .ant-input-group-wrap > * {
  display: block !important;
}
html[data-theme='light'] .ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
html[data-theme='light'] .ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
html[data-theme='light'] .ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
html[data-theme='light'] .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #25282e;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
html[data-theme='light'] .ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
html[data-theme='light'] .ant-input-group-addon .ant-select-open .ant-select-selection,
html[data-theme='light'] .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #4261ff;
}
html[data-theme='light'] .ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
html[data-theme='light'] .ant-input-group > .ant-input:first-child,
html[data-theme='light'] .ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
html[data-theme='light'] .ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .ant-input-group-addon:first-child {
  border-right: 0;
}
html[data-theme='light'] .ant-input-group-addon:last-child {
  border-left: 0;
}
html[data-theme='light'] .ant-input-group > .ant-input:last-child,
html[data-theme='light'] .ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
html[data-theme='light'] .ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-input-group-lg .ant-input,
html[data-theme='light'] .ant-input-group-lg > .ant-input-group-addon {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-input-group-sm .ant-input,
html[data-theme='light'] .ant-input-group-sm > .ant-input-group-addon {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-input-group-lg .ant-select-selection--single {
  height: 30px;
}
html[data-theme='light'] .ant-input-group-sm .ant-select-selection--single {
  height: 30px;
}
html[data-theme='light'] .ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact::before,
html[data-theme='light'] .ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact::after {
  clear: both;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact::before,
html[data-theme='light'] .ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact::after {
  clear: both;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
html[data-theme='light'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-right-width: 1px;
  border-radius: 0;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
  z-index: 1;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
  z-index: 1;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > *:first-child,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > *:last-child,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
html[data-theme='light'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
html[data-theme='light'] .ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
html[data-theme='light'] .ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
html[data-theme='light'] .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-prefix,
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  color: #25282e;
  line-height: 0;
  transform: translateY(-50%);
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
html[data-theme='light'] .ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
html[data-theme='light'] .ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-input-password-icon:hover {
  color: #333;
}
html[data-theme='light'] .ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: top;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-input-clear-icon + i {
  margin-left: 6px;
}
html[data-theme='light'] .ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
html[data-theme='light'] .ant-input-search-enter-button input {
  border-right: 0;
}
html[data-theme='light'] .ant-input-search-enter-button + .ant-input-group-addon,
html[data-theme='light'] .ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
html[data-theme='light'] .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
html[data-theme='light'] .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .ant-input-number {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #babece;
  border-radius: 2px;
}
html[data-theme='light'] .ant-input-number::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input-number::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input-number::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input-number:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-input-number-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-input-number-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
html[data-theme='light'] .ant-input-number-handler:active {
  background: #f4f4f4;
}
html[data-theme='light'] .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
html[data-theme='light'] .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #6b89ff;
}
html[data-theme='light'] .ant-input-number-handler-up-inner,
html[data-theme='light'] .ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
html[data-theme='light'] .ant-input-number-handler-up-inner > *,
html[data-theme='light'] .ant-input-number-handler-down-inner > * {
  line-height: 1;
}
html[data-theme='light'] .ant-input-number-handler-up-inner svg,
html[data-theme='light'] .ant-input-number-handler-down-inner svg {
  display: inline-block;
}
html[data-theme='light'] .ant-input-number-handler-up-inner::before,
html[data-theme='light'] .ant-input-number-handler-down-inner::before {
  display: none;
}
html[data-theme='light'] .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
html[data-theme='light'] .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
html[data-theme='light'] .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
html[data-theme='light'] .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
html[data-theme='light'] .ant-input-number:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input-number-focused {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
html[data-theme='light'] .ant-input-number-input {
  width: 100%;
  height: 28px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield;
}
html[data-theme='light'] .ant-input-number-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input-number-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-input-number-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
html[data-theme='light'] .ant-input-number-lg input {
  height: 28px;
}
html[data-theme='light'] .ant-input-number-sm {
  padding: 0;
}
html[data-theme='light'] .ant-input-number-sm input {
  height: 28px;
  padding: 0 7px;
}
html[data-theme='light'] .ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #babece;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
html[data-theme='light'] .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
html[data-theme='light'] .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
html[data-theme='light'] :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
html[data-theme='light'] :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
html[data-theme='light'] .ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
html[data-theme='light'] .ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
html[data-theme='light'] .ant-input-number-handler-up {
  cursor: pointer;
}
html[data-theme='light'] .ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
html[data-theme='light'] .ant-input-number-handler-up:hover {
  height: 60% !important;
}
html[data-theme='light'] .ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #babece;
  cursor: pointer;
}
html[data-theme='light'] .ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
html[data-theme='light'] .ant-input-number-handler-down:hover {
  height: 60% !important;
}
html[data-theme='light'] .ant-input-number-handler-up-disabled,
html[data-theme='light'] .ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
html[data-theme='light'] .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
html[data-theme='light'] .ant-layout,
html[data-theme='light'] .ant-layout * {
  box-sizing: border-box;
}
html[data-theme='light'] .ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
html[data-theme='light'] .ant-layout.ant-layout-has-sider > .ant-layout,
html[data-theme='light'] .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
html[data-theme='light'] .ant-layout-header,
html[data-theme='light'] .ant-layout-footer {
  flex: 0 0 auto;
}
html[data-theme='light'] .ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
html[data-theme='light'] .ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
html[data-theme='light'] .ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
html[data-theme='light'] .ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
html[data-theme='light'] .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
html[data-theme='light'] .ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
html[data-theme='light'] .ant-layout-sider-right {
  order: 1;
}
html[data-theme='light'] .ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
html[data-theme='light'] .ant-layout-sider-zero-width > * {
  overflow: hidden;
}
html[data-theme='light'] .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
html[data-theme='light'] .ant-layout-sider-zero-width-trigger-right {
  left: -36px;
}
html[data-theme='light'] .ant-layout-sider-light {
  background: #fff;
}
html[data-theme='light'] .ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
html[data-theme='light'] .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
html[data-theme='light'] .ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
html[data-theme='light'] .ant-list * {
  outline: none;
}
html[data-theme='light'] .ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
html[data-theme='light'] .ant-list-more {
  margin-top: 12px;
  text-align: center;
}
html[data-theme='light'] .ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
html[data-theme='light'] .ant-list-spin {
  min-height: 40px;
  text-align: center;
}
html[data-theme='light'] .ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
html[data-theme='light'] .ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-list-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
html[data-theme='light'] .ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  font-size: 0;
}
html[data-theme='light'] .ant-list-item-meta-avatar {
  margin-right: 16px;
}
html[data-theme='light'] .ant-list-item-meta-content {
  flex: 1 0;
}
html[data-theme='light'] .ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='light'] .ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
html[data-theme='light'] .ant-list-item-meta-title > a:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='light'] .ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
html[data-theme='light'] .ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
html[data-theme='light'] .ant-list-item-action > li:first-child {
  padding-left: 0;
}
html[data-theme='light'] .ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-list-header {
  background: transparent;
}
html[data-theme='light'] .ant-list-footer {
  background: transparent;
}
html[data-theme='light'] .ant-list-header,
html[data-theme='light'] .ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
html[data-theme='light'] .ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
html[data-theme='light'] .ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
html[data-theme='light'] .ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
html[data-theme='light'] .ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
html[data-theme='light'] .ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item {
  align-items: initial;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
html[data-theme='light'] .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
html[data-theme='light'] .ant-list-grid .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
html[data-theme='light'] .ant-list-item-no-flex {
  display: block;
}
html[data-theme='light'] .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
html[data-theme='light'] .ant-list-bordered {
  border: 1px solid #babece;
  border-radius: 2px;
}
html[data-theme='light'] .ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
html[data-theme='light'] .ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
html[data-theme='light'] .ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
html[data-theme='light'] .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
html[data-theme='light'] .ant-list-bordered.ant-list-sm .ant-list-header,
html[data-theme='light'] .ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
html[data-theme='light'] .ant-list-bordered.ant-list-lg .ant-list-header,
html[data-theme='light'] .ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  html[data-theme='light'] .ant-list-item-action {
    margin-left: 24px;
  }
  html[data-theme='light'] .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  html[data-theme='light'] .ant-list-item {
    flex-wrap: wrap;
  }
  html[data-theme='light'] .ant-list-item-action {
    margin-left: 12px;
  }
  html[data-theme='light'] .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  html[data-theme='light'] .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  html[data-theme='light'] .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
html[data-theme='light'] .ant-mentions-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 30px;
  padding: 0;
  line-height: 1.5;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-mentions-wrapper .ant-mentions-editor {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-mentions-wrapper .ant-mentions-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
html[data-theme='light'] .ant-mentions-wrapper.ant-mentions-active:not(.disabled) .ant-mentions-editor {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-mentions-wrapper.disabled .ant-mentions-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper.disabled .ant-mentions-editor:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
html[data-theme='light'] .ant-mentions-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #85899d;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
html[data-theme='light'] .ant-mentions-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-mentions-dropdown-placement-top {
  margin-top: -0.1em;
}
html[data-theme='light'] .ant-mentions-dropdown-notfound.ant-mentions-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-mentions-dropdown-notfound.ant-mentions-dropdown-item .anticon-loading {
  display: block;
  color: #4261ff;
  text-align: center;
}
html[data-theme='light'] .ant-mentions-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
html[data-theme='light'] .ant-mentions-dropdown-item:hover {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-mentions-dropdown-item.focus,
html[data-theme='light'] .ant-mentions-dropdown-item-active {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-mentions-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-mentions-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-mentions-dropdown-item-selected,
html[data-theme='light'] .ant-mentions-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
html[data-theme='light'] .ant-mentions-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-mentions {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  white-space: pre-wrap;
  padding: 0;
  overflow: hidden;
  vertical-align: bottom;
}
html[data-theme='light'] .ant-mentions::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-mentions::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-mentions::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-mentions-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-mentions-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-mentions-disabled > textarea:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-mentions-focused {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-mentions > textarea,
html[data-theme='light'] .ant-mentions-measure {
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: initial;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
html[data-theme='light'] .ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
html[data-theme='light'] .ant-mentions > textarea:read-only {
  cursor: default;
}
html[data-theme='light'] .ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
html[data-theme='light'] .ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-mentions-dropdown-hidden {
  display: none;
}
html[data-theme='light'] .ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  min-width: 100px;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item:hover {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='light'] .ant-mentions-dropdown-menu-item-active {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: none;
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
html[data-theme='light'] .ant-menu::before,
html[data-theme='light'] .ant-menu::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-menu::after {
  clear: both;
}
html[data-theme='light'] .ant-menu::before,
html[data-theme='light'] .ant-menu::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-menu::after {
  clear: both;
}
html[data-theme='light'] .ant-menu ul,
html[data-theme='light'] .ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-menu-hidden {
  display: none;
}
html[data-theme='light'] .ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-menu-submenu,
html[data-theme='light'] .ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-item:active,
html[data-theme='light'] .ant-menu-submenu-title:active {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-menu-item > a:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
html[data-theme='light'] .ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-menu-item:hover,
html[data-theme='light'] .ant-menu-item-active,
html[data-theme='light'] .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
html[data-theme='light'] .ant-menu-submenu-active,
html[data-theme='light'] .ant-menu-submenu-title:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-horizontal .ant-menu-item,
html[data-theme='light'] .ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item:hover,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item-active,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
html[data-theme='light'] .ant-menu-item-selected {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-item-selected > a,
html[data-theme='light'] .ant-menu-item-selected > a:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-menu-inline,
html[data-theme='light'] .ant-menu-vertical,
html[data-theme='light'] .ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-menu-vertical.ant-menu-sub,
html[data-theme='light'] .ant-menu-vertical-left.ant-menu-sub,
html[data-theme='light'] .ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  transform-origin: 0 0;
}
html[data-theme='light'] .ant-menu-vertical.ant-menu-sub .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
html[data-theme='light'] .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
html[data-theme='light'] .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
html[data-theme='light'] .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
html[data-theme='light'] .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
html[data-theme='light'] .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
html[data-theme='light'] .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
html[data-theme='light'] .ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
html[data-theme='light'] .ant-menu-item,
html[data-theme='light'] .ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-item .anticon,
html[data-theme='light'] .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-item .anticon + span,
html[data-theme='light'] .ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 2px;
}
html[data-theme='light'] .ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
html[data-theme='light'] .ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
html[data-theme='light'] .ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
html[data-theme='light'] .ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background: rgba(0, 0, 0, 0.65) \9;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #4261ff, #4261ff);
}
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
html[data-theme='light'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
html[data-theme='light'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
html[data-theme='light'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
html[data-theme='light'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-submenu-selected,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-submenu-selected,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-submenu-selected > a,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-submenu-selected > a,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item:hover,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu:hover,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item-active,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu-active,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item-open,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu-open,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item-selected,
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #4261ff;
  border-bottom: 2px solid #4261ff;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
html[data-theme='light'] .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #4261ff;
}
html[data-theme='light'] .ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-item,
html[data-theme='light'] .ant-menu-inline .ant-menu-item {
  position: relative;
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-item::after,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-item::after,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-item::after,
html[data-theme='light'] .ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #4261ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-item,
html[data-theme='light'] .ant-menu-inline .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-submenu,
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-submenu,
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-submenu,
html[data-theme='light'] .ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.01px;
}
html[data-theme='light'] .ant-menu-vertical .ant-menu-item:not(:last-child),
html[data-theme='light'] .ant-menu-vertical-left .ant-menu-item:not(:last-child),
html[data-theme='light'] .ant-menu-vertical-right .ant-menu-item:not(:last-child),
html[data-theme='light'] .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
html[data-theme='light'] .ant-menu-vertical > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-left > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical-right > .ant-menu-item,
html[data-theme='light'] .ant-menu-inline > .ant-menu-item,
html[data-theme='light'] .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
html[data-theme='light'] .ant-menu-inline {
  width: 100%;
}
html[data-theme='light'] .ant-menu-inline .ant-menu-selected::after,
html[data-theme='light'] .ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-menu-inline .ant-menu-item,
html[data-theme='light'] .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
html[data-theme='light'] .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
html[data-theme='light'] .ant-menu-inline-collapsed {
  width: 80px;
}
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item .anticon,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
html[data-theme='light'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
html[data-theme='light'] .ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
html[data-theme='light'] .ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
html[data-theme='light'] .ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
html[data-theme='light'] .ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
html[data-theme='light'] .ant-menu-item-group-list .ant-menu-item,
html[data-theme='light'] .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
html[data-theme='light'] .ant-menu-root.ant-menu-vertical,
html[data-theme='light'] .ant-menu-root.ant-menu-vertical-left,
html[data-theme='light'] .ant-menu-root.ant-menu-vertical-right,
html[data-theme='light'] .ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
html[data-theme='light'] .ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-menu-sub.ant-menu-inline > .ant-menu-item,
html[data-theme='light'] .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
html[data-theme='light'] .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
html[data-theme='light'] .ant-menu-item-disabled,
html[data-theme='light'] .ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-menu-item-disabled > a,
html[data-theme='light'] .ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
html[data-theme='light'] .ant-menu-item-disabled > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
html[data-theme='light'] .ant-menu-dark,
html[data-theme='light'] .ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
html[data-theme='light'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-group-title,
html[data-theme='light'] .ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
html[data-theme='light'] .ant-menu-dark.ant-menu-inline,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-left,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-inline .ant-menu-item,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical .ant-menu-item,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
html[data-theme='light'] .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
html[data-theme='light'] .ant-menu-dark.ant-menu-inline .ant-menu-item,
html[data-theme='light'] .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-selected > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
html[data-theme='light'] .ant-menu.ant-menu-dark .ant-menu-item-selected,
html[data-theme='light'] .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-disabled,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-disabled,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-disabled > a,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
html[data-theme='light'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='light'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='light'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
html[data-theme='light'] .ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
html[data-theme='light'] .ant-message-notice {
  padding: 8px;
  text-align: center;
}
html[data-theme='light'] .ant-message-notice:first-child {
  margin-top: -8px;
}
html[data-theme='light'] .ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: none;
  pointer-events: all;
}
html[data-theme='light'] .ant-message-success .anticon {
  color: #4261ff;
}
html[data-theme='light'] .ant-message-error .anticon {
  color: #ff376b;
}
html[data-theme='light'] .ant-message-warning .anticon {
  color: #babece;
}
html[data-theme='light'] .ant-message-info .anticon,
html[data-theme='light'] .ant-message-loading .anticon {
  color: #4261ff;
}
html[data-theme='light'] .ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
html[data-theme='light'] .ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
html[data-theme='light'] .ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
}
html[data-theme='light'] .ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
html[data-theme='light'] .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
html[data-theme='light'] .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
html[data-theme='light'] .ant-modal-close:focus,
html[data-theme='light'] .ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
html[data-theme='light'] .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
html[data-theme='light'] .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: tranparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
html[data-theme='light'] .ant-modal.zoom-enter,
html[data-theme='light'] .ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
html[data-theme='light'] .ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(37, 40, 46, 0.5);
  filter: alpha(opacity=50);
}
html[data-theme='light'] .ant-modal-mask-hidden {
  display: none;
}
html[data-theme='light'] .ant-modal-open {
  overflow: hidden;
}
html[data-theme='light'] .ant-modal-centered {
  text-align: center;
}
html[data-theme='light'] .ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
html[data-theme='light'] .ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  html[data-theme='light'] .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  html[data-theme='light'] .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
html[data-theme='light'] .ant-modal-confirm .ant-modal-header {
  display: none;
}
html[data-theme='light'] .ant-modal-confirm .ant-modal-close {
  display: none;
}
html[data-theme='light'] .ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
html[data-theme='light'] .ant-modal-confirm-body-wrapper {
  zoom: 1;
}
html[data-theme='light'] .ant-modal-confirm-body-wrapper::before,
html[data-theme='light'] .ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-modal-confirm-body-wrapper::after {
  clear: both;
}
html[data-theme='light'] .ant-modal-confirm-body-wrapper::before,
html[data-theme='light'] .ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-modal-confirm-body-wrapper::after {
  clear: both;
}
html[data-theme='light'] .ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
html[data-theme='light'] .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
html[data-theme='light'] .ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
html[data-theme='light'] .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
html[data-theme='light'] .ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
html[data-theme='light'] .ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
html[data-theme='light'] .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff376b;
}
html[data-theme='light'] .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
html[data-theme='light'] .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #babece;
}
html[data-theme='light'] .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #4261ff;
}
html[data-theme='light'] .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #4261ff;
}
html[data-theme='light'] .ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
html[data-theme='light'] .ant-notification-topLeft,
html[data-theme='light'] .ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
html[data-theme='light'] .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='light'] .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='light'] .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
html[data-theme='light'] .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
html[data-theme='light'] .ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
html[data-theme='light'] .ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
html[data-theme='light'] .ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
html[data-theme='light'] .ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
html[data-theme='light'] .ant-notification-notice-description {
  font-size: 14px;
}
html[data-theme='light'] .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
html[data-theme='light'] .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
html[data-theme='light'] .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
html[data-theme='light'] .ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
html[data-theme='light'] .anticon.ant-notification-notice-icon-success {
  color: #4261ff;
}
html[data-theme='light'] .anticon.ant-notification-notice-icon-info {
  color: #4261ff;
}
html[data-theme='light'] .anticon.ant-notification-notice-icon-warning {
  color: #babece;
}
html[data-theme='light'] .anticon.ant-notification-notice-icon-error {
  color: #ff376b;
}
html[data-theme='light'] .ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
html[data-theme='light'] .ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
html[data-theme='light'] .ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
html[data-theme='light'] .ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
html[data-theme='light'] .ant-notification-fade-enter,
html[data-theme='light'] .ant-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='light'] .ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
html[data-theme='light'] .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='light'] .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
html[data-theme='light'] .ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
html[data-theme='light'] .ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background: #fff;
}
html[data-theme='light'] .ant-page-header.ant-page-header-has-footer {
  padding-bottom: 0;
}
html[data-theme='light'] .ant-page-header-back {
  display: inline-block;
  padding: 4px 0;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;
}
html[data-theme='light'] .ant-page-header-back-button {
  color: #4261ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-page-header-back-button:focus,
html[data-theme='light'] .ant-page-header-back-button:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-page-header-back-button:active {
  color: #2e44d9;
}
html[data-theme='light'] .ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
}
html[data-theme='light'] .ant-page-header .ant-breadcrumb {
  margin-bottom: 12px;
}
html[data-theme='light'] .ant-page-header-title-view {
  display: inline-block;
}
html[data-theme='light'] .ant-page-header-title-view-title {
  display: inline-block;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
}
html[data-theme='light'] .ant-page-header-title-view-sub-title {
  display: inline-block;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.8;
}
html[data-theme='light'] .ant-page-header-title-view-tags {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='light'] .ant-page-header-title-view-extra {
  position: absolute;
  top: 16px;
  right: 24px;
}
html[data-theme='light'] .ant-page-header-title-view-extra > * {
  margin-right: 8px;
}
html[data-theme='light'] .ant-page-header-title-view-extra > *:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-page-header-content-view {
  padding-top: 12px;
}
html[data-theme='light'] .ant-page-header-footer {
  margin: 0 -8px;
  padding-top: 24px;
}
html[data-theme='light'] .ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
html[data-theme='light'] .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 8px;
  padding-top: 0;
}
html[data-theme='light'] .ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-pagination ul,
html[data-theme='light'] .ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
html[data-theme='light'] .ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
html[data-theme='light'] .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
html[data-theme='light'] .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
}
html[data-theme='light'] .ant-pagination-item:focus,
html[data-theme='light'] .ant-pagination-item:hover {
  border-color: #4261ff;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-pagination-item:focus a,
html[data-theme='light'] .ant-pagination-item:hover a {
  color: #4261ff;
}
html[data-theme='light'] .ant-pagination-item-active {
  font-weight: 500;
  background: transparent;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-pagination-item-active a {
  color: #4261ff;
}
html[data-theme='light'] .ant-pagination-item-active:focus,
html[data-theme='light'] .ant-pagination-item-active:hover {
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-pagination-item-active:focus a,
html[data-theme='light'] .ant-pagination-item-active:hover a {
  color: #6b89ff;
}
html[data-theme='light'] .ant-pagination-jump-prev,
html[data-theme='light'] .ant-pagination-jump-next {
  outline: 0;
}
html[data-theme='light'] .ant-pagination-jump-prev .ant-pagination-item-container,
html[data-theme='light'] .ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
html[data-theme='light'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  color: #4261ff;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
html[data-theme='light'] :root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
html[data-theme='light'] :root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
html[data-theme='light'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
html[data-theme='light'] .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
html[data-theme='light'] .ant-pagination-prev,
html[data-theme='light'] .ant-pagination-jump-prev,
html[data-theme='light'] .ant-pagination-jump-next {
  margin-right: 8px;
}
html[data-theme='light'] .ant-pagination-prev,
html[data-theme='light'] .ant-pagination-next,
html[data-theme='light'] .ant-pagination-jump-prev,
html[data-theme='light'] .ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-pagination-prev,
html[data-theme='light'] .ant-pagination-next {
  outline: 0;
}
html[data-theme='light'] .ant-pagination-prev a,
html[data-theme='light'] .ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  user-select: none;
}
html[data-theme='light'] .ant-pagination-prev:hover a,
html[data-theme='light'] .ant-pagination-next:hover a {
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-pagination-prev:focus .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-next:focus .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-prev:hover .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-next:hover .ant-pagination-item-link {
  color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-pagination-disabled,
html[data-theme='light'] .ant-pagination-disabled:hover,
html[data-theme='light'] .ant-pagination-disabled:focus {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination-disabled a,
html[data-theme='light'] .ant-pagination-disabled:hover a,
html[data-theme='light'] .ant-pagination-disabled:focus a,
html[data-theme='light'] .ant-pagination-disabled .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-disabled:hover .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #babece;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination-slash {
  margin: 0 10px 0 5px;
}
html[data-theme='light'] .ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
html[data-theme='light'] .ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-pagination-options-quick-jumper input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-prev,
html[data-theme='light'] .ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
html[data-theme='light'] .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
html[data-theme='light'] .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-total-text,
html[data-theme='light'] .ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-prev,
html[data-theme='light'] .ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
html[data-theme='light'] .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-jump-prev,
html[data-theme='light'] .ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
html[data-theme='light'] .ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 30px;
  padding: 1px 7px;
  width: 44px;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #babece;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(0, 0, 0, 0.45);
  background: #f5f5f5;
  border-color: #babece;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
html[data-theme='light'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  html[data-theme='light'] .ant-pagination-item-after-jump-prev,
  html[data-theme='light'] .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  html[data-theme='light'] .ant-pagination-options {
    display: none;
  }
}
html[data-theme='light'] .ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
html[data-theme='light'] .ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
html[data-theme='light'] .ant-popover-hidden {
  display: none;
}
html[data-theme='light'] .ant-popover-placement-top,
html[data-theme='light'] .ant-popover-placement-topLeft,
html[data-theme='light'] .ant-popover-placement-topRight {
  padding-bottom: 10px;
}
html[data-theme='light'] .ant-popover-placement-right,
html[data-theme='light'] .ant-popover-placement-rightTop,
html[data-theme='light'] .ant-popover-placement-rightBottom {
  padding-left: 10px;
}
html[data-theme='light'] .ant-popover-placement-bottom,
html[data-theme='light'] .ant-popover-placement-bottomLeft,
html[data-theme='light'] .ant-popover-placement-bottomRight {
  padding-top: 10px;
}
html[data-theme='light'] .ant-popover-placement-left,
html[data-theme='light'] .ant-popover-placement-leftTop,
html[data-theme='light'] .ant-popover-placement-leftBottom {
  padding-right: 10px;
}
html[data-theme='light'] .ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: none;
  box-shadow: 0 0 8px none \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-theme='light'] .ant-popover {
    /* IE10+ */
  }
  html[data-theme='light'] .ant-popover-inner {
    box-shadow: none;
  }
}
html[data-theme='light'] .ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
html[data-theme='light'] .ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #babece;
  font-size: 14px;
}
html[data-theme='light'] .ant-popover-message-title {
  padding-left: 22px;
}
html[data-theme='light'] .ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
html[data-theme='light'] .ant-popover-buttons button {
  margin-left: 8px;
}
html[data-theme='light'] .ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
html[data-theme='light'] .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='light'] .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
html[data-theme='light'] .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
html[data-theme='light'] .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
html[data-theme='light'] .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='light'] .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
html[data-theme='light'] .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
html[data-theme='light'] .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
html[data-theme='light'] .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
html[data-theme='light'] .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
html[data-theme='light'] .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
html[data-theme='light'] .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
html[data-theme='light'] .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
html[data-theme='light'] .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='light'] .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
html[data-theme='light'] .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
html[data-theme='light'] .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
html[data-theme='light'] .ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='light'] .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
html[data-theme='light'] .ant-progress-small.ant-progress-line,
html[data-theme='light'] .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
html[data-theme='light'] .ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
html[data-theme='light'] .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
html[data-theme='light'] .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
html[data-theme='light'] .ant-progress-circle-trail {
  stroke: #f5f5f5;
}
html[data-theme='light'] .ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
  stroke: #4261ff;
}
html[data-theme='light'] .ant-progress-success-bg,
html[data-theme='light'] .ant-progress-bg {
  position: relative;
  background-color: #4261ff;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
html[data-theme='light'] .ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #4261ff;
}
html[data-theme='light'] .ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
html[data-theme='light'] .ant-progress-text .anticon {
  font-size: 14px;
}
html[data-theme='light'] .ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
html[data-theme='light'] .ant-progress-status-exception .ant-progress-bg {
  background-color: #ff376b;
}
html[data-theme='light'] .ant-progress-status-exception .ant-progress-text {
  color: #ff376b;
}
html[data-theme='light'] .ant-progress-status-exception .ant-progress-circle-path {
  stroke: #ff376b;
}
html[data-theme='light'] .ant-progress-status-success .ant-progress-bg {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-progress-status-success .ant-progress-text {
  color: #4261ff;
}
html[data-theme='light'] .ant-progress-status-success .ant-progress-circle-path {
  stroke: #4261ff;
}
html[data-theme='light'] .ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
html[data-theme='light'] .ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
html[data-theme='light'] .ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
html[data-theme='light'] .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff376b;
}
html[data-theme='light'] .ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #4261ff;
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
html[data-theme='light'] .ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
}
html[data-theme='light'] .ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
html[data-theme='light'] .ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-radio-wrapper:hover .ant-radio,
html[data-theme='light'] .ant-radio:hover .ant-radio-inner,
html[data-theme='light'] .ant-radio-input:focus + .ant-radio-inner {
  border-color: #25282e;
}
html[data-theme='light'] .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(37, 40, 46, 0.08);
}
html[data-theme='light'] .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #25282e;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='light'] .ant-radio:hover::after,
html[data-theme='light'] .ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
html[data-theme='light'] .ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #babece;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #25282e;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
html[data-theme='light'] .ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='light'] .ant-radio-checked .ant-radio-inner {
  border-color: #25282e;
}
html[data-theme='light'] .ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #babece !important;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
html[data-theme='light'] .ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 45px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 43px;
  background: #fff;
  border: 1px solid #babece;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
html[data-theme='light'] .ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
html[data-theme='light'] .ant-radio-group-large .ant-radio-button-wrapper {
  height: 30px;
  font-size: 16px;
  line-height: 28px;
}
html[data-theme='light'] .ant-radio-group-small .ant-radio-button-wrapper {
  height: 30px;
  padding: 0 7px;
  line-height: 28px;
}
html[data-theme='light'] .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #babece;
  content: '';
}
html[data-theme='light'] .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #babece;
  border-radius: 2px 0 0 2px;
}
html[data-theme='light'] .ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
html[data-theme='light'] .ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
html[data-theme='light'] .ant-radio-button-wrapper:hover {
  position: relative;
  color: #25282e;
}
html[data-theme='light'] .ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(37, 40, 46, 0.06);
}
html[data-theme='light'] .ant-radio-button-wrapper .ant-radio-inner,
html[data-theme='light'] .ant-radio-button-wrapper input[type='checkbox'],
html[data-theme='light'] .ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #25282e;
  background: #fff;
  border-color: #25282e;
  box-shadow: -1px 0 0 0 #25282e;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked::before {
  background-color: #25282e !important;
  opacity: 0.1;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked:first-child {
  border-color: #25282e;
  box-shadow: none !important;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked:hover {
  color: #6b89ff;
  border-color: #6b89ff;
  box-shadow: -1px 0 0 0 #6b89ff;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked:active {
  color: #2e44d9;
  border-color: #2e44d9;
  box-shadow: -1px 0 0 0 #2e44d9;
}
html[data-theme='light'] .ant-radio-button-wrapper-checked:focus-within {
  outline: 3px solid rgba(37, 40, 46, 0.06);
}
html[data-theme='light'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #25282e;
  border-color: #25282e;
}
html[data-theme='light'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #6b89ff;
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #2e44d9;
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(37, 40, 46, 0.06);
}
html[data-theme='light'] .ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-radio-button-wrapper-disabled:first-child,
html[data-theme='light'] .ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #babece;
}
html[data-theme='light'] .ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #babece;
}
html[data-theme='light'] .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #babece;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  html[data-theme='light'] .ant-radio {
    vertical-align: text-bottom;
  }
}
html[data-theme='light'] .ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
html[data-theme='light'] .ant-rate-disabled .ant-rate-star {
  cursor: default;
}
html[data-theme='light'] .ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
html[data-theme='light'] .ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-right: 8px;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-rate-star > div:focus {
  outline: 0;
}
html[data-theme='light'] .ant-rate-star > div:hover,
html[data-theme='light'] .ant-rate-star > div:focus {
  transform: scale(1.1);
}
html[data-theme='light'] .ant-rate-star-first,
html[data-theme='light'] .ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='light'] .ant-rate-star-first .anticon,
html[data-theme='light'] .ant-rate-star-second .anticon {
  vertical-align: middle;
}
html[data-theme='light'] .ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
html[data-theme='light'] .ant-rate-star-half .ant-rate-star-first,
html[data-theme='light'] .ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
html[data-theme='light'] .ant-rate-star-half .ant-rate-star-first,
html[data-theme='light'] .ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
html[data-theme='light'] .ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
html[data-theme='light'] .ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
}
html[data-theme='light'] .ant-select ul,
html[data-theme='light'] .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
html[data-theme='light'] .ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}
html[data-theme='light'] .ant-select-arrow > * {
  line-height: 1;
}
html[data-theme='light'] .ant-select-arrow svg {
  display: inline-block;
}
html[data-theme='light'] .ant-select-arrow::before {
  display: none;
}
html[data-theme='light'] .ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
html[data-theme='light'] .ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #babece;
  border-top-width: 1.02px;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}
html[data-theme='light'] .ant-select-selection:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-select-focused .ant-select-selection,
html[data-theme='light'] .ant-select-selection:focus,
html[data-theme='light'] .ant-select-selection:active {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
html[data-theme='light'] .ant-select-selection__clear::before {
  display: block;
}
html[data-theme='light'] .ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
html[data-theme='light'] .ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
html[data-theme='light'] .ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection:hover,
html[data-theme='light'] .ant-select-disabled .ant-select-selection:focus,
html[data-theme='light'] .ant-select-disabled .ant-select-selection:active {
  border-color: #babece;
  box-shadow: none;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
html[data-theme='light'] .ant-select-selection--single {
  position: relative;
  height: 30px;
  cursor: pointer;
}
html[data-theme='light'] .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 28px;
}
html[data-theme='light'] .ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
html[data-theme='light'] .ant-select-lg {
  font-size: 16px;
}
html[data-theme='light'] .ant-select-lg .ant-select-selection--single {
  height: 30px;
}
html[data-theme='light'] .ant-select-lg .ant-select-selection__rendered {
  line-height: 28px;
}
html[data-theme='light'] .ant-select-lg .ant-select-selection--multiple {
  min-height: 30px;
}
html[data-theme='light'] .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 22px;
  line-height: 22px;
}
html[data-theme='light'] .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='light'] .ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection--single {
  height: 30px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection__rendered {
  margin: 0 7px;
  line-height: 28px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection--multiple {
  min-height: 30px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 22px;
  line-height: 20px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='light'] .ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='light'] .ant-select-sm .ant-select-selection__clear,
html[data-theme='light'] .ant-select-sm .ant-select-arrow {
  right: 8px;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
html[data-theme='light'] .ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
html[data-theme='light'] .ant-select-selection__placeholder,
html[data-theme='light'] .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #85899d;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-select-search__field__placeholder {
  left: 12px;
}
html[data-theme='light'] .ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 2px;
  outline: 0;
}
html[data-theme='light'] .ant-select-search--inline > i {
  float: right;
}
html[data-theme='light'] .ant-select-selection--multiple {
  min-height: 30px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
html[data-theme='light'] .ant-select-selection--multiple::before,
html[data-theme='light'] .ant-select-selection--multiple::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-select-selection--multiple::after {
  clear: both;
}
html[data-theme='light'] .ant-select-selection--multiple::before,
html[data-theme='light'] .ant-select-selection--multiple::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-select-selection--multiple::after {
  clear: both;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
html[data-theme='light'] .ant-select-selection--multiple > ul > li,
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 22px;
  margin-top: 3px;
  line-height: 20px;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
html[data-theme='light'] :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
html[data-theme='light'] .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='light'] .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='light'] .ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
html[data-theme='light'] .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
html[data-theme='light'] .ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
html[data-theme='light'] .ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}
html[data-theme='light'] .ant-select-open .ant-select-selection {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-select-combobox .ant-select-arrow {
  display: none;
}
html[data-theme='light'] .ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
html[data-theme='light'] .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
html[data-theme='light'] .ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
html[data-theme='light'] .ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
html[data-theme='light'] .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
html[data-theme='light'] .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
html[data-theme='light'] .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
html[data-theme='light'] .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
html[data-theme='light'] .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
html[data-theme='light'] .ant-select-dropdown-hidden {
  display: none;
}
html[data-theme='light'] .ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
html[data-theme='light'] .ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
html[data-theme='light'] .ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-select-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  transform: translateY(-50%);
  transition: all 0.2s;
}
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
html[data-theme='light'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #4261ff;
}
html[data-theme='light'] .ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
html[data-theme='light'] .ant-select-dropdown-container-open .ant-select-dropdown,
html[data-theme='light'] .ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
html[data-theme='light'] .ant-skeleton {
  display: table;
  width: 100%;
}
html[data-theme='light'] .ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
html[data-theme='light'] .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='light'] .ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
html[data-theme='light'] .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
html[data-theme='light'] .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
html[data-theme='light'] .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
html[data-theme='light'] .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
html[data-theme='light'] .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
html[data-theme='light'] .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
html[data-theme='light'] .ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 14px 9px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
html[data-theme='light'] .ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-track {
  width: 4px;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
html[data-theme='light'] .ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
html[data-theme='light'] .ant-slider-with-marks {
  margin-bottom: 28px;
}
html[data-theme='light'] .ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #babece;
  border-radius: 2px;
  transition: background-color 0.3s;
}
html[data-theme='light'] .ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #4261ff;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}
html[data-theme='light'] .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  margin-left: -7px;
  background-color: #fff;
  border: solid 2px #4261ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
html[data-theme='light'] .ant-slider-handle:focus {
  border-color: #6881ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-slider-handle.ant-tooltip-open {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-slider:hover .ant-slider-rail {
  background-color: #babece;
}
html[data-theme='light'] .ant-slider:hover .ant-slider-track {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #94adff;
}
html[data-theme='light'] .ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
html[data-theme='light'] .ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
html[data-theme='light'] .ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
html[data-theme='light'] .ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
html[data-theme='light'] .ant-slider-dot:first-child {
  margin-left: -4px;
}
html[data-theme='light'] .ant-slider-dot:last-child {
  margin-left: -4px;
}
html[data-theme='light'] .ant-slider-dot-active {
  border-color: #a1b0ff;
}
html[data-theme='light'] .ant-slider-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
html[data-theme='light'] .ant-slider-disabled .ant-slider-handle,
html[data-theme='light'] .ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-slider-disabled .ant-slider-mark-text,
html[data-theme='light'] .ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
html[data-theme='light'] .ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #4261ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
html[data-theme='light'] .ant-spin-nested-loading {
  position: relative;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px none;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
html[data-theme='light'] .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
html[data-theme='light'] .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
html[data-theme='light'] .ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
html[data-theme='light'] .ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
html[data-theme='light'] .ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
html[data-theme='light'] .ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
html[data-theme='light'] .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #4261ff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
html[data-theme='light'] .ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
html[data-theme='light'] .ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
html[data-theme='light'] .ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
html[data-theme='light'] .ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
html[data-theme='light'] .ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
html[data-theme='light'] .ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
html[data-theme='light'] .ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
html[data-theme='light'] .ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
html[data-theme='light'] .ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
html[data-theme='light'] .ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html[data-theme='light'] {
    /* IE10+ */
  }
  html[data-theme='light'] .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
html[data-theme='light'] .ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: Tahoma, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='light'] .ant-statistic-content-value-decimal {
  font-size: 16px;
}
html[data-theme='light'] .ant-statistic-content-prefix,
html[data-theme='light'] .ant-statistic-content-suffix {
  display: inline-block;
}
html[data-theme='light'] .ant-statistic-content-prefix {
  margin-right: 4px;
}
html[data-theme='light'] .ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
html[data-theme='light'] .ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
}
html[data-theme='light'] .ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
html[data-theme='light'] .ant-steps-item:last-child {
  flex: none;
}
html[data-theme='light'] .ant-steps-item:last-child > .ant-steps-item-tail,
html[data-theme='light'] .ant-steps-item:last-child > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
html[data-theme='light'] .ant-steps-item-icon,
html[data-theme='light'] .ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='light'] .ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
html[data-theme='light'] .ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #4261ff;
  line-height: 1;
}
html[data-theme='light'] .ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
html[data-theme='light'] .ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
html[data-theme='light'] .ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
html[data-theme='light'] .ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
html[data-theme='light'] .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-steps-item-wait > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #4261ff;
}
html[data-theme='light'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
html[data-theme='light'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-steps-item-process > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-icon {
  background: #4261ff;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
html[data-theme='light'] .ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
html[data-theme='light'] .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #4261ff;
}
html[data-theme='light'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff376b;
}
html[data-theme='light'] .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff376b;
}
html[data-theme='light'] .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff376b;
}
html[data-theme='light'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff376b;
}
html[data-theme='light'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff376b;
}
html[data-theme='light'] .ant-steps-item-error > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='light'] .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff376b;
}
html[data-theme='light'] .ant-steps-item[role='button'] {
  outline: none;
}
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process) {
  cursor: pointer;
}
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-title,
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-description,
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-title,
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-description {
  color: #4261ff;
}
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-icon {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-icon .ant-steps-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
html[data-theme='light'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
html[data-theme='light'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
html[data-theme='light'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
html[data-theme='light'] .ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
html[data-theme='light'] .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
html[data-theme='light'] .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
html[data-theme='light'] .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
html[data-theme='light'] .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
html[data-theme='light'] .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
html[data-theme='light'] .ant-steps-vertical {
  display: block;
}
html[data-theme='light'] .ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
html[data-theme='light'] .ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
html[data-theme='light'] .ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
html[data-theme='light'] .ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
html[data-theme='light'] .ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
html[data-theme='light'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
html[data-theme='light'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
html[data-theme='light'] .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
  display: block;
}
html[data-theme='light'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-small .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
    display: block;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  html[data-theme='light'] .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 51px;
  padding: 3.5px 24px;
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 104px;
  margin-top: 8px;
  text-align: center;
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
html[data-theme='light'] .ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
html[data-theme='light'] .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 40px;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-title {
  line-height: 1.5;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-content {
  width: 140px;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
html[data-theme='light'] .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
html[data-theme='light'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
html[data-theme='light'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}
html[data-theme='light'] .ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 20px;
  line-height: 18px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
html[data-theme='light'] .ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
html[data-theme='light'] .ant-switch-loading-icon,
html[data-theme='light'] .ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
html[data-theme='light'] .ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
html[data-theme='light'] .ant-switch:not(.ant-switch-disabled):active::before,
html[data-theme='light'] .ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
html[data-theme='light'] .ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
html[data-theme='light'] .ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #4261ff;
}
html[data-theme='light'] .ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-switch:focus:hover {
  box-shadow: none;
}
html[data-theme='light'] .ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
html[data-theme='light'] .ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
html[data-theme='light'] .ant-switch-small::after {
  width: 12px;
  height: 12px;
}
html[data-theme='light'] .ant-switch-small:active::before,
html[data-theme='light'] .ant-switch-small:active::after {
  width: 16px;
}
html[data-theme='light'] .ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
html[data-theme='light'] .ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
html[data-theme='light'] .ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
html[data-theme='light'] .ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  transform: scale(0.66667);
}
html[data-theme='light'] .ant-switch-checked {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
html[data-theme='light'] .ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  transform: translateX(-100%);
}
html[data-theme='light'] .ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
html[data-theme='light'] .ant-switch-loading,
html[data-theme='light'] .ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
html[data-theme='light'] .ant-switch-loading *,
html[data-theme='light'] .ant-switch-disabled * {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-switch-loading::before,
html[data-theme='light'] .ant-switch-disabled::before,
html[data-theme='light'] .ant-switch-loading::after,
html[data-theme='light'] .ant-switch-disabled::after {
  cursor: not-allowed;
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}
html[data-theme='light'] .ant-table-wrapper {
  zoom: 1;
}
html[data-theme='light'] .ant-table-wrapper::before,
html[data-theme='light'] .ant-table-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-table-wrapper::after {
  clear: both;
}
html[data-theme='light'] .ant-table-wrapper::before,
html[data-theme='light'] .ant-table-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-table-wrapper::after {
  clear: both;
}
html[data-theme='light'] .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  clear: both;
}
html[data-theme='light'] .ant-table-body {
  transition: opacity 0.3s;
}
html[data-theme='light'] .ant-table-empty .ant-table-body {
  overflow: auto !important;
}
html[data-theme='light'] .ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: collapse;
}
html[data-theme='light'] .ant-table-thead > tr {
  background: #fafafa;
}
html[data-theme='light'] .ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
html[data-theme='light'] .ant-table-thead > tr > th[colspan] {
  text-align: center;
}
html[data-theme='light'] .ant-table-thead > tr > th .anticon-filter,
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-table-thead > tr > th .anticon-filter > svg,
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #4261ff;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
html[data-theme='light'] :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
html[data-theme='light'] :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #4261ff;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
html[data-theme='light'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-has-sorters {
  user-select: none;
}
html[data-theme='light'] .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 2px;
}
html[data-theme='light'] .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 2px;
}
html[data-theme='light'] .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
html[data-theme='light'] .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
html[data-theme='light'] .ant-table-thead > tr,
html[data-theme='light'] .ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
html[data-theme='light'] .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
html[data-theme='light'] .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
html[data-theme='light'] .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-table-footer {
  position: relative;
  padding: 16px 16px;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
html[data-theme='light'] .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-title + .ant-table-content {
  position: relative;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-table-bordered .ant-table-title + .ant-table-content,
html[data-theme='light'] .ant-table-bordered .ant-table-title + .ant-table-content table,
html[data-theme='light'] .ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
html[data-theme='light'] .ant-table-without-column-header .ant-table-title + .ant-table-content,
html[data-theme='light'] .ant-table-without-column-header table {
  border-radius: 0;
}
html[data-theme='light'] .ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 2px;
}
html[data-theme='light'] .ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
html[data-theme='light'] .ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
html[data-theme='light'] .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-tbody > tr > td {
  padding: 16px 16px;
}
html[data-theme='light'] .ant-table-expand-icon-th,
html[data-theme='light'] .ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
html[data-theme='light'] .ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
html[data-theme='light'] .ant-table-header table {
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-table-loading {
  position: relative;
}
html[data-theme='light'] .ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
html[data-theme='light'] .ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
html[data-theme='light'] .ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
html[data-theme='light'] .ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
html[data-theme='light'] .ant-table-bordered .ant-table-header > table,
html[data-theme='light'] .ant-table-bordered .ant-table-body > table,
html[data-theme='light'] .ant-table-bordered .ant-table-fixed-left table,
html[data-theme='light'] .ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
html[data-theme='light'] .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
html[data-theme='light'] .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
html[data-theme='light'] .ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
html[data-theme='light'] .ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
html[data-theme='light'] .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-bordered .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-placeholder {
  position: relative;
  z-index: 1;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-table-placeholder .anticon {
  margin-right: 4px;
}
html[data-theme='light'] .ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
html[data-theme='light'] .ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  border-radius: 2px 2px 0 0;
  box-shadow: none;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #4261ff;
  font-weight: bold;
  text-shadow: 0 0 2px #e6edff;
}
html[data-theme='light'] .ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
html[data-theme='light'] .ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
html[data-theme='light'] .ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
html[data-theme='light'] .ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-filter-dropdown-link {
  color: #4261ff;
}
html[data-theme='light'] .ant-table-filter-dropdown-link:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-table-filter-dropdown-link:active {
  color: #2e44d9;
}
html[data-theme='light'] .ant-table-filter-dropdown-link.confirm {
  float: left;
}
html[data-theme='light'] .ant-table-filter-dropdown-link.clear {
  float: right;
}
html[data-theme='light'] .ant-table-selection {
  white-space: nowrap;
}
html[data-theme='light'] .ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
html[data-theme='light'] .ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
html[data-theme='light'] .ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
html[data-theme='light'] .ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
html[data-theme='light'] .ant-table-row-expand-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 14px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  user-select: none;
}
html[data-theme='light'] .ant-table-row-expanded::after {
  content: '-';
}
html[data-theme='light'] .ant-table-row-collapsed::after {
  content: '+';
}
html[data-theme='light'] .ant-table-row-spaced {
  visibility: hidden;
}
html[data-theme='light'] .ant-table-row-spaced::after {
  content: '.';
}
html[data-theme='light'] tr.ant-table-expanded-row,
html[data-theme='light'] tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
html[data-theme='light'] tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
html[data-theme='light'] .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
html[data-theme='light'] .ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
html[data-theme='light'] .ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
html[data-theme='light'] .ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
html[data-theme='light'] .ant-table-body-inner {
  height: 100%;
}
html[data-theme='light'] .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
html[data-theme='light'] .ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
html[data-theme='light'] .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
html[data-theme='light'] .ant-table-fixed-left,
html[data-theme='light'] .ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: auto;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
html[data-theme='light'] .ant-table-fixed-left table,
html[data-theme='light'] .ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
html[data-theme='light'] .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
html[data-theme='light'] .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
html[data-theme='light'] .ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px none;
}
html[data-theme='light'] .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
html[data-theme='light'] .ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
html[data-theme='light'] .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
html[data-theme='light'] .ant-table-fixed-left,
html[data-theme='light'] .ant-table-fixed-left table {
  border-radius: 2px 0 0 0;
}
html[data-theme='light'] .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
html[data-theme='light'] .ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px none;
}
html[data-theme='light'] .ant-table-fixed-right,
html[data-theme='light'] .ant-table-fixed-right table {
  border-radius: 0 2px 0 0;
}
html[data-theme='light'] .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
html[data-theme='light'] .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
html[data-theme='light'] .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
html[data-theme='light'] .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
html[data-theme='light'] .ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-selection-column,
html[data-theme='light'] .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
html[data-theme='light'] .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
html[data-theme='light'] .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
html[data-theme='light'] .ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
html[data-theme='light'] .ant-table-middle > .ant-table-title,
html[data-theme='light'] .ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
html[data-theme='light'] .ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
html[data-theme='light'] .ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
html[data-theme='light'] .ant-table-small > .ant-table-title,
html[data-theme='light'] .ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
html[data-theme='light'] .ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
html[data-theme='light'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
html[data-theme='light'] .ant-table-small > .ant-table-content .ant-table-header {
  background-color: #fff;
}
html[data-theme='light'] .ant-table-small > .ant-table-content .ant-table-placeholder,
html[data-theme='light'] .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered {
  border-right: 0;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 55px;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 55px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 53px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 55px;
  color: #25282e;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(0, 0, 0, 0.85);
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
html[data-theme='light'] .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
html[data-theme='light'] .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
html[data-theme='light'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
html[data-theme='light'] .ant-tabs-extra-content {
  line-height: 55px;
}
html[data-theme='light'] .ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #25282e;
  border-color: #25282e;
}
html[data-theme='light'] .ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: auto;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
html[data-theme='light'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #4261ff;
}
html[data-theme='light'] .ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  overflow: hidden;
  zoom: 1;
}
html[data-theme='light'] .ant-tabs::before,
html[data-theme='light'] .ant-tabs::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-tabs::after {
  clear: both;
}
html[data-theme='light'] .ant-tabs::before,
html[data-theme='light'] .ant-tabs::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-tabs::after {
  clear: both;
}
html[data-theme='light'] .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 2px;
  background-color: #25282e;
  transform-origin: 0 0;
}
html[data-theme='light'] .ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
html[data-theme='light'] .ant-tabs-nav-container::before,
html[data-theme='light'] .ant-tabs-nav-container::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-tabs-nav-container::after {
  clear: both;
}
html[data-theme='light'] .ant-tabs-nav-container::before,
html[data-theme='light'] .ant-tabs-nav-container::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-tabs-nav-container::after {
  clear: both;
}
html[data-theme='light'] .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
html[data-theme='light'] .ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
html[data-theme='light'] .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
html[data-theme='light'] .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs-tab-prev,
html[data-theme='light'] .ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  pointer-events: none;
}
html[data-theme='light'] .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='light'] .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='light'] .ant-tabs-tab-prev:hover,
html[data-theme='light'] .ant-tabs-tab-next:hover {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-tabs-tab-prev-icon,
html[data-theme='light'] .ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, -50%);
}
html[data-theme='light'] .ant-tabs-tab-prev-icon-target,
html[data-theme='light'] .ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='light'] :root .ant-tabs-tab-prev-icon-target,
html[data-theme='light'] :root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
html[data-theme='light'] .ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-tabs-tab-btn-disabled,
html[data-theme='light'] .ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-tabs-tab-next {
  right: 2px;
}
html[data-theme='light'] .ant-tabs-tab-prev {
  left: 0;
}
html[data-theme='light'] :root .ant-tabs-tab-prev {
  filter: none;
}
html[data-theme='light'] .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
html[data-theme='light'] .ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
html[data-theme='light'] .ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs-nav::before,
html[data-theme='light'] .ant-tabs-nav::after {
  display: table;
  content: ' ';
}
html[data-theme='light'] .ant-tabs-nav::after {
  clear: both;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 20px 0 0;
  padding: 18px 0;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab:hover {
  color: #141519;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab:active {
  color: #050506;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab-disabled,
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab-active {
  color: #050506;
  font-weight: 500;
}
html[data-theme='light'] .ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-top-content,
html[data-theme='light'] .ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
html[data-theme='light'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
html[data-theme='light'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.45s;
}
html[data-theme='light'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
html[data-theme='light'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
html[data-theme='light'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='light'] .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
html[data-theme='light'] .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar-tab-prev,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar-tab-prev,
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar-tab-next,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar-tab-next {
  width: 32px;
  height: 0;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar-tab-next.ant-tabs-tab-arrow-show,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar-tab-next.ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: auto;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  bottom: 0;
  width: 100%;
  height: 32px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-content,
html[data-theme='light'] .ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
html[data-theme='light'] .ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-tabs-top .ant-tabs-ink-bar-animated,
html[data-theme='light'] .ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .ant-tabs-left .ant-tabs-ink-bar-animated,
html[data-theme='light'] .ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='light'] .no-flex > .ant-tabs-content > .ant-tabs-content-animated,
html[data-theme='light'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
html[data-theme='light'] .no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
html[data-theme='light'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
html[data-theme='light'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='light'] .ant-tabs-left-content > .ant-tabs-content-animated,
html[data-theme='light'] .ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
html[data-theme='light'] .ant-tabs-left-content > .ant-tabs-tabpane-inactive,
html[data-theme='light'] .ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
html[data-theme='light'] .ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='light'] .ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #babece;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .ant-tag:hover {
  opacity: 0.85;
}
html[data-theme='light'] .ant-tag,
html[data-theme='light'] .ant-tag a,
html[data-theme='light'] .ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
html[data-theme='light'] .ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] :root .ant-tag .anticon-close {
  font-size: 12px;
}
html[data-theme='light'] .ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
html[data-theme='light'] .ant-tag-has-color {
  border-color: transparent;
}
html[data-theme='light'] .ant-tag-has-color,
html[data-theme='light'] .ant-tag-has-color a,
html[data-theme='light'] .ant-tag-has-color a:hover,
html[data-theme='light'] .ant-tag-has-color .anticon-close,
html[data-theme='light'] .ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
html[data-theme='light'] .ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='light'] .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #4261ff;
}
html[data-theme='light'] .ant-tag-checkable:active,
html[data-theme='light'] .ant-tag-checkable-checked {
  color: #fff;
}
html[data-theme='light'] .ant-tag-checkable-checked {
  background-color: #4261ff;
}
html[data-theme='light'] .ant-tag-checkable:active {
  background-color: #2e44d9;
}
html[data-theme='light'] .ant-tag-hidden {
  display: none;
}
html[data-theme='light'] .ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
html[data-theme='light'] .ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
html[data-theme='light'] .ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
html[data-theme='light'] .ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
html[data-theme='light'] .ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
html[data-theme='light'] .ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
html[data-theme='light'] .ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
html[data-theme='light'] .ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
html[data-theme='light'] .ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
html[data-theme='light'] .ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
html[data-theme='light'] .ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
html[data-theme='light'] .ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
html[data-theme='light'] .ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
html[data-theme='light'] .ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
html[data-theme='light'] .ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
html[data-theme='light'] .ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
html[data-theme='light'] .ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
html[data-theme='light'] .ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
html[data-theme='light'] .ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
html[data-theme='light'] .ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
html[data-theme='light'] .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
html[data-theme='light'] .ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
html[data-theme='light'] .ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
html[data-theme='light'] .ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
html[data-theme='light'] .ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
html[data-theme='light'] .ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
html[data-theme='light'] .ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='light'] .ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='light'] .ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
html[data-theme='light'] .ant-time-picker-panel-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-panel-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-panel-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-panel-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-panel-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-panel-input-wrap {
  position: relative;
  box-sizing: border-box;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-time-picker-panel-input-invalid {
  border-color: #ff376b;
}
html[data-theme='light'] .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
html[data-theme='light'] .ant-time-picker-panel-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
html[data-theme='light'] .ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
html[data-theme='light'] .ant-time-picker-panel-select:last-child {
  border-right: 0;
}
html[data-theme='light'] .ant-time-picker-panel-select:only-child {
  width: 100%;
}
html[data-theme='light'] .ant-time-picker-panel-select ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
html[data-theme='light'] .ant-time-picker-panel-select li {
  box-sizing: content-box;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: background 0.3s;
  user-select: none;
}
html[data-theme='light'] .ant-time-picker-panel-select li:hover {
  background: #f0f5ff;
}
html[data-theme='light'] li.ant-time-picker-panel-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
html[data-theme='light'] li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
html[data-theme='light'] li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
html[data-theme='light'] .ant-time-picker-panel-combobox {
  zoom: 1;
}
html[data-theme='light'] .ant-time-picker-panel-combobox::before,
html[data-theme='light'] .ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-time-picker-panel-combobox::after {
  clear: both;
}
html[data-theme='light'] .ant-time-picker-panel-combobox::before,
html[data-theme='light'] .ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-time-picker-panel-combobox::after {
  clear: both;
}
html[data-theme='light'] .ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
html[data-theme='light'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='light'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
html[data-theme='light'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='light'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='light'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='light'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='light'] .ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
html[data-theme='light'] .ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #25282e;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #babece;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-time-picker-input::-moz-placeholder {
  color: #85899d;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input:-ms-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-time-picker-input::-webkit-input-placeholder {
  color: #85899d;
}
html[data-theme='light'] .ant-time-picker-input::-webkit-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input::-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input:-moz-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input:-ms-input-placeholder {
  color: #85899d !important;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input:hover {
  border-color: #6b89ff;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-time-picker-input:focus {
  border-color: #6b89ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(66, 97, 255, 0.2);
}
html[data-theme='light'] .ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input-disabled:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='light'] .ant-time-picker-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-time-picker-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='light'] .ant-time-picker-input[disabled]:hover {
  border-color: #ced1db;
  border-right-width: 1px !important;
}
html[data-theme='light'] .ant-time-picker-open {
  opacity: 0;
}
html[data-theme='light'] .ant-time-picker-icon,
html[data-theme='light'] .ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}
html[data-theme='light'] .ant-time-picker-icon .ant-time-picker-clock-icon,
html[data-theme='light'] .ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
html[data-theme='light'] .ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='light'] .ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='light'] .ant-time-picker-large .ant-time-picker-input {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='light'] .ant-time-picker-small .ant-time-picker-input {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='light'] .ant-time-picker-small .ant-time-picker-icon,
html[data-theme='light'] .ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
html[data-theme='light'] .ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
html[data-theme='light'] .ant-timeline-item-tail {
  position: absolute;
  top: 0.75em;
  left: 4px;
  height: 100%;
  border-left: 2px solid #e8e8e8;
}
html[data-theme='light'] .ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
}
html[data-theme='light'] .ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
html[data-theme='light'] .ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
html[data-theme='light'] .ant-timeline-item-head-blue {
  color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-timeline-item-head-red {
  color: #ff376b;
  border-color: #ff376b;
}
html[data-theme='light'] .ant-timeline-item-head-green {
  color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
html[data-theme='light'] .ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
}
html[data-theme='light'] .ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
html[data-theme='light'] .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-head,
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: 50%;
  width: 50%;
  text-align: left;
}
html[data-theme='light'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 50%;
  left: -30px;
  width: 50%;
  margin-right: 18px;
  text-align: right;
}
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: 100%;
}
html[data-theme='light'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 0;
  left: -30px;
  width: 100%;
}
html[data-theme='light'] .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
html[data-theme='light'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
html[data-theme='light'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
html[data-theme='light'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
html[data-theme='light'] .ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 300px;
  visibility: visible;
}
html[data-theme='light'] .ant-tooltip-hidden {
  display: none;
}
html[data-theme='light'] .ant-tooltip-placement-top,
html[data-theme='light'] .ant-tooltip-placement-topLeft,
html[data-theme='light'] .ant-tooltip-placement-topRight {
  padding-bottom: 10px;
}
html[data-theme='light'] .ant-tooltip-placement-right,
html[data-theme='light'] .ant-tooltip-placement-rightTop,
html[data-theme='light'] .ant-tooltip-placement-rightBottom {
  padding-left: 10px;
}
html[data-theme='light'] .ant-tooltip-placement-bottom,
html[data-theme='light'] .ant-tooltip-placement-bottomLeft,
html[data-theme='light'] .ant-tooltip-placement-bottomRight {
  padding-top: 10px;
}
html[data-theme='light'] .ant-tooltip-placement-left,
html[data-theme='light'] .ant-tooltip-placement-leftTop,
html[data-theme='light'] .ant-tooltip-placement-leftBottom {
  padding-right: 10px;
}
html[data-theme='light'] .ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #25282e;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='light'] .ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
html[data-theme='light'] .ant-tooltip-placement-top .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 7px 7px 0;
  border-top-color: #9dabbf;
}
html[data-theme='light'] .ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -7px;
}
html[data-theme='light'] .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}
html[data-theme='light'] .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}
html[data-theme='light'] .ant-tooltip-placement-right .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 7px 7px 7px 0;
  border-right-color: #9dabbf;
}
html[data-theme='light'] .ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -7px;
}
html[data-theme='light'] .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}
html[data-theme='light'] .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}
html[data-theme='light'] .ant-tooltip-placement-left .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 7px 0 7px 7px;
  border-left-color: #9dabbf;
}
html[data-theme='light'] .ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -7px;
}
html[data-theme='light'] .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}
html[data-theme='light'] .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}
html[data-theme='light'] .ant-tooltip-placement-bottom .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
html[data-theme='light'] .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 7px 7px;
  border-bottom-color: #9dabbf;
}
html[data-theme='light'] .ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -7px;
}
html[data-theme='light'] .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}
html[data-theme='light'] .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}
html[data-theme='light'] .ant-transfer-customize-list {
  display: flex;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
html[data-theme='light'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
html[data-theme='light'] .ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
html[data-theme='light'] .ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
html[data-theme='light'] .ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #babece;
  border-radius: 2px;
}
html[data-theme='light'] .ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
html[data-theme='light'] .ant-transfer-list-search {
  padding: 0 8px;
}
html[data-theme='light'] .ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 30px;
  text-align: center;
}
html[data-theme='light'] .ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
html[data-theme='light'] .ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] span.ant-transfer-list-search-action {
  pointer-events: none;
}
html[data-theme='light'] .ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 12px 9px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
html[data-theme='light'] .ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
html[data-theme='light'] .ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
html[data-theme='light'] .ant-transfer-list-body-with-search {
  padding-top: 54px;
}
html[data-theme='light'] .ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
html[data-theme='light'] .ant-transfer-list-content > .LazyLoad {
  animation: transferHighlightIn 1s;
}
html[data-theme='light'] .ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-transfer-list-content-item > span {
  padding-right: 0;
}
html[data-theme='light'] .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f0f5ff;
  cursor: pointer;
}
html[data-theme='light'] .ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  transform: translateY(-50%);
}
html[data-theme='light'] .ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 15px;
}
html[data-theme='light'] .ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='light'] .ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
html[data-theme='light'] .ant-transfer-operation .ant-btn {
  display: block;
}
html[data-theme='light'] .ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
html[data-theme='light'] .ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@keyframes transferHighlightIn {
  0% {
    background: #e6edff;
  }
  100% {
    background: transparent;
  }
}
html[data-theme='light'] .ant-tree.ant-tree-directory {
  position: relative;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-switcher,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #f0f5ff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
html[data-theme='light'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #4261ff;
}
html[data-theme='light'] .ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
html[data-theme='light'] .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
html[data-theme='light'] .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4261ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='light'] .ant-tree-checkbox:hover::after,
html[data-theme='light'] .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
html[data-theme='light'] .ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='light'] .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='light'] .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #babece !important;
}
html[data-theme='light'] .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='light'] .ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='light'] .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='light'] .ant-tree-checkbox-wrapper + span,
html[data-theme='light'] .ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='light'] .ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='light'] .ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='light'] .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4261ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='light'] .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
}
html[data-theme='light'] .ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
html[data-theme='light'] .ant-tree ol,
html[data-theme='light'] .ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='light'] .ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
html[data-theme='light'] .ant-tree li span[draggable],
html[data-theme='light'] .ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
html[data-theme='light'] .ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #4261ff;
  opacity: 0.8;
}
html[data-theme='light'] .ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #4261ff;
}
html[data-theme='light'] .ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #4261ff;
}
html[data-theme='light'] .ant-tree li.filter-node > span {
  color: #ff376b !important;
  font-weight: 500 !important;
}
html[data-theme='light'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
html[data-theme='light'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #4261ff;
  font-size: 14px;
  transform: none;
}
html[data-theme='light'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
html[data-theme='light'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] :root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
html[data-theme='light'] :root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
html[data-theme='light'] .ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
html[data-theme='light'] .ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e6edff;
}
html[data-theme='light'] .ant-tree li span.ant-tree-checkbox {
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher,
html[data-theme='light'] .ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher {
  position: relative;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='light'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='light'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='light'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='light'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
html[data-theme='light'] .ant-tree li:last-child > span.ant-tree-switcher::before,
html[data-theme='light'] .ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
html[data-theme='light'] .ant-tree > li:first-child {
  padding-top: 7px;
}
html[data-theme='light'] .ant-tree > li:last-child {
  padding-bottom: 7px;
}
html[data-theme='light'] .ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
html[data-theme='light'] .ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
html[data-theme='light'] li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
html[data-theme='light'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
html[data-theme='light'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='light'] .ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='light'] .ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li {
  position: relative;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #fff;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  margin: 22px 0;
  border-left: 1px solid #babece;
  content: ' ';
}
html[data-theme='light'] .ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
html[data-theme='light'] .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
html[data-theme='light'] .ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
html[data-theme='light'] .ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
html[data-theme='light'] .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
html[data-theme='light'] .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #4261ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='light'] .ant-select-tree-checkbox:hover::after,
html[data-theme='light'] .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
html[data-theme='light'] .ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='light'] .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='light'] .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #babece !important;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='light'] .ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='light'] .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='light'] .ant-select-tree-checkbox-wrapper + span,
html[data-theme='light'] .ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='light'] .ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='light'] .ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='light'] .ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='light'] .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #babece;
}
html[data-theme='light'] .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #4261ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='light'] .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='light'] .ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
html[data-theme='light'] .ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
html[data-theme='light'] .ant-select-tree li.filter-node > span {
  font-weight: 500;
}
html[data-theme='light'] .ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
html[data-theme='light'] .ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #e6edff;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #4261ff;
  font-size: 14px;
  transform: none;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='light'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='light'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='light'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='light'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: rotate(-90deg);
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #4261ff;
  font-size: 14px;
  transform: none;
}
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
html[data-theme='light'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='light'] .ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
html[data-theme='light'] .ant-select-tree-child-tree {
  display: none;
}
html[data-theme='light'] .ant-select-tree-child-tree-open {
  display: block;
}
html[data-theme='light'] li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
html[data-theme='light'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
html[data-theme='light'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='light'] .ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='light'] .ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='light'] .ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='light'] .ant-select-tree-dropdown .ant-select-dropdown-search {
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
html[data-theme='light'] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
html[data-theme='light'] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #babece;
  border-radius: 4px;
  outline: none;
}
html[data-theme='light'] .ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
html[data-theme='light'] .ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='light'] .ant-typography {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-typography.ant-typography-warning {
  color: #d48806;
}
html[data-theme='light'] .ant-typography.ant-typography-danger {
  color: #cf1322;
}
html[data-theme='light'] .ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
html[data-theme='light'] div.ant-typography,
html[data-theme='light'] .ant-typography p {
  margin-bottom: 1em;
}
html[data-theme='light'] h1.ant-typography,
html[data-theme='light'] .ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
html[data-theme='light'] h2.ant-typography,
html[data-theme='light'] .ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
html[data-theme='light'] h3.ant-typography,
html[data-theme='light'] .ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
html[data-theme='light'] h4.ant-typography,
html[data-theme='light'] .ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
html[data-theme='light'] .ant-typography + h1.ant-typography,
html[data-theme='light'] .ant-typography + h2.ant-typography,
html[data-theme='light'] .ant-typography + h3.ant-typography,
html[data-theme='light'] .ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
html[data-theme='light'] .ant-typography div + h1,
html[data-theme='light'] .ant-typography ul + h1,
html[data-theme='light'] .ant-typography li + h1,
html[data-theme='light'] .ant-typography p + h1,
html[data-theme='light'] .ant-typography h1 + h1,
html[data-theme='light'] .ant-typography h2 + h1,
html[data-theme='light'] .ant-typography h3 + h1,
html[data-theme='light'] .ant-typography h4 + h1,
html[data-theme='light'] .ant-typography div + h2,
html[data-theme='light'] .ant-typography ul + h2,
html[data-theme='light'] .ant-typography li + h2,
html[data-theme='light'] .ant-typography p + h2,
html[data-theme='light'] .ant-typography h1 + h2,
html[data-theme='light'] .ant-typography h2 + h2,
html[data-theme='light'] .ant-typography h3 + h2,
html[data-theme='light'] .ant-typography h4 + h2,
html[data-theme='light'] .ant-typography div + h3,
html[data-theme='light'] .ant-typography ul + h3,
html[data-theme='light'] .ant-typography li + h3,
html[data-theme='light'] .ant-typography p + h3,
html[data-theme='light'] .ant-typography h1 + h3,
html[data-theme='light'] .ant-typography h2 + h3,
html[data-theme='light'] .ant-typography h3 + h3,
html[data-theme='light'] .ant-typography h4 + h3,
html[data-theme='light'] .ant-typography div + h4,
html[data-theme='light'] .ant-typography ul + h4,
html[data-theme='light'] .ant-typography li + h4,
html[data-theme='light'] .ant-typography p + h4,
html[data-theme='light'] .ant-typography h1 + h4,
html[data-theme='light'] .ant-typography h2 + h4,
html[data-theme='light'] .ant-typography h3 + h4,
html[data-theme='light'] .ant-typography h4 + h4 {
  margin-top: 1.2em;
}
html[data-theme='light'] span.ant-typography-ellipsis {
  display: inline-block;
}
html[data-theme='light'] .ant-typography a {
  color: #4261ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='light'] .ant-typography a:focus,
html[data-theme='light'] .ant-typography a:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-typography a:active {
  color: #2e44d9;
}
html[data-theme='light'] .ant-typography a:active,
html[data-theme='light'] .ant-typography a:hover {
  text-decoration: none;
}
html[data-theme='light'] .ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
html[data-theme='light'] .ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
html[data-theme='light'] .ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
html[data-theme='light'] .ant-typography u,
html[data-theme='light'] .ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
html[data-theme='light'] .ant-typography s,
html[data-theme='light'] .ant-typography del {
  text-decoration: line-through;
}
html[data-theme='light'] .ant-typography strong {
  font-weight: 600;
}
html[data-theme='light'] .ant-typography-expand,
html[data-theme='light'] .ant-typography-edit,
html[data-theme='light'] .ant-typography-copy {
  color: #4261ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
html[data-theme='light'] .ant-typography-expand:focus,
html[data-theme='light'] .ant-typography-edit:focus,
html[data-theme='light'] .ant-typography-copy:focus,
html[data-theme='light'] .ant-typography-expand:hover,
html[data-theme='light'] .ant-typography-edit:hover,
html[data-theme='light'] .ant-typography-copy:hover {
  color: #6b89ff;
}
html[data-theme='light'] .ant-typography-expand:active,
html[data-theme='light'] .ant-typography-edit:active,
html[data-theme='light'] .ant-typography-copy:active {
  color: #2e44d9;
}
html[data-theme='light'] .ant-typography-copy-success,
html[data-theme='light'] .ant-typography-copy-success:hover,
html[data-theme='light'] .ant-typography-copy-success:focus {
  color: #4261ff;
}
html[data-theme='light'] .ant-typography-edit-content {
  position: relative;
}
html[data-theme='light'] div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
html[data-theme='light'] .ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
html[data-theme='light'] .ant-typography ul,
html[data-theme='light'] .ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
html[data-theme='light'] .ant-typography ul li,
html[data-theme='light'] .ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
html[data-theme='light'] .ant-typography ul li {
  list-style-type: circle;
}
html[data-theme='light'] .ant-typography ul li li {
  list-style-type: disc;
}
html[data-theme='light'] .ant-typography ol li {
  list-style-type: decimal;
}
html[data-theme='light'] .ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
html[data-theme='light'] .ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
html[data-theme='light'] .ant-upload p {
  margin: 0;
}
html[data-theme='light'] .ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
html[data-theme='light'] .ant-upload input[type='file'] {
  cursor: pointer;
}
html[data-theme='light'] .ant-upload.ant-upload-select {
  display: inline-block;
}
html[data-theme='light'] .ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-upload.ant-upload-select-picture-card {
  display: table;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #babece;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
html[data-theme='light'] .ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
html[data-theme='light'] .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #4261ff;
}
html[data-theme='light'] .ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #babece;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
html[data-theme='light'] .ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
html[data-theme='light'] .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #2e44d9;
}
html[data-theme='light'] .ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
html[data-theme='light'] .ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
html[data-theme='light'] .ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='light'] .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #6b89ff;
}
html[data-theme='light'] .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
html[data-theme='light'] .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #6b89ff;
  font-size: 48px;
}
html[data-theme='light'] .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
html[data-theme='light'] .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  zoom: 1;
}
html[data-theme='light'] .ant-upload-list::before,
html[data-theme='light'] .ant-upload-list::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-upload-list::after {
  clear: both;
}
html[data-theme='light'] .ant-upload-list::before,
html[data-theme='light'] .ant-upload-list::after {
  display: table;
  content: '';
}
html[data-theme='light'] .ant-upload-list::after {
  clear: both;
}
html[data-theme='light'] .ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
html[data-theme='light'] .ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
html[data-theme='light'] .ant-upload-list-item-info > span {
  display: block;
}
html[data-theme='light'] .ant-upload-list-item-info .anticon-loading,
html[data-theme='light'] .ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='light'] .ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
html[data-theme='light'] :root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
html[data-theme='light'] .ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.65);
}
html[data-theme='light'] .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f0f5ff;
}
html[data-theme='light'] .ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
html[data-theme='light'] .ant-upload-list-item-error,
html[data-theme='light'] .ant-upload-list-item-error .anticon-paper-clip,
html[data-theme='light'] .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff376b;
}
html[data-theme='light'] .ant-upload-list-item-error .anticon-close {
  color: #ff376b !important;
  opacity: 1;
}
html[data-theme='light'] .ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #babece;
  border-radius: 2px;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item:hover,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-error,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff376b;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-info,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-uploading,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-thumbnail,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-icon,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-name,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
html[data-theme='light'] .ant-upload-list-picture .ant-upload-list-item-progress,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
html[data-theme='light'] .ant-upload-list-picture .anticon-close,
html[data-theme='light'] .ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
html[data-theme='light'] .ant-upload-list-picture-card {
  float: left;
}
html[data-theme='light'] .ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
html[data-theme='light'] .ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  display: block;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='light'] .ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
html[data-theme='light'] .ant-upload-list .ant-upload-success-icon {
  color: #4261ff;
  font-weight: bold;
}
html[data-theme='light'] .ant-upload-list .ant-upload-animate-enter,
html[data-theme='light'] .ant-upload-list .ant-upload-animate-leave,
html[data-theme='light'] .ant-upload-list .ant-upload-animate-inline-enter,
html[data-theme='light'] .ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='light'] .ant-upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateIn;
}
html[data-theme='light'] .ant-upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateOut;
}
html[data-theme='light'] .ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
html[data-theme='light'] .ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
html[data-theme='light'] .rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
html[data-theme='light'] button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
html[data-theme='light'] button[disabled].rbc-btn {
  cursor: not-allowed;
}
html[data-theme='light'] button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html[data-theme='light'] .rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
html[data-theme='light'] .rbc-calendar *,
html[data-theme='light'] .rbc-calendar *:before,
html[data-theme='light'] .rbc-calendar *:after {
  box-sizing: inherit;
}
html[data-theme='light'] .rbc-abs-full,
html[data-theme='light'] .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
html[data-theme='light'] .rbc-ellipsis,
html[data-theme='light'] .rbc-event-label,
html[data-theme='light'] .rbc-row-segment .rbc-event-content,
html[data-theme='light'] .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html[data-theme='light'] .rbc-rtl {
  direction: rtl;
}
html[data-theme='light'] .rbc-off-range {
  color: #999999;
}
html[data-theme='light'] .rbc-off-range-bg {
  background: #e6e6e6;
}
html[data-theme='light'] .rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
html[data-theme='light'] .rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-header > a,
html[data-theme='light'] .rbc-header > a:active,
html[data-theme='light'] .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
html[data-theme='light'] .rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
html[data-theme='light'] .rbc-today {
  background-color: #eaf6ff;
}
html[data-theme='light'] .rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
html[data-theme='light'] .rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
html[data-theme='light'] .rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
html[data-theme='light'] .rbc-toolbar button:active,
html[data-theme='light'] .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='light'] .rbc-toolbar button:active:hover,
html[data-theme='light'] .rbc-toolbar button:active:focus,
html[data-theme='light'] .rbc-toolbar button.rbc-active:hover,
html[data-theme='light'] .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
html[data-theme='light'] .rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='light'] .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='light'] .rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
html[data-theme='light'] .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
html[data-theme='light'] .rbc-btn-group button + button {
  margin-left: -1px;
}
html[data-theme='light'] .rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
html[data-theme='light'] .rbc-btn-group + .rbc-btn-group,
html[data-theme='light'] .rbc-btn-group + button {
  margin-left: 10px;
}
html[data-theme='light'] .rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
html[data-theme='light'] .rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
html[data-theme='light'] .rbc-event.rbc-selected {
  background-color: #265985;
}
html[data-theme='light'] .rbc-event:focus {
  outline: 5px auto #3b99fc;
}
html[data-theme='light'] .rbc-event-label {
  font-size: 80%;
}
html[data-theme='light'] .rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
html[data-theme='light'] .rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='light'] .rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
html[data-theme='light'] .rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='light'] .rbc-row {
  display: flex;
  flex-direction: row;
}
html[data-theme='light'] .rbc-row-segment {
  padding: 0 1px 1px 1px;
}
html[data-theme='light'] .rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
html[data-theme='light'] .rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}
html[data-theme='light'] .rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
html[data-theme='light'] .rbc-month-header {
  display: flex;
  flex-direction: row;
}
html[data-theme='light'] .rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
html[data-theme='light'] .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}
html[data-theme='light'] .rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
html[data-theme='light'] .rbc-date-cell.rbc-now {
  font-weight: bold;
}
html[data-theme='light'] .rbc-date-cell > a,
html[data-theme='light'] .rbc-date-cell > a:active,
html[data-theme='light'] .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
html[data-theme='light'] .rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
html[data-theme='light'] .rbc-day-bg {
  flex: 1 0 0%;
}
html[data-theme='light'] .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
html[data-theme='light'] .rbc-overlay > * + * {
  margin-top: 1px;
}
html[data-theme='light'] .rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
html[data-theme='light'] .rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
html[data-theme='light'] .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
html[data-theme='light'] .rbc-agenda-time-cell {
  text-transform: lowercase;
}
html[data-theme='light'] .rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
html[data-theme='light'] .rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}
html[data-theme='light'] .rbc-agenda-date-cell,
html[data-theme='light'] .rbc-agenda-time-cell {
  white-space: nowrap;
}
html[data-theme='light'] .rbc-agenda-event-cell {
  width: 100%;
}
html[data-theme='light'] .rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
html[data-theme='light'] .rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
html[data-theme='light'] .rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
html[data-theme='light'] .rbc-time-gutter,
html[data-theme='light'] .rbc-header-gutter {
  flex: none;
}
html[data-theme='light'] .rbc-label {
  padding: 0 5px;
}
html[data-theme='light'] .rbc-day-slot {
  position: relative;
}
html[data-theme='light'] .rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
html[data-theme='light'] .rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
html[data-theme='light'] .rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
html[data-theme='light'] .rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
html[data-theme='light'] .rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
html[data-theme='light'] .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-time-gutter,
html[data-theme='light'] .rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
html[data-theme='light'] .rbc-time-view-resources .rbc-header,
html[data-theme='light'] .rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}
html[data-theme='light'] .rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
html[data-theme='light'] .rbc-time-slot {
  flex: 1 0 0;
}
html[data-theme='light'] .rbc-time-slot.rbc-now {
  font-weight: bold;
}
html[data-theme='light'] .rbc-day-header {
  text-align: center;
}
html[data-theme='light'] .rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
html[data-theme='light'] .rbc-slot-selecting {
  cursor: move;
}
html[data-theme='light'] .rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
html[data-theme='light'] .rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
html[data-theme='light'] .rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
html[data-theme='light'] .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
html[data-theme='light'] .rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
html[data-theme='light'] .rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
html[data-theme='light'] .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-header-cell-single-day {
  display: none;
}
html[data-theme='light'] .rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
  flex-shrink: 0;
}
html[data-theme='light'] .rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
html[data-theme='light'] .rbc-time-content > .rbc-time-gutter {
  flex: none;
}
html[data-theme='light'] .rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
html[data-theme='light'] .rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='light'] .rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}
html[data-theme='light'] .rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
html[data-theme='light'] html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
html[data-theme='light'] *,
html[data-theme='light'] ::after,
html[data-theme='light'] ::before {
  box-sizing: inherit;
}
html[data-theme='light'] .container,
html[data-theme='light'] .container-fluid,
html[data-theme='light'] .container-lg,
html[data-theme='light'] .container-md,
html[data-theme='light'] .container-sm,
html[data-theme='light'] .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  html[data-theme='light'] .container,
  html[data-theme='light'] .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .container,
  html[data-theme='light'] .container-md,
  html[data-theme='light'] .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .container,
  html[data-theme='light'] .container-lg,
  html[data-theme='light'] .container-md,
  html[data-theme='light'] .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .container,
  html[data-theme='light'] .container-lg,
  html[data-theme='light'] .container-md,
  html[data-theme='light'] .container-sm,
  html[data-theme='light'] .container-xl {
    max-width: 1140px;
  }
}
html[data-theme='light'] .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
html[data-theme='light'] .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
html[data-theme='light'] .no-gutters > .col,
html[data-theme='light'] .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='light'] .col,
html[data-theme='light'] .col-1,
html[data-theme='light'] .col-10,
html[data-theme='light'] .col-11,
html[data-theme='light'] .col-12,
html[data-theme='light'] .col-2,
html[data-theme='light'] .col-3,
html[data-theme='light'] .col-4,
html[data-theme='light'] .col-5,
html[data-theme='light'] .col-6,
html[data-theme='light'] .col-7,
html[data-theme='light'] .col-8,
html[data-theme='light'] .col-9,
html[data-theme='light'] .col-auto,
html[data-theme='light'] .col-lg,
html[data-theme='light'] .col-lg-1,
html[data-theme='light'] .col-lg-10,
html[data-theme='light'] .col-lg-11,
html[data-theme='light'] .col-lg-12,
html[data-theme='light'] .col-lg-2,
html[data-theme='light'] .col-lg-3,
html[data-theme='light'] .col-lg-4,
html[data-theme='light'] .col-lg-5,
html[data-theme='light'] .col-lg-6,
html[data-theme='light'] .col-lg-7,
html[data-theme='light'] .col-lg-8,
html[data-theme='light'] .col-lg-9,
html[data-theme='light'] .col-lg-auto,
html[data-theme='light'] .col-md,
html[data-theme='light'] .col-md-1,
html[data-theme='light'] .col-md-10,
html[data-theme='light'] .col-md-11,
html[data-theme='light'] .col-md-12,
html[data-theme='light'] .col-md-2,
html[data-theme='light'] .col-md-3,
html[data-theme='light'] .col-md-4,
html[data-theme='light'] .col-md-5,
html[data-theme='light'] .col-md-6,
html[data-theme='light'] .col-md-7,
html[data-theme='light'] .col-md-8,
html[data-theme='light'] .col-md-9,
html[data-theme='light'] .col-md-auto,
html[data-theme='light'] .col-sm,
html[data-theme='light'] .col-sm-1,
html[data-theme='light'] .col-sm-10,
html[data-theme='light'] .col-sm-11,
html[data-theme='light'] .col-sm-12,
html[data-theme='light'] .col-sm-2,
html[data-theme='light'] .col-sm-3,
html[data-theme='light'] .col-sm-4,
html[data-theme='light'] .col-sm-5,
html[data-theme='light'] .col-sm-6,
html[data-theme='light'] .col-sm-7,
html[data-theme='light'] .col-sm-8,
html[data-theme='light'] .col-sm-9,
html[data-theme='light'] .col-sm-auto,
html[data-theme='light'] .col-xl,
html[data-theme='light'] .col-xl-1,
html[data-theme='light'] .col-xl-10,
html[data-theme='light'] .col-xl-11,
html[data-theme='light'] .col-xl-12,
html[data-theme='light'] .col-xl-2,
html[data-theme='light'] .col-xl-3,
html[data-theme='light'] .col-xl-4,
html[data-theme='light'] .col-xl-5,
html[data-theme='light'] .col-xl-6,
html[data-theme='light'] .col-xl-7,
html[data-theme='light'] .col-xl-8,
html[data-theme='light'] .col-xl-9,
html[data-theme='light'] .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
html[data-theme='light'] .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
html[data-theme='light'] .row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
html[data-theme='light'] .row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
html[data-theme='light'] .row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
html[data-theme='light'] .row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
html[data-theme='light'] .row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
html[data-theme='light'] .row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
html[data-theme='light'] .col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
html[data-theme='light'] .col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
html[data-theme='light'] .col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
html[data-theme='light'] .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
html[data-theme='light'] .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
html[data-theme='light'] .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
html[data-theme='light'] .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
html[data-theme='light'] .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
html[data-theme='light'] .col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
html[data-theme='light'] .col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
html[data-theme='light'] .col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
html[data-theme='light'] .col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
html[data-theme='light'] .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
html[data-theme='light'] .order-first {
  -ms-flex-order: -1;
  order: -1;
}
html[data-theme='light'] .order-last {
  -ms-flex-order: 13;
  order: 13;
}
html[data-theme='light'] .order-0 {
  -ms-flex-order: 0;
  order: 0;
}
html[data-theme='light'] .order-1 {
  -ms-flex-order: 1;
  order: 1;
}
html[data-theme='light'] .order-2 {
  -ms-flex-order: 2;
  order: 2;
}
html[data-theme='light'] .order-3 {
  -ms-flex-order: 3;
  order: 3;
}
html[data-theme='light'] .order-4 {
  -ms-flex-order: 4;
  order: 4;
}
html[data-theme='light'] .order-5 {
  -ms-flex-order: 5;
  order: 5;
}
html[data-theme='light'] .order-6 {
  -ms-flex-order: 6;
  order: 6;
}
html[data-theme='light'] .order-7 {
  -ms-flex-order: 7;
  order: 7;
}
html[data-theme='light'] .order-8 {
  -ms-flex-order: 8;
  order: 8;
}
html[data-theme='light'] .order-9 {
  -ms-flex-order: 9;
  order: 9;
}
html[data-theme='light'] .order-10 {
  -ms-flex-order: 10;
  order: 10;
}
html[data-theme='light'] .order-11 {
  -ms-flex-order: 11;
  order: 11;
}
html[data-theme='light'] .order-12 {
  -ms-flex-order: 12;
  order: 12;
}
html[data-theme='light'] .offset-1 {
  margin-left: 8.333333%;
}
html[data-theme='light'] .offset-2 {
  margin-left: 16.666667%;
}
html[data-theme='light'] .offset-3 {
  margin-left: 25%;
}
html[data-theme='light'] .offset-4 {
  margin-left: 33.333333%;
}
html[data-theme='light'] .offset-5 {
  margin-left: 41.666667%;
}
html[data-theme='light'] .offset-6 {
  margin-left: 50%;
}
html[data-theme='light'] .offset-7 {
  margin-left: 58.333333%;
}
html[data-theme='light'] .offset-8 {
  margin-left: 66.666667%;
}
html[data-theme='light'] .offset-9 {
  margin-left: 75%;
}
html[data-theme='light'] .offset-10 {
  margin-left: 83.333333%;
}
html[data-theme='light'] .offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  html[data-theme='light'] .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='light'] .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='light'] .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='light'] .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='light'] .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='light'] .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='light'] .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='light'] .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='light'] .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='light'] .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='light'] .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='light'] .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='light'] .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='light'] .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='light'] .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='light'] .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='light'] .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='light'] .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='light'] .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='light'] .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='light'] .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='light'] .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='light'] .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='light'] .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='light'] .offset-sm-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .offset-sm-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='light'] .offset-sm-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='light'] .offset-sm-3 {
    margin-left: 25%;
  }
  html[data-theme='light'] .offset-sm-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='light'] .offset-sm-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='light'] .offset-sm-6 {
    margin-left: 50%;
  }
  html[data-theme='light'] .offset-sm-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='light'] .offset-sm-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='light'] .offset-sm-9 {
    margin-left: 75%;
  }
  html[data-theme='light'] .offset-sm-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='light'] .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='light'] .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='light'] .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='light'] .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='light'] .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='light'] .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='light'] .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='light'] .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='light'] .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='light'] .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='light'] .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='light'] .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='light'] .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='light'] .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='light'] .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='light'] .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='light'] .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='light'] .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='light'] .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='light'] .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='light'] .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='light'] .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='light'] .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='light'] .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='light'] .offset-md-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .offset-md-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='light'] .offset-md-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='light'] .offset-md-3 {
    margin-left: 25%;
  }
  html[data-theme='light'] .offset-md-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='light'] .offset-md-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='light'] .offset-md-6 {
    margin-left: 50%;
  }
  html[data-theme='light'] .offset-md-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='light'] .offset-md-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='light'] .offset-md-9 {
    margin-left: 75%;
  }
  html[data-theme='light'] .offset-md-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='light'] .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='light'] .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='light'] .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='light'] .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='light'] .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='light'] .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='light'] .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='light'] .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='light'] .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='light'] .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='light'] .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='light'] .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='light'] .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='light'] .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='light'] .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='light'] .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='light'] .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='light'] .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='light'] .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='light'] .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='light'] .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='light'] .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='light'] .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='light'] .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='light'] .offset-lg-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .offset-lg-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='light'] .offset-lg-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='light'] .offset-lg-3 {
    margin-left: 25%;
  }
  html[data-theme='light'] .offset-lg-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='light'] .offset-lg-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='light'] .offset-lg-6 {
    margin-left: 50%;
  }
  html[data-theme='light'] .offset-lg-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='light'] .offset-lg-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='light'] .offset-lg-9 {
    margin-left: 75%;
  }
  html[data-theme='light'] .offset-lg-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='light'] .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='light'] .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='light'] .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='light'] .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='light'] .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='light'] .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='light'] .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='light'] .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='light'] .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='light'] .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='light'] .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='light'] .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='light'] .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='light'] .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='light'] .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='light'] .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='light'] .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='light'] .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='light'] .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='light'] .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='light'] .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='light'] .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='light'] .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='light'] .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='light'] .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='light'] .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='light'] .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='light'] .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='light'] .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='light'] .offset-xl-0 {
    margin-left: 0;
  }
  html[data-theme='light'] .offset-xl-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='light'] .offset-xl-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='light'] .offset-xl-3 {
    margin-left: 25%;
  }
  html[data-theme='light'] .offset-xl-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='light'] .offset-xl-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='light'] .offset-xl-6 {
    margin-left: 50%;
  }
  html[data-theme='light'] .offset-xl-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='light'] .offset-xl-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='light'] .offset-xl-9 {
    margin-left: 75%;
  }
  html[data-theme='light'] .offset-xl-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='light'] .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
html[data-theme='light'] .d-none {
  display: none !important;
}
html[data-theme='light'] .d-inline {
  display: inline !important;
}
html[data-theme='light'] .d-inline-block {
  display: inline-block !important;
}
html[data-theme='light'] .d-block {
  display: block !important;
}
html[data-theme='light'] .d-table {
  display: table !important;
}
html[data-theme='light'] .d-table-row {
  display: table-row !important;
}
html[data-theme='light'] .d-table-cell {
  display: table-cell !important;
}
html[data-theme='light'] .d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
html[data-theme='light'] .d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .d-sm-none {
    display: none !important;
  }
  html[data-theme='light'] .d-sm-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-sm-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-sm-block {
    display: block !important;
  }
  html[data-theme='light'] .d-sm-table {
    display: table !important;
  }
  html[data-theme='light'] .d-sm-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-sm-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .d-md-none {
    display: none !important;
  }
  html[data-theme='light'] .d-md-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-md-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-md-block {
    display: block !important;
  }
  html[data-theme='light'] .d-md-table {
    display: table !important;
  }
  html[data-theme='light'] .d-md-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-md-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .d-lg-none {
    display: none !important;
  }
  html[data-theme='light'] .d-lg-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-lg-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-lg-block {
    display: block !important;
  }
  html[data-theme='light'] .d-lg-table {
    display: table !important;
  }
  html[data-theme='light'] .d-lg-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-lg-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .d-xl-none {
    display: none !important;
  }
  html[data-theme='light'] .d-xl-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-xl-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-xl-block {
    display: block !important;
  }
  html[data-theme='light'] .d-xl-table {
    display: table !important;
  }
  html[data-theme='light'] .d-xl-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-xl-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  html[data-theme='light'] .d-print-none {
    display: none !important;
  }
  html[data-theme='light'] .d-print-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-print-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-print-block {
    display: block !important;
  }
  html[data-theme='light'] .d-print-table {
    display: table !important;
  }
  html[data-theme='light'] .d-print-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-print-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
html[data-theme='light'] .flex-row {
  -ms-flex-direction: row!important;
  flex-direction: row !important;
}
html[data-theme='light'] .flex-column {
  -ms-flex-direction: column!important;
  flex-direction: column !important;
}
html[data-theme='light'] .flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
html[data-theme='light'] .flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
html[data-theme='light'] .flex-wrap {
  -ms-flex-wrap: wrap!important;
  flex-wrap: wrap !important;
}
html[data-theme='light'] .flex-nowrap {
  -ms-flex-wrap: nowrap!important;
  flex-wrap: nowrap !important;
}
html[data-theme='light'] .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
html[data-theme='light'] .flex-fill {
  -ms-flex: 1 1 auto!important;
  flex: 1 1 auto !important;
}
html[data-theme='light'] .flex-grow-0 {
  -ms-flex-positive: 0!important;
  flex-grow: 0 !important;
}
html[data-theme='light'] .flex-grow-1 {
  -ms-flex-positive: 1!important;
  flex-grow: 1 !important;
}
html[data-theme='light'] .flex-shrink-0 {
  -ms-flex-negative: 0!important;
  flex-shrink: 0 !important;
}
html[data-theme='light'] .flex-shrink-1 {
  -ms-flex-negative: 1!important;
  flex-shrink: 1 !important;
}
html[data-theme='light'] .justify-content-start {
  -ms-flex-pack: start!important;
  justify-content: flex-start !important;
}
html[data-theme='light'] .justify-content-end {
  -ms-flex-pack: end!important;
  justify-content: flex-end !important;
}
html[data-theme='light'] .justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center !important;
}
html[data-theme='light'] .justify-content-between {
  -ms-flex-pack: justify!important;
  justify-content: space-between !important;
}
html[data-theme='light'] .justify-content-around {
  -ms-flex-pack: distribute!important;
  justify-content: space-around !important;
}
html[data-theme='light'] .align-items-start {
  -ms-flex-align: start!important;
  align-items: flex-start !important;
}
html[data-theme='light'] .align-items-end {
  -ms-flex-align: end!important;
  align-items: flex-end !important;
}
html[data-theme='light'] .align-items-center {
  -ms-flex-align: center!important;
  align-items: center !important;
}
html[data-theme='light'] .align-items-baseline {
  -ms-flex-align: baseline!important;
  align-items: baseline !important;
}
html[data-theme='light'] .align-items-stretch {
  -ms-flex-align: stretch!important;
  align-items: stretch !important;
}
html[data-theme='light'] .align-content-start {
  -ms-flex-line-pack: start!important;
  align-content: flex-start !important;
}
html[data-theme='light'] .align-content-end {
  -ms-flex-line-pack: end!important;
  align-content: flex-end !important;
}
html[data-theme='light'] .align-content-center {
  -ms-flex-line-pack: center!important;
  align-content: center !important;
}
html[data-theme='light'] .align-content-between {
  -ms-flex-line-pack: justify!important;
  align-content: space-between !important;
}
html[data-theme='light'] .align-content-around {
  -ms-flex-line-pack: distribute!important;
  align-content: space-around !important;
}
html[data-theme='light'] .align-content-stretch {
  -ms-flex-line-pack: stretch!important;
  align-content: stretch !important;
}
html[data-theme='light'] .align-self-auto {
  -ms-flex-item-align: auto!important;
  align-self: auto !important;
}
html[data-theme='light'] .align-self-start {
  -ms-flex-item-align: start!important;
  align-self: flex-start !important;
}
html[data-theme='light'] .align-self-end {
  -ms-flex-item-align: end!important;
  align-self: flex-end !important;
}
html[data-theme='light'] .align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center !important;
}
html[data-theme='light'] .align-self-baseline {
  -ms-flex-item-align: baseline!important;
  align-self: baseline !important;
}
html[data-theme='light'] .align-self-stretch {
  -ms-flex-item-align: stretch!important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .flex-sm-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-sm-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-sm-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-sm-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-sm-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-sm-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-sm-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-sm-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-sm-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-sm-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-sm-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-sm-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-sm-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-sm-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-sm-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-sm-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-sm-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-sm-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-sm-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-sm-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-sm-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-sm-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-sm-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-sm-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-sm-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-sm-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-sm-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-sm-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-sm-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-sm-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-sm-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .flex-md-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-md-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-md-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-md-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-md-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-md-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-md-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-md-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-md-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-md-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-md-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-md-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-md-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-md-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-md-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-md-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-md-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-md-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-md-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-md-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-md-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-md-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-md-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-md-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-md-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-md-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-md-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-md-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-md-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-md-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-md-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .flex-lg-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-lg-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-lg-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-lg-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-lg-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-lg-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-lg-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-lg-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-lg-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-lg-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-lg-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-lg-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-lg-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-lg-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-lg-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-lg-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-lg-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-lg-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-lg-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-lg-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-lg-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-lg-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-lg-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-lg-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-lg-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-lg-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-lg-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-lg-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-lg-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-lg-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-lg-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .flex-xl-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-xl-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-xl-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-xl-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-xl-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-xl-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-xl-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-xl-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-xl-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-xl-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-xl-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-xl-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-xl-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-xl-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-xl-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-xl-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-xl-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-xl-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-xl-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-xl-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-xl-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-xl-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-xl-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-xl-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-xl-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-xl-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-xl-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-xl-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-xl-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-xl-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-xl-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
html[data-theme='light'] .m-0 {
  margin: 0 !important;
}
html[data-theme='light'] .mt-0,
html[data-theme='light'] .my-0 {
  margin-top: 0 !important;
}
html[data-theme='light'] .mr-0,
html[data-theme='light'] .mx-0 {
  margin-right: 0 !important;
}
html[data-theme='light'] .mb-0,
html[data-theme='light'] .my-0 {
  margin-bottom: 0 !important;
}
html[data-theme='light'] .ml-0,
html[data-theme='light'] .mx-0 {
  margin-left: 0 !important;
}
html[data-theme='light'] .m-1 {
  margin: 0.25rem !important;
}
html[data-theme='light'] .mt-1,
html[data-theme='light'] .my-1 {
  margin-top: 0.25rem !important;
}
html[data-theme='light'] .mr-1,
html[data-theme='light'] .mx-1 {
  margin-right: 0.25rem !important;
}
html[data-theme='light'] .mb-1,
html[data-theme='light'] .my-1 {
  margin-bottom: 0.25rem !important;
}
html[data-theme='light'] .ml-1,
html[data-theme='light'] .mx-1 {
  margin-left: 0.25rem !important;
}
html[data-theme='light'] .m-2 {
  margin: 0.5rem !important;
}
html[data-theme='light'] .mt-2,
html[data-theme='light'] .my-2 {
  margin-top: 0.5rem !important;
}
html[data-theme='light'] .mr-2,
html[data-theme='light'] .mx-2 {
  margin-right: 0.5rem !important;
}
html[data-theme='light'] .mb-2,
html[data-theme='light'] .my-2 {
  margin-bottom: 0.5rem !important;
}
html[data-theme='light'] .ml-2,
html[data-theme='light'] .mx-2 {
  margin-left: 0.5rem !important;
}
html[data-theme='light'] .m-3 {
  margin: 1rem !important;
}
html[data-theme='light'] .mt-3,
html[data-theme='light'] .my-3 {
  margin-top: 1rem !important;
}
html[data-theme='light'] .mr-3,
html[data-theme='light'] .mx-3 {
  margin-right: 1rem !important;
}
html[data-theme='light'] .mb-3,
html[data-theme='light'] .my-3 {
  margin-bottom: 1rem !important;
}
html[data-theme='light'] .ml-3,
html[data-theme='light'] .mx-3 {
  margin-left: 1rem !important;
}
html[data-theme='light'] .m-4 {
  margin: 1.5rem !important;
}
html[data-theme='light'] .mt-4,
html[data-theme='light'] .my-4 {
  margin-top: 1.5rem !important;
}
html[data-theme='light'] .mr-4,
html[data-theme='light'] .mx-4 {
  margin-right: 1.5rem !important;
}
html[data-theme='light'] .mb-4,
html[data-theme='light'] .my-4 {
  margin-bottom: 1.5rem !important;
}
html[data-theme='light'] .ml-4,
html[data-theme='light'] .mx-4 {
  margin-left: 1.5rem !important;
}
html[data-theme='light'] .m-5 {
  margin: 3rem !important;
}
html[data-theme='light'] .mt-5,
html[data-theme='light'] .my-5 {
  margin-top: 3rem !important;
}
html[data-theme='light'] .mr-5,
html[data-theme='light'] .mx-5 {
  margin-right: 3rem !important;
}
html[data-theme='light'] .mb-5,
html[data-theme='light'] .my-5 {
  margin-bottom: 3rem !important;
}
html[data-theme='light'] .ml-5,
html[data-theme='light'] .mx-5 {
  margin-left: 3rem !important;
}
html[data-theme='light'] .p-0 {
  padding: 0 !important;
}
html[data-theme='light'] .pt-0,
html[data-theme='light'] .py-0 {
  padding-top: 0 !important;
}
html[data-theme='light'] .pr-0,
html[data-theme='light'] .px-0 {
  padding-right: 0 !important;
}
html[data-theme='light'] .pb-0,
html[data-theme='light'] .py-0 {
  padding-bottom: 0 !important;
}
html[data-theme='light'] .pl-0,
html[data-theme='light'] .px-0 {
  padding-left: 0 !important;
}
html[data-theme='light'] .p-1 {
  padding: 0.25rem !important;
}
html[data-theme='light'] .pt-1,
html[data-theme='light'] .py-1 {
  padding-top: 0.25rem !important;
}
html[data-theme='light'] .pr-1,
html[data-theme='light'] .px-1 {
  padding-right: 0.25rem !important;
}
html[data-theme='light'] .pb-1,
html[data-theme='light'] .py-1 {
  padding-bottom: 0.25rem !important;
}
html[data-theme='light'] .pl-1,
html[data-theme='light'] .px-1 {
  padding-left: 0.25rem !important;
}
html[data-theme='light'] .p-2 {
  padding: 0.5rem !important;
}
html[data-theme='light'] .pt-2,
html[data-theme='light'] .py-2 {
  padding-top: 0.5rem !important;
}
html[data-theme='light'] .pr-2,
html[data-theme='light'] .px-2 {
  padding-right: 0.5rem !important;
}
html[data-theme='light'] .pb-2,
html[data-theme='light'] .py-2 {
  padding-bottom: 0.5rem !important;
}
html[data-theme='light'] .pl-2,
html[data-theme='light'] .px-2 {
  padding-left: 0.5rem !important;
}
html[data-theme='light'] .p-3 {
  padding: 1rem !important;
}
html[data-theme='light'] .pt-3,
html[data-theme='light'] .py-3 {
  padding-top: 1rem !important;
}
html[data-theme='light'] .pr-3,
html[data-theme='light'] .px-3 {
  padding-right: 1rem !important;
}
html[data-theme='light'] .pb-3,
html[data-theme='light'] .py-3 {
  padding-bottom: 1rem !important;
}
html[data-theme='light'] .pl-3,
html[data-theme='light'] .px-3 {
  padding-left: 1rem !important;
}
html[data-theme='light'] .p-4 {
  padding: 1.5rem !important;
}
html[data-theme='light'] .pt-4,
html[data-theme='light'] .py-4 {
  padding-top: 1.5rem !important;
}
html[data-theme='light'] .pr-4,
html[data-theme='light'] .px-4 {
  padding-right: 1.5rem !important;
}
html[data-theme='light'] .pb-4,
html[data-theme='light'] .py-4 {
  padding-bottom: 1.5rem !important;
}
html[data-theme='light'] .pl-4,
html[data-theme='light'] .px-4 {
  padding-left: 1.5rem !important;
}
html[data-theme='light'] .p-5 {
  padding: 3rem !important;
}
html[data-theme='light'] .pt-5,
html[data-theme='light'] .py-5 {
  padding-top: 3rem !important;
}
html[data-theme='light'] .pr-5,
html[data-theme='light'] .px-5 {
  padding-right: 3rem !important;
}
html[data-theme='light'] .pb-5,
html[data-theme='light'] .py-5 {
  padding-bottom: 3rem !important;
}
html[data-theme='light'] .pl-5,
html[data-theme='light'] .px-5 {
  padding-left: 3rem !important;
}
html[data-theme='light'] .m-n1 {
  margin: -0.25rem !important;
}
html[data-theme='light'] .mt-n1,
html[data-theme='light'] .my-n1 {
  margin-top: -0.25rem !important;
}
html[data-theme='light'] .mr-n1,
html[data-theme='light'] .mx-n1 {
  margin-right: -0.25rem !important;
}
html[data-theme='light'] .mb-n1,
html[data-theme='light'] .my-n1 {
  margin-bottom: -0.25rem !important;
}
html[data-theme='light'] .ml-n1,
html[data-theme='light'] .mx-n1 {
  margin-left: -0.25rem !important;
}
html[data-theme='light'] .m-n2 {
  margin: -0.5rem !important;
}
html[data-theme='light'] .mt-n2,
html[data-theme='light'] .my-n2 {
  margin-top: -0.5rem !important;
}
html[data-theme='light'] .mr-n2,
html[data-theme='light'] .mx-n2 {
  margin-right: -0.5rem !important;
}
html[data-theme='light'] .mb-n2,
html[data-theme='light'] .my-n2 {
  margin-bottom: -0.5rem !important;
}
html[data-theme='light'] .ml-n2,
html[data-theme='light'] .mx-n2 {
  margin-left: -0.5rem !important;
}
html[data-theme='light'] .m-n3 {
  margin: -1rem !important;
}
html[data-theme='light'] .mt-n3,
html[data-theme='light'] .my-n3 {
  margin-top: -1rem !important;
}
html[data-theme='light'] .mr-n3,
html[data-theme='light'] .mx-n3 {
  margin-right: -1rem !important;
}
html[data-theme='light'] .mb-n3,
html[data-theme='light'] .my-n3 {
  margin-bottom: -1rem !important;
}
html[data-theme='light'] .ml-n3,
html[data-theme='light'] .mx-n3 {
  margin-left: -1rem !important;
}
html[data-theme='light'] .m-n4 {
  margin: -1.5rem !important;
}
html[data-theme='light'] .mt-n4,
html[data-theme='light'] .my-n4 {
  margin-top: -1.5rem !important;
}
html[data-theme='light'] .mr-n4,
html[data-theme='light'] .mx-n4 {
  margin-right: -1.5rem !important;
}
html[data-theme='light'] .mb-n4,
html[data-theme='light'] .my-n4 {
  margin-bottom: -1.5rem !important;
}
html[data-theme='light'] .ml-n4,
html[data-theme='light'] .mx-n4 {
  margin-left: -1.5rem !important;
}
html[data-theme='light'] .m-n5 {
  margin: -3rem !important;
}
html[data-theme='light'] .mt-n5,
html[data-theme='light'] .my-n5 {
  margin-top: -3rem !important;
}
html[data-theme='light'] .mr-n5,
html[data-theme='light'] .mx-n5 {
  margin-right: -3rem !important;
}
html[data-theme='light'] .mb-n5,
html[data-theme='light'] .my-n5 {
  margin-bottom: -3rem !important;
}
html[data-theme='light'] .ml-n5,
html[data-theme='light'] .mx-n5 {
  margin-left: -3rem !important;
}
html[data-theme='light'] .m-auto {
  margin: auto !important;
}
html[data-theme='light'] .mt-auto,
html[data-theme='light'] .my-auto {
  margin-top: auto !important;
}
html[data-theme='light'] .mr-auto,
html[data-theme='light'] .mx-auto {
  margin-right: auto !important;
}
html[data-theme='light'] .mb-auto,
html[data-theme='light'] .my-auto {
  margin-bottom: auto !important;
}
html[data-theme='light'] .ml-auto,
html[data-theme='light'] .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .m-sm-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-sm-0,
  html[data-theme='light'] .my-sm-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-sm-0,
  html[data-theme='light'] .mx-sm-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-sm-0,
  html[data-theme='light'] .my-sm-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-sm-0,
  html[data-theme='light'] .mx-sm-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-sm-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-sm-1,
  html[data-theme='light'] .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-sm-1,
  html[data-theme='light'] .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-sm-1,
  html[data-theme='light'] .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-sm-1,
  html[data-theme='light'] .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-sm-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-sm-2,
  html[data-theme='light'] .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-sm-2,
  html[data-theme='light'] .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-sm-2,
  html[data-theme='light'] .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-sm-2,
  html[data-theme='light'] .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-sm-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-sm-3,
  html[data-theme='light'] .my-sm-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-sm-3,
  html[data-theme='light'] .mx-sm-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-sm-3,
  html[data-theme='light'] .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-sm-3,
  html[data-theme='light'] .mx-sm-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-sm-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-sm-4,
  html[data-theme='light'] .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-sm-4,
  html[data-theme='light'] .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-sm-4,
  html[data-theme='light'] .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-sm-4,
  html[data-theme='light'] .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-sm-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-sm-5,
  html[data-theme='light'] .my-sm-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-sm-5,
  html[data-theme='light'] .mx-sm-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-sm-5,
  html[data-theme='light'] .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-sm-5,
  html[data-theme='light'] .mx-sm-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-sm-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-sm-0,
  html[data-theme='light'] .py-sm-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-sm-0,
  html[data-theme='light'] .px-sm-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-sm-0,
  html[data-theme='light'] .py-sm-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-sm-0,
  html[data-theme='light'] .px-sm-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-sm-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-sm-1,
  html[data-theme='light'] .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-sm-1,
  html[data-theme='light'] .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-sm-1,
  html[data-theme='light'] .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-sm-1,
  html[data-theme='light'] .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-sm-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-sm-2,
  html[data-theme='light'] .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-sm-2,
  html[data-theme='light'] .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-sm-2,
  html[data-theme='light'] .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-sm-2,
  html[data-theme='light'] .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-sm-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-sm-3,
  html[data-theme='light'] .py-sm-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-sm-3,
  html[data-theme='light'] .px-sm-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-sm-3,
  html[data-theme='light'] .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-sm-3,
  html[data-theme='light'] .px-sm-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-sm-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-sm-4,
  html[data-theme='light'] .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-sm-4,
  html[data-theme='light'] .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-sm-4,
  html[data-theme='light'] .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-sm-4,
  html[data-theme='light'] .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-sm-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-sm-5,
  html[data-theme='light'] .py-sm-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-sm-5,
  html[data-theme='light'] .px-sm-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-sm-5,
  html[data-theme='light'] .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-sm-5,
  html[data-theme='light'] .px-sm-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-sm-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='light'] .mt-sm-n1,
  html[data-theme='light'] .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='light'] .mr-sm-n1,
  html[data-theme='light'] .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='light'] .mb-sm-n1,
  html[data-theme='light'] .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='light'] .ml-sm-n1,
  html[data-theme='light'] .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='light'] .m-sm-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='light'] .mt-sm-n2,
  html[data-theme='light'] .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='light'] .mr-sm-n2,
  html[data-theme='light'] .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='light'] .mb-sm-n2,
  html[data-theme='light'] .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='light'] .ml-sm-n2,
  html[data-theme='light'] .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='light'] .m-sm-n3 {
    margin: -1rem !important;
  }
  html[data-theme='light'] .mt-sm-n3,
  html[data-theme='light'] .my-sm-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='light'] .mr-sm-n3,
  html[data-theme='light'] .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='light'] .mb-sm-n3,
  html[data-theme='light'] .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='light'] .ml-sm-n3,
  html[data-theme='light'] .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='light'] .m-sm-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='light'] .mt-sm-n4,
  html[data-theme='light'] .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='light'] .mr-sm-n4,
  html[data-theme='light'] .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='light'] .mb-sm-n4,
  html[data-theme='light'] .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='light'] .ml-sm-n4,
  html[data-theme='light'] .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='light'] .m-sm-n5 {
    margin: -3rem !important;
  }
  html[data-theme='light'] .mt-sm-n5,
  html[data-theme='light'] .my-sm-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='light'] .mr-sm-n5,
  html[data-theme='light'] .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='light'] .mb-sm-n5,
  html[data-theme='light'] .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='light'] .ml-sm-n5,
  html[data-theme='light'] .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='light'] .m-sm-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-sm-auto,
  html[data-theme='light'] .my-sm-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-sm-auto,
  html[data-theme='light'] .mx-sm-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-sm-auto,
  html[data-theme='light'] .my-sm-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-sm-auto,
  html[data-theme='light'] .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .m-md-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-md-0,
  html[data-theme='light'] .my-md-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-md-0,
  html[data-theme='light'] .mx-md-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-md-0,
  html[data-theme='light'] .my-md-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-md-0,
  html[data-theme='light'] .mx-md-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-md-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-md-1,
  html[data-theme='light'] .my-md-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-md-1,
  html[data-theme='light'] .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-md-1,
  html[data-theme='light'] .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-md-1,
  html[data-theme='light'] .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-md-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-md-2,
  html[data-theme='light'] .my-md-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-md-2,
  html[data-theme='light'] .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-md-2,
  html[data-theme='light'] .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-md-2,
  html[data-theme='light'] .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-md-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-md-3,
  html[data-theme='light'] .my-md-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-md-3,
  html[data-theme='light'] .mx-md-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-md-3,
  html[data-theme='light'] .my-md-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-md-3,
  html[data-theme='light'] .mx-md-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-md-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-md-4,
  html[data-theme='light'] .my-md-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-md-4,
  html[data-theme='light'] .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-md-4,
  html[data-theme='light'] .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-md-4,
  html[data-theme='light'] .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-md-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-md-5,
  html[data-theme='light'] .my-md-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-md-5,
  html[data-theme='light'] .mx-md-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-md-5,
  html[data-theme='light'] .my-md-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-md-5,
  html[data-theme='light'] .mx-md-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-md-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-md-0,
  html[data-theme='light'] .py-md-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-md-0,
  html[data-theme='light'] .px-md-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-md-0,
  html[data-theme='light'] .py-md-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-md-0,
  html[data-theme='light'] .px-md-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-md-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-md-1,
  html[data-theme='light'] .py-md-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-md-1,
  html[data-theme='light'] .px-md-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-md-1,
  html[data-theme='light'] .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-md-1,
  html[data-theme='light'] .px-md-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-md-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-md-2,
  html[data-theme='light'] .py-md-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-md-2,
  html[data-theme='light'] .px-md-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-md-2,
  html[data-theme='light'] .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-md-2,
  html[data-theme='light'] .px-md-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-md-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-md-3,
  html[data-theme='light'] .py-md-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-md-3,
  html[data-theme='light'] .px-md-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-md-3,
  html[data-theme='light'] .py-md-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-md-3,
  html[data-theme='light'] .px-md-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-md-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-md-4,
  html[data-theme='light'] .py-md-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-md-4,
  html[data-theme='light'] .px-md-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-md-4,
  html[data-theme='light'] .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-md-4,
  html[data-theme='light'] .px-md-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-md-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-md-5,
  html[data-theme='light'] .py-md-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-md-5,
  html[data-theme='light'] .px-md-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-md-5,
  html[data-theme='light'] .py-md-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-md-5,
  html[data-theme='light'] .px-md-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-md-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='light'] .mt-md-n1,
  html[data-theme='light'] .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='light'] .mr-md-n1,
  html[data-theme='light'] .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='light'] .mb-md-n1,
  html[data-theme='light'] .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='light'] .ml-md-n1,
  html[data-theme='light'] .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='light'] .m-md-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='light'] .mt-md-n2,
  html[data-theme='light'] .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='light'] .mr-md-n2,
  html[data-theme='light'] .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='light'] .mb-md-n2,
  html[data-theme='light'] .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='light'] .ml-md-n2,
  html[data-theme='light'] .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='light'] .m-md-n3 {
    margin: -1rem !important;
  }
  html[data-theme='light'] .mt-md-n3,
  html[data-theme='light'] .my-md-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='light'] .mr-md-n3,
  html[data-theme='light'] .mx-md-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='light'] .mb-md-n3,
  html[data-theme='light'] .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='light'] .ml-md-n3,
  html[data-theme='light'] .mx-md-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='light'] .m-md-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='light'] .mt-md-n4,
  html[data-theme='light'] .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='light'] .mr-md-n4,
  html[data-theme='light'] .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='light'] .mb-md-n4,
  html[data-theme='light'] .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='light'] .ml-md-n4,
  html[data-theme='light'] .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='light'] .m-md-n5 {
    margin: -3rem !important;
  }
  html[data-theme='light'] .mt-md-n5,
  html[data-theme='light'] .my-md-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='light'] .mr-md-n5,
  html[data-theme='light'] .mx-md-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='light'] .mb-md-n5,
  html[data-theme='light'] .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='light'] .ml-md-n5,
  html[data-theme='light'] .mx-md-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='light'] .m-md-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-md-auto,
  html[data-theme='light'] .my-md-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-md-auto,
  html[data-theme='light'] .mx-md-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-md-auto,
  html[data-theme='light'] .my-md-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-md-auto,
  html[data-theme='light'] .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .m-lg-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-lg-0,
  html[data-theme='light'] .my-lg-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-lg-0,
  html[data-theme='light'] .mx-lg-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-lg-0,
  html[data-theme='light'] .my-lg-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-lg-0,
  html[data-theme='light'] .mx-lg-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-lg-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-lg-1,
  html[data-theme='light'] .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-lg-1,
  html[data-theme='light'] .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-lg-1,
  html[data-theme='light'] .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-lg-1,
  html[data-theme='light'] .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-lg-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-lg-2,
  html[data-theme='light'] .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-lg-2,
  html[data-theme='light'] .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-lg-2,
  html[data-theme='light'] .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-lg-2,
  html[data-theme='light'] .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-lg-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-lg-3,
  html[data-theme='light'] .my-lg-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-lg-3,
  html[data-theme='light'] .mx-lg-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-lg-3,
  html[data-theme='light'] .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-lg-3,
  html[data-theme='light'] .mx-lg-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-lg-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-lg-4,
  html[data-theme='light'] .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-lg-4,
  html[data-theme='light'] .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-lg-4,
  html[data-theme='light'] .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-lg-4,
  html[data-theme='light'] .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-lg-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-lg-5,
  html[data-theme='light'] .my-lg-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-lg-5,
  html[data-theme='light'] .mx-lg-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-lg-5,
  html[data-theme='light'] .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-lg-5,
  html[data-theme='light'] .mx-lg-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-lg-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-lg-0,
  html[data-theme='light'] .py-lg-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-lg-0,
  html[data-theme='light'] .px-lg-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-lg-0,
  html[data-theme='light'] .py-lg-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-lg-0,
  html[data-theme='light'] .px-lg-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-lg-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-lg-1,
  html[data-theme='light'] .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-lg-1,
  html[data-theme='light'] .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-lg-1,
  html[data-theme='light'] .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-lg-1,
  html[data-theme='light'] .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-lg-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-lg-2,
  html[data-theme='light'] .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-lg-2,
  html[data-theme='light'] .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-lg-2,
  html[data-theme='light'] .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-lg-2,
  html[data-theme='light'] .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-lg-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-lg-3,
  html[data-theme='light'] .py-lg-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-lg-3,
  html[data-theme='light'] .px-lg-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-lg-3,
  html[data-theme='light'] .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-lg-3,
  html[data-theme='light'] .px-lg-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-lg-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-lg-4,
  html[data-theme='light'] .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-lg-4,
  html[data-theme='light'] .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-lg-4,
  html[data-theme='light'] .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-lg-4,
  html[data-theme='light'] .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-lg-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-lg-5,
  html[data-theme='light'] .py-lg-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-lg-5,
  html[data-theme='light'] .px-lg-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-lg-5,
  html[data-theme='light'] .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-lg-5,
  html[data-theme='light'] .px-lg-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-lg-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='light'] .mt-lg-n1,
  html[data-theme='light'] .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='light'] .mr-lg-n1,
  html[data-theme='light'] .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='light'] .mb-lg-n1,
  html[data-theme='light'] .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='light'] .ml-lg-n1,
  html[data-theme='light'] .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='light'] .m-lg-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='light'] .mt-lg-n2,
  html[data-theme='light'] .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='light'] .mr-lg-n2,
  html[data-theme='light'] .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='light'] .mb-lg-n2,
  html[data-theme='light'] .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='light'] .ml-lg-n2,
  html[data-theme='light'] .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='light'] .m-lg-n3 {
    margin: -1rem !important;
  }
  html[data-theme='light'] .mt-lg-n3,
  html[data-theme='light'] .my-lg-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='light'] .mr-lg-n3,
  html[data-theme='light'] .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='light'] .mb-lg-n3,
  html[data-theme='light'] .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='light'] .ml-lg-n3,
  html[data-theme='light'] .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='light'] .m-lg-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='light'] .mt-lg-n4,
  html[data-theme='light'] .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='light'] .mr-lg-n4,
  html[data-theme='light'] .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='light'] .mb-lg-n4,
  html[data-theme='light'] .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='light'] .ml-lg-n4,
  html[data-theme='light'] .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='light'] .m-lg-n5 {
    margin: -3rem !important;
  }
  html[data-theme='light'] .mt-lg-n5,
  html[data-theme='light'] .my-lg-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='light'] .mr-lg-n5,
  html[data-theme='light'] .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='light'] .mb-lg-n5,
  html[data-theme='light'] .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='light'] .ml-lg-n5,
  html[data-theme='light'] .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='light'] .m-lg-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-lg-auto,
  html[data-theme='light'] .my-lg-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-lg-auto,
  html[data-theme='light'] .mx-lg-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-lg-auto,
  html[data-theme='light'] .my-lg-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-lg-auto,
  html[data-theme='light'] .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .m-xl-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-xl-0,
  html[data-theme='light'] .my-xl-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-xl-0,
  html[data-theme='light'] .mx-xl-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-xl-0,
  html[data-theme='light'] .my-xl-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-xl-0,
  html[data-theme='light'] .mx-xl-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-xl-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-xl-1,
  html[data-theme='light'] .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-xl-1,
  html[data-theme='light'] .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-xl-1,
  html[data-theme='light'] .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-xl-1,
  html[data-theme='light'] .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-xl-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-xl-2,
  html[data-theme='light'] .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-xl-2,
  html[data-theme='light'] .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-xl-2,
  html[data-theme='light'] .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-xl-2,
  html[data-theme='light'] .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-xl-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-xl-3,
  html[data-theme='light'] .my-xl-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-xl-3,
  html[data-theme='light'] .mx-xl-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-xl-3,
  html[data-theme='light'] .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-xl-3,
  html[data-theme='light'] .mx-xl-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-xl-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-xl-4,
  html[data-theme='light'] .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-xl-4,
  html[data-theme='light'] .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-xl-4,
  html[data-theme='light'] .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-xl-4,
  html[data-theme='light'] .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-xl-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-xl-5,
  html[data-theme='light'] .my-xl-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-xl-5,
  html[data-theme='light'] .mx-xl-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-xl-5,
  html[data-theme='light'] .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-xl-5,
  html[data-theme='light'] .mx-xl-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-xl-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-xl-0,
  html[data-theme='light'] .py-xl-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-xl-0,
  html[data-theme='light'] .px-xl-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-xl-0,
  html[data-theme='light'] .py-xl-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-xl-0,
  html[data-theme='light'] .px-xl-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-xl-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-xl-1,
  html[data-theme='light'] .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-xl-1,
  html[data-theme='light'] .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-xl-1,
  html[data-theme='light'] .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-xl-1,
  html[data-theme='light'] .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-xl-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-xl-2,
  html[data-theme='light'] .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-xl-2,
  html[data-theme='light'] .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-xl-2,
  html[data-theme='light'] .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-xl-2,
  html[data-theme='light'] .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-xl-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-xl-3,
  html[data-theme='light'] .py-xl-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-xl-3,
  html[data-theme='light'] .px-xl-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-xl-3,
  html[data-theme='light'] .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-xl-3,
  html[data-theme='light'] .px-xl-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-xl-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-xl-4,
  html[data-theme='light'] .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-xl-4,
  html[data-theme='light'] .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-xl-4,
  html[data-theme='light'] .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-xl-4,
  html[data-theme='light'] .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-xl-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-xl-5,
  html[data-theme='light'] .py-xl-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-xl-5,
  html[data-theme='light'] .px-xl-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-xl-5,
  html[data-theme='light'] .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-xl-5,
  html[data-theme='light'] .px-xl-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-xl-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='light'] .mt-xl-n1,
  html[data-theme='light'] .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='light'] .mr-xl-n1,
  html[data-theme='light'] .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='light'] .mb-xl-n1,
  html[data-theme='light'] .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='light'] .ml-xl-n1,
  html[data-theme='light'] .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='light'] .m-xl-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='light'] .mt-xl-n2,
  html[data-theme='light'] .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='light'] .mr-xl-n2,
  html[data-theme='light'] .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='light'] .mb-xl-n2,
  html[data-theme='light'] .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='light'] .ml-xl-n2,
  html[data-theme='light'] .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='light'] .m-xl-n3 {
    margin: -1rem !important;
  }
  html[data-theme='light'] .mt-xl-n3,
  html[data-theme='light'] .my-xl-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='light'] .mr-xl-n3,
  html[data-theme='light'] .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='light'] .mb-xl-n3,
  html[data-theme='light'] .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='light'] .ml-xl-n3,
  html[data-theme='light'] .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='light'] .m-xl-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='light'] .mt-xl-n4,
  html[data-theme='light'] .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='light'] .mr-xl-n4,
  html[data-theme='light'] .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='light'] .mb-xl-n4,
  html[data-theme='light'] .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='light'] .ml-xl-n4,
  html[data-theme='light'] .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='light'] .m-xl-n5 {
    margin: -3rem !important;
  }
  html[data-theme='light'] .mt-xl-n5,
  html[data-theme='light'] .my-xl-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='light'] .mr-xl-n5,
  html[data-theme='light'] .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='light'] .mb-xl-n5,
  html[data-theme='light'] .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='light'] .ml-xl-n5,
  html[data-theme='light'] .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='light'] .m-xl-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-xl-auto,
  html[data-theme='light'] .my-xl-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-xl-auto,
  html[data-theme='light'] .mx-xl-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-xl-auto,
  html[data-theme='light'] .my-xl-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-xl-auto,
  html[data-theme='light'] .mx-xl-auto {
    margin-left: auto !important;
  }
}
html[data-theme='light'] .align-baseline {
  vertical-align: baseline !important;
}
html[data-theme='light'] .align-top {
  vertical-align: top !important;
}
html[data-theme='light'] .align-middle {
  vertical-align: middle !important;
}
html[data-theme='light'] .align-bottom {
  vertical-align: bottom !important;
}
html[data-theme='light'] .align-text-bottom {
  vertical-align: text-bottom !important;
}
html[data-theme='light'] .align-text-top {
  vertical-align: text-top !important;
}
html[data-theme='light'] .bg-primary {
  background-color: #007bff !important;
}
html[data-theme='light'] a.bg-primary:focus,
html[data-theme='light'] a.bg-primary:hover,
html[data-theme='light'] button.bg-primary:focus,
html[data-theme='light'] button.bg-primary:hover {
  background-color: #0062cc !important;
}
html[data-theme='light'] .bg-secondary {
  background-color: #6c757d !important;
}
html[data-theme='light'] a.bg-secondary:focus,
html[data-theme='light'] a.bg-secondary:hover,
html[data-theme='light'] button.bg-secondary:focus,
html[data-theme='light'] button.bg-secondary:hover {
  background-color: #545b62 !important;
}
html[data-theme='light'] .bg-success {
  background-color: #28a745 !important;
}
html[data-theme='light'] a.bg-success:focus,
html[data-theme='light'] a.bg-success:hover,
html[data-theme='light'] button.bg-success:focus,
html[data-theme='light'] button.bg-success:hover {
  background-color: #1e7e34 !important;
}
html[data-theme='light'] .bg-info {
  background-color: #17a2b8 !important;
}
html[data-theme='light'] a.bg-info:focus,
html[data-theme='light'] a.bg-info:hover,
html[data-theme='light'] button.bg-info:focus,
html[data-theme='light'] button.bg-info:hover {
  background-color: #117a8b !important;
}
html[data-theme='light'] .bg-warning {
  background-color: #ffc107 !important;
}
html[data-theme='light'] a.bg-warning:focus,
html[data-theme='light'] a.bg-warning:hover,
html[data-theme='light'] button.bg-warning:focus,
html[data-theme='light'] button.bg-warning:hover {
  background-color: #d39e00 !important;
}
html[data-theme='light'] .bg-danger {
  background-color: #dc3545 !important;
}
html[data-theme='light'] a.bg-danger:focus,
html[data-theme='light'] a.bg-danger:hover,
html[data-theme='light'] button.bg-danger:focus,
html[data-theme='light'] button.bg-danger:hover {
  background-color: #bd2130 !important;
}
html[data-theme='light'] .bg-light {
  background-color: #f8f9fa !important;
}
html[data-theme='light'] a.bg-light:focus,
html[data-theme='light'] a.bg-light:hover,
html[data-theme='light'] button.bg-light:focus,
html[data-theme='light'] button.bg-light:hover {
  background-color: #dae0e5 !important;
}
html[data-theme='light'] .bg-dark {
  background-color: #343a40 !important;
}
html[data-theme='light'] a.bg-dark:focus,
html[data-theme='light'] a.bg-dark:hover,
html[data-theme='light'] button.bg-dark:focus,
html[data-theme='light'] button.bg-dark:hover {
  background-color: #1d2124 !important;
}
html[data-theme='light'] .bg-white {
  background-color: #fff !important;
}
html[data-theme='light'] .bg-transparent {
  background-color: transparent !important;
}
html[data-theme='light'] .border {
  border: 1px solid #dee2e6 !important;
}
html[data-theme='light'] .border-top {
  border-top: 1px solid #dee2e6 !important;
}
html[data-theme='light'] .border-right {
  border-right: 1px solid #dee2e6 !important;
}
html[data-theme='light'] .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
html[data-theme='light'] .border-left {
  border-left: 1px solid #dee2e6 !important;
}
html[data-theme='light'] .border-0 {
  border: 0 !important;
}
html[data-theme='light'] .border-top-0 {
  border-top: 0 !important;
}
html[data-theme='light'] .border-right-0 {
  border-right: 0 !important;
}
html[data-theme='light'] .border-bottom-0 {
  border-bottom: 0 !important;
}
html[data-theme='light'] .border-left-0 {
  border-left: 0 !important;
}
html[data-theme='light'] .border-primary {
  border-color: #007bff !important;
}
html[data-theme='light'] .border-secondary {
  border-color: #6c757d !important;
}
html[data-theme='light'] .border-success {
  border-color: #28a745 !important;
}
html[data-theme='light'] .border-info {
  border-color: #17a2b8 !important;
}
html[data-theme='light'] .border-warning {
  border-color: #ffc107 !important;
}
html[data-theme='light'] .border-danger {
  border-color: #dc3545 !important;
}
html[data-theme='light'] .border-light {
  border-color: #f8f9fa !important;
}
html[data-theme='light'] .border-dark {
  border-color: #343a40 !important;
}
html[data-theme='light'] .border-white {
  border-color: #fff !important;
}
html[data-theme='light'] .rounded {
  border-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-top {
  border-top-left-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-right,
html[data-theme='light'] .rounded-top {
  border-top-right-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-bottom,
html[data-theme='light'] .rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-bottom,
html[data-theme='light'] .rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-left {
  border-top-left-radius: 0.25rem !important;
}
html[data-theme='light'] .rounded-circle {
  border-radius: 50% !important;
}
html[data-theme='light'] .rounded-0 {
  border-radius: 0 !important;
}
html[data-theme='light'] .clearfix:after {
  display: block;
  clear: both;
  content: "";
}
html[data-theme='light'] .d-none {
  display: none !important;
}
html[data-theme='light'] .d-inline {
  display: inline !important;
}
html[data-theme='light'] .d-inline-block {
  display: inline-block !important;
}
html[data-theme='light'] .d-block {
  display: block !important;
}
html[data-theme='light'] .d-table {
  display: table !important;
}
html[data-theme='light'] .d-table-row {
  display: table-row !important;
}
html[data-theme='light'] .d-table-cell {
  display: table-cell !important;
}
html[data-theme='light'] .d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
html[data-theme='light'] .d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .d-sm-none {
    display: none !important;
  }
  html[data-theme='light'] .d-sm-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-sm-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-sm-block {
    display: block !important;
  }
  html[data-theme='light'] .d-sm-table {
    display: table !important;
  }
  html[data-theme='light'] .d-sm-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-sm-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .d-md-none {
    display: none !important;
  }
  html[data-theme='light'] .d-md-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-md-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-md-block {
    display: block !important;
  }
  html[data-theme='light'] .d-md-table {
    display: table !important;
  }
  html[data-theme='light'] .d-md-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-md-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .d-lg-none {
    display: none !important;
  }
  html[data-theme='light'] .d-lg-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-lg-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-lg-block {
    display: block !important;
  }
  html[data-theme='light'] .d-lg-table {
    display: table !important;
  }
  html[data-theme='light'] .d-lg-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-lg-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .d-xl-none {
    display: none !important;
  }
  html[data-theme='light'] .d-xl-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-xl-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-xl-block {
    display: block !important;
  }
  html[data-theme='light'] .d-xl-table {
    display: table !important;
  }
  html[data-theme='light'] .d-xl-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-xl-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  html[data-theme='light'] .d-print-none {
    display: none !important;
  }
  html[data-theme='light'] .d-print-inline {
    display: inline !important;
  }
  html[data-theme='light'] .d-print-inline-block {
    display: inline-block !important;
  }
  html[data-theme='light'] .d-print-block {
    display: block !important;
  }
  html[data-theme='light'] .d-print-table {
    display: table !important;
  }
  html[data-theme='light'] .d-print-table-row {
    display: table-row !important;
  }
  html[data-theme='light'] .d-print-table-cell {
    display: table-cell !important;
  }
  html[data-theme='light'] .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='light'] .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
html[data-theme='light'] .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
html[data-theme='light'] .embed-responsive:before {
  display: block;
  content: "";
}
html[data-theme='light'] .embed-responsive .embed-responsive-item,
html[data-theme='light'] .embed-responsive embed,
html[data-theme='light'] .embed-responsive iframe,
html[data-theme='light'] .embed-responsive object,
html[data-theme='light'] .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
html[data-theme='light'] .embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}
html[data-theme='light'] .embed-responsive-16by9:before {
  padding-top: 56.25%;
}
html[data-theme='light'] .embed-responsive-4by3:before {
  padding-top: 75%;
}
html[data-theme='light'] .embed-responsive-1by1:before {
  padding-top: 100%;
}
html[data-theme='light'] .flex-row {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
html[data-theme='light'] .flex-column,
html[data-theme='light'] .flex-row {
  -webkit-box-direction: normal !important;
}
html[data-theme='light'] .flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
html[data-theme='light'] .flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
html[data-theme='light'] .flex-column-reverse,
html[data-theme='light'] .flex-row-reverse {
  -webkit-box-direction: reverse !important;
}
html[data-theme='light'] .flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
html[data-theme='light'] .flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
html[data-theme='light'] .flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
html[data-theme='light'] .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
html[data-theme='light'] .flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
html[data-theme='light'] .flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
html[data-theme='light'] .flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
html[data-theme='light'] .flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
html[data-theme='light'] .flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
html[data-theme='light'] .justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
html[data-theme='light'] .justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
html[data-theme='light'] .justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
html[data-theme='light'] .justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
html[data-theme='light'] .justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
html[data-theme='light'] .align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
html[data-theme='light'] .align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
html[data-theme='light'] .align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
html[data-theme='light'] .align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
html[data-theme='light'] .align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
html[data-theme='light'] .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
html[data-theme='light'] .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
html[data-theme='light'] .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
html[data-theme='light'] .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
html[data-theme='light'] .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
html[data-theme='light'] .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
html[data-theme='light'] .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
html[data-theme='light'] .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
html[data-theme='light'] .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
html[data-theme='light'] .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
html[data-theme='light'] .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
html[data-theme='light'] .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-sm-column,
  html[data-theme='light'] .flex-sm-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='light'] .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-md-column,
  html[data-theme='light'] .flex-md-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='light'] .flex-md-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-lg-column,
  html[data-theme='light'] .flex-lg-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='light'] .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='light'] .flex-xl-column,
  html[data-theme='light'] .flex-xl-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='light'] .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='light'] .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='light'] .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='light'] .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='light'] .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='light'] .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='light'] .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='light'] .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='light'] .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='light'] .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='light'] .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='light'] .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='light'] .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='light'] .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='light'] .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='light'] .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='light'] .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='light'] .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='light'] .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='light'] .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='light'] .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='light'] .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='light'] .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='light'] .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='light'] .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='light'] .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='light'] .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='light'] .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='light'] .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='light'] .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='light'] .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='light'] .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='light'] .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
html[data-theme='light'] .float-left {
  float: left !important;
}
html[data-theme='light'] .float-right {
  float: right !important;
}
html[data-theme='light'] .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .float-sm-left {
    float: left !important;
  }
  html[data-theme='light'] .float-sm-right {
    float: right !important;
  }
  html[data-theme='light'] .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .float-md-left {
    float: left !important;
  }
  html[data-theme='light'] .float-md-right {
    float: right !important;
  }
  html[data-theme='light'] .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .float-lg-left {
    float: left !important;
  }
  html[data-theme='light'] .float-lg-right {
    float: right !important;
  }
  html[data-theme='light'] .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .float-xl-left {
    float: left !important;
  }
  html[data-theme='light'] .float-xl-right {
    float: right !important;
  }
  html[data-theme='light'] .float-xl-none {
    float: none !important;
  }
}
html[data-theme='light'] .position-static {
  position: static !important;
}
html[data-theme='light'] .position-relative {
  position: relative !important;
}
html[data-theme='light'] .position-absolute {
  position: absolute !important;
}
html[data-theme='light'] .position-fixed {
  position: fixed !important;
}
html[data-theme='light'] .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
html[data-theme='light'] .fixed-top {
  top: 0;
}
html[data-theme='light'] .fixed-bottom,
html[data-theme='light'] .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
html[data-theme='light'] .fixed-bottom {
  bottom: 0;
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
  html[data-theme='light'] .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
html[data-theme='light'] .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
html[data-theme='light'] .sr-only-focusable:active,
html[data-theme='light'] .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
html[data-theme='light'] .shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
html[data-theme='light'] .shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
html[data-theme='light'] .shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
html[data-theme='light'] .shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
html[data-theme='light'] .w-25 {
  width: 25% !important;
}
html[data-theme='light'] .w-50 {
  width: 50% !important;
}
html[data-theme='light'] .w-75 {
  width: 75% !important;
}
html[data-theme='light'] .w-100 {
  width: 100% !important;
}
html[data-theme='light'] .w-auto {
  width: auto !important;
}
html[data-theme='light'] .h-25 {
  height: 25% !important;
}
html[data-theme='light'] .h-50 {
  height: 50% !important;
}
html[data-theme='light'] .h-75 {
  height: 75% !important;
}
html[data-theme='light'] .h-100 {
  height: 100% !important;
}
html[data-theme='light'] .h-auto {
  height: auto !important;
}
html[data-theme='light'] .mw-100 {
  max-width: 100% !important;
}
html[data-theme='light'] .mh-100 {
  max-height: 100% !important;
}
html[data-theme='light'] .m-0 {
  margin: 0 !important;
}
html[data-theme='light'] .mt-0,
html[data-theme='light'] .my-0 {
  margin-top: 0 !important;
}
html[data-theme='light'] .mr-0,
html[data-theme='light'] .mx-0 {
  margin-right: 0 !important;
}
html[data-theme='light'] .mb-0,
html[data-theme='light'] .my-0 {
  margin-bottom: 0 !important;
}
html[data-theme='light'] .ml-0,
html[data-theme='light'] .mx-0 {
  margin-left: 0 !important;
}
html[data-theme='light'] .m-1 {
  margin: 0.25rem !important;
}
html[data-theme='light'] .mt-1,
html[data-theme='light'] .my-1 {
  margin-top: 0.25rem !important;
}
html[data-theme='light'] .mr-1,
html[data-theme='light'] .mx-1 {
  margin-right: 0.25rem !important;
}
html[data-theme='light'] .mb-1,
html[data-theme='light'] .my-1 {
  margin-bottom: 0.25rem !important;
}
html[data-theme='light'] .ml-1,
html[data-theme='light'] .mx-1 {
  margin-left: 0.25rem !important;
}
html[data-theme='light'] .m-2 {
  margin: 0.5rem !important;
}
html[data-theme='light'] .mt-2,
html[data-theme='light'] .my-2 {
  margin-top: 0.5rem !important;
}
html[data-theme='light'] .mr-2,
html[data-theme='light'] .mx-2 {
  margin-right: 0.5rem !important;
}
html[data-theme='light'] .mb-2,
html[data-theme='light'] .my-2 {
  margin-bottom: 0.5rem !important;
}
html[data-theme='light'] .ml-2,
html[data-theme='light'] .mx-2 {
  margin-left: 0.5rem !important;
}
html[data-theme='light'] .m-3 {
  margin: 1rem !important;
}
html[data-theme='light'] .mt-3,
html[data-theme='light'] .my-3 {
  margin-top: 1rem !important;
}
html[data-theme='light'] .mr-3,
html[data-theme='light'] .mx-3 {
  margin-right: 1rem !important;
}
html[data-theme='light'] .mb-3,
html[data-theme='light'] .my-3 {
  margin-bottom: 1rem !important;
}
html[data-theme='light'] .ml-3,
html[data-theme='light'] .mx-3 {
  margin-left: 1rem !important;
}
html[data-theme='light'] .m-4 {
  margin: 1.5rem !important;
}
html[data-theme='light'] .mt-4,
html[data-theme='light'] .my-4 {
  margin-top: 1.5rem !important;
}
html[data-theme='light'] .mr-4,
html[data-theme='light'] .mx-4 {
  margin-right: 1.5rem !important;
}
html[data-theme='light'] .mb-4,
html[data-theme='light'] .my-4 {
  margin-bottom: 1.5rem !important;
}
html[data-theme='light'] .ml-4,
html[data-theme='light'] .mx-4 {
  margin-left: 1.5rem !important;
}
html[data-theme='light'] .m-5 {
  margin: 3rem !important;
}
html[data-theme='light'] .mt-5,
html[data-theme='light'] .my-5 {
  margin-top: 3rem !important;
}
html[data-theme='light'] .mr-5,
html[data-theme='light'] .mx-5 {
  margin-right: 3rem !important;
}
html[data-theme='light'] .mb-5,
html[data-theme='light'] .my-5 {
  margin-bottom: 3rem !important;
}
html[data-theme='light'] .ml-5,
html[data-theme='light'] .mx-5 {
  margin-left: 3rem !important;
}
html[data-theme='light'] .p-0 {
  padding: 0 !important;
}
html[data-theme='light'] .pt-0,
html[data-theme='light'] .py-0 {
  padding-top: 0 !important;
}
html[data-theme='light'] .pr-0,
html[data-theme='light'] .px-0 {
  padding-right: 0 !important;
}
html[data-theme='light'] .pb-0,
html[data-theme='light'] .py-0 {
  padding-bottom: 0 !important;
}
html[data-theme='light'] .pl-0,
html[data-theme='light'] .px-0 {
  padding-left: 0 !important;
}
html[data-theme='light'] .p-1 {
  padding: 0.25rem !important;
}
html[data-theme='light'] .pt-1,
html[data-theme='light'] .py-1 {
  padding-top: 0.25rem !important;
}
html[data-theme='light'] .pr-1,
html[data-theme='light'] .px-1 {
  padding-right: 0.25rem !important;
}
html[data-theme='light'] .pb-1,
html[data-theme='light'] .py-1 {
  padding-bottom: 0.25rem !important;
}
html[data-theme='light'] .pl-1,
html[data-theme='light'] .px-1 {
  padding-left: 0.25rem !important;
}
html[data-theme='light'] .p-2 {
  padding: 0.5rem !important;
}
html[data-theme='light'] .pt-2,
html[data-theme='light'] .py-2 {
  padding-top: 0.5rem !important;
}
html[data-theme='light'] .pr-2,
html[data-theme='light'] .px-2 {
  padding-right: 0.5rem !important;
}
html[data-theme='light'] .pb-2,
html[data-theme='light'] .py-2 {
  padding-bottom: 0.5rem !important;
}
html[data-theme='light'] .pl-2,
html[data-theme='light'] .px-2 {
  padding-left: 0.5rem !important;
}
html[data-theme='light'] .p-3 {
  padding: 1rem !important;
}
html[data-theme='light'] .pt-3,
html[data-theme='light'] .py-3 {
  padding-top: 1rem !important;
}
html[data-theme='light'] .pr-3,
html[data-theme='light'] .px-3 {
  padding-right: 1rem !important;
}
html[data-theme='light'] .pb-3,
html[data-theme='light'] .py-3 {
  padding-bottom: 1rem !important;
}
html[data-theme='light'] .pl-3,
html[data-theme='light'] .px-3 {
  padding-left: 1rem !important;
}
html[data-theme='light'] .p-4 {
  padding: 1.5rem !important;
}
html[data-theme='light'] .pt-4,
html[data-theme='light'] .py-4 {
  padding-top: 1.5rem !important;
}
html[data-theme='light'] .pr-4,
html[data-theme='light'] .px-4 {
  padding-right: 1.5rem !important;
}
html[data-theme='light'] .pb-4,
html[data-theme='light'] .py-4 {
  padding-bottom: 1.5rem !important;
}
html[data-theme='light'] .pl-4,
html[data-theme='light'] .px-4 {
  padding-left: 1.5rem !important;
}
html[data-theme='light'] .p-5 {
  padding: 3rem !important;
}
html[data-theme='light'] .pt-5,
html[data-theme='light'] .py-5 {
  padding-top: 3rem !important;
}
html[data-theme='light'] .pr-5,
html[data-theme='light'] .px-5 {
  padding-right: 3rem !important;
}
html[data-theme='light'] .pb-5,
html[data-theme='light'] .py-5 {
  padding-bottom: 3rem !important;
}
html[data-theme='light'] .pl-5,
html[data-theme='light'] .px-5 {
  padding-left: 3rem !important;
}
html[data-theme='light'] .m-auto {
  margin: auto !important;
}
html[data-theme='light'] .mt-auto,
html[data-theme='light'] .my-auto {
  margin-top: auto !important;
}
html[data-theme='light'] .mr-auto,
html[data-theme='light'] .mx-auto {
  margin-right: auto !important;
}
html[data-theme='light'] .mb-auto,
html[data-theme='light'] .my-auto {
  margin-bottom: auto !important;
}
html[data-theme='light'] .ml-auto,
html[data-theme='light'] .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .m-sm-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-sm-0,
  html[data-theme='light'] .my-sm-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-sm-0,
  html[data-theme='light'] .mx-sm-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-sm-0,
  html[data-theme='light'] .my-sm-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-sm-0,
  html[data-theme='light'] .mx-sm-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-sm-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-sm-1,
  html[data-theme='light'] .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-sm-1,
  html[data-theme='light'] .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-sm-1,
  html[data-theme='light'] .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-sm-1,
  html[data-theme='light'] .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-sm-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-sm-2,
  html[data-theme='light'] .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-sm-2,
  html[data-theme='light'] .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-sm-2,
  html[data-theme='light'] .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-sm-2,
  html[data-theme='light'] .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-sm-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-sm-3,
  html[data-theme='light'] .my-sm-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-sm-3,
  html[data-theme='light'] .mx-sm-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-sm-3,
  html[data-theme='light'] .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-sm-3,
  html[data-theme='light'] .mx-sm-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-sm-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-sm-4,
  html[data-theme='light'] .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-sm-4,
  html[data-theme='light'] .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-sm-4,
  html[data-theme='light'] .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-sm-4,
  html[data-theme='light'] .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-sm-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-sm-5,
  html[data-theme='light'] .my-sm-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-sm-5,
  html[data-theme='light'] .mx-sm-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-sm-5,
  html[data-theme='light'] .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-sm-5,
  html[data-theme='light'] .mx-sm-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-sm-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-sm-0,
  html[data-theme='light'] .py-sm-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-sm-0,
  html[data-theme='light'] .px-sm-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-sm-0,
  html[data-theme='light'] .py-sm-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-sm-0,
  html[data-theme='light'] .px-sm-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-sm-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-sm-1,
  html[data-theme='light'] .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-sm-1,
  html[data-theme='light'] .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-sm-1,
  html[data-theme='light'] .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-sm-1,
  html[data-theme='light'] .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-sm-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-sm-2,
  html[data-theme='light'] .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-sm-2,
  html[data-theme='light'] .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-sm-2,
  html[data-theme='light'] .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-sm-2,
  html[data-theme='light'] .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-sm-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-sm-3,
  html[data-theme='light'] .py-sm-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-sm-3,
  html[data-theme='light'] .px-sm-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-sm-3,
  html[data-theme='light'] .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-sm-3,
  html[data-theme='light'] .px-sm-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-sm-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-sm-4,
  html[data-theme='light'] .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-sm-4,
  html[data-theme='light'] .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-sm-4,
  html[data-theme='light'] .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-sm-4,
  html[data-theme='light'] .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-sm-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-sm-5,
  html[data-theme='light'] .py-sm-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-sm-5,
  html[data-theme='light'] .px-sm-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-sm-5,
  html[data-theme='light'] .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-sm-5,
  html[data-theme='light'] .px-sm-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-sm-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-sm-auto,
  html[data-theme='light'] .my-sm-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-sm-auto,
  html[data-theme='light'] .mx-sm-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-sm-auto,
  html[data-theme='light'] .my-sm-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-sm-auto,
  html[data-theme='light'] .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .m-md-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-md-0,
  html[data-theme='light'] .my-md-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-md-0,
  html[data-theme='light'] .mx-md-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-md-0,
  html[data-theme='light'] .my-md-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-md-0,
  html[data-theme='light'] .mx-md-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-md-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-md-1,
  html[data-theme='light'] .my-md-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-md-1,
  html[data-theme='light'] .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-md-1,
  html[data-theme='light'] .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-md-1,
  html[data-theme='light'] .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-md-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-md-2,
  html[data-theme='light'] .my-md-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-md-2,
  html[data-theme='light'] .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-md-2,
  html[data-theme='light'] .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-md-2,
  html[data-theme='light'] .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-md-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-md-3,
  html[data-theme='light'] .my-md-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-md-3,
  html[data-theme='light'] .mx-md-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-md-3,
  html[data-theme='light'] .my-md-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-md-3,
  html[data-theme='light'] .mx-md-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-md-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-md-4,
  html[data-theme='light'] .my-md-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-md-4,
  html[data-theme='light'] .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-md-4,
  html[data-theme='light'] .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-md-4,
  html[data-theme='light'] .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-md-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-md-5,
  html[data-theme='light'] .my-md-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-md-5,
  html[data-theme='light'] .mx-md-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-md-5,
  html[data-theme='light'] .my-md-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-md-5,
  html[data-theme='light'] .mx-md-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-md-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-md-0,
  html[data-theme='light'] .py-md-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-md-0,
  html[data-theme='light'] .px-md-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-md-0,
  html[data-theme='light'] .py-md-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-md-0,
  html[data-theme='light'] .px-md-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-md-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-md-1,
  html[data-theme='light'] .py-md-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-md-1,
  html[data-theme='light'] .px-md-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-md-1,
  html[data-theme='light'] .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-md-1,
  html[data-theme='light'] .px-md-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-md-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-md-2,
  html[data-theme='light'] .py-md-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-md-2,
  html[data-theme='light'] .px-md-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-md-2,
  html[data-theme='light'] .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-md-2,
  html[data-theme='light'] .px-md-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-md-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-md-3,
  html[data-theme='light'] .py-md-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-md-3,
  html[data-theme='light'] .px-md-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-md-3,
  html[data-theme='light'] .py-md-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-md-3,
  html[data-theme='light'] .px-md-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-md-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-md-4,
  html[data-theme='light'] .py-md-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-md-4,
  html[data-theme='light'] .px-md-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-md-4,
  html[data-theme='light'] .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-md-4,
  html[data-theme='light'] .px-md-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-md-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-md-5,
  html[data-theme='light'] .py-md-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-md-5,
  html[data-theme='light'] .px-md-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-md-5,
  html[data-theme='light'] .py-md-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-md-5,
  html[data-theme='light'] .px-md-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-md-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-md-auto,
  html[data-theme='light'] .my-md-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-md-auto,
  html[data-theme='light'] .mx-md-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-md-auto,
  html[data-theme='light'] .my-md-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-md-auto,
  html[data-theme='light'] .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .m-lg-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-lg-0,
  html[data-theme='light'] .my-lg-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-lg-0,
  html[data-theme='light'] .mx-lg-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-lg-0,
  html[data-theme='light'] .my-lg-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-lg-0,
  html[data-theme='light'] .mx-lg-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-lg-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-lg-1,
  html[data-theme='light'] .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-lg-1,
  html[data-theme='light'] .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-lg-1,
  html[data-theme='light'] .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-lg-1,
  html[data-theme='light'] .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-lg-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-lg-2,
  html[data-theme='light'] .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-lg-2,
  html[data-theme='light'] .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-lg-2,
  html[data-theme='light'] .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-lg-2,
  html[data-theme='light'] .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-lg-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-lg-3,
  html[data-theme='light'] .my-lg-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-lg-3,
  html[data-theme='light'] .mx-lg-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-lg-3,
  html[data-theme='light'] .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-lg-3,
  html[data-theme='light'] .mx-lg-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-lg-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-lg-4,
  html[data-theme='light'] .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-lg-4,
  html[data-theme='light'] .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-lg-4,
  html[data-theme='light'] .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-lg-4,
  html[data-theme='light'] .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-lg-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-lg-5,
  html[data-theme='light'] .my-lg-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-lg-5,
  html[data-theme='light'] .mx-lg-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-lg-5,
  html[data-theme='light'] .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-lg-5,
  html[data-theme='light'] .mx-lg-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-lg-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-lg-0,
  html[data-theme='light'] .py-lg-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-lg-0,
  html[data-theme='light'] .px-lg-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-lg-0,
  html[data-theme='light'] .py-lg-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-lg-0,
  html[data-theme='light'] .px-lg-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-lg-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-lg-1,
  html[data-theme='light'] .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-lg-1,
  html[data-theme='light'] .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-lg-1,
  html[data-theme='light'] .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-lg-1,
  html[data-theme='light'] .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-lg-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-lg-2,
  html[data-theme='light'] .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-lg-2,
  html[data-theme='light'] .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-lg-2,
  html[data-theme='light'] .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-lg-2,
  html[data-theme='light'] .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-lg-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-lg-3,
  html[data-theme='light'] .py-lg-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-lg-3,
  html[data-theme='light'] .px-lg-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-lg-3,
  html[data-theme='light'] .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-lg-3,
  html[data-theme='light'] .px-lg-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-lg-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-lg-4,
  html[data-theme='light'] .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-lg-4,
  html[data-theme='light'] .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-lg-4,
  html[data-theme='light'] .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-lg-4,
  html[data-theme='light'] .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-lg-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-lg-5,
  html[data-theme='light'] .py-lg-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-lg-5,
  html[data-theme='light'] .px-lg-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-lg-5,
  html[data-theme='light'] .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-lg-5,
  html[data-theme='light'] .px-lg-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-lg-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-lg-auto,
  html[data-theme='light'] .my-lg-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-lg-auto,
  html[data-theme='light'] .mx-lg-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-lg-auto,
  html[data-theme='light'] .my-lg-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-lg-auto,
  html[data-theme='light'] .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .m-xl-0 {
    margin: 0 !important;
  }
  html[data-theme='light'] .mt-xl-0,
  html[data-theme='light'] .my-xl-0 {
    margin-top: 0 !important;
  }
  html[data-theme='light'] .mr-xl-0,
  html[data-theme='light'] .mx-xl-0 {
    margin-right: 0 !important;
  }
  html[data-theme='light'] .mb-xl-0,
  html[data-theme='light'] .my-xl-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='light'] .ml-xl-0,
  html[data-theme='light'] .mx-xl-0 {
    margin-left: 0 !important;
  }
  html[data-theme='light'] .m-xl-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='light'] .mt-xl-1,
  html[data-theme='light'] .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='light'] .mr-xl-1,
  html[data-theme='light'] .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='light'] .mb-xl-1,
  html[data-theme='light'] .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .ml-xl-1,
  html[data-theme='light'] .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='light'] .m-xl-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='light'] .mt-xl-2,
  html[data-theme='light'] .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='light'] .mr-xl-2,
  html[data-theme='light'] .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='light'] .mb-xl-2,
  html[data-theme='light'] .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .ml-xl-2,
  html[data-theme='light'] .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='light'] .m-xl-3 {
    margin: 1rem !important;
  }
  html[data-theme='light'] .mt-xl-3,
  html[data-theme='light'] .my-xl-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='light'] .mr-xl-3,
  html[data-theme='light'] .mx-xl-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='light'] .mb-xl-3,
  html[data-theme='light'] .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='light'] .ml-xl-3,
  html[data-theme='light'] .mx-xl-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='light'] .m-xl-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='light'] .mt-xl-4,
  html[data-theme='light'] .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='light'] .mr-xl-4,
  html[data-theme='light'] .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='light'] .mb-xl-4,
  html[data-theme='light'] .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .ml-xl-4,
  html[data-theme='light'] .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='light'] .m-xl-5 {
    margin: 3rem !important;
  }
  html[data-theme='light'] .mt-xl-5,
  html[data-theme='light'] .my-xl-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='light'] .mr-xl-5,
  html[data-theme='light'] .mx-xl-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='light'] .mb-xl-5,
  html[data-theme='light'] .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='light'] .ml-xl-5,
  html[data-theme='light'] .mx-xl-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='light'] .p-xl-0 {
    padding: 0 !important;
  }
  html[data-theme='light'] .pt-xl-0,
  html[data-theme='light'] .py-xl-0 {
    padding-top: 0 !important;
  }
  html[data-theme='light'] .pr-xl-0,
  html[data-theme='light'] .px-xl-0 {
    padding-right: 0 !important;
  }
  html[data-theme='light'] .pb-xl-0,
  html[data-theme='light'] .py-xl-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='light'] .pl-xl-0,
  html[data-theme='light'] .px-xl-0 {
    padding-left: 0 !important;
  }
  html[data-theme='light'] .p-xl-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='light'] .pt-xl-1,
  html[data-theme='light'] .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='light'] .pr-xl-1,
  html[data-theme='light'] .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='light'] .pb-xl-1,
  html[data-theme='light'] .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='light'] .pl-xl-1,
  html[data-theme='light'] .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='light'] .p-xl-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='light'] .pt-xl-2,
  html[data-theme='light'] .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='light'] .pr-xl-2,
  html[data-theme='light'] .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='light'] .pb-xl-2,
  html[data-theme='light'] .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='light'] .pl-xl-2,
  html[data-theme='light'] .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='light'] .p-xl-3 {
    padding: 1rem !important;
  }
  html[data-theme='light'] .pt-xl-3,
  html[data-theme='light'] .py-xl-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='light'] .pr-xl-3,
  html[data-theme='light'] .px-xl-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='light'] .pb-xl-3,
  html[data-theme='light'] .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='light'] .pl-xl-3,
  html[data-theme='light'] .px-xl-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='light'] .p-xl-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='light'] .pt-xl-4,
  html[data-theme='light'] .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='light'] .pr-xl-4,
  html[data-theme='light'] .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='light'] .pb-xl-4,
  html[data-theme='light'] .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='light'] .pl-xl-4,
  html[data-theme='light'] .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='light'] .p-xl-5 {
    padding: 3rem !important;
  }
  html[data-theme='light'] .pt-xl-5,
  html[data-theme='light'] .py-xl-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='light'] .pr-xl-5,
  html[data-theme='light'] .px-xl-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='light'] .pb-xl-5,
  html[data-theme='light'] .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='light'] .pl-xl-5,
  html[data-theme='light'] .px-xl-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='light'] .m-xl-auto {
    margin: auto !important;
  }
  html[data-theme='light'] .mt-xl-auto,
  html[data-theme='light'] .my-xl-auto {
    margin-top: auto !important;
  }
  html[data-theme='light'] .mr-xl-auto,
  html[data-theme='light'] .mx-xl-auto {
    margin-right: auto !important;
  }
  html[data-theme='light'] .mb-xl-auto,
  html[data-theme='light'] .my-xl-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='light'] .ml-xl-auto,
  html[data-theme='light'] .mx-xl-auto {
    margin-left: auto !important;
  }
}
html[data-theme='light'] .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}
html[data-theme='light'] .text-justify {
  text-align: justify !important;
}
html[data-theme='light'] .text-nowrap {
  white-space: nowrap !important;
}
html[data-theme='light'] .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html[data-theme='light'] .text-left {
  text-align: left !important;
}
html[data-theme='light'] .text-right {
  text-align: right !important;
}
html[data-theme='light'] .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  html[data-theme='light'] .text-sm-left {
    text-align: left !important;
  }
  html[data-theme='light'] .text-sm-right {
    text-align: right !important;
  }
  html[data-theme='light'] .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='light'] .text-md-left {
    text-align: left !important;
  }
  html[data-theme='light'] .text-md-right {
    text-align: right !important;
  }
  html[data-theme='light'] .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .text-lg-left {
    text-align: left !important;
  }
  html[data-theme='light'] .text-lg-right {
    text-align: right !important;
  }
  html[data-theme='light'] .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='light'] .text-xl-left {
    text-align: left !important;
  }
  html[data-theme='light'] .text-xl-right {
    text-align: right !important;
  }
  html[data-theme='light'] .text-xl-center {
    text-align: center !important;
  }
}
html[data-theme='light'] .text-lowercase {
  text-transform: lowercase !important;
}
html[data-theme='light'] .text-uppercase {
  text-transform: uppercase !important;
}
html[data-theme='light'] .text-capitalize {
  text-transform: capitalize !important;
}
html[data-theme='light'] .font-weight-light {
  font-weight: 300 !important;
}
html[data-theme='light'] .font-weight-normal {
  font-weight: 400 !important;
}
html[data-theme='light'] .font-weight-bold {
  font-weight: 700 !important;
}
html[data-theme='light'] .font-italic {
  font-style: italic !important;
}
html[data-theme='light'] .text-white {
  color: #fff !important;
}
html[data-theme='light'] .text-primary {
  color: #007bff !important;
}
html[data-theme='light'] a.text-primary:focus,
html[data-theme='light'] a.text-primary:hover {
  color: #0062cc !important;
}
html[data-theme='light'] .text-secondary {
  color: #6c757d !important;
}
html[data-theme='light'] a.text-secondary:focus,
html[data-theme='light'] a.text-secondary:hover {
  color: #545b62 !important;
}
html[data-theme='light'] .text-success {
  color: #28a745 !important;
}
html[data-theme='light'] a.text-success:focus,
html[data-theme='light'] a.text-success:hover {
  color: #1e7e34 !important;
}
html[data-theme='light'] .text-info {
  color: #17a2b8 !important;
}
html[data-theme='light'] a.text-info:focus,
html[data-theme='light'] a.text-info:hover {
  color: #117a8b !important;
}
html[data-theme='light'] .text-warning {
  color: #ffc107 !important;
}
html[data-theme='light'] a.text-warning:focus,
html[data-theme='light'] a.text-warning:hover {
  color: #d39e00 !important;
}
html[data-theme='light'] .text-danger {
  color: #dc3545 !important;
}
html[data-theme='light'] a.text-danger:focus,
html[data-theme='light'] a.text-danger:hover {
  color: #bd2130 !important;
}
html[data-theme='light'] .text-light {
  color: #f8f9fa !important;
}
html[data-theme='light'] a.text-light:focus,
html[data-theme='light'] a.text-light:hover {
  color: #dae0e5 !important;
}
html[data-theme='light'] .text-dark {
  color: #343a40 !important;
}
html[data-theme='light'] a.text-dark:focus,
html[data-theme='light'] a.text-dark:hover {
  color: #1d2124 !important;
}
html[data-theme='light'] .text-body {
  color: #212529 !important;
}
html[data-theme='light'] .text-muted {
  color: #6c757d !important;
}
html[data-theme='light'] .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
html[data-theme='light'] .text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
html[data-theme='light'] .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
html[data-theme='light'] .visible {
  visibility: visible !important;
}
html[data-theme='light'] .invisible {
  visibility: hidden !important;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.svg#Roboto-Bold-Italic') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Merriweather;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.svg#Merriweather-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
html[data-theme='light'] html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.5;
}
html[data-theme='light'] html.font-size-m {
  font-size: 71.4286%;
}
html[data-theme='light'] html.font-size-l {
  font-size: 80.357%;
}
html[data-theme='light'] body {
  position: relative;
  margin: 0;
  padding: 0;
  color: #25282e;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='light'] p {
  margin-bottom: 10px;
}
html[data-theme='light'] #root {
  min-height: 100vh;
}
html[data-theme='light'] .content {
  background-color: #e4e8ee;
}
html[data-theme='light'] .calendar-picker .ant-calendar {
  margin-left: -294px;
}
html[data-theme='light'] .calendar {
  height: 720px;
}
html[data-theme='light'] .calendar .ant-tabs-bar {
  margin-bottom: 0;
}
html[data-theme='light'] .calendar .tab-current .button.button_icon {
  min-width: 20px;
  padding: 0;
  border: none;
}
html[data-theme='light'] .calendar .tab-current .button.button_icon.arrow_left {
  margin-right: 15px;
}
html[data-theme='light'] .calendar .tab-current .button.button_icon.arrow_right {
  margin-left: 15px;
}
html[data-theme='light'] .calendar .tab-current .button.button_icon:focus {
  outline: none;
}
html[data-theme='light'] .calendar .tab-current .button.button_icon:after {
  opacity: 0;
}
html[data-theme='light'] .calendar .tab-current .ant-calendar-picker {
  width: 18px;
  margin-right: 15px;
  cursor: pointer;
}
html[data-theme='light'] .calendar .tab-current .ant-calendar-picker input {
  border: none;
  cursor: pointer;
}
html[data-theme='light'] .calendar .tab-current .ant-calendar-picker .ant-calendar-picker-icon {
  right: -1px;
  width: 18px;
  height: 18px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
}
html[data-theme='light'] .calendar .tab-current .ant-calendar-picker .Icon {
  padding: 0;
}
html[data-theme='light'] .calendar .tab-current .Icon {
  padding: 5px;
}
html[data-theme='light'] .calendar .calendar-header-panel h3 {
  margin: 15px 0 10px;
}
html[data-theme='light'] .calendar .calendar-header-panel p {
  margin-bottom: 15px;
}
html[data-theme='light'] .calendar .calendar-header-panel .tab-current {
  display: flex;
  justify-content: center;
  align-items: center;
}
html[data-theme='light'] .calendar .calendar-header-panel .button {
  height: 20px;
  min-width: 70px;
  padding: 3px 10px;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.16666667;
}
html[data-theme='light'] .calendar .rbc-month-view {
  border-top: none;
  border-color: #babece;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-month-header {
  position: relative;
  flex: 1 0 auto;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-month-header:before {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #fff;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-month-header:after {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #fff;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-header {
  border-color: #babece;
  border-left: none;
  padding: 0 5px 15px;
  color: #25282e;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
  font-weight: normal;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-month-row {
  flex: auto;
  overflow: visible;
  height: 100%;
  flex-basis: auto;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #babece;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-row-bg {
  position: relative;
  overflow: visible;
  height: 100%;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-row-content {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  position: absolute;
  height: 100%;
  min-height: 97px;
  width: 100%;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-row-content .rbc-show-more {
  margin-left: 14px;
  color: #85899d;
  font-weight: normal;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.69230769;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-date-cell {
  padding-top: 1px;
  padding-right: 7px;
  color: #85899d;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-date-cell.rbc-current a {
  position: relative;
  left: -3px;
  display: inline-block;
  color: #fff;
  letter-spacing: -2px;
  text-indent: -2px;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-date-cell.rbc-current a:before {
  position: absolute;
  left: calc(50% - 9px);
  z-index: -1;
  display: inline-block;
  width: 19px;
  height: 19px;
  content: '';
  border-radius: 50%;
  background-color: #4261ff;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-day-bg {
  position: relative;
  display: block;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #babece;
}
html[data-theme='light'] .calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
html[data-theme='light'] .calendar .rbc-row-segment {
  padding: 0;
}
html[data-theme='light'] .calendar .rbc-event {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.15384615;
  font-weight: bold;
  padding: 0;
  border: none;
  color: #4C5484;
  border-radius: 0;
  background-color: transparent;
}
html[data-theme='light'] .calendar .rbc-event .rbc-event-content {
  background-color: #D2D8FF;
  padding: 3px 0;
  width: 100%;
}
html[data-theme='light'] .calendar .rbc-event.event-purple {
  color: #7B4C84;
}
html[data-theme='light'] .calendar .rbc-event.event-purple .rbc-event-content {
  background-color: #F6C8FF;
}
html[data-theme='light'] .calendar .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #7B4C84;
}
html[data-theme='light'] .calendar .rbc-event.event-orange {
  color: #CE805E;
}
html[data-theme='light'] .calendar .rbc-event.event-orange .rbc-event-content {
  background-color: #FFE6DB;
}
html[data-theme='light'] .calendar .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #CE805E;
}
html[data-theme='light'] .calendar .rbc-event.event-green {
  color: #72A356;
}
html[data-theme='light'] .calendar .rbc-event.event-green .rbc-event-content {
  background-color: #D5FFBE;
}
html[data-theme='light'] .calendar .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #72A356;
}
html[data-theme='light'] .calendar .rbc-event:focus {
  outline: none;
}
html[data-theme='light'] .calendar .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #4C5484;
  color: #fff;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday):not(.ant-popover-open) .rbc-event-content {
  background-color: transparent;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday) .rbc-event-content:before {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 5px;
  height: 5px;
  content: '';
  background-color: #4C5484;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 5px;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-purple .rbc-event-content:before {
  background-color: #7B4C84;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-orange .rbc-event-content:before {
  background-color: #CE805E;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-green .rbc-event-content:before {
  background-color: #72A356;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content:before {
  background-color: #fff;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event.rbc-event-allday {
  width: calc(100% - 2px);
  margin: 0 1px;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event.rbc-event-allday .rbc-event-content {
  padding: 3px 5px;
}
html[data-theme='light'] .calendar .rbc-month-view .rbc-event.rbc-event-allday:before {
  display: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event .rbc-event-label {
  display: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event .rbc-event-content {
  padding-left: 5px;
  margin-bottom: 1px;
  border-left: 3px solid #4C5484;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.15384615;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event .rbc-event-content .event-start {
  font-weight: normal;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.rbc-event-allday .rbc-event-content {
  margin-top: 2px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #4C5484;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-purple .rbc-event-content {
  border-color: #7B4C84;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #7B4C84;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-orange .rbc-event-content {
  border-color: #CE805E;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #CE805E;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-green .rbc-event-content {
  border-color: #72A356;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #72A356;
}
html[data-theme='light'] .calendar.calendar-week .rbc-label {
  margin-top: 37px;
}
html[data-theme='light'] .calendar.calendar-week .rbc-current-time-indicator {
  display: none;
  background-color: #4261ff;
}
html[data-theme='light'] .calendar.calendar-day .rbc-time-view .rbc-time-header:after {
  top: 0;
}
html[data-theme='light'] .calendar.calendar-day .rbc-time-view .rbc-time-header .rbc-allday-cell {
  min-height: 24px;
}
html[data-theme='light'] .calendar.calendar-day .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
  min-height: 0;
}
html[data-theme='light'] .calendar.calendar-day .rbc-time-view .current-time-dot {
  left: -1px;
  margin-top: -5.5px;
}
html[data-theme='light'] .calendar.calendar-day .rbc-time-view .rbc-current-time-indicator {
  display: none;
}
html[data-theme='light'] .calendar .rbc-time-view {
  position: relative;
  border: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-content {
  overflow-x: hidden;
  border-color: #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-today {
  background-color: transparent;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-day-slot .rbc-time-slot {
  border: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-day-slot .rbc-timeslot-group {
  border-top: 1px solid #babece;
  border-bottom: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column {
  margin-top: 25px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column:not(:nth-child(1)) .current-time {
  display: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column:not(:nth-child(2)) .current-time-dot {
  display: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column:not(:first-child):after {
  position: absolute;
  top: -25px;
  right: -1px;
  width: 1px;
  height: 25px;
  content: '';
  background-color: #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column .rbc-timeslot-group {
  min-height: 49px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-column .rbc-time-slot {
  margin-top: -7px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-content > * + * > * {
  border-color: #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-content > *:first-child + * > * {
  border-left: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-timeslot-group {
  border-color: #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-gutter.rbc-time-column {
  position: relative;
  background-color: #fff;
  z-index: 4;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
  color: #25282e;
  text-align: right;
  border-bottom: none;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header {
  border-right: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header:after {
  position: absolute;
  top: 37px;
  left: 0;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-label {
  color: #25282e;
  text-align: right;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.84615385;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-time-header-content {
  border: none;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-time-header-cell {
  min-height: auto;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-header {
  border: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
  font-weight: normal;
  padding-bottom: 15px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-header.rbc-today a span,
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-header a .rbc-today span {
  display: inline-block;
  width: 19px;
  height: 19px;
  color: #fff;
  letter-spacing: -2px;
  border-radius: 50%;
  background-color: #4261ff;
  text-indent: -2px;
  font-weight: bold;
  line-height: 19px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-header a {
  color: #25282e;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-header a:after {
  position: absolute;
  top: calc(50% - 9.5px);
  left: calc(50% - 8.5px);
  z-index: -1;
  display: inline-block;
  width: 19px;
  height: 19px;
  content: '';
  border-radius: 50%;
  background-color: #4261ff;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-allday-cell {
  min-height: 24px;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
  min-height: 0;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-day-bg {
  border-left: 1px solid #babece;
}
html[data-theme='light'] .calendar .rbc-time-view .rbc-time-header .rbc-day-bg:first-child {
  border-color: transparent;
}
html[data-theme='light'] .calendar .rbc-time-view .current-time {
  position: absolute;
  z-index: 5;
  width: 60px;
  margin-top: -10px;
  background-color: #fff;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.69230769;
  color: #4261ff;
  text-align: right;
}
html[data-theme='light'] .calendar .rbc-time-view .current-time-dot {
  position: absolute;
  left: calc(100% - 4.5px);
  z-index: 3;
  display: block;
  width: 11px;
  height: 11px;
  margin-top: -4.5px;
  border: 1px solid #fff !important;
  content: '';
  background-color: #4261ff;
  border-radius: 50%;
}
html[data-theme='light'] .calendar .rbc-time-view .current-time-dot:before,
html[data-theme='light'] .calendar .rbc-time-view .current-time-dot:after {
  top: 4px;
  position: absolute;
  right: 100%;
  display: block;
  width: 1000px;
  height: 1px;
  content: '';
  background-color: #4261ff;
}
html[data-theme='light'] .calendar .rbc-time-view .current-time-dot:after {
  left: 100%;
}
html[data-theme='light'] .event-popover .ant-popover-arrow {
  margin: -9px 0 0 !important;
  box-shadow: -1px -1px 0 #babece !important;
}
html[data-theme='light'] .event-popover .ant-popover-content {
  transform: translate(0, -6px);
}
html[data-theme='light'] .event-popover.ant-popover-placement-top .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-topLeft .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-topRight .ant-popover-content {
  transform: translate(0, 6px);
}
html[data-theme='light'] .event-popover.ant-popover-placement-top .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-topLeft .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-topRight .ant-popover-arrow {
  margin: 0 0 -10px !important;
  box-shadow: 1px 1px 0 #babece !important;
}
html[data-theme='light'] .event-popover.ant-popover-placement-left .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-leftTop .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-leftBottom .ant-popover-content {
  transform: translate(6px, 0);
}
html[data-theme='light'] .event-popover.ant-popover-placement-left .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-leftTop .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-leftBottom .ant-popover-arrow {
  margin: 0 -9px 0 0 !important;
  box-shadow: 1px -1px 0 #babece !important;
}
html[data-theme='light'] .event-popover.ant-popover-placement-right .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-rightTop .ant-popover-content,
html[data-theme='light'] .event-popover.ant-popover-placement-rightBottom .ant-popover-content {
  transform: translate(-6px, 0);
}
html[data-theme='light'] .event-popover.ant-popover-placement-right .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-rightTop .ant-popover-arrow,
html[data-theme='light'] .event-popover.ant-popover-placement-rightBottom .ant-popover-arrow {
  margin: 0 0 0 -9px !important;
  box-shadow: -1px 1px 0 #babece !important;
}
html[data-theme='light'] .event-popover .ant-popover-inner {
  border-radius: 5px;
  box-shadow: 0 0 0 1px #babece;
}
html[data-theme='light'] .event-popover .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}
html[data-theme='light'] .event-details {
  width: 225px;
  padding: 20px 20px 18px;
}
html[data-theme='light'] .event-details h4 {
  margin-bottom: 7px;
}
html[data-theme='light'] .event-details hr {
  border-top: 1px solid #e4e8ee;
  border-left: none;
  border-bottom: none;
  border-right: none;
}
html[data-theme='light'] .event-details .contact-info {
  display: block;
  padding-top: 16px;
  border-top: 1px solid #e4e8ee;
  line-height: 1;
}
html[data-theme='light'] .event-details .contact-info + p.paragraph_primary {
  margin-top: 16px;
}
html[data-theme='light'] .event-details .text-underlined {
  color: #25282e;
  line-height: 22px;
  text-decoration: underline;
}
html[data-theme='light'] .event-details p.paragraph_primary {
  display: block;
  border-top: 1px solid #e4e8ee;
  color: #25282e;
  margin-bottom: 0;
  padding-top: 7px;
}
html[data-theme='light'] .bandwidth-chart-container {
  position: relative;
}
html[data-theme='light'] .bandwidth-chart-container .chart-root {
  width: 100%;
}
html[data-theme='light'] .bandwidth-chart-container .chart-plot {
  fill: #e4e8ee;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-0.chart-bar {
  fill: #25282e;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-1.chart-bar {
  fill: #4261ff;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-2.chart-bar {
  fill: #a0b0ff;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-3.chart-bar {
  fill: #85899d;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-4.chart-bar {
  fill: #6780ff;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-5.chart-bar {
  fill: #3851d1;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-6.chart-bar {
  fill: #9dabbf;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-7.chart-bar {
  fill: #83e5cd;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-8.chart-bar {
  fill: #07cc9b;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-9.chart-bar {
  fill: #159475;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-0.chart-triangle {
  fill: #2d41a6;
}
html[data-theme='light'] .bandwidth-chart-container .chart-color-1.chart-triangle {
  fill: #a0b0ff;
}
html[data-theme='light'] .bandwidth-chart-container .chart-bar-legend {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
}
html[data-theme='light'] .bandwidth-chart-container .chart-legend {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='light'] .bandwidth-chart-container .chart-xaxis-line {
  fill: none;
  stroke: #25282e;
}
html[data-theme='light'] .bandwidth-chart-container .chart-axis-labels {
  position: absolute;
  top: 0;
  left: 0;
}
html[data-theme='light'] .bandwidth-chart-container .chart-axis-labels .chart-label {
  display: flex;
  align-items: center;
  height: 49px;
  margin-top: 16px;
}
html[data-theme='light'] .bandwidth-chart-container .chart-axis-labels .chart-label .Form-control-label {
  margin-bottom: 0;
}
html[data-theme='light'] .highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
}
html[data-theme='light'] .highcharts-root {
  display: block;
}
html[data-theme='light'] .highcharts-root text {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-strong {
  font-weight: bold;
}
html[data-theme='light'] .highcharts-emphasized {
  font-style: italic;
}
html[data-theme='light'] .highcharts-anchor {
  cursor: pointer;
}
html[data-theme='light'] .highcharts-background {
  fill: #ffffff;
}
html[data-theme='light'] .highcharts-plot-border,
html[data-theme='light'] .highcharts-plot-background {
  fill: none;
}
html[data-theme='light'] .highcharts-label-box {
  fill: none;
}
html[data-theme='light'] .highcharts-button-box {
  fill: inherit;
}
html[data-theme='light'] .highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}
html[data-theme='light'] .highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}
html[data-theme='light'] .highcharts-subtitle {
  fill: #666666;
}
html[data-theme='light'] .highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}
html[data-theme='light'] .highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-axis-title {
  fill: #666666;
}
html[data-theme='light'] .highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}
html[data-theme='light'] .highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}
html[data-theme='light'] .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}
html[data-theme='light'] .highcharts-tick {
  stroke: #ccd6eb;
}
html[data-theme='light'] .highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-minor-grid-line {
  stroke: #f2f2f2;
}
html[data-theme='light'] .highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}
html[data-theme='light'] .highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}
html[data-theme='light'] .highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}
html[data-theme='light'] .highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}
html[data-theme='light'] .highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}
html[data-theme='light'] .highcharts-tooltip text {
  fill: #333333;
}
html[data-theme='light'] .highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}
html[data-theme='light'] .highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
html[data-theme='light'] .highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
html[data-theme='light'] .highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}
html[data-theme='light'] .highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
html[data-theme='light'] .highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}
html[data-theme='light'] .highcharts-state-hover path {
  transition: stroke-width 50;
  /* quick in */
}
html[data-theme='light'] .highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}
html[data-theme='light'] g.highcharts-series,
html[data-theme='light'] .highcharts-point,
html[data-theme='light'] .highcharts-markers,
html[data-theme='light'] .highcharts-data-labels {
  transition: opacity 250ms;
}
html[data-theme='light'] .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
html[data-theme='light'] .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
html[data-theme='light'] .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
html[data-theme='light'] .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}
html[data-theme='light'] .highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}
html[data-theme='light'] .highcharts-color-1 {
  fill: #434348;
  stroke: #434348;
}
html[data-theme='light'] .highcharts-color-2 {
  fill: #90ed7d;
  stroke: #90ed7d;
}
html[data-theme='light'] .highcharts-color-3 {
  fill: #f7a35c;
  stroke: #f7a35c;
}
html[data-theme='light'] .highcharts-color-4 {
  fill: #8085e9;
  stroke: #8085e9;
}
html[data-theme='light'] .highcharts-color-5 {
  fill: #f15c80;
  stroke: #f15c80;
}
html[data-theme='light'] .highcharts-color-6 {
  fill: #e4d354;
  stroke: #e4d354;
}
html[data-theme='light'] .highcharts-color-7 {
  fill: #2b908f;
  stroke: #2b908f;
}
html[data-theme='light'] .highcharts-color-8 {
  fill: #f45b5b;
  stroke: #f45b5b;
}
html[data-theme='light'] .highcharts-color-9 {
  fill: #91e8e1;
  stroke: #91e8e1;
}
html[data-theme='light'] .highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff;
}
html[data-theme='light'] .highcharts-point {
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}
html[data-theme='light'] .highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-data-label text,
html[data-theme='light'] text.highcharts-data-label {
  fill: #333333;
}
html[data-theme='light'] .highcharts-data-label-connector {
  fill: none;
}
html[data-theme='light'] .highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
html[data-theme='light'] .highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}
html[data-theme='light'] .highcharts-column-series rect.highcharts-point {
  stroke: #ffffff;
}
html[data-theme='light'] .highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}
html[data-theme='light'] .highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='light'] .highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='light'] .highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='light'] .highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='light'] .highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='light'] .highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
html[data-theme='light'] .highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='light'] .highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='light'] .highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
html[data-theme='light'] .highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-above-level {
  display: none;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}
html[data-theme='light'] .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}
html[data-theme='light'] .highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-legend-item:hover text {
  fill: #000000;
}
html[data-theme='light'] .highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms;
}
html[data-theme='light'] .highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}
html[data-theme='light'] .highcharts-legend-nav-inactive {
  fill: #cccccc;
}
html[data-theme='light'] circle.highcharts-legend-nav-active,
html[data-theme='light'] circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}
html[data-theme='light'] .highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}
html[data-theme='light'] .highcharts-bubble-legend-connectors {
  stroke-width: 1;
}
html[data-theme='light'] .highcharts-bubble-legend-labels {
  fill: #333333;
}
html[data-theme='light'] .highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}
html[data-theme='light'] .highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}
html[data-theme='light'] .highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}
html[data-theme='light'] .highcharts-plot-band,
html[data-theme='light'] .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}
html[data-theme='light'] .highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-boxplot-box {
  fill: #ffffff;
}
html[data-theme='light'] .highcharts-boxplot-median {
  stroke-width: 2px;
}
html[data-theme='light'] .highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}
html[data-theme='light'] .highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}
html[data-theme='light'] .highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}
html[data-theme='light'] .highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}
html[data-theme='light'] .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}
html[data-theme='light'] .highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  transition: stroke 250ms, fill-opacity 250ms;
}
html[data-theme='light'] .highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc;
}
html[data-theme='light'] .highcharts-navigator-mask-outside {
  fill-opacity: 0;
}
html[data-theme='light'] .highcharts-navigator-mask-inside {
  fill: #6685c2;
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}
html[data-theme='light'] .highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}
html[data-theme='light'] .highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}
html[data-theme='light'] .highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}
html[data-theme='light'] .highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}
html[data-theme='light'] .highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}
html[data-theme='light'] .highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}
html[data-theme='light'] .highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-scrollbar-arrow {
  fill: #666666;
}
html[data-theme='light'] .highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}
html[data-theme='light'] .highcharts-button text {
  fill: #333333;
}
html[data-theme='light'] .highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc;
}
html[data-theme='light'] .highcharts-button-hover text {
  fill: #333333;
}
html[data-theme='light'] .highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc;
}
html[data-theme='light'] .highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold;
}
html[data-theme='light'] .highcharts-button-disabled text {
  fill: #333333;
}
html[data-theme='light'] .highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}
html[data-theme='light'] .highcharts-range-label rect {
  fill: none;
}
html[data-theme='light'] .highcharts-range-label text {
  fill: #666666;
}
html[data-theme='light'] .highcharts-range-input rect {
  fill: none;
}
html[data-theme='light'] .highcharts-range-input text {
  fill: #333333;
}
html[data-theme='light'] .highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc;
}
html[data-theme='light'] input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}
html[data-theme='light'] .highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em;
}
html[data-theme='light'] .highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}
html[data-theme='light'] .highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff;
}
html[data-theme='light'] .highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}
html[data-theme='light'] .highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms;
}
html[data-theme='light'] .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}
html[data-theme='light'] .highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}
html[data-theme='light'] .highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
}
html[data-theme='light'] .highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}
html[data-theme='light'] .highcharts-mapline-series .highcharts-point {
  fill: none;
}
html[data-theme='light'] .highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}
html[data-theme='light'] .highcharts-coloraxis {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-coloraxis-marker {
  fill: #999999;
}
html[data-theme='light'] .highcharts-null-point {
  fill: #f7f7f7;
}
html[data-theme='light'] .highcharts-3d-frame {
  fill: transparent;
}
html[data-theme='light'] .highcharts-contextbutton {
  fill: #ffffff;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}
html[data-theme='light'] .highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}
html[data-theme='light'] .highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}
html[data-theme='light'] .highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}
html[data-theme='light'] .highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}
html[data-theme='light'] .highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff;
}
html[data-theme='light'] .highcharts-drilldown-point {
  cursor: pointer;
}
html[data-theme='light'] .highcharts-drilldown-data-label text,
html[data-theme='light'] text.highcharts-drilldown-data-label,
html[data-theme='light'] .highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}
html[data-theme='light'] .highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}
html[data-theme='light'] .highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}
html[data-theme='light'] .highcharts-bullet-target {
  stroke-width: 0;
}
html[data-theme='light'] .highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}
html[data-theme='light'] .highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}
html[data-theme='light'] .highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}
html[data-theme='light'] .highcharts-annotation-label text {
  fill: #e6e6e6;
}
html[data-theme='light'] .highcharts-treegrid-node-collapsed,
html[data-theme='light'] .highcharts-treegrid-node-expanded {
  cursor: pointer;
}
html[data-theme='light'] .highcharts-point-connecting-path {
  fill: none;
}
html[data-theme='light'] .highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-line {
  overflow: visible !important;
}
html[data-theme='light'] .highcharts-line .highcharts-container {
  overflow: visible !important;
}
html[data-theme='light'] .highcharts-line .highcharts-root {
  overflow: visible !important;
}
html[data-theme='light'] .highcharts-line .highcharts-area {
  fill: #4261ff;
  fill-opacity: 0.1;
}
html[data-theme='light'] .highcharts-line .highcharts-background {
  fill: transparent;
}
html[data-theme='light'] .highcharts-line .highcharts-color-0 {
  stroke: #a0b0ff;
}
html[data-theme='light'] .highcharts-line .highcharts-color-0.highcharts-legend-item:before {
  background-color: #a0b0ff;
}
html[data-theme='light'] .highcharts-line .highcharts-color-1 {
  stroke: #2d41a6;
}
html[data-theme='light'] .highcharts-line .highcharts-color-1.highcharts-legend-item:before {
  background-color: #2d41a6;
}
html[data-theme='light'] .highcharts-line .highcharts-color-2 {
  stroke: #ff5f88;
}
html[data-theme='light'] .highcharts-line .highcharts-color-2.highcharts-legend-item:before {
  background-color: #ff5f88;
}
html[data-theme='light'] .highcharts-line .highcharts-color-3 {
  stroke: #25282e;
}
html[data-theme='light'] .highcharts-line .highcharts-color-3.highcharts-legend-item:before {
  background-color: #25282e;
}
html[data-theme='light'] .highcharts-line .highcharts-color-4 {
  stroke: #babece;
}
html[data-theme='light'] .highcharts-line .highcharts-color-4.highcharts-legend-item:before {
  background-color: #babece;
}
html[data-theme='light'] .highcharts-line .highcharts-color-5 {
  stroke: #85899d;
}
html[data-theme='light'] .highcharts-line .highcharts-color-5.highcharts-legend-item:before {
  background-color: #85899d;
}
html[data-theme='light'] .highcharts-line .highcharts-color-6 {
  stroke: #ce3960;
}
html[data-theme='light'] .highcharts-line .highcharts-color-6.highcharts-legend-item:before {
  background-color: #ce3960;
}
html[data-theme='light'] .highcharts-line .highcharts-color-7 {
  stroke: #83e5cd;
}
html[data-theme='light'] .highcharts-line .highcharts-color-7.highcharts-legend-item:before {
  background-color: #83e5cd;
}
html[data-theme='light'] .highcharts-line .highcharts-color-8 {
  stroke: #159475;
}
html[data-theme='light'] .highcharts-line .highcharts-color-8.highcharts-legend-item:before {
  background-color: #159475;
}
html[data-theme='light'] .highcharts-line .highcharts-color-9 {
  stroke: #ff9bb5;
}
html[data-theme='light'] .highcharts-line .highcharts-color-9.highcharts-legend-item:before {
  background-color: #ff9bb5;
}
html[data-theme='light'] .highcharts-line .highcharts-axis-labels {
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #25282e;
}
html[data-theme='light'] .highcharts-line .highcharts-axis-title {
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #25282e;
  font-weight: bold;
}
html[data-theme='light'] .highcharts-line .highcharts-xaxis .highcharts-axis-line,
html[data-theme='light'] .highcharts-line .highcharts-xaxis .highcharts-tick {
  stroke: none;
}
html[data-theme='light'] .highcharts-line .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='light'] .highcharts-line .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #babece;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='light'] .highcharts-line .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='light'] .highcharts-line .highcharts-halo {
  fill-opacity: 1;
  stroke-width: 1px;
  fill: #fff;
  stroke: #85899d;
}
html[data-theme='light'] .highcharts-line .highcharts-markers {
  stroke-width: 0;
  fill: #25282e;
}
html[data-theme='light'] .highcharts-line .highcharts-goal .highcharts-point {
  stroke-width: 0;
  fill: #4261ff;
}
html[data-theme='light'] .highcharts-line .highcharts-goal .highcharts-halo {
  stroke: #4261ff;
}
html[data-theme='light'] .highcharts-line .highcharts-duration .highcharts-graph {
  stroke: #4261ff;
  stroke-width: 2px;
}
html[data-theme='light'] .highcharts-line .highcharts-duration .highcharts-point {
  fill: #4261ff;
  stroke-width: 2px;
  stroke: #fff;
}
html[data-theme='light'] .highcharts-line .highcharts-tooltip {
  stroke: #babece;
  filter: none !important;
}
html[data-theme='light'] .highcharts-line .highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
}
html[data-theme='light'] .highcharts-line .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #25282e;
  white-space: normal !important;
}
html[data-theme='light'] .highcharts-line .highcharts-legend g.highcharts-legend-item {
  opacity: 0;
}
html[data-theme='light'] .highcharts-line .highcharts-legend div.highcharts-legend-item {
  color: #25282e;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
html[data-theme='light'] .highcharts-line .highcharts-legend div.highcharts-legend-item:before {
  display: inline-block;
  width: 14px;
  height: 14px;
  content: '';
  margin-top: 8px;
  margin-left: -10px;
  border-radius: 50%;
}
html[data-theme='light'] .highcharts-line .highcharts-legend div.highcharts-legend-item > span {
  padding-left: 6px;
}
html[data-theme='light'] .pie-chart-container {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
html[data-theme='light'] .highcharts-pie {
  display: flex;
  justify-content: stretch;
  align-items: center;
  min-width: 225px;
  width: 33%;
}
@media only screen and (max-width: 880px) {
  html[data-theme='light'] .highcharts-pie {
    min-width: 250px;
    width: 50%;
  }
}
@media only screen and (max-width: 540px) {
  html[data-theme='light'] .highcharts-pie {
    flex-direction: column;
  }
  html[data-theme='light'] .highcharts-pie .legend {
    height: auto;
  }
}
html[data-theme='light'] .highcharts-pie .highcharts-react-container {
  overflow: visible !important;
  min-width: 201px;
  min-height: 201px;
  width: 201px;
  height: 201px;
}
html[data-theme='light'] .highcharts-pie .highcharts-container {
  overflow: visible !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
html[data-theme='light'] .highcharts-pie .highcharts-root {
  overflow: visible !important;
}
html[data-theme='light'] .highcharts-pie .highcharts-background {
  fill: transparent;
}
html[data-theme='light'] .highcharts-pie .highcharts-subtitle {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: -1;
  color: #25282e;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.16666667;
}
html[data-theme='light'] .highcharts-pie .highcharts-tooltip {
  stroke: #babece;
  filter: none !important;
}
html[data-theme='light'] .highcharts-pie .highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
}
html[data-theme='light'] .highcharts-pie .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 20px !important;
  white-space: normal !important;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-0 {
  fill: #25282e;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-1 {
  fill: #4261ff;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-2 {
  fill: #a0b0ff;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-3 {
  fill: #babece;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-4 {
  fill: #6780ff;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-5 {
  fill: #3851d1;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-6 {
  fill: #9dabbf;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-7 {
  fill: #83e5cd;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-8 {
  fill: #07cc9b;
}
html[data-theme='light'] .highcharts-pie .highcharts-color-9 {
  fill: #159475;
}
html[data-theme='light'] .highcharts-pie .legend {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px 0;
}
html[data-theme='light'] .highcharts-pie .legend-point {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  margin-right: 10px;
}
html[data-theme='light'] .highcharts-pie .legend-item {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  height: 33%;
  margin-left: 30px;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(1) .legend-point {
  background-color: #25282e;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(2) .legend-point {
  background-color: #4261ff;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(3) .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(4) .legend-point {
  background-color: #babece;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(5) .legend-point {
  background-color: #6780ff;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(6) .legend-point {
  background-color: #3851d1;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(7) .legend-point {
  background-color: #9dabbf;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(8) .legend-point {
  background-color: #83e5cd;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(9) .legend-point {
  background-color: #07cc9b;
}
html[data-theme='light'] .highcharts-pie .legend-item:nth-child(10) .legend-point {
  background-color: #159475;
}
html[data-theme='light'] .highcharts-pie .legend-label {
  margin-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
}
html[data-theme='light'] .highcharts-pie .legend-name {
  color: #25282e;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  margin-bottom: 5px;
  max-width: 126px;
}
html[data-theme='light'] .highcharts-pie .legend-percent {
  float: right;
  color: #25282e;
  text-align: right;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  margin-left: 12px;
}
html[data-theme='light'] .highcharts-pie .legend-value {
  color: #85899d;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-react-container {
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  height: 120px;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-series-inactive,
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-point-inactive {
  opacity: 1 !important;
  transition: none !important;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: #25282e;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: #4261ff;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: #a0b0ff;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: #babece;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: #6780ff;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #3851d1;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: #9dabbf;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
  fill: #83e5cd;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0 {
  fill: #07cc9b;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0 {
  fill: #159475;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-color-1 {
  fill: #e4e8ee;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .legend {
  justify-content: center;
  height: auto;
  padding: 0;
}
html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation .legend-item {
  margin-left: 20px;
}
@media only screen and (max-width: 540px) {
  html[data-theme='light'] .highcharts-pie.highcharts-pie_single-allocation {
    flex-direction: row;
  }
}
html[data-theme='light'] .projection-chart-container .highcharts-projection-chart {
  overflow: visible !important;
}
html[data-theme='light'] .projection-chart-container .highcharts-container {
  overflow: visible !important;
}
html[data-theme='light'] .projection-chart-container .highcharts-root {
  overflow: visible !important;
}
html[data-theme='light'] .projection-chart-container .highcharts-background {
  fill: transparent;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-markers {
  fill: #3851d1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-markers {
  fill: #a0b0ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-markers {
  fill: #a0b0ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-markers {
  fill: #9dabbf;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-markers {
  fill: #babece;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-markers {
  fill: #25282e;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-markers {
  fill: #07cc9b;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-markers {
  fill: #159475;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-0.highcharts-markers {
  stroke: #3851d1;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-1.highcharts-markers {
  stroke: #6780ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-2.highcharts-markers {
  stroke: #a0b0ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-3.highcharts-markers {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-4.highcharts-markers {
  stroke: #6780ff;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-5.highcharts-markers {
  stroke: #9dabbf;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-6.highcharts-markers {
  stroke: #babece;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-7.highcharts-markers {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-8.highcharts-markers {
  stroke: #07cc9b;
}
html[data-theme='light'] .projection-chart-container .highcharts-color-9.highcharts-series,
html[data-theme='light'] .projection-chart-container .highcharts-color-9.highcharts-markers {
  stroke: #159475;
}
html[data-theme='light'] .projection-chart-container .highcharts-graph {
  stroke-width: 1px;
}
html[data-theme='light'] .projection-chart-container .highcharts-axis-line {
  display: none;
}
html[data-theme='light'] .projection-chart-container .highcharts-grid-line {
  stroke: #babece;
}
html[data-theme='light'] .projection-chart-container .highcharts-axis-labels,
html[data-theme='light'] .projection-chart-container .highcharts-axis-title {
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #25282e;
}
html[data-theme='light'] .projection-chart-container .highcharts-axis-title {
  font-weight: bold;
}
html[data-theme='light'] .projection-chart-container .highcharts-xaxis .highcharts-axis-line,
html[data-theme='light'] .projection-chart-container .highcharts-xaxis .highcharts-tick {
  stroke: none;
}
html[data-theme='light'] .projection-chart-container .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='light'] .projection-chart-container .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #babece;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='light'] .projection-chart-container .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='light'] .projection-chart-container .highcharts-area-series .highcharts-halo {
  fill-opacity: 1;
  stroke-width: 1px;
  fill: #fff;
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container .highcharts-markers {
  stroke-width: 0;
  fill: #25282e;
}
html[data-theme='light'] .projection-chart-container .highcharts-series-inactive {
  opacity: 1 !important;
}
html[data-theme='light'] .projection-chart-container .highcharts-crosshair {
  stroke: #babece;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='light'] .projection-chart-container .highcharts-tooltip {
  stroke: #babece;
  filter: none !important;
  z-index: 999 !important;
}
html[data-theme='light'] .projection-chart-container .highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #25282e;
  white-space: normal !important;
}
html[data-theme='light'] .projection-chart-container .projection-legend {
  margin: 0 auto;
  width: fit-content;
}
html[data-theme='light'] .projection-chart-container .projection-legend-item {
  display: inline-flex;
  align-items: center;
  color: #25282e;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] .projection-chart-container .projection-legend-item:not(:last-child) {
  margin-right: 20px;
}
html[data-theme='light'] .projection-chart-container .projection-legend-item .legend-point {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
html[data-theme='light'] .projection-chart-container .projection-legend-item .legend-name {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='light'] .projection-chart-container .projection-legend .legend-external-item-0 .legend-point {
  background-color: #85899d;
}
html[data-theme='light'] .projection-chart-container .projection-legend .legend-external-item-1 .legend-point {
  background-color: #85899d;
}
html[data-theme='light'] .projection-chart-container .projection-legend .legend-item-0 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='light'] .projection-chart-container .projection-legend .legend-item-1 .legend-point {
  background-color: #6780ff;
}
html[data-theme='light'] .projection-chart-container .projection-legend .legend-item-2 .legend-point {
  background-color: #3851d1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-series-group {
  opacity: 0.75;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-series-3 .highcharts-halo {
  fill-opacity: 0;
  stroke-width: 0;
  fill: transparent;
  stroke: transparent;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
  fill: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
  fill: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
  fill: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
  fill: #e4e8ee;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
  fill: #e4e8ee;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
  fill: #e4e8ee;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
  fill: #e4e8ee;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-area,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point,
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series {
  stroke: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point {
  stroke: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
  fill: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
  stroke: #ff9bb5;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series {
  stroke: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point {
  stroke: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
  stroke: #6780ff;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
  fill: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
  fill: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
  stroke: #25282e;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
  fill: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
  stroke: #85899d;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-0 .legend-point {
  background-color: #85899d;
  opacity: 0.75;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-1 .legend-point {
  background-color: #85899d;
  opacity: 0.75;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-0 .legend-point {
  background-color: #e4e8ee;
  opacity: 0.75;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-1 .legend-point {
  background-color: #6780ff;
  opacity: 0.75;
}
html[data-theme='light'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-2 .legend-point {
  background-color: #ff9bb5;
  opacity: 0.75;
}
html[data-theme='light'] .highcharts-risk-return {
  overflow: visible !important;
  width: fit-content;
  margin-left: 20px;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-container {
  overflow: visible !important;
  width: fit-content;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-root {
  overflow: visible !important;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-background {
  fill: transparent;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-point {
  cursor: pointer;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-point-hover {
  stroke: #3851d1 !important;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-0 {
  fill: #a0b0ff;
  stroke: #a0b0ff;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-1 {
  fill: #2d41a6;
  stroke: #2d41a6;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-2 {
  fill: #85899d;
  stroke: #85899d;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-3 {
  fill: #4261ff;
  stroke: #4261ff;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-4 {
  fill: #a0b0ff;
  stroke: #a0b0ff;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-5 {
  fill: #9dabbf;
  stroke: #9dabbf;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-6 {
  fill: #25282e;
  stroke: #25282e;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-color-7 {
  fill: #83e5cd;
  stroke: #83e5cd;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-axis-labels {
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #25282e;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-axis-line {
  stroke: #babece;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-xaxis-grid .highcharts-grid-line {
  stroke: #e4e8ee;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #e4e8ee;
  stroke-width: 1px;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-halo {
  display: none;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-crosshair {
  stroke: #25282e;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-tooltip {
  stroke: #babece;
  filter: none !important;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-tooltip > span {
  font-size: 16px;
  font-family: 'Montserrat-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #25282e;
  white-space: normal !important;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-tooltip .tooltip-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
html[data-theme='light'] .highcharts-risk-return .highcharts-tooltip .value {
  min-width: 52px;
}
html[data-theme='light'] .risk-return-container {
  position: relative;
  align-items: center;
}
html[data-theme='light'] .risk-return-chart-container {
  position: relative;
  width: fit-content;
  margin: 0 auto 30px;
}
html[data-theme='light'] .risk-return-labels {
  position: absolute;
  display: flex;
  justify-content: space-between;
}
html[data-theme='light'] .risk-return-labels > span {
  color: #25282e;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
}
html[data-theme='light'] .risk-return-xaxis-labels {
  width: calc(100% - 40px);
  margin-top: -5px;
  margin-left: 29px;
}
html[data-theme='light'] .risk-return-yaxis-labels {
  width: calc(100% - 45px);
  margin-left: 0;
  transform: rotate(-90deg);
  transform-origin: -5px -10px;
}
html[data-theme='light'] .risk-return-legend {
  margin: 0 auto;
  width: fit-content;
}
html[data-theme='light'] .risk-return-legend-item {
  display: inline-flex;
  align-items: center;
  color: #25282e;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] .risk-return-legend-item:not(:last-child) {
  margin-right: 20px;
}
html[data-theme='light'] .risk-return-legend-item .legend-point {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
html[data-theme='light'] .risk-return-legend-item .legend-name {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='light'] .risk-return-legend .legend-item-0 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='light'] .risk-return-legend .legend-item-1 .legend-point {
  background-color: #2d41a6;
}
html[data-theme='light'] .risk-return-legend .legend-item-2 .legend-point {
  background-color: #85899d;
}
html[data-theme='light'] .risk-return-legend .legend-item-3 .legend-point {
  background-color: #4261ff;
}
html[data-theme='light'] .risk-return-legend .legend-item-4 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='light'] .risk-return-legend .legend-item-5 .legend-point {
  background-color: #9dabbf;
}
html[data-theme='light'] .risk-return-legend .legend-item-6 .legend-point {
  background-color: #25282e;
}
html[data-theme='light'] .risk-return-legend .legend-item-7 .legend-point {
  background-color: #83e5cd;
}
html[data-theme='light'] .ant-calendar-picker {
  width: 320px;
}
html[data-theme='light'] .ant-calendar-picker .ant-calendar-picker-input:hover {
  border-color: #babece;
}
html[data-theme='light'] .ant-calendar-picker .ant-calendar-picker-icon .Icon {
  color: #25282e;
}
html[data-theme='light'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon {
  opacity: 0.5;
}
html[data-theme='light'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon,
html[data-theme='light'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon:hover {
  cursor: not-allowed;
}
html[data-theme='light'] .datepicker-top {
  margin-top: -272px;
}
html[data-theme='light'] .datepicker-top .ant-calendar-date-panel:before,
html[data-theme='light'] .datepicker-top .ant-calendar-date-panel:after {
  position: absolute;
  right: 8px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 10px;
}
html[data-theme='light'] .datepicker-top .ant-calendar-date-panel:before {
  bottom: -20px;
  border-color: #babece transparent transparent transparent;
}
html[data-theme='light'] .datepicker-top .ant-calendar-date-panel:after {
  bottom: -19px;
  border-color: #fff transparent transparent transparent;
}
html[data-theme='light'] .datepicker .ant-calendar-date-panel:before,
html[data-theme='light'] .datepicker .ant-calendar-date-panel:after {
  position: absolute;
  right: 8px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 10px;
}
html[data-theme='light'] .datepicker .ant-calendar-date-panel:before {
  top: -20px;
  border-color: transparent transparent #babece transparent;
}
html[data-theme='light'] .datepicker .ant-calendar-date-panel:after {
  top: -19px;
  border-color: transparent transparent #fff transparent;
}
html[data-theme='light'] .datepicker .ant-calendar-date-panel,
html[data-theme='light'] .datepicker-top .ant-calendar-date-panel {
  margin-top: 42px;
  padding: 0 20px 20px;
  background-color: #fff;
  border: 1px solid #babece;
  border-radius: 5px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .datepicker .ant-calendar,
html[data-theme='light'] .datepicker-top .ant-calendar {
  width: 320px;
  background-color: transparent;
  border: 0;
}
html[data-theme='light'] .datepicker .ant-calendar-input-wrap,
html[data-theme='light'] .datepicker-top .ant-calendar-input-wrap {
  display: none;
}
html[data-theme='light'] .datepicker .ant-calendar-header,
html[data-theme='light'] .datepicker-top .ant-calendar-header,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header {
  height: 50px;
  border-bottom-color: #e4e8ee;
}
html[data-theme='light'] .datepicker .ant-calendar-header > a,
html[data-theme='light'] .datepicker-top .ant-calendar-header > a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header > a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header > a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header > a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header > a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header > a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header > a,
html[data-theme='light'] .datepicker .ant-calendar-header div > a,
html[data-theme='light'] .datepicker-top .ant-calendar-header div > a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header div > a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header div > a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header div > a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header div > a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header div > a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header div > a {
  padding: 0;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2.45;
}
html[data-theme='light'] .datepicker .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-prev-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-prev-year-btn {
  left: 0;
}
html[data-theme='light'] .datepicker .ant-calendar-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-next-year-btn,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-next-year-btn {
  right: 0;
}
html[data-theme='light'] .datepicker .ant-calendar-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker-top .ant-calendar-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-month-select,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-month-select {
  padding-right: 5px;
}
html[data-theme='light'] .datepicker .ant-calendar-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker-top .ant-calendar-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-my-select a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-my-select a {
  color: #25282e;
  font-size: 16px !important;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 3.125;
}
html[data-theme='light'] .datepicker .ant-calendar-body,
html[data-theme='light'] .datepicker-top .ant-calendar-body {
  padding: 10px 0 20px;
}
html[data-theme='light'] .datepicker .ant-calendar-column-header,
html[data-theme='light'] .datepicker-top .ant-calendar-column-header {
  padding: 5px 0 10px;
  color: #25282e;
}
html[data-theme='light'] .datepicker .ant-calendar-cell,
html[data-theme='light'] .datepicker-top .ant-calendar-cell {
  padding: 5px 0;
}
html[data-theme='light'] .datepicker .ant-calendar-cell .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell .ant-calendar-date,
html[data-theme='light'] .datepicker .ant-calendar-cell .ant-calendar-selected-day,
html[data-theme='light'] .datepicker-top .ant-calendar-cell .ant-calendar-selected-day {
  width: 40px;
  height: 28px;
  border-radius: 0;
  color: #85899d;
  background-color: #fff;
  line-height: 24px;
  font-weight: normal;
}
html[data-theme='light'] .datepicker .ant-calendar-cell:hover .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell:hover .ant-calendar-date {
  background-color: transparent;
}
html[data-theme='light'] .datepicker .ant-calendar-cell.ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell.ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='light'] .datepicker .ant-calendar-cell.ant-calendar-next-month-btn-day .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: #e4e8ee;
}
html[data-theme='light'] .datepicker .ant-calendar-cell:first-child .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell:first-child .ant-calendar-date,
html[data-theme='light'] .datepicker .ant-calendar-cell:last-child .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell:last-child .ant-calendar-date {
  background-color: #e4e8ee;
  color: #babece;
}
html[data-theme='light'] .datepicker .ant-calendar-cell.ant-calendar-disabled-cell .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell.ant-calendar-disabled-cell .ant-calendar-date {
  text-decoration: line-through;
}
html[data-theme='light'] .datepicker .ant-calendar-cell.ant-calendar-today .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell.ant-calendar-today .ant-calendar-date {
  border-color: #4261ff;
  color: #4261ff;
}
html[data-theme='light'] .datepicker .ant-calendar-cell.ant-calendar-selected-date .ant-calendar-date,
html[data-theme='light'] .datepicker-top .ant-calendar-cell.ant-calendar-selected-date .ant-calendar-date {
  background-color: #4261ff;
  color: #fff;
}
html[data-theme='light'] .datepicker .ant-calendar-cell,
html[data-theme='light'] .datepicker-top .ant-calendar-cell,
html[data-theme='light'] .datepicker .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel-decade {
  height: 38px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='light'] .datepicker .ant-calendar-footer,
html[data-theme='light'] .datepicker-top .ant-calendar-footer {
  padding: 15px 0 0;
  border-top-color: #e4e8ee;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .datepicker .ant-calendar-footer a,
html[data-theme='light'] .datepicker-top .ant-calendar-footer a {
  color: #85899d;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel,
html[data-theme='light'] .datepicker .ant-calendar-year-panel,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel {
  padding: 0 20px 0;
  margin-bottom: 5px;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-century,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-century,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-century,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-century,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-century,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-century {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 3.125;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header {
  position: relative;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-body,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-body,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-body {
  padding: 10px 0 0;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-month,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-year,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-decade,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-decade {
  padding: 7px 5px;
  width: 100%;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-month:hover,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-decade:hover {
  background-color: transparent;
  color: #85899d;
}
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='light'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a:hover {
  background-color: #4261ff;
  color: #fff;
  border-radius: 0;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-decade-select {
  color: #25282e !important;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-decade-select:hover {
  color: #141519 !important;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-year {
  color: #9dabbf;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-year:hover {
  color: #808ea2 !important;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: #9dabbf;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year:hover,
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year:hover {
  color: #808ea2 !important;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-btn {
  color: #9dabbf;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-prev-decade-btn:hover,
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-btn:hover {
  color: #808ea2 !important;
}
html[data-theme='light'] .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover {
  color: #fff !important;
}
html[data-theme='light'] .container {
  max-width: 1202px;
  margin: 0 auto;
  padding: 0 40px;
}
html[data-theme='light'] .container .row {
  margin-bottom: 30px;
}
html[data-theme='light'] .header {
  position: relative;
  height: 80px;
  background-color: var(--background-header);
  color: #fff #25282e;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='light'] .header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  html[data-theme='light'] .header > .container {
    padding: 0 20px;
  }
}
html[data-theme='light'] .header__left,
html[data-theme='light'] .header__right {
  max-width: 40%;
  flex-shrink: 0;
  text-align: left;
}
html[data-theme='light'] .header.header_with-navigation .header__left,
html[data-theme='light'] .header.header_with-navigation .header__right {
  flex-basis: 120px;
}
@media only screen and (max-width: 768px) {
  html[data-theme='light'] .header.header_with-navigation .header__left,
  html[data-theme='light'] .header.header_with-navigation .header__right {
    flex-basis: 95px;
  }
}
@media only screen and (max-width: 480px) {
  html[data-theme='light'] .header.header_with-navigation .header__left,
  html[data-theme='light'] .header.header_with-navigation .header__right {
    flex-basis: 80px;
  }
}
html[data-theme='light'] .header__right {
  text-align: right;
}
html[data-theme='light'] .header__center {
  flex: 1;
}
html[data-theme='light'] .header__button {
  position: relative;
  z-index: 10;
  display: inline-flex;
  justify-content: space-between;
  float: left;
  align-items: center;
  padding: 5px 0;
  background: none;
  border: 0;
  min-height: 32px;
  color: #25282e;
  text-align: left;
}
html[data-theme='light'] .header__button.left-button[name='login'] .Icon {
  transform: scaleX(-1);
}
@media only screen and (max-width: 540px) {
  html[data-theme='light'] .header__button span {
    display: none;
  }
}
html[data-theme='light'] .header__button:hover {
  color: #25282e;
  cursor: pointer;
}
html[data-theme='light'] .header__button:hover span {
  text-decoration: underline;
}
html[data-theme='light'] .header__right .header__button {
  float: right;
  text-align: right;
  flex-direction: row-reverse;
}
html[data-theme='light'] .header__right .header__button .Icon + span {
  margin-right: 10px;
  margin-left: 0;
}
html[data-theme='light'] .header__logo {
  width: 100%;
  color: #25282e;
}
html[data-theme='light'] .header__logo a {
  color: #25282e;
}
html[data-theme='light'] .header__logo b {
  font-weight: bold;
}
html[data-theme='light'] .header__navigation {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
html[data-theme='light'] .header__navigation .navigation__link {
  position: relative;
  display: inline-block;
  color: #85899d;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .header__navigation .navigation__link:hover,
html[data-theme='light'] .header__navigation .navigation__link:active {
  color: #050506;
}
html[data-theme='light'] .header__navigation .navigation__link.navigation__link-active {
  color: #050506;
  font-weight: bold;
}
html[data-theme='light'] .header__navigation .navigation__link.navigation__link_changed:after {
  position: absolute;
  right: -8px;
  top: 0;
  background-color: #ff5f88;
}
html[data-theme='light'] .header img {
  max-width: 65px;
  margin: 0 20px;
}
html[data-theme='light'] .header .Icon {
  font-size: 18px;
}
html[data-theme='light'] .header .Icon + span {
  margin-left: 10px;
}
html[data-theme='light'] .header .profile-menu {
  justify-content: flex-end;
}
@media only screen and (max-width: 480px) {
  html[data-theme='light'] .profile-menu__Popover,
  html[data-theme='light'] .notifications-menu__Popover {
    left: 0 !important;
    right: 0 !important;
  }
  html[data-theme='light'] .profile-menu__Popover.dropdown-inner .rc-tooltip-inner-content,
  html[data-theme='light'] .notifications-menu__Popover.dropdown-inner .rc-tooltip-inner-content {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  html[data-theme='light'] .profile-menu__Popover .rc-tooltip-arrow {
    right: var(--padding-base) !important;
  }
  html[data-theme='light'] .notifications-menu__Popover .rc-tooltip-arrow {
    right: calc(var(--padding-base) + var(--avatar-size-sm) + var(--margin-base)) !important;
  }
}
html[data-theme='light'] .loader-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
html[data-theme='light'] .loader-wrapper .loader {
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 2px solid #babece;
  border-left-color: #808ea2;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
}
html[data-theme='light'] .loader-wrapper .loader.loader-large {
  border-width: 3px;
  width: 50px;
  height: 50px;
}
html[data-theme='light'] .loader-wrapper .loader.loader-light {
  border-color: #e4e8ee;
  border-left-color: #85899d;
}
html[data-theme='light'] .loader-wrapper .loader.loader-negative {
  border-color: #ff9bb5;
  border-left-color: #fff;
}
html[data-theme='light'] .loader-wrapper .loader .loader-circle {
  display: none;
}
html[data-theme='light'] .loader-wrapper .loader .loader-circle:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  position: absolute;
  left: 50%;
  top: 25%;
  height: 25%;
  width: 25%;
  opacity: 1;
  transform-origin: left top;
  border-right: 3px solid #808ea2;
  border-top: 3px solid #808ea2;
  content: '';
}
@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 50px;
    opacity: 1;
  }
  40% {
    height: 50px;
    width: 50px;
    opacity: 1;
  }
  100% {
    height: 50px;
    width: 50px;
    opacity: 1;
  }
}
html[data-theme='light'] .PageVariant.wrapper {
  max-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='light'] .PageVariant .main {
  max-width: 354px;
  text-align: center;
}
html[data-theme='light'] .PageVariant .Content-box {
  display: inline-block;
  padding: 30px !important;
  vertical-align: top;
  width: 100%;
  text-align: left;
}
html[data-theme='light'] .PageVariant i {
  margin-bottom: 20px;
}
html[data-theme='light'] .PageVariant .error > i {
  color: #ff376b !important;
}
html[data-theme='light'] .PageVariant .success > i {
  color: #07cc9b !important;
}
html[data-theme='light'] .PageVariant p {
  margin-bottom: 20px;
  color: #85899d;
}
html[data-theme='light'] .PageVariant button {
  width: 100%;
}
html[data-theme='light'] .PageVariant button + button {
  margin-top: 20px;
}
@media screen and (min-width: 240px) {
  html[data-theme='light'] html,
  html[data-theme='light'] body {
    margin: 0;
  }
}
@media screen and (max-width: 240px) {
  html[data-theme='light'] html,
  html[data-theme='light'] body {
    margin: 0;
  }
  html[data-theme='light'] .navigation {
    width: 100%;
  }
}
html[data-theme='light'] .navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -240px;
  z-index: 2000;
  width: 240px;
  height: 100vh;
  background-color: #fff;
  margin: 0;
  padding: 40px;
  border-right: 1px solid #babece;
  overflow-y: auto;
  transition: 0.3s;
}
html[data-theme='light'] .navigation ~ * {
  transition: transform 0.3s;
}
html[data-theme='light'] .navigation_open {
  left: 0;
}
html[data-theme='light'] .navigation_open ~ * {
  transform: translateX(240px);
}
html[data-theme='light'] .navigation__link {
  display: block;
  color: #85899d;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 3.33333333;
}
html[data-theme='light'] .navigation__link:hover {
  color: #808ea2;
}
html[data-theme='light'] .navigation__link:active {
  color: #5e6b7d;
}
html[data-theme='light'] .navigation__link_changed:after {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: super;
  border-radius: 50%;
  background: #4261ff;
  content: ' ';
  margin-left: 2.66666667px;
}
html[data-theme='light'] .navigation__link_home {
  font-weight: bold;
  color: #25282e;
}
html[data-theme='light'] .navigation__link_home:hover {
  color: #25282e;
}
html[data-theme='light'] .navigation__button {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  padding: 0;
  margin-bottom: 5.33333333px;
  color: #4261ff;
  background: none;
  border: 0;
  cursor: pointer;
}
html[data-theme='light'] .navigation__button .Icon {
  font-size: 16px;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select {
  max-height: 220px;
  overflow-y: auto;
  display: flex;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-inner {
  width: 16px;
  height: 16px;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper {
  display: flex;
  flex: 1 0 auto;
  margin: 0 !important;
  padding: 12px 20px;
  color: #25282e;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper .checkbox-input {
  position: absolute !important;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover {
  background-color: #4261ff;
  color: #fff;
  cursor: pointer;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover .checkbox-label {
  color: #fff;
}
html[data-theme='light'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-label {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] .select {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
}
html[data-theme='light'] .select__multi {
  min-width: 200px;
}
html[data-theme='light'] .select__multi-mini {
  width: auto;
  height: auto;
  min-width: 0;
}
html[data-theme='light'] .select__button {
  width: 100%;
  height: inherit;
  padding: 6px 27px 6px 12px;
  background: transparent;
  border: 1px solid #babece;
  border-radius: 2px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease 0s;
}
html[data-theme='light'] .form-group_focused .select__button {
  background-color: #fff;
  border-color: #4261ff;
  outline: none;
}
html[data-theme='light'] .select__button:active,
html[data-theme='light'] .select__button:hover,
html[data-theme='light'] .form-group_focused .select__button:hover,
html[data-theme='light'] .form-group_focused .select__button:hover:active,
html[data-theme='light'] .form-group_opened .select__button {
  background-color: #e4e8ee;
  border-color: #85899d;
  outline: none;
  cursor: pointer;
}
html[data-theme='light'] .form-group_opened .select__button {
  border-radius: 2px 2px 0 0;
}
html[data-theme='light'] .form-group_invalid .select__button {
  border-color: #ff376b;
}
html[data-theme='light'] .form-group_invalid .select__button:hover {
  border-color: #ce3960;
}
html[data-theme='light'] .form-group_disabled .select__button {
  pointer-events: none;
}
html[data-theme='light'] .select__button_with_placeholder {
  color: #85899d;
}
html[data-theme='light'] .select__button_mini {
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 2px;
}
html[data-theme='light'] .select__button_mini .Icon.anticon__arrow {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, -50%, 0);
}
html[data-theme='light'] .form-group_opened .select__button_mini .Icon.anticon__arrow {
  transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}
html[data-theme='light'] .form-group_opened .select__button_mini {
  border-color: transparent;
  border-radius: 2px;
}
html[data-theme='light'] .select__button_mini.select__button_checked {
  background-color: #4261ff;
  border-color: #4261ff;
}
html[data-theme='light'] .select__button_mini.select__button_checked .Icon.anticon__arrow {
  color: #fff;
}
html[data-theme='light'] .select__button_mini.select__button_checked:hover {
  background-color: #e4e8ee;
  border-color: #85899d;
}
html[data-theme='light'] .select__button_mini.select__button_checked:hover .Icon.anticon__arrow {
  color: #25282e;
}
html[data-theme='light'] .select__button_tooltiped {
  padding-right: 52px;
}
html[data-theme='light'] .select__button_boxed {
  border-color: #babece;
}
html[data-theme='light'] .select__dropdown {
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  display: none;
  width: 100%;
  border: 1px solid #babece;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  transition: all 0.3s ease 0s;
}
html[data-theme='light'] .form-group_opened .select__dropdown {
  display: block;
}
html[data-theme='light'] .form-group_invalid .select__dropdown {
  border-top-color: #ff376b;
}
html[data-theme='light'] .form-group_invalid .select__dropdown:hover {
  border-top-color: #ce3960;
}
html[data-theme='light'] .select__dropdown_mini {
  min-width: 250px;
  border-radius: 2px;
  margin-top: 5px;
}
html[data-theme='light'] .select__search {
  height: 30px;
  margin: 20px 20px 10px;
  width: calc(100% - 20px * 2);
  padding: 7px 12px;
  box-sizing: border-box !important;
  border: 1px solid #babece;
  border-radius: 2px;
  background-color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
}
html[data-theme='light'] .select .select__search + fieldset {
  max-height: 240px;
}
html[data-theme='light'] .select__dropdown .select__search[hidden] + fieldset {
  margin-top: 10px;
}
html[data-theme='light'] .select__search:hover {
  border-color: #85899d;
}
html[data-theme='light'] .select__search:focus {
  border-color: #4261ff;
  outline: none;
}
html[data-theme='light'] .select__option {
  line-height: 0;
  transition: all 0.3s ease 0s;
}
html[data-theme='light'] .select__option .checkbox-wrapper {
  display: flex;
  padding: 12px 20px;
  color: #25282e;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] .select__option .checkbox-label {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='light'] .select__option:hover {
  background-color: #4261ff;
  cursor: pointer;
}
html[data-theme='light'] .select__option:hover .checkbox-wrapper,
html[data-theme='light'] .select__option:hover .checkbox-label {
  color: #fff;
}
html[data-theme='light'] .select__controls {
  border-top: 1px solid #e4e8ee;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0 0;
  margin: 10px 20px 20px;
}
html[data-theme='light'] .select__controls button {
  padding: 0;
  border: 0;
  background: none;
  color: #85899d;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  cursor: pointer;
}
html[data-theme='light'] .select__controls button:first-child {
  font-weight: bold;
}
html[data-theme='light'] .select__controls button:hover {
  color: #808ea2;
}
html[data-theme='light'] .form-group_disabled .select {
  opacity: 0.5;
  pointer-events: none;
}
html[data-theme='light'] .select fieldset {
  max-height: 300px;
  overflow: auto;
}
html[data-theme='light'] .selected__values {
  display: block;
  height: 16px;
  width: calc(100% - 22px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .selected__count {
  height: 16px;
  padding: 0 5px;
  background: #4261ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
  border-radius: 3px;
  min-width: 18px;
}
html[data-theme='light'] .select .Icon {
  position: absolute;
  right: 12px;
  top: 50%;
  display: block;
  transform: translate3d(0, -50%, 0);
  transition: all 0.3s ease 0s;
}
html[data-theme='light'] .select .Icon.anticon__arrow {
  font-size: 10px;
}
html[data-theme='light'] .form-group_opened .select .Icon.anticon__arrow,
html[data-theme='light'] .select .Icon.anticon__arrow:hover:visited,
html[data-theme='light'] .select .Icon.anticon__arrow:visited {
  color: #4261ff;
  transform: translate3d(0, -50%, 0) rotate(-180deg);
}
html[data-theme='light'] .select .Icon.anticon__error {
  right: 30px;
  color: #ff376b;
}
html[data-theme='light'] .Skeleton {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  min-height: 15px;
  background: linear-gradient(-90deg, #e4e8ee 0%, #fff 50%, #e4e8ee 100%);
  background-size: 400% 400%;
  animation: left-to-right 1.2s ease-in-out infinite;
}
html[data-theme='light'] .Skeleton.line {
  display: inline-block;
  border-radius: 4px;
}
html[data-theme='light'] .Skeleton.circle {
  border-radius: 100%;
  display: block;
}
html[data-theme='light'] .Skeleton:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #e4e8ee 0%, #fff 50%, #e4e8ee 100%);
  background-size: 400% 400%;
  content: '';
  border-radius: inherit;
  animation: left-to-right 1.2s ease-in-out infinite;
}
@keyframes left-to-right {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -135% 0;
  }
}
html[data-theme='light'] .ant-slider {
  margin-left: 75px;
  margin-right: 75px;
}
html[data-theme='light'] .ant-slider-rail {
  width: calc(100% + 2px);
}
html[data-theme='light'] .ant-slider-rail,
html[data-theme='light'] .ant-slider-track {
  height: 3px;
  border-radius: 0;
}
html[data-theme='light'] .ant-slider-dot {
  top: 13px;
  width: 2px;
  height: 15px;
  margin-left: 0;
  background-color: #babece;
  border: none;
  border-radius: 0;
}
html[data-theme='light'] .ant-slider-dot:first-child,
html[data-theme='light'] .ant-slider-dot:last-child {
  margin-left: 0;
}
html[data-theme='light'] .ant-slider-handle {
  width: 18px;
  height: 18px;
  margin-top: -7.5px;
  margin-left: -8px;
  background-color: #4261ff;
  border: none;
}
html[data-theme='light'] .ant-slider-handle.hidden {
  opacity: 0;
}
html[data-theme='light'] .ant-slider-handle:focus {
  box-shadow: none;
}
html[data-theme='light'] .ant-slider-mark {
  top: 43px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='light'] .ant-slider-mark-text {
  width: 150px;
  color: #25282e;
}
html[data-theme='light'] .ant-slider-with-marks {
  margin-bottom: 61px;
}
html[data-theme='light'] .ant-tabs {
  overflow: unset;
}
html[data-theme='light'] .ant-tabs-ink-bar {
  height: 5px;
  line-height: 1.5;
}
html[data-theme='light'] .ant-tabs-bar {
  margin-bottom: 30px;
  border-bottom: 1px solid #e4e8ee;
}
html[data-theme='light'] .ant-tabs-nav-container {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab {
  color: #85899d;
}
html[data-theme='light'] .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
  color: #050506;
}
html[data-theme='light'] .ant-tabs-extra-content {
  display: flex;
  align-items: center;
  height: 55px;
}
html[data-theme='light'] .ant-tabs-extra__item {
  display: inline-block;
  margin: 5px 5px 5px 15px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  line-height: 0;
  cursor: pointer;
}
html[data-theme='light'] .ant-tabs-extra__item:last-child {
  margin-right: 0;
}
html[data-theme='light'] .ant-tabs-extra__item > .Icon {
  color: #babece;
}
html[data-theme='light'] .ant-tabs-extra__item:hover > .Icon {
  color: #141519;
}
html[data-theme='light'] .ant-tabs-extra__item_active > .Icon {
  color: #050506;
}
html[data-theme='light'] .ant-tabs-extra__item .form-group {
  margin: 0;
}
html[data-theme='light'] .tabbed-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
}
html[data-theme='light'] .tabbed-navigation__link {
  color: #85899d;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  text-align: center;
}
html[data-theme='light'] .tabbed-navigation__link:hover {
  color: #141519;
}
html[data-theme='light'] .tabbed-navigation__link:active {
  color: #050506;
}
html[data-theme='light'] .tabbed-navigation__link_active {
  color: #25282e;
  font-weight: bold;
}
html[data-theme='light'] .tabbed-navigation__link + .tabbed-navigation__link {
  margin-left: 40px;
}
html[data-theme='light'] .colors ul {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 0;
}
html[data-theme='light'] .colors li {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  margin: 0 2% 2%;
  width: 20%;
  border-radius: 4px;
  /* stylelint-disable-next-line */
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
  padding-top: 70px;
}
html[data-theme='light'] .colors span {
  display: block;
  padding: 10px 15px;
  background: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='light'] .colors_mini ul {
  flex-wrap: wrap;
  margin: 0;
}
html[data-theme='light'] .colors_mini li {
  display: flex;
  flex-direction: initial;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 0;
  padding: 0 0 0 20px;
  box-shadow: none;
}
html[data-theme='light'] .colors_mini span {
  padding: 0 0 0 10px;
  width: 100%;
}
html[data-theme='light'] .primary-default {
  background-color: #25282e;
}
html[data-theme='light'] .primary-lighter {
  background-color: #babece;
}
html[data-theme='light'] .primary-light {
  background-color: #85899d;
}
html[data-theme='light'] .primary-dark {
  background-color: #141519;
}
html[data-theme='light'] .primary-darker {
  background-color: #050506;
}
html[data-theme='light'] .secondary-default {
  background-color: #9dabbf;
}
html[data-theme='light'] .secondary-lighter {
  background-color: #e4e8ee;
}
html[data-theme='light'] .secondary-light {
  background-color: #c3ccd9;
}
html[data-theme='light'] .secondary-dark {
  background-color: #808ea2;
}
html[data-theme='light'] .secondary-darker {
  background-color: #5e6b7d;
}
html[data-theme='light'] .accent-default {
  background-color: #4261ff;
}
html[data-theme='light'] .accent-lighter {
  background-color: #a0b0ff;
}
html[data-theme='light'] .accent-light {
  background-color: #6780ff;
}
html[data-theme='light'] .accent-dark {
  background-color: #3851d1;
}
html[data-theme='light'] .accent-darker {
  background-color: #2d41a6;
}
html[data-theme='light'] .positive-default {
  background-color: #07cc9b;
}
html[data-theme='light'] .positive-lighter {
  background-color: #83e5cd;
}
html[data-theme='light'] .positive-light {
  background-color: #38d6af;
}
html[data-theme='light'] .positive-dark {
  background-color: #19b38d;
}
html[data-theme='light'] .positive-darker {
  background-color: #159475;
}
html[data-theme='light'] .negative-default {
  background-color: #ff376b;
}
html[data-theme='light'] .negative-lighter {
  background-color: #ff9bb5;
}
html[data-theme='light'] .negative-light {
  background-color: #ff5f88;
}
html[data-theme='light'] .negative-dark {
  background-color: #ce3960;
}
html[data-theme='light'] .negative-darker {
  background-color: #bb3256;
}
html[data-theme='light'] .white {
  background-color: #fff;
}
html[data-theme='light'] .line-chart-goal-color {
  background-color: #4261ff;
}
html[data-theme='light'] .checkbox-check-color {
  background-color: #fff;
}
html[data-theme='light'] .switch-color {
  background-color: #4261ff;
}
html[data-theme='light'] .pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .pagination-item,
html[data-theme='light'] .pagination-prev,
html[data-theme='light'] .pagination-next {
  height: 20px;
  min-width: 20px;
  margin: 0 5px;
  color: #85899d;
  list-style: none;
  line-height: 20px;
  text-align: center;
}
html[data-theme='light'] .pagination-item:hover,
html[data-theme='light'] .pagination-prev:hover,
html[data-theme='light'] .pagination-next:hover,
html[data-theme='light'] .pagination-item:focus,
html[data-theme='light'] .pagination-prev:focus,
html[data-theme='light'] .pagination-next:focus,
html[data-theme='light'] .pagination-item:hover button,
html[data-theme='light'] .pagination-prev:hover button,
html[data-theme='light'] .pagination-next:hover button {
  color: #25282e;
  cursor: pointer;
}
html[data-theme='light'] .pagination-prev,
html[data-theme='light'] .pagination-next {
  margin-left: 0;
  font-size: 10px;
}
html[data-theme='light'] .pagination-next {
  margin: 0 0 0 5px;
}
html[data-theme='light'] .pagination button {
  display: block;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
}
html[data-theme='light'] .pagination-item-active button {
  font-weight: bold;
  color: #25282e;
}
html[data-theme='light'] .paragraph_primary,
html[data-theme='light'] .paragraph_title,
html[data-theme='light'] .paragraph_secondary {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='light'] .paragraph_highlights {
  font-weight: bold;
}
html[data-theme='light'] .paragraph_title {
  font-style: italic;
}
html[data-theme='light'] .paragraph_secondary {
  color: #85899d;
}
html[data-theme='light'] .ant-steps-wrapper {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  color: #25282e;
  text-align: center;
}
html[data-theme='light'] .ant-steps-horizontal {
  color: #25282e;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-tail {
  margin-left: 52.16px;
  padding: 3.04761905px 15.02347418px;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-tail:after {
  height: 2px;
  background: linear-gradient(to right, #25282e 50%, #babece 50%) right bottom;
  background-size: 200% 100%;
  transition: all 0.5s ease-out 0s;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-custom .ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px !important;
  height: 32px !important;
  border: 2px solid #25282e;
  transition: border-color 0.5s ease 0.5s;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-custom .ant-steps-item-icon .ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  color: transparent;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #babece !important;
  transition-duration: 0.25s;
  transition-delay: 0s;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-process.ant-steps-item-custom .ant-steps-icon {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.83333333;
  color: #25282e;
  transition: color 0.1s ease 0.5s;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-tail:after {
  background-position: left bottom;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-finish.ant-steps-item-custom .ant-steps-icon {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
  color: #25282e;
}
html[data-theme='light'] .ant-steps-horizontal .ant-steps-item-title {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  color: #25282e !important;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small {
  justify-content: center;
  padding-top: 9px;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item {
  flex: 0 0 44px;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-tail {
  top: 7.5px;
  margin-left: 22px;
  padding: 0 7px;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-tail:after {
  height: 1px;
  transition-duration: 0.3s;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-content {
  display: none;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: 16px !important;
  height: 16px !important;
  margin: auto;
  border: 1px solid #25282e;
  border-radius: 50%;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-wait .ant-steps-item-icon {
  transition-duration: 0.15s;
  transition-delay: 0s;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-process .ant-steps-icon {
  font-size: 6px;
  font-size: 0.6rem;
  line-height: 3.66666667;
  transition-delay: 0.3s;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-finish .ant-steps-icon {
  font-size: 8px;
  font-size: 0.8rem;
  line-height: 2.75;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item {
  flex-basis: 50px;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-icon {
  display: none;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-tail {
  margin-left: 0;
  padding: 0 5px;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-tail:after {
  height: 3px;
  background: linear-gradient(to right, #4261ff 50%, #babece 50%) right bottom;
  background-size: 200% 100%;
  transition: all 0.3s ease-out 0s;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item:last-child .ant-steps-item-tail {
  display: initial;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-process .ant-steps-item-tail:after {
  box-shadow: 0 0 5px 0.5px rgba(76, 132, 118, 0.5);
  background-position: left bottom;
}
html[data-theme='light'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-finish .ant-steps-item-tail:after {
  background-position: left bottom;
}
html[data-theme='light'] .content-clearfix.Content-box {
  padding-bottom: 15px;
}
html[data-theme='light'] .content-clearfix .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='light'] .padding-reset {
  padding: 0;
}
html[data-theme='light'] #root {
  display: flex;
  flex-direction: column;
  background: #f5f6fa;
}
html[data-theme='light'] #root > .container {
  min-height: calc(100vh - 182px);
}
html[data-theme='light'] #root .PageVariant.main {
  height: calc(100vh - 212px);
}
html[data-theme='light'] #root .datepicker-top .ant-calendar {
  margin-top: -447px;
  margin-left: -77px;
}
html[data-theme='light'] #root .content-wrapper {
  margin: 0 -30px;
}
html[data-theme='light'] #root .widget__empty {
  margin-bottom: 0;
  text-align: center;
}
html[data-theme='light'] #root .loader-wrapper {
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;
}
html[data-theme='light'] #root .buttons-block .button {
  float: right;
  margin-left: 20px;
  margin-top: 28px;
}
html[data-theme='light'] #root .button-group:not(.button-group--horiz) button + button {
  margin-left: 20px;
}
html[data-theme='light'] #root .button-group.text-sm-left .button {
  float: left;
  margin-left: 0;
}
html[data-theme='light'] #root .pie-chart-container .highcharts-pie {
  width: 100%;
  justify-content: center;
}
@media (max-width: 1023px) {
  html[data-theme='light'] #root .pie-chart-container .highcharts-pie {
    flex-direction: column;
  }
}
html[data-theme='light'] #root .highcharts-container {
  z-index: 1;
}
html[data-theme='light'] #root a.paragraph_secondary,
html[data-theme='light'] #root .paragraph_secondary a,
html[data-theme='light'] #root a.paragraph_secondary:active,
html[data-theme='light'] #root .paragraph_secondary a:active,
html[data-theme='light'] #root a.paragraph_secondary:focus,
html[data-theme='light'] #root .paragraph_secondary a:focus {
  color: #9dabbf;
  text-decoration: underline;
}
html[data-theme='light'] #root a.paragraph_secondary:hover,
html[data-theme='light'] #root .paragraph_secondary a:hover {
  color: #808ea2;
}
html[data-theme='light'] #root .Title a {
  color: inherit;
}
html[data-theme='light'] #root .Title .Icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  font-size: 12px;
  line-height: inherit;
}
html[data-theme='light'] #root .bread-crumbs a {
  color: var(--primary-light);
}
html[data-theme='light'] #root .select__multi {
  min-width: inherit;
}
html[data-theme='light'] main.container {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  html[data-theme='light'] main.container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  html[data-theme='light'] .ant-modal {
    min-width: 370px !important;
  }
  html[data-theme='light'] .ant-modal-confirm-centered {
    position: relative;
    top: -600px;
  }
}
@media (max-width: 992px) {
  html[data-theme='light'] .container .row {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  html[data-theme='light'] .container .row + .row {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  html[data-theme='light'] .Content-box {
    overflow-x: hidden;
    padding: 15px;
    box-shadow: none;
    border-radius: 0;
  }
  html[data-theme='light'] .Content-box .button-sm {
    min-width: 100%;
    margin-top: 15px !important;
  }
}
@media (max-width: 767px) {
  html[data-theme='light'] .Content-box > .row {
    padding-bottom: 15px;
  }
}
html[data-theme='light'] .header__left,
html[data-theme='light'] .header__right {
  min-width: 150px;
}
@media (max-width: 767px) {
  html[data-theme='light'] .header .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
html[data-theme='light'] footer {
  margin-top: auto;
}
@media (max-width: 767px) {
  html[data-theme='light'] .Footer_mini .Footer__bottom {
    display: flex;
    align-items: center;
    padding: 30px 15px;
    flex-direction: column-reverse;
  }
  html[data-theme='light'] .Footer_mini .Footer__bottom a {
    margin-left: 0;
    margin-bottom: 15px;
  }
  html[data-theme='light'] .Footer_mini .Footer__bottom span {
    display: block;
    text-align: center;
  }
}
html[data-theme='light'] .auto-logout.ant-modal-confirm .ant-modal-confirm-btns {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  html[data-theme='light'] .hide-on-mobile {
    display: none !important;
  }
}
html[data-theme='light'] .overview-panel {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 72px;
}
html[data-theme='light'] .overview-panel:not(:last-child) {
  padding-bottom: 30px;
}
html[data-theme='light'] .overview-panel .row {
  margin-bottom: 0 !important;
}
html[data-theme='light'] .overview-panel .row + .row {
  margin-top: 30px;
}
html[data-theme='light'] .overview-item {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  color: #25282e;
}
html[data-theme='light'] .overview-item__title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
}
html[data-theme='light'] .overview-item__value {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.125;
}
html[data-theme='light'] .overview-item__value_positive {
  color: #07cc9b;
}
html[data-theme='light'] .overview-item__value_negative {
  color: #ff376b;
}
html[data-theme='light'] .overview-item__value_small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
  color: #25282e;
}
html[data-theme='light'] .overview-item__currency {
  margin-left: 5px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  color: #25282e;
}
html[data-theme='light'] .media {
  display: flex;
  align-items: center;
}
html[data-theme='light'] .media img {
  border-radius: 50%;
  margin-right: 10px;
}
html[data-theme='light'] .media-body {
  flex: 1;
}
html[data-theme='light'] .media-body .media-links {
  display: flex;
  flex-wrap: wrap;
}
html[data-theme='light'] .media-body h3 {
  margin-bottom: 0;
}
html[data-theme='light'] .media-body a {
  text-decoration: none !important;
}
html[data-theme='light'] .media-body a.call-now {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  color: #9dabbf;
  margin-left: 14px;
}
html[data-theme='light'] .single-news-item {
  margin-bottom: 40px !important;
}
@media (max-width: 767px) {
  html[data-theme='light'] .single-news-item hr {
    display: none;
  }
}
html[data-theme='light'] .single-news-item .Content-box .button {
  min-width: 60px;
  margin-left: 20px;
}
html[data-theme='light'] .single-news-item hr {
  display: block;
  height: 1px;
  margin: 30px -30px;
  border: none;
  color: #e4e8ee;
  background-color: #e4e8ee;
}
html[data-theme='light'] .single-news-item--image {
  max-width: 100%;
}
@media (max-width: 767px) {
  html[data-theme='light'] .single-news-item--image {
    margin: 20px 0;
  }
}
html[data-theme='light'] .single-news-item--date {
  min-width: 100px;
  line-height: 1.7;
  margin-bottom: 10px;
}
html[data-theme='light'] .single-news-item--content {
  margin-bottom: 9px;
  white-space: pre-line;
}
@media (max-width: 767px) {
  html[data-theme='light'] .single-news-item--content {
    text-align: justify;
  }
}
html[data-theme='light'] .portfolios {
  margin-bottom: 40px;
}
html[data-theme='light'] .portfolios.Content-box {
  padding-bottom: 15px;
}
html[data-theme='light'] .portfolios .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='light'] .portfolios .loader-wrapper {
  padding-bottom: 120px !important;
}
html[data-theme='light'] .analysis .column-center {
  margin: auto;
}
html[data-theme='light'] .analysis .AccordionPanel-content {
  overflow: visible;
}
html[data-theme='light'] .analysis .AccordionPanel-content-box {
  padding-bottom: 15px;
}
html[data-theme='light'] .analysis .Info-box {
  margin: 15px auto;
}
html[data-theme='light'] .analysis .risk-return-container {
  margin: 0 auto 15px;
  width: fit-content;
}
html[data-theme='light'] .analysis .highcharts-container {
  z-index: unset;
}
html[data-theme='light'] .analysis .highcharts-container .highcharts-background {
  width: 0;
}
html[data-theme='light'] .analysis .loader-wrapper {
  margin: 0 auto !important;
  padding-bottom: 15px;
}
html[data-theme='light'] .analysis .highcharts-tooltip > span {
  padding: 7px !important;
  outline: 7px solid #fff;
  background: #fff;
  white-space: nowrap !important;
}
html[data-theme='light'] .analysis .highcharts-tooltip > span .value {
  margin-left: 10px;
}
html[data-theme='light'] .single-portfolio {
  margin-bottom: 40px !important;
}
html[data-theme='light'] .single-portfolio .Content-box {
  padding-bottom: 0;
}
@media (max-width: 928px) {
  html[data-theme='light'] .single-portfolio .button {
    clear: both;
  }
}
@media (max-width: 575px) {
  html[data-theme='light'] .single-portfolio .button {
    width: 100%;
  }
}
html[data-theme='light'] .single-portfolio .Info-box {
  margin-bottom: 30px;
}
html[data-theme='light'] .single-portfolio .highcharts-line .highcharts-tooltip > span {
  padding: 11.5px 7px !important;
  white-space: nowrap !important;
}
html[data-theme='light'] .single-portfolio .overview-panel .bottom-padded {
  margin-bottom: 20px;
}
html[data-theme='light'] .single-portfolio .overview-panel .bottom-padded .overview-item:first-child {
  padding-bottom: 22px;
}
html[data-theme='light'] .single-portfolio .overview-panel .overview-item {
  align-items: flex-start;
}
html[data-theme='light'] .single-portfolio .overview-panel .overview-item .overview-item__value {
  height: 38px;
  line-height: 38px;
}
html[data-theme='light'] .single-portfolio .overview-panel .mobile-portfolio-value-performance {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
}
html[data-theme='light'] .single-portfolio .overview-panel .mobile-portfolio-value-performance .overview-item {
  width: 50%;
}
html[data-theme='light'] .single-portfolio .overview-panel .mobile-portfolio-value-performance .overview-item:first-child {
  padding-bottom: 0;
}
html[data-theme='light'] .single-portfolio .security-transactions .AccordionPanel-content-box,
html[data-theme='light'] .single-portfolio .positions .AccordionPanel-content-box,
html[data-theme='light'] .single-portfolio .proposals .AccordionPanel-content-box {
  padding-bottom: 10px;
}
html[data-theme='light'] .single-portfolio .security-transactions .loader-wrapper,
html[data-theme='light'] .single-portfolio .positions .loader-wrapper,
html[data-theme='light'] .single-portfolio .proposals .loader-wrapper {
  margin-bottom: 15px;
}
html[data-theme='light'] .single-portfolio .positions.AccordionPanel__active .AccordionPanel-header,
html[data-theme='light'] .single-portfolio .proposals.AccordionPanel__active .AccordionPanel-header {
  padding-bottom: 14px;
}
html[data-theme='light'] .single-portfolio .positions .widget__empty,
html[data-theme='light'] .single-portfolio .proposals .widget__empty {
  margin-bottom: 20px !important;
}
html[data-theme='light'] .single-portfolio .security-transactions .info-box_error,
html[data-theme='light'] .single-portfolio .cash-flows .info-box_error {
  margin-bottom: 20px;
}
html[data-theme='light'] .single-portfolio .security-transactions .widget__empty,
html[data-theme='light'] .single-portfolio .cash-flows .widget__empty {
  margin: 0 0 20px !important;
}
html[data-theme='light'] .single-portfolio .table-wrapper .pagination {
  padding-bottom: 20px;
}
html[data-theme='light'] .single-portfolio .data-wrapper {
  border-top: 1px solid #e4e8ee;
}
html[data-theme='light'] .single-portfolio .positions .currency-cel {
  padding-left: 30px;
}
html[data-theme='light'] .single-portfolio .positions .table-cell__actions {
  padding: 20px 0 20px 10px;
}
html[data-theme='light'] .single-portfolio .performance.AccordionPanel__active .AccordionPanel-header,
html[data-theme='light'] .single-portfolio .allocation.AccordionPanel__active .AccordionPanel-header {
  padding-bottom: 8px;
}
html[data-theme='light'] .single-portfolio .performance .ant-tabs,
html[data-theme='light'] .single-portfolio .allocation .ant-tabs {
  margin-top: 0 !important;
}
html[data-theme='light'] .single-portfolio .performance .pie-chart-container,
html[data-theme='light'] .single-portfolio .allocation .pie-chart-container {
  justify-content: center;
}
@media only screen and (min-width: 1040px) {
  html[data-theme='light'] .single-portfolio .performance .pie-chart-container .highcharts-pie,
  html[data-theme='light'] .single-portfolio .allocation .pie-chart-container .highcharts-pie {
    width: 25% !important;
  }
}
html[data-theme='light'] .single-portfolio .cash-flows {
  border-bottom: 0 !important;
}
html[data-theme='light'] .single-portfolio .cash-flows .loader-wrapper {
  margin-bottom: 15px;
}
html[data-theme='light'] .single-portfolio .cash-flows .AccordionPanel-content-box {
  padding-bottom: 5px;
}
html[data-theme='light'] .position-details,
html[data-theme='light'] .position-details-overview {
  margin-top: 30px;
  margin-bottom: 40px !important;
}
html[data-theme='light'] .position-details .Content-box,
html[data-theme='light'] .position-details-overview .Content-box {
  padding-bottom: 10px;
}
html[data-theme='light'] .position-details .content-wrapper,
html[data-theme='light'] .position-details-overview .content-wrapper {
  margin: 0 -30px;
}
html[data-theme='light'] .position-details .overview-panel .overview-item:not(:last-child),
html[data-theme='light'] .position-details-overview .overview-panel .overview-item:not(:last-child) {
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  html[data-theme='light'] .position-details .overview-panel > div,
  html[data-theme='light'] .position-details-overview .overview-panel > div {
    min-width: 40%;
  }
  html[data-theme='light'] .position-details .overview-panel > div + div,
  html[data-theme='light'] .position-details-overview .overview-panel > div + div {
    padding-top: 15px;
  }
  html[data-theme='light'] .position-details .overview-panel > div:last-child .overview-item:last-child,
  html[data-theme='light'] .position-details-overview .overview-panel > div:last-child .overview-item:last-child {
    margin-bottom: 0;
  }
  html[data-theme='light'] .position-details .overview-panel .overview-item,
  html[data-theme='light'] .position-details-overview .overview-panel .overview-item {
    padding-bottom: 0 !important;
  }
}
html[data-theme='light'] .position-details .performance .AccordionPanel-content__active,
html[data-theme='light'] .position-details-overview .performance .AccordionPanel-content__active {
  overflow: visible;
}
html[data-theme='light'] .position-details .performance .AccordionPanel-content__active .info-box_error,
html[data-theme='light'] .position-details-overview .performance .AccordionPanel-content__active .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='light'] .position-details .performance .AccordionPanel-content__active .AccordionPanel-content-box .row,
html[data-theme='light'] .position-details-overview .performance .AccordionPanel-content__active .AccordionPanel-content-box .row {
  margin-bottom: -10px;
}
html[data-theme='light'] .position-details .performance .AccordionPanel-content__active .form-group,
html[data-theme='light'] .position-details-overview .performance .AccordionPanel-content__active .form-group {
  margin-top: -58px;
  float: right;
}
html[data-theme='light'] .position-details .trade,
html[data-theme='light'] .position-details-overview .trade {
  border-bottom: 0;
}
@media (max-width: 767px) {
  html[data-theme='light'] .position-details .trade .row + .row,
  html[data-theme='light'] .position-details-overview .trade .row + .row {
    margin-top: 15px;
  }
  html[data-theme='light'] .position-details .trade .row > div:not(:last-child),
  html[data-theme='light'] .position-details-overview .trade .row > div:not(:last-child) {
    margin-bottom: 15px;
  }
}
html[data-theme='light'] .position-details .trade .ant-calendar-picker,
html[data-theme='light'] .position-details-overview .trade .ant-calendar-picker {
  width: 243px;
}
html[data-theme='light'] .position-details .trade .info-box_accent .title-3,
html[data-theme='light'] .position-details-overview .trade .info-box_accent .title-3 {
  margin-bottom: 5px;
}
html[data-theme='light'] .position-details .trade .info-box_error,
html[data-theme='light'] .position-details-overview .trade .info-box_error {
  margin-bottom: 30px;
}
html[data-theme='light'] .position-details .trade .AccordionPanel-content__active,
html[data-theme='light'] .position-details-overview .trade .AccordionPanel-content__active {
  overflow: visible;
}
html[data-theme='light'] .position-details .trade .AccordionPanel-content-box,
html[data-theme='light'] .position-details-overview .trade .AccordionPanel-content-box {
  padding-bottom: 0;
}
html[data-theme='light'] .position-details .trade .quantity .ant-input-group-addon,
html[data-theme='light'] .position-details-overview .trade .quantity .ant-input-group-addon {
  padding: 0;
}
html[data-theme='light'] .position-details .trade .quantity .ant-input-group-addon:before,
html[data-theme='light'] .position-details-overview .trade .quantity .ant-input-group-addon:before {
  display: none;
}
html[data-theme='light'] .position-details .trade .quantity .ant-input-group-addon button,
html[data-theme='light'] .position-details-overview .trade .quantity .ant-input-group-addon button {
  padding: 0 10px;
  min-width: auto;
  text-decoration: underline;
  color: var(--primary-default);
  border: none;
}
html[data-theme='light'] .position-details .trade .quantity .ant-input-group-addon button:hover,
html[data-theme='light'] .position-details-overview .trade .quantity .ant-input-group-addon button:hover {
  color: var(--primary-darker);
}
html[data-theme='light'] .position-details .trade .bottom-row,
html[data-theme='light'] .position-details-overview .trade .bottom-row {
  margin: 0 -30px 30px;
  border-bottom: 1px solid #e4e8ee;
  padding: 0 15px 30px;
}
html[data-theme='light'] .position-details-overview .buttons-block {
  padding-bottom: 15px;
}
html[data-theme='light'] .RememberMe .checkbox-wrapper {
  margin-bottom: 20px;
}
html[data-theme='light'] .Login {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  html[data-theme='light'] .Login.Login {
    padding-right: 0;
    padding-left: 0;
  }
}
html[data-theme='light'] .Login .detail_content {
  max-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='light'] .Login .detail_content .content-pane-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
}
html[data-theme='light'] .Login .detail_content .content-pane {
  padding: 30px;
  border-radius: 5px;
}
html[data-theme='light'] .Login .detail_content .content-pane .buttons.button-group {
  text-align: center;
}
html[data-theme='light'] .Login .detail_content .content-pane .buttons.button-group .btn {
  float: none !important;
}
html[data-theme='light'] .Login .detail_content .content-pane .buttons.button-group button {
  width: 100%;
}
html[data-theme='light'] .Login .detail_content .content-pane .buttons.button-group .button-primary {
  margin-bottom: 20px;
}
html[data-theme='light'] .Login .detail_content .pf-form_horizontal {
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
  border-radius: 5px;
}
html[data-theme='light'] .Login .detail_content .pf-form_horizontal .content-pane .pf-form_row {
  max-width: 28em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
html[data-theme='light'] .Login .detail_content .pf-form_horizontal .validation-summary-errors .btn {
  display: block;
  margin: 1em auto 0;
  color: #333;
}
html[data-theme='light'] .Login .detail_content .qr-code {
  display: block;
  width: 14em;
  height: 14em;
  background-size: 14em;
  margin: 1em auto;
}
html[data-theme='light'] .Login .application-title {
  margin-bottom: 30px;
  text-align: center;
}
html[data-theme='light'] .UsernameIdentity .info-text,
html[data-theme='light'] .UsernameIdentity .form-group,
html[data-theme='light'] .UsernameIdentity .ant-input-affix-wrapper {
  margin-bottom: 20px;
}
html[data-theme='light'] .ForgotPassword .application-title {
  margin-bottom: 30px;
  text-align: center;
}
html[data-theme='light'] .ForgotPassword .detail_content {
  max-width: 354px;
  min-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='light'] .ForgotPassword .detail_content .content-pane {
  padding: 30px;
  border-radius: 5px;
}
html[data-theme='light'] .ForgotPassword .detail_content .content-pane-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
}
html[data-theme='light'] .ForgotPassword button {
  width: 100%;
}
html[data-theme='light'] .ForgotPassword .info-text:not(:last-child),
html[data-theme='light'] .ForgotPassword .Info-box {
  margin-bottom: 20px;
}
html[data-theme='light'] .ResetPassword {
  padding: 30px !important;
  margin: 8rem auto 0;
  max-width: 354px;
}
html[data-theme='light'] .ResetPassword button {
  width: 100%;
}
html[data-theme='light'] .ResetPassword .info-text,
html[data-theme='light'] .ResetPassword .form-group,
html[data-theme='light'] .ResetPassword .Info-box {
  margin-bottom: 20px;
}
html[data-theme='light'] header.header {
  min-height: 80px;
}
html[data-theme='light'] header.header img {
  margin: 0;
  max-width: 200px;
}
html[data-theme='light'] .profile-menu__name {
  color: #25282e;
}
html[data-theme='light'] .profile-menu__additional {
  line-height: 20px;
}
html[data-theme='light'] .content-box_title > .Info-box {
  margin-top: 30px;
}
html[data-theme='light'] .investment-allocation .Highcharts-bar-chart {
  margin: -16px 0;
}
html[data-theme='light'] .investment-allocation .table-wrapper {
  margin-top: -30px !important;
  margin-bottom: -15px !important;
}
html[data-theme='light'] .cell-actions {
  position: relative;
}
html[data-theme='light'] .cell-actions .table-cell_icon {
  color: #85899d;
}
html[data-theme='light'] .cell-actions .table-cell_icon:hover {
  cursor: pointer;
}
html[data-theme='light'] .cell-actions-panel {
  position: absolute;
  top: 100%;
  right: -15px;
  z-index: 20;
  display: none;
  border: 1px solid #babece;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
}
html[data-theme='light'] .cell-actions-panel_visible {
  display: block;
}
html[data-theme='light'] .cell-actions-panel:after,
html[data-theme='light'] .cell-actions-panel:before {
  position: absolute;
  bottom: 100%;
  right: 15px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 0 9px 11px;
  border-color: transparent;
  border-bottom-color: #babece;
}
html[data-theme='light'] .cell-actions-panel:before {
  margin-bottom: 1px;
}
html[data-theme='light'] .cell-actions-panel:after {
  border-bottom-color: #fff;
}
html[data-theme='light'] .cell-actions-item {
  position: relative;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 170px;
  transition: all 0.3s ease 0s;
}
html[data-theme='light'] .cell-actions-item:first-child {
  border-radius: 5px 5px 0 0;
}
html[data-theme='light'] .cell-actions-item:last-child {
  border-radius: 0 0 5px 5px;
}
html[data-theme='light'] .cell-actions-item:not(:first-child):before {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  display: block;
  height: 0;
  content: '';
  border-bottom: 1px solid #e4e8ee;
}
html[data-theme='light'] .cell-actions-item:hover {
  background: #4261ff;
  color: #fff;
  cursor: pointer;
}
html[data-theme='light'] .cell-actions-item:hover:before,
html[data-theme='light'] .cell-actions-item:hover + li:before {
  content: none;
}
html[data-theme='light'] .overview-panel {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
html[data-theme='light'] .overview-panel:not(:last-child) {
  padding-bottom: 30px;
}
html[data-theme='light'] .overview-panel .row {
  width: 100%;
}
html[data-theme='light'] .overview-item + .overview-item {
  margin-top: 30px;
}
@media (max-width: 767px) {
  html[data-theme='light'] .overview-item {
    margin-bottom: 15px;
    margin-top: 0 !important;
  }
  html[data-theme='light'] .overview-item + .overview-item:last-child {
    margin-bottom: 0;
  }
}
html[data-theme='light'] .overview-item {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  color: #25282e;
}
html[data-theme='light'] .overview-item .Form-control-label > span {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='light'] .overview-item__title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
}
html[data-theme='light'] .overview-item__value {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.125;
}
html[data-theme='light'] .overview-item__value_positive {
  color: #07cc9b;
}
html[data-theme='light'] .overview-item__value_negative {
  color: #ff376b;
}
html[data-theme='light'] .overview-item__value_small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
  color: #85899d;
}
html[data-theme='light'] .overview-item__percent {
  margin-left: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
  color: #25282e;
}
html[data-theme='light'] .overview-item__percent_positive {
  color: #07cc9b;
}
html[data-theme='light'] .overview-item__percent_negative {
  color: #ff376b;
}
html[data-theme='light'] .overview-item__link {
  text-decoration: underline;
  color: #85899d;
}
html[data-theme='light'] .MTan .info-text,
html[data-theme='light'] .MTan a {
  color: #85899d;
}
html[data-theme='light'] .MTan a {
  display: block;
  text-align: center;
  text-decoration: underline;
}
html[data-theme='light'] .MTan .form-control-message {
  margin-bottom: 0;
  display: inline-block;
}
html[data-theme='light'] .MTan .buttons.button-group .button:not(:last-child) {
  margin-bottom: 20px;
}
html[data-theme='light'] .MTan .buttons.button-group .link {
  height: 24px;
  padding: 0;
  color: #85899d !important;
}
html[data-theme='light'] .MTan .buttons.button-group .link span {
  text-decoration: underline !important;
}
html[data-theme='light'] .MTan .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-color: transparent !important;
}
html[data-theme='light'] .MTan .passcode-inputs {
  margin-bottom: 24px;
}
html[data-theme='light'] .MTan .passcode-inputs .form-group {
  display: inline-block;
}
html[data-theme='light'] .MTan .passcode-inputs .form-group + .form-group {
  margin-left: 3px;
}
html[data-theme='light'] .MTan .passcode-inputs .ant-input-affix-wrapper:hover .ant-input {
  border-width: 0 0 1px !important;
}
html[data-theme='light'] .MTan .passcode-inputs input {
  width: 28px;
  height: 32px;
  font-size: 18px;
  padding: 0 5px !important;
  border-width: 0 0 1px !important;
  border-radius: 0 !important;
  text-align: center;
}
html[data-theme='light'] .MTan .passcode-inputs input:focus,
html[data-theme='light'] .MTan .passcode-inputs input:hover {
  outline: 0 !important;
  border-width: 0 0 1px !important;
}
html[data-theme='light'] .chart-0 .Highcharts-bar-chart {
  --bar-chart-color-0: #ff9900;
  --bar-chart-color-1: #86a644;
  --bar-chart-color-2: #66aae2;
  --bar-chart-color-3: #0072ce;
  --bar-chart-color-4: #63666a;
  --bar-chart-color-5: #bbaf97;
  --bar-chart-color-6: #ffc266;
  --bar-chart-color-7: #b8cf8a;
}
html[data-theme='light'] .chart-1 .Highcharts-bar-chart {
  --bar-chart-color-0: var(--chart0-color1);
  --bar-chart-color-1: var(--chart0-color2);
  --bar-chart-color-2: #a3ccee;
  --bar-chart-color-3: #c2ddf3;
  --bar-chart-color-4: #e0eef9;
  --bar-chart-color-5: #4095da;
  --bar-chart-color-6: #7db9e7;
  --bar-chart-color-7: #bfdcf3;
  --bar-chart-color-8: #8a8c8f;
  --bar-chart-color-9: #b1b2b5;
  --bar-chart-color-10: #d8d9da;
  --bar-chart-color-11: var(--chart0-color6);
  --bar-chart-color-12: var(--chart0-color7);
  --bar-chart-color-13: #ffebcc;
  --bar-chart-color-14: #e7efd8;
  --bar-chart-color-15: #e4dfd5;
  --bar-chart-color-16: #d9d9d9;
}
html[data-theme='light'] .chart-2 .Highcharts-bar-chart {
  --bar-chart-color-1: var(--chart0-color7);
  --bar-chart-color-2: var(--chart0-color8);
  --bar-chart-color-3: var(--chart0-color3);
  --bar-chart-color-4: var(--chart1-color3);
  --bar-chart-color-5: var(--chart0-color4);
  --bar-chart-color-6: var(--chart1-color4);
  --bar-chart-color-7: var(--chart1-color5);
  --bar-chart-color-8: var(--chart0-color5);
  --bar-chart-color-9: var(--chart0-color1);
  --bar-chart-color-10: var(--chart1-color6);
  --bar-chart-color-11: var(--chart1-color7);
  --bar-chart-color-12: var(--chart0-color2);
  --bar-chart-color-13: var(--chart1-color8);
  --bar-chart-color-14: var(--chart1-color9);
  --bar-chart-color-15: var(--chart1-color10);
  --bar-chart-color-16: var(--chart1-color11);
  --bar-chart-color-17: var(--chart0-color6);
  --bar-chart-color-18: var(--chart0-color7);
  --bar-chart-color-19: var(--chart1-color14);
  --bar-chart-color-20: var(--chart1-color15);
  --bar-chart-color-21: var(--chart1-color16);
  --bar-chart-color-22: var(--chart1-color17);
  --bar-chart-color-23: #ffff00;
  --bar-chart-color-24: var(--chart0-color6);
  --bar-chart-color-25: #00b050;
  --bar-chart-color-26: #7030a0;
}
@font-face {
  font-family: 'Montserrat-UltraLight';
  src: url('../../clientRequirements/fonts/Montserrat-UltraLight.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Century-gothic';
  src: url('../../clientRequirements/fonts/Century-gothic.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Century-gothic-bold';
  src: url('../../clientRequirements/fonts/Century-gothic-bold.ttf');
  font-weight: normal;
  font-style: normal;
}
html[data-theme='light'] body {
  /* NS-747: NEED TO DO Refactor */
  color: #363636;
  /* NS-747 */
}
html[data-theme='light'] body .navigation__link {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='light'] body .header__navigation .navigation__link.navigation__link {
  white-space: nowrap;
}
html[data-theme='light'] body .Footer {
  background-color: #3681b0;
}
html[data-theme='light'] body .Footer__bottom {
  color: var(--lighter-blue);
}
html[data-theme='light'] body .Footer img {
  background-color: var(--white);
}
html[data-theme='light'] body .select__option:hover {
  background-color: var(--accent-default);
}
html[data-theme='light'] body .form-group_opened .select__button,
html[data-theme='light'] body .select:focus .select__button,
html[data-theme='light'] body .select .select__button:hover,
html[data-theme='light'] body .select .select__button:focus,
html[data-theme='light'] body .select .select__button:active {
  background-color: var(--darker-gray);
}
html[data-theme='light'] body .ant-tabs-extra__item .form-group_opened .select__button,
html[data-theme='light'] body .AccordionPanel-content-box .form-group_opened .select__button,
html[data-theme='light'] body .ant-tabs-extra__item .form-group_focused .select__button,
html[data-theme='light'] body .AccordionPanel-content-box .form-group_focused .select__button,
html[data-theme='light'] body .ant-tabs-extra__item .select .select__button:hover,
html[data-theme='light'] body .AccordionPanel-content-box .select .select__button:hover {
  background-color: var(--light-grey);
}
html[data-theme='light'] body .terms-section {
  background: var(--terms-bg-color);
}
html[data-theme='light'] body .terms-container {
  background-color: var(--body-bg);
}
html[data-theme='light'] body .terms__content {
  background-color: var(--body-bg);
}
html[data-theme='light'] body .terms__content h3 li {
  color: var(--accent-light);
}
html[data-theme='light'] body .terms__content body .terms__header h1,
html[data-theme='light'] body .terms__content div h2,
html[data-theme='light'] body .terms__content ol h3 {
  color: var(--darker-gray);
}
html[data-theme='light'] body .terms__footer {
  border-color: var(--dark-grey);
}
html[data-theme='light'] body .terms-container .button-secondary {
  color: var(--white);
}
html[data-theme='light'] body .table div {
  border-color: var(--darker-gray);
}
html[data-theme='light'] body .Table.table td:first-of-type {
  background-color: var(--background-content);
}
html[data-theme='light'] body .Table.table th {
  background-color: var(--background-content);
  box-shadow: 0 1px 0 0 var(--secondary-lighter);
}
@media (min-width: 769px) {
  html[data-theme='light'] body .Table.table td:first-child:before {
    background-color: var(--background-content);
  }
}
html[data-theme='light'] body a {
  color: #989591;
}
html[data-theme='light'] body a:hover {
  color: #3681b0;
}
html[data-theme='light'] body .bread-crumbs a {
  color: #dddcda;
}
html[data-theme='light'] body .button {
  text-transform: uppercase;
}
html[data-theme='light'] body .info-box_error span,
html[data-theme='light'] body .form-group_invalid .form-control-message {
  color: #ff9900;
}
html[data-theme='light'] body .overview-item__value {
  color: #000;
}
html[data-theme='light'] body .scroll-top {
  background: #bab8b5;
}
html[data-theme='light'] body .header__navigation .navigation__link:hover {
  background-color: #75726C;
  color: #FFF;
}
html[data-theme='light'] body .header_with_navigation {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}
html[data-theme='light'] body .TextInput_invalid input,
html[data-theme='light'] body .TextInput_invalid .TextInput-group {
  border-color: #ff9900;
}
html[data-theme='light'] body .application-title img {
  width: 300px!important;
}
html[data-theme='light'] body .select__button {
  color: #FFF;
}
html[data-theme='light'] body .Accordion,
html[data-theme='light'] body .Content-box,
html[data-theme='light'] body .news-item,
html[data-theme='light'] body .detail_content .content-pan {
  border-radius: 0;
  box-shadow: none;
}
html[data-theme='light'] body .Accordion {
  background: #ebf2f7;
}
html[data-theme='light'] body .Info-box {
  background: #f1f1f0;
}
html[data-theme='light'] body .info-box_accent {
  background: #f1f1f0;
  border: 1px solid #989591;
}
html[data-theme='light'] body .rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
html[data-theme='light'] body .rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
html[data-theme='light'] body .rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
  border-top-color: #f1f1f0;
  border-right-color: #f1f1f0;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
html[data-theme='light'] body .Table thead > tr th,
html[data-theme='light'] body .Table tbody > tr td,
html[data-theme='light'] body .Table thead > tr th.column-sort.sorted {
  color: #75726C;
}
html[data-theme='light'] body .Table thead > tr th.column-sort:hover,
html[data-theme='light'] body .Table thead > tr th.column-filter:hover {
  color: #989591;
}
html[data-theme='light'] body .Title {
  color: #3681b0;
}
html[data-theme='light'] body .overview-item {
  color: #989591;
}
html[data-theme='light'] body h1,
html[data-theme='light'] body h2,
html[data-theme='light'] body h3,
html[data-theme='light'] body h4,
html[data-theme='light'] body h5,
html[data-theme='light'] body h6,
html[data-theme='light'] body .highcharts-pie .legend-name,
html[data-theme='light'] body .AccordionPanel-header {
  color: #75726C;
}
html[data-theme='light'] body .AccordionPanel-header i,
html[data-theme='light'] body .Table tr td .expand-icon i {
  color: #cfb896;
}
html[data-theme='light'] body .AccordionPanel-content-box,
html[data-theme='light'] body .news-item-content p,
html[data-theme='light'] body .single-news-item--content {
  color: #000;
}
html[data-theme='light'] body .content-pane,
html[data-theme='light'] body .Content-box {
  background: #ebf2f7;
}
html[data-theme='light'] body .highcharts-background {
  fill: #ebf2f7;
}
html[data-theme='light'] body .Form-control-label,
html[data-theme='light'] body .bar-chart__label {
  color: #989591;
}
html[data-theme='light'] body .Login .detail_content .content-pane-wrapper {
  border-radius: 0;
}
html[data-theme='light'] body #root {
  background: #cde0eb;
}
html[data-theme='light'] body .select__button {
  color: #000;
}
html[data-theme='light'] body .Footer .select__button {
  color: #fff;
}
html[data-theme='light'] body .ant-tabs-nav .ant-tabs-tab {
  color: #989591;
}
html[data-theme='light'] body .ant-tabs-ink-bar {
  background: #75726C;
}
html[data-theme='light'] body .overview-item__value_positive,
html[data-theme='light'] body .Table tbody > tr .table-cell_positive,
html[data-theme='light'] body .Table tbody > tr .table-cell_highlighted,
html[data-theme='light'] body .Table tfoot > tr .table-cell_positive,
html[data-theme='light'] body .Table tfoot > tr .table-cell_highlighted {
  color: #86a644;
}
html[data-theme='light'] body .Table tbody > tr .table-cell_negative,
html[data-theme='light'] body .Table tfoot > tr .table-cell_negative .pdf-controls {
  color: #ff9900;
}
html[data-theme='light'] body .dropdown-inner .rc-tooltip-inner-content,
html[data-theme='light'] body .select-dropdown-options .select__option {
  background: #f1f1f0;
}
html[data-theme='light'] body .dropdown-inner .dropdown .menu-item:hover {
  background: #75726C;
}
html[data-theme='light'] body tbody td .table-cell {
  color: #000;
}
html[data-theme='light'] body .Footer__logo {
  background: #FFF !important;
  margin-bottom: 20px;
}
html[data-theme='light'] body .header.header img {
  width: 80px;
  height: 80px;
  background: #FFF;
}
html[data-theme='light'] body .Footer img,
html[data-theme='light'] body header.header img {
  background: none;
}
html[data-theme='light'] body .Table.table th,
html[data-theme='light'] body .Table.table td:first-of-type {
  background: #ebf2f7;
}
html[data-theme='light'] body Table.table td:first-child:before {
  content: none!important;
}
html[data-theme='light'] body .header_with-navigation {
  z-index: 50;
}
html[data-theme='light'] .btn-apps {
  color: var(--primary-default);
  border: 1px solid var(--primary-lighter);
}
html[data-theme='light'] .btn-apps:hover {
  color: var(--dark-grey);
  box-shadow: 0 1px 3px #a3a3a3;
}
html[data-theme='light'] .btn-apps:first-of-type img {
  filter: brightness(0.25);
}
html[data-theme='light'] .button-primary.button {
  color: #fff;
  background-color: #3681b0;
  border: 0;
}
html[data-theme='light'] .button-primary.button:hover {
  background-color: #75726C;
  /* NS-747: NEED TO DO Refactor */
}
html[data-theme='light'] .button-secondary.button:hover {
  color: #fff;
  background-color: #75726C;
  /* NS-747: NEED TO DO Refactor */
}
html[data-theme='light'] header.header img {
  background-color: var(--white);
}
html[data-theme='light'] .header__navigation .navigation__link {
  color: var(--light-grey);
  background-color: var(--accent-light);
}
html[data-theme='light'] .header__navigation .navigation__link.navigation__link-active {
  color: var(--light-grey);
  background-color: var(--dark-grey);
}
html[data-theme='light'] .chart-0 .highcharts-color-0 {
  fill: var(--chart0-color1);
}
html[data-theme='light'] .chart-0 .highcharts-color-1 {
  fill: var(--chart0-color2);
}
html[data-theme='light'] .chart-0 .highcharts-color-2 {
  fill: var(--chart0-color3);
}
html[data-theme='light'] .chart-0 .highcharts-color-3 {
  fill: var(--chart0-color4);
}
html[data-theme='light'] .chart-0 .highcharts-color-4 {
  fill: var(--chart0-color5);
}
html[data-theme='light'] .chart-0 .highcharts-color-5 {
  fill: var(--chart0-color6);
}
html[data-theme='light'] .chart-0 .highcharts-color-6 {
  fill: var(--chart0-color7);
}
html[data-theme='light'] .chart-0 .highcharts-color-7 {
  fill: var(--chart0-color8);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(1) .legend-point {
  background-color: var(--chart0-color1);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(2) .legend-point {
  background-color: var(--chart0-color2);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(3) .legend-point {
  background-color: var(--chart0-color3);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(4) .legend-point {
  background-color: var(--chart0-color4);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(5) .legend-point {
  background-color: var(--chart0-color5);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(6) .legend-point {
  background-color: var(--chart0-color6);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(7) .legend-point {
  background-color: var(--chart0-color7);
}
html[data-theme='light'] .chart-0 .legend-item:nth-child(8) .legend-point {
  background-color: var(--chart0-color8);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: var(--chart0-color1);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: var(--chart0-color2);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: var(--chart0-color3);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: var(--chart0-color4);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: var(--chart0-color5);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: var(--chart0-color6);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: var(--chart0-color7);
}
html[data-theme='light'] .chart-0.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0,
html[data-theme='light'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
  fill: var(--chart0-color8);
}
html[data-theme='light'] .chart-1 .highcharts-color-0 {
  fill: var(--chart1-color1);
}
html[data-theme='light'] .chart-1 .highcharts-color-1 {
  fill: var(--chart1-color2);
}
html[data-theme='light'] .chart-1 .highcharts-color-2 {
  fill: var(--chart1-color3);
}
html[data-theme='light'] .chart-1 .highcharts-color-3 {
  fill: var(--chart1-color4);
}
html[data-theme='light'] .chart-1 .highcharts-color-4 {
  fill: var(--chart1-color5);
}
html[data-theme='light'] .chart-1 .highcharts-color-5 {
  fill: var(--chart1-color6);
}
html[data-theme='light'] .chart-1 .highcharts-color-6 {
  fill: var(--chart1-color7);
}
html[data-theme='light'] .chart-1 .highcharts-color-7 {
  fill: var(--chart1-color8);
}
html[data-theme='light'] .chart-1 .highcharts-color-8 {
  fill: var(--chart1-color9);
}
html[data-theme='light'] .chart-1 .highcharts-color-9 {
  fill: var(--chart1-color10);
}
html[data-theme='light'] .chart-1 .highcharts-color-10 {
  fill: var(--chart1-color11);
}
html[data-theme='light'] .chart-1 .highcharts-color-11 {
  fill: var(--chart1-color12);
}
html[data-theme='light'] .chart-1 .highcharts-color-12 {
  fill: var(--chart1-color13);
}
html[data-theme='light'] .chart-1 .highcharts-color-13 {
  fill: var(--chart1-color14);
}
html[data-theme='light'] .chart-1 .highcharts-color-14 {
  fill: var(--chart1-color15);
}
html[data-theme='light'] .chart-1 .highcharts-color-15 {
  fill: var(--chart1-color16);
}
html[data-theme='light'] .chart-1 .highcharts-color-16 {
  fill: var(--chart1-color17);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(1) .legend-point {
  background-color: var(--chart1-color1);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(2) .legend-point {
  background-color: var(--chart1-color2);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(3) .legend-point {
  background-color: var(--chart1-color3);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(4) .legend-point {
  background-color: var(--chart1-color4);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(5) .legend-point {
  background-color: var(--chart1-color5);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(6) .legend-point {
  background-color: var(--chart1-color6);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(7) .legend-point {
  background-color: var(--chart1-color7);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(8) .legend-point {
  background-color: var(--chart1-color8);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(9) .legend-point {
  background-color: var(--chart1-color9);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(10) .legend-point {
  background-color: var(--chart1-color10);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(11) .legend-point {
  background-color: var(--chart1-color11);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(12) .legend-point {
  background-color: var(--chart1-color12);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(13) .legend-point {
  background-color: var(--chart1-color13);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(14) .legend-point {
  background-color: var(--chart1-color14);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(15) .legend-point {
  background-color: var(--chart1-color15);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(16) .legend-point {
  background-color: var(--chart1-color16);
}
html[data-theme='light'] .chart-1 .legend-item:nth-child(17) .legend-point {
  background-color: var(--chart1-color17);
}
html[data-theme='light'] .chart-2 .highcharts-color-0 {
  fill: var(--chart2-color1);
}
html[data-theme='light'] .chart-2 .highcharts-color-1 {
  fill: var(--chart2-color2);
}
html[data-theme='light'] .chart-2 .highcharts-color-2 {
  fill: var(--chart2-color3);
}
html[data-theme='light'] .chart-2 .highcharts-color-3 {
  fill: var(--chart2-color4);
}
html[data-theme='light'] .chart-2 .highcharts-color-4 {
  fill: var(--chart2-color5);
}
html[data-theme='light'] .chart-2 .highcharts-color-5 {
  fill: var(--chart2-color6);
}
html[data-theme='light'] .chart-2 .highcharts-color-6 {
  fill: var(--chart2-color7);
}
html[data-theme='light'] .chart-2 .highcharts-color-7 {
  fill: var(--chart2-color8);
}
html[data-theme='light'] .chart-2 .highcharts-color-8 {
  fill: var(--chart2-color9);
}
html[data-theme='light'] .chart-2 .highcharts-color-9 {
  fill: var(--chart2-color10);
}
html[data-theme='light'] .chart-2 .highcharts-color-10 {
  fill: var(--chart2-color11);
}
html[data-theme='light'] .chart-2 .highcharts-color-11 {
  fill: var(--chart2-color12);
}
html[data-theme='light'] .chart-2 .highcharts-color-12 {
  fill: var(--chart2-color13);
}
html[data-theme='light'] .chart-2 .highcharts-color-13 {
  fill: var(--chart2-color14);
}
html[data-theme='light'] .chart-2 .highcharts-color-14 {
  fill: var(--chart2-color15);
}
html[data-theme='light'] .chart-2 .highcharts-color-15 {
  fill: var(--chart2-color16);
}
html[data-theme='light'] .chart-2 .highcharts-color-16 {
  fill: var(--chart2-color17);
}
html[data-theme='light'] .chart-2 .highcharts-color-17 {
  fill: var(--chart2-color18);
}
html[data-theme='light'] .chart-2 .highcharts-color-18 {
  fill: var(--chart2-color19);
}
html[data-theme='light'] .chart-2 .highcharts-color-19 {
  fill: var(--chart2-color20);
}
html[data-theme='light'] .chart-2 .highcharts-color-20 {
  fill: var(--chart2-color21);
}
html[data-theme='light'] .chart-2 .highcharts-color-21 {
  fill: var(--chart2-color22);
}
html[data-theme='light'] .chart-2 .highcharts-color-22 {
  fill: var(--chart2-color23);
}
html[data-theme='light'] .chart-2 .highcharts-color-23 {
  fill: var(--chart2-color24);
}
html[data-theme='light'] .chart-2 .highcharts-color-24 {
  fill: var(--chart2-color25);
}
html[data-theme='light'] .chart-2 .highcharts-color-25 {
  fill: var(--chart2-color26);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(1) .legend-point {
  background-color: var(--chart2-color1);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(2) .legend-point {
  background-color: var(--chart2-color2);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(3) .legend-point {
  background-color: var(--chart2-color3);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(4) .legend-point {
  background-color: var(--chart2-color4);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(5) .legend-point {
  background-color: var(--chart2-color5);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(6) .legend-point {
  background-color: var(--chart2-color6);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(7) .legend-point {
  background-color: var(--chart2-color7);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(8) .legend-point {
  background-color: var(--chart2-color8);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(9) .legend-point {
  background-color: var(--chart2-color9);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(10) .legend-point {
  background-color: var(--chart2-color10);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(11) .legend-point {
  background-color: var(--chart2-color11);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(12) .legend-point {
  background-color: var(--chart2-color12);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(13) .legend-point {
  background-color: var(--chart2-color13);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(14) .legend-point {
  background-color: var(--chart2-color14);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(15) .legend-point {
  background-color: var(--chart2-color15);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(16) .legend-point {
  background-color: var(--chart2-color16);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(17) .legend-point {
  background-color: var(--chart2-color17);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(18) .legend-point {
  background-color: var(--chart2-color18);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(19) .legend-point {
  background-color: var(--chart2-color19);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(20) .legend-point {
  background-color: var(--chart2-color20);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(21) .legend-point {
  background-color: var(--chart2-color21);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(22) .legend-point {
  background-color: var(--chart2-color22);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(23) .legend-point {
  background-color: var(--chart2-color23);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(24) .legend-point {
  background-color: var(--chart2-color24);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(25) .legend-point {
  background-color: var(--chart2-color25);
}
html[data-theme='light'] .chart-2 .legend-item:nth-child(26) .legend-point {
  background-color: var(--chart2-color26);
}
html[data-theme='light'] .highcharts-line .highcharts-area {
  fill: var(--light-blue);
}
html[data-theme='light'] .highcharts-line .highcharts-color-0 {
  stroke: var(--accent-light);
}
html[data-theme='light'] button.call-now span {
  color: var(--secondary-default);
}
html[data-theme='light'] button.call-now:hover span {
  color: var(--secondary-dark);
}
html[data-theme='light'] .pdf-controls {
  background-color: var(--white-darker);
}
html[data-theme='light'] #root .generate-report-container {
  display: flex;
}
html[data-theme='light'] #root .generate-report-container .loader-wrapper {
  margin: 0;
  padding: 0;
  width: 45px;
  height: 30px;
}
html[data-theme='light'] #root > .overflow > .container {
  min-height: calc(100vh - 188px);
}
html[data-theme='light'] body h1 {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20pt;
}
html[data-theme='light'] body h2,
html[data-theme='light'] body h3,
html[data-theme='light'] body h4,
html[data-theme='light'] body h5,
html[data-theme='light'] body h6 {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14pt;
}
html[data-theme='light'] body .btn-apps-group {
  display: flex;
  flex-direction: column;
  align-content: center;
}
html[data-theme='light'] body .btn-apps {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin-top: 10px;
  font-weight: 700;
  padding: 0.45rem 14px 0.45em 0;
  font-size: var(--font-size-base);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
html[data-theme='light'] body .btn-apps img {
  margin-right: 5px;
}
html[data-theme='light'] body .back-to-login {
  margin-top: 20px;
}
html[data-theme='light'] body .alert-icon-wrapper {
  display: none;
}
html[data-theme='light'] body .navigation__link-active {
  font-weight: bold;
}
html[data-theme='light'] body .factsheet-button {
  margin-bottom: 20px;
}
html[data-theme='light'] body .terms-section {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3% 5%;
}
html[data-theme='light'] body .terms-section + .overflow {
  overflow: hidden;
  height: 100vh;
}
html[data-theme='light'] body .terms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  max-width: 700px;
  padding: 10px;
}
html[data-theme='light'] body .terms__header {
  margin-top: 20px;
}
html[data-theme='light'] body .terms__content {
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  max-width: 100%;
  padding: 10px 15px 10px 0;
}
html[data-theme='light'] body .terms__content h1 {
  margin-top: 40px;
}
html[data-theme='light'] body .terms__footer {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  width: 98%;
  max-width: 700px;
  border-top-width: 1px;
  border-top-style: solid;
}
@media (max-width: 600px) {
  html[data-theme='light'] body .terms__footer {
    flex-direction: column;
  }
}
html[data-theme='light'] body .terms__notification {
  margin-bottom: 0;
  margin-right: 40px;
}
@media (max-width: 600px) {
  html[data-theme='light'] body .terms__notification {
    margin-bottom: 15px;
  }
}
html[data-theme='light'] body .terms-container .button-secondary {
  margin-left: 20px;
}
html[data-theme='light'] body .terms__content ol {
  margin-top: 1.2em;
  padding-left: 25px;
}
html[data-theme='light'] body .terms__content ol > ol,
html[data-theme='light'] body .terms__content ol > ul {
  padding-left: 15px;
}
html[data-theme='light'] body .terms__content ol li {
  margin-top: 1em;
}
html[data-theme='light'] body .terms .table {
  display: table;
  margin: 20px 0 30px;
  border-collapse: collapse;
}
html[data-theme='light'] body .terms .table div {
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
}
html[data-theme='light'] body .terms .table-header {
  display: table-header-group;
}
html[data-theme='light'] body .terms .table-row {
  display: table-row;
}
html[data-theme='light'] body .terms .table-cell {
  display: table-cell;
}
html[data-theme='light'] body .Table-content {
  position: relative;
  max-height: 73vh;
}
html[data-theme='light'] body .Table.table th {
  position: sticky;
  top: 0;
  z-index: 20;
}
@media (min-width: 769px) {
  html[data-theme='light'] body .Table.table th:first-of-type {
    left: 0;
    z-index: 30;
  }
  html[data-theme='light'] body .Table.table td:first-of-type:before {
    position: absolute;
    top: 0;
    left: -34px;
    display: block;
    box-sizing: content-box;
    width: 34px;
    height: 100%;
    content: '';
  }
  html[data-theme='light'] body .Table.table td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 10;
  }
  html[data-theme='light'] body .Table.table .row-level-1 td:first-of-type {
    left: 34px;
    overflow: visible;
  }
}
html[data-theme='light'] body .profile-menu__additional b:first-of-type,
html[data-theme='light'] body .profile-menu__additional br:first-of-type {
  display: none;
}
html[data-theme='light'] body .dashboard .overview-wrapper {
  min-height: 72px;
}
html[data-theme='light'] body .dashboard .overview-panel {
  min-height: unset;
}
html[data-theme='light'] body .dashboard .overview-item {
  justify-content: space-between;
  width: 50%;
  min-width: 180px;
  margin-bottom: 10px;
}
html[data-theme='light'] body .overview-item__value_mid {
  font-size: 1.5em;
  line-height: 1.25;
}
html[data-theme='light'] body .faq__item dfn {
  font-weight: 600;
  font-style: normal;
}
html[data-theme='light'] body .Footer img {
  max-width: 42px;
}
html[data-theme='light'] .PageVariant.wrapper {
  padding-top: 0;
}
html[data-theme='light'] .Table tbody .table-cell {
  white-space: pre;
}
html[data-theme='light'] .portfolios .Table tr td:first-child:not(:last-child) {
  display: table-cell;
}
html[data-theme='light'] .Table tr td .expand-cell {
  margin-right: unset;
}
html[data-theme='light'] .Title.application-title img {
  width: 250px;
}
html[data-theme='light'] .Passcode .passcode-inputs {
  display: flex;
}
html[data-theme='light'] .Passcode .form-group {
  width: 14.3%;
}
html[data-theme='light'] .Passcode .form-group + .form-group {
  margin-left: 8px;
}
html[data-theme='light'] .Passcode input {
  width: 100% !important;
}
html[data-theme='light'] .eye-button {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 32px;
  height: 32px;
  transform: translate(-30%, 50%);
  background: transparent;
  border: none;
}
html[data-theme='light'] .eye-button img {
  width: 23px;
  height: 23px;
}
html[data-theme='light'] .Info-box a {
  text-decoration: underline;
}
html[data-theme='light'] header.header img {
  height: 80px;
  width: 80px;
}
html[data-theme='light'] .header__navigation {
  justify-content: flex-end;
  margin: 0 0 0 auto;
  max-width: 600px;
}
html[data-theme='light'] .header__navigation .navigation__link {
  padding: 0 10px;
  line-height: 5;
  text-transform: uppercase;
}
html[data-theme='light'] .header__left {
  min-width: 160px;
}
@media only screen and (max-width: 480px) {
  html[data-theme='light'] .header__left {
    min-width: 60px;
  }
}
@media only screen and (max-width: 480px) {
  html[data-theme='light'] .header__right {
    min-width: 100px;
  }
}
@media only screen and (min-width: 769px) {
  html[data-theme='light'] .header .header__logo div {
    justify-content: flex-start;
  }
}
html[data-theme='light'] .profile-menu__content .avatar {
  display: none;
}
html[data-theme='light'] .Accordion .overview-item + .overview-item {
  margin: 0 0 15px;
}
@media only screen and (max-width: 768px) {
  html[data-theme='light'] .Accordion .overview-item + .overview-item {
    width: 50%;
  }
}
html[data-theme='light'] .AccordionPanel-content-box .widget__empty {
  min-height: 350px;
}
html[data-theme='light'] .news-item-image-container {
  overflow: hidden;
  height: 200px;
}
@media screen and (max-width: 992px) {
  html[data-theme='light'] .news-item-image-container {
    height: 250px;
  }
}
html[data-theme='light'] .single-news-item .origin-link {
  margin-top: 10px;
}
html[data-theme='light'] .single-news-item figure {
  max-width: 100%;
}
html[data-theme='light'] .single-news-item img {
  max-width: 100%;
  width: 100%;
  height: auto!important;
  margin-bottom: 15px;
}
html[data-theme='light'] .single-news-item iframe {
  max-width: 560px;
  height: 315px;
  border: none;
}
@media (max-width: 768px) {
  html[data-theme='light'] .single-news-item iframe {
    width: 100% !important;
    height: 250px;
  }
}
html[data-theme='light'] .ant-calendar-month-panel {
  border-width: 1px;
  border-style: solid;
}
html[data-theme='light'] .ant-calendar-picker {
  max-width: 85px;
}
html[data-theme='light'] .month-picker-start {
  margin-right: 5px;
}
html[data-theme='light'] .tabs-with-filter .ant-tabs-extra__item {
  margin: 0 0 0 5px;
}
html[data-theme='light'] .tabs-with-filter .ant-tabs-extra-content .select {
  max-width: 110px;
}
html[data-theme='light'] .select fieldset {
  max-height: unset;
}
html[data-theme='light'] .select__dropdown_mini {
  min-width: 290px;
}
@media (max-width: 559px) {
  html[data-theme='light'] .tabs-with-filter .ant-tabs-top-bar {
    display: flex;
    flex-direction: column-reverse;
  }
  html[data-theme='light'] .tabs-with-filter .ant-tabs-extra-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    float: unset;
    height: auto;
  }
  html[data-theme='light'] .tabs-with-filter .ant-tabs-extra__item {
    margin: 5px 0;
  }
}
@media (min-width: 1024px) {
  html[data-theme='light'] .header_with-navigation {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: var(--navigation-box-shadow);
  }
}
html[data-theme='light'] .relationship-manager-section .row,
html[data-theme='light'] .relationship-manager-section .relationship-manager {
  height: 100%;
}
html[data-theme='light'] button.call-now span {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 14px;
}
html[data-theme='light'] button.call-now {
  padding: 0 3px 0 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
html[data-theme='light'] .scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 69px;
  height: 69px;
  padding: 9px 5px 5px;
  opacity: 0;
  background: var(--scroll-top-bg-color);
  color: var(--scroll-top-color);
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='light'] .scroll-top.visible {
  opacity: 1;
}
html[data-theme='light'] .Highcharts-bar-chart .highcharts-axis-labels .Form-control-label {
  margin-bottom: -3px;
}
html[data-theme='light'] .highcharts-pie .legend {
  text-transform: capitalize;
}
html[data-theme='light'] .login-content .Info-box {
  margin-bottom: 20px;
}
html[data-theme='light'] .react-pdf__Document {
  display: flex;
  flex-direction: column;
}
html[data-theme='light'] .react-pdf__Page {
  margin: 0 auto 10px;
}
html[data-theme='dark'] {
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-no-unknown */
  @import '../../../node_modules/ui-library/dist/styles/themes/dark/variables.css';
  /* stylelint-disable */
  /*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
  /*# sourceMappingURL=bootstrap-grid.min.css.map */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
  /* Titles */
  /* Axes */
  /* Credits */
  /* Tooltip */
  /* Legend hover affects points and series */
  /* Series options */
  /* Default colors */
  /* Legend */
  /* Bubble legend */
  /* Loading */
  /* Plot bands and polar pane backgrounds */
  /* Highcharts More and modules */
  /* Highstock */
  /* Highmaps */
  /* 3d charts */
  /* Exporting module */
  /* Drilldown module */
  /* No-data module */
  /* Drag-panes module */
  /* Bullet type series */
  /* Lineargauge type series */
  /* Annotations module */
  /* Gantt */
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-disable */
}
html[data-theme='dark'] html,
html[data-theme='dark'] body {
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] input::-ms-clear,
html[data-theme='dark'] input::-ms-reveal {
  display: none;
}
html[data-theme='dark'] *,
html[data-theme='dark'] *::before,
html[data-theme='dark'] *::after {
  box-sizing: border-box;
}
html[data-theme='dark'] html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
html[data-theme='dark'] article,
html[data-theme='dark'] aside,
html[data-theme='dark'] dialog,
html[data-theme='dark'] figcaption,
html[data-theme='dark'] figure,
html[data-theme='dark'] footer,
html[data-theme='dark'] header,
html[data-theme='dark'] hgroup,
html[data-theme='dark'] main,
html[data-theme='dark'] nav,
html[data-theme='dark'] section {
  display: block;
}
html[data-theme='dark'] body {
  margin: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #191934;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] [tabindex='-1']:focus {
  outline: none !important;
}
html[data-theme='dark'] hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
html[data-theme='dark'] h1,
html[data-theme='dark'] h2,
html[data-theme='dark'] h3,
html[data-theme='dark'] h4,
html[data-theme='dark'] h5,
html[data-theme='dark'] h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #e4e8ee;
  font-weight: 500;
}
html[data-theme='dark'] p {
  margin-top: 0;
  margin-bottom: 1em;
}
html[data-theme='dark'] abbr[title],
html[data-theme='dark'] abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
html[data-theme='dark'] address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
html[data-theme='dark'] input[type='text'],
html[data-theme='dark'] input[type='password'],
html[data-theme='dark'] input[type='number'],
html[data-theme='dark'] textarea {
  -webkit-appearance: none;
}
html[data-theme='dark'] ol,
html[data-theme='dark'] ul,
html[data-theme='dark'] dl {
  margin-top: 0;
  margin-bottom: 1em;
}
html[data-theme='dark'] ol ol,
html[data-theme='dark'] ul ul,
html[data-theme='dark'] ol ul,
html[data-theme='dark'] ul ol {
  margin-bottom: 0;
}
html[data-theme='dark'] dt {
  font-weight: 500;
}
html[data-theme='dark'] dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
html[data-theme='dark'] blockquote {
  margin: 0 0 1em;
}
html[data-theme='dark'] dfn {
  font-style: italic;
}
html[data-theme='dark'] b,
html[data-theme='dark'] strong {
  font-weight: bolder;
}
html[data-theme='dark'] small {
  font-size: 80%;
}
html[data-theme='dark'] sub,
html[data-theme='dark'] sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
html[data-theme='dark'] sub {
  bottom: -0.25em;
}
html[data-theme='dark'] sup {
  top: -0.5em;
}
html[data-theme='dark'] a {
  color: #e4e8ee;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
html[data-theme='dark'] a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] a:active {
  color: #b5bcc7;
}
html[data-theme='dark'] a:active,
html[data-theme='dark'] a:hover {
  text-decoration: none;
  outline: 0;
}
html[data-theme='dark'] a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
html[data-theme='dark'] pre,
html[data-theme='dark'] code,
html[data-theme='dark'] kbd,
html[data-theme='dark'] samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
html[data-theme='dark'] pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
html[data-theme='dark'] figure {
  margin: 0 0 1em;
}
html[data-theme='dark'] img {
  vertical-align: middle;
  border-style: none;
}
html[data-theme='dark'] svg:not(:root) {
  overflow: hidden;
}
html[data-theme='dark'] a,
html[data-theme='dark'] area,
html[data-theme='dark'] button,
html[data-theme='dark'] [role='button'],
html[data-theme='dark'] input:not([type='range']),
html[data-theme='dark'] label,
html[data-theme='dark'] select,
html[data-theme='dark'] summary,
html[data-theme='dark'] textarea {
  touch-action: manipulation;
}
html[data-theme='dark'] table {
  border-collapse: collapse;
}
html[data-theme='dark'] caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
html[data-theme='dark'] th {
  text-align: inherit;
}
html[data-theme='dark'] input,
html[data-theme='dark'] button,
html[data-theme='dark'] select,
html[data-theme='dark'] optgroup,
html[data-theme='dark'] textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
html[data-theme='dark'] button,
html[data-theme='dark'] input {
  overflow: visible;
}
html[data-theme='dark'] button,
html[data-theme='dark'] select {
  text-transform: none;
}
html[data-theme='dark'] button,
html[data-theme='dark'] html [type="button"],
html[data-theme='dark'] [type="reset"],
html[data-theme='dark'] [type="submit"] {
  -webkit-appearance: button;
}
html[data-theme='dark'] button::-moz-focus-inner,
html[data-theme='dark'] [type='button']::-moz-focus-inner,
html[data-theme='dark'] [type='reset']::-moz-focus-inner,
html[data-theme='dark'] [type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
html[data-theme='dark'] input[type='radio'],
html[data-theme='dark'] input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
html[data-theme='dark'] input[type='date'],
html[data-theme='dark'] input[type='time'],
html[data-theme='dark'] input[type='datetime-local'],
html[data-theme='dark'] input[type='month'] {
  -webkit-appearance: listbox;
}
html[data-theme='dark'] textarea {
  overflow: auto;
  resize: vertical;
}
html[data-theme='dark'] fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
html[data-theme='dark'] legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
html[data-theme='dark'] progress {
  vertical-align: baseline;
}
html[data-theme='dark'] [type='number']::-webkit-inner-spin-button,
html[data-theme='dark'] [type='number']::-webkit-outer-spin-button {
  height: auto;
}
html[data-theme='dark'] [type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
html[data-theme='dark'] [type='search']::-webkit-search-cancel-button,
html[data-theme='dark'] [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
html[data-theme='dark'] ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
html[data-theme='dark'] output {
  display: inline-block;
}
html[data-theme='dark'] summary {
  display: list-item;
}
html[data-theme='dark'] template {
  display: none;
}
html[data-theme='dark'] [hidden] {
  display: none !important;
}
html[data-theme='dark'] mark {
  padding: 0.2em;
  background-color: #feffe6;
}
html[data-theme='dark'] ::selection {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .clearfix {
  zoom: 1;
}
html[data-theme='dark'] .clearfix::before,
html[data-theme='dark'] .clearfix::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .clearfix::after {
  clear: both;
}
html[data-theme='dark'] .anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html[data-theme='dark'] .anticon > * {
  line-height: 1;
}
html[data-theme='dark'] .anticon svg {
  display: inline-block;
}
html[data-theme='dark'] .anticon::before {
  display: none;
}
html[data-theme='dark'] .anticon .anticon-icon {
  display: block;
}
html[data-theme='dark'] .anticon[tabindex] {
  cursor: pointer;
}
html[data-theme='dark'] .anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
html[data-theme='dark'] .anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
html[data-theme='dark'] .fade-enter,
html[data-theme='dark'] .fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .fade-enter.fade-enter-active,
html[data-theme='dark'] .fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
html[data-theme='dark'] .fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .fade-enter,
html[data-theme='dark'] .fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
html[data-theme='dark'] .fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html[data-theme='dark'] .move-up-enter,
html[data-theme='dark'] .move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-up-enter.move-up-enter-active,
html[data-theme='dark'] .move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
html[data-theme='dark'] .move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .move-up-enter,
html[data-theme='dark'] .move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='dark'] .move-down-enter,
html[data-theme='dark'] .move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-down-enter.move-down-enter-active,
html[data-theme='dark'] .move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
html[data-theme='dark'] .move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .move-down-enter,
html[data-theme='dark'] .move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='dark'] .move-left-enter,
html[data-theme='dark'] .move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-left-enter.move-left-enter-active,
html[data-theme='dark'] .move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
html[data-theme='dark'] .move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .move-left-enter,
html[data-theme='dark'] .move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
html[data-theme='dark'] .move-right-enter,
html[data-theme='dark'] .move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .move-right-enter.move-right-enter-active,
html[data-theme='dark'] .move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
html[data-theme='dark'] .move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .move-right-enter,
html[data-theme='dark'] .move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
html[data-theme='dark'] [ant-click-animating='true'],
html[data-theme='dark'] [ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html[data-theme='dark'] html {
  --antd-wave-shadow-color: #3851d1;
}
html[data-theme='dark'] [ant-click-animating-without-extra-node='true']::after,
html[data-theme='dark'] .ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #3851d1;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #3851d1;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
html[data-theme='dark'] .slide-up-enter,
html[data-theme='dark'] .slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-up-enter.slide-up-enter-active,
html[data-theme='dark'] .slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
html[data-theme='dark'] .slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .slide-up-enter,
html[data-theme='dark'] .slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='dark'] .slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='dark'] .slide-down-enter,
html[data-theme='dark'] .slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-down-enter.slide-down-enter-active,
html[data-theme='dark'] .slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
html[data-theme='dark'] .slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .slide-down-enter,
html[data-theme='dark'] .slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='dark'] .slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='dark'] .slide-left-enter,
html[data-theme='dark'] .slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-left-enter.slide-left-enter-active,
html[data-theme='dark'] .slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
html[data-theme='dark'] .slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .slide-left-enter,
html[data-theme='dark'] .slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='dark'] .slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
html[data-theme='dark'] .slide-right-enter,
html[data-theme='dark'] .slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .slide-right-enter.slide-right-enter-active,
html[data-theme='dark'] .slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
html[data-theme='dark'] .slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .slide-right-enter,
html[data-theme='dark'] .slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
html[data-theme='dark'] .slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
html[data-theme='dark'] .swing-enter,
html[data-theme='dark'] .swing-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .swing-enter.swing-enter-active,
html[data-theme='dark'] .swing-appear.swing-appear-active {
  animation-name: antSwingIn;
  animation-play-state: running;
}
@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
html[data-theme='dark'] .zoom-enter,
html[data-theme='dark'] .zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-enter.zoom-enter-active,
html[data-theme='dark'] .zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-enter,
html[data-theme='dark'] .zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-big-enter,
html[data-theme='dark'] .zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-big-enter.zoom-big-enter-active,
html[data-theme='dark'] .zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-big-enter,
html[data-theme='dark'] .zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-big-fast-enter,
html[data-theme='dark'] .zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-big-fast-enter.zoom-big-fast-enter-active,
html[data-theme='dark'] .zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-big-fast-enter,
html[data-theme='dark'] .zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-up-enter,
html[data-theme='dark'] .zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-up-enter.zoom-up-enter-active,
html[data-theme='dark'] .zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-up-enter,
html[data-theme='dark'] .zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-down-enter,
html[data-theme='dark'] .zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-down-enter.zoom-down-enter-active,
html[data-theme='dark'] .zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-down-enter,
html[data-theme='dark'] .zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-left-enter,
html[data-theme='dark'] .zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-left-enter.zoom-left-enter-active,
html[data-theme='dark'] .zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-left-enter,
html[data-theme='dark'] .zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .zoom-right-enter,
html[data-theme='dark'] .zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .zoom-right-enter.zoom-right-enter-active,
html[data-theme='dark'] .zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
html[data-theme='dark'] .zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .zoom-right-enter,
html[data-theme='dark'] .zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
html[data-theme='dark'] .zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-motion-collapse-legacy {
  overflow: hidden;
}
html[data-theme='dark'] .ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
html[data-theme='dark'] .ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
html[data-theme='dark'] .ant-affix {
  position: fixed;
  z-index: 10;
}
html[data-theme='dark'] .ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 8px 15px 8px 37px;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
html[data-theme='dark'] .ant-alert.ant-alert-closable {
  padding-right: 30px;
}
html[data-theme='dark'] .ant-alert-icon {
  position: absolute;
  top: 11.5px;
  left: 16px;
}
html[data-theme='dark'] .ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-alert-success {
  background-color: #f0f5ff;
  border: 1px solid #bacaf7;
}
html[data-theme='dark'] .ant-alert-success .ant-alert-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-alert-info {
  background-color: #f0f5ff;
  border: 1px solid #bacaf7;
}
html[data-theme='dark'] .ant-alert-info .ant-alert-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-alert-warning {
  background-color: #f0faff;
  border: 1px solid #f0f9ff;
}
html[data-theme='dark'] .ant-alert-warning .ant-alert-icon {
  color: #f0f3f6;
}
html[data-theme='dark'] .ant-alert-error {
  background-color: #fff0f1;
  border: 1px solid #ffb3bf;
}
html[data-theme='dark'] .ant-alert-error .ant-alert-icon {
  color: #ff376b;
}
html[data-theme='dark'] .ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
html[data-theme='dark'] .ant-alert-close-text {
  position: absolute;
  right: 16px;
}
html[data-theme='dark'] .ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: #e4e8ee;
  line-height: 1.5;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
html[data-theme='dark'] .ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
html[data-theme='dark'] .ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #e4e8ee;
  font-size: 16px;
}
html[data-theme='dark'] .ant-alert-with-description .ant-alert-description {
  display: block;
}
html[data-theme='dark'] .ant-alert.ant-alert-close {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .ant-alert-slide-up-leave {
  animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
html[data-theme='dark'] .ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
html[data-theme='dark'] .ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #191934;
}
html[data-theme='dark'] .ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
html[data-theme='dark'] .ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
html[data-theme='dark'] .ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #191934;
  border: 2px solid #3851d1;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
html[data-theme='dark'] .ant-anchor-ink-ball.visible {
  display: inline-block;
}
html[data-theme='dark'] .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
html[data-theme='dark'] .ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
html[data-theme='dark'] .ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: #e4e8ee;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-anchor-link-active > .ant-anchor-link-title {
  color: #3851d1;
}
html[data-theme='dark'] .ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
html[data-theme='dark'] .ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 30px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-input {
  height: 30px;
  line-height: 1.5;
  background: transparent;
  border-width: 1px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-input:focus,
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 30px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select-lg .ant-input {
  height: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 30px;
}
html[data-theme='dark'] .ant-select-auto-complete.ant-select-sm .ant-input {
  height: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
}
html[data-theme='dark'] .ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}
html[data-theme='dark'] .ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #191934;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-avatar-image {
  background: transparent;
}
html[data-theme='dark'] .ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='dark'] .ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
html[data-theme='dark'] .ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='dark'] .ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
html[data-theme='dark'] .ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
html[data-theme='dark'] .ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
html[data-theme='dark'] .ant-avatar-square {
  border-radius: 2px;
}
html[data-theme='dark'] .ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #191934;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-back-top-content:hover {
  background-color: #e4e8ee;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 1% no-repeat;
}
@media screen and (max-width: 768px) {
  html[data-theme='dark'] .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  html[data-theme='dark'] .ant-back-top {
    right: 20px;
  }
}
html[data-theme='dark'] .ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
html[data-theme='dark'] .ant-badge-count {
  z-index: 10;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #191934;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff376b;
  border-radius: 10px;
  box-shadow: 0 0 0 1px none;
}
html[data-theme='dark'] .ant-badge-count a,
html[data-theme='dark'] .ant-badge-count a:hover {
  color: #191934;
}
html[data-theme='dark'] .ant-badge-multiple-words {
  padding: 0 8px;
}
html[data-theme='dark'] .ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #ff376b;
  border-radius: 100%;
  box-shadow: 0 0 0 1px none;
}
html[data-theme='dark'] .ant-badge-count,
html[data-theme='dark'] .ant-badge-dot,
html[data-theme='dark'] .ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
html[data-theme='dark'] .ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
html[data-theme='dark'] .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-badge-status-success {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-badge-status-processing {
  position: relative;
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3851d1;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
html[data-theme='dark'] .ant-badge-status-default {
  background-color: #d9d9d9;
}
html[data-theme='dark'] .ant-badge-status-error {
  background-color: #ff376b;
}
html[data-theme='dark'] .ant-badge-status-warning {
  background-color: #f0f3f6;
}
html[data-theme='dark'] .ant-badge-status-pink {
  background: #eb2f96;
}
html[data-theme='dark'] .ant-badge-status-magenta {
  background: #eb2f96;
}
html[data-theme='dark'] .ant-badge-status-red {
  background: #f5222d;
}
html[data-theme='dark'] .ant-badge-status-volcano {
  background: #fa541c;
}
html[data-theme='dark'] .ant-badge-status-orange {
  background: #fa8c16;
}
html[data-theme='dark'] .ant-badge-status-yellow {
  background: #fadb14;
}
html[data-theme='dark'] .ant-badge-status-gold {
  background: #faad14;
}
html[data-theme='dark'] .ant-badge-status-cyan {
  background: #13c2c2;
}
html[data-theme='dark'] .ant-badge-status-lime {
  background: #a0d911;
}
html[data-theme='dark'] .ant-badge-status-green {
  background: #52c41a;
}
html[data-theme='dark'] .ant-badge-status-blue {
  background: #1890ff;
}
html[data-theme='dark'] .ant-badge-status-geekblue {
  background: #2f54eb;
}
html[data-theme='dark'] .ant-badge-status-purple {
  background: #722ed1;
}
html[data-theme='dark'] .ant-badge-status-text {
  margin-left: 8px;
  color: #e4e8ee;
  font-size: 14px;
}
html[data-theme='dark'] .ant-badge-zoom-appear,
html[data-theme='dark'] .ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
html[data-theme='dark'] .ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
html[data-theme='dark'] .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
html[data-theme='dark'] .ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
html[data-theme='dark'] .ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-scroll-number {
  overflow: hidden;
}
html[data-theme='dark'] .ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
}
html[data-theme='dark'] .ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-breadcrumb .anticon {
  font-size: 14px;
}
html[data-theme='dark'] .ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-breadcrumb a:hover {
  color: #5f79de;
}
html[data-theme='dark'] .ant-breadcrumb > span:last-child {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
html[data-theme='dark'] .ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
html[data-theme='dark'] .ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
html[data-theme='dark'] .ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 45px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #e4e8ee;
  background-color: #191934;
  border-color: #090912;
}
html[data-theme='dark'] .ant-btn > .anticon {
  line-height: 1;
}
html[data-theme='dark'] .ant-btn,
html[data-theme='dark'] .ant-btn:active,
html[data-theme='dark'] .ant-btn:focus {
  outline: 0;
}
html[data-theme='dark'] .ant-btn:not([disabled]):hover {
  text-decoration: none;
}
html[data-theme='dark'] .ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn.disabled,
html[data-theme='dark'] .ant-btn[disabled] {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-btn.disabled > *,
html[data-theme='dark'] .ant-btn[disabled] > * {
  pointer-events: none;
}
html[data-theme='dark'] .ant-btn-lg {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
html[data-theme='dark'] .ant-btn-sm {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 3px;
}
html[data-theme='dark'] .ant-btn > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn:hover,
html[data-theme='dark'] .ant-btn:focus {
  color: #5f79de;
  background-color: #191934;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn:hover > a:only-child,
html[data-theme='dark'] .ant-btn:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn:active,
html[data-theme='dark'] .ant-btn.active {
  color: #2637ab;
  background-color: #191934;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn:active > a:only-child,
html[data-theme='dark'] .ant-btn.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn:active > a:only-child::after,
html[data-theme='dark'] .ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-disabled,
html[data-theme='dark'] .ant-btn.disabled,
html[data-theme='dark'] .ant-btn[disabled],
html[data-theme='dark'] .ant-btn-disabled:hover,
html[data-theme='dark'] .ant-btn.disabled:hover,
html[data-theme='dark'] .ant-btn[disabled]:hover,
html[data-theme='dark'] .ant-btn-disabled:focus,
html[data-theme='dark'] .ant-btn.disabled:focus,
html[data-theme='dark'] .ant-btn[disabled]:focus,
html[data-theme='dark'] .ant-btn-disabled:active,
html[data-theme='dark'] .ant-btn.disabled:active,
html[data-theme='dark'] .ant-btn[disabled]:active,
html[data-theme='dark'] .ant-btn-disabled.active,
html[data-theme='dark'] .ant-btn.disabled.active,
html[data-theme='dark'] .ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-disabled > a:only-child,
html[data-theme='dark'] .ant-btn.disabled > a:only-child,
html[data-theme='dark'] .ant-btn[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn:hover,
html[data-theme='dark'] .ant-btn:focus,
html[data-theme='dark'] .ant-btn:active,
html[data-theme='dark'] .ant-btn.active {
  text-decoration: none;
  background: #191934;
}
html[data-theme='dark'] .ant-btn > i,
html[data-theme='dark'] .ant-btn > span {
  display: inline-block;
  pointer-events: none;
}
html[data-theme='dark'] .ant-btn-primary {
  color: #191934;
  background-color: #3851d1;
  border-color: #3851d1;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-primary > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-primary:hover,
html[data-theme='dark'] .ant-btn-primary:focus {
  color: #191934;
  background-color: #5f79de;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-primary:hover > a:only-child,
html[data-theme='dark'] .ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-primary:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-primary:active,
html[data-theme='dark'] .ant-btn-primary.active {
  color: #191934;
  background-color: #2637ab;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn-primary:active > a:only-child,
html[data-theme='dark'] .ant-btn-primary.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-primary:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-primary-disabled,
html[data-theme='dark'] .ant-btn-primary.disabled,
html[data-theme='dark'] .ant-btn-primary[disabled],
html[data-theme='dark'] .ant-btn-primary-disabled:hover,
html[data-theme='dark'] .ant-btn-primary.disabled:hover,
html[data-theme='dark'] .ant-btn-primary[disabled]:hover,
html[data-theme='dark'] .ant-btn-primary-disabled:focus,
html[data-theme='dark'] .ant-btn-primary.disabled:focus,
html[data-theme='dark'] .ant-btn-primary[disabled]:focus,
html[data-theme='dark'] .ant-btn-primary-disabled:active,
html[data-theme='dark'] .ant-btn-primary.disabled:active,
html[data-theme='dark'] .ant-btn-primary[disabled]:active,
html[data-theme='dark'] .ant-btn-primary-disabled.active,
html[data-theme='dark'] .ant-btn-primary.disabled.active,
html[data-theme='dark'] .ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-primary-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-primary.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-primary[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-primary-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-primary.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-primary[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-primary-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-primary.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-primary[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-primary-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-primary.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-primary[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-primary-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-primary.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-primary-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #5f79de;
  border-left-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #090912;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #090912;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
html[data-theme='dark'] .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
html[data-theme='dark'] .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #090912;
}
html[data-theme='dark'] .ant-btn-ghost {
  color: #e4e8ee;
  background-color: transparent;
  border-color: #090912;
}
html[data-theme='dark'] .ant-btn-ghost > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-ghost:hover,
html[data-theme='dark'] .ant-btn-ghost:focus {
  color: #5f79de;
  background-color: transparent;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-ghost:hover > a:only-child,
html[data-theme='dark'] .ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-ghost:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-ghost:active,
html[data-theme='dark'] .ant-btn-ghost.active {
  color: #2637ab;
  background-color: transparent;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn-ghost:active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-ghost:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-ghost-disabled,
html[data-theme='dark'] .ant-btn-ghost.disabled,
html[data-theme='dark'] .ant-btn-ghost[disabled],
html[data-theme='dark'] .ant-btn-ghost-disabled:hover,
html[data-theme='dark'] .ant-btn-ghost.disabled:hover,
html[data-theme='dark'] .ant-btn-ghost[disabled]:hover,
html[data-theme='dark'] .ant-btn-ghost-disabled:focus,
html[data-theme='dark'] .ant-btn-ghost.disabled:focus,
html[data-theme='dark'] .ant-btn-ghost[disabled]:focus,
html[data-theme='dark'] .ant-btn-ghost-disabled:active,
html[data-theme='dark'] .ant-btn-ghost.disabled:active,
html[data-theme='dark'] .ant-btn-ghost[disabled]:active,
html[data-theme='dark'] .ant-btn-ghost-disabled.active,
html[data-theme='dark'] .ant-btn-ghost.disabled.active,
html[data-theme='dark'] .ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-ghost-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-ghost[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-ghost-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-ghost[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-ghost-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-ghost[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-ghost-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-ghost-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-dashed {
  color: #e4e8ee;
  background-color: #191934;
  border-color: #090912;
  border-style: dashed;
}
html[data-theme='dark'] .ant-btn-dashed > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-dashed:hover,
html[data-theme='dark'] .ant-btn-dashed:focus {
  color: #5f79de;
  background-color: #191934;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-dashed:hover > a:only-child,
html[data-theme='dark'] .ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-dashed:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-dashed:active,
html[data-theme='dark'] .ant-btn-dashed.active {
  color: #2637ab;
  background-color: #191934;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn-dashed:active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-dashed:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-dashed-disabled,
html[data-theme='dark'] .ant-btn-dashed.disabled,
html[data-theme='dark'] .ant-btn-dashed[disabled],
html[data-theme='dark'] .ant-btn-dashed-disabled:hover,
html[data-theme='dark'] .ant-btn-dashed.disabled:hover,
html[data-theme='dark'] .ant-btn-dashed[disabled]:hover,
html[data-theme='dark'] .ant-btn-dashed-disabled:focus,
html[data-theme='dark'] .ant-btn-dashed.disabled:focus,
html[data-theme='dark'] .ant-btn-dashed[disabled]:focus,
html[data-theme='dark'] .ant-btn-dashed-disabled:active,
html[data-theme='dark'] .ant-btn-dashed.disabled:active,
html[data-theme='dark'] .ant-btn-dashed[disabled]:active,
html[data-theme='dark'] .ant-btn-dashed-disabled.active,
html[data-theme='dark'] .ant-btn-dashed.disabled.active,
html[data-theme='dark'] .ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-dashed-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-dashed[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-dashed-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-dashed[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-dashed-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-dashed[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-dashed-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-dashed-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-danger {
  color: #ff376b;
  background-color: #f5f5f5;
  border-color: #090912;
}
html[data-theme='dark'] .ant-btn-danger > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-danger:hover {
  color: #191934;
  background-color: #ff6186;
  border-color: #ff6186;
}
html[data-theme='dark'] .ant-btn-danger:hover > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-danger:hover > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-danger:focus {
  color: #ff6186;
  background-color: #191934;
  border-color: #ff6186;
}
html[data-theme='dark'] .ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-danger:active,
html[data-theme='dark'] .ant-btn-danger.active {
  color: #191934;
  background-color: #d9255b;
  border-color: #d9255b;
}
html[data-theme='dark'] .ant-btn-danger:active > a:only-child,
html[data-theme='dark'] .ant-btn-danger.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-danger:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-danger-disabled,
html[data-theme='dark'] .ant-btn-danger.disabled,
html[data-theme='dark'] .ant-btn-danger[disabled],
html[data-theme='dark'] .ant-btn-danger-disabled:hover,
html[data-theme='dark'] .ant-btn-danger.disabled:hover,
html[data-theme='dark'] .ant-btn-danger[disabled]:hover,
html[data-theme='dark'] .ant-btn-danger-disabled:focus,
html[data-theme='dark'] .ant-btn-danger.disabled:focus,
html[data-theme='dark'] .ant-btn-danger[disabled]:focus,
html[data-theme='dark'] .ant-btn-danger-disabled:active,
html[data-theme='dark'] .ant-btn-danger.disabled:active,
html[data-theme='dark'] .ant-btn-danger[disabled]:active,
html[data-theme='dark'] .ant-btn-danger-disabled.active,
html[data-theme='dark'] .ant-btn-danger.disabled.active,
html[data-theme='dark'] .ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-danger-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-danger.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-danger[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-danger-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-danger.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-danger[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-danger-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-danger.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-danger[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-danger-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-danger.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-danger[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-danger-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-danger.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-danger-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-link {
  color: #e4e8ee;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-link > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-link:hover,
html[data-theme='dark'] .ant-btn-link:focus {
  color: #5f79de;
  background-color: transparent;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-link:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-link:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-link:active,
html[data-theme='dark'] .ant-btn-link.active {
  color: #2637ab;
  background-color: transparent;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn-link:active > a:only-child,
html[data-theme='dark'] .ant-btn-link.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-link:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-link-disabled,
html[data-theme='dark'] .ant-btn-link.disabled,
html[data-theme='dark'] .ant-btn-link[disabled],
html[data-theme='dark'] .ant-btn-link-disabled:hover,
html[data-theme='dark'] .ant-btn-link.disabled:hover,
html[data-theme='dark'] .ant-btn-link[disabled]:hover,
html[data-theme='dark'] .ant-btn-link-disabled:focus,
html[data-theme='dark'] .ant-btn-link.disabled:focus,
html[data-theme='dark'] .ant-btn-link[disabled]:focus,
html[data-theme='dark'] .ant-btn-link-disabled:active,
html[data-theme='dark'] .ant-btn-link.disabled:active,
html[data-theme='dark'] .ant-btn-link[disabled]:active,
html[data-theme='dark'] .ant-btn-link-disabled.active,
html[data-theme='dark'] .ant-btn-link.disabled.active,
html[data-theme='dark'] .ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-link-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-link-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-link:hover,
html[data-theme='dark'] .ant-btn-link:focus,
html[data-theme='dark'] .ant-btn-link:active {
  border-color: transparent;
}
html[data-theme='dark'] .ant-btn-link-disabled,
html[data-theme='dark'] .ant-btn-link.disabled,
html[data-theme='dark'] .ant-btn-link[disabled],
html[data-theme='dark'] .ant-btn-link-disabled:hover,
html[data-theme='dark'] .ant-btn-link.disabled:hover,
html[data-theme='dark'] .ant-btn-link[disabled]:hover,
html[data-theme='dark'] .ant-btn-link-disabled:focus,
html[data-theme='dark'] .ant-btn-link.disabled:focus,
html[data-theme='dark'] .ant-btn-link[disabled]:focus,
html[data-theme='dark'] .ant-btn-link-disabled:active,
html[data-theme='dark'] .ant-btn-link.disabled:active,
html[data-theme='dark'] .ant-btn-link[disabled]:active,
html[data-theme='dark'] .ant-btn-link-disabled.active,
html[data-theme='dark'] .ant-btn-link.disabled.active,
html[data-theme='dark'] .ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-link-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-link-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-link.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-link-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-round {
  height: 45px;
  padding: 0 22.5px;
  font-size: 16px;
  border-radius: 45px;
}
html[data-theme='dark'] .ant-btn-round.ant-btn-lg {
  height: 45px;
  padding: 0 22.5px;
  font-size: 18px;
  border-radius: 45px;
}
html[data-theme='dark'] .ant-btn-round.ant-btn-sm {
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 30px;
}
html[data-theme='dark'] .ant-btn-circle,
html[data-theme='dark'] .ant-btn-circle-outline {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 16px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-btn-circle.ant-btn-lg,
html[data-theme='dark'] .ant-btn-circle-outline.ant-btn-lg {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 18px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-btn-circle.ant-btn-sm,
html[data-theme='dark'] .ant-btn-circle-outline.ant-btn-sm {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 14px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #191934;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
html[data-theme='dark'] .ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-btn .anticon.anticon-plus > svg,
html[data-theme='dark'] .ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
html[data-theme='dark'] .ant-btn.ant-btn-loading {
  position: relative;
  pointer-events: none;
}
html[data-theme='dark'] .ant-btn.ant-btn-loading::before {
  display: block;
}
html[data-theme='dark'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
html[data-theme='dark'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
html[data-theme='dark'] .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
html[data-theme='dark'] .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
html[data-theme='dark'] .ant-btn-group {
  position: relative;
  display: inline-block;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn {
  position: relative;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:hover,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn:hover,
html[data-theme='dark'] .ant-btn-group > .ant-btn:focus,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn:focus,
html[data-theme='dark'] .ant-btn-group > .ant-btn:active,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn:active,
html[data-theme='dark'] .ant-btn-group > .ant-btn.active,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:disabled,
html[data-theme='dark'] .ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
html[data-theme='dark'] .ant-btn-group-lg > .ant-btn,
html[data-theme='dark'] .ant-btn-group-lg > span > .ant-btn {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 43px;
}
html[data-theme='dark'] .ant-btn-group-sm > .ant-btn,
html[data-theme='dark'] .ant-btn-group-sm > span > .ant-btn {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 28px;
}
html[data-theme='dark'] .ant-btn-group-sm > .ant-btn > .anticon,
html[data-theme='dark'] .ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
html[data-theme='dark'] .ant-btn-group .ant-btn + .ant-btn,
html[data-theme='dark'] .ant-btn + .ant-btn-group,
html[data-theme='dark'] .ant-btn-group span + .ant-btn,
html[data-theme='dark'] .ant-btn-group .ant-btn + span,
html[data-theme='dark'] .ant-btn-group > span + span,
html[data-theme='dark'] .ant-btn-group + .ant-btn,
html[data-theme='dark'] .ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
html[data-theme='dark'] .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
html[data-theme='dark'] .ant-btn-group .ant-btn {
  border-radius: 0;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:first-child,
html[data-theme='dark'] .ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
html[data-theme='dark'] .ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:first-child:not(:last-child),
html[data-theme='dark'] .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn:last-child:not(:first-child),
html[data-theme='dark'] .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
html[data-theme='dark'] .ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 3px;
}
html[data-theme='dark'] .ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 3px;
}
html[data-theme='dark'] .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
html[data-theme='dark'] .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
html[data-theme='dark'] .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
html[data-theme='dark'] .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn-group {
  float: left;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-btn:focus > span,
html[data-theme='dark'] .ant-btn:active > span {
  position: relative;
}
html[data-theme='dark'] .ant-btn > .anticon + span,
html[data-theme='dark'] .ant-btn > span + .anticon {
  margin-left: 8px;
}
html[data-theme='dark'] .ant-btn-background-ghost {
  color: #191934;
  background: transparent !important;
  border-color: #191934;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary {
  color: #3851d1;
  background-color: transparent;
  border-color: #3851d1;
  text-shadow: none;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #5f79de;
  background-color: transparent;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.active {
  color: #2637ab;
  background-color: transparent;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled],
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger {
  color: #ff376b;
  background-color: transparent;
  border-color: #ff376b;
  text-shadow: none;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff6186;
  background-color: transparent;
  border-color: #ff6186;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9255b;
  background-color: transparent;
  border-color: #d9255b;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled],
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link {
  color: #e4e8ee;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #191934;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:focus {
  color: #ebf1fa;
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.active {
  color: #b5bcc7;
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled],
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled.active,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
html[data-theme='dark'] .ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
html[data-theme='dark'] .ant-btn-block {
  width: 100%;
}
html[data-theme='dark'] .ant-btn:empty {
  vertical-align: top;
}
html[data-theme='dark'] a.ant-btn {
  line-height: 43px;
}
html[data-theme='dark'] a.ant-btn-lg {
  line-height: 43px;
}
html[data-theme='dark'] a.ant-btn-sm {
  line-height: 28px;
}
html[data-theme='dark'] .ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid #090912;
  outline: none;
}
html[data-theme='dark'] .ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
html[data-theme='dark'] .ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
html[data-theme='dark'] .ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
html[data-theme='dark'] .ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
html[data-theme='dark'] .ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
html[data-theme='dark'] .ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
html[data-theme='dark'] .ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
html[data-theme='dark'] .ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
html[data-theme='dark'] .ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
html[data-theme='dark'] .ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
html[data-theme='dark'] .ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
html[data-theme='dark'] .ant-fullcalendar table,
html[data-theme='dark'] .ant-fullcalendar th,
html[data-theme='dark'] .ant-fullcalendar td {
  border: 0;
}
html[data-theme='dark'] .ant-fullcalendar td {
  position: relative;
}
html[data-theme='dark'] .ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
html[data-theme='dark'] .ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
html[data-theme='dark'] .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
html[data-theme='dark'] .ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
html[data-theme='dark'] .ant-fullcalendar-month,
html[data-theme='dark'] .ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: #e4e8ee;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-fullcalendar-value:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-fullcalendar-value:active {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
html[data-theme='dark'] .ant-fullcalendar-today .ant-fullcalendar-value,
html[data-theme='dark'] .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #3851d1 inset;
}
html[data-theme='dark'] .ant-fullcalendar-selected-day .ant-fullcalendar-value,
html[data-theme='dark'] .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
html[data-theme='dark'] .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
html[data-theme='dark'] .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
html[data-theme='dark'] .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='dark'] .ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen {
  border-top: 0;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 30px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: #e4e8ee;
  text-align: left;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #e8efff;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #3851d1;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #3851d1;
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
html[data-theme='dark'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
html[data-theme='dark'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
html[data-theme='dark'] .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #191934;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-card-hoverable {
  cursor: pointer;
}
html[data-theme='dark'] .ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: none;
}
html[data-theme='dark'] .ant-card-bordered {
  border: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
html[data-theme='dark'] .ant-card-head::before,
html[data-theme='dark'] .ant-card-head::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-head::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-head::before,
html[data-theme='dark'] .ant-card-head::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-head::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
html[data-theme='dark'] .ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: #e4e8ee;
  font-weight: normal;
  font-size: 14px;
}
html[data-theme='dark'] .ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: #e4e8ee;
  font-weight: normal;
  font-size: 14px;
}
html[data-theme='dark'] .ant-card-body {
  padding: 24px;
  zoom: 1;
}
html[data-theme='dark'] .ant-card-body::before,
html[data-theme='dark'] .ant-card-body::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-body::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-body::before,
html[data-theme='dark'] .ant-card-body::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-body::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
html[data-theme='dark'] .ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-card-grid:hover {
  position: relative;
  z-index: 1;
  box-shadow: none;
}
html[data-theme='dark'] .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-card-contain-tabs .ant-card-extra {
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-card-cover > * {
  display: block;
  width: 100%;
}
html[data-theme='dark'] .ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
html[data-theme='dark'] .ant-card-actions::before,
html[data-theme='dark'] .ant-card-actions::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-actions::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-actions::before,
html[data-theme='dark'] .ant-card-actions::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-actions::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
html[data-theme='dark'] .ant-card-actions > li > span {
  position: relative;
  display: inline-block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-card-actions > li > span:hover {
  color: #3851d1;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-card-actions > li > span a {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
html[data-theme='dark'] .ant-card-actions > li > span a:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
html[data-theme='dark'] .ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
html[data-theme='dark'] .ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
html[data-theme='dark'] .ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
html[data-theme='dark'] .ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
html[data-theme='dark'] .ant-card-meta::before,
html[data-theme='dark'] .ant-card-meta::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-meta::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-meta::before,
html[data-theme='dark'] .ant-card-meta::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-card-meta::after {
  clear: both;
}
html[data-theme='dark'] .ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
html[data-theme='dark'] .ant-card-meta-detail {
  overflow: hidden;
}
html[data-theme='dark'] .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
html[data-theme='dark'] .ant-card-meta-title {
  overflow: hidden;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-card-loading {
  overflow: hidden;
}
html[data-theme='dark'] .ant-card-loading .ant-card-body {
  user-select: none;
}
html[data-theme='dark'] .ant-card-loading-content p {
  margin: 0;
}
html[data-theme='dark'] .ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
html[data-theme='dark'] .ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
html[data-theme='dark'] .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
html[data-theme='dark'] .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
html[data-theme='dark'] .ant-card-small > .ant-card-body {
  padding: 12px;
}
html[data-theme='dark'] .ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
html[data-theme='dark'] .ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
html[data-theme='dark'] .ant-carousel .slick-list:focus {
  outline: none;
}
html[data-theme='dark'] .ant-carousel .slick-list.dragging {
  cursor: pointer;
}
html[data-theme='dark'] .ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
html[data-theme='dark'] .ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
html[data-theme='dark'] .ant-carousel .slick-slider .slick-track,
html[data-theme='dark'] .ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
html[data-theme='dark'] .ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
html[data-theme='dark'] .ant-carousel .slick-track::before,
html[data-theme='dark'] .ant-carousel .slick-track::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-carousel .slick-track::after {
  clear: both;
}
html[data-theme='dark'] .slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
html[data-theme='dark'] .ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
html[data-theme='dark'] [dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
html[data-theme='dark'] .ant-carousel .slick-slide img {
  display: block;
}
html[data-theme='dark'] .ant-carousel .slick-slide.slick-loading img {
  display: none;
}
html[data-theme='dark'] .ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
html[data-theme='dark'] .ant-carousel .slick-initialized .slick-slide {
  display: block;
}
html[data-theme='dark'] .ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
html[data-theme='dark'] .ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
html[data-theme='dark'] .ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
html[data-theme='dark'] .ant-carousel .slick-prev,
html[data-theme='dark'] .ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-carousel .slick-prev:hover,
html[data-theme='dark'] .ant-carousel .slick-next:hover,
html[data-theme='dark'] .ant-carousel .slick-prev:focus,
html[data-theme='dark'] .ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
html[data-theme='dark'] .ant-carousel .slick-prev:hover::before,
html[data-theme='dark'] .ant-carousel .slick-next:hover::before,
html[data-theme='dark'] .ant-carousel .slick-prev:focus::before,
html[data-theme='dark'] .ant-carousel .slick-next:focus::before {
  opacity: 1;
}
html[data-theme='dark'] .ant-carousel .slick-prev.slick-disabled::before,
html[data-theme='dark'] .ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
html[data-theme='dark'] .ant-carousel .slick-prev {
  left: -25px;
}
html[data-theme='dark'] .ant-carousel .slick-prev::before {
  content: '←';
}
html[data-theme='dark'] .ant-carousel .slick-next {
  right: -25px;
}
html[data-theme='dark'] .ant-carousel .slick-next::before {
  content: '→';
}
html[data-theme='dark'] .ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
html[data-theme='dark'] .ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
html[data-theme='dark'] .ant-carousel .slick-dots-top {
  top: 12px;
}
html[data-theme='dark'] .ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
html[data-theme='dark'] .ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #191934;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
html[data-theme='dark'] .ant-carousel .slick-dots li button:hover,
html[data-theme='dark'] .ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
html[data-theme='dark'] .ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #191934;
  opacity: 1;
}
html[data-theme='dark'] .ant-carousel .slick-dots li.slick-active button:hover,
html[data-theme='dark'] .ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  transform: translateY(-50%);
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
html[data-theme='dark'] .ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
html[data-theme='dark'] .ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  background-color: transparent !important;
  cursor: pointer;
}
html[data-theme='dark'] .ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
html[data-theme='dark'] .ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  background-color: #191934;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
html[data-theme='dark'] .ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #191934;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
html[data-theme='dark'] .ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
html[data-theme='dark'] .ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  transition: transform 0.2s;
}
html[data-theme='dark'] .ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  transform: rotate(180deg);
}
html[data-theme='dark'] .ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-cascader-picker-small .ant-cascader-picker-clear,
html[data-theme='dark'] .ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
html[data-theme='dark'] .ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #191934;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-cascader-menus ul,
html[data-theme='dark'] .ant-cascader-menus ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-cascader-menus-empty,
html[data-theme='dark'] .ant-cascader-menus-hidden {
  display: none;
}
html[data-theme='dark'] .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
html[data-theme='dark'] .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
html[data-theme='dark'] .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
html[data-theme='dark'] .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
html[data-theme='dark'] .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
html[data-theme='dark'] .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
html[data-theme='dark'] .ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html[data-theme='dark'] .ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px;
}
html[data-theme='dark'] .ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0;
}
html[data-theme='dark'] .ant-cascader-menu:only-child {
  border-radius: 2px;
}
html[data-theme='dark'] .ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-cascader-menu-item:hover {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
html[data-theme='dark'] .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='dark'] .ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
html[data-theme='dark'] .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
html[data-theme='dark'] .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
html[data-theme='dark'] :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff376b;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-checkbox-wrapper:hover .ant-checkbox-inner,
html[data-theme='dark'] .ant-checkbox:hover .ant-checkbox-inner,
html[data-theme='dark'] .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #10101f;
}
html[data-theme='dark'] .ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #10101f;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='dark'] .ant-checkbox:hover::after,
html[data-theme='dark'] .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
html[data-theme='dark'] .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #e4e8ee;
  border: 1px solid #090912;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='dark'] .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #10101f;
  border-color: #10101f;
}
html[data-theme='dark'] .ant-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='dark'] .ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #090912 !important;
}
html[data-theme='dark'] .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='dark'] .ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='dark'] .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='dark'] .ant-checkbox-wrapper + span,
html[data-theme='dark'] .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='dark'] .ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='dark'] .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #090912;
}
html[data-theme='dark'] .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #10101f;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='dark'] .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #090912;
  border-bottom: 0;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #090912;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item:last-child,
html[data-theme='dark'] .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 30px;
  padding-left: 40px;
  color: #e4e8ee;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
  line-height: 46px;
  transform: translateY(-50%);
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
html[data-theme='dark'] .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
html[data-theme='dark'] .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 30px;
  padding-right: 40px;
}
html[data-theme='dark'] .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: initial;
}
html[data-theme='dark'] .ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html[data-theme='dark'] .ant-collapse-content {
  overflow: hidden;
  color: #e4e8ee;
  background-color: #191934;
  border-top: 1px solid #090912;
}
html[data-theme='dark'] .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 30px 30px;
}
html[data-theme='dark'] .ant-collapse-content-inactive {
  display: none;
}
html[data-theme='dark'] .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-collapse-borderless {
  background-color: #191934;
  border: 0;
}
html[data-theme='dark'] .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #090912;
}
html[data-theme='dark'] .ant-collapse-borderless > .ant-collapse-item:last-child,
html[data-theme='dark'] .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
html[data-theme='dark'] .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
html[data-theme='dark'] .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
html[data-theme='dark'] .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
html[data-theme='dark'] .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-comment {
  position: relative;
}
html[data-theme='dark'] .ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
html[data-theme='dark'] .ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
html[data-theme='dark'] .ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
html[data-theme='dark'] .ant-comment-content-author {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
html[data-theme='dark'] .ant-comment-content-author > a,
html[data-theme='dark'] .ant-comment-content-author > span {
  height: 18px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
html[data-theme='dark'] .ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
html[data-theme='dark'] .ant-comment-content-detail p {
  white-space: pre-wrap;
}
html[data-theme='dark'] .ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
html[data-theme='dark'] .ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
html[data-theme='dark'] .ant-comment-actions > li > span:hover {
  color: #595959;
}
html[data-theme='dark'] .ant-comment-nested {
  margin-left: 44px;
}
html[data-theme='dark'] .ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
html[data-theme='dark'] .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='dark'] .ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
html[data-theme='dark'] .ant-calendar-picker-input {
  outline: none;
}
html[data-theme='dark'] .ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-calendar-picker-clear,
html[data-theme='dark'] .ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #191934;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='dark'] .ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
html[data-theme='dark'] .ant-calendar-picker-small .ant-calendar-picker-clear,
html[data-theme='dark'] .ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
html[data-theme='dark'] .ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #191934;
  background-clip: padding-box;
  border: 1px solid #191934;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-input {
  width: 100%;
  height: 22px;
  color: #e4e8ee;
  background: #191934;
  border: 0;
  outline: 0;
  cursor: auto;
}
html[data-theme='dark'] .ant-calendar-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-week-number {
  width: 286px;
}
html[data-theme='dark'] .ant-calendar-week-number-cell {
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-header a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-century-select,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-decade-select,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-year-select,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #e4e8ee;
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-century-select-arrow,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-decade-select-arrow,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-year-select-arrow,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-century-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-century-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-decade-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-month-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-month-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-century-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-century-btn::after,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-decade-btn::after,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-year-btn::after {
  content: '«';
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-century-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-decade-btn,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-century-btn::after,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-decade-btn::after,
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-year-btn::after {
  content: '»';
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-prev-month-btn::after {
  content: '‹';
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
}
html[data-theme='dark'] .ant-calendar-header .ant-calendar-next-month-btn::after {
  content: '›';
}
html[data-theme='dark'] .ant-calendar-body {
  padding: 8px 12px;
}
html[data-theme='dark'] .ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
html[data-theme='dark'] .ant-calendar table,
html[data-theme='dark'] .ant-calendar th,
html[data-theme='dark'] .ant-calendar td {
  text-align: center;
  border: 0;
}
html[data-theme='dark'] .ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
html[data-theme='dark'] .ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
html[data-theme='dark'] .ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
html[data-theme='dark'] .ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
html[data-theme='dark'] .ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: #e4e8ee;
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-calendar-date-panel {
  position: relative;
  outline: none;
}
html[data-theme='dark'] .ant-calendar-date:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-calendar-date:active {
  color: #191934;
  background: #5f79de;
}
html[data-theme='dark'] .ant-calendar-today .ant-calendar-date {
  color: #3851d1;
  font-weight: bold;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='dark'] .ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-calendar-selected-day .ant-calendar-date {
  background: #d7dcf6;
}
html[data-theme='dark'] .ant-calendar-selected-date .ant-calendar-date,
html[data-theme='dark'] .ant-calendar-selected-start-date .ant-calendar-date,
html[data-theme='dark'] .ant-calendar-selected-end-date .ant-calendar-date {
  color: #191934;
  background: #3851d1;
  border: 1px solid transparent;
}
html[data-theme='dark'] .ant-calendar-selected-date .ant-calendar-date:hover,
html[data-theme='dark'] .ant-calendar-selected-start-date .ant-calendar-date:hover,
html[data-theme='dark'] .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
html[data-theme='dark'] .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: '';
}
html[data-theme='dark'] .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
html[data-theme='dark'] .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
html[data-theme='dark'] .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
html[data-theme='dark'] .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
html[data-theme='dark'] .ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-footer:empty {
  border-top: 0;
}
html[data-theme='dark'] .ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-footer-extra {
  text-align: left;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-today-btn,
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-today-btn-disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-today-btn:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 45px;
  padding: 0 15px;
  border-radius: 4px;
  color: #191934;
  background-color: #3851d1;
  border-color: #3851d1;
  text-shadow: none;
  box-shadow: none;
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled > *,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-lg {
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-sm {
  height: 30px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 3px;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:focus {
  color: #191934;
  background-color: #5f79de;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.active {
  color: #191934;
  background-color: #2637ab;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled],
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled],
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  text-shadow: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
html[data-theme='dark'] .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
html[data-theme='dark'] .ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
html[data-theme='dark'] .ant-calendar-range-picker-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range-picker-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range-picker-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
html[data-theme='dark'] .ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
html[data-theme='dark'] .ant-calendar-range-part {
  position: relative;
  width: 50%;
}
html[data-theme='dark'] .ant-calendar-range-left {
  float: left;
}
html[data-theme='dark'] .ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-range-right {
  float: right;
}
html[data-theme='dark'] .ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  transform: translateX(-50%);
  pointer-events: none;
}
html[data-theme='dark'] .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  transform: translateX(-50%);
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
  height: 30px;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input::-moz-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input::-moz-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:-moz-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:hover,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:focus,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input-disabled,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input-disabled:hover,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input[disabled],
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input[disabled]:hover,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-calendar-range .ant-calendar-input,
html[data-theme='dark'] textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input-lg,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input-sm,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-input:focus,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-year-panel,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-month-panel,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-decade-panel-table,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-year-panel-table,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #f0f5ff;
  border: 0;
  border-radius: 0;
  content: '';
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
html[data-theme='dark'] div.ant-calendar-range-quick-selector {
  text-align: left;
}
html[data-theme='dark'] div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-header,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-month-panel-header,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-year-panel-header {
  border-bottom: 0;
}
html[data-theme='dark'] .ant-calendar-range .ant-calendar-body,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-month-panel-body,
html[data-theme='dark'] .ant-calendar-range .ant-calendar-year-panel-body {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #191934;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
html[data-theme='dark'] .ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
html[data-theme='dark'] .ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #191934;
}
html[data-theme='dark'] .ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
html[data-theme='dark'] .ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #191934;
  background-clip: padding-box;
  outline: none;
}
html[data-theme='dark'] .ant-calendar-time-picker-combobox {
  width: 100%;
}
html[data-theme='dark'] .ant-calendar-time-picker-column-1,
html[data-theme='dark'] .ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
html[data-theme='dark'] .ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
html[data-theme='dark'] .ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
html[data-theme='dark'] .ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
html[data-theme='dark'] .ant-calendar-time-picker-input-wrap {
  display: none;
}
html[data-theme='dark'] .ant-calendar-time-picker-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
html[data-theme='dark'] .ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
html[data-theme='dark'] .ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
html[data-theme='dark'] .ant-calendar-time-picker-select ul {
  box-sizing: border-box;
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-calendar-time-picker-select li {
  box-sizing: content-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding-left: 32px;
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  transition: background 0.3s ease;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
html[data-theme='dark'] .ant-calendar-time-picker-select li:hover {
  background: #f0f5ff;
}
html[data-theme='dark'] li.ant-calendar-time-picker-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
html[data-theme='dark'] li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: #e4e8ee;
  font-weight: 500;
  line-height: 34px;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #191934;
  border-radius: 2px;
  outline: none;
}
html[data-theme='dark'] .ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
html[data-theme='dark'] .ant-calendar-month-panel-hidden {
  display: none;
}
html[data-theme='dark'] .ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-month-panel-header a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #e4e8ee;
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='dark'] .ant-calendar-month-panel-body {
  flex: 1;
}
html[data-theme='dark'] .ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='dark'] .ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='dark'] .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-month-panel-cell {
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
html[data-theme='dark'] .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: #e4e8ee;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-calendar-month-panel-month:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #191934;
  border-radius: 2px;
  outline: none;
}
html[data-theme='dark'] .ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
html[data-theme='dark'] .ant-calendar-year-panel-hidden {
  display: none;
}
html[data-theme='dark'] .ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-year-panel-header a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #e4e8ee;
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
}
html[data-theme='dark'] .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='dark'] .ant-calendar-year-panel-body {
  flex: 1;
}
html[data-theme='dark'] .ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='dark'] .ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='dark'] .ant-calendar-year-panel-cell {
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: #e4e8ee;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-calendar-year-panel-year:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
html[data-theme='dark'] .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #191934;
  border-radius: 2px;
  outline: none;
}
html[data-theme='dark'] .ant-calendar-decade-panel-hidden {
  display: none;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #e4e8ee;
  font-weight: 500;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  content: '«';
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  content: '»';
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  content: '‹';
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  content: '›';
}
html[data-theme='dark'] .ant-calendar-decade-panel-body {
  flex: 1;
}
html[data-theme='dark'] .ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
html[data-theme='dark'] .ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
html[data-theme='dark'] .ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
html[data-theme='dark'] .ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: #e4e8ee;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-calendar-decade-panel-decade:hover {
  background: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}
html[data-theme='dark'] .ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
html[data-theme='dark'] .ant-calendar-month .ant-calendar-month-panel,
html[data-theme='dark'] .ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
html[data-theme='dark'] .ant-calendar-week-number-cell {
  opacity: 0.5;
}
html[data-theme='dark'] .ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #e8efff;
}
html[data-theme='dark'] .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
html[data-theme='dark'] .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: #e4e8ee;
  background: transparent;
}
html[data-theme='dark'] .ant-descriptions-title {
  margin-bottom: 20px;
  color: #e4e8ee;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
html[data-theme='dark'] .ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-descriptions-view table {
  width: 100%;
}
html[data-theme='dark'] .ant-descriptions-row > td {
  padding-bottom: 16px;
}
html[data-theme='dark'] .ant-descriptions-row:last-child {
  border-bottom: none;
}
html[data-theme='dark'] .ant-descriptions-item-label {
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}
html[data-theme='dark'] .ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ':';
}
html[data-theme='dark'] .ant-descriptions-item-content {
  display: table-cell;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
}
html[data-theme='dark'] .ant-descriptions-item {
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-descriptions-item > span {
  display: inline-block;
}
html[data-theme='dark'] .ant-descriptions-item .ant-descriptions-item-label {
  float: left;
  padding: 0 !important;
}
html[data-theme='dark'] .ant-descriptions-item .ant-descriptions-item-content {
  float: left;
  padding: 0 !important;
}
html[data-theme='dark'] .ant-descriptions .ant-descriptions-item-label,
html[data-theme='dark'] .ant-descriptions .ant-descriptions-item-content {
  padding: 16px 24px;
}
html[data-theme='dark'] .ant-descriptions.bordered.middle .ant-descriptions-item-label,
html[data-theme='dark'] .ant-descriptions.bordered.middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
html[data-theme='dark'] .ant-descriptions.bordered.small .ant-descriptions-item-label,
html[data-theme='dark'] .ant-descriptions.bordered.small .ant-descriptions-item-content {
  padding: 8px 16px;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-label,
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-content {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-label:last-child,
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
html[data-theme='dark'] .ant-descriptions.bordered .ant-descriptions-item-label::after {
  display: none;
}
html[data-theme='dark'] .ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #e8e8e8;
}
html[data-theme='dark'] .ant-divider,
html[data-theme='dark'] .ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text::after,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left::after,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
  content: '';
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
html[data-theme='dark'] .ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
html[data-theme='dark'] .ant-divider-dashed {
  background: none;
  border-top: 1px dashed #e8e8e8;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
html[data-theme='dark'] .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
html[data-theme='dark'] .ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
}
html[data-theme='dark'] .ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
html[data-theme='dark'] .ant-drawer-content-wrapper {
  position: fixed;
}
html[data-theme='dark'] .ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-drawer-left,
html[data-theme='dark'] .ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
html[data-theme='dark'] .ant-drawer-left .ant-drawer-content-wrapper,
html[data-theme='dark'] .ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
html[data-theme='dark'] .ant-drawer-left.ant-drawer-open,
html[data-theme='dark'] .ant-drawer-right.ant-drawer-open {
  width: 100%;
}
html[data-theme='dark'] .ant-drawer-left.ant-drawer-open.no-mask,
html[data-theme='dark'] .ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
html[data-theme='dark'] .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='dark'] .ant-drawer-right {
  right: 0;
}
html[data-theme='dark'] .ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
html[data-theme='dark'] .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='dark'] .ant-drawer-top,
html[data-theme='dark'] .ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
html[data-theme='dark'] .ant-drawer-top .ant-drawer-content-wrapper,
html[data-theme='dark'] .ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
html[data-theme='dark'] .ant-drawer-top.ant-drawer-open,
html[data-theme='dark'] .ant-drawer-bottom.ant-drawer-open {
  height: 100%;
}
html[data-theme='dark'] .ant-drawer-top.ant-drawer-open.no-mask,
html[data-theme='dark'] .ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
html[data-theme='dark'] .ant-drawer-top {
  top: 0;
}
html[data-theme='dark'] .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='dark'] .ant-drawer-bottom {
  bottom: 0;
}
html[data-theme='dark'] .ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
html[data-theme='dark'] .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}
html[data-theme='dark'] .ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 0.3;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
html[data-theme='dark'] .ant-drawer-title {
  margin: 0;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-drawer-content {
  position: relative;
  z-index: 1;
  background-color: #191934;
  background-clip: padding-box;
  border: 0;
}
html[data-theme='dark'] .ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
html[data-theme='dark'] .ant-drawer-close:focus,
html[data-theme='dark'] .ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
html[data-theme='dark'] .ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: #e4e8ee;
  background: #191934;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-drawer-header-no-title {
  color: #e4e8ee;
  background: #191934;
}
html[data-theme='dark'] .ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
html[data-theme='dark'] .ant-drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(228, 232, 238, 0.5);
  opacity: 0;
  filter: alpha(opacity=50);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
html[data-theme='dark'] .ant-drawer-open {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
html[data-theme='dark'] .ant-drawer-open-content {
  box-shadow: none;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
html[data-theme='dark'] .ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
html[data-theme='dark'] .ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
html[data-theme='dark'] .ant-dropdown-wrap {
  position: relative;
}
html[data-theme='dark'] .ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] :root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
html[data-theme='dark'] .ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
html[data-theme='dark'] .ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
html[data-theme='dark'] .ant-dropdown-hidden,
html[data-theme='dark'] .ant-dropdown-menu-hidden {
  display: none;
}
html[data-theme='dark'] .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #191934;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  -webkit-transform: translate3d(0, 0, 0);
}
html[data-theme='dark'] .ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  transform-origin: 0 0;
}
html[data-theme='dark'] .ant-dropdown-menu-item,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #e4e8ee;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-dropdown-menu-item > .anticon:first-child,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-dropdown-menu-item > a,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #e4e8ee;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-dropdown-menu-item-selected,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title-selected,
html[data-theme='dark'] .ant-dropdown-menu-item-selected > a,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title-selected > a {
  color: #3851d1;
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-dropdown-menu-item:hover,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title:hover {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-dropdown-menu-item-disabled,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-dropdown-menu-item-disabled:hover,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #191934;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-dropdown-menu-item-divider,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
html[data-theme='dark'] .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
html[data-theme='dark'] .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] :root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
html[data-theme='dark'] :root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu-vertical {
  position: relative;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
html[data-theme='dark'] .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
html[data-theme='dark'] .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #191934;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
html[data-theme='dark'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
html[data-theme='dark'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
html[data-theme='dark'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
html[data-theme='dark'] .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
html[data-theme='dark'] .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='dark'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
html[data-theme='dark'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
html[data-theme='dark'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
html[data-theme='dark'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
html[data-theme='dark'] .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
html[data-theme='dark'] .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='dark'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
html[data-theme='dark'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
html[data-theme='dark'] .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='dark'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
html[data-theme='dark'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
html[data-theme='dark'] .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='dark'] .ant-dropdown-trigger > .anticon.anticon-down,
html[data-theme='dark'] .ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] :root .ant-dropdown-trigger > .anticon.anticon-down,
html[data-theme='dark'] :root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
html[data-theme='dark'] .ant-dropdown-button {
  white-space: nowrap;
}
html[data-theme='dark'] .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] :root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
html[data-theme='dark'] .ant-dropdown-menu-dark,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(25, 25, 52, 0.65);
}
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(25, 25, 52, 0.65);
}
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #191934;
  background: transparent;
}
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
html[data-theme='dark'] .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #191934;
  background: #3851d1;
}
html[data-theme='dark'] .ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
html[data-theme='dark'] .ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
html[data-theme='dark'] .ant-empty-image img {
  height: 100%;
}
html[data-theme='dark'] .ant-empty-description {
  margin: 0;
}
html[data-theme='dark'] .ant-empty-footer {
  margin-top: 16px;
}
html[data-theme='dark'] .ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-empty-normal .ant-empty-image {
  height: 40px;
}
html[data-theme='dark'] .ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-empty-small .ant-empty-image {
  height: 35px;
}
html[data-theme='dark'] .ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #090912;
}
html[data-theme='dark'] .ant-form label {
  font-size: 14px;
}
html[data-theme='dark'] .ant-form input[type='search'] {
  box-sizing: border-box;
}
html[data-theme='dark'] .ant-form input[type='radio'],
html[data-theme='dark'] .ant-form input[type='checkbox'] {
  line-height: normal;
}
html[data-theme='dark'] .ant-form input[type='file'] {
  display: block;
}
html[data-theme='dark'] .ant-form input[type='range'] {
  display: block;
  width: 100%;
}
html[data-theme='dark'] .ant-form select[multiple],
html[data-theme='dark'] .ant-form select[size] {
  height: auto;
}
html[data-theme='dark'] .ant-form input[type='file']:focus,
html[data-theme='dark'] .ant-form input[type='radio']:focus,
html[data-theme='dark'] .ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
html[data-theme='dark'] .ant-form output {
  display: block;
  padding-top: 15px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
}
html[data-theme='dark'] .ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff376b;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
html[data-theme='dark'] .ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
html[data-theme='dark'] .ant-form-item-label > label {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
html[data-theme='dark'] .ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
html[data-theme='dark'] input[type='radio'][disabled],
html[data-theme='dark'] input[type='checkbox'][disabled],
html[data-theme='dark'] input[type='radio'].disabled,
html[data-theme='dark'] input[type='checkbox'].disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-radio-inline.disabled,
html[data-theme='dark'] .ant-radio-vertical.disabled,
html[data-theme='dark'] .ant-checkbox-inline.disabled,
html[data-theme='dark'] .ant-checkbox-vertical.disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-radio.disabled label,
html[data-theme='dark'] .ant-checkbox.disabled label {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-form-item label {
  position: relative;
}
html[data-theme='dark'] .ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-form-item-control {
  position: relative;
  line-height: 30px;
  zoom: 1;
}
html[data-theme='dark'] .ant-form-item-control::before,
html[data-theme='dark'] .ant-form-item-control::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-form-item-control::after {
  clear: both;
}
html[data-theme='dark'] .ant-form-item-control::before,
html[data-theme='dark'] .ant-form-item-control::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-form-item-control::after {
  clear: both;
}
html[data-theme='dark'] .ant-form-item-children {
  position: relative;
}
html[data-theme='dark'] .ant-form-item-with-help {
  margin-bottom: 1px;
}
html[data-theme='dark'] .ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 29.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-form-item-label-left {
  text-align: left;
}
html[data-theme='dark'] .ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
html[data-theme='dark'] .ant-form-explain,
html[data-theme='dark'] .ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html[data-theme='dark'] .ant-form-explain {
  margin-bottom: -1px;
}
html[data-theme='dark'] .ant-form-extra {
  padding-top: 4px;
}
html[data-theme='dark'] .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
html[data-theme='dark'] .ant-form-split {
  display: block;
  text-align: center;
}
html[data-theme='dark'] form .has-feedback .ant-input {
  padding-right: 24px;
}
html[data-theme='dark'] form .has-feedback .ant-input-password-icon {
  margin-right: 18px;
}
html[data-theme='dark'] form .has-feedback > .ant-select .ant-select-arrow,
html[data-theme='dark'] form .has-feedback > .ant-select .ant-select-selection__clear,
html[data-theme='dark'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
html[data-theme='dark'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
html[data-theme='dark'] form .has-feedback > .ant-select .ant-select-selection-selected-value,
html[data-theme='dark'] form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
html[data-theme='dark'] form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
html[data-theme='dark'] form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
html[data-theme='dark'] form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
html[data-theme='dark'] form .has-feedback .ant-calendar-picker-icon,
html[data-theme='dark'] form .has-feedback .ant-time-picker-icon,
html[data-theme='dark'] form .has-feedback .ant-calendar-picker-clear,
html[data-theme='dark'] form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
html[data-theme='dark'] form .ant-mentions,
html[data-theme='dark'] form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
html[data-theme='dark'] form .ant-upload {
  background: transparent;
}
html[data-theme='dark'] form input[type='radio'],
html[data-theme='dark'] form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
html[data-theme='dark'] form .ant-radio-inline,
html[data-theme='dark'] form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
html[data-theme='dark'] form .ant-radio-inline:first-child,
html[data-theme='dark'] form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
html[data-theme='dark'] form .ant-checkbox-vertical,
html[data-theme='dark'] form .ant-radio-vertical {
  display: block;
}
html[data-theme='dark'] form .ant-checkbox-vertical + .ant-checkbox-vertical,
html[data-theme='dark'] form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
html[data-theme='dark'] form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
html[data-theme='dark'] form .ant-input-number-handler-wrap {
  z-index: 2;
}
html[data-theme='dark'] form .ant-select,
html[data-theme='dark'] form .ant-cascader-picker {
  width: 100%;
}
html[data-theme='dark'] form .ant-input-group .ant-select,
html[data-theme='dark'] form .ant-input-group .ant-cascader-picker {
  width: auto;
}
html[data-theme='dark'] form :not(.ant-input-group-wrapper) > .ant-input-group,
html[data-theme='dark'] form .ant-input-group-wrapper {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-input-group-wrap .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-input-group-wrap .ant-select-selection:hover {
  border-color: #090912;
}
html[data-theme='dark'] .ant-input-group-wrap .ant-select-selection--single {
  height: 30px;
  margin-left: -1px;
  background-color: rgba(0, 0, 0, 0.07);
}
html[data-theme='dark'] .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 25px;
  padding-left: 8px;
  line-height: 30px;
}
html[data-theme='dark'] .ant-input-group-wrap .ant-select-open .ant-select-selection {
  border-color: #090912;
  box-shadow: none;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-item-label,
html[data-theme='dark'] .ant-col-24.ant-form-item-label,
html[data-theme='dark'] .ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-item-label label::after,
html[data-theme='dark'] .ant-col-24.ant-form-item-label label::after,
html[data-theme='dark'] .ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
html[data-theme='dark'] .ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  html[data-theme='dark'] .ant-form-item-label,
  html[data-theme='dark'] .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  html[data-theme='dark'] .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-form-item-label label::after {
    display: none;
  }
  html[data-theme='dark'] .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  html[data-theme='dark'] .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  html[data-theme='dark'] .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  html[data-theme='dark'] .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  html[data-theme='dark'] .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  html[data-theme='dark'] .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
html[data-theme='dark'] .ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
html[data-theme='dark'] .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
html[data-theme='dark'] .ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='dark'] .ant-form-inline .ant-form-text {
  display: inline-block;
}
html[data-theme='dark'] .ant-form-inline .has-feedback {
  display: inline-block;
}
html[data-theme='dark'] .has-success.has-feedback .ant-form-item-children-icon,
html[data-theme='dark'] .has-warning.has-feedback .ant-form-item-children-icon,
html[data-theme='dark'] .has-error.has-feedback .ant-form-item-children-icon,
html[data-theme='dark'] .is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 30px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
html[data-theme='dark'] .has-success.has-feedback .ant-form-item-children-icon svg,
html[data-theme='dark'] .has-warning.has-feedback .ant-form-item-children-icon svg,
html[data-theme='dark'] .has-error.has-feedback .ant-form-item-children-icon svg,
html[data-theme='dark'] .is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .has-success.has-feedback .ant-form-item-children-icon {
  color: #3851d1;
  animation-name: diffZoomIn1 !important;
}
html[data-theme='dark'] .has-warning .ant-form-explain,
html[data-theme='dark'] .has-warning .ant-form-split {
  color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input,
html[data-theme='dark'] .has-warning .ant-input:hover {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input:focus {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-warning .ant-input:not([disabled]):hover {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-warning .ant-input-affix-wrapper .ant-input,
html[data-theme='dark'] .has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #191934;
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input-prefix {
  color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input-group-addon {
  color: #f0f3f6;
  background-color: #191934;
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .has-feedback {
  color: #f0f3f6;
}
html[data-theme='dark'] .has-warning.has-feedback .ant-form-item-children-icon {
  color: #f0f3f6;
  animation-name: diffZoomIn3 !important;
}
html[data-theme='dark'] .has-warning .ant-select-selection {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-select-selection:hover {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-select-open .ant-select-selection,
html[data-theme='dark'] .has-warning .ant-select-focused .ant-select-selection {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-warning .ant-calendar-picker-icon::after,
html[data-theme='dark'] .has-warning .ant-time-picker-icon::after,
html[data-theme='dark'] .has-warning .ant-picker-icon::after,
html[data-theme='dark'] .has-warning .ant-select-arrow,
html[data-theme='dark'] .has-warning .ant-cascader-picker-arrow {
  color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input-number,
html[data-theme='dark'] .has-warning .ant-time-picker-input {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-input-number-focused,
html[data-theme='dark'] .has-warning .ant-time-picker-input-focused,
html[data-theme='dark'] .has-warning .ant-input-number:focus,
html[data-theme='dark'] .has-warning .ant-time-picker-input:focus {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-warning .ant-input-number:not([disabled]):hover,
html[data-theme='dark'] .has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #f0f8ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(240, 243, 246, 0.2);
}
html[data-theme='dark'] .has-error .ant-form-explain,
html[data-theme='dark'] .has-error .ant-form-split {
  color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input,
html[data-theme='dark'] .has-error .ant-input:hover {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-input:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-input-affix-wrapper .ant-input,
html[data-theme='dark'] .has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #191934;
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-prefix {
  color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-group-addon {
  color: #ff376b;
  background-color: #191934;
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .has-feedback {
  color: #ff376b;
}
html[data-theme='dark'] .has-error.has-feedback .ant-form-item-children-icon {
  color: #ff376b;
  animation-name: diffZoomIn2 !important;
}
html[data-theme='dark'] .has-error .ant-select-selection {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-select-selection:hover {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-select-open .ant-select-selection,
html[data-theme='dark'] .has-error .ant-select-focused .ant-select-selection {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
html[data-theme='dark'] .has-error .ant-calendar-picker-icon::after,
html[data-theme='dark'] .has-error .ant-time-picker-icon::after,
html[data-theme='dark'] .has-error .ant-picker-icon::after,
html[data-theme='dark'] .has-error .ant-select-arrow,
html[data-theme='dark'] .has-error .ant-cascader-picker-arrow {
  color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-number,
html[data-theme='dark'] .has-error .ant-time-picker-input {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-input-number-focused,
html[data-theme='dark'] .has-error .ant-time-picker-input-focused,
html[data-theme='dark'] .has-error .ant-input-number:focus,
html[data-theme='dark'] .has-error .ant-time-picker-input:focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-input-number:not([disabled]):hover,
html[data-theme='dark'] .has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-mention-wrapper .ant-mention-editor,
html[data-theme='dark'] .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
html[data-theme='dark'] .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff6186;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 55, 107, 0.2);
}
html[data-theme='dark'] .has-error .ant-transfer-list {
  border-color: #ff376b;
}
html[data-theme='dark'] .has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #090912;
}
html[data-theme='dark'] .has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #3851d1;
}
html[data-theme='dark'] .ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
html[data-theme='dark'] .ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 1px;
}
html[data-theme='dark'] .show-help-enter,
html[data-theme='dark'] .show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .show-help-enter.show-help-enter-active,
html[data-theme='dark'] .show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
html[data-theme='dark'] .show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
html[data-theme='dark'] .show-help-enter,
html[data-theme='dark'] .show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
html[data-theme='dark'] .ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
html[data-theme='dark'] .ant-row::before,
html[data-theme='dark'] .ant-row::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-row::after {
  clear: both;
}
html[data-theme='dark'] .ant-row::before,
html[data-theme='dark'] .ant-row::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-row::after {
  clear: both;
}
html[data-theme='dark'] .ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
html[data-theme='dark'] .ant-row-flex::before,
html[data-theme='dark'] .ant-row-flex::after {
  display: flex;
}
html[data-theme='dark'] .ant-row-flex-start {
  justify-content: flex-start;
}
html[data-theme='dark'] .ant-row-flex-center {
  justify-content: center;
}
html[data-theme='dark'] .ant-row-flex-end {
  justify-content: flex-end;
}
html[data-theme='dark'] .ant-row-flex-space-between {
  justify-content: space-between;
}
html[data-theme='dark'] .ant-row-flex-space-around {
  justify-content: space-around;
}
html[data-theme='dark'] .ant-row-flex-top {
  align-items: flex-start;
}
html[data-theme='dark'] .ant-row-flex-middle {
  align-items: center;
}
html[data-theme='dark'] .ant-row-flex-bottom {
  align-items: flex-end;
}
html[data-theme='dark'] .ant-col {
  position: relative;
}
html[data-theme='dark'] .ant-col-1,
html[data-theme='dark'] .ant-col-xs-1,
html[data-theme='dark'] .ant-col-sm-1,
html[data-theme='dark'] .ant-col-md-1,
html[data-theme='dark'] .ant-col-lg-1,
html[data-theme='dark'] .ant-col-2,
html[data-theme='dark'] .ant-col-xs-2,
html[data-theme='dark'] .ant-col-sm-2,
html[data-theme='dark'] .ant-col-md-2,
html[data-theme='dark'] .ant-col-lg-2,
html[data-theme='dark'] .ant-col-3,
html[data-theme='dark'] .ant-col-xs-3,
html[data-theme='dark'] .ant-col-sm-3,
html[data-theme='dark'] .ant-col-md-3,
html[data-theme='dark'] .ant-col-lg-3,
html[data-theme='dark'] .ant-col-4,
html[data-theme='dark'] .ant-col-xs-4,
html[data-theme='dark'] .ant-col-sm-4,
html[data-theme='dark'] .ant-col-md-4,
html[data-theme='dark'] .ant-col-lg-4,
html[data-theme='dark'] .ant-col-5,
html[data-theme='dark'] .ant-col-xs-5,
html[data-theme='dark'] .ant-col-sm-5,
html[data-theme='dark'] .ant-col-md-5,
html[data-theme='dark'] .ant-col-lg-5,
html[data-theme='dark'] .ant-col-6,
html[data-theme='dark'] .ant-col-xs-6,
html[data-theme='dark'] .ant-col-sm-6,
html[data-theme='dark'] .ant-col-md-6,
html[data-theme='dark'] .ant-col-lg-6,
html[data-theme='dark'] .ant-col-7,
html[data-theme='dark'] .ant-col-xs-7,
html[data-theme='dark'] .ant-col-sm-7,
html[data-theme='dark'] .ant-col-md-7,
html[data-theme='dark'] .ant-col-lg-7,
html[data-theme='dark'] .ant-col-8,
html[data-theme='dark'] .ant-col-xs-8,
html[data-theme='dark'] .ant-col-sm-8,
html[data-theme='dark'] .ant-col-md-8,
html[data-theme='dark'] .ant-col-lg-8,
html[data-theme='dark'] .ant-col-9,
html[data-theme='dark'] .ant-col-xs-9,
html[data-theme='dark'] .ant-col-sm-9,
html[data-theme='dark'] .ant-col-md-9,
html[data-theme='dark'] .ant-col-lg-9,
html[data-theme='dark'] .ant-col-10,
html[data-theme='dark'] .ant-col-xs-10,
html[data-theme='dark'] .ant-col-sm-10,
html[data-theme='dark'] .ant-col-md-10,
html[data-theme='dark'] .ant-col-lg-10,
html[data-theme='dark'] .ant-col-11,
html[data-theme='dark'] .ant-col-xs-11,
html[data-theme='dark'] .ant-col-sm-11,
html[data-theme='dark'] .ant-col-md-11,
html[data-theme='dark'] .ant-col-lg-11,
html[data-theme='dark'] .ant-col-12,
html[data-theme='dark'] .ant-col-xs-12,
html[data-theme='dark'] .ant-col-sm-12,
html[data-theme='dark'] .ant-col-md-12,
html[data-theme='dark'] .ant-col-lg-12,
html[data-theme='dark'] .ant-col-13,
html[data-theme='dark'] .ant-col-xs-13,
html[data-theme='dark'] .ant-col-sm-13,
html[data-theme='dark'] .ant-col-md-13,
html[data-theme='dark'] .ant-col-lg-13,
html[data-theme='dark'] .ant-col-14,
html[data-theme='dark'] .ant-col-xs-14,
html[data-theme='dark'] .ant-col-sm-14,
html[data-theme='dark'] .ant-col-md-14,
html[data-theme='dark'] .ant-col-lg-14,
html[data-theme='dark'] .ant-col-15,
html[data-theme='dark'] .ant-col-xs-15,
html[data-theme='dark'] .ant-col-sm-15,
html[data-theme='dark'] .ant-col-md-15,
html[data-theme='dark'] .ant-col-lg-15,
html[data-theme='dark'] .ant-col-16,
html[data-theme='dark'] .ant-col-xs-16,
html[data-theme='dark'] .ant-col-sm-16,
html[data-theme='dark'] .ant-col-md-16,
html[data-theme='dark'] .ant-col-lg-16,
html[data-theme='dark'] .ant-col-17,
html[data-theme='dark'] .ant-col-xs-17,
html[data-theme='dark'] .ant-col-sm-17,
html[data-theme='dark'] .ant-col-md-17,
html[data-theme='dark'] .ant-col-lg-17,
html[data-theme='dark'] .ant-col-18,
html[data-theme='dark'] .ant-col-xs-18,
html[data-theme='dark'] .ant-col-sm-18,
html[data-theme='dark'] .ant-col-md-18,
html[data-theme='dark'] .ant-col-lg-18,
html[data-theme='dark'] .ant-col-19,
html[data-theme='dark'] .ant-col-xs-19,
html[data-theme='dark'] .ant-col-sm-19,
html[data-theme='dark'] .ant-col-md-19,
html[data-theme='dark'] .ant-col-lg-19,
html[data-theme='dark'] .ant-col-20,
html[data-theme='dark'] .ant-col-xs-20,
html[data-theme='dark'] .ant-col-sm-20,
html[data-theme='dark'] .ant-col-md-20,
html[data-theme='dark'] .ant-col-lg-20,
html[data-theme='dark'] .ant-col-21,
html[data-theme='dark'] .ant-col-xs-21,
html[data-theme='dark'] .ant-col-sm-21,
html[data-theme='dark'] .ant-col-md-21,
html[data-theme='dark'] .ant-col-lg-21,
html[data-theme='dark'] .ant-col-22,
html[data-theme='dark'] .ant-col-xs-22,
html[data-theme='dark'] .ant-col-sm-22,
html[data-theme='dark'] .ant-col-md-22,
html[data-theme='dark'] .ant-col-lg-22,
html[data-theme='dark'] .ant-col-23,
html[data-theme='dark'] .ant-col-xs-23,
html[data-theme='dark'] .ant-col-sm-23,
html[data-theme='dark'] .ant-col-md-23,
html[data-theme='dark'] .ant-col-lg-23,
html[data-theme='dark'] .ant-col-24,
html[data-theme='dark'] .ant-col-xs-24,
html[data-theme='dark'] .ant-col-sm-24,
html[data-theme='dark'] .ant-col-md-24,
html[data-theme='dark'] .ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='dark'] .ant-col-1,
html[data-theme='dark'] .ant-col-2,
html[data-theme='dark'] .ant-col-3,
html[data-theme='dark'] .ant-col-4,
html[data-theme='dark'] .ant-col-5,
html[data-theme='dark'] .ant-col-6,
html[data-theme='dark'] .ant-col-7,
html[data-theme='dark'] .ant-col-8,
html[data-theme='dark'] .ant-col-9,
html[data-theme='dark'] .ant-col-10,
html[data-theme='dark'] .ant-col-11,
html[data-theme='dark'] .ant-col-12,
html[data-theme='dark'] .ant-col-13,
html[data-theme='dark'] .ant-col-14,
html[data-theme='dark'] .ant-col-15,
html[data-theme='dark'] .ant-col-16,
html[data-theme='dark'] .ant-col-17,
html[data-theme='dark'] .ant-col-18,
html[data-theme='dark'] .ant-col-19,
html[data-theme='dark'] .ant-col-20,
html[data-theme='dark'] .ant-col-21,
html[data-theme='dark'] .ant-col-22,
html[data-theme='dark'] .ant-col-23,
html[data-theme='dark'] .ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
html[data-theme='dark'] .ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
html[data-theme='dark'] .ant-col-push-24 {
  left: 100%;
}
html[data-theme='dark'] .ant-col-pull-24 {
  right: 100%;
}
html[data-theme='dark'] .ant-col-offset-24 {
  margin-left: 100%;
}
html[data-theme='dark'] .ant-col-order-24 {
  order: 24;
}
html[data-theme='dark'] .ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
html[data-theme='dark'] .ant-col-push-23 {
  left: 95.83333333%;
}
html[data-theme='dark'] .ant-col-pull-23 {
  right: 95.83333333%;
}
html[data-theme='dark'] .ant-col-offset-23 {
  margin-left: 95.83333333%;
}
html[data-theme='dark'] .ant-col-order-23 {
  order: 23;
}
html[data-theme='dark'] .ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
html[data-theme='dark'] .ant-col-push-22 {
  left: 91.66666667%;
}
html[data-theme='dark'] .ant-col-pull-22 {
  right: 91.66666667%;
}
html[data-theme='dark'] .ant-col-offset-22 {
  margin-left: 91.66666667%;
}
html[data-theme='dark'] .ant-col-order-22 {
  order: 22;
}
html[data-theme='dark'] .ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
html[data-theme='dark'] .ant-col-push-21 {
  left: 87.5%;
}
html[data-theme='dark'] .ant-col-pull-21 {
  right: 87.5%;
}
html[data-theme='dark'] .ant-col-offset-21 {
  margin-left: 87.5%;
}
html[data-theme='dark'] .ant-col-order-21 {
  order: 21;
}
html[data-theme='dark'] .ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
html[data-theme='dark'] .ant-col-push-20 {
  left: 83.33333333%;
}
html[data-theme='dark'] .ant-col-pull-20 {
  right: 83.33333333%;
}
html[data-theme='dark'] .ant-col-offset-20 {
  margin-left: 83.33333333%;
}
html[data-theme='dark'] .ant-col-order-20 {
  order: 20;
}
html[data-theme='dark'] .ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
html[data-theme='dark'] .ant-col-push-19 {
  left: 79.16666667%;
}
html[data-theme='dark'] .ant-col-pull-19 {
  right: 79.16666667%;
}
html[data-theme='dark'] .ant-col-offset-19 {
  margin-left: 79.16666667%;
}
html[data-theme='dark'] .ant-col-order-19 {
  order: 19;
}
html[data-theme='dark'] .ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
html[data-theme='dark'] .ant-col-push-18 {
  left: 75%;
}
html[data-theme='dark'] .ant-col-pull-18 {
  right: 75%;
}
html[data-theme='dark'] .ant-col-offset-18 {
  margin-left: 75%;
}
html[data-theme='dark'] .ant-col-order-18 {
  order: 18;
}
html[data-theme='dark'] .ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
html[data-theme='dark'] .ant-col-push-17 {
  left: 70.83333333%;
}
html[data-theme='dark'] .ant-col-pull-17 {
  right: 70.83333333%;
}
html[data-theme='dark'] .ant-col-offset-17 {
  margin-left: 70.83333333%;
}
html[data-theme='dark'] .ant-col-order-17 {
  order: 17;
}
html[data-theme='dark'] .ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
html[data-theme='dark'] .ant-col-push-16 {
  left: 66.66666667%;
}
html[data-theme='dark'] .ant-col-pull-16 {
  right: 66.66666667%;
}
html[data-theme='dark'] .ant-col-offset-16 {
  margin-left: 66.66666667%;
}
html[data-theme='dark'] .ant-col-order-16 {
  order: 16;
}
html[data-theme='dark'] .ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
html[data-theme='dark'] .ant-col-push-15 {
  left: 62.5%;
}
html[data-theme='dark'] .ant-col-pull-15 {
  right: 62.5%;
}
html[data-theme='dark'] .ant-col-offset-15 {
  margin-left: 62.5%;
}
html[data-theme='dark'] .ant-col-order-15 {
  order: 15;
}
html[data-theme='dark'] .ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
html[data-theme='dark'] .ant-col-push-14 {
  left: 58.33333333%;
}
html[data-theme='dark'] .ant-col-pull-14 {
  right: 58.33333333%;
}
html[data-theme='dark'] .ant-col-offset-14 {
  margin-left: 58.33333333%;
}
html[data-theme='dark'] .ant-col-order-14 {
  order: 14;
}
html[data-theme='dark'] .ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
html[data-theme='dark'] .ant-col-push-13 {
  left: 54.16666667%;
}
html[data-theme='dark'] .ant-col-pull-13 {
  right: 54.16666667%;
}
html[data-theme='dark'] .ant-col-offset-13 {
  margin-left: 54.16666667%;
}
html[data-theme='dark'] .ant-col-order-13 {
  order: 13;
}
html[data-theme='dark'] .ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
html[data-theme='dark'] .ant-col-push-12 {
  left: 50%;
}
html[data-theme='dark'] .ant-col-pull-12 {
  right: 50%;
}
html[data-theme='dark'] .ant-col-offset-12 {
  margin-left: 50%;
}
html[data-theme='dark'] .ant-col-order-12 {
  order: 12;
}
html[data-theme='dark'] .ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
html[data-theme='dark'] .ant-col-push-11 {
  left: 45.83333333%;
}
html[data-theme='dark'] .ant-col-pull-11 {
  right: 45.83333333%;
}
html[data-theme='dark'] .ant-col-offset-11 {
  margin-left: 45.83333333%;
}
html[data-theme='dark'] .ant-col-order-11 {
  order: 11;
}
html[data-theme='dark'] .ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
html[data-theme='dark'] .ant-col-push-10 {
  left: 41.66666667%;
}
html[data-theme='dark'] .ant-col-pull-10 {
  right: 41.66666667%;
}
html[data-theme='dark'] .ant-col-offset-10 {
  margin-left: 41.66666667%;
}
html[data-theme='dark'] .ant-col-order-10 {
  order: 10;
}
html[data-theme='dark'] .ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
html[data-theme='dark'] .ant-col-push-9 {
  left: 37.5%;
}
html[data-theme='dark'] .ant-col-pull-9 {
  right: 37.5%;
}
html[data-theme='dark'] .ant-col-offset-9 {
  margin-left: 37.5%;
}
html[data-theme='dark'] .ant-col-order-9 {
  order: 9;
}
html[data-theme='dark'] .ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
html[data-theme='dark'] .ant-col-push-8 {
  left: 33.33333333%;
}
html[data-theme='dark'] .ant-col-pull-8 {
  right: 33.33333333%;
}
html[data-theme='dark'] .ant-col-offset-8 {
  margin-left: 33.33333333%;
}
html[data-theme='dark'] .ant-col-order-8 {
  order: 8;
}
html[data-theme='dark'] .ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
html[data-theme='dark'] .ant-col-push-7 {
  left: 29.16666667%;
}
html[data-theme='dark'] .ant-col-pull-7 {
  right: 29.16666667%;
}
html[data-theme='dark'] .ant-col-offset-7 {
  margin-left: 29.16666667%;
}
html[data-theme='dark'] .ant-col-order-7 {
  order: 7;
}
html[data-theme='dark'] .ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
html[data-theme='dark'] .ant-col-push-6 {
  left: 25%;
}
html[data-theme='dark'] .ant-col-pull-6 {
  right: 25%;
}
html[data-theme='dark'] .ant-col-offset-6 {
  margin-left: 25%;
}
html[data-theme='dark'] .ant-col-order-6 {
  order: 6;
}
html[data-theme='dark'] .ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
html[data-theme='dark'] .ant-col-push-5 {
  left: 20.83333333%;
}
html[data-theme='dark'] .ant-col-pull-5 {
  right: 20.83333333%;
}
html[data-theme='dark'] .ant-col-offset-5 {
  margin-left: 20.83333333%;
}
html[data-theme='dark'] .ant-col-order-5 {
  order: 5;
}
html[data-theme='dark'] .ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
html[data-theme='dark'] .ant-col-push-4 {
  left: 16.66666667%;
}
html[data-theme='dark'] .ant-col-pull-4 {
  right: 16.66666667%;
}
html[data-theme='dark'] .ant-col-offset-4 {
  margin-left: 16.66666667%;
}
html[data-theme='dark'] .ant-col-order-4 {
  order: 4;
}
html[data-theme='dark'] .ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
html[data-theme='dark'] .ant-col-push-3 {
  left: 12.5%;
}
html[data-theme='dark'] .ant-col-pull-3 {
  right: 12.5%;
}
html[data-theme='dark'] .ant-col-offset-3 {
  margin-left: 12.5%;
}
html[data-theme='dark'] .ant-col-order-3 {
  order: 3;
}
html[data-theme='dark'] .ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
html[data-theme='dark'] .ant-col-push-2 {
  left: 8.33333333%;
}
html[data-theme='dark'] .ant-col-pull-2 {
  right: 8.33333333%;
}
html[data-theme='dark'] .ant-col-offset-2 {
  margin-left: 8.33333333%;
}
html[data-theme='dark'] .ant-col-order-2 {
  order: 2;
}
html[data-theme='dark'] .ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
html[data-theme='dark'] .ant-col-push-1 {
  left: 4.16666667%;
}
html[data-theme='dark'] .ant-col-pull-1 {
  right: 4.16666667%;
}
html[data-theme='dark'] .ant-col-offset-1 {
  margin-left: 4.16666667%;
}
html[data-theme='dark'] .ant-col-order-1 {
  order: 1;
}
html[data-theme='dark'] .ant-col-0 {
  display: none;
}
html[data-theme='dark'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='dark'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='dark'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='dark'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='dark'] .ant-col-offset-0 {
  margin-left: 0;
}
html[data-theme='dark'] .ant-col-order-0 {
  order: 0;
}
html[data-theme='dark'] .ant-col-xs-1,
html[data-theme='dark'] .ant-col-xs-2,
html[data-theme='dark'] .ant-col-xs-3,
html[data-theme='dark'] .ant-col-xs-4,
html[data-theme='dark'] .ant-col-xs-5,
html[data-theme='dark'] .ant-col-xs-6,
html[data-theme='dark'] .ant-col-xs-7,
html[data-theme='dark'] .ant-col-xs-8,
html[data-theme='dark'] .ant-col-xs-9,
html[data-theme='dark'] .ant-col-xs-10,
html[data-theme='dark'] .ant-col-xs-11,
html[data-theme='dark'] .ant-col-xs-12,
html[data-theme='dark'] .ant-col-xs-13,
html[data-theme='dark'] .ant-col-xs-14,
html[data-theme='dark'] .ant-col-xs-15,
html[data-theme='dark'] .ant-col-xs-16,
html[data-theme='dark'] .ant-col-xs-17,
html[data-theme='dark'] .ant-col-xs-18,
html[data-theme='dark'] .ant-col-xs-19,
html[data-theme='dark'] .ant-col-xs-20,
html[data-theme='dark'] .ant-col-xs-21,
html[data-theme='dark'] .ant-col-xs-22,
html[data-theme='dark'] .ant-col-xs-23,
html[data-theme='dark'] .ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
html[data-theme='dark'] .ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
html[data-theme='dark'] .ant-col-xs-push-24 {
  left: 100%;
}
html[data-theme='dark'] .ant-col-xs-pull-24 {
  right: 100%;
}
html[data-theme='dark'] .ant-col-xs-offset-24 {
  margin-left: 100%;
}
html[data-theme='dark'] .ant-col-xs-order-24 {
  order: 24;
}
html[data-theme='dark'] .ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
html[data-theme='dark'] .ant-col-xs-push-23 {
  left: 95.83333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-23 {
  right: 95.83333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
html[data-theme='dark'] .ant-col-xs-order-23 {
  order: 23;
}
html[data-theme='dark'] .ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
html[data-theme='dark'] .ant-col-xs-push-22 {
  left: 91.66666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-22 {
  right: 91.66666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
html[data-theme='dark'] .ant-col-xs-order-22 {
  order: 22;
}
html[data-theme='dark'] .ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
html[data-theme='dark'] .ant-col-xs-push-21 {
  left: 87.5%;
}
html[data-theme='dark'] .ant-col-xs-pull-21 {
  right: 87.5%;
}
html[data-theme='dark'] .ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
html[data-theme='dark'] .ant-col-xs-order-21 {
  order: 21;
}
html[data-theme='dark'] .ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
html[data-theme='dark'] .ant-col-xs-push-20 {
  left: 83.33333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-20 {
  right: 83.33333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
html[data-theme='dark'] .ant-col-xs-order-20 {
  order: 20;
}
html[data-theme='dark'] .ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
html[data-theme='dark'] .ant-col-xs-push-19 {
  left: 79.16666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-19 {
  right: 79.16666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
html[data-theme='dark'] .ant-col-xs-order-19 {
  order: 19;
}
html[data-theme='dark'] .ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
html[data-theme='dark'] .ant-col-xs-push-18 {
  left: 75%;
}
html[data-theme='dark'] .ant-col-xs-pull-18 {
  right: 75%;
}
html[data-theme='dark'] .ant-col-xs-offset-18 {
  margin-left: 75%;
}
html[data-theme='dark'] .ant-col-xs-order-18 {
  order: 18;
}
html[data-theme='dark'] .ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
html[data-theme='dark'] .ant-col-xs-push-17 {
  left: 70.83333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-17 {
  right: 70.83333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
html[data-theme='dark'] .ant-col-xs-order-17 {
  order: 17;
}
html[data-theme='dark'] .ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
html[data-theme='dark'] .ant-col-xs-push-16 {
  left: 66.66666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-16 {
  right: 66.66666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
html[data-theme='dark'] .ant-col-xs-order-16 {
  order: 16;
}
html[data-theme='dark'] .ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
html[data-theme='dark'] .ant-col-xs-push-15 {
  left: 62.5%;
}
html[data-theme='dark'] .ant-col-xs-pull-15 {
  right: 62.5%;
}
html[data-theme='dark'] .ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
html[data-theme='dark'] .ant-col-xs-order-15 {
  order: 15;
}
html[data-theme='dark'] .ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
html[data-theme='dark'] .ant-col-xs-push-14 {
  left: 58.33333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-14 {
  right: 58.33333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
html[data-theme='dark'] .ant-col-xs-order-14 {
  order: 14;
}
html[data-theme='dark'] .ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
html[data-theme='dark'] .ant-col-xs-push-13 {
  left: 54.16666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-13 {
  right: 54.16666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
html[data-theme='dark'] .ant-col-xs-order-13 {
  order: 13;
}
html[data-theme='dark'] .ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
html[data-theme='dark'] .ant-col-xs-push-12 {
  left: 50%;
}
html[data-theme='dark'] .ant-col-xs-pull-12 {
  right: 50%;
}
html[data-theme='dark'] .ant-col-xs-offset-12 {
  margin-left: 50%;
}
html[data-theme='dark'] .ant-col-xs-order-12 {
  order: 12;
}
html[data-theme='dark'] .ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
html[data-theme='dark'] .ant-col-xs-push-11 {
  left: 45.83333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-11 {
  right: 45.83333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
html[data-theme='dark'] .ant-col-xs-order-11 {
  order: 11;
}
html[data-theme='dark'] .ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
html[data-theme='dark'] .ant-col-xs-push-10 {
  left: 41.66666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-10 {
  right: 41.66666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
html[data-theme='dark'] .ant-col-xs-order-10 {
  order: 10;
}
html[data-theme='dark'] .ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
html[data-theme='dark'] .ant-col-xs-push-9 {
  left: 37.5%;
}
html[data-theme='dark'] .ant-col-xs-pull-9 {
  right: 37.5%;
}
html[data-theme='dark'] .ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
html[data-theme='dark'] .ant-col-xs-order-9 {
  order: 9;
}
html[data-theme='dark'] .ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
html[data-theme='dark'] .ant-col-xs-push-8 {
  left: 33.33333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-8 {
  right: 33.33333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
html[data-theme='dark'] .ant-col-xs-order-8 {
  order: 8;
}
html[data-theme='dark'] .ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
html[data-theme='dark'] .ant-col-xs-push-7 {
  left: 29.16666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-7 {
  right: 29.16666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
html[data-theme='dark'] .ant-col-xs-order-7 {
  order: 7;
}
html[data-theme='dark'] .ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
html[data-theme='dark'] .ant-col-xs-push-6 {
  left: 25%;
}
html[data-theme='dark'] .ant-col-xs-pull-6 {
  right: 25%;
}
html[data-theme='dark'] .ant-col-xs-offset-6 {
  margin-left: 25%;
}
html[data-theme='dark'] .ant-col-xs-order-6 {
  order: 6;
}
html[data-theme='dark'] .ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
html[data-theme='dark'] .ant-col-xs-push-5 {
  left: 20.83333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-5 {
  right: 20.83333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
html[data-theme='dark'] .ant-col-xs-order-5 {
  order: 5;
}
html[data-theme='dark'] .ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
html[data-theme='dark'] .ant-col-xs-push-4 {
  left: 16.66666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-4 {
  right: 16.66666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
html[data-theme='dark'] .ant-col-xs-order-4 {
  order: 4;
}
html[data-theme='dark'] .ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
html[data-theme='dark'] .ant-col-xs-push-3 {
  left: 12.5%;
}
html[data-theme='dark'] .ant-col-xs-pull-3 {
  right: 12.5%;
}
html[data-theme='dark'] .ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
html[data-theme='dark'] .ant-col-xs-order-3 {
  order: 3;
}
html[data-theme='dark'] .ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
html[data-theme='dark'] .ant-col-xs-push-2 {
  left: 8.33333333%;
}
html[data-theme='dark'] .ant-col-xs-pull-2 {
  right: 8.33333333%;
}
html[data-theme='dark'] .ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
html[data-theme='dark'] .ant-col-xs-order-2 {
  order: 2;
}
html[data-theme='dark'] .ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
html[data-theme='dark'] .ant-col-xs-push-1 {
  left: 4.16666667%;
}
html[data-theme='dark'] .ant-col-xs-pull-1 {
  right: 4.16666667%;
}
html[data-theme='dark'] .ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
html[data-theme='dark'] .ant-col-xs-order-1 {
  order: 1;
}
html[data-theme='dark'] .ant-col-xs-0 {
  display: none;
}
html[data-theme='dark'] .ant-col-push-0 {
  left: auto;
}
html[data-theme='dark'] .ant-col-pull-0 {
  right: auto;
}
html[data-theme='dark'] .ant-col-xs-push-0 {
  left: auto;
}
html[data-theme='dark'] .ant-col-xs-pull-0 {
  right: auto;
}
html[data-theme='dark'] .ant-col-xs-offset-0 {
  margin-left: 0;
}
html[data-theme='dark'] .ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .ant-col-sm-1,
  html[data-theme='dark'] .ant-col-sm-2,
  html[data-theme='dark'] .ant-col-sm-3,
  html[data-theme='dark'] .ant-col-sm-4,
  html[data-theme='dark'] .ant-col-sm-5,
  html[data-theme='dark'] .ant-col-sm-6,
  html[data-theme='dark'] .ant-col-sm-7,
  html[data-theme='dark'] .ant-col-sm-8,
  html[data-theme='dark'] .ant-col-sm-9,
  html[data-theme='dark'] .ant-col-sm-10,
  html[data-theme='dark'] .ant-col-sm-11,
  html[data-theme='dark'] .ant-col-sm-12,
  html[data-theme='dark'] .ant-col-sm-13,
  html[data-theme='dark'] .ant-col-sm-14,
  html[data-theme='dark'] .ant-col-sm-15,
  html[data-theme='dark'] .ant-col-sm-16,
  html[data-theme='dark'] .ant-col-sm-17,
  html[data-theme='dark'] .ant-col-sm-18,
  html[data-theme='dark'] .ant-col-sm-19,
  html[data-theme='dark'] .ant-col-sm-20,
  html[data-theme='dark'] .ant-col-sm-21,
  html[data-theme='dark'] .ant-col-sm-22,
  html[data-theme='dark'] .ant-col-sm-23,
  html[data-theme='dark'] .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='dark'] .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='dark'] .ant-col-sm-push-24 {
    left: 100%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-24 {
    right: 100%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='dark'] .ant-col-sm-order-24 {
    order: 24;
  }
  html[data-theme='dark'] .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-23 {
    order: 23;
  }
  html[data-theme='dark'] .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-22 {
    order: 22;
  }
  html[data-theme='dark'] .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='dark'] .ant-col-sm-push-21 {
    left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-sm-order-21 {
    order: 21;
  }
  html[data-theme='dark'] .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-20 {
    order: 20;
  }
  html[data-theme='dark'] .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-19 {
    order: 19;
  }
  html[data-theme='dark'] .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='dark'] .ant-col-sm-push-18 {
    left: 75%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-18 {
    right: 75%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .ant-col-sm-order-18 {
    order: 18;
  }
  html[data-theme='dark'] .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-17 {
    order: 17;
  }
  html[data-theme='dark'] .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-16 {
    order: 16;
  }
  html[data-theme='dark'] .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='dark'] .ant-col-sm-push-15 {
    left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-sm-order-15 {
    order: 15;
  }
  html[data-theme='dark'] .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-14 {
    order: 14;
  }
  html[data-theme='dark'] .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-13 {
    order: 13;
  }
  html[data-theme='dark'] .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='dark'] .ant-col-sm-push-12 {
    left: 50%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-12 {
    right: 50%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .ant-col-sm-order-12 {
    order: 12;
  }
  html[data-theme='dark'] .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-11 {
    order: 11;
  }
  html[data-theme='dark'] .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-10 {
    order: 10;
  }
  html[data-theme='dark'] .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='dark'] .ant-col-sm-push-9 {
    left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-sm-order-9 {
    order: 9;
  }
  html[data-theme='dark'] .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-8 {
    order: 8;
  }
  html[data-theme='dark'] .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-7 {
    order: 7;
  }
  html[data-theme='dark'] .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='dark'] .ant-col-sm-push-6 {
    left: 25%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-6 {
    right: 25%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .ant-col-sm-order-6 {
    order: 6;
  }
  html[data-theme='dark'] .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-5 {
    order: 5;
  }
  html[data-theme='dark'] .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-4 {
    order: 4;
  }
  html[data-theme='dark'] .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='dark'] .ant-col-sm-push-3 {
    left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-sm-order-3 {
    order: 3;
  }
  html[data-theme='dark'] .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-sm-order-2 {
    order: 2;
  }
  html[data-theme='dark'] .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-sm-order-1 {
    order: 1;
  }
  html[data-theme='dark'] .ant-col-sm-0 {
    display: none;
  }
  html[data-theme='dark'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-sm-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-sm-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .ant-col-md-1,
  html[data-theme='dark'] .ant-col-md-2,
  html[data-theme='dark'] .ant-col-md-3,
  html[data-theme='dark'] .ant-col-md-4,
  html[data-theme='dark'] .ant-col-md-5,
  html[data-theme='dark'] .ant-col-md-6,
  html[data-theme='dark'] .ant-col-md-7,
  html[data-theme='dark'] .ant-col-md-8,
  html[data-theme='dark'] .ant-col-md-9,
  html[data-theme='dark'] .ant-col-md-10,
  html[data-theme='dark'] .ant-col-md-11,
  html[data-theme='dark'] .ant-col-md-12,
  html[data-theme='dark'] .ant-col-md-13,
  html[data-theme='dark'] .ant-col-md-14,
  html[data-theme='dark'] .ant-col-md-15,
  html[data-theme='dark'] .ant-col-md-16,
  html[data-theme='dark'] .ant-col-md-17,
  html[data-theme='dark'] .ant-col-md-18,
  html[data-theme='dark'] .ant-col-md-19,
  html[data-theme='dark'] .ant-col-md-20,
  html[data-theme='dark'] .ant-col-md-21,
  html[data-theme='dark'] .ant-col-md-22,
  html[data-theme='dark'] .ant-col-md-23,
  html[data-theme='dark'] .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='dark'] .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='dark'] .ant-col-md-push-24 {
    left: 100%;
  }
  html[data-theme='dark'] .ant-col-md-pull-24 {
    right: 100%;
  }
  html[data-theme='dark'] .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='dark'] .ant-col-md-order-24 {
    order: 24;
  }
  html[data-theme='dark'] .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-23 {
    order: 23;
  }
  html[data-theme='dark'] .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-22 {
    order: 22;
  }
  html[data-theme='dark'] .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='dark'] .ant-col-md-push-21 {
    left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-md-pull-21 {
    right: 87.5%;
  }
  html[data-theme='dark'] .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-md-order-21 {
    order: 21;
  }
  html[data-theme='dark'] .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-20 {
    order: 20;
  }
  html[data-theme='dark'] .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-19 {
    order: 19;
  }
  html[data-theme='dark'] .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='dark'] .ant-col-md-push-18 {
    left: 75%;
  }
  html[data-theme='dark'] .ant-col-md-pull-18 {
    right: 75%;
  }
  html[data-theme='dark'] .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .ant-col-md-order-18 {
    order: 18;
  }
  html[data-theme='dark'] .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-17 {
    order: 17;
  }
  html[data-theme='dark'] .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-16 {
    order: 16;
  }
  html[data-theme='dark'] .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='dark'] .ant-col-md-push-15 {
    left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-md-pull-15 {
    right: 62.5%;
  }
  html[data-theme='dark'] .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-md-order-15 {
    order: 15;
  }
  html[data-theme='dark'] .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-14 {
    order: 14;
  }
  html[data-theme='dark'] .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-13 {
    order: 13;
  }
  html[data-theme='dark'] .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='dark'] .ant-col-md-push-12 {
    left: 50%;
  }
  html[data-theme='dark'] .ant-col-md-pull-12 {
    right: 50%;
  }
  html[data-theme='dark'] .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .ant-col-md-order-12 {
    order: 12;
  }
  html[data-theme='dark'] .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-11 {
    order: 11;
  }
  html[data-theme='dark'] .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-10 {
    order: 10;
  }
  html[data-theme='dark'] .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='dark'] .ant-col-md-push-9 {
    left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-md-pull-9 {
    right: 37.5%;
  }
  html[data-theme='dark'] .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-md-order-9 {
    order: 9;
  }
  html[data-theme='dark'] .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-8 {
    order: 8;
  }
  html[data-theme='dark'] .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-7 {
    order: 7;
  }
  html[data-theme='dark'] .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='dark'] .ant-col-md-push-6 {
    left: 25%;
  }
  html[data-theme='dark'] .ant-col-md-pull-6 {
    right: 25%;
  }
  html[data-theme='dark'] .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .ant-col-md-order-6 {
    order: 6;
  }
  html[data-theme='dark'] .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-5 {
    order: 5;
  }
  html[data-theme='dark'] .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-4 {
    order: 4;
  }
  html[data-theme='dark'] .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='dark'] .ant-col-md-push-3 {
    left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-md-pull-3 {
    right: 12.5%;
  }
  html[data-theme='dark'] .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-md-order-3 {
    order: 3;
  }
  html[data-theme='dark'] .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-md-order-2 {
    order: 2;
  }
  html[data-theme='dark'] .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-md-order-1 {
    order: 1;
  }
  html[data-theme='dark'] .ant-col-md-0 {
    display: none;
  }
  html[data-theme='dark'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-md-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-md-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-md-offset-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .ant-col-lg-1,
  html[data-theme='dark'] .ant-col-lg-2,
  html[data-theme='dark'] .ant-col-lg-3,
  html[data-theme='dark'] .ant-col-lg-4,
  html[data-theme='dark'] .ant-col-lg-5,
  html[data-theme='dark'] .ant-col-lg-6,
  html[data-theme='dark'] .ant-col-lg-7,
  html[data-theme='dark'] .ant-col-lg-8,
  html[data-theme='dark'] .ant-col-lg-9,
  html[data-theme='dark'] .ant-col-lg-10,
  html[data-theme='dark'] .ant-col-lg-11,
  html[data-theme='dark'] .ant-col-lg-12,
  html[data-theme='dark'] .ant-col-lg-13,
  html[data-theme='dark'] .ant-col-lg-14,
  html[data-theme='dark'] .ant-col-lg-15,
  html[data-theme='dark'] .ant-col-lg-16,
  html[data-theme='dark'] .ant-col-lg-17,
  html[data-theme='dark'] .ant-col-lg-18,
  html[data-theme='dark'] .ant-col-lg-19,
  html[data-theme='dark'] .ant-col-lg-20,
  html[data-theme='dark'] .ant-col-lg-21,
  html[data-theme='dark'] .ant-col-lg-22,
  html[data-theme='dark'] .ant-col-lg-23,
  html[data-theme='dark'] .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='dark'] .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='dark'] .ant-col-lg-push-24 {
    left: 100%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-24 {
    right: 100%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='dark'] .ant-col-lg-order-24 {
    order: 24;
  }
  html[data-theme='dark'] .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-23 {
    order: 23;
  }
  html[data-theme='dark'] .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-22 {
    order: 22;
  }
  html[data-theme='dark'] .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='dark'] .ant-col-lg-push-21 {
    left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-lg-order-21 {
    order: 21;
  }
  html[data-theme='dark'] .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-20 {
    order: 20;
  }
  html[data-theme='dark'] .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-19 {
    order: 19;
  }
  html[data-theme='dark'] .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='dark'] .ant-col-lg-push-18 {
    left: 75%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-18 {
    right: 75%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .ant-col-lg-order-18 {
    order: 18;
  }
  html[data-theme='dark'] .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-17 {
    order: 17;
  }
  html[data-theme='dark'] .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-16 {
    order: 16;
  }
  html[data-theme='dark'] .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='dark'] .ant-col-lg-push-15 {
    left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-lg-order-15 {
    order: 15;
  }
  html[data-theme='dark'] .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-14 {
    order: 14;
  }
  html[data-theme='dark'] .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-13 {
    order: 13;
  }
  html[data-theme='dark'] .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='dark'] .ant-col-lg-push-12 {
    left: 50%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-12 {
    right: 50%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .ant-col-lg-order-12 {
    order: 12;
  }
  html[data-theme='dark'] .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-11 {
    order: 11;
  }
  html[data-theme='dark'] .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-10 {
    order: 10;
  }
  html[data-theme='dark'] .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='dark'] .ant-col-lg-push-9 {
    left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-lg-order-9 {
    order: 9;
  }
  html[data-theme='dark'] .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-8 {
    order: 8;
  }
  html[data-theme='dark'] .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-7 {
    order: 7;
  }
  html[data-theme='dark'] .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='dark'] .ant-col-lg-push-6 {
    left: 25%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-6 {
    right: 25%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .ant-col-lg-order-6 {
    order: 6;
  }
  html[data-theme='dark'] .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-5 {
    order: 5;
  }
  html[data-theme='dark'] .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-4 {
    order: 4;
  }
  html[data-theme='dark'] .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='dark'] .ant-col-lg-push-3 {
    left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-lg-order-3 {
    order: 3;
  }
  html[data-theme='dark'] .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-lg-order-2 {
    order: 2;
  }
  html[data-theme='dark'] .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-lg-order-1 {
    order: 1;
  }
  html[data-theme='dark'] .ant-col-lg-0 {
    display: none;
  }
  html[data-theme='dark'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-lg-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-lg-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .ant-col-xl-1,
  html[data-theme='dark'] .ant-col-xl-2,
  html[data-theme='dark'] .ant-col-xl-3,
  html[data-theme='dark'] .ant-col-xl-4,
  html[data-theme='dark'] .ant-col-xl-5,
  html[data-theme='dark'] .ant-col-xl-6,
  html[data-theme='dark'] .ant-col-xl-7,
  html[data-theme='dark'] .ant-col-xl-8,
  html[data-theme='dark'] .ant-col-xl-9,
  html[data-theme='dark'] .ant-col-xl-10,
  html[data-theme='dark'] .ant-col-xl-11,
  html[data-theme='dark'] .ant-col-xl-12,
  html[data-theme='dark'] .ant-col-xl-13,
  html[data-theme='dark'] .ant-col-xl-14,
  html[data-theme='dark'] .ant-col-xl-15,
  html[data-theme='dark'] .ant-col-xl-16,
  html[data-theme='dark'] .ant-col-xl-17,
  html[data-theme='dark'] .ant-col-xl-18,
  html[data-theme='dark'] .ant-col-xl-19,
  html[data-theme='dark'] .ant-col-xl-20,
  html[data-theme='dark'] .ant-col-xl-21,
  html[data-theme='dark'] .ant-col-xl-22,
  html[data-theme='dark'] .ant-col-xl-23,
  html[data-theme='dark'] .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='dark'] .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='dark'] .ant-col-xl-push-24 {
    left: 100%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-24 {
    right: 100%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='dark'] .ant-col-xl-order-24 {
    order: 24;
  }
  html[data-theme='dark'] .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-23 {
    order: 23;
  }
  html[data-theme='dark'] .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-22 {
    order: 22;
  }
  html[data-theme='dark'] .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xl-push-21 {
    left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xl-order-21 {
    order: 21;
  }
  html[data-theme='dark'] .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-20 {
    order: 20;
  }
  html[data-theme='dark'] .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-19 {
    order: 19;
  }
  html[data-theme='dark'] .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='dark'] .ant-col-xl-push-18 {
    left: 75%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-18 {
    right: 75%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .ant-col-xl-order-18 {
    order: 18;
  }
  html[data-theme='dark'] .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-17 {
    order: 17;
  }
  html[data-theme='dark'] .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-16 {
    order: 16;
  }
  html[data-theme='dark'] .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xl-push-15 {
    left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xl-order-15 {
    order: 15;
  }
  html[data-theme='dark'] .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-14 {
    order: 14;
  }
  html[data-theme='dark'] .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-13 {
    order: 13;
  }
  html[data-theme='dark'] .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='dark'] .ant-col-xl-push-12 {
    left: 50%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-12 {
    right: 50%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .ant-col-xl-order-12 {
    order: 12;
  }
  html[data-theme='dark'] .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-11 {
    order: 11;
  }
  html[data-theme='dark'] .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-10 {
    order: 10;
  }
  html[data-theme='dark'] .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xl-push-9 {
    left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xl-order-9 {
    order: 9;
  }
  html[data-theme='dark'] .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-8 {
    order: 8;
  }
  html[data-theme='dark'] .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-7 {
    order: 7;
  }
  html[data-theme='dark'] .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='dark'] .ant-col-xl-push-6 {
    left: 25%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-6 {
    right: 25%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .ant-col-xl-order-6 {
    order: 6;
  }
  html[data-theme='dark'] .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-5 {
    order: 5;
  }
  html[data-theme='dark'] .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-4 {
    order: 4;
  }
  html[data-theme='dark'] .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xl-push-3 {
    left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xl-order-3 {
    order: 3;
  }
  html[data-theme='dark'] .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xl-order-2 {
    order: 2;
  }
  html[data-theme='dark'] .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xl-order-1 {
    order: 1;
  }
  html[data-theme='dark'] .ant-col-xl-0 {
    display: none;
  }
  html[data-theme='dark'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-xl-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-xl-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  html[data-theme='dark'] .ant-col-xxl-1,
  html[data-theme='dark'] .ant-col-xxl-2,
  html[data-theme='dark'] .ant-col-xxl-3,
  html[data-theme='dark'] .ant-col-xxl-4,
  html[data-theme='dark'] .ant-col-xxl-5,
  html[data-theme='dark'] .ant-col-xxl-6,
  html[data-theme='dark'] .ant-col-xxl-7,
  html[data-theme='dark'] .ant-col-xxl-8,
  html[data-theme='dark'] .ant-col-xxl-9,
  html[data-theme='dark'] .ant-col-xxl-10,
  html[data-theme='dark'] .ant-col-xxl-11,
  html[data-theme='dark'] .ant-col-xxl-12,
  html[data-theme='dark'] .ant-col-xxl-13,
  html[data-theme='dark'] .ant-col-xxl-14,
  html[data-theme='dark'] .ant-col-xxl-15,
  html[data-theme='dark'] .ant-col-xxl-16,
  html[data-theme='dark'] .ant-col-xxl-17,
  html[data-theme='dark'] .ant-col-xxl-18,
  html[data-theme='dark'] .ant-col-xxl-19,
  html[data-theme='dark'] .ant-col-xxl-20,
  html[data-theme='dark'] .ant-col-xxl-21,
  html[data-theme='dark'] .ant-col-xxl-22,
  html[data-theme='dark'] .ant-col-xxl-23,
  html[data-theme='dark'] .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  html[data-theme='dark'] .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-24 {
    left: 100%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-24 {
    right: 100%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-24 {
    order: 24;
  }
  html[data-theme='dark'] .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-23 {
    order: 23;
  }
  html[data-theme='dark'] .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-22 {
    order: 22;
  }
  html[data-theme='dark'] .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-21 {
    order: 21;
  }
  html[data-theme='dark'] .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-20 {
    order: 20;
  }
  html[data-theme='dark'] .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-19 {
    order: 19;
  }
  html[data-theme='dark'] .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-18 {
    left: 75%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-18 {
    right: 75%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-18 {
    order: 18;
  }
  html[data-theme='dark'] .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-17 {
    order: 17;
  }
  html[data-theme='dark'] .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-16 {
    order: 16;
  }
  html[data-theme='dark'] .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-15 {
    order: 15;
  }
  html[data-theme='dark'] .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-14 {
    order: 14;
  }
  html[data-theme='dark'] .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-13 {
    order: 13;
  }
  html[data-theme='dark'] .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-12 {
    left: 50%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-12 {
    right: 50%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-12 {
    order: 12;
  }
  html[data-theme='dark'] .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-11 {
    order: 11;
  }
  html[data-theme='dark'] .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-10 {
    order: 10;
  }
  html[data-theme='dark'] .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-9 {
    order: 9;
  }
  html[data-theme='dark'] .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-8 {
    order: 8;
  }
  html[data-theme='dark'] .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-7 {
    order: 7;
  }
  html[data-theme='dark'] .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-6 {
    left: 25%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-6 {
    right: 25%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-6 {
    order: 6;
  }
  html[data-theme='dark'] .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-5 {
    order: 5;
  }
  html[data-theme='dark'] .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-4 {
    order: 4;
  }
  html[data-theme='dark'] .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-3 {
    order: 3;
  }
  html[data-theme='dark'] .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-2 {
    order: 2;
  }
  html[data-theme='dark'] .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  html[data-theme='dark'] .ant-col-xxl-order-1 {
    order: 1;
  }
  html[data-theme='dark'] .ant-col-xxl-0 {
    display: none;
  }
  html[data-theme='dark'] .ant-col-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-xxl-push-0 {
    left: auto;
  }
  html[data-theme='dark'] .ant-col-xxl-pull-0 {
    right: auto;
  }
  html[data-theme='dark'] .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .ant-col-xxl-order-0 {
    order: 0;
  }
}
html[data-theme='dark'] .ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
html[data-theme='dark'] .ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='dark'] .ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
html[data-theme='dark'] .ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
html[data-theme='dark'] .ant-input-group-addon,
html[data-theme='dark'] .ant-input-group-wrap,
html[data-theme='dark'] .ant-input-group > .ant-input {
  display: table-cell;
}
html[data-theme='dark'] .ant-input-group-addon:not(:first-child):not(:last-child),
html[data-theme='dark'] .ant-input-group-wrap:not(:first-child):not(:last-child),
html[data-theme='dark'] .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
html[data-theme='dark'] .ant-input-group-addon,
html[data-theme='dark'] .ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-input-group-wrap > * {
  display: block !important;
}
html[data-theme='dark'] .ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
html[data-theme='dark'] .ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
html[data-theme='dark'] .ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
html[data-theme='dark'] .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #e4e8ee;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: #191934;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
html[data-theme='dark'] .ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
html[data-theme='dark'] .ant-input-group-addon .ant-select-open .ant-select-selection,
html[data-theme='dark'] .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #3851d1;
}
html[data-theme='dark'] .ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
html[data-theme='dark'] .ant-input-group > .ant-input:first-child,
html[data-theme='dark'] .ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
html[data-theme='dark'] .ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .ant-input-group-addon:first-child {
  border-right: 0;
}
html[data-theme='dark'] .ant-input-group-addon:last-child {
  border-left: 0;
}
html[data-theme='dark'] .ant-input-group > .ant-input:last-child,
html[data-theme='dark'] .ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
html[data-theme='dark'] .ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-input-group-lg .ant-input,
html[data-theme='dark'] .ant-input-group-lg > .ant-input-group-addon {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-input-group-sm .ant-input,
html[data-theme='dark'] .ant-input-group-sm > .ant-input-group-addon {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-input-group-lg .ant-select-selection--single {
  height: 30px;
}
html[data-theme='dark'] .ant-input-group-sm .ant-select-selection--single {
  height: 30px;
}
html[data-theme='dark'] .ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::before,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::after {
  clear: both;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::before,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact::after {
  clear: both;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-right-width: 1px;
  border-radius: 0;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
  z-index: 1;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
  z-index: 1;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > *:first-child,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > *:last-child,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
html[data-theme='dark'] .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
html[data-theme='dark'] .ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
html[data-theme='dark'] .ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
html[data-theme='dark'] .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-prefix,
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  color: #e4e8ee;
  line-height: 0;
  transform: translateY(-50%);
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
html[data-theme='dark'] .ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
html[data-theme='dark'] .ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-input-password-icon:hover {
  color: #333;
}
html[data-theme='dark'] .ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: top;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-input-clear-icon:active {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-input-clear-icon + i {
  margin-left: 6px;
}
html[data-theme='dark'] .ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
html[data-theme='dark'] .ant-input-search-enter-button input {
  border-right: 0;
}
html[data-theme='dark'] .ant-input-search-enter-button + .ant-input-group-addon,
html[data-theme='dark'] .ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
html[data-theme='dark'] .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
html[data-theme='dark'] .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #090912;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-input-number::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input-number::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input-number::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input-number:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-input-number-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-input-number-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
html[data-theme='dark'] .ant-input-number-handler:active {
  background: #f4f4f4;
}
html[data-theme='dark'] .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
html[data-theme='dark'] .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #5f79de;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner,
html[data-theme='dark'] .ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner > *,
html[data-theme='dark'] .ant-input-number-handler-down-inner > * {
  line-height: 1;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner svg,
html[data-theme='dark'] .ant-input-number-handler-down-inner svg {
  display: inline-block;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner::before,
html[data-theme='dark'] .ant-input-number-handler-down-inner::before {
  display: none;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
html[data-theme='dark'] .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
html[data-theme='dark'] .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
html[data-theme='dark'] .ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
html[data-theme='dark'] .ant-input-number:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input-number-focused {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
html[data-theme='dark'] .ant-input-number-input {
  width: 100%;
  height: 28px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield;
}
html[data-theme='dark'] .ant-input-number-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input-number-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-input-number-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
html[data-theme='dark'] .ant-input-number-lg input {
  height: 28px;
}
html[data-theme='dark'] .ant-input-number-sm {
  padding: 0;
}
html[data-theme='dark'] .ant-input-number-sm input {
  height: 28px;
  padding: 0 7px;
}
html[data-theme='dark'] .ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #191934;
  border-left: 1px solid #090912;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
html[data-theme='dark'] .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
html[data-theme='dark'] .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
html[data-theme='dark'] :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
html[data-theme='dark'] :root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
html[data-theme='dark'] .ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
html[data-theme='dark'] .ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
html[data-theme='dark'] .ant-input-number-handler-up {
  cursor: pointer;
}
html[data-theme='dark'] .ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
html[data-theme='dark'] .ant-input-number-handler-up:hover {
  height: 60% !important;
}
html[data-theme='dark'] .ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #090912;
  cursor: pointer;
}
html[data-theme='dark'] .ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
html[data-theme='dark'] .ant-input-number-handler-down:hover {
  height: 60% !important;
}
html[data-theme='dark'] .ant-input-number-handler-up-disabled,
html[data-theme='dark'] .ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
html[data-theme='dark'] .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
html[data-theme='dark'] .ant-layout,
html[data-theme='dark'] .ant-layout * {
  box-sizing: border-box;
}
html[data-theme='dark'] .ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
html[data-theme='dark'] .ant-layout.ant-layout-has-sider > .ant-layout,
html[data-theme='dark'] .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
html[data-theme='dark'] .ant-layout-header,
html[data-theme='dark'] .ant-layout-footer {
  flex: 0 0 auto;
}
html[data-theme='dark'] .ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
html[data-theme='dark'] .ant-layout-footer {
  padding: 24px 50px;
  color: #e4e8ee;
  font-size: 14px;
  background: #f0f2f5;
}
html[data-theme='dark'] .ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
html[data-theme='dark'] .ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
html[data-theme='dark'] .ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
html[data-theme='dark'] .ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
html[data-theme='dark'] .ant-layout-sider-right {
  order: 1;
}
html[data-theme='dark'] .ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #191934;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
html[data-theme='dark'] .ant-layout-sider-zero-width > * {
  overflow: hidden;
}
html[data-theme='dark'] .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  width: 36px;
  height: 42px;
  color: #191934;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
html[data-theme='dark'] .ant-layout-sider-zero-width-trigger-right {
  left: -36px;
}
html[data-theme='dark'] .ant-layout-sider-light {
  background: #191934;
}
html[data-theme='dark'] .ant-layout-sider-light .ant-layout-sider-trigger {
  color: #e4e8ee;
  background: #191934;
}
html[data-theme='dark'] .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #e4e8ee;
  background: #191934;
}
html[data-theme='dark'] .ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
html[data-theme='dark'] .ant-list * {
  outline: none;
}
html[data-theme='dark'] .ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
html[data-theme='dark'] .ant-list-more {
  margin-top: 12px;
  text-align: center;
}
html[data-theme='dark'] .ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
html[data-theme='dark'] .ant-list-spin {
  min-height: 40px;
  text-align: center;
}
html[data-theme='dark'] .ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
html[data-theme='dark'] .ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-list-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
html[data-theme='dark'] .ant-list-item-content {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  font-size: 0;
}
html[data-theme='dark'] .ant-list-item-meta-avatar {
  margin-right: 16px;
}
html[data-theme='dark'] .ant-list-item-meta-content {
  flex: 1 0;
}
html[data-theme='dark'] .ant-list-item-meta-title {
  margin-bottom: 4px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-list-item-meta-title > a {
  color: #e4e8ee;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-list-item-meta-title > a:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
html[data-theme='dark'] .ant-list-item-action > li:first-child {
  padding-left: 0;
}
html[data-theme='dark'] .ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-list-header {
  background: transparent;
}
html[data-theme='dark'] .ant-list-footer {
  background: transparent;
}
html[data-theme='dark'] .ant-list-header,
html[data-theme='dark'] .ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
html[data-theme='dark'] .ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
html[data-theme='dark'] .ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
html[data-theme='dark'] .ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
html[data-theme='dark'] .ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
html[data-theme='dark'] .ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item {
  align-items: initial;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: #e4e8ee;
  font-size: 16px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
html[data-theme='dark'] .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
html[data-theme='dark'] .ant-list-grid .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
html[data-theme='dark'] .ant-list-item-no-flex {
  display: block;
}
html[data-theme='dark'] .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
html[data-theme='dark'] .ant-list-bordered {
  border: 1px solid #090912;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
html[data-theme='dark'] .ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
html[data-theme='dark'] .ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
html[data-theme='dark'] .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
html[data-theme='dark'] .ant-list-bordered.ant-list-sm .ant-list-header,
html[data-theme='dark'] .ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
html[data-theme='dark'] .ant-list-bordered.ant-list-lg .ant-list-header,
html[data-theme='dark'] .ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  html[data-theme='dark'] .ant-list-item-action {
    margin-left: 24px;
  }
  html[data-theme='dark'] .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  html[data-theme='dark'] .ant-list-item {
    flex-wrap: wrap;
  }
  html[data-theme='dark'] .ant-list-item-action {
    margin-left: 12px;
  }
  html[data-theme='dark'] .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  html[data-theme='dark'] .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  html[data-theme='dark'] .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
html[data-theme='dark'] .ant-mentions-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 30px;
  padding: 0;
  line-height: 1.5;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-mentions-wrapper .ant-mentions-editor {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-mentions-wrapper .ant-mentions-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
html[data-theme='dark'] .ant-mentions-wrapper.ant-mentions-active:not(.disabled) .ant-mentions-editor {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-mentions-wrapper.disabled .ant-mentions-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper.disabled .ant-mentions-editor:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
html[data-theme='dark'] .ant-mentions-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #ffffff;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
html[data-theme='dark'] .ant-mentions-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #191934;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-mentions-dropdown-placement-top {
  margin-top: -0.1em;
}
html[data-theme='dark'] .ant-mentions-dropdown-notfound.ant-mentions-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-mentions-dropdown-notfound.ant-mentions-dropdown-item .anticon-loading {
  display: block;
  color: #3851d1;
  text-align: center;
}
html[data-theme='dark'] .ant-mentions-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #e4e8ee;
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
html[data-theme='dark'] .ant-mentions-dropdown-item:hover {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-mentions-dropdown-item.focus,
html[data-theme='dark'] .ant-mentions-dropdown-item-active {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-mentions-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-mentions-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #191934;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-mentions-dropdown-item-selected,
html[data-theme='dark'] .ant-mentions-dropdown-item-selected:hover {
  color: #e4e8ee;
  font-weight: bold;
  background-color: #f5f5f5;
}
html[data-theme='dark'] .ant-mentions-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  white-space: pre-wrap;
  padding: 0;
  overflow: hidden;
  vertical-align: bottom;
}
html[data-theme='dark'] .ant-mentions::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-mentions::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-mentions::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-mentions-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-mentions-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-mentions-disabled > textarea:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-mentions-focused {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-mentions > textarea,
html[data-theme='dark'] .ant-mentions-measure {
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: initial;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
html[data-theme='dark'] .ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
html[data-theme='dark'] .ant-mentions > textarea:read-only {
  cursor: default;
}
html[data-theme='dark'] .ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
html[data-theme='dark'] .ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #191934;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-mentions-dropdown-hidden {
  display: none;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #e4e8ee;
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  min-width: 100px;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item:hover {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #191934;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item-selected {
  color: #e4e8ee;
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='dark'] .ant-mentions-dropdown-menu-item-active {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: #e4e8ee;
  line-height: 0;
  list-style: none;
  background: #191934;
  outline: none;
  box-shadow: none;
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
html[data-theme='dark'] .ant-menu::before,
html[data-theme='dark'] .ant-menu::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-menu::after {
  clear: both;
}
html[data-theme='dark'] .ant-menu::before,
html[data-theme='dark'] .ant-menu::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-menu::after {
  clear: both;
}
html[data-theme='dark'] .ant-menu ul,
html[data-theme='dark'] .ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-menu-hidden {
  display: none;
}
html[data-theme='dark'] .ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-item:active,
html[data-theme='dark'] .ant-menu-submenu-title:active {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-item > a {
  display: block;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-menu-item > a:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
html[data-theme='dark'] .ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-menu-item:hover,
html[data-theme='dark'] .ant-menu-item-active,
html[data-theme='dark'] .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
html[data-theme='dark'] .ant-menu-submenu-active,
html[data-theme='dark'] .ant-menu-submenu-title:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-horizontal .ant-menu-item,
html[data-theme='dark'] .ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item:hover,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item-active,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
html[data-theme='dark'] .ant-menu-item-selected {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-item-selected > a,
html[data-theme='dark'] .ant-menu-item-selected > a:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-menu-inline,
html[data-theme='dark'] .ant-menu-vertical,
html[data-theme='dark'] .ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-menu-vertical.ant-menu-sub,
html[data-theme='dark'] .ant-menu-vertical-left.ant-menu-sub,
html[data-theme='dark'] .ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  transform-origin: 0 0;
}
html[data-theme='dark'] .ant-menu-vertical.ant-menu-sub .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
html[data-theme='dark'] .ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
html[data-theme='dark'] .ant-menu-item,
html[data-theme='dark'] .ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-item .anticon,
html[data-theme='dark'] .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-item .anticon + span,
html[data-theme='dark'] .ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #191934;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
html[data-theme='dark'] .ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
html[data-theme='dark'] .ant-menu-submenu > .ant-menu {
  background-color: #191934;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #191934;
  background: #e4e8ee \9;
  background-image: linear-gradient(to right, #e4e8ee, #e4e8ee);
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #3851d1, #3851d1);
}
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
html[data-theme='dark'] .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
html[data-theme='dark'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
html[data-theme='dark'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
html[data-theme='dark'] .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-submenu-selected,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-submenu-selected,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-submenu-selected > a,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-submenu-selected > a,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item:hover,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu:hover,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item-active,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu-active,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item-open,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu-open,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item-selected,
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #3851d1;
  border-bottom: 2px solid #3851d1;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
html[data-theme='dark'] .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #3851d1;
}
html[data-theme='dark'] .ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline .ant-menu-item {
  position: relative;
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #3851d1;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-submenu,
html[data-theme='dark'] .ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.01px;
}
html[data-theme='dark'] .ant-menu-vertical .ant-menu-item:not(:last-child),
html[data-theme='dark'] .ant-menu-vertical-left .ant-menu-item:not(:last-child),
html[data-theme='dark'] .ant-menu-vertical-right .ant-menu-item:not(:last-child),
html[data-theme='dark'] .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
html[data-theme='dark'] .ant-menu-vertical > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-left > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical-right > .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline > .ant-menu-item,
html[data-theme='dark'] .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
html[data-theme='dark'] .ant-menu-inline {
  width: 100%;
}
html[data-theme='dark'] .ant-menu-inline .ant-menu-selected::after,
html[data-theme='dark'] .ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-menu-inline .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
html[data-theme='dark'] .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
html[data-theme='dark'] .ant-menu-inline-collapsed {
  width: 80px;
}
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item .anticon,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
html[data-theme='dark'] .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
html[data-theme='dark'] .ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
html[data-theme='dark'] .ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
html[data-theme='dark'] .ant-menu-inline-collapsed-tooltip a {
  color: rgba(25, 25, 52, 0.85);
}
html[data-theme='dark'] .ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
html[data-theme='dark'] .ant-menu-item-group-list .ant-menu-item,
html[data-theme='dark'] .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
html[data-theme='dark'] .ant-menu-root.ant-menu-vertical,
html[data-theme='dark'] .ant-menu-root.ant-menu-vertical-left,
html[data-theme='dark'] .ant-menu-root.ant-menu-vertical-right,
html[data-theme='dark'] .ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
html[data-theme='dark'] .ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-menu-sub.ant-menu-inline > .ant-menu-item,
html[data-theme='dark'] .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
html[data-theme='dark'] .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
html[data-theme='dark'] .ant-menu-item-disabled,
html[data-theme='dark'] .ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-menu-item-disabled > a,
html[data-theme='dark'] .ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
html[data-theme='dark'] .ant-menu-item-disabled > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
html[data-theme='dark'] .ant-menu-dark,
html[data-theme='dark'] .ant-menu-dark .ant-menu-sub {
  color: rgba(25, 25, 52, 0.65);
  background: #001529;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #191934;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-group-title,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item > a {
  color: rgba(25, 25, 52, 0.65);
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-inline,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-left,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-inline .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
html[data-theme='dark'] .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-dark.ant-menu-inline .ant-menu-item,
html[data-theme='dark'] .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #191934;
  background-color: transparent;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #191934;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #191934;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-selected {
  color: #191934;
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-selected > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #191934;
}
html[data-theme='dark'] .ant-menu.ant-menu-dark .ant-menu-item-selected,
html[data-theme='dark'] .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-disabled,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-disabled,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-disabled > a,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(25, 25, 52, 0.35) !important;
  opacity: 0.8;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(25, 25, 52, 0.35) !important;
}
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
html[data-theme='dark'] .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
html[data-theme='dark'] .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(25, 25, 52, 0.35) !important;
}
html[data-theme='dark'] .ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
html[data-theme='dark'] .ant-message-notice {
  padding: 8px;
  text-align: center;
}
html[data-theme='dark'] .ant-message-notice:first-child {
  margin-top: -8px;
}
html[data-theme='dark'] .ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #191934;
  border-radius: 2px;
  box-shadow: none;
  pointer-events: all;
}
html[data-theme='dark'] .ant-message-success .anticon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-message-error .anticon {
  color: #ff376b;
}
html[data-theme='dark'] .ant-message-warning .anticon {
  color: #f0f3f6;
}
html[data-theme='dark'] .ant-message-info .anticon,
html[data-theme='dark'] .ant-message-loading .anticon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
}
html[data-theme='dark'] .ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
html[data-theme='dark'] .ant-modal-title {
  margin: 0;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
html[data-theme='dark'] .ant-modal-content {
  position: relative;
  background-color: #191934;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
html[data-theme='dark'] .ant-modal-close:focus,
html[data-theme='dark'] .ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
html[data-theme='dark'] .ant-modal-header {
  padding: 16px 24px;
  color: #e4e8ee;
  background: #191934;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
html[data-theme='dark'] .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: tranparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
html[data-theme='dark'] .ant-modal.zoom-enter,
html[data-theme='dark'] .ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
html[data-theme='dark'] .ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(228, 232, 238, 0.5);
  filter: alpha(opacity=50);
}
html[data-theme='dark'] .ant-modal-mask-hidden {
  display: none;
}
html[data-theme='dark'] .ant-modal-open {
  overflow: hidden;
}
html[data-theme='dark'] .ant-modal-centered {
  text-align: center;
}
html[data-theme='dark'] .ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
html[data-theme='dark'] .ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  html[data-theme='dark'] .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
html[data-theme='dark'] .ant-modal-confirm .ant-modal-header {
  display: none;
}
html[data-theme='dark'] .ant-modal-confirm .ant-modal-close {
  display: none;
}
html[data-theme='dark'] .ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
html[data-theme='dark'] .ant-modal-confirm-body-wrapper {
  zoom: 1;
}
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::before,
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::after {
  clear: both;
}
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::before,
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-modal-confirm-body-wrapper::after {
  clear: both;
}
html[data-theme='dark'] .ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #e4e8ee;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
html[data-theme='dark'] .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #e4e8ee;
  font-size: 14px;
}
html[data-theme='dark'] .ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
html[data-theme='dark'] .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
html[data-theme='dark'] .ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
html[data-theme='dark'] .ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
html[data-theme='dark'] .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff376b;
}
html[data-theme='dark'] .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
html[data-theme='dark'] .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f0f3f6;
}
html[data-theme='dark'] .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
html[data-theme='dark'] .ant-notification-topLeft,
html[data-theme='dark'] .ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
html[data-theme='dark'] .ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='dark'] .ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='dark'] .ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
html[data-theme='dark'] .ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
html[data-theme='dark'] .ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #191934;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: #e4e8ee;
  font-size: 16px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
html[data-theme='dark'] .ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
html[data-theme='dark'] .ant-notification-notice-description {
  font-size: 14px;
}
html[data-theme='dark'] .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
html[data-theme='dark'] .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
html[data-theme='dark'] .ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .anticon.ant-notification-notice-icon-success {
  color: #3851d1;
}
html[data-theme='dark'] .anticon.ant-notification-notice-icon-info {
  color: #3851d1;
}
html[data-theme='dark'] .anticon.ant-notification-notice-icon-warning {
  color: #f0f3f6;
}
html[data-theme='dark'] .anticon.ant-notification-notice-icon-error {
  color: #ff376b;
}
html[data-theme='dark'] .ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
html[data-theme='dark'] .ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
html[data-theme='dark'] .ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
html[data-theme='dark'] .ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
html[data-theme='dark'] .ant-notification-fade-enter,
html[data-theme='dark'] .ant-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
html[data-theme='dark'] .ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
html[data-theme='dark'] .ant-notification-fade-enter.ant-notification-fade-enter-active,
html[data-theme='dark'] .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
html[data-theme='dark'] .ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background: #191934;
}
html[data-theme='dark'] .ant-page-header.ant-page-header-has-footer {
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-page-header-back {
  display: inline-block;
  padding: 4px 0;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;
}
html[data-theme='dark'] .ant-page-header-back-button {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-page-header-back-button:focus,
html[data-theme='dark'] .ant-page-header-back-button:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-page-header-back-button:active {
  color: #b5bcc7;
}
html[data-theme='dark'] .ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
}
html[data-theme='dark'] .ant-page-header .ant-breadcrumb {
  margin-bottom: 12px;
}
html[data-theme='dark'] .ant-page-header-title-view {
  display: inline-block;
}
html[data-theme='dark'] .ant-page-header-title-view-title {
  display: inline-block;
  padding-right: 12px;
  color: #e4e8ee;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
}
html[data-theme='dark'] .ant-page-header-title-view-sub-title {
  display: inline-block;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.8;
}
html[data-theme='dark'] .ant-page-header-title-view-tags {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='dark'] .ant-page-header-title-view-extra {
  position: absolute;
  top: 16px;
  right: 24px;
}
html[data-theme='dark'] .ant-page-header-title-view-extra > * {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-page-header-title-view-extra > *:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-page-header-content-view {
  padding-top: 12px;
}
html[data-theme='dark'] .ant-page-header-footer {
  margin: 0 -8px;
  padding-top: 24px;
}
html[data-theme='dark'] .ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
html[data-theme='dark'] .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 8px;
  padding-top: 0;
}
html[data-theme='dark'] .ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-pagination ul,
html[data-theme='dark'] .ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
html[data-theme='dark'] .ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #191934;
  border: 1px solid #090912;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
html[data-theme='dark'] .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #e4e8ee;
  transition: none;
}
html[data-theme='dark'] .ant-pagination-item:focus,
html[data-theme='dark'] .ant-pagination-item:hover {
  border-color: #3851d1;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-pagination-item:focus a,
html[data-theme='dark'] .ant-pagination-item:hover a {
  color: #3851d1;
}
html[data-theme='dark'] .ant-pagination-item-active {
  font-weight: 500;
  background: transparent;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-pagination-item-active a {
  color: #3851d1;
}
html[data-theme='dark'] .ant-pagination-item-active:focus,
html[data-theme='dark'] .ant-pagination-item-active:hover {
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-pagination-item-active:focus a,
html[data-theme='dark'] .ant-pagination-item-active:hover a {
  color: #5f79de;
}
html[data-theme='dark'] .ant-pagination-jump-prev,
html[data-theme='dark'] .ant-pagination-jump-next {
  outline: 0;
}
html[data-theme='dark'] .ant-pagination-jump-prev .ant-pagination-item-container,
html[data-theme='dark'] .ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
html[data-theme='dark'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  color: #3851d1;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
html[data-theme='dark'] :root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
html[data-theme='dark'] :root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
html[data-theme='dark'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
html[data-theme='dark'] .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
html[data-theme='dark'] .ant-pagination-prev,
html[data-theme='dark'] .ant-pagination-jump-prev,
html[data-theme='dark'] .ant-pagination-jump-next {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-pagination-prev,
html[data-theme='dark'] .ant-pagination-next,
html[data-theme='dark'] .ant-pagination-jump-prev,
html[data-theme='dark'] .ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #e4e8ee;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-pagination-prev,
html[data-theme='dark'] .ant-pagination-next {
  outline: 0;
}
html[data-theme='dark'] .ant-pagination-prev a,
html[data-theme='dark'] .ant-pagination-next a {
  color: #e4e8ee;
  user-select: none;
}
html[data-theme='dark'] .ant-pagination-prev:hover a,
html[data-theme='dark'] .ant-pagination-next:hover a {
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #191934;
  border: 1px solid #090912;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-pagination-prev:focus .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-next:focus .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-prev:hover .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-next:hover .ant-pagination-item-link {
  color: #3851d1;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-pagination-disabled,
html[data-theme='dark'] .ant-pagination-disabled:hover,
html[data-theme='dark'] .ant-pagination-disabled:focus {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination-disabled a,
html[data-theme='dark'] .ant-pagination-disabled:hover a,
html[data-theme='dark'] .ant-pagination-disabled:focus a,
html[data-theme='dark'] .ant-pagination-disabled .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-disabled:hover .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #090912;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination-slash {
  margin: 0 10px 0 5px;
}
html[data-theme='dark'] .ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-pagination-options-quick-jumper input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-prev,
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #191934;
  border: 1px solid #090912;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
html[data-theme='dark'] .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-total-text,
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-prev,
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-jump-prev,
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 30px;
  padding: 1px 7px;
  width: 44px;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #090912;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(0, 0, 0, 0.45);
  background: #f5f5f5;
  border-color: #090912;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
html[data-theme='dark'] .ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  html[data-theme='dark'] .ant-pagination-item-after-jump-prev,
  html[data-theme='dark'] .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  html[data-theme='dark'] .ant-pagination-options {
    display: none;
  }
}
html[data-theme='dark'] .ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
html[data-theme='dark'] .ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
html[data-theme='dark'] .ant-popover-hidden {
  display: none;
}
html[data-theme='dark'] .ant-popover-placement-top,
html[data-theme='dark'] .ant-popover-placement-topLeft,
html[data-theme='dark'] .ant-popover-placement-topRight {
  padding-bottom: 10px;
}
html[data-theme='dark'] .ant-popover-placement-right,
html[data-theme='dark'] .ant-popover-placement-rightTop,
html[data-theme='dark'] .ant-popover-placement-rightBottom {
  padding-left: 10px;
}
html[data-theme='dark'] .ant-popover-placement-bottom,
html[data-theme='dark'] .ant-popover-placement-bottomLeft,
html[data-theme='dark'] .ant-popover-placement-bottomRight {
  padding-top: 10px;
}
html[data-theme='dark'] .ant-popover-placement-left,
html[data-theme='dark'] .ant-popover-placement-leftTop,
html[data-theme='dark'] .ant-popover-placement-leftBottom {
  padding-right: 10px;
}
html[data-theme='dark'] .ant-popover-inner {
  background-color: #191934;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: none;
  box-shadow: 0 0 8px none \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-theme='dark'] .ant-popover {
    /* IE10+ */
  }
  html[data-theme='dark'] .ant-popover-inner {
    box-shadow: none;
  }
}
html[data-theme='dark'] .ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #e4e8ee;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-popover-inner-content {
  padding: 12px 16px;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #e4e8ee;
  font-size: 14px;
}
html[data-theme='dark'] .ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #f0f3f6;
  font-size: 14px;
}
html[data-theme='dark'] .ant-popover-message-title {
  padding-left: 22px;
}
html[data-theme='dark'] .ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
html[data-theme='dark'] .ant-popover-buttons button {
  margin-left: 8px;
}
html[data-theme='dark'] .ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
html[data-theme='dark'] .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #191934;
  border-bottom-color: #191934;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='dark'] .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
html[data-theme='dark'] .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
html[data-theme='dark'] .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
html[data-theme='dark'] .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #191934;
  border-left-color: #191934;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='dark'] .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
html[data-theme='dark'] .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
html[data-theme='dark'] .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
html[data-theme='dark'] .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #191934;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #191934;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
html[data-theme='dark'] .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
html[data-theme='dark'] .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
html[data-theme='dark'] .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
html[data-theme='dark'] .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
html[data-theme='dark'] .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #191934;
  border-right-color: #191934;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
html[data-theme='dark'] .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
html[data-theme='dark'] .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
html[data-theme='dark'] .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
html[data-theme='dark'] .ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='dark'] .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
html[data-theme='dark'] .ant-progress-small.ant-progress-line,
html[data-theme='dark'] .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
html[data-theme='dark'] .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
html[data-theme='dark'] .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
html[data-theme='dark'] .ant-progress-circle-trail {
  stroke: #f5f5f5;
}
html[data-theme='dark'] .ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
  stroke: #3851d1;
}
html[data-theme='dark'] .ant-progress-success-bg,
html[data-theme='dark'] .ant-progress-bg {
  position: relative;
  background-color: #3851d1;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
html[data-theme='dark'] .ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
html[data-theme='dark'] .ant-progress-text .anticon {
  font-size: 14px;
}
html[data-theme='dark'] .ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #191934;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
html[data-theme='dark'] .ant-progress-status-exception .ant-progress-bg {
  background-color: #ff376b;
}
html[data-theme='dark'] .ant-progress-status-exception .ant-progress-text {
  color: #ff376b;
}
html[data-theme='dark'] .ant-progress-status-exception .ant-progress-circle-path {
  stroke: #ff376b;
}
html[data-theme='dark'] .ant-progress-status-success .ant-progress-bg {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-progress-status-success .ant-progress-text {
  color: #3851d1;
}
html[data-theme='dark'] .ant-progress-status-success .ant-progress-circle-path {
  stroke: #3851d1;
}
html[data-theme='dark'] .ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
html[data-theme='dark'] .ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
html[data-theme='dark'] .ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
html[data-theme='dark'] .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff376b;
}
html[data-theme='dark'] .ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #3851d1;
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
html[data-theme='dark'] .ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
}
html[data-theme='dark'] .ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
html[data-theme='dark'] .ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-radio-wrapper:hover .ant-radio,
html[data-theme='dark'] .ant-radio:hover .ant-radio-inner,
html[data-theme='dark'] .ant-radio-input:focus + .ant-radio-inner {
  border-color: #e4e8ee;
}
html[data-theme='dark'] .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(228, 232, 238, 0.08);
}
html[data-theme='dark'] .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #e4e8ee;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='dark'] .ant-radio:hover::after,
html[data-theme='dark'] .ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
html[data-theme='dark'] .ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #191934;
  border-color: #090912;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #e4e8ee;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
html[data-theme='dark'] .ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='dark'] .ant-radio-checked .ant-radio-inner {
  border-color: #e4e8ee;
}
html[data-theme='dark'] .ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #090912 !important;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
html[data-theme='dark'] .ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 45px;
  margin: 0;
  padding: 0 15px;
  color: #e4e8ee;
  line-height: 43px;
  background: #191934;
  border: 1px solid #090912;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
html[data-theme='dark'] .ant-radio-button-wrapper a {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
html[data-theme='dark'] .ant-radio-group-large .ant-radio-button-wrapper {
  height: 30px;
  font-size: 16px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-radio-group-small .ant-radio-button-wrapper {
  height: 30px;
  padding: 0 7px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #090912;
  content: '';
}
html[data-theme='dark'] .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #090912;
  border-radius: 2px 0 0 2px;
}
html[data-theme='dark'] .ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
html[data-theme='dark'] .ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
html[data-theme='dark'] .ant-radio-button-wrapper:hover {
  position: relative;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(228, 232, 238, 0.06);
}
html[data-theme='dark'] .ant-radio-button-wrapper .ant-radio-inner,
html[data-theme='dark'] .ant-radio-button-wrapper input[type='checkbox'],
html[data-theme='dark'] .ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #e4e8ee;
  background: #191934;
  border-color: #e4e8ee;
  box-shadow: -1px 0 0 0 #e4e8ee;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked::before {
  background-color: #e4e8ee !important;
  opacity: 0.1;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked:first-child {
  border-color: #e4e8ee;
  box-shadow: none !important;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked:hover {
  color: #5f79de;
  border-color: #5f79de;
  box-shadow: -1px 0 0 0 #5f79de;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked:active {
  color: #2637ab;
  border-color: #2637ab;
  box-shadow: -1px 0 0 0 #2637ab;
}
html[data-theme='dark'] .ant-radio-button-wrapper-checked:focus-within {
  outline: 3px solid rgba(228, 232, 238, 0.06);
}
html[data-theme='dark'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #191934;
  background: #e4e8ee;
  border-color: #e4e8ee;
}
html[data-theme='dark'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #191934;
  background: #5f79de;
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #191934;
  background: #2637ab;
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(228, 232, 238, 0.06);
}
html[data-theme='dark'] .ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-radio-button-wrapper-disabled:first-child,
html[data-theme='dark'] .ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #090912;
}
html[data-theme='dark'] .ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #090912;
}
html[data-theme='dark'] .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #191934;
  background-color: #e6e6e6;
  border-color: #090912;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  html[data-theme='dark'] .ant-radio {
    vertical-align: text-bottom;
  }
}
html[data-theme='dark'] .ant-rate {
  box-sizing: border-box;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
html[data-theme='dark'] .ant-rate-disabled .ant-rate-star {
  cursor: default;
}
html[data-theme='dark'] .ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
html[data-theme='dark'] .ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-right: 8px;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-rate-star > div:focus {
  outline: 0;
}
html[data-theme='dark'] .ant-rate-star > div:hover,
html[data-theme='dark'] .ant-rate-star > div:focus {
  transform: scale(1.1);
}
html[data-theme='dark'] .ant-rate-star-first,
html[data-theme='dark'] .ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='dark'] .ant-rate-star-first .anticon,
html[data-theme='dark'] .ant-rate-star-second .anticon {
  vertical-align: middle;
}
html[data-theme='dark'] .ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
html[data-theme='dark'] .ant-rate-star-half .ant-rate-star-first,
html[data-theme='dark'] .ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
html[data-theme='dark'] .ant-rate-star-half .ant-rate-star-first,
html[data-theme='dark'] .ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
html[data-theme='dark'] .ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
html[data-theme='dark'] .ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
}
html[data-theme='dark'] .ant-select ul,
html[data-theme='dark'] .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-select > ul > li > a {
  padding: 0;
  background-color: #191934;
}
html[data-theme='dark'] .ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}
html[data-theme='dark'] .ant-select-arrow > * {
  line-height: 1;
}
html[data-theme='dark'] .ant-select-arrow svg {
  display: inline-block;
}
html[data-theme='dark'] .ant-select-arrow::before {
  display: none;
}
html[data-theme='dark'] .ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
html[data-theme='dark'] .ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #191934;
  border: 1px solid red;
  border-top-width: 1.02px;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}
html[data-theme='dark'] .ant-select-selection:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-select-focused .ant-select-selection,
html[data-theme='dark'] .ant-select-selection:focus,
html[data-theme='dark'] .ant-select-selection:active {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #191934;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
html[data-theme='dark'] .ant-select-selection__clear::before {
  display: block;
}
html[data-theme='dark'] .ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
html[data-theme='dark'] .ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
html[data-theme='dark'] .ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection:hover,
html[data-theme='dark'] .ant-select-disabled .ant-select-selection:focus,
html[data-theme='dark'] .ant-select-disabled .ant-select-selection:active {
  border-color: red;
  box-shadow: none;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
html[data-theme='dark'] .ant-select-selection--single {
  position: relative;
  height: 30px;
  cursor: pointer;
}
html[data-theme='dark'] .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
html[data-theme='dark'] .ant-select-lg {
  font-size: 16px;
}
html[data-theme='dark'] .ant-select-lg .ant-select-selection--single {
  height: 30px;
}
html[data-theme='dark'] .ant-select-lg .ant-select-selection__rendered {
  line-height: 28px;
}
html[data-theme='dark'] .ant-select-lg .ant-select-selection--multiple {
  min-height: 30px;
}
html[data-theme='dark'] .ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 22px;
  line-height: 22px;
}
html[data-theme='dark'] .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='dark'] .ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection--single {
  height: 30px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection__rendered {
  margin: 0 7px;
  line-height: 28px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection--multiple {
  min-height: 30px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 22px;
  line-height: 20px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='dark'] .ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='dark'] .ant-select-sm .ant-select-selection__clear,
html[data-theme='dark'] .ant-select-sm .ant-select-arrow {
  right: 8px;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
html[data-theme='dark'] .ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
html[data-theme='dark'] .ant-select-selection__placeholder,
html[data-theme='dark'] .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #ffffff;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-select-search__field__placeholder {
  left: 12px;
}
html[data-theme='dark'] .ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 2px;
  outline: 0;
}
html[data-theme='dark'] .ant-select-search--inline > i {
  float: right;
}
html[data-theme='dark'] .ant-select-selection--multiple {
  min-height: 30px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
html[data-theme='dark'] .ant-select-selection--multiple::before,
html[data-theme='dark'] .ant-select-selection--multiple::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-select-selection--multiple::after {
  clear: both;
}
html[data-theme='dark'] .ant-select-selection--multiple::before,
html[data-theme='dark'] .ant-select-selection--multiple::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-select-selection--multiple::after {
  clear: both;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
html[data-theme='dark'] .ant-select-selection--multiple > ul > li,
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 22px;
  margin-top: 3px;
  line-height: 20px;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: #e4e8ee;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
html[data-theme='dark'] :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-selection__clear,
html[data-theme='dark'] .ant-select-selection--multiple .ant-select-arrow {
  top: 15px;
}
html[data-theme='dark'] .ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
html[data-theme='dark'] .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
html[data-theme='dark'] .ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
html[data-theme='dark'] .ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}
html[data-theme='dark'] .ant-select-open .ant-select-selection {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-select-combobox .ant-select-arrow {
  display: none;
}
html[data-theme='dark'] .ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
html[data-theme='dark'] .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
html[data-theme='dark'] .ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
html[data-theme='dark'] .ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #191934;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
html[data-theme='dark'] .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
html[data-theme='dark'] .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
html[data-theme='dark'] .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
html[data-theme='dark'] .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
html[data-theme='dark'] .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
html[data-theme='dark'] .ant-select-dropdown-hidden {
  display: none;
}
html[data-theme='dark'] .ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
html[data-theme='dark'] .ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #e4e8ee;
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-selected {
  color: #e4e8ee;
  font-weight: 600;
  background-color: #fafafa;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  transform: translateY(-50%);
  transition: all 0.2s;
}
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
html[data-theme='dark'] .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #3851d1;
}
html[data-theme='dark'] .ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
html[data-theme='dark'] .ant-select-dropdown-container-open .ant-select-dropdown,
html[data-theme='dark'] .ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
html[data-theme='dark'] .ant-skeleton {
  display: table;
  width: 100%;
}
html[data-theme='dark'] .ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
html[data-theme='dark'] .ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
html[data-theme='dark'] .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
html[data-theme='dark'] .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
html[data-theme='dark'] .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
html[data-theme='dark'] .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
html[data-theme='dark'] .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
html[data-theme='dark'] .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
html[data-theme='dark'] .ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 14px 9px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
html[data-theme='dark'] .ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-track {
  width: 4px;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
html[data-theme='dark'] .ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
html[data-theme='dark'] .ant-slider-with-marks {
  margin-bottom: 28px;
}
html[data-theme='dark'] .ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f0f3f6;
  border-radius: 2px;
  transition: background-color 0.3s;
}
html[data-theme='dark'] .ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #3851d1;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}
html[data-theme='dark'] .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  margin-left: -7px;
  background-color: #191934;
  border: solid 2px #3851d1;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
html[data-theme='dark'] .ant-slider-handle:focus {
  border-color: #6074da;
  outline: none;
  box-shadow: 0 0 0 5px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-slider-handle.ant-tooltip-open {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-slider:hover .ant-slider-rail {
  background-color: #f0f3f6;
}
html[data-theme='dark'] .ant-slider:hover .ant-slider-track {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #8aa1eb;
}
html[data-theme='dark'] .ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
html[data-theme='dark'] .ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
html[data-theme='dark'] .ant-slider-mark-text-active {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
html[data-theme='dark'] .ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #191934;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
html[data-theme='dark'] .ant-slider-dot:first-child {
  margin-left: -4px;
}
html[data-theme='dark'] .ant-slider-dot:last-child {
  margin-left: -4px;
}
html[data-theme='dark'] .ant-slider-dot-active {
  border-color: #9ca8e8;
}
html[data-theme='dark'] .ant-slider-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
html[data-theme='dark'] .ant-slider-disabled .ant-slider-handle,
html[data-theme='dark'] .ant-slider-disabled .ant-slider-dot {
  background-color: #191934;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-slider-disabled .ant-slider-mark-text,
html[data-theme='dark'] .ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
html[data-theme='dark'] .ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #3851d1;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
html[data-theme='dark'] .ant-spin-nested-loading {
  position: relative;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px none;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
html[data-theme='dark'] .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
html[data-theme='dark'] .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
html[data-theme='dark'] .ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #191934;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
html[data-theme='dark'] .ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
html[data-theme='dark'] .ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
html[data-theme='dark'] .ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
html[data-theme='dark'] .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #3851d1;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
html[data-theme='dark'] .ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
html[data-theme='dark'] .ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
html[data-theme='dark'] .ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
html[data-theme='dark'] .ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
html[data-theme='dark'] .ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
html[data-theme='dark'] .ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
html[data-theme='dark'] .ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
html[data-theme='dark'] .ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
html[data-theme='dark'] .ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
html[data-theme='dark'] .ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html[data-theme='dark'] {
    /* IE10+ */
  }
  html[data-theme='dark'] .ant-spin-blur {
    background: #191934;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
html[data-theme='dark'] .ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-statistic-content {
  color: #e4e8ee;
  font-size: 24px;
  font-family: Tahoma, 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='dark'] .ant-statistic-content-value-decimal {
  font-size: 16px;
}
html[data-theme='dark'] .ant-statistic-content-prefix,
html[data-theme='dark'] .ant-statistic-content-suffix {
  display: inline-block;
}
html[data-theme='dark'] .ant-statistic-content-prefix {
  margin-right: 4px;
}
html[data-theme='dark'] .ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
}
html[data-theme='dark'] .ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
html[data-theme='dark'] .ant-steps-item:last-child {
  flex: none;
}
html[data-theme='dark'] .ant-steps-item:last-child > .ant-steps-item-tail,
html[data-theme='dark'] .ant-steps-item:last-child > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
html[data-theme='dark'] .ant-steps-item-icon,
html[data-theme='dark'] .ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='dark'] .ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
html[data-theme='dark'] .ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #3851d1;
  line-height: 1;
}
html[data-theme='dark'] .ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
html[data-theme='dark'] .ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
html[data-theme='dark'] .ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #e4e8ee;
  font-size: 16px;
  line-height: 32px;
}
html[data-theme='dark'] .ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
html[data-theme='dark'] .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #191934;
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-steps-item-wait > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-icon {
  background-color: #191934;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-steps-item-process > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-icon {
  background: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #191934;
}
html[data-theme='dark'] .ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
html[data-theme='dark'] .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #191934;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item-error .ant-steps-item-icon {
  background-color: #191934;
  border-color: #ff376b;
}
html[data-theme='dark'] .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff376b;
}
html[data-theme='dark'] .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff376b;
}
html[data-theme='dark'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff376b;
}
html[data-theme='dark'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff376b;
}
html[data-theme='dark'] .ant-steps-item-error > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
html[data-theme='dark'] .ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff376b;
}
html[data-theme='dark'] .ant-steps-item[role='button'] {
  outline: none;
}
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process) {
  cursor: pointer;
}
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-title,
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-description,
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process) .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-title,
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-description {
  color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-icon {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-steps-item[role='button']:not(.ant-steps-item-process):hover .ant-steps-item-icon .ant-steps-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
html[data-theme='dark'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
html[data-theme='dark'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
html[data-theme='dark'] .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
html[data-theme='dark'] .ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
html[data-theme='dark'] .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
html[data-theme='dark'] .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
html[data-theme='dark'] .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
html[data-theme='dark'] .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-tail {
  top: 8px;
  padding: 0 8px;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
html[data-theme='dark'] .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
html[data-theme='dark'] .ant-steps-vertical {
  display: block;
}
html[data-theme='dark'] .ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
html[data-theme='dark'] .ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
html[data-theme='dark'] .ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
html[data-theme='dark'] .ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
html[data-theme='dark'] .ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
html[data-theme='dark'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
html[data-theme='dark'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
html[data-theme='dark'] .ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
  display: block;
}
html[data-theme='dark'] .ant-steps-vertical > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-small .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-tail {
    display: block;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  html[data-theme='dark'] .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 51px;
  padding: 3.5px 24px;
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 104px;
  margin-top: 8px;
  text-align: center;
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
html[data-theme='dark'] .ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
html[data-theme='dark'] .ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 40px;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-title {
  line-height: 1.5;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-content {
  width: 140px;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
html[data-theme='dark'] .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
html[data-theme='dark'] .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #191934;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #191934;
  content: '';
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
html[data-theme='dark'] .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}
html[data-theme='dark'] .ant-switch {
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 20px;
  line-height: 18px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
html[data-theme='dark'] .ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #191934;
  font-size: 12px;
}
html[data-theme='dark'] .ant-switch-loading-icon,
html[data-theme='dark'] .ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  background-color: #191934;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
html[data-theme='dark'] .ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
html[data-theme='dark'] .ant-switch:not(.ant-switch-disabled):active::before,
html[data-theme='dark'] .ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
html[data-theme='dark'] .ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
html[data-theme='dark'] .ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #3851d1;
}
html[data-theme='dark'] .ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-switch:focus:hover {
  box-shadow: none;
}
html[data-theme='dark'] .ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
html[data-theme='dark'] .ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
html[data-theme='dark'] .ant-switch-small::after {
  width: 12px;
  height: 12px;
}
html[data-theme='dark'] .ant-switch-small:active::before,
html[data-theme='dark'] .ant-switch-small:active::after {
  width: 16px;
}
html[data-theme='dark'] .ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
html[data-theme='dark'] .ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
html[data-theme='dark'] .ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
html[data-theme='dark'] .ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  transform: scale(0.66667);
}
html[data-theme='dark'] .ant-switch-checked {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
html[data-theme='dark'] .ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  transform: translateX(-100%);
}
html[data-theme='dark'] .ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
html[data-theme='dark'] .ant-switch-loading,
html[data-theme='dark'] .ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
html[data-theme='dark'] .ant-switch-loading *,
html[data-theme='dark'] .ant-switch-disabled * {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-switch-loading::before,
html[data-theme='dark'] .ant-switch-disabled::before,
html[data-theme='dark'] .ant-switch-loading::after,
html[data-theme='dark'] .ant-switch-disabled::after {
  cursor: not-allowed;
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}
html[data-theme='dark'] .ant-table-wrapper {
  zoom: 1;
}
html[data-theme='dark'] .ant-table-wrapper::before,
html[data-theme='dark'] .ant-table-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-table-wrapper::after {
  clear: both;
}
html[data-theme='dark'] .ant-table-wrapper::before,
html[data-theme='dark'] .ant-table-wrapper::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-table-wrapper::after {
  clear: both;
}
html[data-theme='dark'] .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  clear: both;
}
html[data-theme='dark'] .ant-table-body {
  transition: opacity 0.3s;
}
html[data-theme='dark'] .ant-table-empty .ant-table-body {
  overflow: auto !important;
}
html[data-theme='dark'] .ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: collapse;
}
html[data-theme='dark'] .ant-table-thead > tr {
  background: #fafafa;
}
html[data-theme='dark'] .ant-table-thead > tr > th {
  color: #586579;
  font-weight: 500;
  text-align: left;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
html[data-theme='dark'] .ant-table-thead > tr > th[colspan] {
  text-align: center;
}
html[data-theme='dark'] .ant-table-thead > tr > th .anticon-filter,
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-table-thead > tr > th .anticon-filter > svg,
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #3851d1;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
html[data-theme='dark'] :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
html[data-theme='dark'] :root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #3851d1;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  vertical-align: top;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
html[data-theme='dark'] .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-has-sorters {
  user-select: none;
}
html[data-theme='dark'] .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 2px;
}
html[data-theme='dark'] .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 2px;
}
html[data-theme='dark'] .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
html[data-theme='dark'] .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
html[data-theme='dark'] .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
html[data-theme='dark'] .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
html[data-theme='dark'] .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
html[data-theme='dark'] .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-table-footer {
  position: relative;
  padding: 16px 16px;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
html[data-theme='dark'] .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-title + .ant-table-content {
  position: relative;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-table-bordered .ant-table-title + .ant-table-content,
html[data-theme='dark'] .ant-table-bordered .ant-table-title + .ant-table-content table,
html[data-theme='dark'] .ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
html[data-theme='dark'] .ant-table-without-column-header .ant-table-title + .ant-table-content,
html[data-theme='dark'] .ant-table-without-column-header table {
  border-radius: 0;
}
html[data-theme='dark'] .ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
html[data-theme='dark'] .ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
html[data-theme='dark'] .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-tbody > tr > td {
  padding: 16px 16px;
}
html[data-theme='dark'] .ant-table-expand-icon-th,
html[data-theme='dark'] .ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
html[data-theme='dark'] .ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
html[data-theme='dark'] .ant-table-header table {
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-table-loading {
  position: relative;
}
html[data-theme='dark'] .ant-table-loading .ant-table-body {
  background: #191934;
  opacity: 0.5;
}
html[data-theme='dark'] .ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
html[data-theme='dark'] .ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
html[data-theme='dark'] .ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
html[data-theme='dark'] .ant-table-bordered .ant-table-header > table,
html[data-theme='dark'] .ant-table-bordered .ant-table-body > table,
html[data-theme='dark'] .ant-table-bordered .ant-table-fixed-left table,
html[data-theme='dark'] .ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
html[data-theme='dark'] .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
html[data-theme='dark'] .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
html[data-theme='dark'] .ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
html[data-theme='dark'] .ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
html[data-theme='dark'] .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-bordered .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-placeholder {
  position: relative;
  z-index: 1;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #191934;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-table-placeholder .anticon {
  margin-right: 4px;
}
html[data-theme='dark'] .ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
html[data-theme='dark'] .ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #191934;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  border-radius: 2px 2px 0 0;
  box-shadow: none;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #3851d1;
  font-weight: bold;
  text-shadow: 0 0 2px #e8efff;
}
html[data-theme='dark'] .ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
html[data-theme='dark'] .ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
html[data-theme='dark'] .ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
html[data-theme='dark'] .ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-filter-dropdown-link {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-table-filter-dropdown-link:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-table-filter-dropdown-link:active {
  color: #b5bcc7;
}
html[data-theme='dark'] .ant-table-filter-dropdown-link.confirm {
  float: left;
}
html[data-theme='dark'] .ant-table-filter-dropdown-link.clear {
  float: right;
}
html[data-theme='dark'] .ant-table-selection {
  white-space: nowrap;
}
html[data-theme='dark'] .ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
html[data-theme='dark'] .ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #191934;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
html[data-theme='dark'] .ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
html[data-theme='dark'] .ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
html[data-theme='dark'] .ant-table-row-expand-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 14px;
  text-align: center;
  background: #191934;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  user-select: none;
}
html[data-theme='dark'] .ant-table-row-expanded::after {
  content: '-';
}
html[data-theme='dark'] .ant-table-row-collapsed::after {
  content: '+';
}
html[data-theme='dark'] .ant-table-row-spaced {
  visibility: hidden;
}
html[data-theme='dark'] .ant-table-row-spaced::after {
  content: '.';
}
html[data-theme='dark'] tr.ant-table-expanded-row,
html[data-theme='dark'] tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
html[data-theme='dark'] tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
html[data-theme='dark'] .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
html[data-theme='dark'] .ant-table-scroll table {
  width: auto;
  min-width: 100%;
}
html[data-theme='dark'] .ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
html[data-theme='dark'] .ant-table-body-inner {
  height: 100%;
}
html[data-theme='dark'] .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #191934;
}
html[data-theme='dark'] .ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
html[data-theme='dark'] .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
html[data-theme='dark'] .ant-table-fixed-left,
html[data-theme='dark'] .ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: auto;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
html[data-theme='dark'] .ant-table-fixed-left table,
html[data-theme='dark'] .ant-table-fixed-right table {
  width: auto;
  background: #191934;
}
html[data-theme='dark'] .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
html[data-theme='dark'] .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
html[data-theme='dark'] .ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px none;
}
html[data-theme='dark'] .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
html[data-theme='dark'] .ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
html[data-theme='dark'] .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
html[data-theme='dark'] .ant-table-fixed-left,
html[data-theme='dark'] .ant-table-fixed-left table {
  border-radius: 2px 0 0 0;
}
html[data-theme='dark'] .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
html[data-theme='dark'] .ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px none;
}
html[data-theme='dark'] .ant-table-fixed-right,
html[data-theme='dark'] .ant-table-fixed-right table {
  border-radius: 0 2px 0 0;
}
html[data-theme='dark'] .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
html[data-theme='dark'] .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
html[data-theme='dark'] .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
html[data-theme='dark'] .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
html[data-theme='dark'] .ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-selection-column,
html[data-theme='dark'] .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
html[data-theme='dark'] .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
html[data-theme='dark'] .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
html[data-theme='dark'] .ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
html[data-theme='dark'] .ant-table-middle > .ant-table-title,
html[data-theme='dark'] .ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
html[data-theme='dark'] .ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
html[data-theme='dark'] .ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-table-small > .ant-table-title,
html[data-theme='dark'] .ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
html[data-theme='dark'] .ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
html[data-theme='dark'] .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content .ant-table-header {
  background-color: #191934;
}
html[data-theme='dark'] .ant-table-small > .ant-table-content .ant-table-placeholder,
html[data-theme='dark'] .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered {
  border-right: 0;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 55px;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 55px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 53px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 55px;
  color: #e4e8ee;
  background: #191934;
  border-color: #e8e8e8;
  border-bottom: 1px solid #191934;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
html[data-theme='dark'] .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
html[data-theme='dark'] .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
html[data-theme='dark'] .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
html[data-theme='dark'] .ant-tabs-extra-content {
  line-height: 55px;
}
html[data-theme='dark'] .ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: #e4e8ee;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #e4e8ee;
  border-color: #e4e8ee;
}
html[data-theme='dark'] .ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: auto;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 2px 0 0 2px;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
html[data-theme='dark'] .ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #3851d1;
}
html[data-theme='dark'] .ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  overflow: hidden;
  zoom: 1;
}
html[data-theme='dark'] .ant-tabs::before,
html[data-theme='dark'] .ant-tabs::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-tabs::after {
  clear: both;
}
html[data-theme='dark'] .ant-tabs::before,
html[data-theme='dark'] .ant-tabs::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-tabs::after {
  clear: both;
}
html[data-theme='dark'] .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 2px;
  background-color: #e4e8ee;
  transform-origin: 0 0;
}
html[data-theme='dark'] .ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
html[data-theme='dark'] .ant-tabs-nav-container::before,
html[data-theme='dark'] .ant-tabs-nav-container::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-tabs-nav-container::after {
  clear: both;
}
html[data-theme='dark'] .ant-tabs-nav-container::before,
html[data-theme='dark'] .ant-tabs-nav-container::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-tabs-nav-container::after {
  clear: both;
}
html[data-theme='dark'] .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
html[data-theme='dark'] .ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
html[data-theme='dark'] .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
html[data-theme='dark'] .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs-tab-prev,
html[data-theme='dark'] .ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  pointer-events: none;
}
html[data-theme='dark'] .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='dark'] .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='dark'] .ant-tabs-tab-prev:hover,
html[data-theme='dark'] .ant-tabs-tab-next:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-tabs-tab-prev-icon,
html[data-theme='dark'] .ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, -50%);
}
html[data-theme='dark'] .ant-tabs-tab-prev-icon-target,
html[data-theme='dark'] .ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
html[data-theme='dark'] :root .ant-tabs-tab-prev-icon-target,
html[data-theme='dark'] :root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
html[data-theme='dark'] .ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-tabs-tab-btn-disabled,
html[data-theme='dark'] .ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-tabs-tab-next {
  right: 2px;
}
html[data-theme='dark'] .ant-tabs-tab-prev {
  left: 0;
}
html[data-theme='dark'] :root .ant-tabs-tab-prev {
  filter: none;
}
html[data-theme='dark'] .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
html[data-theme='dark'] .ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
html[data-theme='dark'] .ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs-nav::before,
html[data-theme='dark'] .ant-tabs-nav::after {
  display: table;
  content: ' ';
}
html[data-theme='dark'] .ant-tabs-nav::after {
  clear: both;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 20px 0 0;
  padding: 18px 0;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab:hover {
  color: #ffffff;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab:active {
  color: #ffffff;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab-disabled,
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab-active {
  color: #ffffff;
  font-weight: 500;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-top-content,
html[data-theme='dark'] .ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
html[data-theme='dark'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.45s;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
html[data-theme='dark'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
html[data-theme='dark'] .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
html[data-theme='dark'] .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar-tab-prev,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar-tab-prev,
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar-tab-next,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar-tab-next {
  width: 32px;
  height: 0;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar-tab-prev.ant-tabs-tab-arrow-show,
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar-tab-next.ant-tabs-tab-arrow-show,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar-tab-next.ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: auto;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  bottom: 0;
  width: 100%;
  height: 32px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-content,
html[data-theme='dark'] .ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
html[data-theme='dark'] .ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-tabs-top .ant-tabs-ink-bar-animated,
html[data-theme='dark'] .ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .ant-tabs-left .ant-tabs-ink-bar-animated,
html[data-theme='dark'] .ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html[data-theme='dark'] .no-flex > .ant-tabs-content > .ant-tabs-content-animated,
html[data-theme='dark'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
html[data-theme='dark'] .no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
html[data-theme='dark'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
html[data-theme='dark'] .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='dark'] .ant-tabs-left-content > .ant-tabs-content-animated,
html[data-theme='dark'] .ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
html[data-theme='dark'] .ant-tabs-left-content > .ant-tabs-tabpane-inactive,
html[data-theme='dark'] .ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
html[data-theme='dark'] .ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
html[data-theme='dark'] .ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #090912;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .ant-tag:hover {
  opacity: 0.85;
}
html[data-theme='dark'] .ant-tag,
html[data-theme='dark'] .ant-tag a,
html[data-theme='dark'] .ant-tag a:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
html[data-theme='dark'] .ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] :root .ant-tag .anticon-close {
  font-size: 12px;
}
html[data-theme='dark'] .ant-tag .anticon-close:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-tag-has-color {
  border-color: transparent;
}
html[data-theme='dark'] .ant-tag-has-color,
html[data-theme='dark'] .ant-tag-has-color a,
html[data-theme='dark'] .ant-tag-has-color a:hover,
html[data-theme='dark'] .ant-tag-has-color .anticon-close,
html[data-theme='dark'] .ant-tag-has-color .anticon-close:hover {
  color: #191934;
}
html[data-theme='dark'] .ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
html[data-theme='dark'] .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #3851d1;
}
html[data-theme='dark'] .ant-tag-checkable:active,
html[data-theme='dark'] .ant-tag-checkable-checked {
  color: #191934;
}
html[data-theme='dark'] .ant-tag-checkable-checked {
  background-color: #3851d1;
}
html[data-theme='dark'] .ant-tag-checkable:active {
  background-color: #2637ab;
}
html[data-theme='dark'] .ant-tag-hidden {
  display: none;
}
html[data-theme='dark'] .ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
html[data-theme='dark'] .ant-tag-pink-inverse {
  color: #191934;
  background: #eb2f96;
  border-color: #eb2f96;
}
html[data-theme='dark'] .ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
html[data-theme='dark'] .ant-tag-magenta-inverse {
  color: #191934;
  background: #eb2f96;
  border-color: #eb2f96;
}
html[data-theme='dark'] .ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
html[data-theme='dark'] .ant-tag-red-inverse {
  color: #191934;
  background: #f5222d;
  border-color: #f5222d;
}
html[data-theme='dark'] .ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
html[data-theme='dark'] .ant-tag-volcano-inverse {
  color: #191934;
  background: #fa541c;
  border-color: #fa541c;
}
html[data-theme='dark'] .ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
html[data-theme='dark'] .ant-tag-orange-inverse {
  color: #191934;
  background: #fa8c16;
  border-color: #fa8c16;
}
html[data-theme='dark'] .ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
html[data-theme='dark'] .ant-tag-yellow-inverse {
  color: #191934;
  background: #fadb14;
  border-color: #fadb14;
}
html[data-theme='dark'] .ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
html[data-theme='dark'] .ant-tag-gold-inverse {
  color: #191934;
  background: #faad14;
  border-color: #faad14;
}
html[data-theme='dark'] .ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
html[data-theme='dark'] .ant-tag-cyan-inverse {
  color: #191934;
  background: #13c2c2;
  border-color: #13c2c2;
}
html[data-theme='dark'] .ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
html[data-theme='dark'] .ant-tag-lime-inverse {
  color: #191934;
  background: #a0d911;
  border-color: #a0d911;
}
html[data-theme='dark'] .ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
html[data-theme='dark'] .ant-tag-green-inverse {
  color: #191934;
  background: #52c41a;
  border-color: #52c41a;
}
html[data-theme='dark'] .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
html[data-theme='dark'] .ant-tag-blue-inverse {
  color: #191934;
  background: #1890ff;
  border-color: #1890ff;
}
html[data-theme='dark'] .ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
html[data-theme='dark'] .ant-tag-geekblue-inverse {
  color: #191934;
  background: #2f54eb;
  border-color: #2f54eb;
}
html[data-theme='dark'] .ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
html[data-theme='dark'] .ant-tag-purple-inverse {
  color: #191934;
  background: #722ed1;
  border-color: #722ed1;
}
html[data-theme='dark'] .ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
html[data-theme='dark'] .ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #191934;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}
html[data-theme='dark'] .ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
html[data-theme='dark'] .ant-time-picker-panel-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-input-wrap {
  position: relative;
  box-sizing: border-box;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-time-picker-panel-input-invalid {
  border-color: #ff376b;
}
html[data-theme='dark'] .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
html[data-theme='dark'] .ant-time-picker-panel-select {
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
html[data-theme='dark'] .ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
html[data-theme='dark'] .ant-time-picker-panel-select:last-child {
  border-right: 0;
}
html[data-theme='dark'] .ant-time-picker-panel-select:only-child {
  width: 100%;
}
html[data-theme='dark'] .ant-time-picker-panel-select ul {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
html[data-theme='dark'] .ant-time-picker-panel-select li {
  box-sizing: content-box;
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: background 0.3s;
  user-select: none;
}
html[data-theme='dark'] .ant-time-picker-panel-select li:hover {
  background: #f0f5ff;
}
html[data-theme='dark'] li.ant-time-picker-panel-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
html[data-theme='dark'] li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
html[data-theme='dark'] li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-time-picker-panel-combobox {
  zoom: 1;
}
html[data-theme='dark'] .ant-time-picker-panel-combobox::before,
html[data-theme='dark'] .ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-time-picker-panel-combobox::after {
  clear: both;
}
html[data-theme='dark'] .ant-time-picker-panel-combobox::before,
html[data-theme='dark'] .ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-time-picker-panel-combobox::after {
  clear: both;
}
html[data-theme='dark'] .ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownIn;
}
html[data-theme='dark'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpIn;
}
html[data-theme='dark'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownOut;
}
html[data-theme='dark'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
html[data-theme='dark'] .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpOut;
}
html[data-theme='dark'] .ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
html[data-theme='dark'] .ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 4px 11px;
  color: #e4e8ee;
  font-size: 14px;
  line-height: 1.5;
  background-color: #191934;
  background-image: none;
  border: 1px solid #090912;
  border-radius: 2px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-time-picker-input::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input:-ms-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-time-picker-input::-webkit-input-placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .ant-time-picker-input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input::-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input:-moz-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input:hover {
  border-color: #5f79de;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-time-picker-input:focus {
  border-color: #5f79de;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(56, 81, 209, 0.2);
}
html[data-theme='dark'] .ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input-disabled:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 30px;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
html[data-theme='dark'] .ant-time-picker-input-lg {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-time-picker-input-sm {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
html[data-theme='dark'] .ant-time-picker-input[disabled]:hover {
  border-color: #14151f;
  border-right-width: 1px !important;
}
html[data-theme='dark'] .ant-time-picker-open {
  opacity: 0;
}
html[data-theme='dark'] .ant-time-picker-icon,
html[data-theme='dark'] .ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}
html[data-theme='dark'] .ant-time-picker-icon .ant-time-picker-clock-icon,
html[data-theme='dark'] .ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
html[data-theme='dark'] .ant-time-picker-clear {
  z-index: 2;
  background: #191934;
  opacity: 0;
  pointer-events: none;
}
html[data-theme='dark'] .ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
html[data-theme='dark'] .ant-time-picker-large .ant-time-picker-input {
  height: 30px;
  padding: 6px 11px;
  font-size: 16px;
}
html[data-theme='dark'] .ant-time-picker-small .ant-time-picker-input {
  height: 30px;
  padding: 1px 7px;
}
html[data-theme='dark'] .ant-time-picker-small .ant-time-picker-icon,
html[data-theme='dark'] .ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
html[data-theme='dark'] .ant-timeline {
  box-sizing: border-box;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
html[data-theme='dark'] .ant-timeline-item-tail {
  position: absolute;
  top: 0.75em;
  left: 4px;
  height: 100%;
  border-left: 2px solid #e8e8e8;
}
html[data-theme='dark'] .ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
}
html[data-theme='dark'] .ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
html[data-theme='dark'] .ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #191934;
  border: 2px solid transparent;
  border-radius: 100px;
}
html[data-theme='dark'] .ant-timeline-item-head-blue {
  color: #3851d1;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-timeline-item-head-red {
  color: #ff376b;
  border-color: #ff376b;
}
html[data-theme='dark'] .ant-timeline-item-head-green {
  color: #3851d1;
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
html[data-theme='dark'] .ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
}
html[data-theme='dark'] .ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
html[data-theme='dark'] .ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-head,
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: 50%;
  width: 50%;
  text-align: left;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 50%;
  left: -30px;
  width: 50%;
  margin-right: 18px;
  text-align: right;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: 100%;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  right: 0;
  left: -30px;
  width: 100%;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  display: block;
  border-left: 2px dotted #e8e8e8;
}
html[data-theme='dark'] .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
html[data-theme='dark'] .ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 300px;
  visibility: visible;
}
html[data-theme='dark'] .ant-tooltip-hidden {
  display: none;
}
html[data-theme='dark'] .ant-tooltip-placement-top,
html[data-theme='dark'] .ant-tooltip-placement-topLeft,
html[data-theme='dark'] .ant-tooltip-placement-topRight {
  padding-bottom: 10px;
}
html[data-theme='dark'] .ant-tooltip-placement-right,
html[data-theme='dark'] .ant-tooltip-placement-rightTop,
html[data-theme='dark'] .ant-tooltip-placement-rightBottom {
  padding-left: 10px;
}
html[data-theme='dark'] .ant-tooltip-placement-bottom,
html[data-theme='dark'] .ant-tooltip-placement-bottomLeft,
html[data-theme='dark'] .ant-tooltip-placement-bottomRight {
  padding-top: 10px;
}
html[data-theme='dark'] .ant-tooltip-placement-left,
html[data-theme='dark'] .ant-tooltip-placement-leftTop,
html[data-theme='dark'] .ant-tooltip-placement-leftBottom {
  padding-right: 10px;
}
html[data-theme='dark'] .ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #e4e8ee;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #191934;
  border-radius: 2px;
  box-shadow: none;
}
html[data-theme='dark'] .ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
html[data-theme='dark'] .ant-tooltip-placement-top .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 7px 7px 0;
  border-top-color: #4a4a7a;
}
html[data-theme='dark'] .ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -7px;
}
html[data-theme='dark'] .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 16px;
}
html[data-theme='dark'] .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 16px;
}
html[data-theme='dark'] .ant-tooltip-placement-right .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 7px 7px 7px 0;
  border-right-color: #4a4a7a;
}
html[data-theme='dark'] .ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  margin-top: -7px;
}
html[data-theme='dark'] .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 8px;
}
html[data-theme='dark'] .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 8px;
}
html[data-theme='dark'] .ant-tooltip-placement-left .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 7px 0 7px 7px;
  border-left-color: #4a4a7a;
}
html[data-theme='dark'] .ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  margin-top: -7px;
}
html[data-theme='dark'] .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 8px;
}
html[data-theme='dark'] .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 8px;
}
html[data-theme='dark'] .ant-tooltip-placement-bottom .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
html[data-theme='dark'] .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 7px 7px;
  border-bottom-color: #4a4a7a;
}
html[data-theme='dark'] .ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  margin-left: -7px;
}
html[data-theme='dark'] .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 16px;
}
html[data-theme='dark'] .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 16px;
}
html[data-theme='dark'] .ant-transfer-customize-list {
  display: flex;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
html[data-theme='dark'] .ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
html[data-theme='dark'] .ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
html[data-theme='dark'] .ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
html[data-theme='dark'] .ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #090912;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
html[data-theme='dark'] .ant-transfer-list-search {
  padding: 0 8px;
}
html[data-theme='dark'] .ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 30px;
  text-align: center;
}
html[data-theme='dark'] .ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] span.ant-transfer-list-search-action {
  pointer-events: none;
}
html[data-theme='dark'] .ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 12px 9px;
  overflow: hidden;
  color: #e4e8ee;
  background: #191934;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
html[data-theme='dark'] .ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
html[data-theme='dark'] .ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
html[data-theme='dark'] .ant-transfer-list-body-with-search {
  padding-top: 54px;
}
html[data-theme='dark'] .ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
html[data-theme='dark'] .ant-transfer-list-content > .LazyLoad {
  animation: transferHighlightIn 1s;
}
html[data-theme='dark'] .ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-transfer-list-content-item > span {
  padding-right: 0;
}
html[data-theme='dark'] .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f0f5ff;
  cursor: pointer;
}
html[data-theme='dark'] .ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  transform: translateY(-50%);
}
html[data-theme='dark'] .ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 15px;
}
html[data-theme='dark'] .ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 2px 2px;
}
html[data-theme='dark'] .ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-transfer-operation .ant-btn {
  display: block;
}
html[data-theme='dark'] .ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
html[data-theme='dark'] .ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@keyframes transferHighlightIn {
  0% {
    background: #e8efff;
  }
  100% {
    background: transparent;
  }
}
html[data-theme='dark'] .ant-tree.ant-tree-directory {
  position: relative;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-switcher,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #f0f5ff;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #191934;
  background: transparent;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #191934;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #e4e8ee;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #e4e8ee;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
html[data-theme='dark'] .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #3851d1;
}
html[data-theme='dark'] .ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
html[data-theme='dark'] .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
html[data-theme='dark'] .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #10101f;
}
html[data-theme='dark'] .ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #10101f;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='dark'] .ant-tree-checkbox:hover::after,
html[data-theme='dark'] .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
html[data-theme='dark'] .ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #e4e8ee;
  border: 1px solid #090912;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='dark'] .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #10101f;
  border-color: #10101f;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #090912 !important;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='dark'] .ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='dark'] .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='dark'] .ant-tree-checkbox-wrapper + span,
html[data-theme='dark'] .ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='dark'] .ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='dark'] .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #090912;
}
html[data-theme='dark'] .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #10101f;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='dark'] .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
}
html[data-theme='dark'] .ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
html[data-theme='dark'] .ant-tree ol,
html[data-theme='dark'] .ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
html[data-theme='dark'] .ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
html[data-theme='dark'] .ant-tree li span[draggable],
html[data-theme='dark'] .ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
html[data-theme='dark'] .ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #3851d1;
  opacity: 0.8;
}
html[data-theme='dark'] .ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #3851d1;
}
html[data-theme='dark'] .ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #3851d1;
}
html[data-theme='dark'] .ant-tree li.filter-node > span {
  color: #ff376b !important;
  font-weight: 500 !important;
}
html[data-theme='dark'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
html[data-theme='dark'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #3851d1;
  font-size: 14px;
  transform: none;
}
html[data-theme='dark'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
html[data-theme='dark'] .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] :root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
html[data-theme='dark'] :root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
html[data-theme='dark'] .ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
html[data-theme='dark'] .ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: #e4e8ee;
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #e8efff;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-checkbox {
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher,
html[data-theme='dark'] .ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher {
  position: relative;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='dark'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='dark'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='dark'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='dark'] :root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
html[data-theme='dark'] .ant-tree li:last-child > span.ant-tree-switcher::before,
html[data-theme='dark'] .ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
html[data-theme='dark'] .ant-tree > li:first-child {
  padding-top: 7px;
}
html[data-theme='dark'] .ant-tree > li:last-child {
  padding-bottom: 7px;
}
html[data-theme='dark'] .ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
html[data-theme='dark'] .ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
html[data-theme='dark'] li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
html[data-theme='dark'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
html[data-theme='dark'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li {
  position: relative;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #191934;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  margin: 22px 0;
  border-left: 1px solid #090912;
  content: ' ';
}
html[data-theme='dark'] .ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
html[data-theme='dark'] .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
html[data-theme='dark'] .ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
html[data-theme='dark'] .ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
html[data-theme='dark'] .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
html[data-theme='dark'] .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #10101f;
}
html[data-theme='dark'] .ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #10101f;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
html[data-theme='dark'] .ant-select-tree-checkbox:hover::after,
html[data-theme='dark'] .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
html[data-theme='dark'] .ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #e4e8ee;
  border: 1px solid #090912;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
html[data-theme='dark'] .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #e4e8ee;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
html[data-theme='dark'] .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #10101f;
  border-color: #10101f;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #090912 !important;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
html[data-theme='dark'] .ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
html[data-theme='dark'] .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
html[data-theme='dark'] .ant-select-tree-checkbox-wrapper + span,
html[data-theme='dark'] .ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
html[data-theme='dark'] .ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
html[data-theme='dark'] .ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
html[data-theme='dark'] .ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
html[data-theme='dark'] .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #090912;
}
html[data-theme='dark'] .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #10101f;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
html[data-theme='dark'] .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
html[data-theme='dark'] .ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
html[data-theme='dark'] .ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
html[data-theme='dark'] .ant-select-tree li.filter-node > span {
  font-weight: 500;
}
html[data-theme='dark'] .ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
html[data-theme='dark'] .ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: #e4e8ee;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #e8efff;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #3851d1;
  font-size: 14px;
  transform: none;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='dark'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
html[data-theme='dark'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
html[data-theme='dark'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
html[data-theme='dark'] :root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: rotate(-90deg);
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #3851d1;
  font-size: 14px;
  transform: none;
}
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
html[data-theme='dark'] .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
html[data-theme='dark'] .ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
html[data-theme='dark'] .ant-select-tree-child-tree {
  display: none;
}
html[data-theme='dark'] .ant-select-tree-child-tree-open {
  display: block;
}
html[data-theme='dark'] li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
html[data-theme='dark'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
html[data-theme='dark'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
html[data-theme='dark'] .ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
}
html[data-theme='dark'] .ant-select-tree-dropdown .ant-select-dropdown-search {
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #191934;
}
html[data-theme='dark'] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
html[data-theme='dark'] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #090912;
  border-radius: 4px;
  outline: none;
}
html[data-theme='dark'] .ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
html[data-theme='dark'] .ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-typography {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-typography.ant-typography-warning {
  color: #d48806;
}
html[data-theme='dark'] .ant-typography.ant-typography-danger {
  color: #cf1322;
}
html[data-theme='dark'] .ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
html[data-theme='dark'] div.ant-typography,
html[data-theme='dark'] .ant-typography p {
  margin-bottom: 1em;
}
html[data-theme='dark'] h1.ant-typography,
html[data-theme='dark'] .ant-typography h1 {
  margin-bottom: 0.5em;
  color: #e4e8ee;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
html[data-theme='dark'] h2.ant-typography,
html[data-theme='dark'] .ant-typography h2 {
  margin-bottom: 0.5em;
  color: #e4e8ee;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
html[data-theme='dark'] h3.ant-typography,
html[data-theme='dark'] .ant-typography h3 {
  margin-bottom: 0.5em;
  color: #e4e8ee;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
html[data-theme='dark'] h4.ant-typography,
html[data-theme='dark'] .ant-typography h4 {
  margin-bottom: 0.5em;
  color: #e4e8ee;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
html[data-theme='dark'] .ant-typography + h1.ant-typography,
html[data-theme='dark'] .ant-typography + h2.ant-typography,
html[data-theme='dark'] .ant-typography + h3.ant-typography,
html[data-theme='dark'] .ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
html[data-theme='dark'] .ant-typography div + h1,
html[data-theme='dark'] .ant-typography ul + h1,
html[data-theme='dark'] .ant-typography li + h1,
html[data-theme='dark'] .ant-typography p + h1,
html[data-theme='dark'] .ant-typography h1 + h1,
html[data-theme='dark'] .ant-typography h2 + h1,
html[data-theme='dark'] .ant-typography h3 + h1,
html[data-theme='dark'] .ant-typography h4 + h1,
html[data-theme='dark'] .ant-typography div + h2,
html[data-theme='dark'] .ant-typography ul + h2,
html[data-theme='dark'] .ant-typography li + h2,
html[data-theme='dark'] .ant-typography p + h2,
html[data-theme='dark'] .ant-typography h1 + h2,
html[data-theme='dark'] .ant-typography h2 + h2,
html[data-theme='dark'] .ant-typography h3 + h2,
html[data-theme='dark'] .ant-typography h4 + h2,
html[data-theme='dark'] .ant-typography div + h3,
html[data-theme='dark'] .ant-typography ul + h3,
html[data-theme='dark'] .ant-typography li + h3,
html[data-theme='dark'] .ant-typography p + h3,
html[data-theme='dark'] .ant-typography h1 + h3,
html[data-theme='dark'] .ant-typography h2 + h3,
html[data-theme='dark'] .ant-typography h3 + h3,
html[data-theme='dark'] .ant-typography h4 + h3,
html[data-theme='dark'] .ant-typography div + h4,
html[data-theme='dark'] .ant-typography ul + h4,
html[data-theme='dark'] .ant-typography li + h4,
html[data-theme='dark'] .ant-typography p + h4,
html[data-theme='dark'] .ant-typography h1 + h4,
html[data-theme='dark'] .ant-typography h2 + h4,
html[data-theme='dark'] .ant-typography h3 + h4,
html[data-theme='dark'] .ant-typography h4 + h4 {
  margin-top: 1.2em;
}
html[data-theme='dark'] span.ant-typography-ellipsis {
  display: inline-block;
}
html[data-theme='dark'] .ant-typography a {
  color: #e4e8ee;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
html[data-theme='dark'] .ant-typography a:focus,
html[data-theme='dark'] .ant-typography a:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-typography a:active {
  color: #b5bcc7;
}
html[data-theme='dark'] .ant-typography a:active,
html[data-theme='dark'] .ant-typography a:hover {
  text-decoration: none;
}
html[data-theme='dark'] .ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
html[data-theme='dark'] .ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
html[data-theme='dark'] .ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
html[data-theme='dark'] .ant-typography u,
html[data-theme='dark'] .ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
html[data-theme='dark'] .ant-typography s,
html[data-theme='dark'] .ant-typography del {
  text-decoration: line-through;
}
html[data-theme='dark'] .ant-typography strong {
  font-weight: 600;
}
html[data-theme='dark'] .ant-typography-expand,
html[data-theme='dark'] .ant-typography-edit,
html[data-theme='dark'] .ant-typography-copy {
  color: #e4e8ee;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
html[data-theme='dark'] .ant-typography-expand:focus,
html[data-theme='dark'] .ant-typography-edit:focus,
html[data-theme='dark'] .ant-typography-copy:focus,
html[data-theme='dark'] .ant-typography-expand:hover,
html[data-theme='dark'] .ant-typography-edit:hover,
html[data-theme='dark'] .ant-typography-copy:hover {
  color: #ebf1fa;
}
html[data-theme='dark'] .ant-typography-expand:active,
html[data-theme='dark'] .ant-typography-edit:active,
html[data-theme='dark'] .ant-typography-copy:active {
  color: #b5bcc7;
}
html[data-theme='dark'] .ant-typography-copy-success,
html[data-theme='dark'] .ant-typography-copy-success:hover,
html[data-theme='dark'] .ant-typography-copy-success:focus {
  color: #3851d1;
}
html[data-theme='dark'] .ant-typography-edit-content {
  position: relative;
}
html[data-theme='dark'] div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
html[data-theme='dark'] .ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
html[data-theme='dark'] .ant-typography ul,
html[data-theme='dark'] .ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
html[data-theme='dark'] .ant-typography ul li,
html[data-theme='dark'] .ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
html[data-theme='dark'] .ant-typography ul li {
  list-style-type: circle;
}
html[data-theme='dark'] .ant-typography ul li li {
  list-style-type: disc;
}
html[data-theme='dark'] .ant-typography ol li {
  list-style-type: decimal;
}
html[data-theme='dark'] .ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
html[data-theme='dark'] .ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
html[data-theme='dark'] .ant-upload p {
  margin: 0;
}
html[data-theme='dark'] .ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
html[data-theme='dark'] .ant-upload input[type='file'] {
  cursor: pointer;
}
html[data-theme='dark'] .ant-upload.ant-upload-select {
  display: inline-block;
}
html[data-theme='dark'] .ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-upload.ant-upload-select-picture-card {
  display: table;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #090912;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
html[data-theme='dark'] .ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #3851d1;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #090912;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #2637ab;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #5f79de;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #5f79de;
  font-size: 48px;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: #e4e8ee;
  font-size: 16px;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  zoom: 1;
}
html[data-theme='dark'] .ant-upload-list::before,
html[data-theme='dark'] .ant-upload-list::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-upload-list::after {
  clear: both;
}
html[data-theme='dark'] .ant-upload-list::before,
html[data-theme='dark'] .ant-upload-list::after {
  display: table;
  content: '';
}
html[data-theme='dark'] .ant-upload-list::after {
  clear: both;
}
html[data-theme='dark'] .ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
html[data-theme='dark'] .ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
html[data-theme='dark'] .ant-upload-list-item-info > span {
  display: block;
}
html[data-theme='dark'] .ant-upload-list-item-info .anticon-loading,
html[data-theme='dark'] .ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
html[data-theme='dark'] .ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
html[data-theme='dark'] :root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
html[data-theme='dark'] .ant-upload-list-item .anticon-close:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f0f5ff;
}
html[data-theme='dark'] .ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
html[data-theme='dark'] .ant-upload-list-item-error,
html[data-theme='dark'] .ant-upload-list-item-error .anticon-paper-clip,
html[data-theme='dark'] .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff376b;
}
html[data-theme='dark'] .ant-upload-list-item-error .anticon-close {
  color: #ff376b !important;
  opacity: 1;
}
html[data-theme='dark'] .ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #090912;
  border-radius: 2px;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item:hover,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-error,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff376b;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-info,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-uploading,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-thumbnail,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-icon,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-name,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
html[data-theme='dark'] .ant-upload-list-picture .ant-upload-list-item-progress,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
html[data-theme='dark'] .ant-upload-list-picture .anticon-close,
html[data-theme='dark'] .ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
html[data-theme='dark'] .ant-upload-list-picture-card {
  float: left;
}
html[data-theme='dark'] .ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(25, 25, 52, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #191934;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
html[data-theme='dark'] .ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  display: block;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
html[data-theme='dark'] .ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
html[data-theme='dark'] .ant-upload-list .ant-upload-success-icon {
  color: #3851d1;
  font-weight: bold;
}
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-enter,
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-leave,
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-inline-enter,
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateIn;
}
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateOut;
}
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
html[data-theme='dark'] .ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
html[data-theme='dark'] .rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
html[data-theme='dark'] button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
html[data-theme='dark'] button[disabled].rbc-btn {
  cursor: not-allowed;
}
html[data-theme='dark'] button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html[data-theme='dark'] .rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
html[data-theme='dark'] .rbc-calendar *,
html[data-theme='dark'] .rbc-calendar *:before,
html[data-theme='dark'] .rbc-calendar *:after {
  box-sizing: inherit;
}
html[data-theme='dark'] .rbc-abs-full,
html[data-theme='dark'] .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
html[data-theme='dark'] .rbc-ellipsis,
html[data-theme='dark'] .rbc-event-label,
html[data-theme='dark'] .rbc-row-segment .rbc-event-content,
html[data-theme='dark'] .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html[data-theme='dark'] .rbc-rtl {
  direction: rtl;
}
html[data-theme='dark'] .rbc-off-range {
  color: #999999;
}
html[data-theme='dark'] .rbc-off-range-bg {
  background: #e6e6e6;
}
html[data-theme='dark'] .rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-header > a,
html[data-theme='dark'] .rbc-header > a:active,
html[data-theme='dark'] .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
html[data-theme='dark'] .rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
html[data-theme='dark'] .rbc-today {
  background-color: #eaf6ff;
}
html[data-theme='dark'] .rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
html[data-theme='dark'] .rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
html[data-theme='dark'] .rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
html[data-theme='dark'] .rbc-toolbar button:active,
html[data-theme='dark'] .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='dark'] .rbc-toolbar button:active:hover,
html[data-theme='dark'] .rbc-toolbar button:active:focus,
html[data-theme='dark'] .rbc-toolbar button.rbc-active:hover,
html[data-theme='dark'] .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
html[data-theme='dark'] .rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='dark'] .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
html[data-theme='dark'] .rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
html[data-theme='dark'] .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
html[data-theme='dark'] .rbc-btn-group button + button {
  margin-left: -1px;
}
html[data-theme='dark'] .rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
html[data-theme='dark'] .rbc-btn-group + .rbc-btn-group,
html[data-theme='dark'] .rbc-btn-group + button {
  margin-left: 10px;
}
html[data-theme='dark'] .rbc-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
html[data-theme='dark'] .rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
html[data-theme='dark'] .rbc-event.rbc-selected {
  background-color: #265985;
}
html[data-theme='dark'] .rbc-event:focus {
  outline: 5px auto #3b99fc;
}
html[data-theme='dark'] .rbc-event-label {
  font-size: 80%;
}
html[data-theme='dark'] .rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
html[data-theme='dark'] .rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[data-theme='dark'] .rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
html[data-theme='dark'] .rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
html[data-theme='dark'] .rbc-row {
  display: flex;
  flex-direction: row;
}
html[data-theme='dark'] .rbc-row-segment {
  padding: 0 1px 1px 1px;
}
html[data-theme='dark'] .rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
html[data-theme='dark'] .rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}
html[data-theme='dark'] .rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
html[data-theme='dark'] .rbc-month-header {
  display: flex;
  flex-direction: row;
}
html[data-theme='dark'] .rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
html[data-theme='dark'] .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
html[data-theme='dark'] .rbc-date-cell.rbc-now {
  font-weight: bold;
}
html[data-theme='dark'] .rbc-date-cell > a,
html[data-theme='dark'] .rbc-date-cell > a:active,
html[data-theme='dark'] .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
html[data-theme='dark'] .rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
html[data-theme='dark'] .rbc-day-bg {
  flex: 1 0 0%;
}
html[data-theme='dark'] .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
html[data-theme='dark'] .rbc-overlay > * + * {
  margin-top: 1px;
}
html[data-theme='dark'] .rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
html[data-theme='dark'] .rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
html[data-theme='dark'] .rbc-agenda-time-cell {
  text-transform: lowercase;
}
html[data-theme='dark'] .rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
html[data-theme='dark'] .rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}
html[data-theme='dark'] .rbc-agenda-date-cell,
html[data-theme='dark'] .rbc-agenda-time-cell {
  white-space: nowrap;
}
html[data-theme='dark'] .rbc-agenda-event-cell {
  width: 100%;
}
html[data-theme='dark'] .rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
html[data-theme='dark'] .rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
html[data-theme='dark'] .rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
html[data-theme='dark'] .rbc-time-gutter,
html[data-theme='dark'] .rbc-header-gutter {
  flex: none;
}
html[data-theme='dark'] .rbc-label {
  padding: 0 5px;
}
html[data-theme='dark'] .rbc-day-slot {
  position: relative;
}
html[data-theme='dark'] .rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
html[data-theme='dark'] .rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
html[data-theme='dark'] .rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
html[data-theme='dark'] .rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
html[data-theme='dark'] .rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
html[data-theme='dark'] .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-time-gutter,
html[data-theme='dark'] .rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
html[data-theme='dark'] .rbc-time-view-resources .rbc-header,
html[data-theme='dark'] .rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}
html[data-theme='dark'] .rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
html[data-theme='dark'] .rbc-time-slot {
  flex: 1 0 0;
}
html[data-theme='dark'] .rbc-time-slot.rbc-now {
  font-weight: bold;
}
html[data-theme='dark'] .rbc-day-header {
  text-align: center;
}
html[data-theme='dark'] .rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
html[data-theme='dark'] .rbc-slot-selecting {
  cursor: move;
}
html[data-theme='dark'] .rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
html[data-theme='dark'] .rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
html[data-theme='dark'] .rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
html[data-theme='dark'] .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
html[data-theme='dark'] .rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
html[data-theme='dark'] .rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
html[data-theme='dark'] .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-header-cell-single-day {
  display: none;
}
html[data-theme='dark'] .rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
  flex-shrink: 0;
}
html[data-theme='dark'] .rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
html[data-theme='dark'] .rbc-time-content > .rbc-time-gutter {
  flex: none;
}
html[data-theme='dark'] .rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
html[data-theme='dark'] .rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}
html[data-theme='dark'] .rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
html[data-theme='dark'] html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
html[data-theme='dark'] *,
html[data-theme='dark'] ::after,
html[data-theme='dark'] ::before {
  box-sizing: inherit;
}
html[data-theme='dark'] .container,
html[data-theme='dark'] .container-fluid,
html[data-theme='dark'] .container-lg,
html[data-theme='dark'] .container-md,
html[data-theme='dark'] .container-sm,
html[data-theme='dark'] .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .container,
  html[data-theme='dark'] .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .container,
  html[data-theme='dark'] .container-md,
  html[data-theme='dark'] .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .container,
  html[data-theme='dark'] .container-lg,
  html[data-theme='dark'] .container-md,
  html[data-theme='dark'] .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .container,
  html[data-theme='dark'] .container-lg,
  html[data-theme='dark'] .container-md,
  html[data-theme='dark'] .container-sm,
  html[data-theme='dark'] .container-xl {
    max-width: 1140px;
  }
}
html[data-theme='dark'] .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
html[data-theme='dark'] .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
html[data-theme='dark'] .no-gutters > .col,
html[data-theme='dark'] .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
html[data-theme='dark'] .col,
html[data-theme='dark'] .col-1,
html[data-theme='dark'] .col-10,
html[data-theme='dark'] .col-11,
html[data-theme='dark'] .col-12,
html[data-theme='dark'] .col-2,
html[data-theme='dark'] .col-3,
html[data-theme='dark'] .col-4,
html[data-theme='dark'] .col-5,
html[data-theme='dark'] .col-6,
html[data-theme='dark'] .col-7,
html[data-theme='dark'] .col-8,
html[data-theme='dark'] .col-9,
html[data-theme='dark'] .col-auto,
html[data-theme='dark'] .col-lg,
html[data-theme='dark'] .col-lg-1,
html[data-theme='dark'] .col-lg-10,
html[data-theme='dark'] .col-lg-11,
html[data-theme='dark'] .col-lg-12,
html[data-theme='dark'] .col-lg-2,
html[data-theme='dark'] .col-lg-3,
html[data-theme='dark'] .col-lg-4,
html[data-theme='dark'] .col-lg-5,
html[data-theme='dark'] .col-lg-6,
html[data-theme='dark'] .col-lg-7,
html[data-theme='dark'] .col-lg-8,
html[data-theme='dark'] .col-lg-9,
html[data-theme='dark'] .col-lg-auto,
html[data-theme='dark'] .col-md,
html[data-theme='dark'] .col-md-1,
html[data-theme='dark'] .col-md-10,
html[data-theme='dark'] .col-md-11,
html[data-theme='dark'] .col-md-12,
html[data-theme='dark'] .col-md-2,
html[data-theme='dark'] .col-md-3,
html[data-theme='dark'] .col-md-4,
html[data-theme='dark'] .col-md-5,
html[data-theme='dark'] .col-md-6,
html[data-theme='dark'] .col-md-7,
html[data-theme='dark'] .col-md-8,
html[data-theme='dark'] .col-md-9,
html[data-theme='dark'] .col-md-auto,
html[data-theme='dark'] .col-sm,
html[data-theme='dark'] .col-sm-1,
html[data-theme='dark'] .col-sm-10,
html[data-theme='dark'] .col-sm-11,
html[data-theme='dark'] .col-sm-12,
html[data-theme='dark'] .col-sm-2,
html[data-theme='dark'] .col-sm-3,
html[data-theme='dark'] .col-sm-4,
html[data-theme='dark'] .col-sm-5,
html[data-theme='dark'] .col-sm-6,
html[data-theme='dark'] .col-sm-7,
html[data-theme='dark'] .col-sm-8,
html[data-theme='dark'] .col-sm-9,
html[data-theme='dark'] .col-sm-auto,
html[data-theme='dark'] .col-xl,
html[data-theme='dark'] .col-xl-1,
html[data-theme='dark'] .col-xl-10,
html[data-theme='dark'] .col-xl-11,
html[data-theme='dark'] .col-xl-12,
html[data-theme='dark'] .col-xl-2,
html[data-theme='dark'] .col-xl-3,
html[data-theme='dark'] .col-xl-4,
html[data-theme='dark'] .col-xl-5,
html[data-theme='dark'] .col-xl-6,
html[data-theme='dark'] .col-xl-7,
html[data-theme='dark'] .col-xl-8,
html[data-theme='dark'] .col-xl-9,
html[data-theme='dark'] .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
html[data-theme='dark'] .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
html[data-theme='dark'] .row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
html[data-theme='dark'] .row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
html[data-theme='dark'] .row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
html[data-theme='dark'] .row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
html[data-theme='dark'] .row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
html[data-theme='dark'] .row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
html[data-theme='dark'] .col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
html[data-theme='dark'] .col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
html[data-theme='dark'] .col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
html[data-theme='dark'] .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
html[data-theme='dark'] .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
html[data-theme='dark'] .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
html[data-theme='dark'] .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
html[data-theme='dark'] .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
html[data-theme='dark'] .col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
html[data-theme='dark'] .col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
html[data-theme='dark'] .col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
html[data-theme='dark'] .col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
html[data-theme='dark'] .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
html[data-theme='dark'] .order-first {
  -ms-flex-order: -1;
  order: -1;
}
html[data-theme='dark'] .order-last {
  -ms-flex-order: 13;
  order: 13;
}
html[data-theme='dark'] .order-0 {
  -ms-flex-order: 0;
  order: 0;
}
html[data-theme='dark'] .order-1 {
  -ms-flex-order: 1;
  order: 1;
}
html[data-theme='dark'] .order-2 {
  -ms-flex-order: 2;
  order: 2;
}
html[data-theme='dark'] .order-3 {
  -ms-flex-order: 3;
  order: 3;
}
html[data-theme='dark'] .order-4 {
  -ms-flex-order: 4;
  order: 4;
}
html[data-theme='dark'] .order-5 {
  -ms-flex-order: 5;
  order: 5;
}
html[data-theme='dark'] .order-6 {
  -ms-flex-order: 6;
  order: 6;
}
html[data-theme='dark'] .order-7 {
  -ms-flex-order: 7;
  order: 7;
}
html[data-theme='dark'] .order-8 {
  -ms-flex-order: 8;
  order: 8;
}
html[data-theme='dark'] .order-9 {
  -ms-flex-order: 9;
  order: 9;
}
html[data-theme='dark'] .order-10 {
  -ms-flex-order: 10;
  order: 10;
}
html[data-theme='dark'] .order-11 {
  -ms-flex-order: 11;
  order: 11;
}
html[data-theme='dark'] .order-12 {
  -ms-flex-order: 12;
  order: 12;
}
html[data-theme='dark'] .offset-1 {
  margin-left: 8.333333%;
}
html[data-theme='dark'] .offset-2 {
  margin-left: 16.666667%;
}
html[data-theme='dark'] .offset-3 {
  margin-left: 25%;
}
html[data-theme='dark'] .offset-4 {
  margin-left: 33.333333%;
}
html[data-theme='dark'] .offset-5 {
  margin-left: 41.666667%;
}
html[data-theme='dark'] .offset-6 {
  margin-left: 50%;
}
html[data-theme='dark'] .offset-7 {
  margin-left: 58.333333%;
}
html[data-theme='dark'] .offset-8 {
  margin-left: 66.666667%;
}
html[data-theme='dark'] .offset-9 {
  margin-left: 75%;
}
html[data-theme='dark'] .offset-10 {
  margin-left: 83.333333%;
}
html[data-theme='dark'] .offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='dark'] .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='dark'] .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='dark'] .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='dark'] .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='dark'] .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='dark'] .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='dark'] .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='dark'] .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='dark'] .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='dark'] .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='dark'] .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='dark'] .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='dark'] .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='dark'] .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='dark'] .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='dark'] .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='dark'] .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='dark'] .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='dark'] .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='dark'] .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='dark'] .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='dark'] .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='dark'] .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='dark'] .offset-sm-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .offset-sm-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='dark'] .offset-sm-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='dark'] .offset-sm-3 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .offset-sm-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='dark'] .offset-sm-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='dark'] .offset-sm-6 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .offset-sm-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='dark'] .offset-sm-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='dark'] .offset-sm-9 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .offset-sm-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='dark'] .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='dark'] .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='dark'] .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='dark'] .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='dark'] .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='dark'] .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='dark'] .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='dark'] .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='dark'] .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='dark'] .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='dark'] .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='dark'] .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='dark'] .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='dark'] .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='dark'] .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='dark'] .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='dark'] .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='dark'] .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='dark'] .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='dark'] .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='dark'] .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='dark'] .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='dark'] .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='dark'] .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='dark'] .offset-md-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .offset-md-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='dark'] .offset-md-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='dark'] .offset-md-3 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .offset-md-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='dark'] .offset-md-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='dark'] .offset-md-6 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .offset-md-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='dark'] .offset-md-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='dark'] .offset-md-9 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .offset-md-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='dark'] .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='dark'] .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='dark'] .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='dark'] .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='dark'] .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='dark'] .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='dark'] .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='dark'] .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='dark'] .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='dark'] .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='dark'] .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='dark'] .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='dark'] .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='dark'] .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='dark'] .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='dark'] .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='dark'] .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='dark'] .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='dark'] .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='dark'] .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='dark'] .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='dark'] .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='dark'] .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='dark'] .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='dark'] .offset-lg-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .offset-lg-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='dark'] .offset-lg-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='dark'] .offset-lg-3 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .offset-lg-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='dark'] .offset-lg-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='dark'] .offset-lg-6 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .offset-lg-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='dark'] .offset-lg-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='dark'] .offset-lg-9 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .offset-lg-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='dark'] .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  html[data-theme='dark'] .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  html[data-theme='dark'] .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  html[data-theme='dark'] .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  html[data-theme='dark'] .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  html[data-theme='dark'] .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  html[data-theme='dark'] .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  html[data-theme='dark'] .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  html[data-theme='dark'] .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  html[data-theme='dark'] .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  html[data-theme='dark'] .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  html[data-theme='dark'] .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  html[data-theme='dark'] .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  html[data-theme='dark'] .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  html[data-theme='dark'] .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  html[data-theme='dark'] .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  html[data-theme='dark'] .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  html[data-theme='dark'] .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  html[data-theme='dark'] .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  html[data-theme='dark'] .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  html[data-theme='dark'] .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  html[data-theme='dark'] .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  html[data-theme='dark'] .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  html[data-theme='dark'] .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  html[data-theme='dark'] .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  html[data-theme='dark'] .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  html[data-theme='dark'] .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  html[data-theme='dark'] .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  html[data-theme='dark'] .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  html[data-theme='dark'] .offset-xl-0 {
    margin-left: 0;
  }
  html[data-theme='dark'] .offset-xl-1 {
    margin-left: 8.333333%;
  }
  html[data-theme='dark'] .offset-xl-2 {
    margin-left: 16.666667%;
  }
  html[data-theme='dark'] .offset-xl-3 {
    margin-left: 25%;
  }
  html[data-theme='dark'] .offset-xl-4 {
    margin-left: 33.333333%;
  }
  html[data-theme='dark'] .offset-xl-5 {
    margin-left: 41.666667%;
  }
  html[data-theme='dark'] .offset-xl-6 {
    margin-left: 50%;
  }
  html[data-theme='dark'] .offset-xl-7 {
    margin-left: 58.333333%;
  }
  html[data-theme='dark'] .offset-xl-8 {
    margin-left: 66.666667%;
  }
  html[data-theme='dark'] .offset-xl-9 {
    margin-left: 75%;
  }
  html[data-theme='dark'] .offset-xl-10 {
    margin-left: 83.333333%;
  }
  html[data-theme='dark'] .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
html[data-theme='dark'] .d-none {
  display: none !important;
}
html[data-theme='dark'] .d-inline {
  display: inline !important;
}
html[data-theme='dark'] .d-inline-block {
  display: inline-block !important;
}
html[data-theme='dark'] .d-block {
  display: block !important;
}
html[data-theme='dark'] .d-table {
  display: table !important;
}
html[data-theme='dark'] .d-table-row {
  display: table-row !important;
}
html[data-theme='dark'] .d-table-cell {
  display: table-cell !important;
}
html[data-theme='dark'] .d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
html[data-theme='dark'] .d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .d-sm-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-sm-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-sm-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-sm-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-sm-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-sm-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-sm-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .d-md-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-md-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-md-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-md-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-md-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-md-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-md-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .d-lg-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-lg-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-lg-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-lg-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-lg-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-lg-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-lg-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .d-xl-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-xl-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-xl-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-xl-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-xl-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-xl-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-xl-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  html[data-theme='dark'] .d-print-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-print-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-print-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-print-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-print-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-print-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-print-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
html[data-theme='dark'] .flex-row {
  -ms-flex-direction: row!important;
  flex-direction: row !important;
}
html[data-theme='dark'] .flex-column {
  -ms-flex-direction: column!important;
  flex-direction: column !important;
}
html[data-theme='dark'] .flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
html[data-theme='dark'] .flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
html[data-theme='dark'] .flex-wrap {
  -ms-flex-wrap: wrap!important;
  flex-wrap: wrap !important;
}
html[data-theme='dark'] .flex-nowrap {
  -ms-flex-wrap: nowrap!important;
  flex-wrap: nowrap !important;
}
html[data-theme='dark'] .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
html[data-theme='dark'] .flex-fill {
  -ms-flex: 1 1 auto!important;
  flex: 1 1 auto !important;
}
html[data-theme='dark'] .flex-grow-0 {
  -ms-flex-positive: 0!important;
  flex-grow: 0 !important;
}
html[data-theme='dark'] .flex-grow-1 {
  -ms-flex-positive: 1!important;
  flex-grow: 1 !important;
}
html[data-theme='dark'] .flex-shrink-0 {
  -ms-flex-negative: 0!important;
  flex-shrink: 0 !important;
}
html[data-theme='dark'] .flex-shrink-1 {
  -ms-flex-negative: 1!important;
  flex-shrink: 1 !important;
}
html[data-theme='dark'] .justify-content-start {
  -ms-flex-pack: start!important;
  justify-content: flex-start !important;
}
html[data-theme='dark'] .justify-content-end {
  -ms-flex-pack: end!important;
  justify-content: flex-end !important;
}
html[data-theme='dark'] .justify-content-center {
  -ms-flex-pack: center!important;
  justify-content: center !important;
}
html[data-theme='dark'] .justify-content-between {
  -ms-flex-pack: justify!important;
  justify-content: space-between !important;
}
html[data-theme='dark'] .justify-content-around {
  -ms-flex-pack: distribute!important;
  justify-content: space-around !important;
}
html[data-theme='dark'] .align-items-start {
  -ms-flex-align: start!important;
  align-items: flex-start !important;
}
html[data-theme='dark'] .align-items-end {
  -ms-flex-align: end!important;
  align-items: flex-end !important;
}
html[data-theme='dark'] .align-items-center {
  -ms-flex-align: center!important;
  align-items: center !important;
}
html[data-theme='dark'] .align-items-baseline {
  -ms-flex-align: baseline!important;
  align-items: baseline !important;
}
html[data-theme='dark'] .align-items-stretch {
  -ms-flex-align: stretch!important;
  align-items: stretch !important;
}
html[data-theme='dark'] .align-content-start {
  -ms-flex-line-pack: start!important;
  align-content: flex-start !important;
}
html[data-theme='dark'] .align-content-end {
  -ms-flex-line-pack: end!important;
  align-content: flex-end !important;
}
html[data-theme='dark'] .align-content-center {
  -ms-flex-line-pack: center!important;
  align-content: center !important;
}
html[data-theme='dark'] .align-content-between {
  -ms-flex-line-pack: justify!important;
  align-content: space-between !important;
}
html[data-theme='dark'] .align-content-around {
  -ms-flex-line-pack: distribute!important;
  align-content: space-around !important;
}
html[data-theme='dark'] .align-content-stretch {
  -ms-flex-line-pack: stretch!important;
  align-content: stretch !important;
}
html[data-theme='dark'] .align-self-auto {
  -ms-flex-item-align: auto!important;
  align-self: auto !important;
}
html[data-theme='dark'] .align-self-start {
  -ms-flex-item-align: start!important;
  align-self: flex-start !important;
}
html[data-theme='dark'] .align-self-end {
  -ms-flex-item-align: end!important;
  align-self: flex-end !important;
}
html[data-theme='dark'] .align-self-center {
  -ms-flex-item-align: center!important;
  align-self: center !important;
}
html[data-theme='dark'] .align-self-baseline {
  -ms-flex-item-align: baseline!important;
  align-self: baseline !important;
}
html[data-theme='dark'] .align-self-stretch {
  -ms-flex-item-align: stretch!important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .flex-sm-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-sm-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-sm-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-sm-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-sm-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-sm-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-sm-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-sm-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-sm-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-sm-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-sm-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-sm-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-sm-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-sm-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-sm-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-sm-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-sm-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-sm-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-sm-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-sm-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-sm-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-sm-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-sm-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-sm-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-sm-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-sm-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-sm-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-sm-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-sm-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .flex-md-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-md-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-md-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-md-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-md-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-md-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-md-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-md-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-md-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-md-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-md-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-md-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-md-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-md-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-md-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-md-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-md-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-md-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-md-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-md-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-md-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-md-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-md-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-md-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-md-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-md-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-md-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-md-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-md-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-md-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-md-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .flex-lg-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-lg-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-lg-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-lg-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-lg-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-lg-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-lg-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-lg-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-lg-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-lg-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-lg-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-lg-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-lg-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-lg-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-lg-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-lg-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-lg-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-lg-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-lg-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-lg-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-lg-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-lg-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-lg-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-lg-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-lg-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-lg-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-lg-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-lg-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-lg-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .flex-xl-row {
    -ms-flex-direction: row!important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-xl-column {
    -ms-flex-direction: column!important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-xl-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-xl-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-xl-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-xl-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-xl-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-xl-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-xl-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-xl-center {
    -ms-flex-pack: center!important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-xl-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-xl-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-xl-start {
    -ms-flex-align: start!important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-xl-end {
    -ms-flex-align: end!important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-xl-center {
    -ms-flex-align: center!important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-xl-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-xl-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-xl-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-xl-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-xl-center {
    -ms-flex-line-pack: center!important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-xl-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-xl-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-xl-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-xl-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-xl-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-xl-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-xl-center {
    -ms-flex-item-align: center!important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-xl-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-xl-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch !important;
  }
}
html[data-theme='dark'] .m-0 {
  margin: 0 !important;
}
html[data-theme='dark'] .mt-0,
html[data-theme='dark'] .my-0 {
  margin-top: 0 !important;
}
html[data-theme='dark'] .mr-0,
html[data-theme='dark'] .mx-0 {
  margin-right: 0 !important;
}
html[data-theme='dark'] .mb-0,
html[data-theme='dark'] .my-0 {
  margin-bottom: 0 !important;
}
html[data-theme='dark'] .ml-0,
html[data-theme='dark'] .mx-0 {
  margin-left: 0 !important;
}
html[data-theme='dark'] .m-1 {
  margin: 0.25rem !important;
}
html[data-theme='dark'] .mt-1,
html[data-theme='dark'] .my-1 {
  margin-top: 0.25rem !important;
}
html[data-theme='dark'] .mr-1,
html[data-theme='dark'] .mx-1 {
  margin-right: 0.25rem !important;
}
html[data-theme='dark'] .mb-1,
html[data-theme='dark'] .my-1 {
  margin-bottom: 0.25rem !important;
}
html[data-theme='dark'] .ml-1,
html[data-theme='dark'] .mx-1 {
  margin-left: 0.25rem !important;
}
html[data-theme='dark'] .m-2 {
  margin: 0.5rem !important;
}
html[data-theme='dark'] .mt-2,
html[data-theme='dark'] .my-2 {
  margin-top: 0.5rem !important;
}
html[data-theme='dark'] .mr-2,
html[data-theme='dark'] .mx-2 {
  margin-right: 0.5rem !important;
}
html[data-theme='dark'] .mb-2,
html[data-theme='dark'] .my-2 {
  margin-bottom: 0.5rem !important;
}
html[data-theme='dark'] .ml-2,
html[data-theme='dark'] .mx-2 {
  margin-left: 0.5rem !important;
}
html[data-theme='dark'] .m-3 {
  margin: 1rem !important;
}
html[data-theme='dark'] .mt-3,
html[data-theme='dark'] .my-3 {
  margin-top: 1rem !important;
}
html[data-theme='dark'] .mr-3,
html[data-theme='dark'] .mx-3 {
  margin-right: 1rem !important;
}
html[data-theme='dark'] .mb-3,
html[data-theme='dark'] .my-3 {
  margin-bottom: 1rem !important;
}
html[data-theme='dark'] .ml-3,
html[data-theme='dark'] .mx-3 {
  margin-left: 1rem !important;
}
html[data-theme='dark'] .m-4 {
  margin: 1.5rem !important;
}
html[data-theme='dark'] .mt-4,
html[data-theme='dark'] .my-4 {
  margin-top: 1.5rem !important;
}
html[data-theme='dark'] .mr-4,
html[data-theme='dark'] .mx-4 {
  margin-right: 1.5rem !important;
}
html[data-theme='dark'] .mb-4,
html[data-theme='dark'] .my-4 {
  margin-bottom: 1.5rem !important;
}
html[data-theme='dark'] .ml-4,
html[data-theme='dark'] .mx-4 {
  margin-left: 1.5rem !important;
}
html[data-theme='dark'] .m-5 {
  margin: 3rem !important;
}
html[data-theme='dark'] .mt-5,
html[data-theme='dark'] .my-5 {
  margin-top: 3rem !important;
}
html[data-theme='dark'] .mr-5,
html[data-theme='dark'] .mx-5 {
  margin-right: 3rem !important;
}
html[data-theme='dark'] .mb-5,
html[data-theme='dark'] .my-5 {
  margin-bottom: 3rem !important;
}
html[data-theme='dark'] .ml-5,
html[data-theme='dark'] .mx-5 {
  margin-left: 3rem !important;
}
html[data-theme='dark'] .p-0 {
  padding: 0 !important;
}
html[data-theme='dark'] .pt-0,
html[data-theme='dark'] .py-0 {
  padding-top: 0 !important;
}
html[data-theme='dark'] .pr-0,
html[data-theme='dark'] .px-0 {
  padding-right: 0 !important;
}
html[data-theme='dark'] .pb-0,
html[data-theme='dark'] .py-0 {
  padding-bottom: 0 !important;
}
html[data-theme='dark'] .pl-0,
html[data-theme='dark'] .px-0 {
  padding-left: 0 !important;
}
html[data-theme='dark'] .p-1 {
  padding: 0.25rem !important;
}
html[data-theme='dark'] .pt-1,
html[data-theme='dark'] .py-1 {
  padding-top: 0.25rem !important;
}
html[data-theme='dark'] .pr-1,
html[data-theme='dark'] .px-1 {
  padding-right: 0.25rem !important;
}
html[data-theme='dark'] .pb-1,
html[data-theme='dark'] .py-1 {
  padding-bottom: 0.25rem !important;
}
html[data-theme='dark'] .pl-1,
html[data-theme='dark'] .px-1 {
  padding-left: 0.25rem !important;
}
html[data-theme='dark'] .p-2 {
  padding: 0.5rem !important;
}
html[data-theme='dark'] .pt-2,
html[data-theme='dark'] .py-2 {
  padding-top: 0.5rem !important;
}
html[data-theme='dark'] .pr-2,
html[data-theme='dark'] .px-2 {
  padding-right: 0.5rem !important;
}
html[data-theme='dark'] .pb-2,
html[data-theme='dark'] .py-2 {
  padding-bottom: 0.5rem !important;
}
html[data-theme='dark'] .pl-2,
html[data-theme='dark'] .px-2 {
  padding-left: 0.5rem !important;
}
html[data-theme='dark'] .p-3 {
  padding: 1rem !important;
}
html[data-theme='dark'] .pt-3,
html[data-theme='dark'] .py-3 {
  padding-top: 1rem !important;
}
html[data-theme='dark'] .pr-3,
html[data-theme='dark'] .px-3 {
  padding-right: 1rem !important;
}
html[data-theme='dark'] .pb-3,
html[data-theme='dark'] .py-3 {
  padding-bottom: 1rem !important;
}
html[data-theme='dark'] .pl-3,
html[data-theme='dark'] .px-3 {
  padding-left: 1rem !important;
}
html[data-theme='dark'] .p-4 {
  padding: 1.5rem !important;
}
html[data-theme='dark'] .pt-4,
html[data-theme='dark'] .py-4 {
  padding-top: 1.5rem !important;
}
html[data-theme='dark'] .pr-4,
html[data-theme='dark'] .px-4 {
  padding-right: 1.5rem !important;
}
html[data-theme='dark'] .pb-4,
html[data-theme='dark'] .py-4 {
  padding-bottom: 1.5rem !important;
}
html[data-theme='dark'] .pl-4,
html[data-theme='dark'] .px-4 {
  padding-left: 1.5rem !important;
}
html[data-theme='dark'] .p-5 {
  padding: 3rem !important;
}
html[data-theme='dark'] .pt-5,
html[data-theme='dark'] .py-5 {
  padding-top: 3rem !important;
}
html[data-theme='dark'] .pr-5,
html[data-theme='dark'] .px-5 {
  padding-right: 3rem !important;
}
html[data-theme='dark'] .pb-5,
html[data-theme='dark'] .py-5 {
  padding-bottom: 3rem !important;
}
html[data-theme='dark'] .pl-5,
html[data-theme='dark'] .px-5 {
  padding-left: 3rem !important;
}
html[data-theme='dark'] .m-n1 {
  margin: -0.25rem !important;
}
html[data-theme='dark'] .mt-n1,
html[data-theme='dark'] .my-n1 {
  margin-top: -0.25rem !important;
}
html[data-theme='dark'] .mr-n1,
html[data-theme='dark'] .mx-n1 {
  margin-right: -0.25rem !important;
}
html[data-theme='dark'] .mb-n1,
html[data-theme='dark'] .my-n1 {
  margin-bottom: -0.25rem !important;
}
html[data-theme='dark'] .ml-n1,
html[data-theme='dark'] .mx-n1 {
  margin-left: -0.25rem !important;
}
html[data-theme='dark'] .m-n2 {
  margin: -0.5rem !important;
}
html[data-theme='dark'] .mt-n2,
html[data-theme='dark'] .my-n2 {
  margin-top: -0.5rem !important;
}
html[data-theme='dark'] .mr-n2,
html[data-theme='dark'] .mx-n2 {
  margin-right: -0.5rem !important;
}
html[data-theme='dark'] .mb-n2,
html[data-theme='dark'] .my-n2 {
  margin-bottom: -0.5rem !important;
}
html[data-theme='dark'] .ml-n2,
html[data-theme='dark'] .mx-n2 {
  margin-left: -0.5rem !important;
}
html[data-theme='dark'] .m-n3 {
  margin: -1rem !important;
}
html[data-theme='dark'] .mt-n3,
html[data-theme='dark'] .my-n3 {
  margin-top: -1rem !important;
}
html[data-theme='dark'] .mr-n3,
html[data-theme='dark'] .mx-n3 {
  margin-right: -1rem !important;
}
html[data-theme='dark'] .mb-n3,
html[data-theme='dark'] .my-n3 {
  margin-bottom: -1rem !important;
}
html[data-theme='dark'] .ml-n3,
html[data-theme='dark'] .mx-n3 {
  margin-left: -1rem !important;
}
html[data-theme='dark'] .m-n4 {
  margin: -1.5rem !important;
}
html[data-theme='dark'] .mt-n4,
html[data-theme='dark'] .my-n4 {
  margin-top: -1.5rem !important;
}
html[data-theme='dark'] .mr-n4,
html[data-theme='dark'] .mx-n4 {
  margin-right: -1.5rem !important;
}
html[data-theme='dark'] .mb-n4,
html[data-theme='dark'] .my-n4 {
  margin-bottom: -1.5rem !important;
}
html[data-theme='dark'] .ml-n4,
html[data-theme='dark'] .mx-n4 {
  margin-left: -1.5rem !important;
}
html[data-theme='dark'] .m-n5 {
  margin: -3rem !important;
}
html[data-theme='dark'] .mt-n5,
html[data-theme='dark'] .my-n5 {
  margin-top: -3rem !important;
}
html[data-theme='dark'] .mr-n5,
html[data-theme='dark'] .mx-n5 {
  margin-right: -3rem !important;
}
html[data-theme='dark'] .mb-n5,
html[data-theme='dark'] .my-n5 {
  margin-bottom: -3rem !important;
}
html[data-theme='dark'] .ml-n5,
html[data-theme='dark'] .mx-n5 {
  margin-left: -3rem !important;
}
html[data-theme='dark'] .m-auto {
  margin: auto !important;
}
html[data-theme='dark'] .mt-auto,
html[data-theme='dark'] .my-auto {
  margin-top: auto !important;
}
html[data-theme='dark'] .mr-auto,
html[data-theme='dark'] .mx-auto {
  margin-right: auto !important;
}
html[data-theme='dark'] .mb-auto,
html[data-theme='dark'] .my-auto {
  margin-bottom: auto !important;
}
html[data-theme='dark'] .ml-auto,
html[data-theme='dark'] .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .m-sm-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-sm-0,
  html[data-theme='dark'] .my-sm-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-sm-0,
  html[data-theme='dark'] .mx-sm-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-sm-0,
  html[data-theme='dark'] .my-sm-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-sm-0,
  html[data-theme='dark'] .mx-sm-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-sm-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-sm-1,
  html[data-theme='dark'] .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-sm-1,
  html[data-theme='dark'] .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-sm-1,
  html[data-theme='dark'] .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-sm-1,
  html[data-theme='dark'] .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-sm-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-2,
  html[data-theme='dark'] .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-2,
  html[data-theme='dark'] .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-2,
  html[data-theme='dark'] .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-2,
  html[data-theme='dark'] .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-sm-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-sm-3,
  html[data-theme='dark'] .my-sm-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-sm-3,
  html[data-theme='dark'] .mx-sm-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-sm-3,
  html[data-theme='dark'] .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-sm-3,
  html[data-theme='dark'] .mx-sm-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-sm-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-4,
  html[data-theme='dark'] .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-4,
  html[data-theme='dark'] .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-4,
  html[data-theme='dark'] .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-4,
  html[data-theme='dark'] .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-sm-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-sm-5,
  html[data-theme='dark'] .my-sm-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-sm-5,
  html[data-theme='dark'] .mx-sm-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-sm-5,
  html[data-theme='dark'] .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-sm-5,
  html[data-theme='dark'] .mx-sm-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-sm-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-sm-0,
  html[data-theme='dark'] .py-sm-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-sm-0,
  html[data-theme='dark'] .px-sm-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-sm-0,
  html[data-theme='dark'] .py-sm-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-sm-0,
  html[data-theme='dark'] .px-sm-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-sm-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-sm-1,
  html[data-theme='dark'] .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-sm-1,
  html[data-theme='dark'] .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-sm-1,
  html[data-theme='dark'] .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-sm-1,
  html[data-theme='dark'] .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-sm-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-sm-2,
  html[data-theme='dark'] .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-sm-2,
  html[data-theme='dark'] .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-sm-2,
  html[data-theme='dark'] .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-sm-2,
  html[data-theme='dark'] .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-sm-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-sm-3,
  html[data-theme='dark'] .py-sm-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-sm-3,
  html[data-theme='dark'] .px-sm-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-sm-3,
  html[data-theme='dark'] .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-sm-3,
  html[data-theme='dark'] .px-sm-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-sm-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-sm-4,
  html[data-theme='dark'] .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-sm-4,
  html[data-theme='dark'] .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-sm-4,
  html[data-theme='dark'] .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-sm-4,
  html[data-theme='dark'] .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-sm-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-sm-5,
  html[data-theme='dark'] .py-sm-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-sm-5,
  html[data-theme='dark'] .px-sm-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-sm-5,
  html[data-theme='dark'] .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-sm-5,
  html[data-theme='dark'] .px-sm-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-sm-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='dark'] .mt-sm-n1,
  html[data-theme='dark'] .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='dark'] .mr-sm-n1,
  html[data-theme='dark'] .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='dark'] .mb-sm-n1,
  html[data-theme='dark'] .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='dark'] .ml-sm-n1,
  html[data-theme='dark'] .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='dark'] .m-sm-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-n2,
  html[data-theme='dark'] .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-n2,
  html[data-theme='dark'] .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-n2,
  html[data-theme='dark'] .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-n2,
  html[data-theme='dark'] .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='dark'] .m-sm-n3 {
    margin: -1rem !important;
  }
  html[data-theme='dark'] .mt-sm-n3,
  html[data-theme='dark'] .my-sm-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='dark'] .mr-sm-n3,
  html[data-theme='dark'] .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='dark'] .mb-sm-n3,
  html[data-theme='dark'] .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='dark'] .ml-sm-n3,
  html[data-theme='dark'] .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='dark'] .m-sm-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-n4,
  html[data-theme='dark'] .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-n4,
  html[data-theme='dark'] .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-n4,
  html[data-theme='dark'] .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-n4,
  html[data-theme='dark'] .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='dark'] .m-sm-n5 {
    margin: -3rem !important;
  }
  html[data-theme='dark'] .mt-sm-n5,
  html[data-theme='dark'] .my-sm-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='dark'] .mr-sm-n5,
  html[data-theme='dark'] .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='dark'] .mb-sm-n5,
  html[data-theme='dark'] .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='dark'] .ml-sm-n5,
  html[data-theme='dark'] .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='dark'] .m-sm-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-sm-auto,
  html[data-theme='dark'] .my-sm-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-sm-auto,
  html[data-theme='dark'] .mx-sm-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-sm-auto,
  html[data-theme='dark'] .my-sm-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-sm-auto,
  html[data-theme='dark'] .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .m-md-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-md-0,
  html[data-theme='dark'] .my-md-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-md-0,
  html[data-theme='dark'] .mx-md-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-md-0,
  html[data-theme='dark'] .my-md-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-md-0,
  html[data-theme='dark'] .mx-md-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-md-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-md-1,
  html[data-theme='dark'] .my-md-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-md-1,
  html[data-theme='dark'] .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-md-1,
  html[data-theme='dark'] .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-md-1,
  html[data-theme='dark'] .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-md-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-md-2,
  html[data-theme='dark'] .my-md-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-md-2,
  html[data-theme='dark'] .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-md-2,
  html[data-theme='dark'] .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-md-2,
  html[data-theme='dark'] .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-md-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-md-3,
  html[data-theme='dark'] .my-md-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-md-3,
  html[data-theme='dark'] .mx-md-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-md-3,
  html[data-theme='dark'] .my-md-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-md-3,
  html[data-theme='dark'] .mx-md-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-md-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-md-4,
  html[data-theme='dark'] .my-md-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-md-4,
  html[data-theme='dark'] .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-md-4,
  html[data-theme='dark'] .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-md-4,
  html[data-theme='dark'] .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-md-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-md-5,
  html[data-theme='dark'] .my-md-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-md-5,
  html[data-theme='dark'] .mx-md-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-md-5,
  html[data-theme='dark'] .my-md-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-md-5,
  html[data-theme='dark'] .mx-md-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-md-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-md-0,
  html[data-theme='dark'] .py-md-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-md-0,
  html[data-theme='dark'] .px-md-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-md-0,
  html[data-theme='dark'] .py-md-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-md-0,
  html[data-theme='dark'] .px-md-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-md-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-md-1,
  html[data-theme='dark'] .py-md-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-md-1,
  html[data-theme='dark'] .px-md-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-md-1,
  html[data-theme='dark'] .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-md-1,
  html[data-theme='dark'] .px-md-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-md-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-md-2,
  html[data-theme='dark'] .py-md-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-md-2,
  html[data-theme='dark'] .px-md-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-md-2,
  html[data-theme='dark'] .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-md-2,
  html[data-theme='dark'] .px-md-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-md-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-md-3,
  html[data-theme='dark'] .py-md-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-md-3,
  html[data-theme='dark'] .px-md-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-md-3,
  html[data-theme='dark'] .py-md-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-md-3,
  html[data-theme='dark'] .px-md-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-md-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-md-4,
  html[data-theme='dark'] .py-md-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-md-4,
  html[data-theme='dark'] .px-md-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-md-4,
  html[data-theme='dark'] .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-md-4,
  html[data-theme='dark'] .px-md-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-md-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-md-5,
  html[data-theme='dark'] .py-md-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-md-5,
  html[data-theme='dark'] .px-md-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-md-5,
  html[data-theme='dark'] .py-md-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-md-5,
  html[data-theme='dark'] .px-md-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-md-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='dark'] .mt-md-n1,
  html[data-theme='dark'] .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='dark'] .mr-md-n1,
  html[data-theme='dark'] .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='dark'] .mb-md-n1,
  html[data-theme='dark'] .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='dark'] .ml-md-n1,
  html[data-theme='dark'] .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='dark'] .m-md-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='dark'] .mt-md-n2,
  html[data-theme='dark'] .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='dark'] .mr-md-n2,
  html[data-theme='dark'] .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='dark'] .mb-md-n2,
  html[data-theme='dark'] .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='dark'] .ml-md-n2,
  html[data-theme='dark'] .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='dark'] .m-md-n3 {
    margin: -1rem !important;
  }
  html[data-theme='dark'] .mt-md-n3,
  html[data-theme='dark'] .my-md-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='dark'] .mr-md-n3,
  html[data-theme='dark'] .mx-md-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='dark'] .mb-md-n3,
  html[data-theme='dark'] .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='dark'] .ml-md-n3,
  html[data-theme='dark'] .mx-md-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='dark'] .m-md-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='dark'] .mt-md-n4,
  html[data-theme='dark'] .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='dark'] .mr-md-n4,
  html[data-theme='dark'] .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='dark'] .mb-md-n4,
  html[data-theme='dark'] .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='dark'] .ml-md-n4,
  html[data-theme='dark'] .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='dark'] .m-md-n5 {
    margin: -3rem !important;
  }
  html[data-theme='dark'] .mt-md-n5,
  html[data-theme='dark'] .my-md-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='dark'] .mr-md-n5,
  html[data-theme='dark'] .mx-md-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='dark'] .mb-md-n5,
  html[data-theme='dark'] .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='dark'] .ml-md-n5,
  html[data-theme='dark'] .mx-md-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='dark'] .m-md-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-md-auto,
  html[data-theme='dark'] .my-md-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-md-auto,
  html[data-theme='dark'] .mx-md-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-md-auto,
  html[data-theme='dark'] .my-md-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-md-auto,
  html[data-theme='dark'] .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .m-lg-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-lg-0,
  html[data-theme='dark'] .my-lg-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-lg-0,
  html[data-theme='dark'] .mx-lg-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-lg-0,
  html[data-theme='dark'] .my-lg-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-lg-0,
  html[data-theme='dark'] .mx-lg-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-lg-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-lg-1,
  html[data-theme='dark'] .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-lg-1,
  html[data-theme='dark'] .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-lg-1,
  html[data-theme='dark'] .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-lg-1,
  html[data-theme='dark'] .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-lg-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-2,
  html[data-theme='dark'] .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-2,
  html[data-theme='dark'] .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-2,
  html[data-theme='dark'] .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-2,
  html[data-theme='dark'] .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-lg-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-lg-3,
  html[data-theme='dark'] .my-lg-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-lg-3,
  html[data-theme='dark'] .mx-lg-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-lg-3,
  html[data-theme='dark'] .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-lg-3,
  html[data-theme='dark'] .mx-lg-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-lg-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-4,
  html[data-theme='dark'] .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-4,
  html[data-theme='dark'] .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-4,
  html[data-theme='dark'] .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-4,
  html[data-theme='dark'] .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-lg-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-lg-5,
  html[data-theme='dark'] .my-lg-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-lg-5,
  html[data-theme='dark'] .mx-lg-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-lg-5,
  html[data-theme='dark'] .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-lg-5,
  html[data-theme='dark'] .mx-lg-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-lg-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-lg-0,
  html[data-theme='dark'] .py-lg-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-lg-0,
  html[data-theme='dark'] .px-lg-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-lg-0,
  html[data-theme='dark'] .py-lg-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-lg-0,
  html[data-theme='dark'] .px-lg-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-lg-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-lg-1,
  html[data-theme='dark'] .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-lg-1,
  html[data-theme='dark'] .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-lg-1,
  html[data-theme='dark'] .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-lg-1,
  html[data-theme='dark'] .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-lg-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-lg-2,
  html[data-theme='dark'] .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-lg-2,
  html[data-theme='dark'] .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-lg-2,
  html[data-theme='dark'] .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-lg-2,
  html[data-theme='dark'] .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-lg-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-lg-3,
  html[data-theme='dark'] .py-lg-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-lg-3,
  html[data-theme='dark'] .px-lg-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-lg-3,
  html[data-theme='dark'] .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-lg-3,
  html[data-theme='dark'] .px-lg-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-lg-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-lg-4,
  html[data-theme='dark'] .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-lg-4,
  html[data-theme='dark'] .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-lg-4,
  html[data-theme='dark'] .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-lg-4,
  html[data-theme='dark'] .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-lg-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-lg-5,
  html[data-theme='dark'] .py-lg-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-lg-5,
  html[data-theme='dark'] .px-lg-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-lg-5,
  html[data-theme='dark'] .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-lg-5,
  html[data-theme='dark'] .px-lg-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-lg-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='dark'] .mt-lg-n1,
  html[data-theme='dark'] .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='dark'] .mr-lg-n1,
  html[data-theme='dark'] .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='dark'] .mb-lg-n1,
  html[data-theme='dark'] .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='dark'] .ml-lg-n1,
  html[data-theme='dark'] .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='dark'] .m-lg-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-n2,
  html[data-theme='dark'] .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-n2,
  html[data-theme='dark'] .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-n2,
  html[data-theme='dark'] .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-n2,
  html[data-theme='dark'] .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='dark'] .m-lg-n3 {
    margin: -1rem !important;
  }
  html[data-theme='dark'] .mt-lg-n3,
  html[data-theme='dark'] .my-lg-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='dark'] .mr-lg-n3,
  html[data-theme='dark'] .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='dark'] .mb-lg-n3,
  html[data-theme='dark'] .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='dark'] .ml-lg-n3,
  html[data-theme='dark'] .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='dark'] .m-lg-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-n4,
  html[data-theme='dark'] .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-n4,
  html[data-theme='dark'] .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-n4,
  html[data-theme='dark'] .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-n4,
  html[data-theme='dark'] .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='dark'] .m-lg-n5 {
    margin: -3rem !important;
  }
  html[data-theme='dark'] .mt-lg-n5,
  html[data-theme='dark'] .my-lg-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='dark'] .mr-lg-n5,
  html[data-theme='dark'] .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='dark'] .mb-lg-n5,
  html[data-theme='dark'] .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='dark'] .ml-lg-n5,
  html[data-theme='dark'] .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='dark'] .m-lg-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-lg-auto,
  html[data-theme='dark'] .my-lg-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-lg-auto,
  html[data-theme='dark'] .mx-lg-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-lg-auto,
  html[data-theme='dark'] .my-lg-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-lg-auto,
  html[data-theme='dark'] .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .m-xl-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-xl-0,
  html[data-theme='dark'] .my-xl-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-xl-0,
  html[data-theme='dark'] .mx-xl-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-xl-0,
  html[data-theme='dark'] .my-xl-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-xl-0,
  html[data-theme='dark'] .mx-xl-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-xl-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-xl-1,
  html[data-theme='dark'] .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-xl-1,
  html[data-theme='dark'] .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-xl-1,
  html[data-theme='dark'] .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-xl-1,
  html[data-theme='dark'] .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-xl-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-2,
  html[data-theme='dark'] .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-2,
  html[data-theme='dark'] .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-2,
  html[data-theme='dark'] .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-2,
  html[data-theme='dark'] .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-xl-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-xl-3,
  html[data-theme='dark'] .my-xl-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-xl-3,
  html[data-theme='dark'] .mx-xl-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-xl-3,
  html[data-theme='dark'] .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-xl-3,
  html[data-theme='dark'] .mx-xl-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-xl-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-4,
  html[data-theme='dark'] .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-4,
  html[data-theme='dark'] .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-4,
  html[data-theme='dark'] .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-4,
  html[data-theme='dark'] .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-xl-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-xl-5,
  html[data-theme='dark'] .my-xl-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-xl-5,
  html[data-theme='dark'] .mx-xl-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-xl-5,
  html[data-theme='dark'] .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-xl-5,
  html[data-theme='dark'] .mx-xl-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-xl-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-xl-0,
  html[data-theme='dark'] .py-xl-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-xl-0,
  html[data-theme='dark'] .px-xl-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-xl-0,
  html[data-theme='dark'] .py-xl-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-xl-0,
  html[data-theme='dark'] .px-xl-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-xl-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-xl-1,
  html[data-theme='dark'] .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-xl-1,
  html[data-theme='dark'] .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-xl-1,
  html[data-theme='dark'] .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-xl-1,
  html[data-theme='dark'] .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-xl-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-xl-2,
  html[data-theme='dark'] .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-xl-2,
  html[data-theme='dark'] .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-xl-2,
  html[data-theme='dark'] .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-xl-2,
  html[data-theme='dark'] .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-xl-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-xl-3,
  html[data-theme='dark'] .py-xl-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-xl-3,
  html[data-theme='dark'] .px-xl-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-xl-3,
  html[data-theme='dark'] .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-xl-3,
  html[data-theme='dark'] .px-xl-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-xl-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-xl-4,
  html[data-theme='dark'] .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-xl-4,
  html[data-theme='dark'] .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-xl-4,
  html[data-theme='dark'] .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-xl-4,
  html[data-theme='dark'] .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-xl-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-xl-5,
  html[data-theme='dark'] .py-xl-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-xl-5,
  html[data-theme='dark'] .px-xl-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-xl-5,
  html[data-theme='dark'] .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-xl-5,
  html[data-theme='dark'] .px-xl-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-xl-n1 {
    margin: -0.25rem !important;
  }
  html[data-theme='dark'] .mt-xl-n1,
  html[data-theme='dark'] .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  html[data-theme='dark'] .mr-xl-n1,
  html[data-theme='dark'] .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  html[data-theme='dark'] .mb-xl-n1,
  html[data-theme='dark'] .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  html[data-theme='dark'] .ml-xl-n1,
  html[data-theme='dark'] .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  html[data-theme='dark'] .m-xl-n2 {
    margin: -0.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-n2,
  html[data-theme='dark'] .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-n2,
  html[data-theme='dark'] .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-n2,
  html[data-theme='dark'] .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-n2,
  html[data-theme='dark'] .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  html[data-theme='dark'] .m-xl-n3 {
    margin: -1rem !important;
  }
  html[data-theme='dark'] .mt-xl-n3,
  html[data-theme='dark'] .my-xl-n3 {
    margin-top: -1rem !important;
  }
  html[data-theme='dark'] .mr-xl-n3,
  html[data-theme='dark'] .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  html[data-theme='dark'] .mb-xl-n3,
  html[data-theme='dark'] .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  html[data-theme='dark'] .ml-xl-n3,
  html[data-theme='dark'] .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  html[data-theme='dark'] .m-xl-n4 {
    margin: -1.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-n4,
  html[data-theme='dark'] .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-n4,
  html[data-theme='dark'] .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-n4,
  html[data-theme='dark'] .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-n4,
  html[data-theme='dark'] .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  html[data-theme='dark'] .m-xl-n5 {
    margin: -3rem !important;
  }
  html[data-theme='dark'] .mt-xl-n5,
  html[data-theme='dark'] .my-xl-n5 {
    margin-top: -3rem !important;
  }
  html[data-theme='dark'] .mr-xl-n5,
  html[data-theme='dark'] .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  html[data-theme='dark'] .mb-xl-n5,
  html[data-theme='dark'] .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  html[data-theme='dark'] .ml-xl-n5,
  html[data-theme='dark'] .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  html[data-theme='dark'] .m-xl-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-xl-auto,
  html[data-theme='dark'] .my-xl-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-xl-auto,
  html[data-theme='dark'] .mx-xl-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-xl-auto,
  html[data-theme='dark'] .my-xl-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-xl-auto,
  html[data-theme='dark'] .mx-xl-auto {
    margin-left: auto !important;
  }
}
html[data-theme='dark'] .align-baseline {
  vertical-align: baseline !important;
}
html[data-theme='dark'] .align-top {
  vertical-align: top !important;
}
html[data-theme='dark'] .align-middle {
  vertical-align: middle !important;
}
html[data-theme='dark'] .align-bottom {
  vertical-align: bottom !important;
}
html[data-theme='dark'] .align-text-bottom {
  vertical-align: text-bottom !important;
}
html[data-theme='dark'] .align-text-top {
  vertical-align: text-top !important;
}
html[data-theme='dark'] .bg-primary {
  background-color: #007bff !important;
}
html[data-theme='dark'] a.bg-primary:focus,
html[data-theme='dark'] a.bg-primary:hover,
html[data-theme='dark'] button.bg-primary:focus,
html[data-theme='dark'] button.bg-primary:hover {
  background-color: #0062cc !important;
}
html[data-theme='dark'] .bg-secondary {
  background-color: #6c757d !important;
}
html[data-theme='dark'] a.bg-secondary:focus,
html[data-theme='dark'] a.bg-secondary:hover,
html[data-theme='dark'] button.bg-secondary:focus,
html[data-theme='dark'] button.bg-secondary:hover {
  background-color: #545b62 !important;
}
html[data-theme='dark'] .bg-success {
  background-color: #28a745 !important;
}
html[data-theme='dark'] a.bg-success:focus,
html[data-theme='dark'] a.bg-success:hover,
html[data-theme='dark'] button.bg-success:focus,
html[data-theme='dark'] button.bg-success:hover {
  background-color: #1e7e34 !important;
}
html[data-theme='dark'] .bg-info {
  background-color: #17a2b8 !important;
}
html[data-theme='dark'] a.bg-info:focus,
html[data-theme='dark'] a.bg-info:hover,
html[data-theme='dark'] button.bg-info:focus,
html[data-theme='dark'] button.bg-info:hover {
  background-color: #117a8b !important;
}
html[data-theme='dark'] .bg-warning {
  background-color: #ffc107 !important;
}
html[data-theme='dark'] a.bg-warning:focus,
html[data-theme='dark'] a.bg-warning:hover,
html[data-theme='dark'] button.bg-warning:focus,
html[data-theme='dark'] button.bg-warning:hover {
  background-color: #d39e00 !important;
}
html[data-theme='dark'] .bg-danger {
  background-color: #dc3545 !important;
}
html[data-theme='dark'] a.bg-danger:focus,
html[data-theme='dark'] a.bg-danger:hover,
html[data-theme='dark'] button.bg-danger:focus,
html[data-theme='dark'] button.bg-danger:hover {
  background-color: #bd2130 !important;
}
html[data-theme='dark'] .bg-light {
  background-color: #f8f9fa !important;
}
html[data-theme='dark'] a.bg-light:focus,
html[data-theme='dark'] a.bg-light:hover,
html[data-theme='dark'] button.bg-light:focus,
html[data-theme='dark'] button.bg-light:hover {
  background-color: #dae0e5 !important;
}
html[data-theme='dark'] .bg-dark {
  background-color: #343a40 !important;
}
html[data-theme='dark'] a.bg-dark:focus,
html[data-theme='dark'] a.bg-dark:hover,
html[data-theme='dark'] button.bg-dark:focus,
html[data-theme='dark'] button.bg-dark:hover {
  background-color: #1d2124 !important;
}
html[data-theme='dark'] .bg-white {
  background-color: #fff !important;
}
html[data-theme='dark'] .bg-transparent {
  background-color: transparent !important;
}
html[data-theme='dark'] .border {
  border: 1px solid #dee2e6 !important;
}
html[data-theme='dark'] .border-top {
  border-top: 1px solid #dee2e6 !important;
}
html[data-theme='dark'] .border-right {
  border-right: 1px solid #dee2e6 !important;
}
html[data-theme='dark'] .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
html[data-theme='dark'] .border-left {
  border-left: 1px solid #dee2e6 !important;
}
html[data-theme='dark'] .border-0 {
  border: 0 !important;
}
html[data-theme='dark'] .border-top-0 {
  border-top: 0 !important;
}
html[data-theme='dark'] .border-right-0 {
  border-right: 0 !important;
}
html[data-theme='dark'] .border-bottom-0 {
  border-bottom: 0 !important;
}
html[data-theme='dark'] .border-left-0 {
  border-left: 0 !important;
}
html[data-theme='dark'] .border-primary {
  border-color: #007bff !important;
}
html[data-theme='dark'] .border-secondary {
  border-color: #6c757d !important;
}
html[data-theme='dark'] .border-success {
  border-color: #28a745 !important;
}
html[data-theme='dark'] .border-info {
  border-color: #17a2b8 !important;
}
html[data-theme='dark'] .border-warning {
  border-color: #ffc107 !important;
}
html[data-theme='dark'] .border-danger {
  border-color: #dc3545 !important;
}
html[data-theme='dark'] .border-light {
  border-color: #f8f9fa !important;
}
html[data-theme='dark'] .border-dark {
  border-color: #343a40 !important;
}
html[data-theme='dark'] .border-white {
  border-color: #fff !important;
}
html[data-theme='dark'] .rounded {
  border-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-top {
  border-top-left-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-right,
html[data-theme='dark'] .rounded-top {
  border-top-right-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-bottom,
html[data-theme='dark'] .rounded-right {
  border-bottom-right-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-bottom,
html[data-theme='dark'] .rounded-left {
  border-bottom-left-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-left {
  border-top-left-radius: 0.25rem !important;
}
html[data-theme='dark'] .rounded-circle {
  border-radius: 50% !important;
}
html[data-theme='dark'] .rounded-0 {
  border-radius: 0 !important;
}
html[data-theme='dark'] .clearfix:after {
  display: block;
  clear: both;
  content: "";
}
html[data-theme='dark'] .d-none {
  display: none !important;
}
html[data-theme='dark'] .d-inline {
  display: inline !important;
}
html[data-theme='dark'] .d-inline-block {
  display: inline-block !important;
}
html[data-theme='dark'] .d-block {
  display: block !important;
}
html[data-theme='dark'] .d-table {
  display: table !important;
}
html[data-theme='dark'] .d-table-row {
  display: table-row !important;
}
html[data-theme='dark'] .d-table-cell {
  display: table-cell !important;
}
html[data-theme='dark'] .d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
html[data-theme='dark'] .d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .d-sm-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-sm-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-sm-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-sm-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-sm-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-sm-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-sm-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .d-md-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-md-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-md-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-md-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-md-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-md-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-md-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .d-lg-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-lg-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-lg-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-lg-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-lg-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-lg-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-lg-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .d-xl-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-xl-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-xl-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-xl-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-xl-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-xl-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-xl-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  html[data-theme='dark'] .d-print-none {
    display: none !important;
  }
  html[data-theme='dark'] .d-print-inline {
    display: inline !important;
  }
  html[data-theme='dark'] .d-print-inline-block {
    display: inline-block !important;
  }
  html[data-theme='dark'] .d-print-block {
    display: block !important;
  }
  html[data-theme='dark'] .d-print-table {
    display: table !important;
  }
  html[data-theme='dark'] .d-print-table-row {
    display: table-row !important;
  }
  html[data-theme='dark'] .d-print-table-cell {
    display: table-cell !important;
  }
  html[data-theme='dark'] .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  html[data-theme='dark'] .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
html[data-theme='dark'] .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
html[data-theme='dark'] .embed-responsive:before {
  display: block;
  content: "";
}
html[data-theme='dark'] .embed-responsive .embed-responsive-item,
html[data-theme='dark'] .embed-responsive embed,
html[data-theme='dark'] .embed-responsive iframe,
html[data-theme='dark'] .embed-responsive object,
html[data-theme='dark'] .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
html[data-theme='dark'] .embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}
html[data-theme='dark'] .embed-responsive-16by9:before {
  padding-top: 56.25%;
}
html[data-theme='dark'] .embed-responsive-4by3:before {
  padding-top: 75%;
}
html[data-theme='dark'] .embed-responsive-1by1:before {
  padding-top: 100%;
}
html[data-theme='dark'] .flex-row {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
html[data-theme='dark'] .flex-column,
html[data-theme='dark'] .flex-row {
  -webkit-box-direction: normal !important;
}
html[data-theme='dark'] .flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
html[data-theme='dark'] .flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
html[data-theme='dark'] .flex-column-reverse,
html[data-theme='dark'] .flex-row-reverse {
  -webkit-box-direction: reverse !important;
}
html[data-theme='dark'] .flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
html[data-theme='dark'] .flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
html[data-theme='dark'] .flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
html[data-theme='dark'] .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
html[data-theme='dark'] .flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
html[data-theme='dark'] .flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
html[data-theme='dark'] .flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
html[data-theme='dark'] .flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
html[data-theme='dark'] .flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
html[data-theme='dark'] .justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
html[data-theme='dark'] .justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
html[data-theme='dark'] .justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
html[data-theme='dark'] .justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
html[data-theme='dark'] .justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
html[data-theme='dark'] .align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
html[data-theme='dark'] .align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
html[data-theme='dark'] .align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
html[data-theme='dark'] .align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
html[data-theme='dark'] .align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
html[data-theme='dark'] .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
html[data-theme='dark'] .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
html[data-theme='dark'] .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
html[data-theme='dark'] .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
html[data-theme='dark'] .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
html[data-theme='dark'] .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
html[data-theme='dark'] .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
html[data-theme='dark'] .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
html[data-theme='dark'] .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
html[data-theme='dark'] .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
html[data-theme='dark'] .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
html[data-theme='dark'] .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-sm-column,
  html[data-theme='dark'] .flex-sm-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='dark'] .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-md-column,
  html[data-theme='dark'] .flex-md-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='dark'] .flex-md-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-lg-column,
  html[data-theme='dark'] .flex-lg-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='dark'] .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  html[data-theme='dark'] .flex-xl-column,
  html[data-theme='dark'] .flex-xl-row {
    -webkit-box-direction: normal !important;
  }
  html[data-theme='dark'] .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  html[data-theme='dark'] .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  html[data-theme='dark'] .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  html[data-theme='dark'] .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  html[data-theme='dark'] .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  html[data-theme='dark'] .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  html[data-theme='dark'] .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  html[data-theme='dark'] .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  html[data-theme='dark'] .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  html[data-theme='dark'] .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  html[data-theme='dark'] .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  html[data-theme='dark'] .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  html[data-theme='dark'] .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  html[data-theme='dark'] .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  html[data-theme='dark'] .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  html[data-theme='dark'] .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  html[data-theme='dark'] .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  html[data-theme='dark'] .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  html[data-theme='dark'] .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  html[data-theme='dark'] .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  html[data-theme='dark'] .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  html[data-theme='dark'] .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  html[data-theme='dark'] .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  html[data-theme='dark'] .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  html[data-theme='dark'] .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  html[data-theme='dark'] .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  html[data-theme='dark'] .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  html[data-theme='dark'] .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  html[data-theme='dark'] .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  html[data-theme='dark'] .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  html[data-theme='dark'] .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
html[data-theme='dark'] .float-left {
  float: left !important;
}
html[data-theme='dark'] .float-right {
  float: right !important;
}
html[data-theme='dark'] .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .float-sm-left {
    float: left !important;
  }
  html[data-theme='dark'] .float-sm-right {
    float: right !important;
  }
  html[data-theme='dark'] .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .float-md-left {
    float: left !important;
  }
  html[data-theme='dark'] .float-md-right {
    float: right !important;
  }
  html[data-theme='dark'] .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .float-lg-left {
    float: left !important;
  }
  html[data-theme='dark'] .float-lg-right {
    float: right !important;
  }
  html[data-theme='dark'] .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .float-xl-left {
    float: left !important;
  }
  html[data-theme='dark'] .float-xl-right {
    float: right !important;
  }
  html[data-theme='dark'] .float-xl-none {
    float: none !important;
  }
}
html[data-theme='dark'] .position-static {
  position: static !important;
}
html[data-theme='dark'] .position-relative {
  position: relative !important;
}
html[data-theme='dark'] .position-absolute {
  position: absolute !important;
}
html[data-theme='dark'] .position-fixed {
  position: fixed !important;
}
html[data-theme='dark'] .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
html[data-theme='dark'] .fixed-top {
  top: 0;
}
html[data-theme='dark'] .fixed-bottom,
html[data-theme='dark'] .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
html[data-theme='dark'] .fixed-bottom {
  bottom: 0;
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
  html[data-theme='dark'] .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
html[data-theme='dark'] .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
html[data-theme='dark'] .sr-only-focusable:active,
html[data-theme='dark'] .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
html[data-theme='dark'] .shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
html[data-theme='dark'] .shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
html[data-theme='dark'] .shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
html[data-theme='dark'] .shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
html[data-theme='dark'] .w-25 {
  width: 25% !important;
}
html[data-theme='dark'] .w-50 {
  width: 50% !important;
}
html[data-theme='dark'] .w-75 {
  width: 75% !important;
}
html[data-theme='dark'] .w-100 {
  width: 100% !important;
}
html[data-theme='dark'] .w-auto {
  width: auto !important;
}
html[data-theme='dark'] .h-25 {
  height: 25% !important;
}
html[data-theme='dark'] .h-50 {
  height: 50% !important;
}
html[data-theme='dark'] .h-75 {
  height: 75% !important;
}
html[data-theme='dark'] .h-100 {
  height: 100% !important;
}
html[data-theme='dark'] .h-auto {
  height: auto !important;
}
html[data-theme='dark'] .mw-100 {
  max-width: 100% !important;
}
html[data-theme='dark'] .mh-100 {
  max-height: 100% !important;
}
html[data-theme='dark'] .m-0 {
  margin: 0 !important;
}
html[data-theme='dark'] .mt-0,
html[data-theme='dark'] .my-0 {
  margin-top: 0 !important;
}
html[data-theme='dark'] .mr-0,
html[data-theme='dark'] .mx-0 {
  margin-right: 0 !important;
}
html[data-theme='dark'] .mb-0,
html[data-theme='dark'] .my-0 {
  margin-bottom: 0 !important;
}
html[data-theme='dark'] .ml-0,
html[data-theme='dark'] .mx-0 {
  margin-left: 0 !important;
}
html[data-theme='dark'] .m-1 {
  margin: 0.25rem !important;
}
html[data-theme='dark'] .mt-1,
html[data-theme='dark'] .my-1 {
  margin-top: 0.25rem !important;
}
html[data-theme='dark'] .mr-1,
html[data-theme='dark'] .mx-1 {
  margin-right: 0.25rem !important;
}
html[data-theme='dark'] .mb-1,
html[data-theme='dark'] .my-1 {
  margin-bottom: 0.25rem !important;
}
html[data-theme='dark'] .ml-1,
html[data-theme='dark'] .mx-1 {
  margin-left: 0.25rem !important;
}
html[data-theme='dark'] .m-2 {
  margin: 0.5rem !important;
}
html[data-theme='dark'] .mt-2,
html[data-theme='dark'] .my-2 {
  margin-top: 0.5rem !important;
}
html[data-theme='dark'] .mr-2,
html[data-theme='dark'] .mx-2 {
  margin-right: 0.5rem !important;
}
html[data-theme='dark'] .mb-2,
html[data-theme='dark'] .my-2 {
  margin-bottom: 0.5rem !important;
}
html[data-theme='dark'] .ml-2,
html[data-theme='dark'] .mx-2 {
  margin-left: 0.5rem !important;
}
html[data-theme='dark'] .m-3 {
  margin: 1rem !important;
}
html[data-theme='dark'] .mt-3,
html[data-theme='dark'] .my-3 {
  margin-top: 1rem !important;
}
html[data-theme='dark'] .mr-3,
html[data-theme='dark'] .mx-3 {
  margin-right: 1rem !important;
}
html[data-theme='dark'] .mb-3,
html[data-theme='dark'] .my-3 {
  margin-bottom: 1rem !important;
}
html[data-theme='dark'] .ml-3,
html[data-theme='dark'] .mx-3 {
  margin-left: 1rem !important;
}
html[data-theme='dark'] .m-4 {
  margin: 1.5rem !important;
}
html[data-theme='dark'] .mt-4,
html[data-theme='dark'] .my-4 {
  margin-top: 1.5rem !important;
}
html[data-theme='dark'] .mr-4,
html[data-theme='dark'] .mx-4 {
  margin-right: 1.5rem !important;
}
html[data-theme='dark'] .mb-4,
html[data-theme='dark'] .my-4 {
  margin-bottom: 1.5rem !important;
}
html[data-theme='dark'] .ml-4,
html[data-theme='dark'] .mx-4 {
  margin-left: 1.5rem !important;
}
html[data-theme='dark'] .m-5 {
  margin: 3rem !important;
}
html[data-theme='dark'] .mt-5,
html[data-theme='dark'] .my-5 {
  margin-top: 3rem !important;
}
html[data-theme='dark'] .mr-5,
html[data-theme='dark'] .mx-5 {
  margin-right: 3rem !important;
}
html[data-theme='dark'] .mb-5,
html[data-theme='dark'] .my-5 {
  margin-bottom: 3rem !important;
}
html[data-theme='dark'] .ml-5,
html[data-theme='dark'] .mx-5 {
  margin-left: 3rem !important;
}
html[data-theme='dark'] .p-0 {
  padding: 0 !important;
}
html[data-theme='dark'] .pt-0,
html[data-theme='dark'] .py-0 {
  padding-top: 0 !important;
}
html[data-theme='dark'] .pr-0,
html[data-theme='dark'] .px-0 {
  padding-right: 0 !important;
}
html[data-theme='dark'] .pb-0,
html[data-theme='dark'] .py-0 {
  padding-bottom: 0 !important;
}
html[data-theme='dark'] .pl-0,
html[data-theme='dark'] .px-0 {
  padding-left: 0 !important;
}
html[data-theme='dark'] .p-1 {
  padding: 0.25rem !important;
}
html[data-theme='dark'] .pt-1,
html[data-theme='dark'] .py-1 {
  padding-top: 0.25rem !important;
}
html[data-theme='dark'] .pr-1,
html[data-theme='dark'] .px-1 {
  padding-right: 0.25rem !important;
}
html[data-theme='dark'] .pb-1,
html[data-theme='dark'] .py-1 {
  padding-bottom: 0.25rem !important;
}
html[data-theme='dark'] .pl-1,
html[data-theme='dark'] .px-1 {
  padding-left: 0.25rem !important;
}
html[data-theme='dark'] .p-2 {
  padding: 0.5rem !important;
}
html[data-theme='dark'] .pt-2,
html[data-theme='dark'] .py-2 {
  padding-top: 0.5rem !important;
}
html[data-theme='dark'] .pr-2,
html[data-theme='dark'] .px-2 {
  padding-right: 0.5rem !important;
}
html[data-theme='dark'] .pb-2,
html[data-theme='dark'] .py-2 {
  padding-bottom: 0.5rem !important;
}
html[data-theme='dark'] .pl-2,
html[data-theme='dark'] .px-2 {
  padding-left: 0.5rem !important;
}
html[data-theme='dark'] .p-3 {
  padding: 1rem !important;
}
html[data-theme='dark'] .pt-3,
html[data-theme='dark'] .py-3 {
  padding-top: 1rem !important;
}
html[data-theme='dark'] .pr-3,
html[data-theme='dark'] .px-3 {
  padding-right: 1rem !important;
}
html[data-theme='dark'] .pb-3,
html[data-theme='dark'] .py-3 {
  padding-bottom: 1rem !important;
}
html[data-theme='dark'] .pl-3,
html[data-theme='dark'] .px-3 {
  padding-left: 1rem !important;
}
html[data-theme='dark'] .p-4 {
  padding: 1.5rem !important;
}
html[data-theme='dark'] .pt-4,
html[data-theme='dark'] .py-4 {
  padding-top: 1.5rem !important;
}
html[data-theme='dark'] .pr-4,
html[data-theme='dark'] .px-4 {
  padding-right: 1.5rem !important;
}
html[data-theme='dark'] .pb-4,
html[data-theme='dark'] .py-4 {
  padding-bottom: 1.5rem !important;
}
html[data-theme='dark'] .pl-4,
html[data-theme='dark'] .px-4 {
  padding-left: 1.5rem !important;
}
html[data-theme='dark'] .p-5 {
  padding: 3rem !important;
}
html[data-theme='dark'] .pt-5,
html[data-theme='dark'] .py-5 {
  padding-top: 3rem !important;
}
html[data-theme='dark'] .pr-5,
html[data-theme='dark'] .px-5 {
  padding-right: 3rem !important;
}
html[data-theme='dark'] .pb-5,
html[data-theme='dark'] .py-5 {
  padding-bottom: 3rem !important;
}
html[data-theme='dark'] .pl-5,
html[data-theme='dark'] .px-5 {
  padding-left: 3rem !important;
}
html[data-theme='dark'] .m-auto {
  margin: auto !important;
}
html[data-theme='dark'] .mt-auto,
html[data-theme='dark'] .my-auto {
  margin-top: auto !important;
}
html[data-theme='dark'] .mr-auto,
html[data-theme='dark'] .mx-auto {
  margin-right: auto !important;
}
html[data-theme='dark'] .mb-auto,
html[data-theme='dark'] .my-auto {
  margin-bottom: auto !important;
}
html[data-theme='dark'] .ml-auto,
html[data-theme='dark'] .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .m-sm-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-sm-0,
  html[data-theme='dark'] .my-sm-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-sm-0,
  html[data-theme='dark'] .mx-sm-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-sm-0,
  html[data-theme='dark'] .my-sm-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-sm-0,
  html[data-theme='dark'] .mx-sm-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-sm-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-sm-1,
  html[data-theme='dark'] .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-sm-1,
  html[data-theme='dark'] .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-sm-1,
  html[data-theme='dark'] .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-sm-1,
  html[data-theme='dark'] .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-sm-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-2,
  html[data-theme='dark'] .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-2,
  html[data-theme='dark'] .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-2,
  html[data-theme='dark'] .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-2,
  html[data-theme='dark'] .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-sm-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-sm-3,
  html[data-theme='dark'] .my-sm-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-sm-3,
  html[data-theme='dark'] .mx-sm-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-sm-3,
  html[data-theme='dark'] .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-sm-3,
  html[data-theme='dark'] .mx-sm-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-sm-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-sm-4,
  html[data-theme='dark'] .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-sm-4,
  html[data-theme='dark'] .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-sm-4,
  html[data-theme='dark'] .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-sm-4,
  html[data-theme='dark'] .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-sm-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-sm-5,
  html[data-theme='dark'] .my-sm-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-sm-5,
  html[data-theme='dark'] .mx-sm-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-sm-5,
  html[data-theme='dark'] .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-sm-5,
  html[data-theme='dark'] .mx-sm-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-sm-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-sm-0,
  html[data-theme='dark'] .py-sm-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-sm-0,
  html[data-theme='dark'] .px-sm-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-sm-0,
  html[data-theme='dark'] .py-sm-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-sm-0,
  html[data-theme='dark'] .px-sm-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-sm-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-sm-1,
  html[data-theme='dark'] .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-sm-1,
  html[data-theme='dark'] .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-sm-1,
  html[data-theme='dark'] .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-sm-1,
  html[data-theme='dark'] .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-sm-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-sm-2,
  html[data-theme='dark'] .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-sm-2,
  html[data-theme='dark'] .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-sm-2,
  html[data-theme='dark'] .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-sm-2,
  html[data-theme='dark'] .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-sm-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-sm-3,
  html[data-theme='dark'] .py-sm-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-sm-3,
  html[data-theme='dark'] .px-sm-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-sm-3,
  html[data-theme='dark'] .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-sm-3,
  html[data-theme='dark'] .px-sm-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-sm-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-sm-4,
  html[data-theme='dark'] .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-sm-4,
  html[data-theme='dark'] .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-sm-4,
  html[data-theme='dark'] .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-sm-4,
  html[data-theme='dark'] .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-sm-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-sm-5,
  html[data-theme='dark'] .py-sm-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-sm-5,
  html[data-theme='dark'] .px-sm-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-sm-5,
  html[data-theme='dark'] .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-sm-5,
  html[data-theme='dark'] .px-sm-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-sm-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-sm-auto,
  html[data-theme='dark'] .my-sm-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-sm-auto,
  html[data-theme='dark'] .mx-sm-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-sm-auto,
  html[data-theme='dark'] .my-sm-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-sm-auto,
  html[data-theme='dark'] .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .m-md-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-md-0,
  html[data-theme='dark'] .my-md-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-md-0,
  html[data-theme='dark'] .mx-md-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-md-0,
  html[data-theme='dark'] .my-md-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-md-0,
  html[data-theme='dark'] .mx-md-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-md-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-md-1,
  html[data-theme='dark'] .my-md-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-md-1,
  html[data-theme='dark'] .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-md-1,
  html[data-theme='dark'] .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-md-1,
  html[data-theme='dark'] .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-md-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-md-2,
  html[data-theme='dark'] .my-md-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-md-2,
  html[data-theme='dark'] .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-md-2,
  html[data-theme='dark'] .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-md-2,
  html[data-theme='dark'] .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-md-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-md-3,
  html[data-theme='dark'] .my-md-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-md-3,
  html[data-theme='dark'] .mx-md-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-md-3,
  html[data-theme='dark'] .my-md-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-md-3,
  html[data-theme='dark'] .mx-md-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-md-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-md-4,
  html[data-theme='dark'] .my-md-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-md-4,
  html[data-theme='dark'] .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-md-4,
  html[data-theme='dark'] .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-md-4,
  html[data-theme='dark'] .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-md-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-md-5,
  html[data-theme='dark'] .my-md-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-md-5,
  html[data-theme='dark'] .mx-md-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-md-5,
  html[data-theme='dark'] .my-md-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-md-5,
  html[data-theme='dark'] .mx-md-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-md-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-md-0,
  html[data-theme='dark'] .py-md-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-md-0,
  html[data-theme='dark'] .px-md-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-md-0,
  html[data-theme='dark'] .py-md-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-md-0,
  html[data-theme='dark'] .px-md-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-md-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-md-1,
  html[data-theme='dark'] .py-md-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-md-1,
  html[data-theme='dark'] .px-md-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-md-1,
  html[data-theme='dark'] .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-md-1,
  html[data-theme='dark'] .px-md-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-md-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-md-2,
  html[data-theme='dark'] .py-md-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-md-2,
  html[data-theme='dark'] .px-md-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-md-2,
  html[data-theme='dark'] .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-md-2,
  html[data-theme='dark'] .px-md-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-md-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-md-3,
  html[data-theme='dark'] .py-md-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-md-3,
  html[data-theme='dark'] .px-md-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-md-3,
  html[data-theme='dark'] .py-md-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-md-3,
  html[data-theme='dark'] .px-md-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-md-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-md-4,
  html[data-theme='dark'] .py-md-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-md-4,
  html[data-theme='dark'] .px-md-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-md-4,
  html[data-theme='dark'] .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-md-4,
  html[data-theme='dark'] .px-md-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-md-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-md-5,
  html[data-theme='dark'] .py-md-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-md-5,
  html[data-theme='dark'] .px-md-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-md-5,
  html[data-theme='dark'] .py-md-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-md-5,
  html[data-theme='dark'] .px-md-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-md-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-md-auto,
  html[data-theme='dark'] .my-md-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-md-auto,
  html[data-theme='dark'] .mx-md-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-md-auto,
  html[data-theme='dark'] .my-md-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-md-auto,
  html[data-theme='dark'] .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .m-lg-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-lg-0,
  html[data-theme='dark'] .my-lg-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-lg-0,
  html[data-theme='dark'] .mx-lg-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-lg-0,
  html[data-theme='dark'] .my-lg-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-lg-0,
  html[data-theme='dark'] .mx-lg-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-lg-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-lg-1,
  html[data-theme='dark'] .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-lg-1,
  html[data-theme='dark'] .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-lg-1,
  html[data-theme='dark'] .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-lg-1,
  html[data-theme='dark'] .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-lg-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-2,
  html[data-theme='dark'] .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-2,
  html[data-theme='dark'] .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-2,
  html[data-theme='dark'] .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-2,
  html[data-theme='dark'] .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-lg-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-lg-3,
  html[data-theme='dark'] .my-lg-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-lg-3,
  html[data-theme='dark'] .mx-lg-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-lg-3,
  html[data-theme='dark'] .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-lg-3,
  html[data-theme='dark'] .mx-lg-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-lg-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-lg-4,
  html[data-theme='dark'] .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-lg-4,
  html[data-theme='dark'] .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-lg-4,
  html[data-theme='dark'] .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-lg-4,
  html[data-theme='dark'] .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-lg-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-lg-5,
  html[data-theme='dark'] .my-lg-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-lg-5,
  html[data-theme='dark'] .mx-lg-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-lg-5,
  html[data-theme='dark'] .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-lg-5,
  html[data-theme='dark'] .mx-lg-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-lg-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-lg-0,
  html[data-theme='dark'] .py-lg-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-lg-0,
  html[data-theme='dark'] .px-lg-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-lg-0,
  html[data-theme='dark'] .py-lg-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-lg-0,
  html[data-theme='dark'] .px-lg-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-lg-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-lg-1,
  html[data-theme='dark'] .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-lg-1,
  html[data-theme='dark'] .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-lg-1,
  html[data-theme='dark'] .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-lg-1,
  html[data-theme='dark'] .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-lg-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-lg-2,
  html[data-theme='dark'] .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-lg-2,
  html[data-theme='dark'] .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-lg-2,
  html[data-theme='dark'] .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-lg-2,
  html[data-theme='dark'] .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-lg-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-lg-3,
  html[data-theme='dark'] .py-lg-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-lg-3,
  html[data-theme='dark'] .px-lg-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-lg-3,
  html[data-theme='dark'] .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-lg-3,
  html[data-theme='dark'] .px-lg-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-lg-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-lg-4,
  html[data-theme='dark'] .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-lg-4,
  html[data-theme='dark'] .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-lg-4,
  html[data-theme='dark'] .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-lg-4,
  html[data-theme='dark'] .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-lg-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-lg-5,
  html[data-theme='dark'] .py-lg-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-lg-5,
  html[data-theme='dark'] .px-lg-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-lg-5,
  html[data-theme='dark'] .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-lg-5,
  html[data-theme='dark'] .px-lg-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-lg-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-lg-auto,
  html[data-theme='dark'] .my-lg-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-lg-auto,
  html[data-theme='dark'] .mx-lg-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-lg-auto,
  html[data-theme='dark'] .my-lg-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-lg-auto,
  html[data-theme='dark'] .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .m-xl-0 {
    margin: 0 !important;
  }
  html[data-theme='dark'] .mt-xl-0,
  html[data-theme='dark'] .my-xl-0 {
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .mr-xl-0,
  html[data-theme='dark'] .mx-xl-0 {
    margin-right: 0 !important;
  }
  html[data-theme='dark'] .mb-xl-0,
  html[data-theme='dark'] .my-xl-0 {
    margin-bottom: 0 !important;
  }
  html[data-theme='dark'] .ml-xl-0,
  html[data-theme='dark'] .mx-xl-0 {
    margin-left: 0 !important;
  }
  html[data-theme='dark'] .m-xl-1 {
    margin: 0.25rem !important;
  }
  html[data-theme='dark'] .mt-xl-1,
  html[data-theme='dark'] .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  html[data-theme='dark'] .mr-xl-1,
  html[data-theme='dark'] .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  html[data-theme='dark'] .mb-xl-1,
  html[data-theme='dark'] .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .ml-xl-1,
  html[data-theme='dark'] .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  html[data-theme='dark'] .m-xl-2 {
    margin: 0.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-2,
  html[data-theme='dark'] .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-2,
  html[data-theme='dark'] .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-2,
  html[data-theme='dark'] .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-2,
  html[data-theme='dark'] .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  html[data-theme='dark'] .m-xl-3 {
    margin: 1rem !important;
  }
  html[data-theme='dark'] .mt-xl-3,
  html[data-theme='dark'] .my-xl-3 {
    margin-top: 1rem !important;
  }
  html[data-theme='dark'] .mr-xl-3,
  html[data-theme='dark'] .mx-xl-3 {
    margin-right: 1rem !important;
  }
  html[data-theme='dark'] .mb-xl-3,
  html[data-theme='dark'] .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  html[data-theme='dark'] .ml-xl-3,
  html[data-theme='dark'] .mx-xl-3 {
    margin-left: 1rem !important;
  }
  html[data-theme='dark'] .m-xl-4 {
    margin: 1.5rem !important;
  }
  html[data-theme='dark'] .mt-xl-4,
  html[data-theme='dark'] .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  html[data-theme='dark'] .mr-xl-4,
  html[data-theme='dark'] .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  html[data-theme='dark'] .mb-xl-4,
  html[data-theme='dark'] .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .ml-xl-4,
  html[data-theme='dark'] .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  html[data-theme='dark'] .m-xl-5 {
    margin: 3rem !important;
  }
  html[data-theme='dark'] .mt-xl-5,
  html[data-theme='dark'] .my-xl-5 {
    margin-top: 3rem !important;
  }
  html[data-theme='dark'] .mr-xl-5,
  html[data-theme='dark'] .mx-xl-5 {
    margin-right: 3rem !important;
  }
  html[data-theme='dark'] .mb-xl-5,
  html[data-theme='dark'] .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  html[data-theme='dark'] .ml-xl-5,
  html[data-theme='dark'] .mx-xl-5 {
    margin-left: 3rem !important;
  }
  html[data-theme='dark'] .p-xl-0 {
    padding: 0 !important;
  }
  html[data-theme='dark'] .pt-xl-0,
  html[data-theme='dark'] .py-xl-0 {
    padding-top: 0 !important;
  }
  html[data-theme='dark'] .pr-xl-0,
  html[data-theme='dark'] .px-xl-0 {
    padding-right: 0 !important;
  }
  html[data-theme='dark'] .pb-xl-0,
  html[data-theme='dark'] .py-xl-0 {
    padding-bottom: 0 !important;
  }
  html[data-theme='dark'] .pl-xl-0,
  html[data-theme='dark'] .px-xl-0 {
    padding-left: 0 !important;
  }
  html[data-theme='dark'] .p-xl-1 {
    padding: 0.25rem !important;
  }
  html[data-theme='dark'] .pt-xl-1,
  html[data-theme='dark'] .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  html[data-theme='dark'] .pr-xl-1,
  html[data-theme='dark'] .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  html[data-theme='dark'] .pb-xl-1,
  html[data-theme='dark'] .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  html[data-theme='dark'] .pl-xl-1,
  html[data-theme='dark'] .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  html[data-theme='dark'] .p-xl-2 {
    padding: 0.5rem !important;
  }
  html[data-theme='dark'] .pt-xl-2,
  html[data-theme='dark'] .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  html[data-theme='dark'] .pr-xl-2,
  html[data-theme='dark'] .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  html[data-theme='dark'] .pb-xl-2,
  html[data-theme='dark'] .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  html[data-theme='dark'] .pl-xl-2,
  html[data-theme='dark'] .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  html[data-theme='dark'] .p-xl-3 {
    padding: 1rem !important;
  }
  html[data-theme='dark'] .pt-xl-3,
  html[data-theme='dark'] .py-xl-3 {
    padding-top: 1rem !important;
  }
  html[data-theme='dark'] .pr-xl-3,
  html[data-theme='dark'] .px-xl-3 {
    padding-right: 1rem !important;
  }
  html[data-theme='dark'] .pb-xl-3,
  html[data-theme='dark'] .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  html[data-theme='dark'] .pl-xl-3,
  html[data-theme='dark'] .px-xl-3 {
    padding-left: 1rem !important;
  }
  html[data-theme='dark'] .p-xl-4 {
    padding: 1.5rem !important;
  }
  html[data-theme='dark'] .pt-xl-4,
  html[data-theme='dark'] .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  html[data-theme='dark'] .pr-xl-4,
  html[data-theme='dark'] .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  html[data-theme='dark'] .pb-xl-4,
  html[data-theme='dark'] .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  html[data-theme='dark'] .pl-xl-4,
  html[data-theme='dark'] .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  html[data-theme='dark'] .p-xl-5 {
    padding: 3rem !important;
  }
  html[data-theme='dark'] .pt-xl-5,
  html[data-theme='dark'] .py-xl-5 {
    padding-top: 3rem !important;
  }
  html[data-theme='dark'] .pr-xl-5,
  html[data-theme='dark'] .px-xl-5 {
    padding-right: 3rem !important;
  }
  html[data-theme='dark'] .pb-xl-5,
  html[data-theme='dark'] .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  html[data-theme='dark'] .pl-xl-5,
  html[data-theme='dark'] .px-xl-5 {
    padding-left: 3rem !important;
  }
  html[data-theme='dark'] .m-xl-auto {
    margin: auto !important;
  }
  html[data-theme='dark'] .mt-xl-auto,
  html[data-theme='dark'] .my-xl-auto {
    margin-top: auto !important;
  }
  html[data-theme='dark'] .mr-xl-auto,
  html[data-theme='dark'] .mx-xl-auto {
    margin-right: auto !important;
  }
  html[data-theme='dark'] .mb-xl-auto,
  html[data-theme='dark'] .my-xl-auto {
    margin-bottom: auto !important;
  }
  html[data-theme='dark'] .ml-xl-auto,
  html[data-theme='dark'] .mx-xl-auto {
    margin-left: auto !important;
  }
}
html[data-theme='dark'] .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}
html[data-theme='dark'] .text-justify {
  text-align: justify !important;
}
html[data-theme='dark'] .text-nowrap {
  white-space: nowrap !important;
}
html[data-theme='dark'] .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html[data-theme='dark'] .text-left {
  text-align: left !important;
}
html[data-theme='dark'] .text-right {
  text-align: right !important;
}
html[data-theme='dark'] .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  html[data-theme='dark'] .text-sm-left {
    text-align: left !important;
  }
  html[data-theme='dark'] .text-sm-right {
    text-align: right !important;
  }
  html[data-theme='dark'] .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  html[data-theme='dark'] .text-md-left {
    text-align: left !important;
  }
  html[data-theme='dark'] .text-md-right {
    text-align: right !important;
  }
  html[data-theme='dark'] .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .text-lg-left {
    text-align: left !important;
  }
  html[data-theme='dark'] .text-lg-right {
    text-align: right !important;
  }
  html[data-theme='dark'] .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  html[data-theme='dark'] .text-xl-left {
    text-align: left !important;
  }
  html[data-theme='dark'] .text-xl-right {
    text-align: right !important;
  }
  html[data-theme='dark'] .text-xl-center {
    text-align: center !important;
  }
}
html[data-theme='dark'] .text-lowercase {
  text-transform: lowercase !important;
}
html[data-theme='dark'] .text-uppercase {
  text-transform: uppercase !important;
}
html[data-theme='dark'] .text-capitalize {
  text-transform: capitalize !important;
}
html[data-theme='dark'] .font-weight-light {
  font-weight: 300 !important;
}
html[data-theme='dark'] .font-weight-normal {
  font-weight: 400 !important;
}
html[data-theme='dark'] .font-weight-bold {
  font-weight: 700 !important;
}
html[data-theme='dark'] .font-italic {
  font-style: italic !important;
}
html[data-theme='dark'] .text-white {
  color: #fff !important;
}
html[data-theme='dark'] .text-primary {
  color: #007bff !important;
}
html[data-theme='dark'] a.text-primary:focus,
html[data-theme='dark'] a.text-primary:hover {
  color: #0062cc !important;
}
html[data-theme='dark'] .text-secondary {
  color: #6c757d !important;
}
html[data-theme='dark'] a.text-secondary:focus,
html[data-theme='dark'] a.text-secondary:hover {
  color: #545b62 !important;
}
html[data-theme='dark'] .text-success {
  color: #28a745 !important;
}
html[data-theme='dark'] a.text-success:focus,
html[data-theme='dark'] a.text-success:hover {
  color: #1e7e34 !important;
}
html[data-theme='dark'] .text-info {
  color: #17a2b8 !important;
}
html[data-theme='dark'] a.text-info:focus,
html[data-theme='dark'] a.text-info:hover {
  color: #117a8b !important;
}
html[data-theme='dark'] .text-warning {
  color: #ffc107 !important;
}
html[data-theme='dark'] a.text-warning:focus,
html[data-theme='dark'] a.text-warning:hover {
  color: #d39e00 !important;
}
html[data-theme='dark'] .text-danger {
  color: #dc3545 !important;
}
html[data-theme='dark'] a.text-danger:focus,
html[data-theme='dark'] a.text-danger:hover {
  color: #bd2130 !important;
}
html[data-theme='dark'] .text-light {
  color: #f8f9fa !important;
}
html[data-theme='dark'] a.text-light:focus,
html[data-theme='dark'] a.text-light:hover {
  color: #dae0e5 !important;
}
html[data-theme='dark'] .text-dark {
  color: #343a40 !important;
}
html[data-theme='dark'] a.text-dark:focus,
html[data-theme='dark'] a.text-dark:hover {
  color: #1d2124 !important;
}
html[data-theme='dark'] .text-body {
  color: #212529 !important;
}
html[data-theme='dark'] .text-muted {
  color: #6c757d !important;
}
html[data-theme='dark'] .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
html[data-theme='dark'] .text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}
html[data-theme='dark'] .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
html[data-theme='dark'] .visible {
  visibility: visible !important;
}
html[data-theme='dark'] .invisible {
  visibility: hidden !important;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Roboto-Bold-Italic.svg#Roboto-Bold-Italic') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Merriweather;
  src: url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.woff2') format('woff2'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.woff') format('woff'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.ttf') format('truetype'), url('../../../node_modules/ui-library/dist/assets/fonts/Merriweather-Black.svg#Merriweather-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}
html[data-theme='dark'] html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.5;
}
html[data-theme='dark'] html.font-size-m {
  font-size: 71.4286%;
}
html[data-theme='dark'] html.font-size-l {
  font-size: 80.357%;
}
html[data-theme='dark'] body {
  position: relative;
  margin: 0;
  padding: 0;
  color: #e4e8ee;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='dark'] p {
  margin-bottom: 10px;
}
html[data-theme='dark'] #root {
  min-height: 100vh;
}
html[data-theme='dark'] .content {
  background-color: #7171a7;
}
html[data-theme='dark'] .calendar-picker .ant-calendar {
  margin-left: -294px;
}
html[data-theme='dark'] .calendar {
  height: 720px;
}
html[data-theme='dark'] .calendar .ant-tabs-bar {
  margin-bottom: 0;
}
html[data-theme='dark'] .calendar .tab-current .button.button_icon {
  min-width: 20px;
  padding: 0;
  border: none;
}
html[data-theme='dark'] .calendar .tab-current .button.button_icon.arrow_left {
  margin-right: 15px;
}
html[data-theme='dark'] .calendar .tab-current .button.button_icon.arrow_right {
  margin-left: 15px;
}
html[data-theme='dark'] .calendar .tab-current .button.button_icon:focus {
  outline: none;
}
html[data-theme='dark'] .calendar .tab-current .button.button_icon:after {
  opacity: 0;
}
html[data-theme='dark'] .calendar .tab-current .ant-calendar-picker {
  width: 18px;
  margin-right: 15px;
  cursor: pointer;
}
html[data-theme='dark'] .calendar .tab-current .ant-calendar-picker input {
  border: none;
  cursor: pointer;
}
html[data-theme='dark'] .calendar .tab-current .ant-calendar-picker .ant-calendar-picker-icon {
  right: -1px;
  width: 18px;
  height: 18px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
}
html[data-theme='dark'] .calendar .tab-current .ant-calendar-picker .Icon {
  padding: 0;
}
html[data-theme='dark'] .calendar .tab-current .Icon {
  padding: 5px;
}
html[data-theme='dark'] .calendar .calendar-header-panel h3 {
  margin: 15px 0 10px;
}
html[data-theme='dark'] .calendar .calendar-header-panel p {
  margin-bottom: 15px;
}
html[data-theme='dark'] .calendar .calendar-header-panel .tab-current {
  display: flex;
  justify-content: center;
  align-items: center;
}
html[data-theme='dark'] .calendar .calendar-header-panel .button {
  height: 20px;
  min-width: 70px;
  padding: 3px 10px;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.16666667;
}
html[data-theme='dark'] .calendar .rbc-month-view {
  border-top: none;
  border-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-month-header {
  position: relative;
  flex: 1 0 auto;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-month-header:before {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #fff;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-month-header:after {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #fff;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-header {
  border-color: #f0f3f6;
  border-left: none;
  padding: 0 5px 15px;
  color: #e4e8ee;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
  font-weight: normal;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-month-row {
  flex: auto;
  overflow: visible;
  height: 100%;
  flex-basis: auto;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-row-bg {
  position: relative;
  overflow: visible;
  height: 100%;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-row-content {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  position: absolute;
  height: 100%;
  min-height: 97px;
  width: 100%;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-row-content .rbc-show-more {
  margin-left: 14px;
  color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.69230769;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-date-cell {
  padding-top: 1px;
  padding-right: 7px;
  color: #ffffff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-date-cell.rbc-current a {
  position: relative;
  left: -3px;
  display: inline-block;
  color: #fff;
  letter-spacing: -2px;
  text-indent: -2px;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-date-cell.rbc-current a:before {
  position: absolute;
  left: calc(50% - 9px);
  z-index: -1;
  display: inline-block;
  width: 19px;
  height: 19px;
  content: '';
  border-radius: 50%;
  background-color: #3851d1;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-day-bg {
  position: relative;
  display: block;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
html[data-theme='dark'] .calendar .rbc-row-segment {
  padding: 0;
}
html[data-theme='dark'] .calendar .rbc-event {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.15384615;
  font-weight: bold;
  padding: 0;
  border: none;
  color: #4c5484;
  border-radius: 0;
  background-color: transparent;
}
html[data-theme='dark'] .calendar .rbc-event .rbc-event-content {
  background-color: #d2d8ff;
  padding: 3px 0;
  width: 100%;
}
html[data-theme='dark'] .calendar .rbc-event.event-purple {
  color: #7b4c84;
}
html[data-theme='dark'] .calendar .rbc-event.event-purple .rbc-event-content {
  background-color: #f6c8ff;
}
html[data-theme='dark'] .calendar .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #7b4c84;
}
html[data-theme='dark'] .calendar .rbc-event.event-orange {
  color: #ce805e;
}
html[data-theme='dark'] .calendar .rbc-event.event-orange .rbc-event-content {
  background-color: #ffe6db;
}
html[data-theme='dark'] .calendar .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #ce805e;
}
html[data-theme='dark'] .calendar .rbc-event.event-green {
  color: #72a356;
}
html[data-theme='dark'] .calendar .rbc-event.event-green .rbc-event-content {
  background-color: #d5ffbe;
}
html[data-theme='dark'] .calendar .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #72a356;
}
html[data-theme='dark'] .calendar .rbc-event:focus {
  outline: none;
}
html[data-theme='dark'] .calendar .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
  background-color: #4c5484;
  color: #fff;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday):not(.ant-popover-open) .rbc-event-content {
  background-color: transparent;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday) .rbc-event-content:before {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 5px;
  height: 5px;
  content: '';
  background-color: #4c5484;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 5px;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-purple .rbc-event-content:before {
  background-color: #7b4c84;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-orange .rbc-event-content:before {
  background-color: #ce805e;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-green .rbc-event-content:before {
  background-color: #72a356;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content:before {
  background-color: #fff;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event.rbc-event-allday {
  width: calc(100% - 2px);
  margin: 0 1px;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event.rbc-event-allday .rbc-event-content {
  padding: 3px 5px;
}
html[data-theme='dark'] .calendar .rbc-month-view .rbc-event.rbc-event-allday:before {
  display: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event .rbc-event-label {
  display: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event .rbc-event-content {
  padding-left: 5px;
  margin-bottom: 1px;
  border-left: 3px solid #4c5484;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.15384615;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event .rbc-event-content .event-start {
  font-weight: normal;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.rbc-event-allday .rbc-event-content {
  margin-top: 2px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #4c5484;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-purple .rbc-event-content {
  border-color: #7b4c84;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #7b4c84;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-orange .rbc-event-content {
  border-color: #ce805e;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #ce805e;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-green .rbc-event-content {
  border-color: #72a356;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
  border-color: #72a356;
}
html[data-theme='dark'] .calendar.calendar-week .rbc-label {
  margin-top: 37px;
}
html[data-theme='dark'] .calendar.calendar-week .rbc-current-time-indicator {
  display: none;
  background-color: #3851d1;
}
html[data-theme='dark'] .calendar.calendar-day .rbc-time-view .rbc-time-header:after {
  top: 0;
}
html[data-theme='dark'] .calendar.calendar-day .rbc-time-view .rbc-time-header .rbc-allday-cell {
  min-height: 24px;
}
html[data-theme='dark'] .calendar.calendar-day .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
  min-height: 0;
}
html[data-theme='dark'] .calendar.calendar-day .rbc-time-view .current-time-dot {
  left: -1px;
  margin-top: -5.5px;
}
html[data-theme='dark'] .calendar.calendar-day .rbc-time-view .rbc-current-time-indicator {
  display: none;
}
html[data-theme='dark'] .calendar .rbc-time-view {
  position: relative;
  border: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-content {
  overflow-x: hidden;
  border-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-today {
  background-color: transparent;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-day-slot .rbc-time-slot {
  border: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-day-slot .rbc-timeslot-group {
  border-top: 1px solid #f0f3f6;
  border-bottom: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column {
  margin-top: 25px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column:not(:nth-child(1)) .current-time {
  display: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column:not(:nth-child(2)) .current-time-dot {
  display: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column:not(:first-child):after {
  position: absolute;
  top: -25px;
  right: -1px;
  width: 1px;
  height: 25px;
  content: '';
  background-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column .rbc-timeslot-group {
  min-height: 49px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-column .rbc-time-slot {
  margin-top: -7px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-content > * + * > * {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-content > *:first-child + * > * {
  border-left: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-timeslot-group {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-gutter.rbc-time-column {
  position: relative;
  background-color: #fff;
  z-index: 4;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
  color: #e4e8ee;
  text-align: right;
  border-bottom: none;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header {
  border-right: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header:after {
  position: absolute;
  top: 37px;
  left: 0;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-label {
  color: #e4e8ee;
  text-align: right;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.84615385;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-time-header-content {
  border: none;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-time-header-cell {
  min-height: auto;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-header {
  border: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
  font-weight: normal;
  padding-bottom: 15px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-header.rbc-today a span,
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-header a .rbc-today span {
  display: inline-block;
  width: 19px;
  height: 19px;
  color: #fff;
  letter-spacing: -2px;
  border-radius: 50%;
  background-color: #3851d1;
  text-indent: -2px;
  font-weight: bold;
  line-height: 19px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-header a {
  color: #e4e8ee;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-header a:after {
  position: absolute;
  top: calc(50% - 9.5px);
  left: calc(50% - 8.5px);
  z-index: -1;
  display: inline-block;
  width: 19px;
  height: 19px;
  content: '';
  border-radius: 50%;
  background-color: #3851d1;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-allday-cell {
  min-height: 24px;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
  min-height: 0;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-day-bg {
  border-left: 1px solid #f0f3f6;
}
html[data-theme='dark'] .calendar .rbc-time-view .rbc-time-header .rbc-day-bg:first-child {
  border-color: transparent;
}
html[data-theme='dark'] .calendar .rbc-time-view .current-time {
  position: absolute;
  z-index: 5;
  width: 60px;
  margin-top: -10px;
  background-color: #fff;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.69230769;
  color: #3851d1;
  text-align: right;
}
html[data-theme='dark'] .calendar .rbc-time-view .current-time-dot {
  position: absolute;
  left: calc(100% - 4.5px);
  z-index: 3;
  display: block;
  width: 11px;
  height: 11px;
  margin-top: -4.5px;
  border: 1px solid #fff !important;
  content: '';
  background-color: #3851d1;
  border-radius: 50%;
}
html[data-theme='dark'] .calendar .rbc-time-view .current-time-dot:before,
html[data-theme='dark'] .calendar .rbc-time-view .current-time-dot:after {
  top: 4px;
  position: absolute;
  right: 100%;
  display: block;
  width: 1000px;
  height: 1px;
  content: '';
  background-color: #3851d1;
}
html[data-theme='dark'] .calendar .rbc-time-view .current-time-dot:after {
  left: 100%;
}
html[data-theme='dark'] .event-popover .ant-popover-arrow {
  margin: -9px 0 0 !important;
  box-shadow: -1px -1px 0 #f0f3f6 !important;
}
html[data-theme='dark'] .event-popover .ant-popover-content {
  transform: translate(0, -6px);
}
html[data-theme='dark'] .event-popover.ant-popover-placement-top .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-topLeft .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-topRight .ant-popover-content {
  transform: translate(0, 6px);
}
html[data-theme='dark'] .event-popover.ant-popover-placement-top .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-topLeft .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-topRight .ant-popover-arrow {
  margin: 0 0 -10px !important;
  box-shadow: 1px 1px 0 #f0f3f6 !important;
}
html[data-theme='dark'] .event-popover.ant-popover-placement-left .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-leftTop .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-leftBottom .ant-popover-content {
  transform: translate(6px, 0);
}
html[data-theme='dark'] .event-popover.ant-popover-placement-left .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-leftTop .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-leftBottom .ant-popover-arrow {
  margin: 0 -9px 0 0 !important;
  box-shadow: 1px -1px 0 #f0f3f6 !important;
}
html[data-theme='dark'] .event-popover.ant-popover-placement-right .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-rightTop .ant-popover-content,
html[data-theme='dark'] .event-popover.ant-popover-placement-rightBottom .ant-popover-content {
  transform: translate(-6px, 0);
}
html[data-theme='dark'] .event-popover.ant-popover-placement-right .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-rightTop .ant-popover-arrow,
html[data-theme='dark'] .event-popover.ant-popover-placement-rightBottom .ant-popover-arrow {
  margin: 0 0 0 -9px !important;
  box-shadow: -1px 1px 0 #f0f3f6 !important;
}
html[data-theme='dark'] .event-popover .ant-popover-inner {
  border-radius: 5px;
  box-shadow: 0 0 0 1px #f0f3f6;
}
html[data-theme='dark'] .event-popover .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}
html[data-theme='dark'] .event-details {
  width: 225px;
  padding: 20px 20px 18px;
}
html[data-theme='dark'] .event-details h4 {
  margin-bottom: 7px;
}
html[data-theme='dark'] .event-details hr {
  border-top: 1px solid #7171a7;
  border-left: none;
  border-bottom: none;
  border-right: none;
}
html[data-theme='dark'] .event-details .contact-info {
  display: block;
  padding-top: 16px;
  border-top: 1px solid #7171a7;
  line-height: 1;
}
html[data-theme='dark'] .event-details .contact-info + p.paragraph_primary {
  margin-top: 16px;
}
html[data-theme='dark'] .event-details .text-underlined {
  color: #e4e8ee;
  line-height: 22px;
  text-decoration: underline;
}
html[data-theme='dark'] .event-details p.paragraph_primary {
  display: block;
  border-top: 1px solid #7171a7;
  color: #e4e8ee;
  margin-bottom: 0;
  padding-top: 7px;
}
html[data-theme='dark'] .bandwidth-chart-container {
  position: relative;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-root {
  width: 100%;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-plot {
  fill: #7171a7;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-0.chart-bar {
  fill: #e4e8ee;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-1.chart-bar {
  fill: #3851d1;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-2.chart-bar {
  fill: #a0b0ff;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-3.chart-bar {
  fill: #ffffff;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-4.chart-bar {
  fill: #6780ff;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-5.chart-bar {
  fill: #3851d1;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-6.chart-bar {
  fill: #4a4a7a;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-7.chart-bar {
  fill: #83e5cd;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-8.chart-bar {
  fill: #07cc9b;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-9.chart-bar {
  fill: #159475;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-0.chart-triangle {
  fill: #2d41a6;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-color-1.chart-triangle {
  fill: #a0b0ff;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-bar-legend {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-legend {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-xaxis-line {
  fill: none;
  stroke: #e4e8ee;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-axis-labels {
  position: absolute;
  top: 0;
  left: 0;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-axis-labels .chart-label {
  display: flex;
  align-items: center;
  height: 49px;
  margin-top: 16px;
}
html[data-theme='dark'] .bandwidth-chart-container .chart-axis-labels .chart-label .Form-control-label {
  margin-bottom: 0;
}
html[data-theme='dark'] .highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
}
html[data-theme='dark'] .highcharts-root {
  display: block;
}
html[data-theme='dark'] .highcharts-root text {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-strong {
  font-weight: bold;
}
html[data-theme='dark'] .highcharts-emphasized {
  font-style: italic;
}
html[data-theme='dark'] .highcharts-anchor {
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-background {
  fill: #ffffff;
}
html[data-theme='dark'] .highcharts-plot-border,
html[data-theme='dark'] .highcharts-plot-background {
  fill: none;
}
html[data-theme='dark'] .highcharts-label-box {
  fill: none;
}
html[data-theme='dark'] .highcharts-button-box {
  fill: inherit;
}
html[data-theme='dark'] .highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}
html[data-theme='dark'] .highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}
html[data-theme='dark'] .highcharts-subtitle {
  fill: #666666;
}
html[data-theme='dark'] .highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}
html[data-theme='dark'] .highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-axis-title {
  fill: #666666;
}
html[data-theme='dark'] .highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}
html[data-theme='dark'] .highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}
html[data-theme='dark'] .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}
html[data-theme='dark'] .highcharts-tick {
  stroke: #ccd6eb;
}
html[data-theme='dark'] .highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-minor-grid-line {
  stroke: #f2f2f2;
}
html[data-theme='dark'] .highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}
html[data-theme='dark'] .highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}
html[data-theme='dark'] .highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}
html[data-theme='dark'] .highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}
html[data-theme='dark'] .highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}
html[data-theme='dark'] .highcharts-tooltip text {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}
html[data-theme='dark'] .highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
html[data-theme='dark'] .highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
html[data-theme='dark'] .highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}
html[data-theme='dark'] .highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
html[data-theme='dark'] .highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}
html[data-theme='dark'] .highcharts-state-hover path {
  transition: stroke-width 50;
  /* quick in */
}
html[data-theme='dark'] .highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}
html[data-theme='dark'] g.highcharts-series,
html[data-theme='dark'] .highcharts-point,
html[data-theme='dark'] .highcharts-markers,
html[data-theme='dark'] .highcharts-data-labels {
  transition: opacity 250ms;
}
html[data-theme='dark'] .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
html[data-theme='dark'] .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
html[data-theme='dark'] .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
html[data-theme='dark'] .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}
html[data-theme='dark'] .highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}
html[data-theme='dark'] .highcharts-color-1 {
  fill: #434348;
  stroke: #434348;
}
html[data-theme='dark'] .highcharts-color-2 {
  fill: #90ed7d;
  stroke: #90ed7d;
}
html[data-theme='dark'] .highcharts-color-3 {
  fill: #f7a35c;
  stroke: #f7a35c;
}
html[data-theme='dark'] .highcharts-color-4 {
  fill: #8085e9;
  stroke: #8085e9;
}
html[data-theme='dark'] .highcharts-color-5 {
  fill: #f15c80;
  stroke: #f15c80;
}
html[data-theme='dark'] .highcharts-color-6 {
  fill: #e4d354;
  stroke: #e4d354;
}
html[data-theme='dark'] .highcharts-color-7 {
  fill: #2b908f;
  stroke: #2b908f;
}
html[data-theme='dark'] .highcharts-color-8 {
  fill: #f45b5b;
  stroke: #f45b5b;
}
html[data-theme='dark'] .highcharts-color-9 {
  fill: #91e8e1;
  stroke: #91e8e1;
}
html[data-theme='dark'] .highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-point {
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}
html[data-theme='dark'] .highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-data-label text,
html[data-theme='dark'] text.highcharts-data-label {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-data-label-connector {
  fill: none;
}
html[data-theme='dark'] .highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
html[data-theme='dark'] .highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}
html[data-theme='dark'] .highcharts-column-series rect.highcharts-point {
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}
html[data-theme='dark'] .highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='dark'] .highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='dark'] .highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='dark'] .highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
html[data-theme='dark'] .highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}
html[data-theme='dark'] .highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
html[data-theme='dark'] .highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-above-level {
  display: none;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}
html[data-theme='dark'] .highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-legend-item:hover text {
  fill: #000000;
}
html[data-theme='dark'] .highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms;
}
html[data-theme='dark'] .highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-legend-nav-inactive {
  fill: #cccccc;
}
html[data-theme='dark'] circle.highcharts-legend-nav-active,
html[data-theme='dark'] circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}
html[data-theme='dark'] .highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}
html[data-theme='dark'] .highcharts-bubble-legend-connectors {
  stroke-width: 1;
}
html[data-theme='dark'] .highcharts-bubble-legend-labels {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}
html[data-theme='dark'] .highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}
html[data-theme='dark'] .highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}
html[data-theme='dark'] .highcharts-plot-band,
html[data-theme='dark'] .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}
html[data-theme='dark'] .highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-boxplot-box {
  fill: #ffffff;
}
html[data-theme='dark'] .highcharts-boxplot-median {
  stroke-width: 2px;
}
html[data-theme='dark'] .highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}
html[data-theme='dark'] .highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}
html[data-theme='dark'] .highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}
html[data-theme='dark'] .highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}
html[data-theme='dark'] .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}
html[data-theme='dark'] .highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  transition: stroke 250ms, fill-opacity 250ms;
}
html[data-theme='dark'] .highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc;
}
html[data-theme='dark'] .highcharts-navigator-mask-outside {
  fill-opacity: 0;
}
html[data-theme='dark'] .highcharts-navigator-mask-inside {
  fill: #6685c2;
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}
html[data-theme='dark'] .highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}
html[data-theme='dark'] .highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}
html[data-theme='dark'] .highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad;
}
html[data-theme='dark'] .highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}
html[data-theme='dark'] .highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}
html[data-theme='dark'] .highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}
html[data-theme='dark'] .highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-scrollbar-arrow {
  fill: #666666;
}
html[data-theme='dark'] .highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}
html[data-theme='dark'] .highcharts-button text {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc;
}
html[data-theme='dark'] .highcharts-button-hover text {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc;
}
html[data-theme='dark'] .highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold;
}
html[data-theme='dark'] .highcharts-button-disabled text {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}
html[data-theme='dark'] .highcharts-range-label rect {
  fill: none;
}
html[data-theme='dark'] .highcharts-range-label text {
  fill: #666666;
}
html[data-theme='dark'] .highcharts-range-input rect {
  fill: none;
}
html[data-theme='dark'] .highcharts-range-input text {
  fill: #333333;
}
html[data-theme='dark'] .highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc;
}
html[data-theme='dark'] input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}
html[data-theme='dark'] .highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em;
}
html[data-theme='dark'] .highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}
html[data-theme='dark'] .highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff;
}
html[data-theme='dark'] .highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}
html[data-theme='dark'] .highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms;
}
html[data-theme='dark'] .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}
html[data-theme='dark'] .highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}
html[data-theme='dark'] .highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
}
html[data-theme='dark'] .highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}
html[data-theme='dark'] .highcharts-mapline-series .highcharts-point {
  fill: none;
}
html[data-theme='dark'] .highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}
html[data-theme='dark'] .highcharts-coloraxis {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-coloraxis-marker {
  fill: #999999;
}
html[data-theme='dark'] .highcharts-null-point {
  fill: #f7f7f7;
}
html[data-theme='dark'] .highcharts-3d-frame {
  fill: transparent;
}
html[data-theme='dark'] .highcharts-contextbutton {
  fill: #ffffff;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}
html[data-theme='dark'] .highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}
html[data-theme='dark'] .highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}
html[data-theme='dark'] .highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}
html[data-theme='dark'] .highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}
html[data-theme='dark'] .highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff;
}
html[data-theme='dark'] .highcharts-drilldown-point {
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-drilldown-data-label text,
html[data-theme='dark'] text.highcharts-drilldown-data-label,
html[data-theme='dark'] .highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}
html[data-theme='dark'] .highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}
html[data-theme='dark'] .highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}
html[data-theme='dark'] .highcharts-bullet-target {
  stroke-width: 0;
}
html[data-theme='dark'] .highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}
html[data-theme='dark'] .highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}
html[data-theme='dark'] .highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}
html[data-theme='dark'] .highcharts-annotation-label text {
  fill: #e6e6e6;
}
html[data-theme='dark'] .highcharts-treegrid-node-collapsed,
html[data-theme='dark'] .highcharts-treegrid-node-expanded {
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-point-connecting-path {
  fill: none;
}
html[data-theme='dark'] .highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-line {
  overflow: visible !important;
}
html[data-theme='dark'] .highcharts-line .highcharts-container {
  overflow: visible !important;
}
html[data-theme='dark'] .highcharts-line .highcharts-root {
  overflow: visible !important;
}
html[data-theme='dark'] .highcharts-line .highcharts-area {
  fill: #3851d1;
  fill-opacity: 0.1;
}
html[data-theme='dark'] .highcharts-line .highcharts-background {
  fill: transparent;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-0 {
  stroke: #a0b0ff;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-0.highcharts-legend-item:before {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-1 {
  stroke: #2d41a6;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-1.highcharts-legend-item:before {
  background-color: #2d41a6;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-2 {
  stroke: #ff5f88;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-2.highcharts-legend-item:before {
  background-color: #ff5f88;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-3 {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-3.highcharts-legend-item:before {
  background-color: #e4e8ee;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-4 {
  stroke: #f0f3f6;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-4.highcharts-legend-item:before {
  background-color: #f0f3f6;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-5 {
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-5.highcharts-legend-item:before {
  background-color: #ffffff;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-6 {
  stroke: #ce3960;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-6.highcharts-legend-item:before {
  background-color: #ce3960;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-7 {
  stroke: #83e5cd;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-7.highcharts-legend-item:before {
  background-color: #83e5cd;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-8 {
  stroke: #159475;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-8.highcharts-legend-item:before {
  background-color: #159475;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-9 {
  stroke: #ff9bb5;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-9.highcharts-legend-item:before {
  background-color: #ff9bb5;
}
html[data-theme='dark'] .highcharts-line .highcharts-axis-labels {
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #e4e8ee;
}
html[data-theme='dark'] .highcharts-line .highcharts-axis-title {
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #e4e8ee;
  font-weight: bold;
}
html[data-theme='dark'] .highcharts-line .highcharts-xaxis .highcharts-axis-line,
html[data-theme='dark'] .highcharts-line .highcharts-xaxis .highcharts-tick {
  stroke: none;
}
html[data-theme='dark'] .highcharts-line .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='dark'] .highcharts-line .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #f0f3f6;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='dark'] .highcharts-line .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='dark'] .highcharts-line .highcharts-halo {
  fill-opacity: 1;
  stroke-width: 1px;
  fill: #fff;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-line .highcharts-markers {
  stroke-width: 0;
  fill: #e4e8ee;
}
html[data-theme='dark'] .highcharts-line .highcharts-goal .highcharts-point {
  stroke-width: 0;
  fill: #3851d1;
}
html[data-theme='dark'] .highcharts-line .highcharts-goal .highcharts-halo {
  stroke: #3851d1;
}
html[data-theme='dark'] .highcharts-line .highcharts-duration .highcharts-graph {
  stroke: #3851d1;
  stroke-width: 2px;
}
html[data-theme='dark'] .highcharts-line .highcharts-duration .highcharts-point {
  fill: #3851d1;
  stroke-width: 2px;
  stroke: #fff;
}
html[data-theme='dark'] .highcharts-line .highcharts-tooltip {
  stroke: #f0f3f6;
  filter: none !important;
}
html[data-theme='dark'] .highcharts-line .highcharts-tooltip-box {
  fill: #191934;
  fill-opacity: 1;
}
html[data-theme='dark'] .highcharts-line .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #e4e8ee;
  white-space: normal !important;
}
html[data-theme='dark'] .highcharts-line .highcharts-legend g.highcharts-legend-item {
  opacity: 0;
}
html[data-theme='dark'] .highcharts-line .highcharts-legend div.highcharts-legend-item {
  color: #e4e8ee;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
html[data-theme='dark'] .highcharts-line .highcharts-legend div.highcharts-legend-item:before {
  display: inline-block;
  width: 14px;
  height: 14px;
  content: '';
  margin-top: 8px;
  margin-left: -10px;
  border-radius: 50%;
}
html[data-theme='dark'] .highcharts-line .highcharts-legend div.highcharts-legend-item > span {
  padding-left: 6px;
}
html[data-theme='dark'] .pie-chart-container {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}
html[data-theme='dark'] .highcharts-pie {
  display: flex;
  justify-content: stretch;
  align-items: center;
  min-width: 225px;
  width: 33%;
}
@media only screen and (max-width: 880px) {
  html[data-theme='dark'] .highcharts-pie {
    min-width: 250px;
    width: 50%;
  }
}
@media only screen and (max-width: 540px) {
  html[data-theme='dark'] .highcharts-pie {
    flex-direction: column;
  }
  html[data-theme='dark'] .highcharts-pie .legend {
    height: auto;
  }
}
html[data-theme='dark'] .highcharts-pie .highcharts-react-container {
  overflow: visible !important;
  min-width: 201px;
  min-height: 201px;
  width: 201px;
  height: 201px;
}
html[data-theme='dark'] .highcharts-pie .highcharts-container {
  overflow: visible !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
html[data-theme='dark'] .highcharts-pie .highcharts-root {
  overflow: visible !important;
}
html[data-theme='dark'] .highcharts-pie .highcharts-background {
  fill: transparent;
}
html[data-theme='dark'] .highcharts-pie .highcharts-subtitle {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  z-index: -1;
  color: #e4e8ee;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.16666667;
}
html[data-theme='dark'] .highcharts-pie .highcharts-tooltip {
  stroke: #f0f3f6;
  filter: none !important;
}
html[data-theme='dark'] .highcharts-pie .highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
}
html[data-theme='dark'] .highcharts-pie .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 20px !important;
  white-space: normal !important;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-0 {
  fill: #e4e8ee;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-1 {
  fill: #3851d1;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-2 {
  fill: #a0b0ff;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-3 {
  fill: #f0f3f6;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-4 {
  fill: #6780ff;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-5 {
  fill: #3851d1;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-6 {
  fill: #4a4a7a;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-7 {
  fill: #83e5cd;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-8 {
  fill: #07cc9b;
}
html[data-theme='dark'] .highcharts-pie .highcharts-color-9 {
  fill: #159475;
}
html[data-theme='dark'] .highcharts-pie .legend {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding: 10px 0;
}
html[data-theme='dark'] .highcharts-pie .legend-point {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  margin-right: 10px;
}
html[data-theme='dark'] .highcharts-pie .legend-item {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  height: 33%;
  margin-left: 30px;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(1) .legend-point {
  background-color: #e4e8ee;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(2) .legend-point {
  background-color: #3851d1;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(3) .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(4) .legend-point {
  background-color: #f0f3f6;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(5) .legend-point {
  background-color: #6780ff;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(6) .legend-point {
  background-color: #3851d1;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(7) .legend-point {
  background-color: #4a4a7a;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(8) .legend-point {
  background-color: #83e5cd;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(9) .legend-point {
  background-color: #07cc9b;
}
html[data-theme='dark'] .highcharts-pie .legend-item:nth-child(10) .legend-point {
  background-color: #159475;
}
html[data-theme='dark'] .highcharts-pie .legend-label {
  margin-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
}
html[data-theme='dark'] .highcharts-pie .legend-name {
  color: #e4e8ee;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  margin-bottom: 5px;
  max-width: 126px;
}
html[data-theme='dark'] .highcharts-pie .legend-percent {
  float: right;
  color: #e4e8ee;
  text-align: right;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  margin-left: 12px;
}
html[data-theme='dark'] .highcharts-pie .legend-value {
  color: #ffffff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-react-container {
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  height: 120px;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-series-inactive,
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-point-inactive {
  opacity: 1 !important;
  transition: none !important;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: #e4e8ee;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: #3851d1;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: #a0b0ff;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: #f0f3f6;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: #6780ff;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #3851d1;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: #4a4a7a;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
  fill: #83e5cd;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0 {
  fill: #07cc9b;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0 {
  fill: #159475;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .highcharts-color-1 {
  fill: #7171a7;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .legend {
  justify-content: center;
  height: auto;
  padding: 0;
}
html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation .legend-item {
  margin-left: 20px;
}
@media only screen and (max-width: 540px) {
  html[data-theme='dark'] .highcharts-pie.highcharts-pie_single-allocation {
    flex-direction: row;
  }
}
html[data-theme='dark'] .projection-chart-container .highcharts-projection-chart {
  overflow: visible !important;
}
html[data-theme='dark'] .projection-chart-container .highcharts-container {
  overflow: visible !important;
}
html[data-theme='dark'] .projection-chart-container .highcharts-root {
  overflow: visible !important;
}
html[data-theme='dark'] .projection-chart-container .highcharts-background {
  fill: transparent;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-markers {
  fill: #3851d1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-markers {
  fill: #a0b0ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-markers {
  fill: #a0b0ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-markers {
  fill: #4a4a7a;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-markers {
  fill: #f0f3f6;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-markers {
  fill: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-markers {
  fill: #07cc9b;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-markers {
  fill: #159475;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-0.highcharts-markers {
  stroke: #3851d1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-1.highcharts-markers {
  stroke: #6780ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-2.highcharts-markers {
  stroke: #a0b0ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-3.highcharts-markers {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-4.highcharts-markers {
  stroke: #6780ff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-5.highcharts-markers {
  stroke: #4a4a7a;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-6.highcharts-markers {
  stroke: #f0f3f6;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-7.highcharts-markers {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-8.highcharts-markers {
  stroke: #07cc9b;
}
html[data-theme='dark'] .projection-chart-container .highcharts-color-9.highcharts-series,
html[data-theme='dark'] .projection-chart-container .highcharts-color-9.highcharts-markers {
  stroke: #159475;
}
html[data-theme='dark'] .projection-chart-container .highcharts-graph {
  stroke-width: 1px;
}
html[data-theme='dark'] .projection-chart-container .highcharts-axis-line {
  display: none;
}
html[data-theme='dark'] .projection-chart-container .highcharts-grid-line {
  stroke: #f0f3f6;
}
html[data-theme='dark'] .projection-chart-container .highcharts-axis-labels,
html[data-theme='dark'] .projection-chart-container .highcharts-axis-title {
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container .highcharts-axis-title {
  font-weight: bold;
}
html[data-theme='dark'] .projection-chart-container .highcharts-xaxis .highcharts-axis-line,
html[data-theme='dark'] .projection-chart-container .highcharts-xaxis .highcharts-tick {
  stroke: none;
}
html[data-theme='dark'] .projection-chart-container .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='dark'] .projection-chart-container .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #f0f3f6;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='dark'] .projection-chart-container .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='dark'] .projection-chart-container .highcharts-area-series .highcharts-halo {
  fill-opacity: 1;
  stroke-width: 1px;
  fill: #fff;
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container .highcharts-markers {
  stroke-width: 0;
  fill: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container .highcharts-series-inactive {
  opacity: 1 !important;
}
html[data-theme='dark'] .projection-chart-container .highcharts-crosshair {
  stroke: #f0f3f6;
  stroke-width: 2px;
  stroke-opacity: 0.6;
  stroke-dasharray: 5px, 4px;
}
html[data-theme='dark'] .projection-chart-container .highcharts-tooltip {
  stroke: #f0f3f6;
  filter: none !important;
  z-index: 999 !important;
}
html[data-theme='dark'] .projection-chart-container .highcharts-tooltip-box {
  fill: #191934;
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container .highcharts-tooltip > span {
  font-size: 14px;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #e4e8ee;
  white-space: normal !important;
}
html[data-theme='dark'] .projection-chart-container .projection-legend {
  margin: 0 auto;
  width: fit-content;
}
html[data-theme='dark'] .projection-chart-container .projection-legend-item {
  display: inline-flex;
  align-items: center;
  color: #e4e8ee;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] .projection-chart-container .projection-legend-item:not(:last-child) {
  margin-right: 20px;
}
html[data-theme='dark'] .projection-chart-container .projection-legend-item .legend-point {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
html[data-theme='dark'] .projection-chart-container .projection-legend-item .legend-name {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='dark'] .projection-chart-container .projection-legend .legend-external-item-0 .legend-point {
  background-color: #ffffff;
}
html[data-theme='dark'] .projection-chart-container .projection-legend .legend-external-item-1 .legend-point {
  background-color: #ffffff;
}
html[data-theme='dark'] .projection-chart-container .projection-legend .legend-item-0 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .projection-chart-container .projection-legend .legend-item-1 .legend-point {
  background-color: #6780ff;
}
html[data-theme='dark'] .projection-chart-container .projection-legend .legend-item-2 .legend-point {
  background-color: #3851d1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-series-group {
  opacity: 0.75;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-series-3 .highcharts-halo {
  fill-opacity: 0;
  stroke-width: 0;
  fill: transparent;
  stroke: transparent;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
  fill: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
  fill: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
  fill: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
  fill: #7171a7;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
  fill: #7171a7;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
  fill: #7171a7;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
  fill: #7171a7;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-area,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-area {
  fill-opacity: 1;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point,
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series {
  stroke: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point {
  stroke: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
  fill: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
  stroke: #ff9bb5;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series {
  stroke: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point {
  stroke: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
  fill: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
  stroke: #6780ff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
  fill: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
  fill: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
  fill: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
  stroke: #ffffff;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-0 .legend-point {
  background-color: #ffffff;
  opacity: 0.75;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-1 .legend-point {
  background-color: #ffffff;
  opacity: 0.75;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-0 .legend-point {
  background-color: #7171a7;
  opacity: 0.75;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-1 .legend-point {
  background-color: #6780ff;
  opacity: 0.75;
}
html[data-theme='dark'] .projection-chart-container.highcharts-positiveAndNegative .projection-legend .legend-item-2 .legend-point {
  background-color: #ff9bb5;
  opacity: 0.75;
}
html[data-theme='dark'] .highcharts-risk-return {
  overflow: visible !important;
  width: fit-content;
  margin-left: 20px;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-container {
  overflow: visible !important;
  width: fit-content;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-root {
  overflow: visible !important;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-background {
  fill: transparent;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-point {
  cursor: pointer;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-point-hover {
  stroke: #3851d1 !important;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-0 {
  fill: #a0b0ff;
  stroke: #a0b0ff;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-1 {
  fill: #2d41a6;
  stroke: #2d41a6;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-2 {
  fill: #ffffff;
  stroke: #ffffff;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-3 {
  fill: #3851d1;
  stroke: #3851d1;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-4 {
  fill: #a0b0ff;
  stroke: #a0b0ff;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-5 {
  fill: #4a4a7a;
  stroke: #4a4a7a;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-6 {
  fill: #e4e8ee;
  stroke: #e4e8ee;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-color-7 {
  fill: #83e5cd;
  stroke: #83e5cd;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-axis-labels {
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  fill: #e4e8ee;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-axis-line {
  stroke: #f0f3f6;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-xaxis-grid .highcharts-grid-line {
  stroke: #7171a7;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-xaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.30769231;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #7171a7;
  stroke-width: 1px;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-yaxis-labels {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 3.07692308;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-halo {
  display: none;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-crosshair {
  stroke: #e4e8ee;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-tooltip {
  stroke: #f0f3f6;
  filter: none !important;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-tooltip-box {
  fill: #191934;
  fill-opacity: 1;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-tooltip > span {
  font-size: 16px;
  font-family: 'AvenirNext-UltraLight', Roboto, Helvetica, Arial, sans-serif;
  padding: 11.5px 20px !important;
  color: #e4e8ee;
  white-space: normal !important;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-tooltip .tooltip-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
html[data-theme='dark'] .highcharts-risk-return .highcharts-tooltip .value {
  min-width: 52px;
}
html[data-theme='dark'] .risk-return-container {
  position: relative;
  align-items: center;
}
html[data-theme='dark'] .risk-return-chart-container {
  position: relative;
  width: fit-content;
  margin: 0 auto 30px;
}
html[data-theme='dark'] .risk-return-labels {
  position: absolute;
  display: flex;
  justify-content: space-between;
}
html[data-theme='dark'] .risk-return-labels > span {
  color: #e4e8ee;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
}
html[data-theme='dark'] .risk-return-xaxis-labels {
  width: calc(100% - 40px);
  margin-top: -5px;
  margin-left: 29px;
}
html[data-theme='dark'] .risk-return-yaxis-labels {
  width: calc(100% - 45px);
  margin-left: 0;
  transform: rotate(-90deg);
  transform-origin: -5px -10px;
}
html[data-theme='dark'] .risk-return-legend {
  margin: 0 auto;
  width: fit-content;
}
html[data-theme='dark'] .risk-return-legend-item {
  display: inline-flex;
  align-items: center;
  color: #e4e8ee;
  letter-spacing: 0.25px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] .risk-return-legend-item:not(:last-child) {
  margin-right: 20px;
}
html[data-theme='dark'] .risk-return-legend-item .legend-point {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
html[data-theme='dark'] .risk-return-legend-item .legend-name {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='dark'] .risk-return-legend .legend-item-0 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .risk-return-legend .legend-item-1 .legend-point {
  background-color: #2d41a6;
}
html[data-theme='dark'] .risk-return-legend .legend-item-2 .legend-point {
  background-color: #ffffff;
}
html[data-theme='dark'] .risk-return-legend .legend-item-3 .legend-point {
  background-color: #3851d1;
}
html[data-theme='dark'] .risk-return-legend .legend-item-4 .legend-point {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .risk-return-legend .legend-item-5 .legend-point {
  background-color: #4a4a7a;
}
html[data-theme='dark'] .risk-return-legend .legend-item-6 .legend-point {
  background-color: #e4e8ee;
}
html[data-theme='dark'] .risk-return-legend .legend-item-7 .legend-point {
  background-color: #83e5cd;
}
html[data-theme='dark'] .ant-calendar-picker {
  width: 320px;
}
html[data-theme='dark'] .ant-calendar-picker .ant-calendar-picker-input:hover {
  border-color: #090912;
}
html[data-theme='dark'] .ant-calendar-picker .ant-calendar-picker-icon .Icon {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon {
  opacity: 0.5;
}
html[data-theme='dark'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon,
html[data-theme='dark'] .ant-calendar-picker .ant-input-disabled + .ant-calendar-picker-icon:hover {
  cursor: not-allowed;
}
html[data-theme='dark'] .datepicker-top {
  margin-top: -272px;
}
html[data-theme='dark'] .datepicker-top .ant-calendar-date-panel:before,
html[data-theme='dark'] .datepicker-top .ant-calendar-date-panel:after {
  position: absolute;
  right: 8px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 10px;
}
html[data-theme='dark'] .datepicker-top .ant-calendar-date-panel:before {
  bottom: -20px;
  border-color: #f0f3f6 transparent transparent transparent;
}
html[data-theme='dark'] .datepicker-top .ant-calendar-date-panel:after {
  bottom: -19px;
  border-color: #fff transparent transparent transparent;
}
html[data-theme='dark'] .datepicker .ant-calendar-date-panel:before,
html[data-theme='dark'] .datepicker .ant-calendar-date-panel:after {
  position: absolute;
  right: 8px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 10px;
}
html[data-theme='dark'] .datepicker .ant-calendar-date-panel:before {
  top: -20px;
  border-color: transparent transparent #f0f3f6 transparent;
}
html[data-theme='dark'] .datepicker .ant-calendar-date-panel:after {
  top: -19px;
  border-color: transparent transparent #fff transparent;
}
html[data-theme='dark'] .datepicker .ant-calendar-date-panel,
html[data-theme='dark'] .datepicker-top .ant-calendar-date-panel {
  margin-top: 42px;
  padding: 0 20px 20px;
  background-color: #fff;
  border: 1px solid #f0f3f6;
  border-radius: 5px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .datepicker .ant-calendar,
html[data-theme='dark'] .datepicker-top .ant-calendar {
  width: 320px;
  background-color: transparent;
  border: 0;
}
html[data-theme='dark'] .datepicker .ant-calendar-input-wrap,
html[data-theme='dark'] .datepicker-top .ant-calendar-input-wrap {
  display: none;
}
html[data-theme='dark'] .datepicker .ant-calendar-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-header,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header {
  height: 50px;
  border-bottom-color: #7171a7;
}
html[data-theme='dark'] .datepicker .ant-calendar-header > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-header > a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header > a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header > a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header > a,
html[data-theme='dark'] .datepicker .ant-calendar-header div > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-header div > a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header div > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header div > a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header div > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header div > a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header div > a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header div > a {
  padding: 0;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2.45;
}
html[data-theme='dark'] .datepicker .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-prev-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-prev-year-btn {
  left: 0;
}
html[data-theme='dark'] .datepicker .ant-calendar-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-next-year-btn,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-next-year-btn {
  right: 0;
}
html[data-theme='dark'] .datepicker .ant-calendar-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker-top .ant-calendar-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-month-select,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-month-select {
  padding-right: 5px;
}
html[data-theme='dark'] .datepicker .ant-calendar-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker-top .ant-calendar-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-header .ant-calendar-my-select a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-header .ant-calendar-my-select a {
  color: #e4e8ee;
  font-size: 16px !important;
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 3.125;
}
html[data-theme='dark'] .datepicker .ant-calendar-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-body {
  padding: 10px 0 20px;
}
html[data-theme='dark'] .datepicker .ant-calendar-column-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-column-header {
  padding: 5px 0 10px;
  color: #e4e8ee;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell {
  padding: 5px 0;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell .ant-calendar-date,
html[data-theme='dark'] .datepicker .ant-calendar-cell .ant-calendar-selected-day,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell .ant-calendar-selected-day {
  width: 40px;
  height: 28px;
  border-radius: 0;
  color: #ffffff;
  background-color: #fff;
  line-height: 24px;
  font-weight: normal;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell:hover .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell:hover .ant-calendar-date {
  background-color: transparent;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell.ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell.ant-calendar-last-month-cell .ant-calendar-date,
html[data-theme='dark'] .datepicker .ant-calendar-cell.ant-calendar-next-month-btn-day .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: #7171a7;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell:first-child .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell:first-child .ant-calendar-date,
html[data-theme='dark'] .datepicker .ant-calendar-cell:last-child .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell:last-child .ant-calendar-date {
  background-color: #7171a7;
  color: #f0f3f6;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell.ant-calendar-disabled-cell .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell.ant-calendar-disabled-cell .ant-calendar-date {
  text-decoration: line-through;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell.ant-calendar-today .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell.ant-calendar-today .ant-calendar-date {
  border-color: #3851d1;
  color: #3851d1;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell.ant-calendar-selected-date .ant-calendar-date,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell.ant-calendar-selected-date .ant-calendar-date {
  background-color: #3851d1;
  color: #fff;
}
html[data-theme='dark'] .datepicker .ant-calendar-cell,
html[data-theme='dark'] .datepicker-top .ant-calendar-cell,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel-decade {
  height: 38px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='dark'] .datepicker .ant-calendar-footer,
html[data-theme='dark'] .datepicker-top .ant-calendar-footer {
  padding: 15px 0 0;
  border-top-color: #7171a7;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .datepicker .ant-calendar-footer a,
html[data-theme='dark'] .datepicker-top .ant-calendar-footer a {
  color: #ffffff;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel {
  padding: 0 20px 0;
  margin-bottom: 5px;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-century,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-century,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-century,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-century,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-century,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-century {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 3.125;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header {
  position: relative;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-month-panel-year-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-header .ant-calendar-year-panel-decade-select-content {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-body,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-body,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-body {
  padding: 10px 0 0;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-month,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-year,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-decade,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-decade {
  padding: 7px 5px;
  width: 100%;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-month:hover,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-decade:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-decade:hover {
  background-color: transparent;
  color: #ffffff;
}
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-month-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-year-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-month-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-year-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a:hover,
html[data-theme='dark'] .datepicker-top .ant-calendar-decade-panel .ant-calendar-decade-panel-selected-cell a:hover {
  background-color: #3851d1;
  color: #fff;
  border-radius: 0;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-decade-select {
  color: #e4e8ee !important;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-decade-select:hover {
  color: #7d8da5 !important;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-year {
  color: #4a4a7a;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-year:hover {
  color: #26264e !important;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: #4a4a7a;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year:hover,
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year:hover {
  color: #26264e !important;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-prev-decade-btn,
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-btn {
  color: #4a4a7a;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-prev-decade-btn:hover,
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-next-decade-btn:hover {
  color: #26264e !important;
}
html[data-theme='dark'] .ant-calendar-year-panel .ant-calendar-year-panel-selected-cell a:hover {
  color: #fff !important;
}
html[data-theme='dark'] .container {
  max-width: 1202px;
  margin: 0 auto;
  padding: 0 40px;
}
html[data-theme='dark'] .container .row {
  margin-bottom: 30px;
}
html[data-theme='dark'] .header {
  position: relative;
  height: 80px;
  background-color: var(--background-header);
  color: #fff #e4e8ee;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='dark'] .header > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  html[data-theme='dark'] .header > .container {
    padding: 0 20px;
  }
}
html[data-theme='dark'] .header__left,
html[data-theme='dark'] .header__right {
  max-width: 40%;
  flex-shrink: 0;
  text-align: left;
}
html[data-theme='dark'] .header.header_with-navigation .header__left,
html[data-theme='dark'] .header.header_with-navigation .header__right {
  flex-basis: 120px;
}
@media only screen and (max-width: 768px) {
  html[data-theme='dark'] .header.header_with-navigation .header__left,
  html[data-theme='dark'] .header.header_with-navigation .header__right {
    flex-basis: 95px;
  }
}
@media only screen and (max-width: 480px) {
  html[data-theme='dark'] .header.header_with-navigation .header__left,
  html[data-theme='dark'] .header.header_with-navigation .header__right {
    flex-basis: 80px;
  }
}
html[data-theme='dark'] .header__right {
  text-align: right;
}
html[data-theme='dark'] .header__center {
  flex: 1;
}
html[data-theme='dark'] .header__button {
  position: relative;
  z-index: 10;
  display: inline-flex;
  justify-content: space-between;
  float: left;
  align-items: center;
  padding: 5px 0;
  background: none;
  border: 0;
  min-height: 32px;
  color: #e4e8ee;
  text-align: left;
}
html[data-theme='dark'] .header__button.left-button[name='login'] .Icon {
  transform: scaleX(-1);
}
@media only screen and (max-width: 540px) {
  html[data-theme='dark'] .header__button span {
    display: none;
  }
}
html[data-theme='dark'] .header__button:hover {
  color: #e4e8ee;
  cursor: pointer;
}
html[data-theme='dark'] .header__button:hover span {
  text-decoration: underline;
}
html[data-theme='dark'] .header__right .header__button {
  float: right;
  text-align: right;
  flex-direction: row-reverse;
}
html[data-theme='dark'] .header__right .header__button .Icon + span {
  margin-right: 10px;
  margin-left: 0;
}
html[data-theme='dark'] .header__logo {
  width: 100%;
  color: #e4e8ee;
}
html[data-theme='dark'] .header__logo a {
  color: #e4e8ee;
}
html[data-theme='dark'] .header__logo b {
  font-weight: bold;
}
html[data-theme='dark'] .header__navigation {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
html[data-theme='dark'] .header__navigation .navigation__link {
  position: relative;
  display: inline-block;
  color: #586579;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .header__navigation .navigation__link:hover,
html[data-theme='dark'] .header__navigation .navigation__link:active {
  color: #ffffff;
}
html[data-theme='dark'] .header__navigation .navigation__link.navigation__link-active {
  color: #ffffff;
  font-weight: bold;
}
html[data-theme='dark'] .header__navigation .navigation__link.navigation__link_changed:after {
  position: absolute;
  right: -8px;
  top: 0;
  background-color: #ff5f88;
}
html[data-theme='dark'] .header img {
  max-width: 65px;
  margin: 0 20px;
}
html[data-theme='dark'] .header .Icon {
  font-size: 18px;
}
html[data-theme='dark'] .header .Icon + span {
  margin-left: 10px;
}
html[data-theme='dark'] .header .profile-menu {
  justify-content: flex-end;
}
@media only screen and (max-width: 480px) {
  html[data-theme='dark'] .profile-menu__Popover,
  html[data-theme='dark'] .notifications-menu__Popover {
    left: 0 !important;
    right: 0 !important;
  }
  html[data-theme='dark'] .profile-menu__Popover.dropdown-inner .rc-tooltip-inner-content,
  html[data-theme='dark'] .notifications-menu__Popover.dropdown-inner .rc-tooltip-inner-content {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  html[data-theme='dark'] .profile-menu__Popover .rc-tooltip-arrow {
    right: var(--padding-base) !important;
  }
  html[data-theme='dark'] .notifications-menu__Popover .rc-tooltip-arrow {
    right: calc(var(--padding-base) + var(--avatar-size-sm) + var(--margin-base)) !important;
  }
}
html[data-theme='dark'] .loader-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .loader-wrapper .loader {
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 2px solid #f0f3f6;
  border-left-color: #26264e;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
}
html[data-theme='dark'] .loader-wrapper .loader.loader-large {
  border-width: 3px;
  width: 50px;
  height: 50px;
}
html[data-theme='dark'] .loader-wrapper .loader.loader-light {
  border-color: #7171a7;
  border-left-color: #ffffff;
}
html[data-theme='dark'] .loader-wrapper .loader.loader-negative {
  border-color: #ff9bb5;
  border-left-color: #fff;
}
html[data-theme='dark'] .loader-wrapper .loader .loader-circle {
  display: none;
}
html[data-theme='dark'] .loader-wrapper .loader .loader-circle:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
  position: absolute;
  left: 50%;
  top: 25%;
  height: 25%;
  width: 25%;
  opacity: 1;
  transform-origin: left top;
  border-right: 3px solid #26264e;
  border-top: 3px solid #26264e;
  content: '';
}
@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 50px;
    opacity: 1;
  }
  40% {
    height: 50px;
    width: 50px;
    opacity: 1;
  }
  100% {
    height: 50px;
    width: 50px;
    opacity: 1;
  }
}
html[data-theme='dark'] .PageVariant.wrapper {
  max-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='dark'] .PageVariant .main {
  max-width: 354px;
  text-align: center;
}
html[data-theme='dark'] .PageVariant .Content-box {
  display: inline-block;
  padding: 30px !important;
  vertical-align: top;
  width: 100%;
  text-align: left;
}
html[data-theme='dark'] .PageVariant i {
  margin-bottom: 20px;
}
html[data-theme='dark'] .PageVariant .error > i {
  color: #ff376b !important;
}
html[data-theme='dark'] .PageVariant .success > i {
  color: #07cc9b !important;
}
html[data-theme='dark'] .PageVariant p {
  margin-bottom: 20px;
  color: #ffffff;
}
html[data-theme='dark'] .PageVariant button {
  width: 100%;
}
html[data-theme='dark'] .PageVariant button + button {
  margin-top: 20px;
}
@media screen and (min-width: 240px) {
  html[data-theme='dark'] html,
  html[data-theme='dark'] body {
    margin: 0;
  }
}
@media screen and (max-width: 240px) {
  html[data-theme='dark'] html,
  html[data-theme='dark'] body {
    margin: 0;
  }
  html[data-theme='dark'] .navigation {
    width: 100%;
  }
}
html[data-theme='dark'] .navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -240px;
  z-index: 2000;
  width: 240px;
  height: 100vh;
  background-color: #191934;
  margin: 0;
  padding: 40px;
  border-right: 1px solid #f0f3f6;
  overflow-y: auto;
  transition: 0.3s;
}
html[data-theme='dark'] .navigation ~ * {
  transition: transform 0.3s;
}
html[data-theme='dark'] .navigation_open {
  left: 0;
}
html[data-theme='dark'] .navigation_open ~ * {
  transform: translateX(240px);
}
html[data-theme='dark'] .navigation__link {
  display: block;
  color: #c8c8e8;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 3.33333333;
}
html[data-theme='dark'] .navigation__link:hover {
  color: #fff;
}
html[data-theme='dark'] .navigation__link:active {
  color: #0b0b1e;
}
html[data-theme='dark'] .navigation__link_changed:after {
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: super;
  border-radius: 50%;
  background: #3851d1;
  content: ' ';
  margin-left: 2.66666667px;
}
html[data-theme='dark'] .navigation__link_home {
  font-weight: bold;
  color: #e4e8ee;
}
html[data-theme='dark'] .navigation__link_home:hover {
  color: #e4e8ee;
}
html[data-theme='dark'] .navigation__button {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  padding: 0;
  margin-bottom: 5.33333333px;
  color: #3851d1;
  background: none;
  border: 0;
  cursor: pointer;
}
html[data-theme='dark'] .navigation__button .Icon {
  font-size: 16px;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select {
  max-height: 220px;
  overflow-y: auto;
  display: flex;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-inner {
  width: 16px;
  height: 16px;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper {
  display: flex;
  flex: 1 0 auto;
  margin: 0 !important;
  padding: 12px 20px;
  color: #e4e8ee;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper .checkbox-input {
  position: absolute !important;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover {
  background-color: #3851d1;
  color: #fff;
  cursor: pointer;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover .checkbox-label {
  color: #fff;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-label {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] .select {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  height: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
}
html[data-theme='dark'] .select__multi {
  min-width: 200px;
}
html[data-theme='dark'] .select__multi-mini {
  width: auto;
  height: auto;
  min-width: 0;
}
html[data-theme='dark'] .select__button {
  width: 100%;
  height: inherit;
  padding: 6px 27px 6px 12px;
  background: transparent;
  border: 1px solid #f0f3f6;
  border-radius: 2px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease 0s;
}
html[data-theme='dark'] .form-group_focused .select__button {
  background-color: #fff;
  border-color: #3851d1;
  outline: none;
}
html[data-theme='dark'] .select__button:active,
html[data-theme='dark'] .select__button:hover,
html[data-theme='dark'] .form-group_focused .select__button:hover,
html[data-theme='dark'] .form-group_focused .select__button:hover:active,
html[data-theme='dark'] .form-group_opened .select__button {
  background-color: #7171a7;
  border-color: #ffffff;
  outline: none;
  cursor: pointer;
}
html[data-theme='dark'] .form-group_opened .select__button {
  border-radius: 2px 2px 0 0;
}
html[data-theme='dark'] .form-group_invalid .select__button {
  border-color: #ff376b;
}
html[data-theme='dark'] .form-group_invalid .select__button:hover {
  border-color: #ce3960;
}
html[data-theme='dark'] .form-group_disabled .select__button {
  pointer-events: none;
}
html[data-theme='dark'] .select__button_with_placeholder {
  color: #ffffff;
}
html[data-theme='dark'] .select__button_mini {
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 2px;
}
html[data-theme='dark'] .select__button_mini .Icon.anticon__arrow {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, -50%, 0);
}
html[data-theme='dark'] .form-group_opened .select__button_mini .Icon.anticon__arrow {
  transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}
html[data-theme='dark'] .form-group_opened .select__button_mini {
  border-color: transparent;
  border-radius: 2px;
}
html[data-theme='dark'] .select__button_mini.select__button_checked {
  background-color: #3851d1;
  border-color: #3851d1;
}
html[data-theme='dark'] .select__button_mini.select__button_checked .Icon.anticon__arrow {
  color: #fff;
}
html[data-theme='dark'] .select__button_mini.select__button_checked:hover {
  background-color: #7171a7;
  border-color: #ffffff;
}
html[data-theme='dark'] .select__button_mini.select__button_checked:hover .Icon.anticon__arrow {
  color: #e4e8ee;
}
html[data-theme='dark'] .select__button_tooltiped {
  padding-right: 52px;
}
html[data-theme='dark'] .select__button_boxed {
  border-color: #f0f3f6;
}
html[data-theme='dark'] .select__dropdown {
  background-color: #191934;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  display: none;
  width: 100%;
  border: 1px solid #f0f3f6;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  transition: all 0.3s ease 0s;
}
html[data-theme='dark'] .form-group_opened .select__dropdown {
  display: block;
}
html[data-theme='dark'] .form-group_invalid .select__dropdown {
  border-top-color: #ff376b;
}
html[data-theme='dark'] .form-group_invalid .select__dropdown:hover {
  border-top-color: #ce3960;
}
html[data-theme='dark'] .select__dropdown_mini {
  min-width: 250px;
  border-radius: 2px;
  margin-top: 5px;
}
html[data-theme='dark'] .select__search {
  height: 30px;
  margin: 20px 20px 10px;
  width: calc(100% - 20px * 2);
  padding: 7px 12px;
  box-sizing: border-box !important;
  border: 1px solid #f0f3f6;
  border-radius: 2px;
  background-color: #4a4a7a;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
}
html[data-theme='dark'] .select .select__search + fieldset {
  max-height: 240px;
}
html[data-theme='dark'] .select__dropdown .select__search[hidden] + fieldset {
  margin-top: 10px;
}
html[data-theme='dark'] .select__search:hover {
  border-color: #ffffff;
}
html[data-theme='dark'] .select__search:focus {
  border-color: #3851d1;
  outline: none;
}
html[data-theme='dark'] .select__option {
  line-height: 0;
  transition: all 0.3s ease 0s;
}
html[data-theme='dark'] .select__option .checkbox-wrapper {
  display: flex;
  padding: 12px 20px;
  color: #e4e8ee;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] .select__option .checkbox-label {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
}
html[data-theme='dark'] .select__option:hover {
  background-color: #3851d1;
  cursor: pointer;
}
html[data-theme='dark'] .select__option:hover .checkbox-wrapper,
html[data-theme='dark'] .select__option:hover .checkbox-label {
  color: #fff;
}
html[data-theme='dark'] .select__controls {
  border-top: 1px solid #7171a7;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0 0;
  margin: 10px 20px 20px;
}
html[data-theme='dark'] .select__controls button {
  padding: 0;
  border: 0;
  background: none;
  color: #ffffff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  cursor: pointer;
}
html[data-theme='dark'] .select__controls button:first-child {
  font-weight: bold;
}
html[data-theme='dark'] .select__controls button:hover {
  color: #26264e;
}
html[data-theme='dark'] .form-group_disabled .select {
  opacity: 0.5;
  pointer-events: none;
}
html[data-theme='dark'] .select fieldset {
  max-height: 300px;
  overflow: auto;
}
html[data-theme='dark'] .selected__values {
  display: block;
  height: 16px;
  width: calc(100% - 22px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .selected__count {
  height: 16px;
  padding: 0 5px;
  background: #3851d1;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.14285714;
  border-radius: 3px;
  min-width: 18px;
}
html[data-theme='dark'] .select .Icon {
  position: absolute;
  right: 12px;
  top: 50%;
  display: block;
  transform: translate3d(0, -50%, 0);
  transition: all 0.3s ease 0s;
}
html[data-theme='dark'] .select .Icon.anticon__arrow {
  font-size: 10px;
}
html[data-theme='dark'] .form-group_opened .select .Icon.anticon__arrow,
html[data-theme='dark'] .select .Icon.anticon__arrow:hover:visited,
html[data-theme='dark'] .select .Icon.anticon__arrow:visited {
  color: #3851d1;
  transform: translate3d(0, -50%, 0) rotate(-180deg);
}
html[data-theme='dark'] .select .Icon.anticon__error {
  right: 30px;
  color: #ff376b;
}
html[data-theme='dark'] .Skeleton {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
  min-height: 15px;
  background: linear-gradient(-90deg, #7171a7 0%, #fff 50%, #7171a7 100%);
  background-size: 400% 400%;
  animation: left-to-right 1.2s ease-in-out infinite;
}
html[data-theme='dark'] .Skeleton.line {
  display: inline-block;
  border-radius: 4px;
}
html[data-theme='dark'] .Skeleton.circle {
  border-radius: 100%;
  display: block;
}
html[data-theme='dark'] .Skeleton:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #7171a7 0%, #fff 50%, #7171a7 100%);
  background-size: 400% 400%;
  content: '';
  border-radius: inherit;
  animation: left-to-right 1.2s ease-in-out infinite;
}
@keyframes left-to-right {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -135% 0;
  }
}
html[data-theme='dark'] .ant-slider {
  margin-left: 75px;
  margin-right: 75px;
}
html[data-theme='dark'] .ant-slider-rail {
  width: calc(100% + 2px);
}
html[data-theme='dark'] .ant-slider-rail,
html[data-theme='dark'] .ant-slider-track {
  height: 3px;
  border-radius: 0;
}
html[data-theme='dark'] .ant-slider-dot {
  top: 13px;
  width: 2px;
  height: 15px;
  margin-left: 0;
  background-color: #f0f3f6;
  border: none;
  border-radius: 0;
}
html[data-theme='dark'] .ant-slider-dot:first-child,
html[data-theme='dark'] .ant-slider-dot:last-child {
  margin-left: 0;
}
html[data-theme='dark'] .ant-slider-handle {
  width: 18px;
  height: 18px;
  margin-top: -7.5px;
  margin-left: -8px;
  background-color: #3851d1;
  border: none;
}
html[data-theme='dark'] .ant-slider-handle.hidden {
  opacity: 0;
}
html[data-theme='dark'] .ant-slider-handle:focus {
  box-shadow: none;
}
html[data-theme='dark'] .ant-slider-mark {
  top: 43px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
html[data-theme='dark'] .ant-slider-mark-text {
  width: 150px;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-slider-with-marks {
  margin-bottom: 61px;
}
html[data-theme='dark'] .ant-tabs {
  overflow: unset;
}
html[data-theme='dark'] .ant-tabs-ink-bar {
  height: 5px;
  line-height: 1.5;
}
html[data-theme='dark'] .ant-tabs-bar {
  margin-bottom: 30px;
  border-bottom: 1px solid #7171a7;
}
html[data-theme='dark'] .ant-tabs-nav-container {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab {
  color: #586579;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
  color: #ffffff;
}
html[data-theme='dark'] .ant-tabs-extra-content {
  display: flex;
  align-items: center;
  height: 55px;
}
html[data-theme='dark'] .ant-tabs-extra__item {
  display: inline-block;
  margin: 5px 5px 5px 15px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1;
  line-height: 0;
  cursor: pointer;
}
html[data-theme='dark'] .ant-tabs-extra__item:last-child {
  margin-right: 0;
}
html[data-theme='dark'] .ant-tabs-extra__item > .Icon {
  color: #586579;
}
html[data-theme='dark'] .ant-tabs-extra__item:hover > .Icon {
  color: #ffffff;
}
html[data-theme='dark'] .ant-tabs-extra__item_active > .Icon {
  color: #ffffff;
}
html[data-theme='dark'] .ant-tabs-extra__item .form-group {
  margin: 0;
}
html[data-theme='dark'] .tabbed-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
}
html[data-theme='dark'] .tabbed-navigation__link {
  color: #ffffff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  text-align: center;
}
html[data-theme='dark'] .tabbed-navigation__link:hover {
  color: #7d8da5;
}
html[data-theme='dark'] .tabbed-navigation__link:active {
  color: #586579;
}
html[data-theme='dark'] .tabbed-navigation__link_active {
  color: #e4e8ee;
  font-weight: bold;
}
html[data-theme='dark'] .tabbed-navigation__link + .tabbed-navigation__link {
  margin-left: 40px;
}
html[data-theme='dark'] .colors ul {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 0;
}
html[data-theme='dark'] .colors li {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  margin: 0 2% 2%;
  width: 20%;
  border-radius: 4px;
  /* stylelint-disable-next-line */
  box-shadow: 0 3px 10px 0 rgba(56, 81, 209, 0.1);
  padding-top: 70px;
}
html[data-theme='dark'] .colors span {
  display: block;
  padding: 10px 15px;
  background: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
html[data-theme='dark'] .colors_mini ul {
  flex-wrap: wrap;
  margin: 0;
}
html[data-theme='dark'] .colors_mini li {
  display: flex;
  flex-direction: initial;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 0;
  padding: 0 0 0 20px;
  box-shadow: none;
}
html[data-theme='dark'] .colors_mini span {
  padding: 0 0 0 10px;
  width: 100%;
}
html[data-theme='dark'] .primary-default {
  background-color: #e4e8ee;
}
html[data-theme='dark'] .primary-lighter {
  background-color: #f0f3f6;
}
html[data-theme='dark'] .primary-light {
  background-color: #ffffff;
}
html[data-theme='dark'] .primary-dark {
  background-color: #7d8da5;
}
html[data-theme='dark'] .primary-darker {
  background-color: #586579;
}
html[data-theme='dark'] .secondary-default {
  background-color: #4a4a7a;
}
html[data-theme='dark'] .secondary-lighter {
  background-color: #7171a7;
}
html[data-theme='dark'] .secondary-light {
  background-color: #c8c8e8;
}
html[data-theme='dark'] .secondary-dark {
  background-color: #26264e;
}
html[data-theme='dark'] .secondary-darker {
  background-color: #0b0b1e;
}
html[data-theme='dark'] .accent-default {
  background-color: #3851d1;
}
html[data-theme='dark'] .accent-lighter {
  background-color: #a0b0ff;
}
html[data-theme='dark'] .accent-light {
  background-color: #6780ff;
}
html[data-theme='dark'] .accent-dark {
  background-color: #3851d1;
}
html[data-theme='dark'] .accent-darker {
  background-color: #2d41a6;
}
html[data-theme='dark'] .positive-default {
  background-color: #07cc9b;
}
html[data-theme='dark'] .positive-lighter {
  background-color: #83e5cd;
}
html[data-theme='dark'] .positive-light {
  background-color: #38d6af;
}
html[data-theme='dark'] .positive-dark {
  background-color: #19b38d;
}
html[data-theme='dark'] .positive-darker {
  background-color: #159475;
}
html[data-theme='dark'] .negative-default {
  background-color: #ff376b;
}
html[data-theme='dark'] .negative-lighter {
  background-color: #ff9bb5;
}
html[data-theme='dark'] .negative-light {
  background-color: #ff5f88;
}
html[data-theme='dark'] .negative-dark {
  background-color: #ce3960;
}
html[data-theme='dark'] .negative-darker {
  background-color: #bb3256;
}
html[data-theme='dark'] .white {
  background-color: #fff;
}
html[data-theme='dark'] .line-chart-goal-color {
  background-color: #3851d1;
}
html[data-theme='dark'] .checkbox-check-color {
  background-color: #e4e8ee;
}
html[data-theme='dark'] .switch-color {
  background-color: #3851d1;
}
html[data-theme='dark'] .pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .pagination-item,
html[data-theme='dark'] .pagination-prev,
html[data-theme='dark'] .pagination-next {
  height: 20px;
  min-width: 20px;
  margin: 0 5px;
  color: #ffffff;
  list-style: none;
  line-height: 20px;
  text-align: center;
}
html[data-theme='dark'] .pagination-item:hover,
html[data-theme='dark'] .pagination-prev:hover,
html[data-theme='dark'] .pagination-next:hover,
html[data-theme='dark'] .pagination-item:focus,
html[data-theme='dark'] .pagination-prev:focus,
html[data-theme='dark'] .pagination-next:focus,
html[data-theme='dark'] .pagination-item:hover button,
html[data-theme='dark'] .pagination-prev:hover button,
html[data-theme='dark'] .pagination-next:hover button {
  color: #e4e8ee;
  cursor: pointer;
}
html[data-theme='dark'] .pagination-prev,
html[data-theme='dark'] .pagination-next {
  margin-left: 0;
  font-size: 10px;
}
html[data-theme='dark'] .pagination-next {
  margin: 0 0 0 5px;
}
html[data-theme='dark'] .pagination button {
  display: block;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
}
html[data-theme='dark'] .pagination-item-active button {
  font-weight: bold;
  color: #e4e8ee;
}
html[data-theme='dark'] .paragraph_primary,
html[data-theme='dark'] .paragraph_title,
html[data-theme='dark'] .paragraph_secondary {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
html[data-theme='dark'] .paragraph_highlights {
  font-weight: bold;
}
html[data-theme='dark'] .paragraph_title {
  font-style: italic;
}
html[data-theme='dark'] .paragraph_secondary {
  color: #ffffff;
}
html[data-theme='dark'] .ant-steps-wrapper {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  color: #e4e8ee;
  text-align: center;
}
html[data-theme='dark'] .ant-steps-horizontal {
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-tail {
  margin-left: 52.16px;
  padding: 3.04761905px 15.02347418px;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-tail:after {
  height: 2px;
  background: linear-gradient(to right, #e4e8ee 50%, #f0f3f6 50%) right bottom;
  background-size: 200% 100%;
  transition: all 0.5s ease-out 0s;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-custom .ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px !important;
  height: 32px !important;
  border: 2px solid #e4e8ee;
  transition: border-color 0.5s ease 0.5s;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-custom .ant-steps-item-icon .ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  color: transparent;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #f0f3f6 !important;
  transition-duration: 0.25s;
  transition-delay: 0s;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-process.ant-steps-item-custom .ant-steps-icon {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.83333333;
  color: #e4e8ee;
  transition: color 0.1s ease 0.5s;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-tail:after {
  background-position: left bottom;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-finish.ant-steps-item-custom .ant-steps-icon {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
  color: #e4e8ee;
}
html[data-theme='dark'] .ant-steps-horizontal .ant-steps-item-title {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  color: #e4e8ee !important;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small {
  justify-content: center;
  padding-top: 9px;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item {
  flex: 0 0 44px;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-tail {
  top: 7.5px;
  margin-left: 22px;
  padding: 0 7px;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-tail:after {
  height: 1px;
  transition-duration: 0.3s;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-content {
  display: none;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: 16px !important;
  height: 16px !important;
  margin: auto;
  border: 1px solid #e4e8ee;
  border-radius: 50%;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-wait .ant-steps-item-icon {
  transition-duration: 0.15s;
  transition-delay: 0s;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-process .ant-steps-icon {
  font-size: 6px;
  font-size: 0.6rem;
  line-height: 3.66666667;
  transition-delay: 0.3s;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small .ant-steps-item-finish .ant-steps-icon {
  font-size: 8px;
  font-size: 0.8rem;
  line-height: 2.75;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item {
  flex-basis: 50px;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-icon {
  display: none;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-tail {
  margin-left: 0;
  padding: 0 5px;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-tail:after {
  height: 3px;
  background: linear-gradient(to right, #3851d1 50%, #f0f3f6 50%) right bottom;
  background-size: 200% 100%;
  transition: all 0.3s ease-out 0s;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item:last-child .ant-steps-item-tail {
  display: initial;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-process .ant-steps-item-tail:after {
  box-shadow: 0 0 5px 0.5px rgba(76, 132, 118, 0.5);
  background-position: left bottom;
}
html[data-theme='dark'] .ant-steps-horizontal.ant-steps-small.ant-steps-type-bars .ant-steps-item-finish .ant-steps-item-tail:after {
  background-position: left bottom;
}
html[data-theme='dark'] .content-clearfix.Content-box {
  padding-bottom: 15px;
}
html[data-theme='dark'] .content-clearfix .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='dark'] .padding-reset {
  padding: 0;
}
html[data-theme='dark'] #root {
  display: flex;
  flex-direction: column;
  background: #10101f;
}
html[data-theme='dark'] #root > .container {
  min-height: calc(100vh - 182px);
}
html[data-theme='dark'] #root .PageVariant.main {
  height: calc(100vh - 212px);
}
html[data-theme='dark'] #root .datepicker-top .ant-calendar {
  margin-top: -447px;
  margin-left: -77px;
}
html[data-theme='dark'] #root .content-wrapper {
  margin: 0 -30px;
}
html[data-theme='dark'] #root .widget__empty {
  margin-bottom: 0;
  text-align: center;
}
html[data-theme='dark'] #root .loader-wrapper {
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;
}
html[data-theme='dark'] #root .buttons-block .button {
  float: right;
  margin-left: 20px;
  margin-top: 28px;
}
html[data-theme='dark'] #root .button-group:not(.button-group--horiz) button + button {
  margin-left: 20px;
}
html[data-theme='dark'] #root .button-group.text-sm-left .button {
  float: left;
  margin-left: 0;
}
html[data-theme='dark'] #root .pie-chart-container .highcharts-pie {
  width: 100%;
  justify-content: center;
}
@media (max-width: 1023px) {
  html[data-theme='dark'] #root .pie-chart-container .highcharts-pie {
    flex-direction: column;
  }
}
html[data-theme='dark'] #root .highcharts-container {
  z-index: 1;
}
html[data-theme='dark'] #root a.paragraph_secondary,
html[data-theme='dark'] #root .paragraph_secondary a,
html[data-theme='dark'] #root a.paragraph_secondary:active,
html[data-theme='dark'] #root .paragraph_secondary a:active,
html[data-theme='dark'] #root a.paragraph_secondary:focus,
html[data-theme='dark'] #root .paragraph_secondary a:focus {
  color: #4a4a7a;
  text-decoration: underline;
}
html[data-theme='dark'] #root a.paragraph_secondary:hover,
html[data-theme='dark'] #root .paragraph_secondary a:hover {
  color: #26264e;
}
html[data-theme='dark'] #root .Title a {
  color: inherit;
}
html[data-theme='dark'] #root .Title .Icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  font-size: 12px;
  line-height: inherit;
}
html[data-theme='dark'] #root .bread-crumbs a {
  color: var(--primary-light);
}
html[data-theme='dark'] #root .select__multi {
  min-width: inherit;
}
html[data-theme='dark'] main.container {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  html[data-theme='dark'] main.container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 767px) {
  html[data-theme='dark'] .ant-modal {
    min-width: 370px !important;
  }
  html[data-theme='dark'] .ant-modal-confirm-centered {
    position: relative;
    top: -600px;
  }
}
@media (max-width: 992px) {
  html[data-theme='dark'] .container .row {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  html[data-theme='dark'] .container .row + .row {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  html[data-theme='dark'] .Content-box {
    overflow-x: hidden;
    padding: 15px;
    box-shadow: none;
    border-radius: 0;
  }
  html[data-theme='dark'] .Content-box .button-sm {
    min-width: 100%;
    margin-top: 15px !important;
  }
}
@media (max-width: 767px) {
  html[data-theme='dark'] .Content-box > .row {
    padding-bottom: 15px;
  }
}
html[data-theme='dark'] .header__left,
html[data-theme='dark'] .header__right {
  min-width: 150px;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .header .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
html[data-theme='dark'] footer {
  margin-top: auto;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .Footer_mini .Footer__bottom {
    display: flex;
    align-items: center;
    padding: 30px 15px;
    flex-direction: column-reverse;
  }
  html[data-theme='dark'] .Footer_mini .Footer__bottom a {
    margin-left: 0;
    margin-bottom: 15px;
  }
  html[data-theme='dark'] .Footer_mini .Footer__bottom span {
    display: block;
    text-align: center;
  }
}
html[data-theme='dark'] .auto-logout.ant-modal-confirm .ant-modal-confirm-btns {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .hide-on-mobile {
    display: none !important;
  }
}
html[data-theme='dark'] .overview-panel {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 72px;
}
html[data-theme='dark'] .overview-panel:not(:last-child) {
  padding-bottom: 30px;
}
html[data-theme='dark'] .overview-panel .row {
  margin-bottom: 0 !important;
}
html[data-theme='dark'] .overview-panel .row + .row {
  margin-top: 30px;
}
html[data-theme='dark'] .overview-item {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  color: #e4e8ee;
}
html[data-theme='dark'] .overview-item__title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
}
html[data-theme='dark'] .overview-item__value {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.125;
}
html[data-theme='dark'] .overview-item__value_positive {
  color: #07cc9b;
}
html[data-theme='dark'] .overview-item__value_negative {
  color: #ff376b;
}
html[data-theme='dark'] .overview-item__value_small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
  color: #e4e8ee;
}
html[data-theme='dark'] .overview-item__currency {
  margin-left: 5px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  color: #e4e8ee;
}
html[data-theme='dark'] .media {
  display: flex;
  align-items: center;
}
html[data-theme='dark'] .media img {
  border-radius: 50%;
  margin-right: 10px;
}
html[data-theme='dark'] .media-body {
  flex: 1;
}
html[data-theme='dark'] .media-body .media-links {
  display: flex;
  flex-wrap: wrap;
}
html[data-theme='dark'] .media-body h3 {
  margin-bottom: 0;
}
html[data-theme='dark'] .media-body a {
  text-decoration: none !important;
}
html[data-theme='dark'] .media-body a.call-now {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  color: #4a4a7a;
  margin-left: 14px;
}
html[data-theme='dark'] .single-news-item {
  margin-bottom: 40px !important;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .single-news-item hr {
    display: none;
  }
}
html[data-theme='dark'] .single-news-item .Content-box .button {
  min-width: 60px;
  margin-left: 20px;
}
html[data-theme='dark'] .single-news-item hr {
  display: block;
  height: 1px;
  margin: 30px -30px;
  border: none;
  color: #7171a7;
  background-color: #7171a7;
}
html[data-theme='dark'] .single-news-item--image {
  max-width: 100%;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .single-news-item--image {
    margin: 20px 0;
  }
}
html[data-theme='dark'] .single-news-item--date {
  min-width: 100px;
  line-height: 1.7;
  margin-bottom: 10px;
}
html[data-theme='dark'] .single-news-item--content {
  margin-bottom: 9px;
  white-space: pre-line;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .single-news-item--content {
    text-align: justify;
  }
}
html[data-theme='dark'] .portfolios {
  margin-bottom: 40px;
}
html[data-theme='dark'] .portfolios.Content-box {
  padding-bottom: 15px;
}
html[data-theme='dark'] .portfolios .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='dark'] .portfolios .loader-wrapper {
  padding-bottom: 120px !important;
}
html[data-theme='dark'] .analysis .column-center {
  margin: auto;
}
html[data-theme='dark'] .analysis .AccordionPanel-content {
  overflow: visible;
}
html[data-theme='dark'] .analysis .AccordionPanel-content-box {
  padding-bottom: 15px;
}
html[data-theme='dark'] .analysis .Info-box {
  margin: 15px auto;
}
html[data-theme='dark'] .analysis .risk-return-container {
  margin: 0 auto 15px;
  width: fit-content;
}
html[data-theme='dark'] .analysis .highcharts-container {
  z-index: unset;
}
html[data-theme='dark'] .analysis .highcharts-container .highcharts-background {
  width: 0;
}
html[data-theme='dark'] .analysis .loader-wrapper {
  margin: 0 auto !important;
  padding-bottom: 15px;
}
html[data-theme='dark'] .analysis .highcharts-tooltip > span {
  padding: 7px !important;
  outline: 7px solid #191934;
  background: #191934;
  white-space: nowrap !important;
}
html[data-theme='dark'] .analysis .highcharts-tooltip > span .value {
  margin-left: 10px;
}
html[data-theme='dark'] .single-portfolio {
  margin-bottom: 40px !important;
}
html[data-theme='dark'] .single-portfolio .Content-box {
  padding-bottom: 0;
}
@media (max-width: 928px) {
  html[data-theme='dark'] .single-portfolio .button {
    clear: both;
  }
}
@media (max-width: 575px) {
  html[data-theme='dark'] .single-portfolio .button {
    width: 100%;
  }
}
html[data-theme='dark'] .single-portfolio .Info-box {
  margin-bottom: 30px;
}
html[data-theme='dark'] .single-portfolio .highcharts-line .highcharts-tooltip > span {
  padding: 11.5px 7px !important;
  white-space: nowrap !important;
}
html[data-theme='dark'] .single-portfolio .overview-panel .bottom-padded {
  margin-bottom: 20px;
}
html[data-theme='dark'] .single-portfolio .overview-panel .bottom-padded .overview-item:first-child {
  padding-bottom: 22px;
}
html[data-theme='dark'] .single-portfolio .overview-panel .overview-item {
  align-items: flex-start;
}
html[data-theme='dark'] .single-portfolio .overview-panel .overview-item .overview-item__value {
  height: 38px;
  line-height: 38px;
}
html[data-theme='dark'] .single-portfolio .overview-panel .mobile-portfolio-value-performance {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
}
html[data-theme='dark'] .single-portfolio .overview-panel .mobile-portfolio-value-performance .overview-item {
  width: 50%;
}
html[data-theme='dark'] .single-portfolio .overview-panel .mobile-portfolio-value-performance .overview-item:first-child {
  padding-bottom: 0;
}
html[data-theme='dark'] .single-portfolio .security-transactions .AccordionPanel-content-box,
html[data-theme='dark'] .single-portfolio .positions .AccordionPanel-content-box,
html[data-theme='dark'] .single-portfolio .proposals .AccordionPanel-content-box {
  padding-bottom: 10px;
}
html[data-theme='dark'] .single-portfolio .security-transactions .loader-wrapper,
html[data-theme='dark'] .single-portfolio .positions .loader-wrapper,
html[data-theme='dark'] .single-portfolio .proposals .loader-wrapper {
  margin-bottom: 15px;
}
html[data-theme='dark'] .single-portfolio .positions.AccordionPanel__active .AccordionPanel-header,
html[data-theme='dark'] .single-portfolio .proposals.AccordionPanel__active .AccordionPanel-header {
  padding-bottom: 14px;
}
html[data-theme='dark'] .single-portfolio .positions .widget__empty,
html[data-theme='dark'] .single-portfolio .proposals .widget__empty {
  margin-bottom: 20px !important;
}
html[data-theme='dark'] .single-portfolio .security-transactions .info-box_error,
html[data-theme='dark'] .single-portfolio .cash-flows .info-box_error {
  margin-bottom: 20px;
}
html[data-theme='dark'] .single-portfolio .security-transactions .widget__empty,
html[data-theme='dark'] .single-portfolio .cash-flows .widget__empty {
  margin: 0 0 20px !important;
}
html[data-theme='dark'] .single-portfolio .table-wrapper .pagination {
  padding-bottom: 20px;
}
html[data-theme='dark'] .single-portfolio .data-wrapper {
  border-top: 1px solid #7171a7;
}
html[data-theme='dark'] .single-portfolio .positions .currency-cel {
  padding-left: 30px;
}
html[data-theme='dark'] .single-portfolio .positions .table-cell__actions {
  padding: 20px 0 20px 10px;
}
html[data-theme='dark'] .single-portfolio .performance.AccordionPanel__active .AccordionPanel-header,
html[data-theme='dark'] .single-portfolio .allocation.AccordionPanel__active .AccordionPanel-header {
  padding-bottom: 8px;
}
html[data-theme='dark'] .single-portfolio .performance .ant-tabs,
html[data-theme='dark'] .single-portfolio .allocation .ant-tabs {
  margin-top: 0 !important;
}
html[data-theme='dark'] .single-portfolio .performance .pie-chart-container,
html[data-theme='dark'] .single-portfolio .allocation .pie-chart-container {
  justify-content: center;
}
@media only screen and (min-width: 1040px) {
  html[data-theme='dark'] .single-portfolio .performance .pie-chart-container .highcharts-pie,
  html[data-theme='dark'] .single-portfolio .allocation .pie-chart-container .highcharts-pie {
    width: 25% !important;
  }
}
html[data-theme='dark'] .single-portfolio .cash-flows {
  border-bottom: 0 !important;
}
html[data-theme='dark'] .single-portfolio .cash-flows .loader-wrapper {
  margin-bottom: 15px;
}
html[data-theme='dark'] .single-portfolio .cash-flows .AccordionPanel-content-box {
  padding-bottom: 5px;
}
html[data-theme='dark'] .position-details,
html[data-theme='dark'] .position-details-overview {
  margin-top: 30px;
  margin-bottom: 40px !important;
}
html[data-theme='dark'] .position-details .Content-box,
html[data-theme='dark'] .position-details-overview .Content-box {
  padding-bottom: 10px;
}
html[data-theme='dark'] .position-details .content-wrapper,
html[data-theme='dark'] .position-details-overview .content-wrapper {
  margin: 0 -30px;
}
html[data-theme='dark'] .position-details .overview-panel .overview-item:not(:last-child),
html[data-theme='dark'] .position-details-overview .overview-panel .overview-item:not(:last-child) {
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .position-details .overview-panel > div,
  html[data-theme='dark'] .position-details-overview .overview-panel > div {
    min-width: 40%;
  }
  html[data-theme='dark'] .position-details .overview-panel > div + div,
  html[data-theme='dark'] .position-details-overview .overview-panel > div + div {
    padding-top: 15px;
  }
  html[data-theme='dark'] .position-details .overview-panel > div:last-child .overview-item:last-child,
  html[data-theme='dark'] .position-details-overview .overview-panel > div:last-child .overview-item:last-child {
    margin-bottom: 0;
  }
  html[data-theme='dark'] .position-details .overview-panel .overview-item,
  html[data-theme='dark'] .position-details-overview .overview-panel .overview-item {
    padding-bottom: 0 !important;
  }
}
html[data-theme='dark'] .position-details .performance .AccordionPanel-content__active,
html[data-theme='dark'] .position-details-overview .performance .AccordionPanel-content__active {
  overflow: visible;
}
html[data-theme='dark'] .position-details .performance .AccordionPanel-content__active .info-box_error,
html[data-theme='dark'] .position-details-overview .performance .AccordionPanel-content__active .info-box_error {
  margin-bottom: 15px;
}
html[data-theme='dark'] .position-details .performance .AccordionPanel-content__active .AccordionPanel-content-box .row,
html[data-theme='dark'] .position-details-overview .performance .AccordionPanel-content__active .AccordionPanel-content-box .row {
  margin-bottom: -10px;
}
html[data-theme='dark'] .position-details .performance .AccordionPanel-content__active .form-group,
html[data-theme='dark'] .position-details-overview .performance .AccordionPanel-content__active .form-group {
  margin-top: -58px;
  float: right;
}
html[data-theme='dark'] .position-details .trade,
html[data-theme='dark'] .position-details-overview .trade {
  border-bottom: 0;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .position-details .trade .row + .row,
  html[data-theme='dark'] .position-details-overview .trade .row + .row {
    margin-top: 15px;
  }
  html[data-theme='dark'] .position-details .trade .row > div:not(:last-child),
  html[data-theme='dark'] .position-details-overview .trade .row > div:not(:last-child) {
    margin-bottom: 15px;
  }
}
html[data-theme='dark'] .position-details .trade .ant-calendar-picker,
html[data-theme='dark'] .position-details-overview .trade .ant-calendar-picker {
  width: 243px;
}
html[data-theme='dark'] .position-details .trade .info-box_accent .title-3,
html[data-theme='dark'] .position-details-overview .trade .info-box_accent .title-3 {
  margin-bottom: 5px;
}
html[data-theme='dark'] .position-details .trade .info-box_error,
html[data-theme='dark'] .position-details-overview .trade .info-box_error {
  margin-bottom: 30px;
}
html[data-theme='dark'] .position-details .trade .AccordionPanel-content__active,
html[data-theme='dark'] .position-details-overview .trade .AccordionPanel-content__active {
  overflow: visible;
}
html[data-theme='dark'] .position-details .trade .AccordionPanel-content-box,
html[data-theme='dark'] .position-details-overview .trade .AccordionPanel-content-box {
  padding-bottom: 0;
}
html[data-theme='dark'] .position-details .trade .quantity .ant-input-group-addon,
html[data-theme='dark'] .position-details-overview .trade .quantity .ant-input-group-addon {
  padding: 0;
}
html[data-theme='dark'] .position-details .trade .quantity .ant-input-group-addon:before,
html[data-theme='dark'] .position-details-overview .trade .quantity .ant-input-group-addon:before {
  display: none;
}
html[data-theme='dark'] .position-details .trade .quantity .ant-input-group-addon button,
html[data-theme='dark'] .position-details-overview .trade .quantity .ant-input-group-addon button {
  padding: 0 10px;
  min-width: auto;
  text-decoration: underline;
  color: var(--primary-default);
  border: none;
}
html[data-theme='dark'] .position-details .trade .quantity .ant-input-group-addon button:hover,
html[data-theme='dark'] .position-details-overview .trade .quantity .ant-input-group-addon button:hover {
  color: var(--primary-darker);
}
html[data-theme='dark'] .position-details .trade .bottom-row,
html[data-theme='dark'] .position-details-overview .trade .bottom-row {
  margin: 0 -30px 30px;
  border-bottom: 1px solid #7171a7;
  padding: 0 15px 30px;
}
html[data-theme='dark'] .position-details-overview .buttons-block {
  padding-bottom: 15px;
}
html[data-theme='dark'] .RememberMe .checkbox-wrapper {
  margin-bottom: 20px;
}
html[data-theme='dark'] .Login {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  html[data-theme='dark'] .Login.Login {
    padding-right: 0;
    padding-left: 0;
  }
}
html[data-theme='dark'] .Login .detail_content {
  max-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='dark'] .Login .detail_content .content-pane-wrapper {
  background-color: #191934;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
}
html[data-theme='dark'] .Login .detail_content .content-pane {
  padding: 30px;
  border-radius: 5px;
}
html[data-theme='dark'] .Login .detail_content .content-pane .buttons.button-group {
  text-align: center;
}
html[data-theme='dark'] .Login .detail_content .content-pane .buttons.button-group .btn {
  float: none !important;
}
html[data-theme='dark'] .Login .detail_content .content-pane .buttons.button-group button {
  width: 100%;
}
html[data-theme='dark'] .Login .detail_content .content-pane .buttons.button-group .button-primary {
  margin-bottom: 20px;
}
html[data-theme='dark'] .Login .detail_content .pf-form_horizontal {
  background-color: #191934;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
  border-radius: 5px;
}
html[data-theme='dark'] .Login .detail_content .pf-form_horizontal .content-pane .pf-form_row {
  max-width: 28em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
html[data-theme='dark'] .Login .detail_content .pf-form_horizontal .validation-summary-errors .btn {
  display: block;
  margin: 1em auto 0;
  color: #333;
}
html[data-theme='dark'] .Login .detail_content .qr-code {
  display: block;
  width: 14em;
  height: 14em;
  background-size: 14em;
  margin: 1em auto;
}
html[data-theme='dark'] .Login .application-title {
  margin-bottom: 30px;
  text-align: center;
}
html[data-theme='dark'] .UsernameIdentity .info-text,
html[data-theme='dark'] .UsernameIdentity .form-group,
html[data-theme='dark'] .UsernameIdentity .ant-input-affix-wrapper {
  margin-bottom: 20px;
}
html[data-theme='dark'] .ForgotPassword .application-title {
  margin-bottom: 30px;
  text-align: center;
}
html[data-theme='dark'] .ForgotPassword .detail_content {
  max-width: 354px;
  min-width: 354px;
  margin: 0 auto;
  padding-top: 8rem;
  background: transparent;
}
html[data-theme='dark'] .ForgotPassword .detail_content .content-pane {
  padding: 30px;
  border-radius: 5px;
}
html[data-theme='dark'] .ForgotPassword .detail_content .content-pane-wrapper {
  background-color: #191934;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(66, 97, 255, 0.1);
}
html[data-theme='dark'] .ForgotPassword button {
  width: 100%;
}
html[data-theme='dark'] .ForgotPassword .info-text:not(:last-child),
html[data-theme='dark'] .ForgotPassword .Info-box {
  margin-bottom: 20px;
}
html[data-theme='dark'] .ResetPassword {
  padding: 30px !important;
  margin: 8rem auto 0;
  max-width: 354px;
}
html[data-theme='dark'] .ResetPassword button {
  width: 100%;
}
html[data-theme='dark'] .ResetPassword .info-text,
html[data-theme='dark'] .ResetPassword .form-group,
html[data-theme='dark'] .ResetPassword .Info-box {
  margin-bottom: 20px;
}
html[data-theme='dark'] header.header {
  min-height: 80px;
}
html[data-theme='dark'] header.header img {
  margin: 0;
  max-width: 200px;
}
html[data-theme='dark'] .profile-menu__name {
  color: #e4e8ee;
}
html[data-theme='dark'] .profile-menu__additional {
  line-height: 20px;
}
html[data-theme='dark'] .content-box_title > .Info-box {
  margin-top: 30px;
}
html[data-theme='dark'] .investment-allocation .Highcharts-bar-chart {
  margin: -16px 0;
}
html[data-theme='dark'] .investment-allocation .table-wrapper {
  margin-top: -30px !important;
  margin-bottom: -15px !important;
}
html[data-theme='dark'] .cell-actions {
  position: relative;
}
html[data-theme='dark'] .cell-actions .table-cell_icon {
  color: #ffffff;
}
html[data-theme='dark'] .cell-actions .table-cell_icon:hover {
  cursor: pointer;
}
html[data-theme='dark'] .cell-actions-panel {
  position: absolute;
  top: 100%;
  right: -15px;
  z-index: 20;
  display: none;
  border: 1px solid #f0f3f6;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
}
html[data-theme='dark'] .cell-actions-panel_visible {
  display: block;
}
html[data-theme='dark'] .cell-actions-panel:after,
html[data-theme='dark'] .cell-actions-panel:before {
  position: absolute;
  bottom: 100%;
  right: 15px;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-style: solid;
  border-width: 0 9px 11px;
  border-color: transparent;
  border-bottom-color: #f0f3f6;
}
html[data-theme='dark'] .cell-actions-panel:before {
  margin-bottom: 1px;
}
html[data-theme='dark'] .cell-actions-panel:after {
  border-bottom-color: #fff;
}
html[data-theme='dark'] .cell-actions-item {
  position: relative;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 170px;
  transition: all 0.3s ease 0s;
}
html[data-theme='dark'] .cell-actions-item:first-child {
  border-radius: 5px 5px 0 0;
}
html[data-theme='dark'] .cell-actions-item:last-child {
  border-radius: 0 0 5px 5px;
}
html[data-theme='dark'] .cell-actions-item:not(:first-child):before {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  display: block;
  height: 0;
  content: '';
  border-bottom: 1px solid #7171a7;
}
html[data-theme='dark'] .cell-actions-item:hover {
  background: #3851d1;
  color: #fff;
  cursor: pointer;
}
html[data-theme='dark'] .cell-actions-item:hover:before,
html[data-theme='dark'] .cell-actions-item:hover + li:before {
  content: none;
}
html[data-theme='dark'] .overview-panel {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
html[data-theme='dark'] .overview-panel:not(:last-child) {
  padding-bottom: 30px;
}
html[data-theme='dark'] .overview-panel .row {
  width: 100%;
}
html[data-theme='dark'] .overview-item + .overview-item {
  margin-top: 30px;
}
@media (max-width: 767px) {
  html[data-theme='dark'] .overview-item {
    margin-bottom: 15px;
    margin-top: 0 !important;
  }
  html[data-theme='dark'] .overview-item + .overview-item:last-child {
    margin-bottom: 0;
  }
}
html[data-theme='dark'] .overview-item {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  color: #e4e8ee;
}
html[data-theme='dark'] .overview-item .Form-control-label > span {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
}
html[data-theme='dark'] .overview-item__title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
}
html[data-theme='dark'] .overview-item__value {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.125;
}
html[data-theme='dark'] .overview-item__value_positive {
  color: #07cc9b;
}
html[data-theme='dark'] .overview-item__value_negative {
  color: #ff376b;
}
html[data-theme='dark'] .overview-item__value_small {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
  color: #ffffff;
}
html[data-theme='dark'] .overview-item__percent {
  margin-left: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.1875;
  font-weight: bold;
  color: #e4e8ee;
}
html[data-theme='dark'] .overview-item__percent_positive {
  color: #07cc9b;
}
html[data-theme='dark'] .overview-item__percent_negative {
  color: #ff376b;
}
html[data-theme='dark'] .overview-item__link {
  text-decoration: underline;
  color: #ffffff;
}
html[data-theme='dark'] .MTan .info-text,
html[data-theme='dark'] .MTan a {
  color: #ffffff;
}
html[data-theme='dark'] .MTan a {
  display: block;
  text-align: center;
  text-decoration: underline;
}
html[data-theme='dark'] .MTan .form-control-message {
  margin-bottom: 0;
  display: inline-block;
}
html[data-theme='dark'] .MTan .buttons.button-group .button:not(:last-child) {
  margin-bottom: 20px;
}
html[data-theme='dark'] .MTan .buttons.button-group .link {
  height: 24px;
  padding: 0;
  color: #ffffff !important;
}
html[data-theme='dark'] .MTan .buttons.button-group .link span {
  text-decoration: underline !important;
}
html[data-theme='dark'] .MTan .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-color: transparent !important;
}
html[data-theme='dark'] .MTan .passcode-inputs {
  margin-bottom: 24px;
}
html[data-theme='dark'] .MTan .passcode-inputs .form-group {
  display: inline-block;
}
html[data-theme='dark'] .MTan .passcode-inputs .form-group + .form-group {
  margin-left: 3px;
}
html[data-theme='dark'] .MTan .passcode-inputs .ant-input-affix-wrapper:hover .ant-input {
  border-width: 0 0 1px !important;
}
html[data-theme='dark'] .MTan .passcode-inputs input {
  width: 28px;
  height: 32px;
  font-size: 18px;
  padding: 0 5px !important;
  border-width: 0 0 1px !important;
  border-radius: 0 !important;
  text-align: center;
}
html[data-theme='dark'] .MTan .passcode-inputs input:focus,
html[data-theme='dark'] .MTan .passcode-inputs input:hover {
  outline: 0 !important;
  border-width: 0 0 1px !important;
}
@font-face {
  font-family: 'AvenirNext-UltraLight';
  src: url('../../clientRequirements/fonts/AvenirNext-UltraLight.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Century-gothic';
  src: url('../../clientRequirements/fonts/Century-gothic.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Century-gothic-bold';
  src: url('../../clientRequirements/fonts/Century-gothic-bold.ttf');
  font-weight: normal;
  font-style: normal;
}
html[data-theme='dark'] body {
  background: #010e1e;
}
html[data-theme='dark'] body .button {
  text-transform: uppercase;
}
html[data-theme='dark'] body .navigation__link {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
}
html[data-theme='dark'] body .header__navigation .navigation__link.navigation__link {
  white-space: nowrap;
}
html[data-theme='dark'] body #root {
  background: #010e1e;
}
html[data-theme='dark'] body #root a.paragraph_secondary,
html[data-theme='dark'] body #root a.call-now {
  color: #F1F1F0;
}
html[data-theme='dark'] body #root a.paragraph_secondary:hover,
html[data-theme='dark'] body #root a.call-now:hover {
  color: #93a9c1;
}
html[data-theme='dark'] body .navigation {
  background-color: #021e36;
}
html[data-theme='dark'] body .navigation__button {
  color: #3681b0;
}
html[data-theme='dark'] body .navigation__link {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  color: #63666a;
}
html[data-theme='dark'] body .navigation__link-active {
  color: #ececec;
}
html[data-theme='dark'] body .Footer {
  background-color: #021e36;
}
html[data-theme='dark'] body .Footer__bottom {
  color: #ececec;
}
html[data-theme='dark'] body .terms {
  /* &__header {} */
}
html[data-theme='dark'] body .terms-section {
  color: #ececec;
  background: #000000ef;
}
html[data-theme='dark'] body .terms-container {
  background-color: #010e1e;
}
html[data-theme='dark'] body .terms__content {
  background-color: #010e1e;
}
html[data-theme='dark'] body .terms__content h3 li {
  color: #3681b0;
}
html[data-theme='dark'] body .terms__content body .terms__header h1,
html[data-theme='dark'] body .terms__content div h2,
html[data-theme='dark'] body .terms__content ol h3 {
  color: #ececec;
}
html[data-theme='dark'] body .terms__footer {
  border-color: #63666a;
}
html[data-theme='dark'] body .terms-container .button-secondary {
  color: var(--white);
}
html[data-theme='dark'] body .table div {
  border-color: #ececec;
}
html[data-theme='dark'] body .Table.table td:first-of-type {
  background-color: #021e36;
}
html[data-theme='dark'] body .Table.table th {
  background-color: #021e36;
  box-shadow: 0 1px 0 0 var(--secondary-lighter);
}
@media (min-width: 769px) {
  html[data-theme='dark'] body .Table.table td:first-child:before {
    background-color: #021e36;
  }
}
html[data-theme='dark'] body .Modal-body {
  background-color: #021e36;
}
html[data-theme='dark'] body .Modal-confirm-title {
  color: #93a9c1;
}
html[data-theme='dark'] .eye-button img {
  filter: invert(0.5);
}
html[data-theme='dark'] .btn-apps {
  color: #63666a;
  border: 1px solid #ececec;
}
html[data-theme='dark'] .btn-apps:hover {
  color: var(--primary-default);
  box-shadow: 0 1px 3px #ececec;
}
html[data-theme='dark'] .btn-apps:first-of-type img {
  filter: brightness(0.9);
}
html[data-theme='dark'] .form-group_opened .select__button,
html[data-theme='dark'] .select:focus .select__button,
html[data-theme='dark'] .select .select__button:hover,
html[data-theme='dark'] .select .select__button:focus,
html[data-theme='dark'] .select .select__button:active {
  background-color: #0072ce38;
}
html[data-theme='dark'] .select__dropdown {
  background-color: #021e36;
}
html[data-theme='dark'] .select__option:hover,
html[data-theme='dark'] .select__option:focus {
  background-color: #0072ce38;
}
html[data-theme='dark'] .button-primary.button {
  color: #ececec;
  background-color: #3681b0;
}
html[data-theme='dark'] .button-primary.button:hover,
html[data-theme='dark'] .button-primary.button:focus {
  background-color: #3681b0;
}
html[data-theme='dark'] .button-secondary.button:hover,
html[data-theme='dark'] .button-secondary.button:active,
html[data-theme='dark'] .button-secondary.button:focus {
  background-color: #0072ce38;
}
html[data-theme='dark'] .ForgotPassword .detail_content .content-pane-wrapper,
html[data-theme='dark'] .Login .detail_content .content-pane-wrapper {
  background-color: #021e36;
}
html[data-theme='dark'] header.header {
  background-color: #021e36;
}
html[data-theme='dark'] .header__navigation .navigation__link {
  color: #63666a;
}
html[data-theme='dark'] .header__navigation .navigation__link.navigation__link-active {
  color: #ececec;
  background-color: #0072ce38;
}
html[data-theme='dark'] .Avatar.avatar-square,
html[data-theme='dark'] .Avatar.avatar-circle {
  color: #F1F1F0;
  background: #63666a;
}
html[data-theme='dark'] .profile-menu__content .logout-button {
  color: #F1F1F0;
}
html[data-theme='dark'] .profile-menu__content .logout-button:hover {
  color: #93a9c1;
}
html[data-theme='dark'] .profile-menu__email,
html[data-theme='dark'] .profile-menu__additional {
  color: #63666a;
}
html[data-theme='dark'] input.switch-round:checked + label:before {
  border-color: #F1F1F0;
  background-color: #3681b0;
}
html[data-theme='dark'] input.switch-round + label:after {
  background-color: #021e36;
}
html[data-theme='dark'] .rc-tooltip {
  color: #63666a;
}
html[data-theme='dark'] .Content-box,
html[data-theme='dark'] .rc-tooltip-inner {
  background-color: #021e36;
}
html[data-theme='dark'] #multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover {
  background-color: #0072ce38;
}
html[data-theme='dark'] .checkbox-wrapper:hover .checkbox-inner,
html[data-theme='dark'] .checkbox:hover .checkbox-inner {
  background-color: #93a9c1;
}
html[data-theme='dark'] .select__controls {
  border-top-color: #93a9c1;
}
html[data-theme='dark'] .Title,
html[data-theme='dark'] .overview-item,
html[data-theme='dark'] .overview-item__currency {
  color: #F1F1F0;
}
html[data-theme='dark'] .Accordion {
  background-color: #021e36;
  border-top-color: #93a9c1;
}
html[data-theme='dark'] .Accordion .AccordionPanel {
  border-bottom-color: #93a9c1;
}
html[data-theme='dark'] .Accordion .AccordionPanel-header i {
  color: #3681b0;
}
html[data-theme='dark'] .bread-crumbs a {
  color: #63666a !important;
}
html[data-theme='dark'] .bread-crumbs a:hover {
  color: #ececec !important;
}
html[data-theme='dark'] .ant-tabs-bar {
  border-color: #93a9c1;
}
html[data-theme='dark'] .ant-tabs-tab-prev,
html[data-theme='dark'] .ant-tabs-tab-next {
  color: #FFF;
}
html[data-theme='dark'] .ant-tabs-tab-prev:hover,
html[data-theme='dark'] .ant-tabs-tab-next:hover {
  color: #F1F1F0;
}
html[data-theme='dark'] .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  color: #FFF;
}
html[data-theme='dark'] .Table thead > tr {
  border-bottom-color: #93a9c1;
}
html[data-theme='dark'] .Table thead > tr th {
  color: #93a9c1;
}
html[data-theme='dark'] .Table tbody > tr.row-level-0,
html[data-theme='dark'] .Table tbody > tr.row-level-1 td {
  border-top-color: #93a9c1;
}
html[data-theme='dark'] .Table tr td .expand-icon i {
  color: #3681b0;
}
html[data-theme='dark'] .Table tr td {
  color: #FFF;
}
html[data-theme='dark'] .ant-calendar-month-panel {
  background-color: #021e36;
  border-color: #ececec;
}
html[data-theme='dark'] .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month {
  color: rgba(232, 229, 229, 0.25);
  background: #0c2e4c;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:after {
  color: #ececec;
}
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover:after,
html[data-theme='dark'] .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover:after {
  color: #3681b0;
}
html[data-theme='dark'] .ant-calendar-picker input {
  background-color: #021e36;
  border-color: #93a9c1;
}
html[data-theme='dark'] .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #ececec;
}
html[data-theme='dark'] .ant-calendar-picker .ant-calendar-picker-icon svg {
  fill: #ececec;
}
html[data-theme='dark'] .chart-0 .highcharts-color-0 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-0 .highcharts-color-1 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-0 .highcharts-color-2 {
  fill: #66aae2;
}
html[data-theme='dark'] .chart-0 .highcharts-color-3 {
  fill: #0072ce;
}
html[data-theme='dark'] .chart-0 .highcharts-color-4 {
  fill: #63666a;
}
html[data-theme='dark'] .chart-0 .highcharts-color-5 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-0 .highcharts-color-6 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-0 .highcharts-color-7 {
  fill: #b8cf8a;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(1) .legend-point {
  background-color: #ff9900;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(2) .legend-point {
  background-color: #86a644;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(3) .legend-point {
  background-color: #66aae2;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(4) .legend-point {
  background-color: #0072ce;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(5) .legend-point {
  background-color: #63666a;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(6) .legend-point {
  background-color: #bbaf97;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(7) .legend-point {
  background-color: #ffc266;
}
html[data-theme='dark'] .chart-0 .legend-item:nth-child(8) .legend-point {
  background-color: #b8cf8a;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: #66aae2;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: #0072ce;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: #63666a;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-0.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0,
html[data-theme='dark'] .chart-0 .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: #b8cf8a;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-background {
  fill: #021e36;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-0.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-1.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-2.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-3.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-4.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-5.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-6.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-0 .Highcharts-bar-chart .highcharts-color-7.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .highcharts-color-0 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-1 .highcharts-color-1 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-1 .highcharts-color-2 {
  fill: #a3ccee;
}
html[data-theme='dark'] .chart-1 .highcharts-color-3 {
  fill: #c2ddf3;
}
html[data-theme='dark'] .chart-1 .highcharts-color-4 {
  fill: #e0eef9;
}
html[data-theme='dark'] .chart-1 .highcharts-color-5 {
  fill: #4095da;
}
html[data-theme='dark'] .chart-1 .highcharts-color-6 {
  fill: #7db9e7;
}
html[data-theme='dark'] .chart-1 .highcharts-color-7 {
  fill: #bfdcf3;
}
html[data-theme='dark'] .chart-1 .highcharts-color-8 {
  fill: #8a8c8f;
}
html[data-theme='dark'] .chart-1 .highcharts-color-9 {
  fill: #b1b2b5;
}
html[data-theme='dark'] .chart-1 .highcharts-color-10 {
  fill: #d8d9da;
}
html[data-theme='dark'] .chart-1 .highcharts-color-11 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-1 .highcharts-color-12 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-1 .highcharts-color-13 {
  fill: #ffebcc;
}
html[data-theme='dark'] .chart-1 .highcharts-color-14 {
  fill: #e7efd8;
}
html[data-theme='dark'] .chart-1 .highcharts-color-15 {
  fill: #e4dfd5;
}
html[data-theme='dark'] .chart-1 .highcharts-color-16 {
  fill: #d9d9d9;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(1) .legend-point {
  background-color: #ff9900;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(2) .legend-point {
  background-color: #86a644;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(3) .legend-point {
  background-color: #a3ccee;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(4) .legend-point {
  background-color: #c2ddf3;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(5) .legend-point {
  background-color: #e0eef9;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(6) .legend-point {
  background-color: #4095da;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(7) .legend-point {
  background-color: #7db9e7;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(8) .legend-point {
  background-color: #bfdcf3;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(9) .legend-point {
  background-color: #8a8c8f;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(10) .legend-point {
  background-color: #b1b2b5;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(11) .legend-point {
  background-color: #d8d9da;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(12) .legend-point {
  background-color: #bbaf97;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(13) .legend-point {
  background-color: #ffc266;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(14) .legend-point {
  background-color: #ffebcc;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(15) .legend-point {
  background-color: #e7efd8;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(16) .legend-point {
  background-color: #e4dfd5;
}
html[data-theme='dark'] .chart-1 .legend-item:nth-child(17) .legend-point {
  background-color: #d9d9d9;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: #a3ccee;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: #c2ddf3;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: #e0eef9;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #4095da;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #7db9e7;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: #bfdcf3;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
  fill: #8a8c8f;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0 {
  fill: #b1b2b5;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0 {
  fill: #d8d9da;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(11) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(11) .highcharts-color-0 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(12) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(12) .highcharts-color-0 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(13) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(13) .highcharts-color-0 {
  fill: #ffebcc;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(14) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(14) .highcharts-color-0 {
  fill: #e7efd8;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(15) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(15) .highcharts-color-0 {
  fill: #e4dfd5;
}
html[data-theme='dark'] .chart-1.highcharts-pie_single-allocation:nth-child(16) .highcharts-color-0,
html[data-theme='dark'] .chart-1 .highcharts-pie.highcharts-pie_single-allocation:nth-child(16) .highcharts-color-0 {
  fill: #d9d9d9;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-background {
  fill: #021e36;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-0.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-1.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-2.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-3.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-4.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-5.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-6.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-7.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-8.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-9.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-10.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-11.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-12.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-13.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-14.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-15.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-1 .Highcharts-bar-chart .highcharts-color-16.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .highcharts-color-0 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-2 .highcharts-color-1 {
  fill: #b8cf8a;
}
html[data-theme='dark'] .chart-2 .highcharts-color-2 {
  fill: #66aae2;
}
html[data-theme='dark'] .chart-2 .highcharts-color-3 {
  fill: #a3ccee;
}
html[data-theme='dark'] .chart-2 .highcharts-color-4 {
  fill: #0072ce;
}
html[data-theme='dark'] .chart-2 .highcharts-color-5 {
  fill: #c2ddf3;
}
html[data-theme='dark'] .chart-2 .highcharts-color-6 {
  fill: #e0eef9;
}
html[data-theme='dark'] .chart-2 .highcharts-color-7 {
  fill: #63666a;
}
html[data-theme='dark'] .chart-2 .highcharts-color-8 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-2 .highcharts-color-9 {
  fill: #4095da;
}
html[data-theme='dark'] .chart-2 .highcharts-color-10 {
  fill: #7db9e7;
}
html[data-theme='dark'] .chart-2 .highcharts-color-11 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-2 .highcharts-color-12 {
  fill: #bfdcf3;
}
html[data-theme='dark'] .chart-2 .highcharts-color-13 {
  fill: #8a8c8f;
}
html[data-theme='dark'] .chart-2 .highcharts-color-14 {
  fill: #b1b2b5;
}
html[data-theme='dark'] .chart-2 .highcharts-color-15 {
  fill: #d8d9da;
}
html[data-theme='dark'] .chart-2 .highcharts-color-16 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-2 .highcharts-color-17 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-2 .highcharts-color-18 {
  fill: #ffebcc;
}
html[data-theme='dark'] .chart-2 .highcharts-color-19 {
  fill: #e7efd8;
}
html[data-theme='dark'] .chart-2 .highcharts-color-20 {
  fill: #e4dfd5;
}
html[data-theme='dark'] .chart-2 .highcharts-color-21 {
  fill: #d9d9d9;
}
html[data-theme='dark'] .chart-2 .highcharts-color-22 {
  fill: #ffff00;
}
html[data-theme='dark'] .chart-2 .highcharts-color-23 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-2 .highcharts-color-24 {
  fill: #00b050;
}
html[data-theme='dark'] .chart-2 .highcharts-color-25 {
  fill: #7030a0;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(1) .legend-point {
  background-color: #ffc266;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(2) .legend-point {
  background-color: #b8cf8a;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(3) .legend-point {
  background-color: #66aae2;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(4) .legend-point {
  background-color: #a3ccee;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(5) .legend-point {
  background-color: #0072ce;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(6) .legend-point {
  background-color: #c2ddf3;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(7) .legend-point {
  background-color: #e0eef9;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(8) .legend-point {
  background-color: #63666a;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(9) .legend-point {
  background-color: #ff9900;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(10) .legend-point {
  background-color: #4095da;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(11) .legend-point {
  background-color: #7db9e7;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(12) .legend-point {
  background-color: #86a644;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(13) .legend-point {
  background-color: #bfdcf3;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(14) .legend-point {
  background-color: #8a8c8f;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(15) .legend-point {
  background-color: #b1b2b5;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(16) .legend-point {
  background-color: #d8d9da;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(17) .legend-point {
  background-color: #bbaf97;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(18) .legend-point {
  background-color: #ffc266;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(19) .legend-point {
  background-color: #ffebcc;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(20) .legend-point {
  background-color: #e7efd8;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(21) .legend-point {
  background-color: #e4dfd5;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(22) .legend-point {
  background-color: #d9d9d9;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(23) .legend-point {
  background-color: #ffff00;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(24) .legend-point {
  background-color: #bbaf97;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(25) .legend-point {
  background-color: #00b050;
}
html[data-theme='dark'] .chart-2 .legend-item:nth-child(26) .legend-point {
  background-color: #7030a0;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
  fill: #b8cf8a;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
  fill: #66aae2;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
  fill: #a3ccee;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
  fill: #0072ce;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #c2ddf3;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
  fill: #e0eef9;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
  fill: #63666a;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
  fill: #ff9900;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0 {
  fill: #4095da;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0 {
  fill: #7db9e7;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(11) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(11) .highcharts-color-0 {
  fill: #86a644;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(12) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(12) .highcharts-color-0 {
  fill: #bfdcf3;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(13) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(13) .highcharts-color-0 {
  fill: #8a8c8f;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(14) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(14) .highcharts-color-0 {
  fill: #b1b2b5;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(15) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(15) .highcharts-color-0 {
  fill: #d8d9da;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(16) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(16) .highcharts-color-0 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(17) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(17) .highcharts-color-0 {
  fill: #ffc266;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(18) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(18) .highcharts-color-0 {
  fill: #ffebcc;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(19) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(19) .highcharts-color-0 {
  fill: #e7efd8;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(20) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(20) .highcharts-color-0 {
  fill: #e4dfd5;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(21) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(21) .highcharts-color-0 {
  fill: #d9d9d9;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(22) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(22) .highcharts-color-0 {
  fill: #ffff00;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(23) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(23) .highcharts-color-0 {
  fill: #bbaf97;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(24) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(24) .highcharts-color-0 {
  fill: #00b050;
}
html[data-theme='dark'] .chart-2.highcharts-pie_single-allocation:nth-child(25) .highcharts-color-0,
html[data-theme='dark'] .chart-2 .highcharts-pie.highcharts-pie_single-allocation:nth-child(25) .highcharts-color-0 {
  fill: #7030a0;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-background {
  fill: #021e36;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-0.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-1.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-2.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-3.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-4.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-5.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-6.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-7.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-8.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-9.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-10.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-11.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-12.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-13.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-14.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-15.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-16.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-17.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-18.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-19.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-20.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-21.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-22.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-23.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-24.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-25.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .chart-2 .Highcharts-bar-chart .highcharts-color-26.highcharts-point {
  fill: #0072ce;
  stroke: #0072ce;
}
html[data-theme='dark'] .highcharts-line .highcharts-area {
  fill: #93a9c1;
}
html[data-theme='dark'] .highcharts-line .highcharts-color-0 {
  stroke: #3681b0;
  background-color: #021e36;
}
html[data-theme='dark'] .highcharts-line .highcharts-tooltip-box {
  fill: #021e36;
}
html[data-theme='dark'] button.call-now:hover span {
  color: #93a9c1;
}
html[data-theme='dark'] .pdf-controls {
  background-color: #010e1ef2;
}
html[data-theme='dark'] #root .generate-report-container {
  display: flex;
}
html[data-theme='dark'] #root .generate-report-container .loader-wrapper {
  margin: 0;
  padding: 0;
  width: 45px;
  height: 30px;
}
html[data-theme='dark'] #root > .overflow > .container {
  min-height: calc(100vh - 188px);
}
html[data-theme='dark'] body h1 {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20pt;
}
html[data-theme='dark'] body h2,
html[data-theme='dark'] body h3,
html[data-theme='dark'] body h4,
html[data-theme='dark'] body h5,
html[data-theme='dark'] body h6 {
  font-family: 'Century-gothic', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14pt;
}
html[data-theme='dark'] body .btn-apps-group {
  display: flex;
  flex-direction: column;
  align-content: center;
}
html[data-theme='dark'] body .btn-apps {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin-top: 10px;
  font-weight: 700;
  padding: 0.45rem 14px 0.45em 0;
  font-size: var(--font-size-base);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
html[data-theme='dark'] body .btn-apps img {
  margin-right: 5px;
}
html[data-theme='dark'] body .back-to-login {
  margin-top: 20px;
}
html[data-theme='dark'] body .alert-icon-wrapper {
  display: none;
}
html[data-theme='dark'] body .navigation__link-active {
  font-weight: bold;
}
html[data-theme='dark'] body .factsheet-button {
  margin-bottom: 20px;
}
html[data-theme='dark'] body .terms-section {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3% 5%;
}
html[data-theme='dark'] body .terms-section + .overflow {
  overflow: hidden;
  height: 100vh;
}
html[data-theme='dark'] body .terms-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  max-width: 700px;
  padding: 10px;
}
html[data-theme='dark'] body .terms__header {
  margin-top: 20px;
}
html[data-theme='dark'] body .terms__content {
  overflow-y: auto;
  max-height: 50vh;
  width: 100%;
  max-width: 100%;
  padding: 10px 15px 10px 0;
}
html[data-theme='dark'] body .terms__content h1 {
  margin-top: 40px;
}
html[data-theme='dark'] body .terms__footer {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  width: 98%;
  max-width: 700px;
  border-top-width: 1px;
  border-top-style: solid;
}
@media (max-width: 600px) {
  html[data-theme='dark'] body .terms__footer {
    flex-direction: column;
  }
}
html[data-theme='dark'] body .terms__notification {
  margin-bottom: 0;
  margin-right: 40px;
}
@media (max-width: 600px) {
  html[data-theme='dark'] body .terms__notification {
    margin-bottom: 15px;
  }
}
html[data-theme='dark'] body .terms-container .button-secondary {
  margin-left: 20px;
}
html[data-theme='dark'] body .terms__content ol {
  margin-top: 1.2em;
  padding-left: 25px;
}
html[data-theme='dark'] body .terms__content ol > ol,
html[data-theme='dark'] body .terms__content ol > ul {
  padding-left: 15px;
}
html[data-theme='dark'] body .terms__content ol li {
  margin-top: 1em;
}
html[data-theme='dark'] body .terms .table {
  display: table;
  margin: 20px 0 30px;
  border-collapse: collapse;
}
html[data-theme='dark'] body .terms .table div {
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
}
html[data-theme='dark'] body .terms .table-header {
  display: table-header-group;
}
html[data-theme='dark'] body .terms .table-row {
  display: table-row;
}
html[data-theme='dark'] body .terms .table-cell {
  display: table-cell;
}
html[data-theme='dark'] body .Table-content {
  position: relative;
  max-height: 73vh;
}
html[data-theme='dark'] body .Table.table th {
  position: sticky;
  top: 0;
  z-index: 20;
}
@media (min-width: 769px) {
  html[data-theme='dark'] body .Table.table th:first-of-type {
    left: 0;
    z-index: 30;
  }
  html[data-theme='dark'] body .Table.table td:first-of-type:before {
    position: absolute;
    top: 0;
    left: -34px;
    display: block;
    box-sizing: content-box;
    width: 34px;
    height: 100%;
    content: '';
  }
  html[data-theme='dark'] body .Table.table td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 10;
  }
  html[data-theme='dark'] body .Table.table .row-level-1 td:first-of-type {
    left: 34px;
    overflow: visible;
  }
}
html[data-theme='dark'] body .profile-menu__additional b:first-of-type,
html[data-theme='dark'] body .profile-menu__additional br:first-of-type {
  display: none;
}
html[data-theme='dark'] body .dashboard .overview-wrapper {
  min-height: 72px;
}
html[data-theme='dark'] body .dashboard .overview-panel {
  min-height: unset;
}
html[data-theme='dark'] body .dashboard .overview-item {
  justify-content: space-between;
  width: 50%;
  min-width: 180px;
  margin-bottom: 10px;
}
html[data-theme='dark'] body .overview-item__value_mid {
  font-size: 1.5em;
  line-height: 1.25;
}
html[data-theme='dark'] body .faq__item dfn {
  font-weight: 600;
  font-style: normal;
}
html[data-theme='dark'] body .Footer img {
  max-width: 42px;
}
html[data-theme='dark'] .PageVariant.wrapper {
  padding-top: 0;
}
html[data-theme='dark'] .Table tbody .table-cell {
  white-space: pre;
}
html[data-theme='dark'] .portfolios .Table tr td:first-child:not(:last-child) {
  display: table-cell;
}
html[data-theme='dark'] .Table tr td .expand-cell {
  margin-right: unset;
}
html[data-theme='dark'] .Title.application-title img {
  width: 250px;
}
html[data-theme='dark'] .Passcode .passcode-inputs {
  display: flex;
}
html[data-theme='dark'] .Passcode .form-group {
  width: 14.3%;
}
html[data-theme='dark'] .Passcode .form-group + .form-group {
  margin-left: 8px;
}
html[data-theme='dark'] .Passcode input {
  width: 100% !important;
}
html[data-theme='dark'] .eye-button {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 32px;
  height: 32px;
  transform: translate(-30%, 50%);
  background: transparent;
  border: none;
}
html[data-theme='dark'] .eye-button img {
  width: 23px;
  height: 23px;
}
html[data-theme='dark'] .Info-box a {
  text-decoration: underline;
}
html[data-theme='dark'] header.header img {
  height: 80px;
  width: 80px;
}
html[data-theme='dark'] .header__navigation {
  justify-content: flex-end;
  margin: 0 0 0 auto;
  max-width: 600px;
}
html[data-theme='dark'] .header__navigation .navigation__link {
  padding: 0 10px;
  line-height: 5;
  text-transform: uppercase;
}
html[data-theme='dark'] .header__left {
  min-width: 160px;
}
@media only screen and (max-width: 480px) {
  html[data-theme='dark'] .header__left {
    min-width: 60px;
  }
}
@media only screen and (max-width: 480px) {
  html[data-theme='dark'] .header__right {
    min-width: 100px;
  }
}
@media only screen and (min-width: 769px) {
  html[data-theme='dark'] .header .header__logo div {
    justify-content: flex-start;
  }
}
html[data-theme='dark'] .profile-menu__content .avatar {
  display: none;
}
html[data-theme='dark'] .Accordion .overview-item + .overview-item {
  margin: 0 0 15px;
}
@media only screen and (max-width: 768px) {
  html[data-theme='dark'] .Accordion .overview-item + .overview-item {
    width: 50%;
  }
}
html[data-theme='dark'] .AccordionPanel-content-box .widget__empty {
  min-height: 350px;
}
html[data-theme='dark'] .news-item-image-container {
  overflow: hidden;
  height: 200px;
}
@media screen and (max-width: 992px) {
  html[data-theme='dark'] .news-item-image-container {
    height: 250px;
  }
}
html[data-theme='dark'] .single-news-item .origin-link {
  margin-top: 10px;
}
html[data-theme='dark'] .single-news-item figure {
  max-width: 100%;
}
html[data-theme='dark'] .single-news-item img {
  max-width: 100%;
  width: 100%;
  height: auto!important;
  margin-bottom: 15px;
}
html[data-theme='dark'] .single-news-item iframe {
  max-width: 560px;
  height: 315px;
  border: none;
}
@media (max-width: 768px) {
  html[data-theme='dark'] .single-news-item iframe {
    width: 100% !important;
    height: 250px;
  }
}
html[data-theme='dark'] .ant-calendar-month-panel {
  border-width: 1px;
  border-style: solid;
}
html[data-theme='dark'] .ant-calendar-picker {
  max-width: 85px;
}
html[data-theme='dark'] .month-picker-start {
  margin-right: 5px;
}
html[data-theme='dark'] .tabs-with-filter .ant-tabs-extra__item {
  margin: 0 0 0 5px;
}
html[data-theme='dark'] .tabs-with-filter .ant-tabs-extra-content .select {
  max-width: 110px;
}
html[data-theme='dark'] .select fieldset {
  max-height: unset;
}
html[data-theme='dark'] .select__dropdown_mini {
  min-width: 290px;
}
@media (max-width: 559px) {
  html[data-theme='dark'] .tabs-with-filter .ant-tabs-top-bar {
    display: flex;
    flex-direction: column-reverse;
  }
  html[data-theme='dark'] .tabs-with-filter .ant-tabs-extra-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    float: unset;
    height: auto;
  }
  html[data-theme='dark'] .tabs-with-filter .ant-tabs-extra__item {
    margin: 5px 0;
  }
}
@media (min-width: 1024px) {
  html[data-theme='dark'] .header_with-navigation {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: var(--navigation-box-shadow);
  }
}
html[data-theme='dark'] .relationship-manager-section .row,
html[data-theme='dark'] .relationship-manager-section .relationship-manager {
  height: 100%;
}
html[data-theme='dark'] button.call-now span {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 14px;
}
html[data-theme='dark'] button.call-now {
  padding: 0 3px 0 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
html[data-theme='dark'] .scroll-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 69px;
  height: 69px;
  padding: 9px 5px 5px;
  opacity: 0;
  background: var(--scroll-top-bg-color);
  color: var(--scroll-top-color);
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
}
html[data-theme='dark'] .scroll-top.visible {
  opacity: 1;
}
html[data-theme='dark'] .Highcharts-bar-chart .highcharts-axis-labels .Form-control-label {
  margin-bottom: -3px;
}
html[data-theme='dark'] .highcharts-pie .legend {
  text-transform: capitalize;
}
html[data-theme='dark'] .login-content .Info-box {
  margin-bottom: 20px;
}
html[data-theme='dark'] .react-pdf__Document {
  display: flex;
  flex-direction: column;
}
html[data-theme='dark'] .react-pdf__Page {
  margin: 0 auto 10px;
}
