.news {
    margin-bottom: 10px;
}

.news .pagination {
    width: calc(100% + 60px);
    margin: 17px calc(0 - var(--padding-base)) 0 calc(0 - var(--padding-base));
    border-top: 1px solid var(--secondary-lighter);
    padding-top: var(--padding-base);
}

.news .news-list {
    column-count: 3;
    column-gap: var(--padding-base);
}

@media screen and (max-width: 992px) {
    .news .news-list {
        column-count: 2;
    }

}

@media screen and (max-width: 768px) {
    .news .news-list {
        column-count: 1;
    }

}
