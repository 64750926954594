.news-item.Content-box {
    padding: 0 !important;
}

.news-item {
    --scale: 1.009;
    overflow: hidden;
    margin: var(--padding-base) 0;
    background: var(--background-content);
    border-radius: 8px;
    break-inside: avoid-column;
    page-break-inside: avoid;
    transform: translateZ(0) scale(1);
    transition: all 0.3s ease-in-out;
    margin-top: 1px;
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .news-item {
            display: inline-block;
        }
    }
}

.news-item:hover {
    transform: translate3d(0, 0, 0) scale(var(--scale));
    border-radius: 8px;
}

.news-item:hover .news-item-content {
    color: var(--primary-dark);
}

.news-item .news-item-image {
    filter: grayscale(0.6);
    width: 100%;
    height: 200px;
    transition: all 0.12s;
    object-fit: cover;
}

.news-item:hover .news-item-image {
    filter: grayscale(0);
    display: block;
    object-fit: cover;
}

.news-item .news-item-content {
    padding: var(--padding-base);
    color: var(--primary-dark);
}

.news-item:first-child {
    margin-top: 0;
}

.news-item .LinkNews {
    text-decoration: none;
    color: unset;
}

.news-item .news-item-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 11px;
}

.news-item .news-item-title h4 {
    font-weight: 800;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .news-list {
        column-count: 2;
    }

    .news-item .news-item-image {
        height: 250px;
    }
}

@media screen and (max-width: 768px) {
    .news-list {
        column-count: 1;
    }

    .news-item .news-item-image {
        height: 300px;
    }
}
