.Footer.Footer_mini {
    margin-top: auto !important;
}

.Footer .Footer__bottom > span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.Footer .Footer__bottom .LanguageSwitcher {
    margin-right: var(--padding-base);
    min-width: 150px;
}

@media (max-width: 767px) {
    .Footer .Footer__bottom .LanguageSwitcher span {
        text-align: left !important;
    }

    .Footer .Footer__bottom .LanguageSwitcher {
        min-width: 200px;
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
    }

    .Footer .Footer__bottom > span {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .Footer .Footer__icon {
        height: 52px;
        margin-left: 10px;
    }
}
