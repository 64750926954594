.PortfoliosCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    align-items: center;
    transition: all 0.3s ease-in-out;

}

.PortfoliosCard:hover {
    transform: translate3d(0, 0, 0) scale(1.009);
}

.portfolios-card-profile {
    color: var(--primary-light);
}

.portfolios-card-profile > .profile-title:after {
    content: ':';
    margin-right: 5px;
}

.portfolios-card-name,
.portfolios-card-profile {
    justify-self: self-start;
}

.portfolios-card-value,
.portfolios-card-performance {
    justify-self: self-end;
}

.portfolios-card-profile,
.portfolios-card-performance,
.portfolios-card-value h2.Title {
    font-weight: bold;
    text-align: right;

}

.portfolios-card-performance {
    font-size: var(--font-size-base);
}

.portfolios-card-performance .table-cell_positive {
    color: var(--positive-default);
}

.portfolios-card-performance .table-cell_negative {
    color: var(--negative-default);
}
