.header__logo div {
    display: flex;
    justify-content: center;
}

.logo-text {
    margin-left: 10px;
    white-space: nowrap;
    align-self: end;
}

@media (max-width: 540px) {
    .header__center .logo-text {
        display: none;
    }
}
