.qr-container {
    display: flex;
    padding-bottom: 25px;
}

.centered-qr {
    text-align: center;
}

.qr-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
