#root .first-section .loader-wrapper {
    padding-bottom: 0 !important;
}

.base-margin-bottom {
    margin-bottom: 30px;
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.second-section .row {
    height: 100%;
}

.second-section .Content-box {
    height: 100%;
}

.container .dashboard .row {
    margin-bottom: 0 !important;
}

.dashboard .relationship-manager.Content-box {
    padding-bottom: 15px;
}

.dashboard .relationship-manager .info-box_error {
    margin-bottom: 15px;
}

.dashboard .profile,
.dashboard .relationship-manager {
    padding-bottom: 30px !important;
}

.dashboard .portfolios {
    margin-top: 0;
    margin-bottom: 0;
}

.dashboard .portfolios .loader-wrapper {
    padding-top: 50px !important;
    padding-bottom: 120px !important;
}

.dashboard .news {
    margin: 0;
}

.dashboard .overview {
    padding: 30px;
    background-color: var(--white);
}

.dashboard .overview-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-bottom: 0;
    margin-top: 0 !important;
}

.dashboard .table-wrapper {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.dashboard .table-cell {
    font-size: 14px;
    vertical-align: middle;
    line-height: 0.875;
}

.dashboard .table-cell_linked {
    color: var(--primary-default);
    text-decoration: underline;
}

.dashboard .highcharts-pie.highcharts-pie_single-allocation .legend-item {
    margin-left: 5px;
}

.dashboard .highcharts-line .highcharts-tooltip > span {
    padding: 11.5px 7px !important;
    white-space: nowrap !important;
}
